<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockList"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.list_id"
      )
    .form-group
      label {{ $t('dashboardBlocks.reportextract.labels.sites_table_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="tables"
        :reduce="option => option.id"
        v-model="editableSettings.sites_table_id"
      )
    .form-group(
      v-for="settingProperty in fieldSettingPropertiesForSites"
      :key="settingProperty"
    )
      label(:for="settingProperty") {{ $t('dashboardBlocks.reportextract.labels.' + settingProperty) }}
      v-select(
        :searching="true"
        label="name"
        :inputId="settingProperty"
        :options="fieldsForSitesTable"
        :reduce="option => option.id"
        v-model="editableSettings[settingProperty]"
      )
    .form-group
      label {{ $t('dashboardBlocks.reportextract.labels.contracts_table_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="tables"
        :reduce="option => option.id"
        v-model="editableSettings.contracts_table_id"
      )
    .form-group(
      v-for="settingProperty in fieldSettingPropertiesForContracts"
      :key="settingProperty"
    )
      label(:for="settingProperty") {{ $t('dashboardBlocks.reportextract.labels.' + settingProperty) }}
      v-select(
        :searching="true"
        label="name"
        :inputId="settingProperty"
        :options="fieldsForContractsTable"
        :reduce="option => option.id"
        v-model="editableSettings[settingProperty]"
      )
</template>

<script>
import { mapState }   from 'vuex'
import DashboardBlock from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableIdForConfiguration: null,
      fieldSettingPropertiesForSites: [
        'site_name_field_id',
        'site_source_id_field_id',
        'site_card_id_field_id',
        'site_technology_field_id'
      ],
      fieldSettingPropertiesForContracts: [
        'contract_site_reference_field_id',
        'contract_target_field_id',
        'contract_start_date_field_id',
        'contract_end_date_field_id'
      ]
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    dashboardBlockList() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    sitesTable() {
      if (this.editableSettings.sites_table_id) {
        return this.tables.find(table => table.id === this.editableSettings.sites_table_id);
      }
    },
    contractsTable () {
      if (this.editableSettings.contracts_table_id) {
        return this.tables.find(table => table.id === this.editableSettings.contracts_table_id);
      }
    },
    fieldsForSitesTable() {
      if (this.sitesTable) {
        return this.sitesTable.fields;
      }
      return [];
    },
    fieldsForContractsTable() {
      if (this.contractsTable) {
        return this.contractsTable.fields;
      }
      return [];
    }
  },
  watch: {
    'editableSettings.sites_table_id': function(newSettings, oldSettings) {
      this.fieldSettingPropertiesForSites.forEach((fieldSetting) => {
        this.$set(this.editableSettings, fieldSetting, null);
      });
    },
    'editableSettings.contracts_table_id': function(newSettings, oldSettings) {
      this.fieldSettingPropertiesForContracts.forEach((fieldSetting) => {
        this.$set(this.editableSettings, fieldSetting, null);
      });
    }
  }
}
</script>
