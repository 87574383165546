<template lang="pug">
.dashboard-kpis-card(ref="blockContent")
  template(v-if="settingsAreReady")
    .dashboard-block--header
      h2 {{ title }}
    .dashboard-kpis
      .dashboard-kpi-wrapper(
          v-for="kpi in items"
          :title="kpi.description"
        )
        .kui-card.dashboard-kpi
          .dashboard-kpi--icon.bg-project-secondary-100.text-project-secondary
            i.fad.fa-fw(:class="'fa-' + kpi.icon")
          .content
            .dashboard-kpi--label &nbsp;{{ kpi.label }}
            .dashboard-kpi--figure
              span.dashboard-kpi--value {{ kpi.value }}
              span.dashboard-kpi--unit {{ kpi.unit }}
  .flex-center.h-100(v-else)
    div
      h2.text-center.text-warning
        i.fas.fa-exclamation-triangle
      p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}
</template>

<script>
import { api }                 from '../../api/client';
import { mapGetters }          from 'vuex';
import DashboardBlock          from '../../models/dashboardBlock';
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import { isEqual, map }        from 'lodash';
import DashboardSharedItem     from '../../models/dashboardSharedItem';

export default {
  mixins: [DashboardBlockMixin],
  props: {
    periodIdentifier: {
      type:     String,
      required: false
    },
    periodDates: {
      type:     Array,
      required: true
    }
  },
  data() {
    return {
      items: [],
      title: '',
    }
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    settingsAreReady() {
      return this.dashboardBlock &&
              this.dashboardBlock.kind_settings.list_id &&
              this.dashboardBlockList &&
              this.dashboardBlockList.kind_settings.table_id &&
              this.dashboardBlockList.kind_settings.view_id
    },
    dashboardBlockList() { // we need the list block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.list_id);
    },
    tableForKpi() {
      if (this.settingsAreReady) {
        return this.getTableById(this.dashboardBlockList.kind_settings.table_id);
      }
    },
    selectedRecordIds() {
      if (this.tableForKpi) {
        const items = DashboardSharedItem.listItems(this.dashboardBlockList.id, true).
                                          where('metadata', value => value.checked).get();
        return map(items, 'item_id');
      }
    },
    shouldAutoResize() {
      // to override computed from DashboardBlockMixin, cannot be in data
      return true;
    }
  },
  watch: {
    'selectedRecordIds': function (newRecordIds, oldRecordIds) {
      if (this.settingsAreReady && !isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) {
        this.loadKpis();
      }
    },
    'periodIdentifier': function (newPeriod, _oldPeriod) {
      if (!newPeriod) return;

      this.loadKpis();
    },
    'periodDates': function (newPeriodDates, _oldPeriodDates) {
      if (!newPeriodDates) return;
      this.loadKpis();
    }
  },
  mounted() {
    if (!this.settingsAreReady) return;

    window.addEventListener("resize", this.resizeBlock);

    setTimeout(() => {
      this.loadKpis();
    }, 500);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeBlock);
  },
  methods: {
    loadKpis() {
      return api.dashboardBlocks.kpis(this.dashboardBlock.id, this.selectedRecordIds, this.periodDates, this.periodIdentifier).then((response) => {
        this.items = response.data.items;
        this.title = response.data.title;
      }).then(this.resizeBlock);
    }
  },
}
</script>
