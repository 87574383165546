import i18n from "../vue/locales/locales.js";

const allAlertsBtnHTML = () => {
  const alertsContainer = document.getElementById('alerts-container');

  return `<a href="${alertsContainer.dataset.path}" class="show-more-btn js-show-more-btn">${i18n.t('notifications.showAllAlerts')}</a>`;
}

const alertHTML = (alert) => {
  return `<a href="${alert.path}" class="d-flex align-items-center justify-content-between mb-2 px-3 py-2 rounded item">
    <div class="d-flex align-items-center justify-content-start text-xl mr-3"><i class="far fa-${alert.icon}"></i></div>
    <div class="flex-grow-1">
      <div class="text-sm font-weight-bolder">${alert.title}</div>
      <div class="text-sm">${alert.table}</div>
    </div>
    <div class="d-flex align-items-center">
      <div class="ml-2 px-2 record-count-badge">${alert.recordsCount}</div>
    </div>
  </a>`;
}

const addAlert = (alert) => {
  const container = document.getElementById('alerts-container');

  container.insertAdjacentHTML("beforeend", alertHTML(alert));
}

const displayAllAlertsBtn = () => {
  const wrapper     = document.getElementById('alerts-wrapper');
  const showMoreBtn = wrapper.querySelector('.js-show-more-btn')

  if (!showMoreBtn) wrapper.insertAdjacentHTML("beforeend", allAlertsBtnHTML());
}

const displayAlerts = (payload) => {
  payload.data.forEach((alert) => addAlert(alert));

  if (payload.moreData) displayAllAlertsBtn();
}

export { displayAlerts };
