import { find } from 'lodash';

export const InsertFieldNameMixin = {
  methods: {
    insertFieldNameInContent(content, fields) {
      // look for `field_(id)`
      [...content.matchAll(/field_(\d+)/g)].forEach(([field_identifier, field_id]) => {
        const field = find(fields, ['id', Number.parseInt(field_id, 10)]);
        if (field) {
          content = content.replaceAll(field_identifier, `{${field.name}}`);
        }
      })
      return content;
    }
  }
}
