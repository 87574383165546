<script>
import { isEqual }             from 'lodash';
import { Line }                from 'vue-chartjs';
import chartjsPluginAnnotation from 'chartjs-plugin-annotation';
import { tailwindColors }      from '../../services/tailwindColors';
import { EventBus }            from '../../main';

export default {
  extends: Line,
  props: {
    chartdata: {
      type:     Object,
      required: true,
    },
    annotations: {
      type:     Array,
      required: true
    },
    dashboardBlockId: {
      type: Number,
      required: true
    }
  },
  computed: {
    yAxes() {
      return this.yLabelStringArray.map((yLabel, index) => {
        return {
            offset: true,
            position: ['left', 'right'][index % 2],
            id: `y-axis-${yLabel}`,
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 8,
              callback: this.yAxesTickCallback
            },
            gridLines: {
              display: true,
            },
            scaleLabel: {
              display: true,
              labelString: yLabel,
              padding: 6,
              fontSize: 15,
              fontStyle: 'bold'
            }
          }
      })
    },
    options() {
      return {
        annotation: {
          events: ["mouseenter"],
          annotations: this.annotations
        },
        animation: {
          duration: 0.1
        },
        legendCallback: this.legendCallback,
        scales: {
          xAxes: [
            {
              type: 'time',
              time: {
                displayFormats: {
                  hour:  'k:mm',
                  day:   'DD/MM',
                  month: 'DD/MM'
                }
              },
              ticks: {
                beginAtZero: true,
                autoSkip: true,
                maxTicksLimit: 21,
                major: {
                  enabled: true,
                  fontStyle: 'bold',
                },
              },
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: this.chartdata.xlabelString,
                padding: 10,
                fontSize: 15,
                fontStyle: 'bold'
              }
            },
          ],
          yAxes: this.yAxes,
        },
        tooltips: {
          position: 'nearest',
          mode: 'index',
          xPadding: 12,
          yPadding: 12,
          intersect: false,
          backgroundColor: 'rgb(255, 255, 255)',
          bodyFontColor: 'black',
          bodyFontStyle: 'bold',
          bodyFontSize: 12,
          borderColor: tailwindColors.gray500,
          borderWidth: '1',
          titleFontColor: tailwindColors.gray800,
          titleFontStyle: 'normal',
          displayColors: true,
          callbacks: {
            title: this.tooltipTitleCallback,
            label: this.tooltipLabelCallback
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'center',
          labels: {
            generateLabels: this.generateLabels
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    },
    yLabelStringArray() {
      if (Array.isArray(this.chartdata.ylabelString)) {
        return this.chartdata.ylabelString
      }

      return Array.of(this.chartdata.ylabelString)
    },
  },
  watch: {
    'chartdata': function (newChartdata, oldChartdata) {
      if (!isEqual(newChartdata, oldChartdata)) {
        this.renderChart(newChartdata, this.options);
        this.$emit('sendLegend', this.generateLegend());
      }
    },
    'annotations': function (newAnnotations, oldAnnotations) {
      if (!isEqual(newAnnotations, oldAnnotations)) {
        this.options.annotation.annotations = newAnnotations;
        this.renderChart(this.chartdata, this.options);
      }
    }
  },
  mounted() {
    this.addPlugin(chartjsPluginAnnotation);
    this.renderChart(this.chartdata, this.options);
    this.$emit('sendLegend', this.generateLegend());

    EventBus.$on(`toggleSerie${this.dashboardBlockId}`, (item) => {
      const index = item.datasetIndex;
      const meta = this.chartdata.datasets[index]._meta;
      const hidden = meta[Object.keys(meta)[0]].hidden;

      meta[Object.keys(meta)[0]].hidden = hidden ? null : true;
      this.$data._chart.update();
    });
  },
  beforeDestroy() {
    EventBus.$off(`toggleSerie${this.dashboardBlockId}`);
  },
  methods: {
    legendCallback(chart) {
      return chart.legend.legendItems;
    },
    yAxesTickCallback(value) {
      return Intl.NumberFormat(this.$i18n.locale).format(value);
    },
    tooltipTitleCallback(tooltipItems) {
      var date            = new Date(tooltipItems[0].label);
      var dateOptions     = { month: 'long', day: 'numeric' };
      var dateTimeOptions = { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};

      if (date.getHours() == 0 && date.getMinutes() == 0 && date.getSeconds() == 0) {
        return date.toLocaleDateString(this.$i18n.locale, dateOptions);
      } else {
        return date.toLocaleDateString(this.$i18n.locale, dateTimeOptions);
      }
    },
    tooltipLabelCallback(tooltipItem, data) {
      var unit        = data.datasets[tooltipItem.datasetIndex].unit
      var label = data.datasets[tooltipItem.datasetIndex].label || '';

      if (label) {
        label += ' : ' + Intl.NumberFormat(this.$i18n.locale).format(tooltipItem.yLabel) + ' ' + unit;
      }

      return label;
    },
    generateLabels(chart) {
      return chart.data.datasets.map((dataset, i) => {
        return {
          datasetIndex: i,
          text:         dataset.label,
          fillStyle:    dataset.backgroundColor,
          strokeStyle:  dataset.borderColor,
          lineDash:     dataset.borderDash,
          lineWidth:    dataset.borderWidth,
          hidden:       false
        };
      });
    },
  }
}
</script>
