import DashboardBlock from '../models/dashboardBlock';
import { throttle }   from 'lodash';
import { EventBus }   from '../main';
import { mapState }   from 'vuex';

export const DashboardBlockMixin = {
  props: {
    dashboardBlockId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState(['translationFallbackClasses']),
    dashboardBlock() {
      return DashboardBlock.find(this.dashboardBlockId);
    },
    shouldAutoResize() {
      // it needs to be a computed so it can be dynamically set in components including the mixin
      return false;
    },
    titleFallback() {
      return this.dashboardBlock?.title_fallback;
    }
  },
  created() {
    if (!(typeof this.resizeBlock == 'function')) {
      // do this only if `resizeBlock` function is not already declarded in component
      this.resizeBlock = throttle(() => {
        // this needs to be done this way so each component including the mixin will have its own `resizeBlock` function. If this function were
        // simply put in `methods`, each call to `resizeBlock` would throttle it for *all* components including the mixin, and so only one would
        // actually perform the resize. By doing so, a unique function is created at each component creation and added to component's properties (this)

        // default method is to adapt block size to its content (content overflow needs to NOT be constrained block size)
        // you need to add blockContent ref for this to work (probably on component's top level div)
        if (this.shouldAutoResize) {
          const blockElementStyle = getComputedStyle(document.getElementById(`dashboard-block-${this.dashboardBlockId}`));
          const paddingTop        = Number.parseInt(blockElementStyle.paddingTop, 10);
          const paddingBottom     = Number.parseInt(blockElementStyle.paddingBottom, 10);
          const aimedHeight       = this.$refs.blockContent.clientHeight + paddingTop + paddingBottom;

          EventBus.$emit('resize-block', aimedHeight, this.dashboardBlockId);
        }
      }, 500, { leading: false, trailing: true });
    }
    EventBus.$on('dashboard-breakpoint-updated', this.resizeBlock);
    EventBus.$on('main-panel-width-updated', this.resizeBlock);
  },
  beforeDestroy() {
    EventBus.$off('dashboard-breakpoint-updated');
    EventBus.$off('main-panel-width-updated');
  }
};
