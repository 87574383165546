<template lang="pug">
  .view-form-wrapper.overflow-auto
    .view-form-container.p-3(
      v-if="!record.id && canUpdateRecord"
      :class="{ 'my-4': screenWidth >= 768 }"
    )
      h3.mb-3.text-center(
        :class="{ [translationFallbackClasses]: currentView.nameFallback }"
      ) {{ currentView.name }}
      h5.mb-4.text-gray-800.text-center.markdown-container(
        :class="{ [translationFallbackClasses]: currentView.descriptionFallback }"
        v-html="currentViewDescriptionHTML"
      )
      hr.mb-4.divider
      h6.text-gray-700.text-center {{ $t("viewForm.whatDoYouWantToDo") }}
      .text-center.p-3
        div
          button.btn.btn-primary(
            type="button"
            :disabled="waitingModeOn"
            @click="createAndOpenNewRecord"
          ) {{ $t("viewForm.createRecord") }}
            i(
              :class="waitingModeOn ? 'ml-2 far fa-spinner fa-spin fa-lg fa-fw' : 'd-done'"
            )
        div.edit-or-create
          hr.divider
          span.conjonction {{ $t("or") }}
        div
          v-select.mx-auto(
            label="name"
            :reduce="option => option.id"
            :get-option-label="option => option.displayName"
            :get-option-key="option => option.id"
            :options="recordsForEdit"
            :clearable="false"
            v-model="recordIdForEdit"
            input-id="view-form-update-record"
            :placeholder="$t('selectRecord')"
          )
          button.btn.btn-primary.mt-2(
            type="button"
            :disabled="waitingModeOn || !recordIdForEdit"
            @click="selectAndOpenRecord"
          ) {{ $t("viewForm.editRecord") }}

    .view-form-container(
      v-if="record.id"
      :class="{ 'my-4': screenWidth >= 768 }"
    )
      .d-flex.justify-content-center.align-items-center
        .col.p-0
          .view-form-header(
            :style="{ backgroundColor: projectPrimaryColors.normal }"
            :class="{ 'rounded-0': screenWidth < 768 }"
          )
            button.btn.btn-secondary.btn-sm.mb-3.mt-n2(
              v-if="canUpdateRecord"
              :disabled="waitingModeOn"
              type="button"
              @click="cancelForm"
            )
              i.fal.fa-arrow-left.mr-2
              span {{ $t("back") }}
            h5
              span.i.far.fa-money-check-edit
              span.ml-2(
                :class="{ [translationFallbackClasses]: currentView.nameFallback }"
              ) {{ currentView.name }}

          .view-form-body.my-4
            RecordEntriesInput(
              :record="record"
              :canShowHiddenFields="false"
            )

          .view-form-footer(
            v-if="!recordIdForEdit"
          )
            hr
            .d-flex.justify-content-center
              button.btn.btn-info.text-uppercase.text-light.btn-submit.mt-2.mb-4.mr-2(
                v-if="canUpdateRecord"
                :disabled="waitingModeOn"
                @click="cancelForm"
              ) {{ $t('cancel') }}

              button.btn.btn-info.text-uppercase.text-light.btn-submit.mt-2.mb-4(
                :disabled="waitingModeOn || !canCreateRecord || (hasFilesEntries && !hasFileAttached)"
                @click="confirmRecordCreation"
              ) {{ $t('submit') }}
                i(
                  :class="waitingModeOn ? 'ml-2 far fa-spinner fa-spin fa-lg fa-fw' : 'd-done'"
                )
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { some, find } from "lodash";
import { api } from "../api/client";
import Record from "../models/record";
import { alertPopup } from "../../components/alert_popup";
import RecordEntriesInput from "./inputs/RecordEntriesInput";
import { marked } from "marked";

export default {
  components: {
    RecordEntriesInput
  },
  data() {
    return {
      recordIdForEdit: null,
      recordIdForEdit: null,
      record:          {},
      tmpRecordJobId:  null,
      waitingModeOn:   false,
    };
  },
  computed: {
    ...mapState({
      currentTable:               state => state.tableStore.table,
      currentViewId:              state => state.viewStore.view.id,
      currentView:                state => state.viewStore.view,
      projectPrimaryColors:       state => state.projectPrimaryColors,
      screenWidth:                state => state.screenWidth,
      translationFallbackClasses: state => state.translationFallbackClasses,
    }),
    ...mapGetters({
      getRecordById: "recordStore/getRecordById"
    }),
    canCreateRecord() {
      return some(this.record.entries, (entry) => {
        return entry.field.visible && entry.isWritable;
      });
    },
    hasFilesEntries() {
      find(this.record.entries, (entry) => entry.field.fieldType === "files");
    },
    hasFileAttached(){
      return some(this.record.entries, (entry) => {
        return entry.field.fieldType === "files" && entry.value.length > 0;
      })
    },
    canUpdateRecord() {
      return this.currentView.typeOptions?.can_update_record
    },
    recordsForEdit() {
      return this.currentView.sortedRecordsIds.map((recordId) => {
        return this.currentTable.recordsById[recordId];
      });
    },
    currentViewDescriptionHTML() {
      const renderer = new marked.Renderer();
      renderer.link = (href, title, text) => `<a target="_blank" href="${href}" title="${title}">${text}</a>`;

      return marked.parse(this.currentView.description, { renderer: renderer });
    },
  },
  created() {
    if (!this.canUpdateRecord) this.createAndOpenNewRecord();
  },
  mounted() {
    this.currentTable.records.forEach(record => record.buildEntries())
  },
  beforeDestroy() {
    this.$store.commit('recordStore/REMOVE_RECORD_IN_MODALS', this.record);
  },
  methods:{
    cancelForm() {
      this.deleteUnconfirmedNewRecord();
      this.resetForm();
    },
    resetForm() {
      this.$store.commit('recordStore/REMOVE_RECORD_IN_MODALS', this.record);
      this.tmpRecordJobId = null;
      this.recordIdForEdit = null;
      this.record = {};
    },
    deleteUnconfirmedNewRecord() {
      if (this.record.creationConfirmed) return;

      api.records.delete({
        recordId: this.record.id,
        jobId: this.tmpRecordJobId
      }).then(() => {
        this.newRecord = false;
        this.tmpRecordJobId = null;
      });
    },
    selectAndOpenRecord() {
      this.record = this.getRecordById(this.recordIdForEdit)
    },
    createAndOpenNewRecord() {
      this.waitingModeOn = true;

      api.tables.createRecord({
        viewId:  this.currentViewId,
        tableId: this.currentTable.id
      }).then(response => {
          this.waitingModeOn = false;

          this.tmpRecordJobId = response.data.job_id;
          const record        = Record.build(response.data.record, this.currentTable);
          record.visible      = true;
          this.record         = record;
          this.$store.commit('recordStore/ADD_RECORD_IN_MODALS', record);
        });
    },
    confirmRecordCreation() {
      this.waitingModeOn = true;

      api.records.confirmCreation({
        recordId: this.record.id, jobId: this.tmpRecordJobId
      }).then((response) => {
          this.waitingModeOn = false;

          if (!response.data.error) {
            this.record.creationConfirmed = true;
            this.$store.dispatch("recordStore/addRecord", this.record);
            this.$toast.success(`<i class="fas fa-check mr-2"></i>${this.$t('controlBarActions.newRecordCreated')}.`, { position: 'bottom-right' });
            this.resetForm();

            if (!this.canUpdateRecord) this.createAndOpenNewRecord();

            if (this.currentView.typeOptions.form_redirection_view_id) {
              this.fetchView(this.currentView.typeOptions.form_redirection_view_id);
            }
          } else {
            const message = response.data.custom_message || this.$t('updateForbidden')
            alertPopup(message, 'OK');
          }
        })
    },
    fetchView(viewId) {
      if (viewId === this.currentView.id) return;

      this.$store.dispatch("viewStore/fetchView", { viewId });
    },
  }
}
</script>

<style lang="scss" scoped>
.edit-or-create {
  position: relative;
  height: 1rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .divider {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
  }
  .conjonction {
    padding: .5rem 1rem;
    background-color: white;
    position: relative;
    z-index: 1;
  }
}
</style>
