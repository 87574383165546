import { api }                  from '../../api/client';
import { remove, find }         from 'lodash';
import { buildTableFromSchema } from '../../services/buildTableFromSchemaService';
import TreeItem                 from '../../models/treeItem';


export const namespaced = true;

export const state = {
  tables: [],
  table:  {}
};

export const mutations = {
  SET_TABLES: function(state, tables) {
    state.tables = tables;
  },
  SET_TABLE: function(state, table) {
    state.table = table;
  },
  PUSH_TABLE: function(state, table) {
    state.tables.push(table);
  },
  ADD_RECORD: function(state, record) {
    state.table.records.push(record);
    state.table.recordsById[record.id] = record;
  },
  ADD_FIELD_TO_TABLE: function(state, { tableIndex, field }) {
    state.tables[tableIndex].fields.push(field);
  },
  REMOVE_TABLE: function(state, tableId) {
    remove(state.tables, table => table.id === tableId);
  },
  REMOVE_VIEW: function(state, viewId) {
    remove(state.table.views, view => view.id === viewId);
  }
};

export const actions = {
  fetchTable: function({ dispatch, commit }, { tableId, skipVisibleRecordIds = false }) {
    dispatch('disconnectAllEntryUpdateChannel', null, { root: true });
    dispatch('clearViewAndGrid', null, { root: true });
    dispatch('setCurrentResource', { resourceType: null, resourceId: null }, { root: true });
    commit('SET_TABLE', {});
    return api.tables.load({ tableId, withViewData: true }).then(response => {
      dispatch('buildTableFromPayload', { tableData: response.data.tableData });
      dispatch('viewStore/buildViewFromPayload', { viewData: response.data.viewData, skipVisibleRecordIds }, { root: true });
    });
  },
  buildNewTable: function({ commit, dispatch }, { payload }) {
    dispatch('clearViewAndGrid', null, { root: true });
    commit('SET_TABLE', {});
    const table = buildTableFromSchema(payload.tableSchema[0]);
    commit('PUSH_TABLE', table);
    setTimeout(() => {
      dispatch('buildTableFromPayload', { tableData: payload.tableData });
      dispatch('viewStore/buildViewFromPayload', { viewData: payload.viewData }, { root: true });
    }, 0)
  },
  buildTableFromPayload: function({ commit, getters, dispatch }, { tableData, setAsShownTable = true }) {
    const table = getters.getTableById(tableData.id);
    table.updateWithTableData(tableData);
    table.lockRecords();

    if (tableData.tree_item) TreeItem.insertOrUpdate({ data: tableData.tree_item });

    dispatch('addEntryUpdateChannel', table.id, { root: true });
    if (setAsShownTable) {
      commit('SET_TABLE', table);
    }
    return table;
  },
  deleteCurrentTable({ state, dispatch, commit }) {
    const tableId         = state.table.id;
    const treeItem        = TreeItem.query().where('itemId', tableId).limit(1).get()[0];
    const itemToFetch     = treeItem.findNextItemToFetch();
    const resourceToFetch = { id: itemToFetch.itemId, type: itemToFetch.type };

    commit('SET_TABLE', {});
    dispatch('clearViewAndGrid', null, { root: true });
    dispatch('deleteTable', { tableId });

    setTimeout(() => dispatch('loadResource', resourceToFetch, { root: true }));
  },
  deleteTable({ commit }, { tableId }) {
    TreeItem.delete(treeItem => treeItem.itemId === tableId && treeItem.type === 'table');

    commit('REMOVE_TABLE', tableId);
  },
  connectTablesWithRelationalData({ state, getters }) {
    state.tables.forEach(table => {
      const foreignTableId    = table.relationalData.foreign_table_id
      if (!foreignTableId) return;

      const relationalFieldId = table.relationalData.foreign_table_reference_field_id;
      const foreignTable      = getters.getTableById(foreignTableId);

      if (!foreignTable) return;

      if (table.relationalData.child) {
        table.relationalData.relationalField = find(foreignTable.fields, ['id', relationalFieldId]);
        table.relationalData.parentTable     = foreignTable;
      } else {
        table.relationalData.relationalField = find(table.fields, ['id', relationalFieldId]);
        table.relationalData.childTable      = foreignTable;
      }
    });
  }
};

export const getters = {
  getTableById: state => id => {
    return state.tables.find(table => table.id === id);
  },
  isCurrentTable: (_state, _getters, rootState, rootGetters) => (id) => {
    if (rootState.currentResourceType != 'view') return;

    const currentView = rootGetters['viewStore/getViewById'](rootState.currentResourceId);

    if (!currentView) return;

    return id === currentView.table.id;
  },
};
