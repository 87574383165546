const REGEXPS_BY_ATTRIBUTE = {
  // matches only french phone numbers
  CALL: /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
  // same as above, except that it matches only mobile phone numbers
  SMS: /^(?:(?:\+|00)33|0)\s*[6-7](?:[\s.-]*\d{2}){4}$/,
  // https://regexr.com/2rhq7 (the one from  URI::MailTo::EMAIL_REGEXP is not valid in JS)
  MAIL: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
};

export const FlexContactValidationMixin = {
  data() {
    return {
      validContactFormat: true
    }
  },
  computed: {
    invalidContactFormat() {
      return !this.validContactFormat;
    }
  },
  methods: {
    testContactFormat(value) {
      this.validContactFormat = value.match(REGEXPS_BY_ATTRIBUTE[this.type]);
    }
  }
}
