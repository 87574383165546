<template lang="pug">
    v-select.entry-input-select.bg-white(
      appendToBody
      :searching="true"
      :options="entry.field.typeOptions.select_list"
      v-model="entry.value"
      @input="updateEntry(entry)"
      :inputId="'selectCellInput' + entry.field.id"
    )
      template(#no-options="{ search, searching, loading }") {{ $t('entries.select.noOptions') }}
</template>

<script>
import { UpdateEntryMixin } from "../../../mixins/UpdateEntryMixin";
import { selectInputMixin } from "../../../mixins/selectInputMixin";

export default {
  mixins: [UpdateEntryMixin, selectInputMixin],
  props: {
    entry: {
      type: Object,
      requried: true
    }
  },
  data() {
    return {
      shouldAddListener: false
    }
  }
};
</script>
