<script>
import { createPopper } from '@popperjs/core';

export default {
  name: 'Popper',
  props: {
    popperId: {
      type: String,
      required: true
    },
    config: {
      type: Object,
      default: () => ({ placement: 'bottom' }),
    },
  },
  data() {
    return {
      isOpen:    false,
      triggerEl: null,
      popperEl:  null,
    };
  },
  beforeDestroy() {
    if (!this.popper) { return; }

    this.popper.destroy();
  },
  mounted() {
    this.triggerEl = this.$el.querySelector(`[data-popper-trigger="${this.popperId}"]`);
    this.popperEl  = this.$el.querySelector(`[data-popper-content="${this.popperId}"]`);
  },
  methods: {
    open() {
      if (this.isOpen) { return; }

      this.isOpen = true;
      this.$nextTick(this.setupPopper);
      this.$emit('open');
    },
    close() {
      if (!this.isOpen) { return; }

      this.$emit('close');
      this.isOpen = false;
    },
    setupPopper() {
      if (this.popper === undefined) {
        this.popper = createPopper(this.triggerEl, this.popperEl, this.config);
      } else {
        this.updatePopper();
      }
    },
    updatePopper() {
      this.popper.update();
    }
  },
  render() {
    return this.$scopedSlots.default({
      isOpen:       this.isOpen,
      open:         this.open,
      close:        this.close,
      updatePopper: this.updatePopper
    });
  },
};
</script>
