import { Controller } from "@hotwired/stimulus";
import Chart from "chart.js";

export default class extends Controller {
  static values = {
    canvasId: String,
    options: Object,
    locale: String
  }

  connect() {
    this.render();
  }

  render() {
    this.chart = new Chart(this.canvas, this.options);
  }

  get canvas() {
    return this.element.querySelector("canvas");
  }

  get options() {
    return this.optionsValue;
  }
}
