<template lang="pug">
.d-flex.justify-content-center
		input(
			hidden
			:id="`entry-boolean-modal-${entry.record.id}-${entry.field.id}`"
			type="checkbox"
			v-model="entry.value"
			@change="updateEntry(entry)"
		)
		label.boolean-label.editable.mb-0.pl-0(:for="`entry-boolean-modal-${entry.record.id}-${entry.field.id}`")
			i.checkbox-icon.far(:class="entry.value ? 'fa-check text-success' : 'fa-times text-danger times-icon'")
</template>

<script>
import { UpdateEntryMixin } from '../../../mixins/UpdateEntryMixin';

export default {
  mixins: [UpdateEntryMixin],
  props: {
    entry: {
      type: Object,
      required: true
    }
  }
};
</script>
