<template lang="pug">
div
  .form-group
    label(
      for="dashboard-block-setting-text"
    ) {{ $t('dashboardBlocks.text.labels.text') }}
    textarea.pt-1.form-control(
      rows=16
      id="dashboard-block-setting-text"
      v-model="editableSettings[`text_${locale}`]"
    )
</template>

<script>
import i18n from '../../../locales/locales.js';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    locale() {
      return i18n.locale;
    }
  }
}
</script>
