<template lang="pug">
div
  template(v-if="settingsAreReady")
    .dashboard-block--header
      h2 {{ dashboardBlock.title }}
    .dashboard-block--body.p-3.py-4.bg-white
      .px-3(v-if="currentSite")
        .d-flex.justify-content-end(v-if="currentUser.admin")
          a.mb-0.text-secondary(
            :href="'/views/' + downloadViewId + '/export_data/export.csv'"
          )
            i.far.fa-file-download.fa-fw
            small {{ $t('dashboardBlocks.flex_contacts.downloadContacts') }}
        .d-flex
          .form-group.mb-4.flex-grow-1
            label.text-secondary(:for="'site-' + dashboardBlockId") {{ $t('dashboardBlocks.flex_contacts.site') }}
            v-select(
              v-if="sites.length > 1"
              :id="'site-' + dashboardBlockId"
              v-model="selectedSite"
              :options="sites"
              :clearable="false"
            )
            p.text-dark(
              v-else
            ) {{ currentSite.site }}
        .d-flex.flex-wrap.mt-3
          .pr-2(style="flex: 50% 0 0;")
            FlexContacts(
              type="CALL"
              :items="phoneCalls"
              @add-item="appendNewItem"
              @edit-item="updateItem"
              @delete-item="deleteItem"
            )
          .pl-2(style="flex: 50% 0 0;")
            FlexContacts(
              type="SMS"
              :items="textMessages"
              @add-item="appendNewItem"
              @edit-item="updateItem"
              @delete-item="deleteItem"
            )
        .mt-4
          FlexContacts(
            style="flex: 100% 0 0;"
            type="MAIL"
            :items="emails"
            @add-item="appendNewItem"
            @edit-item="updateItem"
            @delete-item="deleteItem"
          )
      .flex-center(v-else-if="loaded")
        p.mb-0.font-italic {{ $t('dashboardBlocks.flex_contacts.noAssociatedSite') }}
      .flex-center(v-else)
        Loader
  .flex-center.h-100(v-else)
    div
      h2.text-center.text-warning
        i.fas.fa-exclamation-triangle.fa-1x
      p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}
</template>

<script>
import { mapState } from 'vuex';
import { api } from '../../api/client';
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import FlexContacts from './flex_contacts/FlexContacts.vue';

export default {
  mixins: [DashboardBlockMixin],
  components: {
    FlexContacts
  },
  data() {
    return {
      selectedSite: null,
      contacts: [],
      loaded: false
    }
  },
  computed: {
    ...mapState(['currentUser']),
    settingsAreReady() {
      return this.dashboardBlock &&
               this.dashboardBlock.kind_settings.flex_contacts_table_id &&
               this.dashboardBlock.kind_settings.download_view_id
    },
    sites() {
      return this.contacts.map(contact => contact.site);
    },
    currentSite() {
      return this.contacts.find(({ site }) => {
        return site === this.selectedSite
      });
    },
    textMessages() {
      if (this.currentSite) {
        return this.currentSite.contacts.filter((contact) => {
          return contact.name.match(/SMS/);
        });
      }
      return [];
    },
    phoneCalls() {
      if (this.currentSite) {
        return this.currentSite.contacts.filter((contact) => {
          return contact.name.match(/CALL/);
        });
      }
      return [];
    },
    emails() {
      if (this.currentSite) {
        return this.currentSite.contacts.filter((contact) => {
          return contact.name.match(/MAIL/);
        });
      }
      return [];
    },
    downloadViewId() {
      return this.dashboardBlock.kind_settings.download_view_id;
    }
  },
  watch: {
    settingsAreReady: function(areReady, wasReady) {
      if (areReady && !wasReady) {
        this.fetchContacts();
      }
    }
  },
  mounted() {
    if (!this.settingsAreReady) return;

    this.fetchContacts();
  },
  methods: {
    fetchContacts() {
      api.flexContacts.index({ dashboard_block_id: this.dashboardBlockId })
        .then(({ data: contacts }) => {
          this.contacts = contacts;
          if (contacts.length) {
            this.selectedSite = contacts[0].site;
            this.loaded = true;
          }
        });
    },
    appendNewItem({ type, value}) {
      const { dashboardBlockId: dashboard_block_id,
              currentSite: { site_record_id } } = this;

      api.flexContacts.create({
        dashboard_block_id,
        type,
        value,
        site_record_id
      }).then(({ data: newItem }) => {
          this.currentSite.contacts.push(newItem);
        });
    },
    updateItem({ contact_record_id, value, attribute }) {
      api.flexContacts.update({
        contact_record_id,
        value,
        attribute,
        dashboard_block_id: this.dashboardBlockId
      }).then(({ data }) => {
          const item = this.currentSite.contacts.find((contact) => {
            return contact.contact_record_id === contact_record_id;
          });
          item.tel = data.tel;
          item.email = data.email;
        });
    },
    deleteItem(item) {
      api.flexContacts.destroy({
        dashboard_block_id: this.dashboardBlockId,
        contact_record_id: item.contact_record_id
      }).then(({ data: contactData }) => {
        contactData.forEach(({ contact_record_id, name }) => {
          const contactToUpdate = this.currentSite.contacts.find((contact) => {
            return contact_record_id === contact.contact_record_id;
          });
          contactToUpdate.name = name;
        });
        this.currentSite.contacts = this.currentSite.contacts.filter((contact) => {
          return contact.contact_record_id !== item.contact_record_id;
        });
      });
    }
  },
}
</script>

<style lang="scss" scoped>
.site-detail {
  flex: 50% 0 0;
}
</style>
