<template lang="pug">
  .view-show
    template(v-if="!error")
      ViewControlBar
    .grid-wrapper-error(v-if="error")
      MainError
    .grid-wrapper-loading(v-else-if="!error && loading")
      .grid-loading
        Loader
    template(v-else)
      ViewGrid(
        v-if="currentView.type === 'grid' && !displayGallery"
      )
      ViewCalendar(
        v-if="currentView.type === 'calendar'"
      )
      ViewGallery(
        v-if="currentView.type === 'gallery' || displayGallery"
      )
      ViewForm(
        v-if="currentView.type === 'form'"
      )
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { gridNavigationMixin }  from "../mixins/gridNavigationMixin";
import { copyEntryMixin }       from "../mixins/copyEntryMixin";
import { pasteEntryMixin }      from "../mixins/pasteEntryMixin";

import ViewGrid        from "./ViewGrid.vue";
import ViewCalendar    from "./ViewCalendar.vue";
import ViewControlBar  from "./ViewControlBar.vue";
import ViewGallery     from "./ViewGallery";
import ViewForm        from "./ViewForm";
import MainError       from "./MainError.vue";

export default {
  mixins: [gridNavigationMixin, copyEntryMixin, pasteEntryMixin],
  components: {
    MainError,
    ViewCalendar,
    ViewControlBar,
    ViewGrid,
    ViewGallery,
    ViewForm,
  },
  computed: {
    ...mapState({
      currentView: state => state.viewStore.view,
      error:       state => state.error,
      mainPanelWidth: state => state.mainPanelWidth
    }),
    ...mapGetters({
      loading: "isLoading"
    }),
    displayGallery() {
      return this.currentView.type == 'grid' && this.mainPanelWidth < 768;
    }
  },
  created() {
    document.addEventListener('paste', this.pasteEntryValueFromClipBoard); // pasteEntryValueFromClipBoard is a method from pasteEntryMixin
  },
  beforeDestroy() {
    document.removeEventListener('paste', this.pasteEntryValueFromClipBoard); // pasteEntryValueFromClipBoard is a method from pasteEntryMixin
  }
}
</script>
