<template lang="pug">
  .mt-3
    h2 {{ $t('automations.labels.source') }}
    .form-group
      label {{ $t('automations.labels.module_number') }}
      v-select(
        :options="moduleInputPositions"
        label="name"
        v-model="moduleInputPosition"
      )

    .form-group
      label {{ $t('automations.labels.content') }}
      textarea.form-control(
        id="notification-content"
        ref="notificationContent"
        name="notification-content"
        class="automation-module--body"
        v-model="content"
      ) {{ content }}

    .form-group
      label
        | {{ $t('automations.labels.availableFields') }}
        small.ml-1 {{ $t('automations.labels.clickToInsert') }}
      .d-flex.flex-wrap
        .mr-2.cursor-pointer.hover-text-underline(
           v-for="field in fields"
           @click="addFieldToContent(field)"
           style="width: 200px;"
        ) {{ field.name }}

    h2.mt-4 {{ $t('automations.labels.recipients') }}
    .mb-3
      label {{ $t('automations.labels.profiles') }}
      div(v-for="(policyProfileDisplayName, policyProfileName) in PolicyProfileNamesByDisplayNames")
        input(
          type="checkbox"
          v-model="recipientsPolicyProfileNames"
          :id="'automation-module-' + automationModuleId + '-profile-' + policyProfileName"
          :value="policyProfileName"
        )
        label.ml-2(
          :for="'automation-module-' + automationModuleId + '-profile-' + policyProfileName"
        ) {{ policyProfileDisplayName }}

    .mb-3(v-if="showRecipientsFields")
      label {{ $t('automations.labels.recipientsField') }}
      .form-group
        v-select(
          :searching="true"
          :options="recipientFields"
          label="name"
          :reduce="option => option.id"
          v-model="recipientsFieldId"
        )

    .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
      button.btn.btn-primary(
        @click.stop="updateAutomationModule"
      ) {{ $t('save') }}
</template>

<script>
import { api }       from '../../../../api/client';
import RecordFilters from '../../../RecordFilters';
import Field         from '../../../../models/field';
import { InsertFieldNameMixin } from '../../../../mixins/insertFieldNameMixin';

export default {
  components: {
    RecordFilters
  },
  mixins: [InsertFieldNameMixin],
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    tableId: {
      type: Number,
      required: true,
    },
    configParams: {
      type: Object,
      required: true,
    },
    automationModulePosition: {
      type: Number,
      required: true,
    },
    PolicyProfileNamesByDisplayNames: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      fields: [],
      recipientsPolicyProfileNames: [],
      recipientsFieldId: null,
      showRecipientsFields: false,
      content: null,
      moduleInputPositions: [],
      moduleInputPosition: null,
    }
  },
  created() {
    this.content = this.configParams.notification?.content || '';
    this.recipientsPolicyProfileNames = this.configParams.recipients?.from_policy_profile_names || [];
    this.recipientsFieldId = this.configParams.recipients?.from_field_id;
    this.moduleInputPosition = this.configParams.module_input_position || 1;
    this.moduleInputPositions = Array.from({ length: this.automationModulePosition - 1 }, (_, i) => i + 1)
    this.loadTableSchemaForFields();
    this.loadTableSchemaForRecipientsFieldId();
  },
  methods: {
    loadTableSchemaForFields() {
      if (!this.tableId) return;

      api.fields.index({ tableId: this.tableId }).then(({ data }) => {
        this.fields = Field.build(data);
        this.content = this.insertFieldNameInContent(this.content, this.fields);
      });
    },
    loadTableSchemaForRecipientsFieldId() {
      if (!this.tableId) return;

      api.fields.index({
        tableId: this.tableId,
      }).then((response) => {
        this.showRecipientsFields = false
        setTimeout(() => {
          this.recipientFields = Field.build(response.data);
          this.recipientsFieldId = this.fields.filter(field => this.recipientsFieldId === field.id)[0];
          this.showRecipientsFields = true;
        });
      });
    },
    updateAutomationModule() {
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        configParams: {
          module_input_position: this.moduleInputPosition,
          recipients: {
            from_policy_profile_names: this.recipientsPolicyProfileNames,
            from_field_id: this.recipientsFieldId,
          },
          notification: { content: this.content },
          table_id: this.tableId,
        },
      });
    },
    addFieldToContent(field) {
      if(this.$refs.notificationContent) {
        const { selectionStart, selectionEnd } = this.$refs.notificationContent;
        this.content = this.content.slice(0, selectionStart) +
                         `{${field.name}}` +
                         this.content.slice(selectionEnd);
      }
    },
  }
}
</script>
