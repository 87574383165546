import { apiClient, startFetch, endFetch } from '../client';

export default {
  create({ id: recordId }, form) {
    return apiClient.post(`/api/records/${recordId}/repeatable_records`, { repeatable_record: form })
  },
  delete({ recordId, destroyRepetitionsOption = null, includeChildrenRecords = false }) {
    const saver = startFetch('SAVING');
    return apiClient.delete(
      `/api/records/${recordId}/repeatable_records`,
      {
        params: {
          destroy_repetitions: destroyRepetitionsOption,
          include_children_records: includeChildrenRecords,
        }
      }
    ).then(endFetch(saver))
  },
  repetitions({ recordId }) {
    return apiClient.get(`/api/records/${recordId}/repeatable_records/repetitions`)
  },
}
