<template lang="pug">
.d-flex
  p.mb-0.mx-4.filter-input-min-width {{ $t('is') }}
  .d-flex.justify-content-center.align-items-center
    .boolean-label.editable.mb-0(@click="filter.value = !filter.value")
      i.checkbox-icon.far(:class="filter.value ? 'fa-check text-success' : 'fa-times text-danger times-icon'")
</template>

<script>
import { RecordFilterMixin } from '../../mixins/RecordFilterMixin';

export default {
  mixins: [RecordFilterMixin]
}
</script>
