<template lang="pug">
  .mt-3
    .form-group
      label {{ $t('automations.labels.table') }}
      v-select(
        :searching="true"
        :options="tablesProp"
        label="name"
        :reduce="option => option.id"
        v-model="tableId"
      )

    .form-group(v-if="tableId && showFilters")
      label {{ $t('automations.labels.conditions') }}
      RecordFilters(
        :fields="fields"
        :originalQuery="query"
        @save-query="updateAutomationModule"
      )
</template>

<script>
import { api }       from '../../../../api/client';
import RecordFilters from '../../../RecordFilters';
import Field         from '../../../../models/field';

export default {
  components: {
    RecordFilters
  },
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    projectUsers: {
      type: Array,
      required: true,
    },
    tablesProp: {
      type: Array,
      required: true,
    },
    filtersProp: {
      type: Array,
      required: true,
    },
    tableIdProp: {
      type: Number,
      required: true,
    },
    configParamsProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId:     null,
      fields:      [],
      query:       { logicalOperator: null, filters: [] },
      showFilters: false,
    }
  },
  watch: {
    tableId: function(newTableId, oldTableId) {
      if(oldTableId !== newTableId) {
        this.loadTableSchema()
      }
    }
  },
  created() {
    this.loadTableSchema();
    this.tableId               = this.tableIdProp;
    this.query.logicalOperator = this.configParamsProp.logical_operator || 'and';
    this.setQueryFilters();
  },
  methods: {
    loadTableSchema() {
      if (!this.tableId) return;

      api.fields.index({
        tableId: this.tableId,
      }).then((response) => {
        this.showFilters = false
        setTimeout(() => {
          this.fields = Field.build(response.data);
          this.setQueryFilters();
          this.showFilters = true;
        });
      });
    },
    setQueryFilters() {
      const fieldIds     = this.fields.map(field => field.id);
      this.query.filters = this.filtersProp.filter(filter => fieldIds.includes(filter.field_id));
    },
    updateAutomationModule(queryPayload) {
      this.saving = true;
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        kind:               'when_record_matches_conditions',
        configParams:       { logical_operator: queryPayload.logicalOperator },
        tableId:            this.tableId,
        filters:            queryPayload.filters,
      }).then(() => {
        this.query = queryPayload;
      });
    },
  }
}
</script>
