import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["periodOptionInput", "dateRangeInput", "startDateInput", "endDateInput"];
  static values = {
    settings: Object,
    periodOptions: Object,
    maxDays: Number,
    maxDate: String,
  };

  form() {
    return this.startDateInputTarget.form;
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    return [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join('-');
  }

  updateRange({ detail: { selectedDates } }) {
    if (selectedDates.length < 2) {
      this.dateRangeInputTarget._flatpickr.config.minDate = null;
      this.dateRangeInputTarget._flatpickr.config.maxDate = this.maxDateValue;

      return;
    }

    const newStartDate = this.formatDate(selectedDates[0]);
    const newEndDate = this.formatDate(selectedDates[1]);

    if (this.startDateInputTarget.value === newStartDate && this.endDateInputTarget.value === newEndDate) return;

    this.startDateInputTarget.value = newStartDate
    this.endDateInputTarget.value = newEndDate;
    if (this.hasPeriodOptionInputTarget) this.periodOptionInputTarget.value = "";

    this.dispatch("updated");
  }

  changePeriodOption() {
    const periodOption = this.periodOptionInputTarget.value;
    const selectedDates = this.periodOptionsValue[periodOption];

    this.startDateInputTarget.value = selectedDates[0];
    this.endDateInputTarget.value = selectedDates[1];
    this.dateRangeInputTarget._flatpickr.setDate(selectedDates);

    this.dispatch("updated");
  }

  submitForm() {
    // <form>.requestSubmit() is not supported on Safari prior to version 16 (2022/09/12),
    // but requestSubmit triggers html form validations, which are nice for this form,
    // so if available, use requestSubmit, else fallback to classic submit.
    // NB: even on Safari 16, requestSubmit does not support passing a submit btn
    // to simulate a submit from this btn.
    // NB2: feels like Safari wanna play IE style ...

    if (this.form().requestSubmit) {
      this.form().requestSubmit();
    } else {
      this.form().submit();
    }
  }

  changeDate({ detail: { selectedDates } }) {
    if (this.maxDaysValue === 0) return;

    if (selectedDates.length == 1) {
      const startDate = selectedDates[0];
      let endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + this.maxDaysValue - 1);

      this.dateRangeInputTarget._flatpickr.config.minDate = startDate.toUTCString();
      if (endDate <= this.dateRangeInputTarget._flatpickr.config.maxDate) {
        this.dateRangeInputTarget._flatpickr.config.maxDate = endDate.toUTCString();
      }
    } else {
      this.dateRangeInputTarget._flatpickr.config.minDate = null;
      this.dateRangeInputTarget._flatpickr.config.maxDate = this.maxDateValue;
    }
  }
}
