export const selectListMixin = {
  directives: {
    selectListElement: {
      bind(el, binding, vnode) {
        el.classList.add('select-list-element');
        el.classList.add(`element-name-${vnode.context.elementObjectName}`);
        el.dataset.selectListElementId = binding.value[vnode.context.elementIdentifier];
        el.addEventListener('click', vnode.context.selectListElementOnClick);
        el.addEventListener('mousemove', vnode.context.selectListElementOnMouseMove);
      },
      unbind(el, _binding, vnode) {
        el.removeEventListener('click', vnode.context.selectListElementOnClick);
        el.removeEventListener('mousemove', vnode.context.selectListElementOnMouseMove);
      },
      componentUpdated(el, _binding, vnode) {
        if (el.dataset.selectListElementId == vnode.context.selectedElementId) {
          el.classList.add('selected')
        } else {
          el.classList.remove('selected')
        }
      }
    }
  },
  data() {
    return {
      filterElementsInput:     '',
      selectedElementId:       null,
      elementIdentifier:       'id',
      elementFilteredProperty: 'name',
      elementObjectName:       ''
    };
  },
  computed: {
    selectedElement() {
      return this.selectListElements.find(element => element[this.elementIdentifier] === this.selectedElementId);
    },
    currentIndex() {
      return this.selectListElements.findIndex(element => element[this.elementIdentifier] === this.selectedElementId);
    },
    filteredElements() {
      if (this.selectListElements.length) {
        return this.selectListElements.filter(element => {
          return element[this.elementFilteredProperty]
            .toLowerCase()
            .includes(this.filterElementsInput.toLowerCase());
        });
      } else {
        return this.selectListElements;
      }
    }
  },
  created() {
    document.addEventListener("keydown", this.componentKeydownEventCallback);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.componentKeydownEventCallback);
  },
  methods: {
    selectListElementOnClick(event) {
      event.stopPropagation();
      this.selectElement('click');
    },
    selectListElementOnMouseMove(event) {
      this.selectedElementId = Number.parseInt(event.currentTarget.dataset.selectListElementId, 10);
    },
    componentKeydownEventCallback(event) {
      if (this.componentIsOnForeground) {
        switch (event.key) {
          case "Enter":
            this.selectElement('enter');
            break;
          case "ArrowDown":
            this.selectNextElement();
            break;
          case "ArrowUp":
            event.preventDefault();
            this.selectPreviousElement();
            break;
        }
      }
    },
    selectElement(eventType) {
      this.elementSelected(this.selectedElement, eventType);
    },
    elementSelected(_element, _eventType) {
    },
    selectNextElement() {
      const currentIndex = this.filteredElements.findIndex(element => element[this.elementIdentifier] === this.selectedElementId);
      this.selectHTMLElement(currentIndex + 1 === this.filteredElements.length ? 0 : currentIndex + 1);
    },
    selectPreviousElement() {
      const currentIndex = this.filteredElements.findIndex(element => element[this.elementIdentifier] === this.selectedElementId);
      this.selectHTMLElement(currentIndex - 1 === -1 ? this.filteredElements.length - 1 : currentIndex - 1);
    },
    selectHTMLElement(index) {
      this.selectedElementId = this.filteredElements[index][this.elementIdentifier];
      const element = document.querySelector(
        `.select-list-element.element-name-${this.elementObjectName}[data-select-list-element-id="${this.selectedElementId}"]`
      );
      element.scrollIntoView({ block: "nearest" });
    },
    cleanSelectList() {
      this.selectedElementId   = null;
      this.filterElementsInput = '';
    }
  },
  watch: {
    filterElementsInput() {
      this.$nextTick(() => {
        const filteredElement  = this.filteredElements[0];
        this.selectedElementId = filteredElement ? filteredElement[this.elementIdentifier] : null;
      });
    }
  }
};
