<template functional lang="pug">
  .cell-content {{ props.entry.value }}
</template>

<script>
  export default {
    functional: true,
    props: {
      entry: Object
    },
    render(h, ctx) {
      const entry = ctx.props.entry
    }
  }
</script>
