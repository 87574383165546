import { units } from "./units";

export const fr = {
  units,
  true: "Oui",
  false: "Non",
  yes: "Oui",
  no: "Non",
  create: "Créer",
  apply: "Appliquer",
  continue: "Continuer",
  update: "Modifier",
  cancel: "Annuler",
  back: "retour",
  close: "Fermer",
  save: "Sauvegarder",
  saved: "Sauvegardé",
  delete: "Supprimer",
  confirm: "Confirmer",
  deny: "Décliner",
  add: "Ajouter",
  days: "jours",
  and: "et",
  or: "ou",
  is: "est",
  at: "à",
  rename: "Renommer",
  tables: "Tables",
  table: "Table",
  view: "Vue",
  field: "Champ",
  dashboards: "Dashboards",
  folder: "Dossier",
  home: "Accueil",
  workspace: "Espace de travail",
  newTable: "Nouvelle table",
  seeMoreDetails: "Voir plus",
  no_alerts: "Aucune alerte",
  history: "Historique",
  selectAll: "Tout sélectionner",
  selectNothing: "Tout désélectionner",
  selectRecord: "Sélectionner l'entrée",
  noOptionForSelect: "Aucun résultat",
  recordWillBeFiltered: "Cette entrée ne sera pas visible étant donnés les filtres de cette vue.",
  send: "Envoyer",
  submit: "Soumettre",
  preview: "Prévisualiser",
  addNewField: "Ajouter une nouvelle colonne",
  addReferenceRecord: "Ajouter une entrée",
  addExistingReferenceRecord: "Ajouter une entrée existante",
  createNewRecord: "Créer une nouvelle entrée",
  searchReference: "Rechercher une entrée",
  searchField: "Rechercher un champ",
  noRecordsFound: "Aucune entrée trouvée",
  noRecords: "Aucune entrée",
  current_user: "Utilisateur courant",
  addUserRecord: "Ajouter un utilisateur",
  searchUser: "Rechercher un utilisateur",
  noUsersFound: "Aucun utilisateur trouvé",
  hiddenFields: "champ masqué | champs masqués",
  lockedRecordInfo: "Cette entrée est verrouillée et ne peux être ni supprimée ni éditée.",
  updateForbidden: "Cette modification est interdite car elle ne respecte pas les droits d'utilisation de votre profil.",
  forbidden: "Vous n'êtes pas autorisé à effectuer cette action.",
  mainErrorMsg: "Désolé, il y a eu un problème...",
  tryToReload: "Essayer de recharger la page",
  default: "Par défaut",
  SUM: "Somme",
  AVG: "Moyenne",
  MEDIAN: "Mediane",
  days: "Jours",
  weeks: "Semaines",
  month: "mois",
  times: "fois",
  loading: "Chargement",
  preview: "Prévisualiser",
  addAttribute: "Ajouter un attribut",
  occurrences: {
    every_day: "Tous les jours",
    every_week: "Toutes les semaines",
    every_two_week: "Toutes les deux semaines",
    every_month: "Tous les mois",
    custom: "Personnalisé",
    every: "Tous les"
  },
  repeatable_records: {
    occurrence: "occurrence",
    repeat_record: "Répéter l'entrée",
    repeat_this_record: "Répéter cette entrée",
    use_the_field: "Utiliser le champ",
    for_repeat: "pour la répétition",
    of_children_records: "des",
    include_children_records: "Inclure les",
    no_value_for_repetition: "L'entrée ne peut pas être répétée car le champ date n'est pas renseigné.",
    confirmation: "En confirmant, vous allez créer {occurrences} entrée{plural} pour l'entrée : <span class=\"text-info\">{recordDisplayName}</span>, avec la fréquence suivante : <i>{frequency}</i> , êtes-vous sûr ?",
    children_records_confirmation: "</br></br>Vous allez également créer {numberOfChildrenRecords} entrée{plural} sur la table <strong>{childTableName}</strong>."
  },
  deleteRecordOptions: {
    deleteOptions: "Options de suppression",
    forRecurringEvent: "Pour l'événement périodique",
    thisEvent: "Cet événement",
    thisAndTheFollowingEvents: "Cet événement et les suivants",
    allEvents: "Tous les événements",
    forParentRecord: "Pour l'entrée parente",
    WithoutChildrenRecords: "Sans les {childTableName} associées",
    WithChildrenRecords: "Avec les {childTableName} associées",
  },
  fields: {
    primaryTooltip: "Le champ primaire est utilisé comme nom d'affichage pour les références.",
    noForeignTableId: "Impossible de chercher des entrées.<br/>Vérifiez les réglages du champ.",
    readAccess: "Champ en lecture seule",
    writeAccess: "Champ en écriture",
    system: "Champ système",
    useToRepeatRecords: "Utiliser pour répéter des entrées ?",
    currentTemplate: "template actuel ",
    newTemplate: "nouveau template ",
    none: "aucun",
  },
  routes: {
    views: "vues"
  },
  models: {
    record: {
      noDisplayName: "Entrée sans nom",
    }
  },
  saving: "Sauvegarde",
  controlBarActions: {
    getZipFile: "Télécharger les PJ",
    filtersApplied: "Filtres appliqués",
    filter: "Filtrer",
    noFilter: "Aucun filtre appliqué",
    noAttribute: "Aucun attribut",
    addFilter: "Ajouter un filtre",
    restoreFilters: "Restaurer les filtres",
    restoreSorts: "Restaurer le tri",
    filterBy: "Filtrer par",
    filteredBy: "Filtrée par",
    tablePrefiltered: "Cette table est pré-filtrée (cliquer pour afficher)",
    updateViewFilters: "Sauvegarder",
    newRecordCreated: "Nouvelle entrée créée",
    addRecord: "Nouvelle Entrée",
    sort: "Trier",
    noSorting: "Aucun tri appliqué",
    createView: "Dupliquer",
    showHide: "Masquer",
    download: "Télécharger",
    editViewDescription: "Éditer la description de la vue",
    editViewOptions: "Éditer les options de la vue",
    newView: "Ajouter une nouvelle vue",
    calendar: "Calendrier",
    gallery: "Galerie",
    grid: "Tableur",
    board: "Kanban",
    form: "Formulaire",
    searchView: "Rechercher une vue",
    downloadToCalendarFormat: "Télécharger au format calendrier",
    importData: "Importer des données",
    applyEverywhereThisViewFieldsDisplay: "Appliquer partout cette disposition des colonnes",
    dissociateView: "Dissocier la vue",
    deleteTable: "Supprimer la table",
    cantDeleteLastView: "Impossible de dissocier la dernière vue d'une table.",
    cantDeleteLastTable: "Impossible de supprimer la dernière table.",
    lockView: "Vue déverrouillée. Cliquer pour verrouiller la vue.",
    unlockView: "Vue verrouillée. Cliquer pour déverrouiller la vue.",
    lockedView: "Vue verrouillée",
    editRecordValidations: "Validation sur les entrées",
    saved: "Sauvegardé !",
  },
  calendarNav: {
    today: "Aujourd'hui",
    month: "Mois",
    week: "Semaine",
    selectedCalendars: "Calendriers sélectionnés",
  },
  recordModal: {
    alreadyOpened: "Cette entrée est déjà ouverte.",
    noParentRecord: "Pas d'entrée parente associée à partir du champ {fieldName}.",
    associateParentRecord: "Associer une entrée parente",
    confirmNoCreation: "Cette entrée n’est pas enregistrée dans la table.</br><strong>Voulez-vous créer cette entrée ?</strong></br><i>Cette entrée sera perdue si vous ne la créez pas.</i>",
  },
  calendarModal: {
    newCalendarView: "Nouvelle vue calendrier",
    editCalendarView: "Modification du calendrier",
    startAtField: "Colonne pour la date de début",
    endAtField: "Colonne pour la date de fin",
    title: "Colonne pour le titre de chaque événement",
    reference: "Colonne pour filtrer les calendriers",
    details: "Colonne pour les détails",
    endDateOption: "Mode de fin d'événement",
    range: "Période temporelle",
    end_date_field: "Champ de date de fin",
    location: "Colonne pour le lieu",
    organizer_email: "Colonne pour l'email de l'organisateur",
    organizer_name: "Colonne pour le nom de l'organisateur",
    attendees: "Colonne pour les invités",
  },
  dashboard: {
    addBlock: "Ajouter un module",
    editMode: "Mode édition",
    endEditMode: "Terminer l'édition",
    blockSettings: "Paramètres du module",
    blockName: "Nom du module",
    newBlock: "Nouveau module",
    confirmDeleteBlock: "Supprimer le module ?",
    details: "Détails",
    display_period_selector: "Afficher le datepicker"
  },
  dashboardBlockKinds: {
    alerts: "Alertes",
    calendar: "Calendrier",
    chart: "Graphique standard",
    flex_contacts: "Contacts FLEX",
    kpi: "KPI",
    list: "Liste",
    live: "En direct",
    load_shift: "Effacements",
    map: "Carte",
    ncclist: "NCC",
    peakperiod: "Jours PP2",
    regionalmap: "Régions",
    reportextract: "Rapport",
    schedule: "Agenda",
    sourced_chart: "Graphique avec sources",
    sourced_kpi: "KPI avec sources",
    standalonekpi: "KPI brut",
    stat: "Statistique",
    table: "Tableau",
    text: "Texte",
    timescale: "Graphique",
  },
  dashboardBlocks: {
    noInfo: "Aucune information disponible",
    backToList: "Retour à la liste",
    blockIsNotConfigured: "Ce module nécessite d'être configuré.",
    apiError: "Données temporairement indisponibles, veuillez réessayer plus tard.",
    noSitesSelected: "Aucun site sélectionné",
    alerts: {
      level: "Niveau",
      more: "de plus",
      negative_price: "Alerte de prix négatif en cours",
      noAlerts: "Aucune alerte en cours"
    },
    kpi: {
      labels: {
        period: "Période",
        api_source_id_field_id: "Champ pour l'id de la source API",
        kind_technology_field_id: "Champ pour le type de technologie",
        kind: "Type de données",
        data_contracts_table_id: "Champ pour la table Données contractuelles",
        engagement_value_field_id: "Champ pour la valeur de l'engagement de puissance",
        engagement_kind_field_id: "Champ pour le type d'engagement",
        wiped_power_field_id: "Champ pour la puissance effacée",
        trigger_power_field_id: "Champ pour la puissance talon",
        performance_table_id: "Table pour les contrats ENR",
        performance_value_field_id: "Champ pour la performance de référence",
        start_date_field_id: "Champ pour la date de début de contrat",
        end_date_field_id: "Champ pour la date de fin de contrat",
        solar_installed_capacity_field_id: "Champ pour la valeur de capacité installée PV",
        other_installed_capacity_field_id: "Champ pour la valeur de capacité installée autres technos",
        referenced_site_field_id: "Champ pour les sites référencés"
      },
      dataTypes: {
        production: "Production",
        contract: "Données contractuelles",
        enr_performance: "Performance ENR"
      }
    },
    nccList: {
      site: "Site",
      ncc: "NCC",
      certifiedCapacityLevel: "Niveau de Capacité Certifié",
    },
    labels: {
      additional_params: "Paramètres additionnels",
      advanced_params: "Paramètres avancés",
      api_source_id_field_id: "Champ pour l'id de la source API",
      autoResize: "Taille auto.",
      calendar_id: "Module calendrier pour les éléments à afficher",
      color_field_id: "Champ pour la couleur",
      concerned_months: "Mois de fonctionnement (Tous si vide)",
      concerned_table_ids: "Tables à inclure",
      default_description_field_id: "Description par défaut",
      default_title_field_id: "Titre par défaut",
      description_field_id: "Champ pour la description",
      disabled_on_holidays: "Désactivé pour les vacances et jours fériés",
      enabled_only_on_weekdays: "Activé seulement pour les jours de semaine",
      end_date_field_id: "Champ pour la date de fin",
      icon_field_id: "Champ pour l'icône",
      label_field_id: "Champ pour le label",
      level_color: "Couleur du niveau {level}",
      level_name: "Nom du niveau {level}",
      level_view_ids: "Vues de niveau {level}",
      list_alert_default_level: "Niveau d'alerte par défaut de la liste",
      list_id: "Module liste pour les éléments à afficher",
      locked_title: "Titre verrouillé ?",
      reference_field_id: "Champ pour la référence",
      schedule_id: "Module agenda pour les éléments à afficher",
      select_source_to_configure: "Sélectionner une source pour la configurer",
      select_table_to_configure: "Sélectionner une table pour la configurer",
      separated_by_commas: "séparés par des virgules",
      start_date_field_id: "Champ pour la date de début",
      table_alert_default_level: "Niveau d'alerte par défaut de la table",
      table_id: "Table",
      title_field_id: "Champ pour le titre",
      view_id: "Vue",
      styleTypes: {
        relative: "Relatif",
      }
    },
    list: {
      displayAllDetails: "Détails",
      displayAllMaintenances: "maintenances",
      yes: "oui",
      no: "non",
      inProgress: "En cours",
      labels: {
        table_id: "Table",
        view_id: "Vue",
        title_field_id: "Champ pour le titre",
        description_field_id: "Champ pour la description",
        technology_field_id: "Champ pour la technologie",
        icon_field_id: "Champ pour l'icône",
        image_field_id: "Champ pour l'image",
        color_field_id: "Champ pour la couleur",
        list_additional_field_ids: "Champs additionels pour la liste",
        details_additional_field_ids: "Champs additionels pour les détails",
        disable_sorts_and_filters: "Désactiver les tris et filtres",
        disable_item_select: "Désactiver la sélection d'éléments",
        disable_details: "Désactiver le détail",
        associations_table_id: "Table pour les associations",
        principal_table_id: "Table principale",
        associations_view_id: "Vue pour les associations",
        associations_reference_field_id: "Champ pour les associations",
        associations_reference_field_id_site: "Champ pour les associations de site de prod",
        associations_reference_field_id_perf: "Champ pour les associations de performance",
        associations_reference_field_id_date: "Champ pour les associations de date",
        main_reference_field_id_start_date: "Champ pour la date de début de prix négatif",
        filtered_field_ids: "Champs pouvant être filtrés",
        parent_list: "Module liste parent pour les préfiltres",
        parent_list_reference_field_id: "Champ pour les filtres de la liste parente",
        api_source_id_field_id: "Champ pour l'id de la source API",
        add_margin: "Ajout d'espace à droite de la liste",
        year_field_id: "Champ pour l'année",
        ncc_field_id: "Champ pour la valeur de NCC",
        site_name_field_id: "Champ pour le nom du site",
      }
    },
    reportextract: {
      kinds: {
        performances: "Rapport de performances",
        negative_price_monitoring: "Suivi des prix négatifs",
        flex_activity: "Activités et Indisponibiltés FLEX",
      },
      labels: {
        report_kind: "Type de rapport",
        sites_table_id: "Table pour les sites",
        contracts_table_id: "Table pour les contrats",
        collects_table_id: "Table pour les fiches de collecte",
        site_name_field_id: "Champ pour le nom du site",
        site_file_name_field_id: "Champ pour le nom de fichier du site",
        site_source_id_field_id: "Champ pour l'id OPINUM",
        site_card_id_field_id: "Champ pour CARD ID",
        site_technology_field_id: "Champ pour la technologie",
        contract_site_reference_field_id: "Champ pour la référence site",
        contract_target_field_id: "Champ pour la performance cible (€/MWh)",
        contract_start_date_field_id: "Champ pour la date de début de contrat",
        contract_end_date_field_id: "Champ pour la date de fin de contrat",
        monitoring_table_id: "Table pour les suivis",
        site_reference_field_id: "Champ pour la référence au site",
        card_id_field_id: "Champ pour CARD ID",
        start_of_period_field_id: "Champ pour l'heure de prix négatif",
        first_half_hour_production_field_id: "Champ pour la première demi-heure de production",
        last_half_hour_production_field_id: "Champ pour la seconde demi-heure de production",
        total_production_field_id: "Champ pour le total de la production",
        bonus_field_id: "Champ pour la prime",
        comment_field_id: "Champ pour le commentaire",
        responsability_field_id: "Champ pour la responsabilité",
        calendar_id: "Module calendrier pour l'année",
        first_day_of_week_field_id: "Champ pour le premier jour de la semaine",
      },
    },
    live: {
      since: "Depuis",
      noCurrentEvents: "Aucun événement en cours",
      labels: {
        maxNumberOfLines: "Nombre de lignes maximum (Taille auto.)"
      }
    },
    calendar: {
      availabilities: "Disponibilités",
      unavailabilities: "Indisponibilités",
      activations: "Activations",
      negative: "Prix Négatif",
      negatives: "Production sur prix négatif",
      notifiedOn: "notifié le",
      downloadReport: "Télécharger le rapport",
      labels: {
        calendar_type: "Type de calendrier",
        peak_periods_table_id: "Table des alertes PP1/PP2",
        peak_periods_one_field_id: "Champ pour PP1",
        peak_periods_two_field_id: "Champ pour PP2",
        peak_periods_period_field_id: "Champ pour la date",
        activations_table_id: "Table pour les effacements",
        activations_site_field_id: "Champ pour le site",
        activations_period_field_id: "Champ pour l'heure d'effacement",
        activations_file_field_id: "Champ pour le document associé",
        activations_notification_date_field_id: "Champ pour l'heure de notification d'effacement",
        unavailabilities_table_id: "Table pour les indisponibilites",
        unavailabilities_table_site_field_id: "Champ pour le site",
        unavailabilities_period_field_id: "Champ pour la periode d'indisponibilité"
      },
      legend: {
        pp2: "PP2",
        pp1pp2: "PP1/PP2",
        dayWithActivations: "Jour avec activation",
        sitesAvailability: "Disponibilité des sites",
        dayWithNegativePrice: "Jour avec prix négatif",
        sitesWithoutProductionRatio: "Ratio des sites sans production",
      }
    },
    map: {
      default_coordinates: [2.213749, 46.227638],
      labels: {
        longitude_field_id: "Champ pour la longitude",
        latitude_field_id: "Champ pour la latitude",
        title_field_id: "Champ pour le titre",
        detail_field_id: "Champ pour le détail",
        alert_id: "Module \"Alertes\" pour les éléments à afficher",
      },
      errors: {
        listIsNotConfigured: "Le module liste n'est pas correctement configuré"
      }
    },
    timescale: {
      download: "Télécharger",
      labels: {
        period: "Sélectionnez une période",
        year: "Sélectionnez une année de livraison",
        color: "Champ pour la couleur de la courbe graphique",
        chart_kind: "Type de graphique",
        datatype: "Type de données",
        select_year: "Sélectionner l'année",
        aggregating_field_id: "Champ pour l'agrégation des données",
        load_shift_date_field_id: "Champ pour la date des effacements",
        y_axis_field_id: "Champ pour l'aggregation des données en ordonnées",
        keep_empty_cell: "Conserver le compte des cellules vides",
        calculation_type: "Type d'agrégat",
        computation_mode: {
          difference: "MWh",
          ratio: "%",
          assets: "Par entité",
          technologies: "Par technologies",
          customers: "Par clients",
          aggregated: "Agrégé",
        },
        aggregated: {
          true: "Courbe agrégée",
          false: "Courbe par site",
          entities: {
            true: "Courbe agrégée",
            false: "Courbe par entité",
          },
        },
        contract_start_date_field_id: "Date de début du contrat",
        contract_end_date_field_id: "Date de fin du contrat",
        unique_element: "L'entrée sélectionnée doit-elle être unique ?"
      }
    },
    peakperiod: {
      header: "Jours & prévisions PP1/PP2",
      labels: {
        table_id: "Table des alertes PP1/PP2",
        view_id: "Vue",
        date_field_id: "Champ pour la date",
        pp1_value_field_id: "Champ pour la valeur PP1",
        pp2_value_field_id: "Champ pour la valeur PP2",
        estimated_value_field_id: "Champ pour le risque PP1/PP2",
      },
      today: "Aujourd'hui",
      tomorrow: "Demain",
      confirmed: "Signalement PP1/PP2",
      prevision: "Risque journée signalée",
      source: {
        confirmed: "Source : RTE",
        prevision: "% de risque d'occurrence de jours PP1 / PP2, calculé par TEFP"
      },
      ecowatt: "Ecowatt",
      ecowatt_not_declared: "Pas de déclaration",
      ecowatt_no_alert: "Pas d'alerte",
      ecowatt_level1: "Journée tendue",
      ecowatt_level2: "Journée très tendue"
    },
    capacityAuction: {
      labels: {
        table_id: "Table",
        view_id: "Vue",
        year_field_id: "Champ pour l'année de livraison",
        date_field_id: "Champ pour la date",
        capacity_auction_value_field_id: "Champ pour la valeur de l'enchères",
      },
    },
    load_shift: {
      objective: "Objectif",
      average_consumption: "Consommation moyenne",
      average_load_shift: "Effacement moyen",
      consumed: "Consommé",
      erased: "Effacé",
      reference_power: "Puissance de référence"
    },
    regionalMap: {
      header: "Reporting régional en MWh",
      Biomass: "Biomasse",
      Hydro: "Hydro",
      Wind: "Éolien",
      Solar: "Solaire",
      CogenGas: "Cogénération à gaz",
      CogenWaste: "Cogénération à déchets",
    },
    flex_contacts: {
      eda: "EDA",
      entity: "Entité",
      site: "Site",
      CALL: "Appels",
      SMS: "SMS",
      MAIL: "Emails",
      noAssociatedSite: "Vous n'êtes rattaché à aucun site.",
      downloadContacts: "Télécharger tous les contacts",
      invalidFormat: "Format non valide",
      labels: {
        flex_contacts_table_id: "Table pour l'export Excel des contacts FLEX",
        download_view_id: "Vue pour l'export Excel"
      }
    },
    sourcedChart: {
      displayAsLine: "Afficher en courbe",
      forceAggregation: "Forcer l'agrégation",
      realTime: "Temps réel",
      labels: {
        sources: "Choix des sources à afficher",
        title: "Champ pour le titre",
        api_id: "Champ pour l'id de la source API",
        type: "Champ pour le type Vertical Power",
        technology: "Champ pour la technologie",
        customer: "Champ pour le client",
        program_to_display: "Programme à afficher"
      },
      sources: {
        "auto_grid/real_time_active_power": "Temps réél - Puissance active",
        "vertical_power/available_power": "Puissance disponible",
        "vertical_power/day_ahead_commitment": "Engagement Day Ahead",
        "vertical_power/client_program": "Programme client",
        "vertical_power/day_ahead_market_price": "Prix de marché Day Ahead",
        "vertical_power/estimated_revenues": "Revenus estimés",
        "vertical_power/forecast": "Prévisions de production",
        "vertical_power/imbalance": "Imbalance",
        "vertical_power/intraday_commitment": "Engagement intraday",
        "vertical_power/intraday_market_price": "Prix de marché intraday",
        "vertical_power/measured_production": "Production mesurée",
        "vertical_power/monthly_imbalance_and_revenues_kpi": "Only KPI - Déséquilibres et revenus mensuels",
        "vertical_power/output_generation": "Puisance de sortie",
        "vertical_power/power_factor": "Facteur de puissance",
        "vertical_power/reactive_power": "Puissance réactive",
        "vertical_power/active_power": "Puissance active",
        "vertical_power/ree_limitations": "Limitations"
      }
    },
    text: {
      labels: {
        text: "Texte",
      },
    },
  },
  galleryModal: {
    newGalleryView: "Nouvelle vue galerie"
  },
  gridModal: {
    newGridView: "Nouvelle vue tableur"
  },
  formModal: {
    newFormView: "Nouvelle vue formulaire"
  },
  gallery: {
    focus: "Agrandir"
  },
  views: {
    scope: "Visibilité",
    profileViewCreationNotAllowed: "Vous pouvez uniquement créer des vues personnelles",
    selectSort: "Choisissez un champ à trier",
    entrySelectDefault: "Choisissez une option",
    sortBy: "Trier par",
    thenBy: "puis par",
    createViewPlaceholder: "Nom de la vue",
    createView: "Créer la vue",
    updateView: "Mettre à jour la vue",
    profileView: "Vue de profil",
    alert: "Alerte",
    showAll: "Tous",
    hideAll: "Aucun",
    records: "Entrée | Entrées",
    addRecord: "Nouvelle entrée",
    editRecord: "Modifier l'entrée",
    foreignRecordFrom: "Entrée de la table {tableName}",
    noFieldAccessible: "Votre profil ne vous permet pas d'accéder à cette vue.",
    files: "fichiers",
    addFiles: "Ajouter des fichiers",
    users: "Utilisateurs",
  },
  fileManager: {
    fileManager: "Gestion des fichiers",
    dropzoneDragAndDrop: "Glisser et déposer pour charger vos fichiers !",
    dropzoneClickToSelectAFileFromYourComputer: "... ou cliquer pour sélectionner un fichier depuis votre ordinateur",
    myFiles: "Mes fichiers",
    newFilesToUpload: "Nouveaux fichiers à charger",
    upload: "Charger",
    processing: "En cours de traitement ...",
    operationCanTakeAFewSeconds: "L'opération peut prendre quelques secondes ...",
    operationCanTakeAFewMinutes: "L'opération peut prendre quelques minutes ...",
    fileVerified: "Fichier vérifié par antivirus",
    fileNotYetVerified: "Fichier pas encore vérifié par antivirus"
  },
  entries: {
    select: {
      noReferences: "Pas de référence",
      noUsers: "Pas d'utilisateurs",
      noOptions: "Pas d'option",
    }
  },
  actionsColumn: {
    actions: "Actions",
    editColumn: "Paramétrer",
    deleteColumn: "Supprimer la colonne",
    systemField: "Champ système",
    lockedField: "Champ verrouillé",
    nameColumn: "Nom de la colonne",
    description: "Description",
    typeOfData: "Type de données",
    confirmEdit: "Vous avez changé le type de données de ce champ.<br>Les données associées peuvent être supprimées ou compromises à la suite de cette opération.<br><span style='color: #E64C43;'>Cela n'est pas réversible</span>.<br><br>Êtes-vous sûr ?",
    confirmDelete: "Vous allez supprimer ce champ.<br><span style='color: #E64C43;'>Cela n'est pas réversible</span>.<br><br>Êtes-vous sûr ?",
  },
  actionsRow: {
    confirmDelete: "Vous allez supprimer cette entrée.<br><span style='color: #E64C43;'>Cela n'est pas réversible</span>.<br><br>Êtes-vous sûr ?",
  },
  fieldType: {
    number: "Nombre",
    string: "Texte",
    date: "Date",
    date_time_range: "Période temporelle",
    boolean: "Oui/Non",
    select: "Liste d'options",
    files: "Fichiers",
    references: "Références",
    users: "Utilisateurs",
    formula: "Formule",
    lookups: "Recherche",
    created_at: "Date de création",
    last_modified_at: "Date de modification",
    created_by: "Créateur",
    last_modified_by: "Dernier modificateur",
  },
  editOptions: {
    date: "Gérer le format de date",
    number: "Selectionner l'unité",
    select: "Edition options",
    formula: "Gérer les paramètres du champ formule",
    formula_data_type: "Format de sortie",
    newOption: "Nouvelle option",
    selectTable: "Sélectionner la table à référencer",
    oneEntry: "Limiter à une seule entrée",
    selectVue: "Limiter la sélection à une vue particulière",
    noTable: "Aucune table",
    noView: "Aucune vue",
    selectReferencesField: "Sélectionner le champ Références",
    selectLookupsField: "Sélectionner le champ à rechercher",
    isFiltered: "Respectant certaines conditions",
    antivirusScan: "Scanner les fichiers par antivirus",
    acceptedFileFormats: "Formats de fichiers acceptés",
  },
  projectFileAcceptedFormats: {
    all: "Tous les formats",
    excel: "Microsoft Excel (.xls, .xlsx)"
  },
  confirmations: {
    applyEverywhereFieldsDisplay: "Êtes-vous sûr de vouloir appliquer cette disposition des colonnes pour toutes les vues du profil et personnelles de la table ?",
    deleteView: "Êtes-vous sûr de vouloir dissocier cette vue ? Si cette vue n'est liée à aucun autre profil, elle sera supprimée.",
    deleteTable: "Êtes-vous sûr de vouloir supprimer cette table ?",
    deleteDashboard: "Êtes-vous sûr de vouloir supprimer ce tableau de bord ?",
    deleteFolder: "Êtes-vous sûr de vouloir supprimer ce dossier ?",
    deletePage: "Êtes-vous sûr de vouloir supprimer cette page ?"
  },
  rightClick: {
    copy: "Copier",
    cut: "Couper",
    paste: "Coller",
    duplicate: "Dupliquer l'entrée",
    edit: "Editer l'entrée",
    deleteRecord: "Supprimer l'entrée",
    addField: "Ajouter une colonne",
    deleteField: "Supprimer la colonne",
  },
  viewForm: {
    whatDoYouWantToDo: "Que voulez-vous faire ?",
    createRecord: "Créer une entrée",
    editRecord: "Éditer l'entrée",
    formSubmitted: "Le formulaire a bien été soumis",
  },
  time: {
    custom: "Personalisée",
    currentWeek: "Semaine en cours",
    date_range: "Période",
    lastFourWeeks: "4 dernières semaines",
    lastSevenDays: "7 derniers jours",
    lastThreeMonths: "3 derniers mois",
    lastTwelveMonths: "12 derniers mois",
    month: "Mois",
    monthly: "Mensuel",
    monthToDate: "Depuis le début du mois",
    week: "Semaine",
    year: "Année",
    yearToDate: "Depuis le début de l'année",
    fromLastWeekToNextWeek: "De la semaine dernière à la suivante",
  },
  filterOperators: {
    contains: "contient",
    doesNotContain: "ne contient pas",
    containsToday: "contient aujourd'hui",
    containsNow: "contient maintenant",
    isEmpty: "est vide",
    isNotEmpty: "n'est pas vide",
    isEqualTo: "est égal à",
    isNotEqualTo: "n'est pas égal à",
    is: "est",
    isNot: "n'est pas",
    isOn: "est le",
    isNotOn: "n'est pas le",
    fromExcluded: "à partir du (exclus)",
    fromIncluded: "à partir du (inclus)",
    untilExcluded: "jusqu'au (exclus)",
    untilIncluded: "jusqu'au (inclus)",
    isToday: "est aujourd'hui",
    overlaps: "coïncide avec",
    doesNotoverlap: "ne coïncide pas avec",
    isPast: "est passée",
    isNotPast: "n'est pas passée",
    isInLessThan: "est dans moins de",
    isInMoreThan: "est dans plus de",
    hasBeenLessThan: "il y a moins de",
    hasBeenMoreThan: "il y a plus de",
    reference: "rérérence l'entrée",
    doesNotReference: "ne rérérence pas"
  },
  charts: {
    bar: "Histogramme",
    bar_double_axis: "Histogramme double axes",
    line: "Courbe"
  },
  dataTypes: {
    enr_production: "La production d'électricité du",
    enr_production_stacked: "La production d'électricité cumulée par site du",
    enr_perf_production: "La production réelle et la performance d'électricité du",
    enr_forecast_production: "La production réelle et prévisionnelle d'électricité du",
    flex_production: "La consommation d'électricité du",
    epex_m0: "Les prix EPEX et MO du",
    epex: "Le prix EPEX du",
    capacity_auction: "Enchères de capacité de l'année de livraison",
    aoe: "Prix de l'Appel d'Offre Effacement",
    perf_target_actual: "Les performances réalisées vs cibles du",
    enr_forecast_and_best_production: "La production réelle et prévisionnelle"
  },
  calendarTypes: {
    calendar_with_all_events: "Le calendrier PP1 et PP2",
    calendar_without_pp1: "Le calendrier sans PP1",
    calendar_negative_price: "Le calendrier des jours négatifs"
  },
  notifications: {
    showAllAlerts: "Voir toutes les alertes",
    showMore: "Voir plus",
    noAlerts: "Pas d'alerte",
    noNotifications: "Pas de notification",
  },
  automations: {
    labels: {
      activity_times_field_ids: "Champs pour les heures d'activités",
      andTriggerRecord: "ET l'entrée déclenchée",
      arguments: "Arguments",
      attachments: "Pièces jointes",
      attributes: "Attributs",
      availableFields: "Champs disponibles",
      body: "Corps",
      clickToInsert: "(cliquer pour insérer)",
      collection_sheets_settings: "Paramètres pour les fiches de collecte",
      collection_sheets_table_id: "Table pour les fiches de collecte",
      concerned_field_ids: "Champs concernés",
      concerned_period: "Période concernée",
      concernedFields: "Champs concernés",
      conditions: "Conditions",
      content: "Contenu",
      custom_dates: "Dates personnalisées",
      days_in_order_hint: "(dans l'ordre, de lundi à dimanche)",
      daysOfWeek: "Jours de la semaine",
      default_period_field_id: "Champ pour la période par default",
      email_content: "Contenu de l'email",
      end_date: "Date de fin",
      fields: "Champs",
      file_name_field_id: "Champ pour le nom de fichier",
      filename_field_id: "Champ pour le nom de l'export",
      filters: "Filtres",
      findRecordsBasedOn: "Rechercher des entrées en se basant sur",
      first_day_of_week_field_id: "Champ pour le premier jour de la semaine",
      fromModuleOutput: "À partir de la sortie d'un module",
      host: "Serveur distant",
      hour: "Heure",
      identifier: "Identifiant",
      intervalTypes: "Types d'intervalle",
      job: "Tâche",
      module: "Module",
      module_number: "Numéro de module",
      next_week: "Semaine à venir",
      no_source: "Pas de source",
      options: "Options",
      periods_field_ids: "Champs pour les périodes",
      port: "Port",
      profiles: "Profils",
      recipients: "Destinataires",
      recipientsField: "Champ de destinataires",
      reference_to_site_field_id: "Champ pour la référence au site",
      remotePath: "Chemin sur le serveur distant",
      source: "Source",
      source_id_field_id: "Champ pour le source ID",
      start_date: "Date de début",
      subject: "Sujet",
      synchronization: "Synchronisation",
      synchronized: "Synchronisé ?",
      table: "Table",
      unavailabilities_settings: "Paramètres pour les indisponibilités",
      unavailability_days_field_ids: "Champs pour les jours d'indisponibilités",
      unavailability_table_id: "Table pour les d'indisponibilités",
      unavailability_table: "Table pour les indisponibilités",
      user: "Utilisateur",
      users: "Utilisateurs",
      view: "Vue",
    }
  },
  importDataModal: {
    importFile: "Importer le fichier",
    dzMessage: "Cliquez ou faites glisser le fichier",
    loadingMessage: "<strong>Étape 1/2 :</strong><br>Chargement du fichier en cours.<br>Veuillez patienter ...",
    dataImportLoadingMessage: "<strong>Étape 2/2 :</strong><br>Import des données en cours.<br>Veuillez patienter ...",
    dataImportSuccessMessage: "<strong>Données importées avec succès.</strong>",
    dataImportErrorMessage: "<strong>Erreur lors de l'import des données.</strong>",
    importFailed: "L'import a échoué ",
    atLineNumber: "à la <strong>ligne {line_number}</strong><br>",
    becauseFollowingError: "à cause de l'erreur de validation suivante :",
    importFailedWarning: "Attention : les lignes précédentes ont bien été importées.",
    CloseModalConfirmation: "Votre formulaire d'import n'est pas vide.<br><br>Êtes-vous sûr de vouloir le fermer ?",
    downloadTemplate: "Télécharger le template",
  },
  editViewDescriptionModal: {
    editViewDescription: "Éditer la description de la vue",
    viewDescriptionPlaceholder: "Décrivez la vue",
  },
  editViewOptionsModal: {
    editViewOptions: "Éditer les options de la vue",
    chooseRedirectionView: "Choisir la vue de redirection",
    editRedirectionView: "Éditer la vue de redirection",
  },
  daysOfWeek: {
    sunday: "Dimanche",
    monday: "Lundi",
    tuesday: "Mardi",
    wednesday: "Mercredi",
    thursday: "Jeudi",
    friday: "Vendredi",
    saturday: "Samedi",
  },
  months: {
    january: "Janvier",
    february: "Février",
    march: "Mars",
    april: "Avril",
    may: "Mai",
    june: "Juin",
    july: "Juillet",
    august: "Août",
    september: "Septembre",
    october: "Octobre",
    november: "Novembre",
    december: "Décembre",
  },
  relative_years: {
    last_year: "Année dernière",
    current_year: "Année en cours",
    next_year: "Année prochaine",
  },
  validations: {
    title: "Validations sur les entrées",
    add_validation_on: "Ajouter un champ à valider",
    must_be: "doit être",
    mustBePresent: "doit être présent",
    mustBeUnique: "doit être unique",
    must_be_a: "doit être un",
    add_comparison: "Ajouter une comparaison",
    add_format: "Ajouter un format",
    empty: "Aucune validation sur les entrées de cette table",
    presence: {
      must_be_present: "Le champ {fieldName} doit être renseigné."
    },
    comparison: {
      lt: "inférieur",
      gt: "supérieur",
      gteq: "supérieur ou égal",
      lteq: "inférieur ou égal",
      eq: "égal",
      not_eq: "différent",
    },
    format: {
      tel: "numéro de téléphone (français)",
      email: "courriel",
    },
    otherAttributeType: {
      field: "au champ",
      relative: "relatif à",
    },
    relativeAttributeValue: {
      today: "aujourd'hui",
    },
  },
  pages: {
    new: {
      title: "Créer une page",
    },
    edit: {
      title: "Éditer la page",
    },
    form: {
      name: "Nom de la page",
      type_name: "Type de page",
    },
    types: {
      enr_unavailability: "Gestion des indisponibilités ENR",
      flex_annual_delivery: "FLEX suivi annuel de livraison"
    }
  }
};
