import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dateInput"];

  form() {
    return this.dateInputTarget.form;
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  formatDate(date) {
    return [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join('-');
  }

  clear() {
    this.dateInputTarget.value = '';
    this.element.querySelector("[data-controller='flatpickr']")._flatpickr.clear();
    this.submitForm();
  }

  updateRange({ detail: { selectedDates } }) {
    const newDate = this.formatDate(selectedDates[0]);
    this.dateInputTarget.value = newDate
  }

  submitForm() {
    // <form>.requestSubmit() is not supported on Safari prior to version 16 (2022/09/12),
    // but requestSubmit triggers html form validations, which are nice for this form,
    // so if available, use requestSubmit, else fallback to classic submit.
    // NB: even on Safari 16, requestSubmit does not support passing a submit btn
    // to simulate a submit from this btn.
    // NB2: feels like Safari wanna play IE style ...

    if (this.form().requestSubmit) {
      this.form().requestSubmit();
    } else {
      this.form().submit();
    }
  }
}
