var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"my-3"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('editOptions.formula')))]),_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('editOptions.formula_data_type')))]),_c('v-select',{attrs:{"input-id":'edit-unit-type' + _vm.editedField.id,"appendToBody":"","searching":true,"options":_vm.listTypeField,"get-option-label":function (option) { return _vm.$t('fieldType.' + option); },"placeholder":_vm.$t('units.null.name'),"disabled":!_vm.canEditField},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(_vm._s(_vm.$t('noOptionForSelect')))]}}]),model:{value:(_vm.editedField.dataType),callback:function ($$v) {_vm.$set(_vm.editedField, "dataType", $$v)},expression:"editedField.dataType"}}),(_vm.editedField.dataType == 'number')?_c('div',{staticClass:"mt-2"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('editOptions.number')))]),_c('v-select',{attrs:{"input-id":'edit-unit-type' + _vm.editedField.id,"appendToBody":"","searching":true,"options":_vm.unitList,"disabled":!_vm.canEditField,"get-option-label":function (option) { return _vm.$t('units.' + option + '.name'); },"placeholder":_vm.$t('units.null.name')},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(_vm._s(_vm.$t('noOptionForSelect')))]}}],null,false,2537567840),model:{value:(_vm.editedField.typeOptions.unit_type),callback:function ($$v) {_vm.$set(_vm.editedField.typeOptions, "unit_type", $$v)},expression:"editedField.typeOptions.unit_type"}})],1):_vm._e(),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.editedField.typeOptions.formula),expression:"editedField.typeOptions.formula"}],staticClass:"form-control string formula-field-input mt-4",attrs:{"disabled":!_vm.canEditField},domProps:{"value":(_vm.editedField.typeOptions.formula)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.editedField.typeOptions, "formula", $event.target.value)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }