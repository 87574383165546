import moment from 'moment';

export const dataTypesSetValueCellOperations = {
  number: (entry, newValue) => {
    const number = Number.parseInt(newValue);
    if (number) {
      entry.value = number;
      return true;
    }
    return false;
  },
  string: (entry, newValue) => {
    entry.value = newValue;
    return true;
  },
  date: (entry, newValue) => {
    if (entry.field.typeOptions.date_format === 'with_time') {
      if (moment(newValue, "DD/MM/YYYY HH:mm").isValid()) {
        entry.value = moment(newValue, "DD/MM/YYYY HH:mm").toDate();
        return true;
      }
    } else {
      if (moment(newValue, "DD/MM/YYYY").isValid()) {
        entry.value = moment(newValue, "DD/MM/YYYY").toDate();
        return true;
      }
    }
    return false;
  },
  date_time_range: (entry, newValue) => {
    if (moment(newValue.start_date_time, "DD/MM/YYYY HH:mm").isValid() && moment(newValue.end_date_time, "DD/MM/YYYY HH:mm").isValid()) {
      entry.value.start_date_time = moment(newValue.startDateTime, "DD/MM/YYYY HH:mm").toDate();
      entry.value.end_date_time   = moment(newValue.endDateTime, "DD/MM/YYYY HH:mm").toDate();
      return true;
    }
    return false;
  },
  boolean: (entry, newValue) => {
    if (newValue.toLowerCase() === "oui" || newValue.toLowerCase() === "true") entry.value = true;
    else if (newValue.toLowerCase() === "non" || newValue.toLowerCase() === "false") entry.value = false;
    else return false;
    return true;
  },
  select: (entry, newValue) => {
    if (entry.field.typeOptions.select_list.includes(newValue)) {
      entry.value = newValue;
      return true;
    }
    return false;
  },
  references: (_entry, _newValue) => {
    return false;
  },
  files: (_entry, _newValue) => {
    return false;
  },
};
