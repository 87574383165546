export default class History {
  constructor() {
    this.history = [];

    document.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  handleKeyDown(event) {
    if ((event.key === "z" || event.key === "Z") && (event.ctrlKey || event.metaKey)) {
      this.undo();
    }
  }

  add(cell) {
    this.history.push(cell);
  }

  undo() {
    if (!this.history.length) return;

    const lastCell = this.history.pop();

    lastCell.update(lastCell.previousValue);
  }
}
