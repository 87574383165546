import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "computationMode",
  ];

  change() {
    this.submitForm();
  }

  submitForm() {
    // <form>.requestSubmit() is not supported on Safari prior to version 16 (2022/09/12),
    // but requestSubmit triggers html form validations, which are nice for this form,
    // so if available, use requestSubmit, else fallback to classic submit.
    // NB: even on Safari 16, requestSubmit does not support passing a submit btn
    // to simulate a submit from this btn.
    // NB2: feels like Safari wanna play IE style ...

    const form = this.computationModeTarget.form;

    // The idea here is to submit the form to the right path depending on the computation mode.
    // If the computation mode is "aggregated", we submit to the table_path directly as we don't need the selected
    // option.
    // Otherwise, we submit the form to the select_list_path in order to have access to the select option input.

    if (this.computationModeTarget.tomselect.activeOption.innerHTML == "aggregated") {
      form.action = this.computationModeTarget.dataset.pathValue;
    }

    if (this.computationModeTarget.form.requestSubmit) {
      this.computationModeTarget.form.requestSubmit();
    } else {
      this.computationModeTarget.form.submit();
    }
  }

}
