<template lang="pug">
.dashboard-show
  template(v-if="loading")
    .flex-center.h-100
      Loader

  template(v-else)
    .dashboard-show--header
      .text-center.text-light.bg-warning(v-if="editMode") {{ $t('dashboard.editMode') }}
      .main-panel-nav(
        :class="{ 'edit-mode': editMode }"
      )

        h3.mb-0.dashboard-name.text-project-secondary
          span.text-warning.font-italic(
            v-if="editMode"
          )
            input.px-2.rounded.border(
              :value="dashboard.title"
              @change="updateDashboardTitle"
            )
          span(v-else
            :class="{ [translationFallbackClasses]: dashboard.title_fallback }"
          ) {{ dashboard.title }}

        .d-flex.flex-item-25.align-items-center(v-if="editMode")
          .main-panel-nav--btn.main-panel-nav--btn-text.d-inline-block.edit-btn(
            @click.prevent="addBlockToDashboard"
          )
            p.mb-0 {{ $t('dashboard.addBlock') }}

        .d-flex.flex-item-25.justify-content-end.flex-center
          .d-flex.align-items-center.mr-4(v-if="editMode")
            input.rounded.border(
              type="checkbox"
              id="display-period-selector"
              :checked="dashboard.display_period_selector"
              @change="updateDashboardDisplayPeriodSelector"
            )
            label.ml-2.mb-0(for="display-period-selector") {{ $t('dashboard.display_period_selector') }}
            v-select(
              style="width: 190px"
              :value="dashboard.period_selector_type"
              :options="periodSelectorOptions"
              :reduce="option => option.id"
              :get-option-label="option => option.name"
              :get-option-key="option => option.id"
              :clearable="false"
              @input="updateDashboardPeriodSelectorType"
            )
          template(v-else)
            form(v-if="dashboard.display_period_selector")
              .d-flex
                v-select.fixed-height.selected-w-72.period-options.mr-1(
                  v-if="dashboard.period_selector_type === 'date_range'"
                  style="width: 300px;"
                  :options="periodOptions"
                  label="name"
                  :reduce="option => option.id"
                  :get-option-label="option => option.name"
                  :get-option-key="option => option.id"
                  :value="periodIdentifier"
                  @input="changePeriod($event)"
                  :clearable="false"
                  :placeholder="$t('time.custom')"
                )
                date-picker.mr-1(
                  v-model="dateOrDatesRange"
                  :lang="this.$i18n.locale"
                  :type="datePickerOptions.type"
                  :range="datePickerOptions.range"
                  :format="datePickerOptions.format"
                  value-type="date"
                  :placeholder="datePickerOptions.placeholder"
                  :clearable="false"
                  @change="handleCalendarChange"
                )

          .main-panel-nav--btn.main-panel-nav--btn-text(
            v-if="currentUser.superAdmin"
            @click.prevent="editMode = !editMode"
            :class="{ 'edit-btn': editMode }"
          )
            span(v-if="editMode") {{ $t('dashboard.endEditMode') }}
            i.fal.fa-sliders-h(v-else)

    //- We can't use layout.sync because it causes random bugs of layout update (like hidden a block not working properly)
    grid-layout(
      v-if="displayGrid"
      class="dashboard-layout"
      ref="dashboardLayout"
      :layout="layout"
      :responsive-layouts="responsiveLayouts"
      :is-draggable="editMode"
      :is-resizable="editMode"
      :margin="[gridLayoutOptions.marginX, gridLayoutOptions.marginY]"
      v-bind="gridLayoutOptions"
      @breakpoint-changed="saveCurrentBreakpoint"
    )
      grid-item(
        v-for="(item) in layout"
        class="dashboard-block"
        :id="`dashboard-block-${item.i}`"
        :class="{ 'd-none': item.hidden && !editMode }"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :key="item.i"
        dragAllowFrom=".move-block"
        @resized="saveLayout"
        @moved="saveLayout"
      )
        .dashboard-block--content
          .dashboard-block--edit(v-if="editMode")
            p.text-light.p-2.mb-0.bg-secondary(:class="{ [translationFallbackClasses]: item.title_fallback }") {{ item.title }}
            .edit-buttons
              span(@click="toggleBlockVisiblity(item.dashboardBlockId)")
                i.far(:class="item.hidden ? 'fa-eye-slash' : 'fa-eye'")
              span(@click="editBlockSettings(item.dashboardBlockId)")
                i.far.fa-cog
              span(@click="destroyDashboardBlock(item.dashboardBlockId)")
                i.far.fa-trash-alt
              span.move-block
                i.far.fa-arrows-alt
          component(
            :is="'dashboard-block-' + item.dashboardBlockKind"
            :dashboardBlockId="item.dashboardBlockId"
            :editMode="editMode"
            :periodIdentifier="periodIdentifier"
            :periodDates="periodDates"
            @handleResize
          )
</template>

<script>
import { api } from '../api/client';
import { DatepickerMixin } from '../mixins/entries/DatepickerMixin';
import DashboardBlockList from './dashboard_blocks/DashboardBlockList';
import DashboardBlockMap from './dashboard_blocks/DashboardBlockMap';
import DashboardBlockKpi from './dashboard_blocks/DashboardBlockKPI';
import DashboardBlockTimescale from './dashboard_blocks/DashboardBlockTimescale';
import DashboardBlockStat from './dashboard_blocks/DashboardBlockStat';
import DashboardBlockCalendar from './dashboard_blocks/DashboardBlockCalendar';
import DashboardBlockSchedule from './dashboard_blocks/DashboardBlockSchedule';
import DashboardBlockLive from './dashboard_blocks/DashboardBlockLive';
import DashboardBlockPeakperiod from './dashboard_blocks/DashboardBlockPeakPeriod';
import DashboardBlockReportextract from './dashboard_blocks/DashboardBlockReportExtract';
import DashboardBlockNcclist from './dashboard_blocks/DashboardBlockNccList';
import DashboardBlockStandalonekpi from './dashboard_blocks/DashboardBlockStandaloneKPI';
import DashboardBlockSourcedChart from './dashboard_blocks/DashboardBlockSourcedChart';
import DashboardBlockSourcedKPI from './dashboard_blocks/DashboardBlockSourcedKPI';
import DashboardBlockChart from './dashboard_blocks/DashboardBlockChart';
import DashboardBlockLoadShift from './dashboard_blocks/DashboardBlockLoadShift';
import DashboardBlockRegionalmap from './dashboard_blocks/DashboardBlockRegionalMap';
import DashboardBlockFlexContacts from './dashboard_blocks/DashboardBlockFlexContacts';
import DashboardBlockText from './dashboard_blocks/DashboardBlockText';

import { EventBus } from '../main';
import { clone, has, isUndefined } from 'lodash';
import { mapState, mapGetters } from 'vuex';
import { requireConfirmation } from '../../components/require_confirmation';

import Dashboard from '../models/dashboard';
import DashboardBlock from '../models/dashboardBlock';

export default {
  mixins: [DatepickerMixin],
  components: {
    DashboardBlockList,
    DashboardBlockCalendar,
    DashboardBlockSchedule,
    DashboardBlockMap,
    DashboardBlockKpi, // needs to be Kpi so vue understand the name boundaries
    DashboardBlockStandalonekpi, // needs to be Kpi so vue understand the name boundaries
    DashboardBlockTimescale,
    DashboardBlockChart,
    DashboardBlockStat,
    DashboardBlockLive,
    DashboardBlockPeakperiod,
    'dashboard-block-sourced_chart': DashboardBlockSourcedChart,
    'dashboard-block-sourced_kpi': DashboardBlockSourcedKPI,
    DashboardBlockReportextract,
    DashboardBlockNcclist,
    'dashboard-block-load_shift': DashboardBlockLoadShift,
    DashboardBlockRegionalmap,
    'dashboard-block-flex_contacts': DashboardBlockFlexContacts,
    DashboardBlockText,
  },
  data() {
    return {
      periodIdentifier:   null,
      dateOrDatesRange:   null,
      periodDates:        [],
      displayGrid:        false,
      editMode:           false,
      layout:             [],
      responsiveLayouts:  { xxs: [], md: [], lg: [] },
      currentBreakpoint:  'md',
      gridLayoutOptions: {
        rowHeight:        16,
        colNum:           6,
        isMirrored:       false,
        autoSize:         true,
        verticalCompact:  true,
        preventCollision: false,
        useCssTransforms: true,
        marginX:          0,
        marginY:          0,
        breakpoints:      { lg: 992, md: 600, sm: 0, xs: 0, xxs: 0 }, // remember to migrate old DashboardBlock layout_settings when adding breakpoint
        cols:             { lg: 6, md: 4, sm: 1, xs: 1, xxs: 1 },
        responsive:       true
      },
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      currentResourceId: state => state.currentResourceId,
      translationFallbackClasses: state => state.translationFallbackClasses,
    }),
    ...mapGetters({
      loading: 'isLoading'
    }),
    dashboard() {
      return Dashboard.query().with('dashboard_blocks').find(this.currentResourceId) || {};
    },
    periodOptions() {
      const options = [
        'currentWeek',
        'lastSevenDays',
        'lastFourWeeks',
        'lastThreeMonths',
        'lastTwelveMonths',
        'monthToDate',
        'yearToDate',
        'fromLastWeekToNextWeek',
      ]

      return options.map((option) => {
        return { id: option, name: this.$t(`time.${option}`) }
      });
    },
    periodSelectorOptions() {
      const options = [
        'date_range',
        'monthly'
      ]

      return options.map((option) => {
        return { id: option, name: this.$t(`time.${option}`) }
      });
    },
    datePickerOptions() {
      // For period_selector_type options see model Dashboard *dashboard.rb*

      if (this.dashboard.period_selector_type === "monthly" ) {
        return {
          type: "month",
          range: false,
          format: "MMM YYYY",
          placeholder: "MM YYYY"
        }
      } else {
        return {
          type: "date",
          range: true,
          format: "DD/MM/YYYY",
          placeholder: "DD/MM/YYYY ~ DD/MM/YYYY"
        }
      }
    }
  },
  watch: {
    currentResourceId: function(_newResourceId, oldResourceId) {
      const oldDashboard = Dashboard.query().find(oldResourceId)
      if (this.dashboard.period_selector_type !== oldDashboard.period_selector_type) {
        if (this.dateOrDatesRange && this.dashboard.period_selector_type === "monthly") {
          this.setDatesRangeAsDate();
          this.periodDates      = this.dateToRange(this.periodDates[0]);
          this.periodIdentifier = null;
        } else if (this.dateOrDatesRange && this.dashboard.period_selector_type !== "monthly") {
          this.dateOrDatesRange = this.dateToRange(this.dateOrDatesRange);
        }
      }
      this.editMode = false;
      this.buildAndSetLayout();
    },
    editMode: function(_isOn, _wasOn) {
      this.buildAndSetLayout();
    },
    periodIdentifier: function(newPeriodIdentifier, _oldPeriodIdentifier) {
      if (!newPeriodIdentifier) return;

      api.dashboards.datesForPeriod(newPeriodIdentifier)
        .then((response) => {
          const dates = response.data.map((dateString) => {
            return new Date(dateString);
          });

          this.dateOrDatesRange  = dates;
          this.periodDates = dates;
          this.setDatesRangeAsDate();
        });
    }
  },
  created() {
    EventBus.$on('blockCreated', this.buildAndSaveResponsiveLayouts);
    EventBus.$on('blockUpdated', this.buildAndSetLayout);
    EventBus.$on('resize-block', this.resizeBlock); // FROM DashboardBlockLive.vue for automatic resize of block
  },
  mounted() {
    if (this.dashboard.period_selector_type !== "monthly") {
      this.periodIdentifier = 'lastFourWeeks';
    }
    this.buildAndSetResponsiveLayouts();
    this.layout = this.responsiveLayouts[this.currentBreakpoint];
    this.displayGrid = true;
    this.setInitialPeriodDates();
  },
  beforeDestroy() {
    EventBus.$off('blockCreated', this.buildAndSaveResponsiveLayouts);
    EventBus.$off('blockUpdated', this.buildAndSetLayout);
    EventBus.$off('resize-block', this.resizeBlock); // FROM DashboardBlockLive.vue for automatic resize of block
  },
  methods: {
    buildAndSetResponsiveLayouts() {
      const breakpoints = Object.keys(this.responsiveLayouts);

      breakpoints.forEach((breakpoint) => {
        this.responsiveLayouts[breakpoint] = this.buildSanitizedLayout(breakpoint);
      });
    },
    buildAndSetLayout() {
      this.layout = this.buildSanitizedLayout(this.currentBreakpoint);
    },
    buildSanitizedLayout(breakpoint) {
      const blocks          = this.dashboard.dashboard_blocks;
      const gridItemBuilder = this.buildGridItem(breakpoint);
      const layout          = blocks.map(gridItemBuilder);

      return this.sanitizeLayout(layout);
    },
    buildGridItem(breakpoint) {
      return (dashboardBlock) =>{
        return {
          ...dashboardBlock.layoutSettings(breakpoint),
          dashboardBlockId: dashboardBlock.id,
          dashboardBlockKind: dashboardBlock.kind,
          title: dashboardBlock.title,
          title_fallback: dashboardBlock.title_fallback,
        }
      }
    },
    sanitizeLayout(layout) { // We need to do this because the lib goes full crazy loop if an item doesn't have correct properties ...
      return layout.filter((item) => {
        return ['i', 'x', 'y', 'w', 'h'].every((key) => has(item, key) && !isUndefined(item[key]));
      });
    },
    saveCurrentBreakpoint(newBreakpoint, _layout) {
      this.currentBreakpoint = newBreakpoint;
      // as we can't use layout.sync as lib says so, we must force layout update like this when breakpoint change
      this.buildAndSetLayout();
      this.$nextTick(() => {
        EventBus.$emit('dashboard-breakpoint-updated');
      });
    },
    saveLayout() {
      return DashboardBlock.api().saveLayout(this.layout, this.currentBreakpoint);
    },
    buildAndSaveResponsiveLayouts() {
      this.buildAndSetResponsiveLayouts();
      this.layout = this.responsiveLayouts[this.currentBreakpoint];
      this.saveLayout();
    },
    updateDashboardTitle(event) {
      Dashboard.api().saveTitle(this.currentResourceId, event.currentTarget.value);
    },
    updateDashboardDisplayPeriodSelector(event) {
      Dashboard.api().saveDisplayPeriodSelector(this.currentResourceId, event.currentTarget.checked);
    },
    updateDashboardPeriodSelectorType(event) {
      Dashboard.api().savePeriodSelectorType(this.currentResourceId, event);
    },
    addBlockToDashboard() {
      EventBus.$emit('openModal', {
        modalName:  'NewDashboardBlockModal',
        modalProps: {}
      })
    },
    destroyDashboardBlock(dashboardBlockId) {
      requireConfirmation(
        this.$t('dashboard.confirmDeleteBlock'),
        this.$t('yes'),
        this.$t('cancel'),
      ).then((result) => {
        if(result.isConfirmed) {
          DashboardBlock.api().destroy(dashboardBlockId).then(this.buildAndSaveResponsiveLayouts);
        }
      })
    },
    editBlockSettings(dashboardBlockId) {
      EventBus.$emit('openModal', {
        modalName:  'DashboardBlockSettingsModal',
        modalProps: { dashboardBlockId }
      })
    },
    resizeBlock(aimedHeight, dashboardBlockId) { // FROM DashboardBlockLive.vue for automatic resize of block
      const rowHeight       = this.gridLayoutOptions.rowHeight;
      const marginY         = this.gridLayoutOptions.marginY;
      const newHeight       = Math.ceil((aimedHeight + marginY) / (rowHeight + marginY));
      const dashboardBlock  = DashboardBlock.find(dashboardBlockId);
      const layout_settings = clone(dashboardBlock.layout_settings);

      layout_settings[this.currentBreakpoint].height = newHeight;

      DashboardBlock.update({ where: dashboardBlockId, data: { layout_settings } });

      this.buildAndSetLayout();
    },
    toggleBlockVisiblity(dashboardBlockId) {
      const dashboardBlock   = DashboardBlock.find(dashboardBlockId);
      const layout_settings  = dashboardBlock.layout_settings[this.currentBreakpoint];
      layout_settings.hidden = !layout_settings.hidden;

      DashboardBlock.api().update(dashboardBlockId, { layout_settings: { [this.currentBreakpoint]: layout_settings } }).then(this.buildAndSetLayout);
    },
    changePeriod(period) {
      this.periodIdentifier = period;
    },
    dateToRange(date) {
      return [new Date(date.getFullYear(), date.getMonth(), 1), new Date(date.getFullYear(), date.getMonth() + 1, 0)]

    },
    setDatesRangeAsDate(){
      if (this.dashboard.period_selector_type !== "monthly") return;


      const startDate       = this.dateOrDatesRange[0]
      this.dateOrDatesRange = new Date(startDate.getFullYear(), startDate.getMonth(), 1)
    },
    handleCalendarChange(dates) {
      this.periodIdentifier = null;
      if (Array.isArray(dates)) {
        this.periodDates = dates;
      } else {
        const dateRanges = this.dateToRange(dates);
        this.periodDates = dateRanges;
      }
    },
    setInitialPeriodDates() {
      if (this.dashboard.period_selector_type !== "monthly") return;

      const today = new Date()

      this.periodDates      = this.dateToRange(today);
      this.dateOrDatesRange = new Date(today.getFullYear(), today.getMonth(), 1)
    }
  }
}
</script>

<style>
.v-select.period-options .vs__dropdown-toggle {
  height: 100%;
}
</style>
