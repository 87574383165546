import { render, staticRenderFns } from "./NewFormModal.vue?vue&type=template&id=4a0c7e85&scoped=true&lang=pug&"
import script from "./NewFormModal.vue?vue&type=script&lang=js&"
export * from "./NewFormModal.vue?vue&type=script&lang=js&"
import style0 from "./NewFormModal.vue?vue&type=style&index=0&id=4a0c7e85&lang=scss&scoped=true&and-dirty=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a0c7e85",
  null
  
)

export default component.exports