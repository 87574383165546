import { EventBus }         from '../../main';
import { UpdateEntryMixin } from '../UpdateEntryMixin';

export const LookupsMixin = {
  mixins: [UpdateEntryMixin],
  methods: {
    openEntryLookupsModal() {
      EventBus.$emit('openModal', {
        modalName: 'EntryLookupsModal',
        modalProps: this.entry
      });
    },
  }
};
