import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "year"];

  submitForm() {
    // <form>.requestSubmit() is not supported on Safari prior to version 16 (2022/09/12),
    // but requestSubmit triggers html form validations, which are nice for this form,
    // so if available, use requestSubmit, else fallback to classic submit.
    // NB: even on Safari 16, requestSubmit does not support passing a submit btn
    // to simulate a submit from this btn.
    // NB2: feels like Safari wanna play IE style ...

    if (this.formTarget.requestSubmit) {
      this.formTarget.requestSubmit();
    } else {
      this.formTarget.submit();
    }
  }

  setValueAndSubmitForm(event) {
    event.preventDefault();

    const actionTarget = event.target.dataset.actionTarget;
    const actionValue = event.target.dataset.actionValue;
    this[`${actionTarget}Target`].value = actionValue;

    this.submitForm();
  }
}
