<template lang="pug">
  .calendar-wrapper(ref="calendarWrapper")
    .records-loader(v-if="loadingRecords")
      Loader
    .calendar-nav.main-panel-nav.px-4.py-2(ref="calendarNavigation")
      .d-flex
        .main-panel-nav--btn.main-panel-nav--btn-text.mr-2(@click="moveToToday")
          span.none-display-mobile {{ this.$t('calendarNav.today') }}
          i.fas.fa-calendar-day.none-display-desktop
        .main-panel-nav--btn.main-panel-nav--btn-icon(@click="moveToPreviousRange")
          i.fas.fa-chevron-left
        .main-panel-nav--btn.main-panel-nav--btn-icon(@click="moveToNextRange")
          i.fas.fa-chevron-right

        .calendar-nav--current-month {{ currentMonth }}


      .d-flex
        .main-panel-nav--range-size-selection.mr-2
          PopAction.view-calendar-pop-action(btnClass="p-0")
            div(slot="trigger")
              .pill
                i.fas.fa-calendar-alt.fa-fw
            .pt-3.pb-2(slot="content")
              h6.mb-2.mr-5.font-weight-bold {{ $t('calendarNav.selectedCalendars') }}

              .calendar-select.mb-1(
                v-for="calendar in calendars"
                :key="calendar.id"
                :style="{ backgroundColor: calendar.bgColor, color: calendar.color }"
                @click="toggleCalendar(calendar.id)"
              )
                span.mr-3
                  i(
                    v-if="selectedCalendarsByIds[calendar.id]"
                    class="fad fa-check-circle text-success"
                    style="--fa-secondary-color: white; --fa-secondary-opacity: 1.0"
                  )
                  i(
                    v-else
                    class="fas fa-circle text-white"
                  )
                span.calendar-select--name {{ calendar.name }}

        .main-panel-nav--range-size-selection
          .pill(
            :class="[{ selected: tuiView === 'month' }]"
            @click="selectViewType('month')"
          ) {{ this.$t('calendarNav.month') }}

          .pill(
            :class="[{ selected: tuiView === 'week' }]"
            @click="selectViewType('week')"
          ) {{ this.$t('calendarNav.week') }}
        .main-panel-nav--btn.main-panel-nav--btn-text.ml-2(@click="openCalendarModal")
          i.fas.fa-cog

    Calendar(
      :key="currentView.id"
      ref="calendar"
      :style="style"
      :usageStatistics="false"
      :calendars="calendars"
      :schedules="shownEvents"
      :template="template"
      :view="tuiView"
      :taskView="taskView"
      :scheduleView="scheduleView"
      :theme="theme"
      :month="month"
      :week="week"
      :disableDblClick="disableDblClick"
      :disableClick="disableClick"
      :isReadOnly="isReadOnly"
      :useCreationPopup="useCreationPopup"
      :useDetailPopup="useDetailPopup"
      @beforeCreateSchedule="onBeforeCreateSchedule"
      @beforeUpdateSchedule="onBeforeUpdateSchedule"
      @beforeDeleteSchedule="onBeforeDeleteSchedule"
    )
</template>

<script>
import { uniqBy,
         filter,
         find,
         map }              from 'lodash';
import { api }                  from '../api/client';
import moment                   from 'moment';
import { mapState, mapGetters } from 'vuex';
import { EventBus }             from '../main.js';
import { Calendar }             from '@toast-ui/vue-calendar';
import calendarOptions          from '../services/calendarOptions';
import { UpdateEntryMixin }     from "../mixins/UpdateEntryMixin";

import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

import '../../../stylesheets/plugins/tui-calendar-overwrite.css';

export default {
  components: {
    Calendar
  },
  mixins: [UpdateEntryMixin],
  data() {
    return {
      ...calendarOptions,
      tuiView:                '',
      currentMonth:           '',
      taskView:               false,
      scheduleView:           true,
      disableDblClick:        false,
      disableClick:           true,
      useCreationPopup:       false,
      useDetailPopup:         true,
      selectedCalendarsByIds: {},
      recordIdsForPeriod:     [],
      loadingRecords:         true
    }
  },
  computed: {
    ...mapState({
      currentView:     state => state.viewStore.view,
      currentTable:    state => state.tableStore.table,
      recordsInModals: state => state.recordStore.recordsInModals,
      currentQuery:    state => state.viewStore.appliedQuery
    }),
    ...mapGetters({
      visibleFields:  'fieldStore/visibleFields',
      visibleRecords: 'recordStore/visibleRecords',
    }),
    hasRangeDateOption() {
      return this.viewTypeOptions.end_date_option === "range";
    },
    hasEndDateOption() {
      return this.viewTypeOptions.end_date_option === "end_date_field";
    },
    viewTypeOptions() {
      return this.currentView.typeOptions;
    },
    dateTimeRangeField() {
      if (this.hasRangeDateOption && this.viewTypeOptions.date_time_range_field_id) {
        return find(this.visibleFields, ['id', this.viewTypeOptions.date_time_range_field_id]);
      }
    },
    dateStartAtField() {
      if (this.hasEndDateOption && this.viewTypeOptions.date_start_at_field_id) {
        return find(this.visibleFields, ['id', this.viewTypeOptions.date_start_at_field_id]);
      }
    },
    dateEndAtField() {
      if (this.hasEndDateOption && this.viewTypeOptions.date_end_at_field_id) {
        return find(this.visibleFields, ['id', this.viewTypeOptions.date_end_at_field_id]);
      }
    },
    referenceField() {
      const referenceFieldId = this.viewTypeOptions.reference_field_id;

      return this.visibleFields.find(field => field.id === referenceFieldId);
    },
    settingsAreReady() {
      if (this.hasRangeDateOption) {
        var fieldsAreReady = this.dateTimeRangeField;
      } else if (this.hasEndDateOption) {
        var fieldsAreReady = this.dateStartAtField && this.dateEndAtField;
      }

      return !!(fieldsAreReady && this.viewTypeOptions.title_field_id);
    },
    allRecords() {
        return uniqBy([...this.visibleRecords, ...filter(this.recordsInModals, ['table.id', this.currentTable.id])], 'id');
    },
    recordsToDisplay() {
      return this.allRecords.filter((record) => {
        return this.recordIdsForPeriod.includes(record.id);
      });
    },
    recordEvents() {
      const recordEvents = [];

      if (this.settingsAreReady) {
        const { title_field_id, details_field_id } = this.viewTypeOptions;

        this.recordsToDisplay.forEach((record) => {
          if (record.hasOwnProperty('entriesByFieldId')) {
            record.buildEntries();

            const { start, end } = this.datesForRecordEvent(record);

            if (start && end && !isNaN(start) && !isNaN(end)) { // check if dates are present and valid
              const id         = record.id;
              const calendarId = this.computeCalendarId(record);
              const title      = record.entriesByFieldId[title_field_id]?.toString()   || '';
              const body       = record.entriesByFieldId[details_field_id]?.toString() || '';
              const category   = 'time';

              const event = { id, calendarId, title, body, category, start, end };

              recordEvents.push(event);
            }
          }
        })

        return recordEvents;
      }
    },
    calendars() {
      if (this.referenceField) {
        const calendarsById = this.visibleRecords.reduce((calendarsById, record) => {
          const referenceEntry = record.entriesByFieldId[this.referenceField.id];

          if (this.shouldCreateCalendar(referenceEntry, calendarsById)) {
            const { foreign_record_id, foreign_record_display_name } = referenceEntry.value[0];

            calendarsById[foreign_record_id] = {
              id:      foreign_record_id,
              name:    foreign_record_display_name,
              color:   '#ffffff',
              bgColor: this.calendarColors[foreign_record_id % this.calendarColors.length]
            }
          }

          return calendarsById;
        }, {});

        return [...Object.values(calendarsById), this.defaultCalendar];
      }
      return [this.defaultCalendar];
    },
    shownCalendars() {
      return this.calendars.filter(calendar => this.selectedCalendarsByIds[calendar.id]);
    },
    shownEvents() {
      return this.recordEvents.filter(event => this.selectedCalendarsByIds[event.calendarId]);
    },
    template() {
      // See documentation at:
      // https://nhn.github.io/tui.calendar/latest/Template

      return {
        popupDetailDate: (isAllDay, start, end) => {
          const startDay = moment(start.toDate()).format('YYYY-MM-DD');
          const endDay   = moment(end.toDate()).format('YYYY-MM-DD');

          const isSameDate = moment(startDay).isSame(endDay);
          const endFormat  = (isSameDate ? '' : 'DD.MM.YYYY ') + 'HH:mm';

          if (isAllDay) {
            return moment(start.toDate()).format('DD.MM.YYYY') + (isSameDate ? '' : ' - ' + moment(end.toDate()).format('DD.MM.YYYY'));
          }
          return (moment(start.toDate()).format('DD.MM.YYYY HH:mm') + ' - ' + moment(end.toDate()).format(endFormat));
        },
        alldayTitle: () => {
            return '<span class="tui-full-calendar-left-content">Jour</span>';
        },
        timegridDisplayPrimaryTime: (time) => {
          const hour = moment(time);
          return hour.format('HH:mm');
        },
        titlePlaceholder: () => 'Titre',
        popupSave:        () => 'Enregistrer',
        popupUpdate:      () => 'Enregistrer',
        popupEdit:        () => 'Modifier',
        popupDelete:      () => 'Supprimer'
      }
    },
    isReadOnly() {
      return !this.currentTable.recordCreationEnabled || !this.currentTable.recordDeletionEnabled;
    },
  },
  watch: {
    recordEvents: function(newValue, _oldValue) {
      if (newValue) { setTimeout(this.setCalendarsColors); }
    },
    "viewTypeOptions": function(newValue, _oldValue) {
      this.tuiView = newValue.last_range_size || 'month';
    },
    calendars: function(newCalendars, _oldCalendars) {
      const newIds = map(newCalendars, 'id');

      this.buildSelectedCalendarsByIds(newIds);
    },
    currentQuery: function(_newValue, _oldValue) {
      this.$nextTick(this.fetchRecordIdsToDisplay);
    }
  },
  mounted() {
    this.style   = { height: `${this.getCalHeight()}px` };
    this.tuiView = this.viewTypeOptions.last_range_size || 'month';

    this.updateCurrentMonth();
    this.buildSelectedCalendarsByIds(map(this.calendars, 'id'));
    this.$nextTick(this.fetchRecordIdsToDisplay);
  },
  methods: {
    selectViewType(viewType) { // viewType in ['month', 'week']
      this.tuiViewSelect(viewType);
      this.$nextTick(this.fetchRecordIdsToDisplay);
    },
    fetchRecordIdsToDisplay() {
      this.loadingRecords = true;
      const calendar = this.$refs.calendar.calendarInstance;
      const datesRange = [
        calendar.getDateRangeStart().toDate(),
        calendar.getDateRangeEnd().toDate()
      ];

      api.views
        .calendarVisibleRecordIds(
          {
            viewId: this.currentView.id,
            datesRange,
            query: JSON.stringify(this.currentQuery)
          }
        )
        .then(({ data: { visible_record_ids: visibleRecordIds } }) => {
          this.recordIdsForPeriod = visibleRecordIds
          this.loadingRecords = false;
        });
    },
    buildSelectedCalendarsByIds(calendarIds) {
      calendarIds.forEach((calendarId) => {
        if (!this.selectedCalendarsByIds.hasOwnProperty(calendarId)) {
          this.$set(this.selectedCalendarsByIds, calendarId, true);
        }
      });
    },
    tuiViewSelect(rangeSize) {
      this.tuiView      = rangeSize;
      const typeOptions = { ...this.viewTypeOptions , last_range_size: rangeSize };

      api.views
        .update({ view: { id: this.currentView.id, type_options: typeOptions } })
        .then(() => {
            this.$store.commit("viewStore/UPDATE_LAST_RANGE_SIZE", [this.currentView, typeOptions]);
        });
    },
    moveToNextRange() {
      this.$refs.calendar.invoke('next');
      this.updateCurrentMonth();
      this.fetchRecordIdsToDisplay();
    },
    moveToPreviousRange() {
      this.$refs.calendar.invoke('prev');
      this.updateCurrentMonth();
      this.fetchRecordIdsToDisplay();
    },
    moveToToday() {
      this.$refs.calendar.invoke('today');
      this.updateCurrentMonth();
      this.fetchRecordIdsToDisplay();
    },
    openCalendarModal() {
      EventBus.$emit("openModal", {
        modalName:  'CalendarModal',
        modalProps: {
          view: this.currentView
        }
      });
    },

    // CREATE EVENT
    onBeforeCreateSchedule(event){
      const defaultValuesByFieldId = {};
      const dateTimeRangeFieldId = this.viewTypeOptions.date_time_range_field_id;
      const dateStartAtFieldId   = this.viewTypeOptions.date_start_at_field_id;
      const dateEndAtFieldId     = this.viewTypeOptions.date_end_at_field_id;

      if (this.hasRangeDateOption){
        defaultValuesByFieldId[dateTimeRangeFieldId] = {
          start_date_time: new Date(event.start.getUTCTime()).toISOString(),
          end_date_time: new Date(event.end.getUTCTime()).toISOString()
        };
      } else {
        defaultValuesByFieldId[dateStartAtFieldId] = new Date(event.start.getUTCTime()).toISOString();
        defaultValuesByFieldId[dateEndAtFieldId]   = new Date(event.end.getUTCTime()).toISOString();
      }

      EventBus.$emit("openModal", {
        modalName:  'RecordModal',
        modalProps: { defaultValuesByFieldId }
      });
    },
    // UPDATE EVENT
    onBeforeUpdateSchedule(event){
      const record = find(this.currentTable.records, ['id', event.schedule.id]);

      if (event.changes) {
        // Event triggerred by drag&drop

        if (this.hasRangeDateOption){
          const entry = record.entriesByFieldId[this.dateTimeRangeField.id];

          if (entry) {
            ['start', 'end'].forEach((dateBound) => {
              if (event.changes.hasOwnProperty(dateBound)) {
                entry.value[`${dateBound}_date_time`] = new Date(event.changes[dateBound].getUTCTime()).toISOString();
              }
            })

            this.updateEntry(entry);
          }

        } else {
          const permittedFields = {
            start: this.viewTypeOptions.date_start_at_field_id,
            end:   this.viewTypeOptions.date_end_at_field_id
          }

          Object.keys(event.changes).forEach((change) => {
            const entry = record.entriesByFieldId[permittedFields[change]];

            if (entry) {
              entry.value = new Date(event.changes[change].getUTCTime()).toISOString();

              this.updateEntry(entry);
            }
          })
        }
      } else {
        // Event triggerred by `edit` from details popup
        EventBus.$emit("openModal", {
          modalName:  'RecordModal',
          modalProps: { id: record.id }
        });
      }
    },
    // DELETE EVENT
    onBeforeDeleteSchedule(event) {
      const record = this.currentTable.records.find(record => record.id === event.schedule.id);

      record.delete(false);
    },
    setCalendarsColors() {
      if (this.calendars) {
        this.calendars.forEach((calendar) => {
          this.$refs.calendar.invoke('setCalendarColor', calendar.id, {
            color: calendar.color,
            bgColor: calendar.bgColor
          });
        })
      }
    },
    updateCurrentMonth() {
      const date = moment(this.$refs.calendar.invoke('getDate').toDate())
      if (window.innerWidth > 920) {
        this.currentMonth = this.$d(date, 'monthYear');
      } else if (window.innerWidth < 336) {
        this.currentMonth = this.$d(date, 'monthSmall');
      }
      else {
        this.currentMonth = this.$d(date, 'monthYearSmall');
      }
    },
    getCalHeight() {
      const wrapperHeight = this.$refs.calendarWrapper.clientHeight
      const navHeight = this.$refs.calendarNavigation.clientHeight
      return wrapperHeight - navHeight;
    },
    datesForRecordEvent(record) {
      if (this.hasRangeDateOption) {
        var dates = this.buildEventDatesWithRangeDateOption(record);
      } else if (this.hasEndDateOption) {
        var dates = this.buildEventDatesWithEndDateOption(record);
      }
      return dates || {};
    },
    buildEventDatesWithRangeDateOption(record) {
      const dateTimeRangeEntry = record.entriesByFieldId[this.dateTimeRangeField.id];

      if (dateTimeRangeEntry) {
        const { value: { start_date_time, end_date_time } } = dateTimeRangeEntry;

        return { start: new Date(start_date_time), end: new Date(end_date_time) };
      }
    },
    buildEventDatesWithEndDateOption(record) {
      const { [this.dateStartAtField.id]: startAtEntry,
              [this.dateEndAtField.id]:   endAtEntry } = record.entriesByFieldId;

      if (startAtEntry && endAtEntry) {
        return { start: new Date(startAtEntry.value), end: new Date(endAtEntry.value) };
      }
    },
    computeCalendarId(record) {
      if (this.referenceField) {
        const entry = record.entriesByFieldId[this.referenceField.id];

        // filter calendars on first reference only (not use case for multi-references yet)
        if (entry && entry.value[0]) {
          return entry.value[0].foreign_record_id;
        }
      }
      return 0;
    },
    shouldCreateCalendar(entry, referencesByForeignId) {
              // entry exists and has value
      return entry && entry.value.length &&
             // and calendar has not already been created
             !referencesByForeignId.hasOwnProperty(entry.value[0].foreign_record_id);
    },
    toggleCalendar(calendarId) {
      this.selectedCalendarsByIds[calendarId] = !this.selectedCalendarsByIds[calendarId];
    }
  },
}
</script>

<style scoped lang="scss">
.none-display-desktop{
  display: none;
}

@media (max-width: 920px) {
  .none-display-mobile{
    display: none;
  }
  .none-display-desktop{
    display: block;
  }
}

.records-loader {
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(40, 40, 40, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: wait;
}
</style>
