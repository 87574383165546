<template lang="pug">
  div
    .text-xl.mt-3.text-gray-700(v-if="negatives.length > 0") {{ $t('dashboardBlocks.calendar.negatives') }}
    .negative.mb-2.pb-2.border-bottom(v-for="negative in negatives")
      .d-flex.justify-content-between.align-items-end
        .text-lg.font-weight-bolder {{ negative.site_name }}
        .text-gray-800 {{ negative.date }}

    .text-xl.mt-3.text-gray-700(v-if="availabilities.length > 0") {{ $t('dashboardBlocks.calendar.availabilities') }}
    .availability.mb-2.pb-2.border-bottom(v-for="availability in availabilities")
      .d-flex.justify-content-between.align-items-end
        .text-lg.font-weight-bolder {{ availability.site_name }}
        .text-gray-800 {{ availability.period }}

    .text-xl.mt-3.text-gray-700(v-if="unavailabilities.length > 0") {{ $t('dashboardBlocks.calendar.unavailabilities') }}
    .unavailability.mb-2.pb-2.border-bottom(v-for="unavailability in unavailabilities")
      .d-flex.justify-content-between.align-items-end
        .text-lg.font-weight-bolder {{ unavailability.site_name }}
        .text-gray-800 {{ unavailability.period }}

    .text-xl.mt-3.text-gray-700(v-if="activations.length > 0") {{ $t('dashboardBlocks.calendar.activations') }}
    .activation.mb-2.pb-2.border-bottom(v-for="activation in activations")
      .d-flex.justify-content-between.align-items-start
        .text-lg.font-weight-bolder
          div(v-for="site_name in activation.site_names") {{ site_name }}
        .text-gray-800 {{ activation.period }}
      .text-sm.text-gray-700(v-if="activation.notification_date") {{ $t('dashboardBlocks.calendar.notifiedOn') }} {{ activation.notification_date }}
      a.text-sm.text-indigo-700(
        v-if="activation.file_url"
        :href="activation.file_url"
        target="_blank"
        rel="noopener noreferrer"
      )
        i.fal.fa-file-download
        span.ml-1 {{ $t('dashboardBlocks.calendar.downloadReport') }}
</template>

<script>

export default {
  props: {
    day: {
      type:     Object,
      required: true
    }
  },
  computed: {
    availabilities() {
      return this.day.events.filter(event => event.kind === "available");
    },
    unavailabilities() {
      return this.day.events.filter(event => event.kind === "unavailable");
    },
    activations() {
      return this.day.events.filter(event => event.kind === "activated");
    },
    negatives() {
      const negativeValues = this.day.events.filter(event => event.kind === "negative")

      if (negativeValues.length > 0 && ( 'details' in negativeValues[0])) {
        return negativeValues[0].details;
      } else {
        return [];
      }
    },
  },
  methods: {
    dayIsPPTwo(day) {
      return day.events.filter(event => event.kind === "pp2").length > 0;
    },
    dayIsPPOne(day) {
      return day.events.filter(event => event.kind === "pp1").length > 0;
    },
  }
}
</script>
