<template lang="pug">
  span.view-action(
    v-if="currentView.locked || canLockViews"
    v-on="canLockViews ? { click: toggleViewLocking } : {}"
  )
    span#view-action--btn-view-locking.view-action--btn.has-tooltip(
      :class="{ 'disabled': !canLockViews }"
      data-toggle='tooltip'
      :title="lockingActionTitle"
    )
      i.fad.fa-fw.view-action--btn-icon(
        :class="currentView.locked ? 'fa-lock' : 'fa-lock-open'"
      )
</template>
<script>
import { mapState } from "vuex";
import { api }      from '../../api/client';
import i18n         from "../../locales/locales.js";

export default {
  computed: {
    ...mapState({
      currentView: state => state.viewStore.view,
      viewLocked: state => state.viewStore.view.locked,
      canLockViews: state => state.feature.canLockViews,
    }),
    lockingActionTitle() {
      if(this.canLockViews && this.currentView.locked) {
        return i18n.t('controlBarActions.unlockView');
      } else if (this.canLockViews && !this.currentView.locked) {
        return i18n.t('controlBarActions.lockView');
      } else {
        return i18n.t('controlBarActions.lockedView');
      }
    },
  },
  watch: {
    viewLocked(_newValue, _oldValue) {
      $('#view-action--btn-view-locking').attr('data-original-title', this.lockingActionTitle).tooltip();
    }
  },
  methods: {
    toggleViewLocking() {
      api.views.toggleViewLocking({
        view: this.currentView
      }).then(response => {
        this.currentView.locked = response.data.view.locked;
      });
    }
  },
};
</script>
