import ChartController from "../../../chart/controller";

export default class extends ChartController {
  get options () {
    const options = this.optionsValue;
    options.options = {
      tooltips: {
        position: "average",
        mode: "index",
        xPadding: 8,
        yPadding: 8,
        xAlign: "center",
        intersect: false,
        backgroundColor: "white",
        bodyFontColor: "rgb(26, 32, 44)",
        bodyFontStyle: "bold",
        bodyFontSize: 12,
        bodySpacing: 8,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: "1",
        titleFontColor: "rgb(26, 32, 44)",
        titleFontStyle: "normal",
        displayColors: false,
        callbacks: {
          label: this.tooltipLabelCallback.bind(this)
        }
      },
      scales: {
        yAxes: [{
          ticks: {
            min: 0,
            max: options.data.datasets[0].max_value,
          }
        }]
      },
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
    }
    return options;
  }

  tooltipLabelCallback(tooltipItem, data) {
    this.highlightUnavailabilities(tooltipItem, data);
    const { value } = tooltipItem;
    const unit = data.datasets[tooltipItem.datasetIndex].unit;

    return `${value} ${unit}`;
  }

  highlightUnavailabilities({ datasetIndex, index }, data) {
    const unavailabilitiesIds = data.datasets[datasetIndex].maintenances_ids[index]

    this.dispatch("point-hovered", { detail: { unavailabilitiesIds } });
  }
}
