import { tailwindColors } from './tailwindColors';

export default {
  theme: {
    'common.border': `1px solid ${tailwindColors.gray300}`,
    'common.holiday.color': tailwindColors.red600,
    'common.saturday.color': tailwindColors.red600,
    'common.dayname.color': tailwindColors.gray800,

    'month.dayname.height': '32px',
    'month.dayname.textAlign': 'center',
    'month.dayname.borderLeft': 'none',
    'month.dayname.backgroundColor': tailwindColors.gray100,
    'month.weekend.backgroundColor': tailwindColors.red100,

    'month.dayExceptThisMonth.color': `${tailwindColors.gray500}`,
    'month.holidayExceptThisMonth.color': `${tailwindColors.red400}`,

    'month.schedule.borderRadius': '4px',

    'week.daygridLeft.width': '50px',
    'week.timegridLeft.width': '50px',

    'week.dayname.height': '42px',
    // 'week.dayname.borderTop': '1px solid #e5e5e5',
    // 'week.dayname.borderBottom': '1px solid #e5e5e5',
    // 'week.dayname.borderLeft': 'inherit',
    // 'week.dayname.paddingLeft': '0',
    'week.dayname.backgroundColor': tailwindColors.gray100,
    'week.dayname.textAlign': 'center',
  },
  defaultCalendar: {
    id: 0,
    name: 'Défaut',
    color: '#000000',
    bgColor: tailwindColors.gray300
  },
  style: {},
  month: {
    startDayOfWeek: 1,
    daynames: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    narrowWeekend: true,
  },
  week: {
    startDayOfWeek: 1,
    daynames: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
    narrowWeekend: true,
    showTimezoneCollapseButton: false,
    timezonesCollapsed: false
  },
  calendarColors: [
    tailwindColors.blue700,
    tailwindColors.green700,
    tailwindColors.red700,
    tailwindColors.yellow700,
    tailwindColors.indigo700,
    tailwindColors.pink700,
    tailwindColors.orange700,
    tailwindColors.purple700,
  ]
};
