export const units = {
  null: {
    name: "Sans unité",
    symbol: "",
  },
  percentage: {
    name: "Pourcentage",
    symbol: "%",
  },
  dollar: {
    name: "Dollar",
    symbol: "$",
  },
  euro: {
    name: "Euro",
    symbol: "€",
  },
  kilowattHour: {
    name: "Kilowatt-heure",
    symbol: "kWh"
  },
  megawattHour: {
    name: "Megawatt-heure",
    symbol: "MWh"
  },
  eurosPerMegawattHour: {
    name: "Euro du Megawatt-heure",
    symbol: "€/MWh"
  },
  megaWatt: {
    name: "Megawatt",
    symbol: "MW"
  },
  kiloWatt: {
    name: "Kilowatt",
    symbol: "kW"
  },
  kiloWattPeak: {
    name: "Kilowatt crête",
    symbol: "kWc"
  },
  hours: {
    name: "Heures",
    symbol: "h"
  },
  minutes: {
    name: "Minutes",
    symbol: "min"
  },
  seconds: {
    name: "Secondes",
    symbol: "sec"
  },
}
