import { apiClient, startFetch, endFetch } from '../client';

export default {
  filtered({ query, tableId }) {
    return apiClient('/records/filtered', {
      params: {
        query:            JSON.stringify(query.filters),
        logical_operator: query.logicalOperator,
        table_id:         tableId
      }
    })
  },
  delete({ recordId, jobId = null, includeChildrenRecords = false }) {
    const saver = startFetch('SAVING');
    return apiClient.delete(
      '/records/' + recordId,
      {
        params: {
          job_id: jobId,
          include_children_records: includeChildrenRecords,
        }
      }
    ).then(endFetch(saver))
  },
  confirmCreation({ recordId, jobId }) {
    const saver = startFetch('SAVING');
    return apiClient.patch(
      '/records/' + recordId + '/confirm_creation',
      {
        params: { job_id: jobId }
      }
    ).then(endFetch(saver))
  },
  presence({ viewId, recordId, filters, logicalOperator }) {
    return apiClient.get(
      '/views/' + viewId + '/record_presences/' + recordId,
      {
        params: {
          query_string:     filters,
          logical_operator: logicalOperator
        }
      }
    )
  },
  presences({ viewId, recordIds, filters, logicalOperator }) {
    return apiClient.get(
      '/views/' + viewId + '/record_presences',
      {
        params: {
          query_string:     filters,
          logical_operator: logicalOperator,
          record_ids:       recordIds
        }
      }
    )
  },
  withTableData({ recordId, tableId, viewId }) {
    return apiClient.get(
      '/tables/' + tableId + '/records/' + recordId + '/foreign_record',
      {
        params: {
          view_id: viewId,
        }
      }
    )
  },
  duplicate({ recordId, tableId }) {
    const saver = startFetch('SAVING');
    return apiClient.post(
      '/tables/' + tableId + '/records/' + recordId + '/duplicate',
    ).then(endFetch(saver))
  },
}
