import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["overlay", "input"]

  toggleChildDevice(event) {
    if (event.target.checked) {
      this.overlayTarget.classList.add("tw-hidden");
      if (this.hasInputTarget) {
        this.inputTarget.disabled = false;
      }
    } else {
      this.overlayTarget.classList.remove("tw-hidden");
      if (this.hasInputTarget) {
        this.inputTarget.disabled = true;
      }
    }
  }
}
