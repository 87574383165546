var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"my-3"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('editOptions.selectTable')))]),_c('v-select',{attrs:{"input-id":'edit-reference-table' + _vm.editedField.id,"appendToBody":"","searching":true,"options":_vm.currentTables,"reduce":function (option) { return option.id; },"get-option-label":function (option) { return option.name; },"disabled":!_vm.canEditField},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(_vm._s(_vm.$t('noOptionForSelect')))]}}]),model:{value:(_vm.editedField.typeOptions.foreign_table_id),callback:function ($$v) {_vm.$set(_vm.editedField.typeOptions, "foreign_table_id", $$v)},expression:"editedField.typeOptions.foreign_table_id"}})],1),_c('div',{staticClass:"mb-1 font-weight-bold"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editedField.typeOptions.limit_to_one_record),expression:"editedField.typeOptions.limit_to_one_record"}],attrs:{"type":"checkbox","name":"limitToOneRecord","disabled":!_vm.canEditField},domProps:{"checked":Array.isArray(_vm.editedField.typeOptions.limit_to_one_record)?_vm._i(_vm.editedField.typeOptions.limit_to_one_record,null)>-1:(_vm.editedField.typeOptions.limit_to_one_record)},on:{"change":function($event){var $$a=_vm.editedField.typeOptions.limit_to_one_record,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.editedField.typeOptions, "limit_to_one_record", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.editedField.typeOptions, "limit_to_one_record", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.editedField.typeOptions, "limit_to_one_record", $$c)}}}}),_c('label',{staticClass:"ml-2",attrs:{"for":"limitToOneRecord"}},[_vm._v(_vm._s(_vm.$t('editOptions.oneEntry')))])]),_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('editOptions.selectVue')))]),_c('v-select',{attrs:{"input-id":'edit-reference-view' + _vm.editedField.id,"appendToBody":"","searching":true,"options":_vm.foreignTableViews,"reduce":function (option) { return option.id; },"disabled":!_vm.canEditField,"get-option-label":function (option) { return option.name; },"placeholder":_vm.$t('editOptions.noView')},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(_vm._s(_vm.$t('noOptionForSelect')))]}}]),model:{value:(_vm.editedField.typeOptions.view_id_for_selection),callback:function ($$v) {_vm.$set(_vm.editedField.typeOptions, "view_id_for_selection", $$v)},expression:"editedField.typeOptions.view_id_for_selection"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }