const initCreateUserForm = () => {
  const profileTableForm = document.querySelector('.js-profile-table-form');

  if (profileTableForm) {
    const lookProfileTableForm = () => {
      profileTableForm.addEventListener('change', () => {
        const tableId = profileTableForm.value;
        const fieldsUrl = profileTableForm.dataset.fieldsUrl;
        const recordSelectorContainer = document.querySelector('.js-record-selector-container');
        const fieldSelectorContainer = document.querySelector('.js-user-type-field-selector-container');

        if (tableId) {
          fetch(`/tables/${tableId}/records`)
            .then(response => response.json())
            .then((data) => displayRecordSelector(data.records, recordSelectorContainer));

          fetch(`${fieldsUrl}/?table_id=${tableId}&field_type=users`)
            .then(response => response.json())
            .then((data) => displayUserTypeFieldSelector(data.fields, fieldSelectorContainer));
        } else {
          removeSelectList(tableId, recordSelectorContainer);
          removeSelectList(tableId, fieldSelectorContainer);
        }
      })
    }

    const removeSelectList = (tableId, selectorContainer) => {
      selectorContainer.innerHTML = "";
    }

    const displayRecordSelector = (records, selectorContainer) => {
      const selectRecord = profileTableForm.dataset.selectRecord;

      const fieldOptionsHTML = records.map((element) => `<option value=${element.id}>${element.display_name}</option>`).join("");

      selectorContainer.innerHTML = `
        <label for="record_selector">${selectRecord}</label>
        <div class="glint-select--wrapper">
          <select name="record_selector" id="record_selector" class="tw-tom-select js-profile-record-form" data-controller="tom-select">
            <option value="">${selectRecord}</option>
            ${fieldOptionsHTML}
          </select>
        </div>
      `;
    };

    const displayUserTypeFieldSelector = (fields, selectorContainer) => {
      const selectUserType = profileTableForm.dataset.selectUserType;

      if (fields.length !== 1) {
        const fieldOptionsHTML = fields.map((element) => `<option value=${element.id}>${element.name}</option>`).join("");

        selectorContainer.innerHTML = `
          <label for="field_selector">${selectUserType}</label>
          <div class="glint-select--wrapper">
            <select name="field_selector" id="field_selector" class="tw-tom-select js-profile-field-form" data-controller="tom-select">
              <option value="">${selectUserType}</option>
              ${fieldOptionsHTML}
            </select>
          </div>
        `;
      } else {
        selectorContainer.innerHTML = `
          <input type="hidden" name="field_selector" id="field_selector" value="${fields[0].id}">
        `;
      }
    };

    lookProfileTableForm();
  }
}

export { initCreateUserForm };
