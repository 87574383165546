<template lang="pug">
div
  .form-group
    label(
      for="flex_contacts_table_id"
    ) {{ $t('dashboardBlocks.flex_contacts.labels.flex_contacts_table_id') }}
    v-select(
      :searching="true"
      :options="tables"
      id="flex_contacts_table_id"
      label="name"
      :reduce="option => option.id"
      v-model="editableSettings.flex_contacts_table_id"
      :append-to-body="true"
    )
  .form-group
    label(
      for="download_view_id"
    ) {{ $t('dashboardBlocks.flex_contacts.labels.download_view_id') }}
    v-select(
      :searching="true"
      :options="contactViews"
      id="download_view_id"
      label="name"
      :reduce="option => option.id"
      v-model="editableSettings.download_view_id"
      :append-to-body="true"
    )
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    contactsTable() {
      if (this.editableSettings.flex_contacts_table_id) {
        return this.getTableById(this.editableSettings.flex_contacts_table_id);
      }
    },
    contactViews() {
      return this.contactsTable?.views || [];
    }
  }
}
</script>
