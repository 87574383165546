import consumer from './consumer';
import { notificationAfterCallback } from '../../components/notifications';

export const buildUserNotificationsChannel = (userId) => {
  return consumer.subscriptions.create(
    {
      channel: "UserNotificationsChannel",
      user_id: userId,
    },
    {
      connected() {
      },
      received(payload) {
        if (payload.action === "add_notification") {
          notificationAfterCallback();
        }
      }
    }
  );
};
