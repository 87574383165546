<template lang="pug">
.cell-content
  date-picker(
    v-model="tmpDate"
    :lang="this.$i18n.locale"
    :type="dateInputType"
    value-type="date"
    :format="dateFormat"
    :placeholder="dateFormat"
    :minute-step="5"
    :show-second="false"
    :confirm="true"
    @close="handleClose"
    @open="disableGridClickOutside"
    @clear="handleChange"
  )
</template>

<script>
import { DatepickerMixin } from '../../../mixins/entries/DatepickerMixin';

export default {
  mixins: [DatepickerMixin],
  mounted() {
    this.disableGridClickOutside();
    document.querySelector('input.mx-input').focus();
  },
  beforeDestroy() {
    this.enableGridClickOutside();
  },
  methods: {
    disableGridClickOutside() {
      this.$store.commit('SET_DISABLE_GRID_CLICK_OUTSIDE', true);
    },
    enableGridClickOutside() {
      this.$store.commit('SET_DISABLE_GRID_CLICK_OUTSIDE', false);
    },
    handleClose() {
      this.handleChange();
      this.enableGridClickOutside();
    }
  }
};
</script>
