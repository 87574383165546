import { Controller } from "@hotwired/stimulus";
import Grid from "../../webpacker/src/javascript/components/kuartz_grid/grid";

export default class extends Controller {
  static values = {
    columnData: Array,
    cellData: Array,
    modelName: String,
  };

  connect() {
    new Grid(
      this.element,
      this.columnDataValue,
      this.cellDataValue,
      { modelName: this.modelNameValue }
    )
  }
}
