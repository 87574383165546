function getFileExtension (filename) {
  return filename.toLowerCase().split('.').pop()
}

const getFileType = (filename, contentType) => {
  const extension   = getFileExtension(filename)

  if (contentType.includes("image")) {
    return "image"
  } else if (contentType.includes("audio")) {
    return "file-audio"
  } else if (contentType.includes("video")) {
    return "file-video"
  } else if (['pdf'].includes(extension)) {
    return "file-pdf"
  } else if (['doc', 'docx', 'odf'].includes(extension)) {
    return "file-word"
  } else if (['ppt', 'pptx'].includes(extension)) {
    return "file-powerpoint"
  } else if (['xls', 'xlsx', 'ods'].includes(extension)) {
    return "file-excel"
  } else if (['zip', 'rar', '7zip', 'tar', 'gz', '7z'].includes(extension)) {
    return "file-archive"
  } else if (['csv'].includes(extension)) {
    return "file-csv"
  } else if (['rb'].includes(extension)) {
    return "file-code"
  } else {
    return "file"
  }
}

const getFontawesomeIcon = (file) => {
  return 'fa-' + getFileType(file.filename, file.content_type)
}

export { getFileType, getFontawesomeIcon }
