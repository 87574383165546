import { isArray, sortBy, minBy, filter, isEmpty } from "lodash";
import Entry                                       from './entry';
import i18n                                        from "../locales/locales.js";
import store                                       from "../store/store";
import Vue                                         from "vue/dist/vue.esm";
import { api }                                     from "../api/client";
import { EventBus }                                from '../main.js';
import { requireConfirmation }                     from '../../components/require_confirmation';
export default class Record {
  constructor(baseRecord, table, { buildEntries = false, creationConfirmed = false }) {
    this.id                           = baseRecord.id;
    this.hovered                      = false;
    this.visible                      = false;
    this.table                        = table;
    this.locked                       = false;
    this.entriesByFieldId             = {};
    this.waitingForDeleteConfirmation = false;
    this.creationConfirmed            = creationConfirmed;

    this.baseEntryValuesByFieldId = baseRecord.entry_values_by_field_id;
    this.needsBuild = true

    if (buildEntries) this.buildEntries(table.fields, baseRecord.entry_values_by_field_id);
  }

  lock() {
    this.locked = true;

    this.entries.forEach(entry => entry.setAccessRight('read') );
  }

  unlock() {
    this.locked = false;

    this.entries.forEach(entry => entry.restoreAccessRight());
  }

  delete(confirmationNeeded = true, callback = () => {}) {
    const relationalReferencesEntry = this.entriesByFieldId[this.table.relationalData.foreign_table_reference_field_id];
    const parentRecordOptionsNeeded = this.table.isParent && relationalReferencesEntry && !isEmpty(relationalReferencesEntry.value);

    return api.repeatableRecords.repetitions({ recordId: this.id }).then(response => {
      const destroyRepetitionsOptionsNeeded = !!response.data.has_repetitions;

      if (parentRecordOptionsNeeded || destroyRepetitionsOptionsNeeded) {
        const withFollowingOccurrencesOptionAvailable = !!response.data.repeated_entry_value_present;
        const childTableName = this.table.isParent && this.table.childTable.name;

        EventBus.$emit("openModal", {
          modalName:  'DeleteRecordOptionsModal',
          modalProps: {
            record: this,
            callback,
            parentRecordOptionsNeeded,
            destroyRepetitionsOptionsNeeded,
            withFollowingOccurrencesOptionAvailable,
            childTableName,
          }
        });
      } else if (confirmationNeeded) {
        return requireConfirmation(i18n.t('actionsRow.confirmDelete'), i18n.t('apply'), i18n.t('cancel')).
        then(result => { if (result.isConfirmed) this.deleteAndRemoveFromStore(callback) });
      } else {
        this.deleteAndRemoveFromStore(callback);
      }
    });
  }

  deleteAndRemoveFromStore(callback, destroyRepetitionsOption = null, includeChildrenRecords = false) {
    if (!isEmpty(destroyRepetitionsOption)) {
      api.repeatableRecords.delete({
        recordId: this.id,
        destroyRepetitionsOption: destroyRepetitionsOption,
        includeChildrenRecords: includeChildrenRecords
      }).then(_response => {
        store.dispatch("recordStore/deleteRecord", this.id);
        callback();
      });
    } else {
      api.records.delete({
        recordId: this.id,
        includeChildrenRecords: includeChildrenRecords
      }).then(_response => {
        store.dispatch("recordStore/deleteRecord", this.id);
        callback();
      });
    }
  }

  get displayName() {
    return this.primaryEntry?.toString() || i18n.t('models.record.noDisplayName');
  }

  get orderedEntries() {
    return sortBy(this.entries, "field.columnNumber");
  }

  get primaryEntry() {
    return this.entries.find(entry => entry.field.primary);
  }

  get visibleEntries() {
    return filter(this.orderedEntries, "field.isDisplayable");
  }

  get entries() {
    return Object.values(this.entriesByFieldId);
  }

  buildEntries(fields = this.table.fields, entryValuesByFieldId = this.baseEntryValuesByFieldId) {
    if (this.needsBuild) {
      const values = entryValuesByFieldId || {};

      fields.forEach(field => {
        const entryValue = values.hasOwnProperty(field.id) ? values[field.id] : field.defaultEntryValue();

        Vue.set(this.entriesByFieldId, field.id, new Entry(entryValue, this, field));
      });

      // clean to free RAM
      Vue.delete(this, 'baseEntryValuesByFieldId');
      Vue.delete(this, 'needsBuild');
    };

  }

  static build(baseRecords, table, { buildEntries = true, creationConfirmed = false } = {}) {
    const recordBuilder = (baseRecord) => new Record(baseRecord, table, { buildEntries, creationConfirmed });
    if (isArray(baseRecords)) {
      return baseRecords.map(recordBuilder)
    }

    return recordBuilder(baseRecords); // if only one record
  }
}
