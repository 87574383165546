<script>
export default {
  name: 'OnClickOutside',
  props: {
    remoteElement: {
      type: HTMLDivElement
    },
    do: {
      type: Function,
      required: true,
    },
  },
  mounted() {
    document.addEventListener('click', this.doOnClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.doOnClickOutside);
  },
  methods: {
    doOnClickOutside(event) {
      const isInElement      = this.$el.contains(event.target);
      const isInRemoteTarget = this.remoteElement && this.remoteElement.contains(event.target);
      const isClickedOutside = !(event.target === this.$el || isInElement || isInRemoteTarget);

      if (isClickedOutside) { this.do(); }
    }
  },
  render() {
    return this.$slots.default[0];
  },
};
</script>
