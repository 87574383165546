import { mapState } from "vuex";

export const selectInputMixin = {
  data() {
    return {
      selectOpened:      false,
      inputElement:      null,
      shouldAddListener: true
    };
  },
  computed: {
    ...mapState({
      openedModals: state => state.openedModals
    })
  },
  methods: {
    openSelect(event) {
      if (event.code === "Enter" && !this.openedModals.length) {
        const el = document.querySelector("#selectCellInput:not(:focus)");
        if (el && !this.selectOpened) el.focus();
      }
    },
    focusSelect(event) {
      this.selectOpened = true;
    },
    blurSelect(event) {
      setTimeout(() => (this.selectOpened = false), 200);
    },
    addListeners() {
      const el = document.getElementById("selectCellInput");
      this.inputElement = el;
      el.addEventListener("focus", this.focusSelect);
      el.addEventListener("blur", this.blurSelect);
      document.addEventListener("keyup", this.openSelect);
    }
  },
  mounted() {
    if (this.shouldAddListener) {
      this.addListeners();
    }
  },
  beforeDestroy() {
    if (this.shouldAddListener) {
      document.removeEventListener("keyup", this.openSelect);
      this.inputElement.removeEventListener('focus', this.focusSelect);
      this.inputElement.removeEventListener('blur', this.focusSelect);
      this.inputElement = null;
    }
  }
};
