import colorPicker from 'tui-color-picker'
import 'tui-color-picker/dist/tui-color-picker.css'

const colorPreset                   = [
  "#A0AEC0", // gray-500
  "#2D3748", // gray-800
  "#F56565", // red-500
  "#9B2C2C", // red-800
  "#742A2A", // red-900
  "#ED8936", // orange-500
  "#9C4221", // orange-800
  "#ECC94B", // yellow-500
  "#975A16", // yellow-800
  "#48BB78", // green-500
  "#276749", // green-800
  "#22543D", // green-900
  "#38B2AC", // teal-500
  "#285E61", // teal-800
  "#234E52", // teal-900
  "#4299E1", // blue-500
  "#2C5282", // blue-800
  "#2A4365", // blue-900
  "#667EEA", // indigo-500
  "#434190", // indigo-800
  "#9F7AEA", // purple-500
  "#553C9A", // purple-800
  "#ED64A6", // pink-500
  "#97266D", // pink-800
];

const initColorPicker = () => {
  const primaryColorPickerContainer   = document.getElementById('tui-primary-color-picker-container');
  const secondaryColorPickerContainer = document.getElementById('tui-secondary-color-picker-container');

  if (primaryColorPickerContainer) {
    const primaryColorPickerInstance = colorPicker.create({
      container: primaryColorPickerContainer,
      color:     primaryColorPickerContainer.dataset.primaryColor,
      preset:    colorPreset
    });

    const primaryColorInput = document.getElementById('project_primary_color');

    primaryColorPickerInstance.getColor();

    primaryColorPickerInstance.on('selectColor', (ev) => {
      primaryColorInput.value = ev.color;

      const navProjectNameEl = document.querySelector(".project-name h3");
      if (navProjectNameEl) navProjectNameEl.style.color = ev.color;
    })
  }

  if (secondaryColorPickerContainer) {
    const secondaryColorPickerInstance = colorPicker.create({
      container: secondaryColorPickerContainer,
      color:     secondaryColorPickerContainer.dataset.secondaryColor,
      preset:    colorPreset
    });

    const secondaryColorInput = document.getElementById('project_secondary_color');

    secondaryColorPickerInstance.getColor();

    secondaryColorPickerInstance.on('selectColor', (ev) => {
      secondaryColorInput.value = ev.color;
    });
  }
}

export { initColorPicker };
