import { each, debounce } from 'lodash';
import { api }            from '../../api/client';

export const namespaced = true;

export const state = {
  recordIdsForLockCheck: new Set
}

export const actions = {
  pushRecordIdForLockCheck({ state, dispatch }, { recordId }) {
    state.recordIdsForLockCheck.add(recordId);
    dispatch('checkRecordsLock');
  },
  checkRecordsLock: debounce(function({ state, rootState, rootGetters }) {
    // is needed to lock records after they have been updated by websocket
    if (state.recordIdsForLockCheck.size) {
      api.tables.lockedRecords({
        tableId:   rootState.tableStore.table.id,
        recordIds: Array.from(state.recordIdsForLockCheck)
      }).then((response) => {
        each(response.data, (locked, recordId) => {
          recordId     = Number.parseInt(recordId, 10);
          const record = rootGetters['recordStore/allRecordsAvailable'].find((record) => {
            return record.id === recordId
          });
          if (record) locked ? record.lock() : record.unlock();
          state.recordIdsForLockCheck.delete(recordId);
        });
      });
    }
  }, 300, { leading: true })
};
