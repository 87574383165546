<template lang="pug">
  .entry-input--reference
    .select-wrapper.reference-entry-content
      .entry-reference(
        v-for="user in entry.displayValue"
        :key="user.id"
      ) {{ user.displayName }}
        span.ml-1.remove-reference-btn(
          v-if="entry.isWritable"
          @click.stop="removeUser(user, entry)"
        )
          i.far.fa-times

      .add-reference-btn.ml-1(
        v-if="canAddUser"
        @click="openAddUserModal"
      )
        i.fa.fa-plus

      .reference-entry-expand-content(
        v-if="entry.value.length"
        @click="openEntryUsersModal"
      )
        i.fad.fa-expand-alt
</template>

<script>
import { UpdateEntryMixin } from "../../../mixins/UpdateEntryMixin";
import { UsersMixin }       from "../../../mixins/entries/UsersMixin";
import { EventBus }         from '../../../main';

export default {
  mixins: [UpdateEntryMixin, UsersMixin],
  props: {
    entry: {
      type: Object,
      requried: true
    },
    recordModalPanel: {
      type: String
    }
  },
  methods: {
    openAddUserModal() {
      EventBus.$emit('openModal', {
        modalName: 'AddUserModal',
        modalProps: {
          entry: this.entry,
        }
      });
    },
  }
};
</script>
