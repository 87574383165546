<template lang="pug">
div
  .record-filters--wrapper(
    :style="{ 'overflow-y': editableFilters.length < 10 ? 'visible' : 'scroll' }"
  )
    slot(name="tableFilters")
    p.record-filters--no-filters.mb-0.font-italic.pl-1(
      v-if="editableFilters.length === 0"
    ) {{ $t('controlBarActions.noAttribute') }}

    .record-filters--filter.my-2(v-for="filter, index in editableFilters")
      .record-filters--remove-filter.mr-1.cursor-pointer(@click.stop="removeFilter(index)")
        i.fal.fa-times

      v-select.filter-input-fixed-width.mx-2.flex-shrink-0(
        :searching="true"
        :options="filterableFields"
        :get-option-label="option => option.name"
        :get-option-key="option => option.id"
        :value="filter.field"
        :clearable="false"
        :append-to-body="true"
        @input="changeFilterField(filter, $event)"
      )
        template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}

      component(
        :is="`filter-input-${filter.dataType}`"
        :filter="filter"
      )

  .record-filters--footer.py-2
    p.record-filters--add-filter.cursor-pointer.mb-0.mr-3(@click="addFilter") + {{ $t('addAttribute') }}
    .spinner-border.spinner-border-sm.text-danger(v-if="loadingRecords" role="status")
      span.sr-only {{ $t('loading') + "..." }}
  .record-filters--footer.justify-content-end.pt-2.mt-2.border-top.border-light(v-if="queryChanged")
    button.btn.btn-primary(
      v-if="filterCanBeSaved"
      @click.stop="$emit('save-query', currentQueryPayload)"
      :disabled="!queryStringCanBeApplied"
    ) {{ $t('save') }}
</template>

<script>
import { RecordFiltersMixin } from '../mixins/RecordFiltersMixin';
import { equalOperatorsByDataType } from '../services/filterOperators';

export default {
  mixins: [RecordFiltersMixin],
  data() {
    return {
      editableFilters: [],
      inputlessOperators: ['empty', 'not empty', 'today', 'is past', 'is not past', 'contains today', 'contains now'],
      equalOperatorsByDataType: equalOperatorsByDataType,
      showLogicalOperatorSelect: false,
      filtersDefaultValuesbyDataType: {
        boolean: false
      }
    }
  },
  computed: {
    currentQueryPayload() {
      const filtersPayload = this.editableFilters.map((filter) => {
        return {
          field_id: filter.field.id,
          operator: filter.operator,
          value:    filter.value
        }
      });
      return {
        filters: filtersPayload,
      }
    },
  },
  methods: {
    filterAttributes(field) {
      const dataType = this.operatorDataType(field);
      const value    = this.filterDefaultValue(dataType);
      const operator = equalOperatorsByDataType[dataType][0].operator;

      return { dataType, value, operator, field };
    },
    buildEditableQuery(query) {
      this.editableFilters = query.filters.map((this.buildFilter));
    },
  }
}
</script>
