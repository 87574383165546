<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockLists"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.list_id"
      )
    .form-group
      label {{ $t('dashboardBlocks.kpi.labels.kind') }}
      v-select(
        :searching="true"
        :options="dataTypeOptions"
        label="name"
        :reduce="option => option.value"
        v-model="editableSettings.kind"
      )
    template(v-if="editableSettings.kind == 'production'")
      template(v-if="selectedDashboardBlockList && selectedTable")
        .form-group(
          v-for="settingProperty in productionFieldSettingProperties"
          :key="settingProperty"
        )
          label(:for="settingProperty") {{ $t('dashboardBlocks.kpi.labels.' + settingProperty) }}
          v-select(
            :searching="true"
            label="name"
            :inputId="settingProperty"
            :options="fieldsForSelectedTable"
            :reduce="option => option.id"
            v-model="editableSettings[settingProperty]"
          )

    template(v-else-if="editableSettings.kind == 'contract'")
      .form-group
        label {{ $t('dashboardBlocks.kpi.labels.data_contracts_table_id') }}
        v-select(
          :searching="true"
          :options="tables"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.table_id"
        )
      template(v-if="editableSettings.table_id")
        .form-group(
          v-for="settingProperty in contractFieldSettingProperties"
          :key="settingProperty"
        )
          label(:for="settingProperty") {{ $t('dashboardBlocks.kpi.labels.' + settingProperty) }}
          v-select(
            :searching="true"
            label="name"
            :inputId="settingProperty"
            :options="fieldsForContractTable"
            :reduce="option => option.id"
            v-model="editableSettings[settingProperty]"
          )

    template(v-else-if="editableSettings.kind == 'enr_performance'")
      .form-group
        label {{ $t('dashboardBlocks.kpi.labels.performance_table_id') }}
        v-select(
          :searching="true"
          :options="tables"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.table_id"
        )
      template(v-if="editableSettings.table_id")
        .form-group(
          v-for="settingProperty in performanceContractFieldSettingProperties"
          :key="settingProperty"
        )
          label(:for="settingProperty") {{ $t('dashboardBlocks.kpi.labels.' + settingProperty) }}
          v-select(
            :searching="true"
            label="name"
            :inputId="settingProperty"
            :options="fieldsForContractTable"
            :reduce="option => option.id"
            v-model="editableSettings[settingProperty]"
          )
      template(v-if="editableSettings.kind")
        .form-group(
          v-for="settingProperty in performanceSiteFieldSettingProperties"
          :key="settingProperty"
        )
          label {{ $t('dashboardBlocks.kpi.labels.' + settingProperty)}}
          v-select(
            :searching="true"
            label="name"
            :inputId="settingProperty"
            :options="fieldsForSelectedTable"
            :reduce="option => option.id"
            v-model="editableSettings[settingProperty]"
          )
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DashboardBlock           from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      productionFieldSettingProperties: [
        'api_source_id_field_id',
        'kind_technology_field_id'
      ],
      contractFieldSettingProperties: [
        'engagement_value_field_id',
        'engagement_kind_field_id',
        'wiped_power_field_id',
        'trigger_power_field_id',
        'contract_year_field_id'
      ],
      performanceContractFieldSettingProperties: [
        'performance_value_field_id',
        'start_date_field_id',
        'end_date_field_id',
        'referenced_site_field_id'
      ],
      performanceSiteFieldSettingProperties: [
        'api_source_id_field_id',
        'solar_installed_capacity_field_id',
        'other_installed_capacity_field_id',
        'kind_technology_field_id'
      ]

    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    selectedDashboardBlockList() {
      if (this.editableSettings.list_id) {
        return DashboardBlock.find(this.editableSettings.list_id);
      }
    },
    selectedTable() {
      if (this.selectedDashboardBlockList && this.selectedDashboardBlockList.kind_settings.table_id) {
        const tableId = this.selectedDashboardBlockList.kind_settings.table_id;
        return this.tables.find(table => table.id === tableId);
      }
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
    tableForContract() {
      if (this.editableSettings.table_id) {
        return this.getTableById(this.editableSettings.table_id);
      }
    },
    fieldsForContractTable() {
      if (this.editableSettings.table_id) {
        return this.tableForContract.fields;
      }
      return [];
    },
    dataTypeOptions() {
      const dataTypes = ['production', 'contract', 'enr_performance'];

      return dataTypes.map((dataType) => {
        return {
          name: this.$t(`dashboardBlocks.kpi.dataTypes.${dataType}`),
          value: dataType
        }
      })
    },
  },
  watch: {
    'editableSettings.kind': function(newValue) {
      this.contractFieldSettingProperties.forEach(property => this.editableSettings[property] = null);
      this.productionFieldSettingProperties.forEach(property => this.editableSettings[property] = null);
    },
  }
}
</script>
