import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  change(event) {
    this.submitForm(event);
  }

  submitForm(event) {
    // <form>.requestSubmit() is not supported on Safari prior to version 16 (2022/09/12),
    // but requestSubmit triggers html form validations, which are nice for this form,
    // so if available, use requestSubmit, else fallback to classic submit.
    // NB: even on Safari 16, requestSubmit does not support passing a submit btn
    // to simulate a submit from this btn.
    // NB2: feels like Safari wanna play IE style ...

    if (event.target.form.requestSubmit) {
      event.target.form.requestSubmit();
    } else {
      event.target.form.submit();
    }
  }

}
