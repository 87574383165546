<template lang="pug">
.d-flex(v-if="shouldDisplaySelectInput")
  slot
  v-select.filter-input-fixed-width.flex-shrink-0.p-0(
    v-if="shouldDisplayInput"
    :searching="true"
    :clearable="false"
    :options="optionsForSelect"
    :reduce="reduceOption"
    :get-option-label="getOptionLabel"
    :get-option-key="getOptionKey"
    :append-to-body="true"
    v-model="filter.value"
  )
    template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}
RecordFilterBasicInput(
  v-else
  :filter="filter"
)
  slot
</template>

<script>
import { api }                from '../../api/client';
import { RecordFilterMixin }  from '../../mixins/RecordFilterMixin';
import User                   from '../../models/user';
import RecordFilterBasicInput from '../record_filters_inputs/RecordFilterBasicInput';

export default {
  components: {
    RecordFilterBasicInput
  },
  mixins: [RecordFilterMixin],
  data() {
    return {
      foreignFieldForSelectList: null,
      recordsForReference:       []
    }
  },
  computed: {
    shouldDisplaySelectInput() {
      switch (this.filter.dataType) {
        case 'select':
          return ['=', '!='].includes(this.filter.operator)
        case 'users':
          return ['LIKE', 'NOT LIKE'].includes(this.filter.operator)
      }
      return true;
    },
    optionsForSelect() {
      switch (this.filter.dataType) {
        case 'select':
          return this.optionsForSelectDataType;
        case 'users':
          return this.userForReferences;
        case 'references':
          return this.recordsForReference;
      }
      return [];
    },
    optionsForSelectDataType() {
      if (this.field.dataType === 'select') return this.field.typeOptions.select_list;
      if (this.field.dataType === 'lookups' && this.filter.dataType === 'select') {
        this.findForeignFieldForSelectList();

        if (this.foreignFieldForSelectList) return this.foreignFieldForSelectList.typeOptions.select_list;
      }
    },
    userForReferences() {
      if (this.filter.dataType === 'users') {
        const currentUser = { id: 'current_user_id', displayName: this.$t('current_user') };

        return [currentUser].concat(User.all());
      }
    }
  },
  created() {
    if (this.filter.dataType === 'references') this.buildRecordsForReference();
  },
  methods: {
    getOptionLabel(option) {
      switch (this.filter.dataType) {
        case 'users':
          return option.displayName;
        case 'references':
          return option.foreign_record_display_name;
        case 'select':
        default:
          return option;
      }
    },
    getOptionKey(option) {
      switch (this.filter.dataType) {
        case 'users':
          return option.id;
        case 'references':
          return option.foreign_record_id;
        case 'select':
        default:
          return option;
      }
    },
    reduceOption(option) {
      switch (this.filter.dataType) {
        case 'users':
          return option.id;
        case 'references':
          return option.foreign_record_id;
        case 'select':
        default:
          return option;
      }
    },
    async findForeignFieldForSelectList() {
      if (this.foreignFieldForSelectList) return;
      if (this.field.dataType === 'lookups' && this.filter.dataType === 'select') {
        const fieldForSelectList = await this.field.findLookupOriginalField();

        this.foreignFieldForSelectList = fieldForSelectList;
      }
    },
    async buildRecordsForReference() {
      if (this.field.dataType === 'references') this.fetchRecordsForReference(this.field);
      if (this.field.dataType === 'lookups' && this.filter.dataType === 'references') {
        const lookupsField = await this.field.findLookupOriginalField();

        if (lookupsField) this.fetchRecordsForReference(lookupsField);
      }
    },
    fetchRecordsForReference(referenceField) {
      const { foreign_table_id:      tableId,
              view_id_for_selection: viewId } = referenceField.typeOptions;

      api.tables.recordsForReference({ tableId, viewId }).then((response) => {
        return this.recordsForReference = response.data.records;
      });
    },
  }
}
</script>
