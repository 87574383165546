import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    typeRegionalProduction: Array,
  }

  connect() {
    this.draw();
  }

  draw() {
    const productionPills = document.getElementById("production-pills")
    const productions = this.typeRegionalProductionValue.map(regionData => regionData.production)
    const maxProduction = Math.max(...productions)

    productionPills.innerHTML = ""
    Object.values(this.regionPaths).forEach((regionId) => {
      const regionPath = document.getElementById(regionId)
      regionPath.setAttribute("fill", "#A8A29E")
    })

    this.typeRegionalProductionValue.forEach((regionData) => {
      if (regionData.name && regionData.rank) {
        const regionId = this.regionPaths[regionData.name];
        const regionPath = document.getElementById(regionId);
        const BBox = regionPath.getBBox();
        const x = BBox.x + (BBox.width / 2);
        const y = BBox.y + (BBox.height / 2);
        const rankColor = this.rankColor(regionData.production, regionData.rank);

        regionPath.setAttribute("fill", rankColor.regionBackgroundColor)
        const pillHTML = `<g id="${regionId}-info">
          <circle cx="${x}" cy="${y}" r="${this.circleRadius(regionData.production, maxProduction)}"
            fill="${rankColor.pillBackgroundColor}"/>

          <text style="font-size: 18px" x="${x}" y="${y}" text-anchor="middle" dominant-baseline="middle"
            fill="#ffffff">
            ${regionData.production}
          </text>
        </g>`
        productionPills.insertAdjacentHTML("beforeend", pillHTML)
      }
    })
  }

  circleRadius(production, maxProduction) {
    const maxRadius = 48;
    const minRadius = 20;
    const productionRatio = maxProduction == 0 ? 0 : (production / maxProduction);

    return minRadius + (productionRatio * (maxRadius - minRadius));
  }

  rankColor(production, rank) {
    const rankIndex = production == 0 ? this.rankColors.length - 1 : rank - 1;
    const regionBackgroundColor = this.rankColors[rankIndex].regionBackgroundColor;
    const pillBackgroundColor = this.rankColors[rankIndex].pillBackgroundColor;

    return {
      regionBackgroundColor,
      pillBackgroundColor,
    }
  }

  get rankColors() {
    return [
      { rank: 1, regionBackgroundColor: "#111827", pillBackgroundColor: "#2F855A" },
      { rank: 2, regionBackgroundColor: "#1e2534", pillBackgroundColor: "#2F855A" },
      { rank: 3, regionBackgroundColor: "#2c3341", pillBackgroundColor: "#2F855A" },
      { rank: 4, regionBackgroundColor: "#3b414f", pillBackgroundColor: "#B7791F" },
      { rank: 5, regionBackgroundColor: "#4a505d", pillBackgroundColor: "#B7791F" },
      { rank: 6, regionBackgroundColor: "#595f6c", pillBackgroundColor: "#B7791F" },
      { rank: 7, regionBackgroundColor: "#696f7b", pillBackgroundColor: "#B7791F" },
      { rank: 8, regionBackgroundColor: "#797f8a", pillBackgroundColor: "#B7791F" },
      { rank: 9, regionBackgroundColor: "#8a909a", pillBackgroundColor: "#B7791F" },
      { rank: 10, regionBackgroundColor: "#9ba1aa", pillBackgroundColor: "#B7791F" },
      { rank: 11, regionBackgroundColor: "#adb2ba", pillBackgroundColor: "#C53030" },
      { rank: 12, regionBackgroundColor: "#bfc3ca", pillBackgroundColor: "#C53030" },
      { rank: 13, regionBackgroundColor: "#d1d5db", pillBackgroundColor: "#C53030" },
    ]
  }

  get regionPaths() {
    return {
      "Corse": "FR-H",
      "Île-de-France": "FR-J",
      "Hauts-de-France": "FR-S",
      "Nouvelle-Aquitaine": "FR-B",
      "Normandie": "FR-P",
      "Pays de la Loire": "FR-R",
      "Centre-Val de Loire": "FR-F",
      "Grand Est": "FR-G",
      "Provence-Alpes-Côte d'Azur": "FR-U",
      "Bretagne": "FR-E",
      "Bourgogne-Franche-Comté": "FR-D",
      "Occitanie": "FR-N",
      "Auvergne-Rhône-Alpes": "FR-V",
    }
  }
}
