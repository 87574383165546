<template functional lang="pug">
  .entry.entry-text
    .d-flex.align-items-baseline.pb-2
      i.entry-title-icon.text-xs.far.fa-fw.mr-2(:class="'fa-'+ props.entry.field.faIconName")
      .entry-title.text-xs.text-gray-500 {{ props.entry.field.name }}
    .d-flex.entry-files.overflow-auto(v-if="props.entry.value")
      .entry-file.px-2(v-for="file in props.entry.value")
        img.entry-file-preview(
          v-if="file.content_type.includes('image')"
          :src="`/project_files/${file.id}`"
        )
        i.fas.fa-2x(
          v-else
          :class="file | getFontawesomeIcon"
        )
</template>

<script>
import { getFontawesomeIcon } from '../../services/getFileType'

  export default {
    props: {
      entry: Object
    },
    filters: { getFontawesomeIcon }
  }
</script>
