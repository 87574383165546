import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "rankingDataContainer",
    "rankingWaitingForDataContainer",
    "rankingKpisDataContainer",
    "rankingKpisWaitingForDataContainer",
    "historyDataContainer",
    "historyWaitingForDataContainer",
    "chartDataContainer",
    "chartWaitingForDataContainer",
  ];

  displayWaitingForDataModeFromDateRangePicker() {
    this.rankingDataContainerTarget.classList.add("tw-hidden");
    this.rankingKpisDataContainerTarget.classList.add("tw-hidden");
    this.chartDataContainerTarget.classList.add("tw-hidden");

    this.rankingWaitingForDataContainerTarget.classList.remove("tw-hidden");
    this.rankingKpisWaitingForDataContainerTarget.classList.remove("tw-hidden");
    this.chartWaitingForDataContainerTarget.classList.remove("tw-hidden");
  }

  displayWaitingFromDataForRanking() {
    this.rankingKpisDataContainerTarget.classList.add("tw-hidden");
    this.chartDataContainerTarget.classList.add("tw-hidden");

    this.rankingKpisWaitingForDataContainerTarget.classList.remove("tw-hidden");
    this.chartWaitingForDataContainerTarget.classList.remove("tw-hidden");
  }
}
