<template lang="pug">
  div
    .form-group(
      v-for="settingProperty in fieldSettingProperties"
      :key="settingProperty"
    )
      label(:for="settingProperty") {{ $t('dashboardBlocks.sourcedChart.labels.' + settingProperty) }}
      v-select(
        :searching="true"
        label="name"
        :inputId="settingProperty"
        :options="fieldsForSelectedTable"
        :reduce="option => option.id"
        v-model="sourceSettings['columns'][settingProperty]"
      )
    hr(v-if="sourceSettings['options']")
    .form-group(v-if="sourceSettings['options'] && sourceSettings['options']['display_as_line_in_bar_chart'] !== undefined")
      input(
        id="display_as_line_in_bar_chart"
        type="checkbox"
        v-model="sourceSettings['options']['display_as_line_in_bar_chart']"
      )
      label.ml-2(for="display_as_line_in_bar_chart") {{ $t('dashboardBlocks.sourcedChart.displayAsLine') }}

    .form-group(v-if="sourceSettings['options'] && sourceSettings['options']['force_aggregation'] !== undefined")
      input(
        id="force_aggregation"
        type="checkbox"
        v-model="sourceSettings['options']['force_aggregation']"
      )
      label.ml-2(for="force_aggregation") {{ $t('dashboardBlocks.sourcedChart.forceAggregation') }}
</template>


<script>
import { mapGetters, mapState } from 'vuex';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    sourceSettings: {
      type: Object,
      required: true
    },
    fieldsForSelectedTable: {
      type: Array,
      required: false
    },
    fieldSettingProperties: {
      type: Array,
      required: false
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
  },
  watch: {
    // Clear source settings if list_id is removed from DashboardBlockSourcedChart editableSettings
    'fieldsForSelectedTable': function(_newSettings, _oldSettings) {
      this.fieldSettingProperties.forEach((property) => {
        this.sourceSettings['columns'][property] = null
      })
    },
  }
}
</script>
