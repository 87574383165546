import { api }                              from '../api/client';
import { throttle }                         from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';

export const ResizeColumnMixin = {
  data() {
    return {
      startOffset:    null,
      helperPosition: 0,
      sideMenuWidth:  0
    }
  },
  computed: {
    ...mapState({
      currentView: state => state.viewStore.view
    }),
    ...mapGetters({
      orderedFields: 'recordStore/orderedFields'
    })
  },
  methods: {
    ...mapActions({
      computeFieldsLeftOffset: 'fieldStore/computeFieldsLeftOffset'
    }),
    startResize(event) {
      event.target.classList.add('resizing');
      this.startOffset    = event.target.parentElement.offsetWidth - event.pageX;
      this.sideMenuWidth  = document.querySelector('.side-menu').clientWidth;
      this.helperPosition = event.pageX - this.sideMenuWidth;
    },
    moveResizeHelper(event) {
      if (this.startOffset) this.helperPosition = event.pageX - this.sideMenuWidth;
    },
    applyResize: throttle(function(field, event) {
      if (this.startOffset) {
        const newWidth      = this.startOffset + event.pageX;
        this.startOffset    = null;
        let minWidth        = field == _.first(this.orderedFields) ? 150 : 48;
        field.columnWidth   = newWidth < minWidth ? minWidth : newWidth;

        this.computeFieldsLeftOffset();
        event.target.classList.remove('resizing');

        api.viewFields.update({
          viewId:    this.currentView.id,
          fieldId:   field.id,
          viewField: {
            column_width: field.columnWidth
          }
        });
      }
    }, 300)
  }
};
