import axios from 'axios';
import store from '../store/store';
import i18n  from "../locales/locales.js";

import automationModules  from './modules/automationModulesClient';
import dashboardBlocks    from './modules/dashboardBlocksClient';
import dashboards         from './modules/dashboardsClient';
import dataImportParams   from './modules/dataImportParamsClient';
import entries            from './modules/entriesClient';
import fields             from './modules/fieldsClient';
import flexContacts       from './modules/flexContactsClient';
import pages              from './modules/pagesClient';
import projectFiles       from './modules/projectFilesClient';
import projects           from './modules/projectsClient';
import records            from './modules/recordsClient';
import repeatableRecords  from './modules/repeatableRecordsClient';
import rowOrders          from './modules/rowOrdersClient';
import sortOrders         from './modules/sortOrdersClient';
import sourceSettings     from './modules/sourceSettingsClient';
import tables             from './modules/tablesClient';
import viewFields         from './modules/viewFieldsClient';
import views              from './modules/viewsClient';
import tablePermissions   from './modules/tablePermissionsClient';

export const apiClient = axios.create({
  baseURL:          window.location.origin,
  headers:          {
    Accept:         'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': document.getElementsByName("csrf-token")[0].getAttribute("content")
  },
  timeout:          30000
});

const raiseError = (error) => {
  let message;
  if (error.response.status === 403) {
    message = i18n.t('forbidden');
  }
  store.dispatch('throwError', { error, message });
  return Promise.reject(error);
};

apiClient.interceptors.request.use((config) => {
  config.headers['X-UNIQ-SESSION-ID'] = store.state.uniqSessionId
  return config;
}, raiseError);

apiClient.interceptors.response.use((response) => {
  return response;
}, raiseError);

export const startFetch = (action = 'LOADING') => {
  const loader = { state: true };
  store.commit('SET_' + action, loader);
  return loader;
};

export const endFetch = loader => response => {
  loader.state = false;
  return response;
};

export const api = {
  automationModules,
  dashboardBlocks,
  dashboards,
  dataImportParams,
  entries,
  fields,
  flexContacts,
  pages,
  projectFiles,
  projects,
  records,
  repeatableRecords,
  rowOrders,
  sortOrders,
  sourceSettings,
  tablePermissions,
  tables,
  viewFields,
  views
};
