<template lang="pug">
  .dashboard-load-shift
    template(v-if="settingsAreReady")
      .dashboard-block--header.pb-3
        h2.pr-3(
          :class="{ [translationFallbackClasses]: titleFallback }"
        ) {{ dashboardBlock.title }}
      .dashboard-load-shift-content
        .dashboard-load-shift-kpi
          .kui-card.dashboard-kpi
            .dashboard-kpi--icon.bg-project-secondary-100.text-project-secondary
              i.fad.fa-fw.fa-bullseye-arrow
            .content
              .dashboard-kpi--label &nbsp;{{ $t('dashboardBlocks.load_shift.objective') }}
              .dashboard-kpi--figure
                span.dashboard-kpi--value {{ contentIsPresent ? content.average_values.floor_power.value : 0 }}
                span.dashboard-kpi--unit {{ contentIsPresent ? content.average_values.floor_power.unit : "kW" }}
          .kui-card.dashboard-kpi
            .dashboard-kpi--icon.bg-project-secondary-100.text-project-secondary
              i.fad.fa-fw.fa-plug
            .content
              .dashboard-kpi--label &nbsp; {{ $t('dashboardBlocks.load_shift.reference_power') }}
              .dashboard-kpi--figure
                span.dashboard-kpi--value {{ contentIsPresent ? content.average_values.reference_power.value : 0}}
                span.dashboard-kpi--unit {{ contentIsPresent ? content.average_values.reference_power.unit : "kW"}}
          .kui-card.dashboard-kpi
            .dashboard-kpi--icon.bg-project-secondary-100.text-project-secondary
              i.fad.fa-fw.fa-plug
            .content
              .dashboard-kpi--label &nbsp; {{ $t('dashboardBlocks.load_shift.average_consumption') }}
              .dashboard-kpi--figure
                span.dashboard-kpi--value {{ contentIsPresent ? content.average_values.average_measured_power.value : 0}}
                span.dashboard-kpi--unit {{ contentIsPresent ? content.average_values.average_measured_power.unit : "kW"}}
          .kui-card.dashboard-kpi
            .dashboard-kpi--icon.bg-project-secondary-100.text-project-secondary
              i.fad.fa-fw.fa-eraser
            .content
              .dashboard-kpi--label &nbsp; {{ $t('dashboardBlocks.load_shift.average_load_shift') }}
              .dashboard-kpi--figure
                span.dashboard-kpi--value {{ contentIsPresent ? content.average_values.average_erased_power.value : 0 }}
                span.dashboard-kpi--unit {{ contentIsPresent ? content.average_values.average_erased_power.unit : "kW" }}
        .dashboard-load-shift-items
          .table(
            v-if="contentIsPresent"
            v-for="group in content.detailed_values"
            :key="group.index"
          )
            table.load-shift-listing
              thead.load-shift-listing--headers
                tr.load-shift-listing--row
                  th.load-shift-listing--cell.title
                  th.load-shift-listing--cell.title(
                    v-for="item in group"
                    :key="group.index"
                  ) {{ item.formatted_time }}
              tbody.load-shift-listing--body
                tr.load-shift-listing--row
                  td.load-shift-listing--cell {{ $t('dashboardBlocks.load_shift.consumed') }}
                  td.load-shift-listing--cell(
                    v-for="item in group"
                    :key="group.index"
                    :class="[ colorMeasuredValueClass(item), labelMeasuredValueClass(item) ]"
                  ) {{ item.measured_power }}
                tr.load-shift-listing--row
                  td.load-shift-listing--cell {{ $t('dashboardBlocks.load_shift.erased') }}
                  td.load-shift-listing--cell(
                    v-for="item in group"
                    :key="group.index"
                  ) {{ item.erased_power }}

    .flex-center.h-100(v-else)
      div
        h2.text-center.text-warning
          i.fas.fa-exclamation-triangle
        p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}

</template>
<script>
import { api }                   from '../../api/client';
import { map, isEqual, isEmpty } from 'lodash';
import { mapGetters }            from 'vuex';
import { EventBus }              from '../../main';
import { DashboardBlockMixin }   from '../../mixins/DashboardBlockMixin';
import DashboardBlock            from '../../models/dashboardBlock';
import DashboardSharedItem       from '../../models/dashboardSharedItem';

export default {
  mixins: [DashboardBlockMixin],
  data() {
    return {
      content: [],
      contentIndex: 0,
    }
  },
  mounted() {
    if (this.settingsAreReady) {
      this.loadData();
    }
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    settingsAreReady() {
      return this.dashboardBlock &&
             this.dashboardBlock.kind_settings.load_shift_list_id &&
             this.dashboardBlock.kind_settings.site_list_id;
    },
    dashboardBlockSitesList() { // we need the list block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.site_list_id);
    },
    tableForSites() {
      if (this.settingsAreReady) {
        return this.getTableById(this.dashboardBlockSitesList.kind_settings.table_id);
      }
    },
    selectedSitesRecordIds() {
      if (this.tableForSites && this.tableForSites.slug) {
        const items = DashboardSharedItem.listItems(this.dashboardBlockSitesList.id, true).
                                          where('metadata', value => value.checked).get();
        return map(items, 'item_id');
      }
    },
    dashboardBlockLoadShiftsList() { // we need the list block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.load_shift_list_id);
    },
    tableForLoadShifts() {
      if (this.settingsAreReady) {
        return this.getTableById(this.dashboardBlockLoadShiftsList.kind_settings.table_id);
      }
    },
    selectedLoadShiftsRecordIds() {
      if (this.tableForLoadShifts && this.tableForLoadShifts.slug) {
        const items = DashboardSharedItem.listItems(this.dashboardBlockLoadShiftsList.id, true).
                                          where('metadata', value => value.checked).get();
        return map(items, 'item_id');
      }
    },
    contentIsPresent() {
      return !isEmpty(this.content) && !isEmpty(this.content.detailed_values)
    }
  },
  beforeDestroy() {
    EventBus.$off('dashboard-block-updated-' + this.dashboardBlockId);
  },
  watch: {
    'selectedSitesRecordIds': function (newRecordIds, oldRecordIds) {
      if (!this.settingsAreReady || isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) return

      this.loadData();
    },
    'selectedLoadShiftsRecordIds': function (newRecordIds, oldRecordIds) {
      if (!this.settingsAreReady || isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) return

      this.loadData();
    },
  },
  methods: {
    loadData() {
      return api.dashboardBlocks.loadShift(this.dashboardBlock.id, this.selectedSitesRecordIds, this.selectedLoadShiftsRecordIds).then((response) => {
        this.content = response.data.content;
      });
    },
    colorMeasuredValueClass(item) {
      return item.measured_power > item.floor_power ? 'over-floor' : 'under-floor'
    },
    labelMeasuredValueClass(item) {
      if (item.measured_power === this.content.average_values.best_measured_power.raw_value) {
        return 'green-label'
      } else if (item.measured_power === this.content.average_values.worst_measured_power.raw_value) {
        return 'red-label'
      }
    },
  },
}
</script>
