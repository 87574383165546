import { apiClient, _startFetch, _endFetch } from '../client';

export default {
  sourcedCharts(dashboardBlockId, recordIds = [], periodDates = [], aggregated = false, computationMode = "") {
    return apiClient.get(`/api/sourced_charts/${dashboardBlockId}`, {
      params: {
        aggregated,
        record_ids: recordIds,
        start_date: periodDates[0],
        end_date: periodDates[1],
        computation_mode: computationMode,
      }
    });
  },
  timescaleCharts(dashboardBlockId, recordIds = [], annotationRecordIds= [], periodDates = [], periodIdentifier= {}, displayAggregation = '', year) {
    return apiClient.get(`/api/timescale_charts/${dashboardBlockId}`, {
      params: {
        record_ids:            recordIds,
        annotation_record_ids: annotationRecordIds,
        start_date:            periodDates[0],
        end_date:              periodDates[1],
        period:                periodIdentifier,
        display_aggregation:   displayAggregation,
        year:                  year || null
      }
    });
  },
  charts(dashboardBlockId) {
    return apiClient.get(`/api/dashboard_block_charts/${dashboardBlockId}`);
  },
  kpis(dashboardBlockId, recordIds = [], periodDates = [], periodIdentifier= {}) {
    return apiClient.get(`/api/kpis/${dashboardBlockId}`, { params: {
      record_ids: recordIds,
      start_date: periodDates[0],
      end_date:   periodDates[1],
      period:     periodIdentifier,
    }});
  },
  sourcedkpis(dashboardBlockId, recordIds = [], periodDates = []) {
    return apiClient.get(`/api/sourced_kpis/${dashboardBlockId}`, {
      params: {
        record_ids: recordIds,
        start_date: periodDates[0],
        end_date: periodDates[1],
      }
    });
  },
  peakPeriods(dashboardBlockId) {
    return apiClient.get(`/api/peak_periods/${dashboardBlockId}`);
  },
  board(dashboardBlockId, recordIds = []) {
    return apiClient.get(`/api/board/${dashboardBlockId}`, { params: { record_ids: recordIds }});
  },
  calendar(dashboardBlockId, year = null, recordIds = []) {
    return apiClient.get(`/api/calendar/${dashboardBlockId}`, { params: { year, record_ids: recordIds }});
  },
  ncc(dashboardBlockId, year = null, recordIds = []) {
    return apiClient.get(`/api/ncc/${dashboardBlockId}`, { params: { year: year, record_ids: recordIds }});
  },
  loadShift(dashboardBlockId, siteRecordIds = [], loadShiftRecordIds= []) {
    return apiClient.get(`/api/load_shifts/${dashboardBlockId}`, { params: { site_record_ids: siteRecordIds, load_shift_record_ids: loadShiftRecordIds }});
  },
  regionalMap(dashboardBlockId, recordIds = [], periodDates = []) {
    return apiClient.get(`/api/regional_map/${dashboardBlockId}`, { params: { record_ids: recordIds, period_dates: periodDates }});
  },
}
