import Cell from '../cell';
import KuartzSelector from "../../kuartz_selector";

export default class SelectCell extends Cell {
  get readHTML() {
    const choice = this.choices && this.choices.find(choice => choice.value === this.value);
    let choiceHTML = "";

    if (choice) {
      const color = choice && choice.color ? choice.color : "slate";

      choiceHTML = `<div class="choice ${color}-choice">${choice.name}</div>`;
    }

    return `<div class="cell-content tw-flex tw-truncate">${choiceHTML}</div>`;
  }

  writeHTML(value) {
    const choices = this.choices;
    let optionsHTML = `<option value=""></option>`;
    const choicesHTML = choices.map(choice => {
      const selected = choice.value == value ? "selected='selected'" : "";
      return `<option value="${choice.value}" ${selected}>${choice.name}</option>`
    }).join("\n");
    optionsHTML += choicesHTML;

    return `
      <form data-turbo-stream="true" action="${this.updateUrl}" method="${this.updateMethod}" class="tw-w-full">
        <select name="${this.inputName}" class="tw-w-full z-200 no-border">
          ${optionsHTML}
        </select>
      </form>
    `;
  }

  edit(value = this.value) {
    if (!this.setWriteMode(value)) return;

    const selectHTML = this.container.querySelector('select');
    const cell = this;
    new KuartzSelector(
      selectHTML,
      {
        locale: this.locale,
        choices: this.choices,
        onChange({ detail: value }) {
          value = value[0] || "";

          cell.update(value);
          cell.navigable = true;
        },
        onDropdownOpen() {
          cell.navigable = false;
        },
        onDropdownClose() {
          cell.navigable = true;
        },
        afterChangeWithEnterKey() {
          setTimeout(() => {
            cell.navigable = true;
            cell.selectDownCell()
          });
        },
      }
    );
  }

  copy() {
    if (!this.selected) return;

    navigator.clipboard.writeText(this.choice.name);
  }

  paste() {
    if (!this.selected || !this.editable) return;

    navigator.clipboard.readText().then(clipText => {
      const choice = this.choiceByName(clipText);
      if (!choice || choice.value === this.value) return;

      this.update(choice.value);
    });
  }

  addEventListeners() {
    this.container.addEventListener("click", () => this.edit());
  }

  selectCallbacks() {
    this.edit();
  }

  editionModeValue() {
    const selectedOption = this.container.querySelector("select option[selected='selected']");

    return selectedOption && selectedOption.value;
  }

  get choices() {
    return this.column.options.choices;
  }

  get choice() {
    if (!this.value) return { name: "", value: "" };

    return this.choices.find(choice => choice.value === this.value);
  }

  choiceByName(name) {
    if (!name) return { name: "", value: "" };

    return this.choices.find(choice => choice.name === name);
  }

  get locale() {
    return this.column.options.locale;
  }
}
