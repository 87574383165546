<template lang="pug">
  span.view-action.mr-1(
    @click="$emit('open')"
    :class="{ opened: show }"
  )
    span.view-action--btn
      i.fal.fa-ellipsis-h.view-action--btn-icon
      .ctrlbar-action.p-0(
        v-if="show"
        v-click-outside="closeAction"
      )
        .ctrlbar-action--body.p-0
          a.d-block.mb-0.px-3.py-2.text-nowrap.view-action--btn.hoverable(
            v-if="currentView.type === 'calendar'"
            :href="'/views/' + currentView.id + '/export_data/export.ics'"
            :title="$t('controlBarActions.downloadToCalendarFormat')"
            data-toggle="tooltip"
          )
            i.view-action--btn-icon.fa-fw.far.fa-calendar-alt
            span.view-action--btn-text.ml-1 {{ $t('controlBarActions.downloadToCalendarFormat') }}

          p.mb-0.px-3.py-2.text-nowrap.view-action--btn.hoverable(
            :class="{ 'btn-disabled': currentView.locked }"
            @click="openEditViewDescriptionModal"
          )
            i.view-action--btn-icon.fa-fw.far.fa-pen
            span.view-action--btn-text.ml-1 {{ $t('controlBarActions.editViewDescription') }}

          p.mb-0.px-3.py-2.text-nowrap.view-action--btn.hoverable(
            :class="{ 'btn-disabled': currentView.locked }"
            v-if="currentView.type === 'form'"
            @click="openEditViewOptionsModal"
          )
            i.view-action--btn-icon.fa-fw.far.fa-cog
            span.view-action--btn-text.ml-1 {{ $t('controlBarActions.editViewOptions') }}

          //- TODO: Remove entirely this feature
          //- p.mb-0.px-3.py-2.text-nowrap.view-action--btn.hoverable(
          //-   v-if="currentView.type != 'form'"
          //-   @click="askForApplyEverywhereThisViewFieldsDisplay"
          //-   :title="$t('controlBarActions.applyEverywhereThisViewFieldsDisplay')"
          //-   data-toggle="tooltip"
          //- )
          //-   i.view-action--btn-icon.fa-fw.far.fa-columns
          //-   span.view-action--btn-text.ml-1 {{ $t('controlBarActions.applyEverywhereThisViewFieldsDisplay') }}

          p.mb-0.px-3.py-2.text-nowrap.view-action--btn.hoverable(
            :class="{ 'btn-disabled has-tooltip': cantDeleteView }"
            @click="askForDeleteCurrentView"
            :title="$t('controlBarActions.cantDeleteLastView')"
            data-toggle="tooltip"
          )
            i.view-action--btn-icon.fa-fw.far.fa-unlink
            span.view-action--btn-text.ml-1 {{ $t('controlBarActions.dissociateView') }}

          p.mb-0.px-3.py-2.text-nowrap.view-action--btn.hoverable(
            v-if="feature.canEditRecordValidations"
            @click="openEditRecordValidationsModal"
            data-toggle="tooltip"
          )
            i.view-action--btn-icon.fa-fw.far.fa-ballot-check
            span.view-action--btn-text.ml-1 {{ $t('controlBarActions.editRecordValidations') }}

          p.mb-0.px-3.py-2.text-nowrap.view-action--btn.hoverable(
            v-if="showDeleteTableButton"
            :class="{ 'btn-disabled has-tooltip': cantDeleteTable }"
            @click="askFordeleteCurrentTable"
            :title="cantDeleteTable ? $t('controlBarActions.cantDeleteLastTable') : ''"
            data-toggle="tooltip"
          )
            i.view-action--btn-icon.fa-fw.far.fa-trash-alt
            span.view-action--btn-text.ml-1 {{ $t('controlBarActions.deleteTable') }}
</template>

<script>
import { api }                 from '../../api/client';
import { EventBus }            from '../../main.js';
import { mapState }            from 'vuex';
import { requireConfirmation } from '../../../components/require_confirmation';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState({
      feature:      state => state.feature,
      currentView:  state => state.viewStore.view,
      tableViews:   state => state.tableStore.table.views,
      currentTable: state => state.tableStore.table,
      tables:       state => state.tableStore.tables
    }),
    cantDeleteView() {
      return this.currentView.locked || this.tableViews.length < 2;
    },
    cantDeleteTable() {
      return this.currentTable.locked || this.tables.length < 2;
    },
    showDeleteTableButton() {
      return this.feature.canCreateResource;
    },
    canEditViewDescription() {
      return !this.currentView.locked;
    },
    canEditViewOptions() {
      return !this.currentView.locked;
    },
  },
  methods: {
    closeAction() {
      this.$emit('close');
    },
    askForApplyEverywhereThisViewFieldsDisplay() {
      this.requireConfirmation(this.$t('confirmations.applyEverywhereFieldsDisplay'))
        .then((result) => {
          if (result.isConfirmed) this.applyEverywhereThisViewFieldsDisplay()
        });
    },
    askForDeleteCurrentView() {
      if (this.cantDeleteView) return;

      this.requireConfirmation(this.$t('confirmations.deleteView'))
        .then((result) => {
          if (result.isConfirmed) this.deleteCurrentView()
        });
    },
    askFordeleteCurrentTable() {
      if (this.cantDeleteTable) return;

      this.requireConfirmation(this.$t('confirmations.deleteTable'))
        .then((result) => {
          if (result.isConfirmed) this.deleteCurrentTable()
        });
    },
    applyEverywhereThisViewFieldsDisplay() {
      const tableViewIds = this.currentTable.views.map(view => view.id);
      const params       = { viewId: this.currentView.id, tableViewIds: tableViewIds }
      api.viewFields.updateFieldsDisplayForAllViews(params);
    },
    deleteCurrentView() {
      api.views.dissociateOrDestroy({ viewId: this.currentView.id }).then(() => {
        this.$store.dispatch('viewStore/deleteView', { viewId: this.currentView.id });
        setTimeout(() => {
          this.$store.dispatch('viewStore/fetchView', { viewId: this.tableViews[0].id })
        }, 0);
      });
    },
    deleteCurrentTable() {
      api.tables.delete({ tableId: this.currentTable.id }).then(() => {
        this.$store.dispatch('tableStore/deleteCurrentTable');
      });
    },
    requireConfirmation(text) {
      return requireConfirmation(text, this.$t('confirm'), this.$t('cancel'))
    },
    // openImportData ############################################################
    openImportDataModal() {
      EventBus.$emit("openModal", {
        modalName:  'ImportDataModal',
        modalProps: {}
      });
    },
    openEditViewDescriptionModal() {
      if (!this.canEditViewDescription) return;

      EventBus.$emit("openModal", {
        modalName:  'EditViewDescriptionModal',
        modalProps: {}
      });
    },
    openEditViewOptionsModal() {
      if (!this.canEditViewOptions) return;

      EventBus.$emit("openModal", {
        modalName:  'EditViewOptionsModal',
        modalProps: {}
      });
    },
    openEditRecordValidationsModal() {
      EventBus.$emit("openModal", {
        modalName:  'EditRecordValidationsModal',
        modalProps: {}
      });
    },
  }
}
</script>

<style scoped>
.ctrlbar-action {
  min-width: 0;
}

.btn-disabled {
  color: #718096 !important;
  cursor: not-allowed !important;
  filter: grayscale(0.3) !important;
}
</style>>
