import { Controller } from "@hotwired/stimulus";
// import { TomSelect } from "tom-select"; "tom-select";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = { settings: Object };

  connect() {
    const controller = this;
    const globalOptions = {
      onInitialize() {
        controller.dispatch("initialize");
      },
      onChange(value) {
        if (value) {
          controller.dispatch("change", { detail: { value } });
        }
      },
      onBlur() {
        controller.dispatch("blur");
      }
    };

    new TomSelect(this.element, Object.assign(globalOptions, this.settingsValue));
  }
}
