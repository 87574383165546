import { selectListMixin }      from './selectListMixin';
import { differenceBy }         from 'lodash';
import { api }                  from '../api/client';
import { mapGetters, mapState } from 'vuex';
import Record                   from "../models/record";
import { UpdateEntryMixin }     from './UpdateEntryMixin';
import { alertPopup }           from '../../components/alert_popup';
import i18n                     from "../locales/locales.js";

export const AddReferenceMixin = {
  mixins: [selectListMixin, UpdateEntryMixin],
  data() {
    return {
      recordsForReference: null,
      elementIdentifier: 'foreign_record_id',
      elementFilteredProperty: 'foreign_record_display_name',
      elementObjectName: 'reference',
      selectedReferenceRecordId: null
    }
  },
  computed: {
    ...mapState({
      currentTable: state => state.tableStore.table
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    selectListElements() {
      if (this.entry) {
        return differenceBy(this.recordsForReference, this.entry.value, 'foreign_record_id');
      }
      return [];
    },
    referenceTable() {
      const referenceTableId = this.entry.field.typeOptions.foreign_table_id;

      return this.getTableById(referenceTableId);
    },
    canCreateReferenceRecord() {
      return !!this.referenceTable && !this.referenceRecordSelected;
    },
    referenceRecordSelected() {
      return this.selectedReferenceRecordId;
    }
  },
  methods: {
    elementSelected(element) {
      if (this.referenceRecordSelected) return;

      this.addWaitingSpinnerToSelectedReferenceRecordEl(element.foreign_record_id);

      if (element) this.addReferenceToEntry(element);
    },
    addWaitingSpinnerToSelectedReferenceRecordEl(foreignRecordId) {
      const selectedReferenceRecordEl = document.querySelector(
        `.select-list-element.element-name-reference[data-select-list-element-id="${foreignRecordId}"]`
      );

      selectedReferenceRecordEl.insertAdjacentHTML(
        'beforeend',
        '<i class="ml-2 far fa-spinner fa-spin fa-lg fa-fw"></i>'
      );
    },
    addReferenceToEntry(reference) {
      this.selectedReferenceRecordId = reference.foreign_record_id;

      api.entries.addReference({
        reference,
        recordId: this.entry.record.id,
        fieldId:  this.entry.field.id
      }).then((response) => {
        if (!response.data.error) {
          this.entry.value.push(reference);
          this.updateCallbacks(this.entry);
          this.selectedReferenceRecordId = null;
          this.afterAddReference();
        } else {
          const message = response.data.custom_message || this.$t('updateForbidden')
          alertPopup(message, 'OK');
        }
      })
    },
    fetchRecordsForReference() {
      api.tables.recordsForReference({
        tableId: this.entry.field.typeOptions.foreign_table_id,
        viewId:  this.entry.field.typeOptions.view_id_for_selection
      }).then(response => {
          this.recordsForReference = response.data.records;
        })
    },
    createReferenceRecord() {
      if(!this.referenceTable) return;

      api.tables.createRecord({
        tableId: this.referenceTable.id,
      }).then(response => {
        const record = Record.build(response.data.record, this.referenceTable);
        const jobId  = response.data.job_id;
        this.addNewReferenceRecordToTableIfNeeded(record);
        this.confirmReferenceRecordCreation(record, jobId);
      });
    },
    confirmReferenceRecordCreation(record, jobId) {
      api.records.confirmCreation({
        recordId: record.id, jobId: jobId
      }).then(() => {
        record.creationConfirmed = true;
        this.setReferenceRecordToEntry(record);
      });
    },
    setReferenceRecordToEntry(record) {
      const reference = {
        foreign_record_id:           record.id,
        foreign_record_display_name: record.displayName,
      };
      this.addReferenceToEntry(reference);
      this.afterCreateReferenceRecord(reference);
    },
    addNewReferenceRecordToTableIfNeeded(record) {
      if (this.currentTable === this.referenceTable) {
        this.$store.dispatch("recordStore/addRecord", record);
        this.ensureRecordStillBelongsToView({ record });
        this.checkIfRecordNeedtoBeLocked({ record });
      }
    }
  }
};
