<template functional lang="pug">
  .entry.entry-text.entry-medium
    .d-flex.align-items-baseline.pb-2
      i.entry-title-icon.text-xs.far.fa-fw.mr-2(:class="'fa-'+ props.entry.field.faIconName")
      .entry-title.text-xs.text-gray-500  {{ props.entry.field.name }}
    .btn.btn-light.entry-text.text-base.text-gray-900.overflow-hidden(v-if="props.entry.value.length !== 0") {{ props.entry.toString() }}
</template>

<script>
export default {
  props:{
    entry :{
      type:     Object,
      required: true
    }
  },
  render(h, ctx) {
    const entry = ctx.props.entry
  }
}
</script>
