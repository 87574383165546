import FieldTypeEditSelect from "../components/field_type_edit/FieldTypeEditSelect";
import FieldTypeEditEmpty from "../components/field_type_edit/FieldTypeEditEmpty";
import FieldTypeEditFormula from "../components/field_type_edit/FieldTypeEditFormula";
import FieldTypeEditDate from "../components/field_type_edit/FieldTypeEditDate";
import FieldTypeEditDateTimeRange from "../components/field_type_edit/FieldTypeEditDateTimeRange";
import FieldTypeEditLookups from "../components/field_type_edit/FieldTypeEditLookups";
import FieldTypeEditReferences from "../components/field_type_edit/FieldTypeEditReferences";
import FieldTypeEditUsers from "../components/field_type_edit/FieldTypeEditUsers";
import FieldTypeEditNumber from "../components/field_type_edit/FieldTypeEditNumber";
import FieldTypeEditFiles from "../components/field_type_edit/FieldTypeEditFiles";

export const FieldTypeEditMixin = {
  components: {
    "fieldTypeEdit-select":           FieldTypeEditSelect,
    "fieldTypeEdit-formula":          FieldTypeEditFormula,
    "fieldTypeEdit-date":             FieldTypeEditDate,
    "fieldTypeEdit-date_time_range":  FieldTypeEditDateTimeRange,
    "fieldTypeEdit-lookups":          FieldTypeEditLookups,
    "fieldTypeEdit-string":           FieldTypeEditEmpty,
    "fieldTypeEdit-number":           FieldTypeEditNumber,
    "fieldTypeEdit-boolean":          FieldTypeEditEmpty,
    "fieldTypeEdit-files":            FieldTypeEditFiles,
    "fieldTypeEdit-references":       FieldTypeEditReferences,
    "fieldTypeEdit-users":            FieldTypeEditUsers,
    "fieldTypeEdit-created_at":       FieldTypeEditEmpty,
    "fieldTypeEdit-last_modified_at": FieldTypeEditEmpty,
    "fieldTypeEdit-created_by":       FieldTypeEditEmpty,
    "fieldTypeEdit-last_modified_by": FieldTypeEditEmpty,
  },
};
