
<template lang="pug">
  .btn-group
    .btn-view.px-2.py-2(@click="openMenu")
      i.far.fa-ellipsis-h.fa-fw.mr-2.view-action--btn-icon.view-action--btn.drop-icon
    .menu-list-action(v-show="show" v-click-outside="closeAction")
      .menu-body.overflow-auto
        span.d-flex.align-items-center.px-3.py-2(@click="openModal('SortModal')")
          i.far.fa-sort-alt.fa-fw.mr-2.view-action--btn-icon.view-action--btn.drop-icon
          span.text-mobile {{ $t('controlBarActions.sort') }}
        span.d-flex.align-items-center.px-3.py-2(@click="openModal('FilterModal')")
          i.far.fa-filter.fa-fw.mr-2.view-action--btn-icon.view-action--btn.drop-icon
          span.text-mobile {{ $t('controlBarActions.filter') }}
        span.d-flex.align-items-center.px-3.py-2(@click="openModal('ShowHideModal')")
          i.far.fa-eye-slash.fa-fw.mr-2.view-action--btn-icon.view-action--btn.drop-icon
          span.text-mobile {{ $t('controlBarActions.showHide') }}
        a.d-flex.align-items-center.view-option.download-btn.px-3.py-2(:href="'/views/' + currentView.id + '/export_data/export.xlsx'")
          i.far.fa-file-download.fa-fw.mr-2.view-action--btn-icon.view-action--btn.drop-icon
          span.text-mobile {{ $t('controlBarActions.download') }}
        span.view-action--btn.d-flex.align-items-center.px-3.py-2(v-if="recordCreationEnabled" @click="openModal('RecordModal')")
          i.far.fa-plus-circle.mr-2.fa-fw.view-action--btn-icon.drop-icon
          span.text-mobile {{ $t('controlBarActions.addRecord') }}
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { EventBus } from "../../main.js";

export default {
  data() {
    return{
      show: false
    }
  },
  computed: {
    ...mapState({
      currentTable: state => state.tableStore.table,
      currentView: state => state.viewStore.view,
      screenWidth: state => state.screenWidth
    }),
    ...mapGetters({
      allCurrentFieldsAreReadOnly: 'fieldStore/allCurrentFieldsAreReadOnly'
    }),
    isSmallMobile() {
      return this.screenWidth < 415;
    },
    recordCreationEnabled() {
      return this.isSmallMobile && !this.allCurrentFieldsAreReadOnly && this.currentTable.recordCreationEnabled;
    },
  },
  methods: {
    openMenu(){
      this.show = true;
    },
    closeAction() {
      this.show = false;
    },
    openModal(modalName) {
      this.closeAction();
      EventBus.$emit("openModal", {
        modalName:  modalName,
        modalProps: {}
      });
    }
  }
};
</script>

<style scoped lang="scss">
.menu-body{
  max-height: 200px;
}
.download-btn{
  color:rgb(26, 32, 44);
}
.drop-icon{
  color: #2C5282;
}
</style>
