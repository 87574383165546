<template lang="pug">
  .control-bar.responsive(:class=" mainPanelWidth >= 920 ? 'px-4' : 'px-1'")
    .control-bar-header
      template(v-if="!loading && visibleFields.length && mainPanelWidth >= 920 && currentView.id")
        .control-bar--left-side
          .view-actions
            //- viewsList ##########################################################
            ControlBarViewsList(
              @open="action = 'viewsList'"
              @close="closeAction"
              :show="action === 'viewsList'"
            )
            //- span(v-if="currentView.type != 'form'")
            //- createRecord #######################################################
            span.view-action.mr-1(
              v-if="recordCreationEnabled"
              @click="openRecordModal"
            )
              span.view-action--btn
                i.far.fa-plus-circle.fa-fw.view-action--btn-icon
                span.view-action--btn-text.ml-1 {{ $t('controlBarActions.addRecord') }}
            //- filterRecords ######################################################
            ControlBarFilterRecords(v-if="currentView.type != 'form'")
            //- sortColumns ########################################################
            ControlBarSortColumns(
              @open="action = 'sortColumns'"
              @close="closeAction"
              :show="action === 'sortColumns'"
              v-if="currentView.type != 'calendar' && currentView.type != 'form'"
            )
            //- showHideColumns ####################################################
            ControlBarShowHideColumns(
              v-if="currentView.type != 'form' || currentUser.superAdmin"
              @open="action = 'showHideColumns'"
              @close="closeAction"
              :show="action === 'showHideColumns'"
            )
            //- DuplicateCurrentView #########################################################
            //- TODO: Remove entirely this feature
            //- ControlBarDuplicateView(
            //-   v-if="currentView.type != 'form'"
            //-   @open="action = 'createView'"
            //-   @close="closeAction"
            //-   :show="action === 'createView'"
            //- )
            //- downloadExcel ######################################################
            span.view-action(v-if="currentView.type != 'form'")
              a.view-action--btn(:href="'/views/' + currentView.id + '/export_data/export.xlsx'")
                i.far.fa-file-download.fa-fw.view-action--btn-icon
                span.view-action--btn-text.ml-1 {{ $t('controlBarActions.download') }}
            //- downloadZipFile ######################################################
            span.view-action(
              v-if="canDownloadZipFile && currentView.type != 'form'"
            )
              a.view-action--btn(:href="'/views/' + currentView.id + '/export_data/export_entry_files_to_zip_file.zip'")
                i.far.fa-file-archive.fa-fw.view-action--btn-icon
                span.view-action--btn-text.ml-1 {{ $t('controlBarActions.getZipFile') }}
            //- importData ##########################################################
            span.view-action(v-if="canImportData")
              span.view-action--btn(@click="openImportDataModal")
                i.view-action--btn-icon.fa-fw.far.fa-arrow-to-bottom
                span.view-action--btn-text.ml-1 {{ $t('controlBarActions.importData') }}
            ControlBarExpandedMenu(
              v-if="currentUser.superAdmin && (currentView.type != 'form' || currentUser.admin)"
              @open="action = 'expandedMenu'"
              @close="closeAction"
              :show="action === 'expandedMenu'"
            )

        .control-bar--right-side
          .view-actions.ml-2
            //- toggleAlert ########################################################
            ControlBarToggleAlert(v-if="canUseViewAlerts && currentView.type != 'form'")

            //- toggleViewLocking ########################################################
            ControlBarToggleViewLocking

      template.d-flex(v-else-if="!loading && visibleFields.length && currentView.type != 'form'")
        //- VIEW MENU
        ControlBarMobileListView.mobile-bar-padding.ml-2
        //- createRecord #######################################################
        span.mr-3.btn-phone-medium.mobile-mini-width(v-if="recordCreationEnabled")
          span(@click="openRecordModal")
            i.far.fa-plus-circle.fa-fw.view-action--btn-icon
            span.view-action--btn-text.ml-1 {{ $t('controlBarActions.addRecord') }}
        //- ACTION MENU
        ControlBarMobileListAction.mobile-mini-width(:show="action === 'mobileListAction'")

</template>

<script>
import { mapState, mapGetters } from "vuex";
import { EventBus }             from "../main.js";
import { some }                 from 'lodash';

import ControlBarViewsList         from "./controlbar/ControlBarViewsList.vue";
import ControlBarFilterRecords     from "./controlbar/ControlBarFilterRecords.vue";
import ControlBarSortColumns       from "./controlbar/ControlBarSortColumns.vue";
import ControlBarDuplicateView     from "./controlbar/ControlBarDuplicateView.vue";
import ControlBarShowHideColumns   from "./controlbar/ControlBarShowHideColumns.vue";
import ControlBarToggleAlert       from "./controlbar/ControlBarToggleAlert.vue";
import ControlBarMobileListView    from "./controlbar/ControlBarMobileListView.vue";
import ControlBarMobileListAction  from "./controlbar/ControlBarMobileListAction.vue";
import ControlBarExpandedMenu      from "./controlbar/ControlBarExpandedMenu.vue";
import ControlBarToggleViewLocking from "./controlbar/ControlBarToggleViewLocking.vue";

export default {
  components: {
    ControlBarFilterRecords,
    ControlBarSortColumns,
    ControlBarDuplicateView,
    ControlBarShowHideColumns,
    ControlBarToggleAlert,
    ControlBarViewsList,
    ControlBarMobileListView,
    ControlBarMobileListAction,
    ControlBarExpandedMenu,
    ControlBarToggleViewLocking,
  },
  data() {
    return {
      action:   "",
      showMenu: false
    };
  },
  computed: {
    ...mapState({
      feature:          state => state.feature,
      currentTable:     state => state.tableStore.table,
      currentView:      state => state.viewStore.view,
      currentUser:      state => state.currentUser,
      mainPanelWidth:   state => state.mainPanelWidth,
      canUseViewAlerts: state => state.feature.canUseViewAlerts,
    }),
    ...mapGetters({
      visibleFields:               'fieldStore/visibleFields',
      loading:                     'isLoading',
      allCurrentFieldsAreReadOnly: 'fieldStore/allCurrentFieldsAreReadOnly'
    }),
    canDownloadZipFile() {
      return some(this.visibleFields, ['fieldType', 'files']);
    },
    canImportData() {
      return this.currentView.type != 'form' && this.currentTable.importEnabled;
    },
    recordCreationEnabled() {
      return !this.allCurrentFieldsAreReadOnly && this.currentView.type != 'form' && this.currentTable.recordCreationEnabled;
    },
  },
  watch: {
    "currentView.id": function(newValue, oldValue) {
      this.action = "";
    }
  },
  created() {
    window.addEventListener("keyup", this.closeActionOnEscape);
    EventBus.$on("viewCreated", () => (this.queryStringChanged = false));
  },
  beforeDestroy() {
    EventBus.$off("viewCreated");
    window.removeEventListener("keyup", this.closeActionOnEscape);
  },
  methods: {
    // openCreateRecord ############################################################
    openRecordModal() {
      EventBus.$emit("openModal", {
        modalName:  'RecordModal',
        modalProps: {}
      });
    },
    // openImportDataModal #########################################################
    openImportDataModal() {
      EventBus.$emit("openModal", {
        modalName:  'ImportDataModal',
        modalProps: {}
      });
    },
    // #############################################################################
    closeAction(event, el) {
      this.action = "";
    },
    closeActionOnEscape(event) {
      if (event.key === "Escape") this.action = "";
    }
  }
};
</script>

<style scoped>
.mobile-bar-padding {
  flex: 1 0 auto;
}
.mobile-mini-width {
  flex: 0 0 auto;
}
@media (max-width: 415px) {
  .btn-phone-medium{
    display: none;
  }
  .mobile-bar-padding {
    max-width: 80%;
  }
}
@media (max-width: 920px) {
  .control-bar{
    margin-bottom: 0px;
  }
}
span.view-action--btn-text {
  line-height: 1.5rem;
  font-size: 0.875rem;
  color: #4A5568;
  font-weight: 600;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.ctrlbar-action {
  left: -21px;
}
.down-btn {
  color:rgb(26, 32, 44);
}
.menu-action {
  width: 200px;
}
.drop-icon {
  color: #2C5282;
}
@media (max-width: 919px) {
    .responsive {
      z-index: 10;
      box-shadow: 0px -2px 5px rgba(0,0,0,0.2);
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
</style>
