import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "editableInput",
    "siteIdInput", // This is an hidden input that gets submitted to get day attributes
    "firstDayOfWeekInput", // This is an hidden input that gets submitted to get day attributes
    "submitButton",
    "siteForm",
    "weekPickerForm",
  ];
  static values = {
    formType: String,
  };

  connect() {
    if (this.formTypeValue == "edit") this.disableSubmitButton();
  }

  onFirstDayOfWeekInputChange(event) {
    const selectedDate = event.target.value;
    if (!selectedDate) return;

    this.firstDayOfWeekInputTargets.forEach(input => {
      input.value = this.formatDate(selectedDate);
    })

    this.submitWeekPickerForm();
  }

  formatDate(dateStr) {
    const date = new Date(dateStr);

    return [
      date.getFullYear(),
      this.padTo2Digits(date.getMonth() + 1),
      this.padTo2Digits(date.getDate()),
    ].join('-');
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  submitWeekPickerForm() {
    if (this.weekPickerFormTarget.requestSubmit) {
      this.weekPickerFormTarget.requestSubmit();
    } else {
      this.weekPickerFormTarget.submit();
    }
  }

  onSiteSelectorChange(event) {
    if (!event.target.value) return;

    this.siteIdInputTargets.forEach(input => {
      input.value = event.target.value;
    })

    this.submitSiteForm();
  }

  submitSiteForm() {
    this.siteFormTarget.requestSubmit ? this.siteFormTarget.requestSubmit() : this.siteFormTarget.submit();
  }

  onEditableInputChange() {
    if (this.formTypeValue === "new") return;

    const hasFormChanged = this.editableInputTargets.some(input => input.dataset.originalValue != input.value);

    hasFormChanged ? this.enableSubmitButton() : this.disableSubmitButton();
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
  }
}
