<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockLists"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.list_id"
      )
    .form-group(
      v-for="settingProperty in fieldSettingProperties"
      :key="settingProperty"
    )
      label(:for="settingProperty") {{ $t('dashboardBlocks.regionalmap.labels.' + settingProperty) }}
      v-select(
        :searching="true"
        label="name"
        :inputId="settingProperty"
        :options="fieldsForSelectedTable"
        :reduce="option => option.id"
        v-model="editableSettings[settingProperty]"
      )

</template>

<script>
import { mapState }   from 'vuex';
import { EventBus }   from '../../../main';
import DashboardBlock from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fieldSettingProperties: [
      'api_source_id_field_id',
      'kind_technology_field_id',
      'regions_field_id'
      ]
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    selectedDashboardBlockList() {
      if (this.editableSettings.list_id) {
        return DashboardBlock.find(this.editableSettings.list_id);
      }
    },
    selectedTable() {
      if (this.selectedDashboardBlockList && this.selectedDashboardBlockList.kind_settings.table_id) {
        const tableId = this.selectedDashboardBlockList.kind_settings.table_id;
        return this.tables.find(table => table.id === tableId);
      }
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
  },
  watch: {
    'dashboardBlock.kind_settings': function(newSettings, oldSettings) {
      const tableChanged = (newSettings.table_id !== oldSettings.table_id);
      if (tableChanged) {
        EventBus.$emit('dashboard-block-updated-' + this.dashboardBlock.id, { type: 'tableChanged' });
      }
    },
    'editableSettings.table_id': function(newValue) {
      this.fieldSettingProperties.forEach(property => this.editableSettings[property] = null);
      this.editableSettings.view_id = null;
    },
  }
}
</script>
