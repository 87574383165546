<template lang="pug">
  .mt-3
    h2 {{ $t('automations.labels.source') }}
    .form-group
      label {{ $t('automations.labels.module') }}
      v-select(
        label="name"
        :options="moduleInputPositions"
        v-model="moduleInputPosition"
      )

    h2.mt-4 {{ $t('automations.labels.email_content') }}
    .form-group
      label {{ $t('automations.labels.subject') }}
      input.form-control(
        id="subject"
        name="subject"
        ref="subject"
        v-model="subject"
        v-on:focus="setAsLastFocusedInput('subject')"
      )
    .form-group
      label {{ $t('automations.labels.body') }}
      textarea.form-control(
        id="body"
        name="body"
        ref="body"
        class="automation-module--body"
        v-model="body"
        v-on:focus="setAsLastFocusedInput('body')"
      ) {{ body }}

    .form-group(v-if="!tableIdProp")
      label {{ $t('automations.labels.table') }}
      v-select(
        :searching="true"
        :options="tables"
        label="name"
        :reduce="option => option.id"
        v-model="tableId"
      )

    .form-group
      label {{ $t('automations.labels.availableFields') }}
        small.ml-1 {{ $t('automations.labels.clickToInsert') }}
      .d-flex.flex-wrap
        .cursor-pointer.hover-font-weight-bold.text-sm.mb-2.mr-2.text-gray-700(
           v-for="field in fields"
           @click="addFieldToContent(field)"
           style="flex: 0 0 256px;"
        ) {{ field.name }}


    h2.mt-4 {{ $t('automations.labels.recipients') }}
    .form-group
      .mb-3
        label Emails
        div
          input.form-control(
            :id="'recipients-input'"
            :name="'recipients-input'"
            v-model="recipientsInput"
          )

      .mb-3
        label {{ $t('automations.labels.recipientsField') }}
        .form-group(v-if="showRecipientsFields")
          v-select(
            :searching="true"
            :options="recipientFields"
            label="name"
            :reduce="option => option.id"
            v-model="recipientsFieldId"
          )

    .form-group
      label Cc emails
      input.form-control(
        :id="'cc-recipients-input'"
        :name="'cc-recipients-input'"
        v-model="ccRecipientsInput"
      )

    .form-group(v-if="automationModulePosition > 2")
      h2.mt-4 {{ $t('automations.labels.attachments') }}

      div
        label {{ $t('automations.labels.fromModuleOutput') }}
        v-select(
          :options="outputPositions"
          label="name"
          v-model="automationModuleOutput"
        )

    .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
      button.btn.btn-primary(
        @click.stop="updateAutomationModule"
      ) {{ $t('save') }}
</template>

<script>
import { api } from '../../../../api/client';
import RecordFilters from '../../../RecordFilters';
import Field from '../../../../models/field';
import Table from '../../../../models/table';
import { InsertFieldNameMixin } from '../../../../mixins/insertFieldNameMixin';
import { sortBy } from 'lodash';
import i18n from "../../../../locales/locales.js";

export default {
  components: {
    RecordFilters
  },
  mixins: [InsertFieldNameMixin],
  props: {
    tableIdProp: {
      type: Number,
    },
    automationModuleId: {
      type: Number,
      required: true,
    },
    automationModulePosition: {
      type: Number,
      required: true,
    },
    configParamsProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      recipientsInput: null,
      showRecipientsFields: false,
      subject: null,
      body: null,
      tableId: null,
      tables: [],
      automationModuleOutput: null,
      moduleInputPosition: null,
      recipientFields: [],
      recipientsFieldId: null,
      ccRecipientsInput: null,
      outputPositions: [],
      moduleInputPositions: [],
      lastFocusedInput: null
    }
  },
  watch: {
    tableId: function(newTableId, oldTableId) {
      if(newTableId === oldTableId) return;

      this.loadTableSchemaForConcernedFields();
    }
  },
  created() {
    if (!this.tableIdProp) {
      api.tables.index().then(this.buildTables);
    }

    this.tableId = this.configParamsProp.table_id || this.tableIdProp;
    this.subject = this.configParamsProp.mailer?.subject || '';
    this.body = this.configParamsProp.mailer?.body || '';
    this.recipientsInput = this.configParamsProp.recipients?.from_input || null;
    this.recipientsFieldId = this.configParamsProp.recipients?.from_field_id || null;
    this.ccRecipientsInput = this.configParamsProp.recipients?.from_cc_input || null;
    this.automationModuleOutput = this.configParamsProp.mailer?.files?.from_automation_module_output;
    this.moduleInputPosition = this.moduleInputPositionOption(this.configParamsProp.module_input_position);
    this.moduleInputPositions = Array.from({ length: this.automationModulePosition }, (_, i) => {
      return this.moduleInputPositionOption(i);
    });
    this.outputPositions = Array.from({ length: this.automationModulePosition - 2 }, (_, i) => i + 2)
    this.loadTableSchemaForConcernedFields();
    this.loadTableSchemaForRecipientsFieldId();
  },
  methods: {
    buildTables({ data: tablesData }) {
      this.tables = Table.build(tablesData);
    },
    loadTableSchemaForConcernedFields() {
      if (!this.tableId) return;

      api.fields.index({
        tableId: this.tableId,
      }).then((response) => {
        this.fields = sortBy(Field.build(response.data), 'name');
        this.subject = this.insertFieldNameInContent(this.subject, this.fields);
        this.body = this.insertFieldNameInContent(this.body, this.fields);
      });
    },
    loadTableSchemaForRecipientsFieldId() {
      if (!this.tableId) return;

      api.fields.index({
        tableId: this.tableId,
      }).then((response) => {
        this.showRecipientsFields = false
        setTimeout(() => {
          this.recipientFields = Field.build(response.data);
          const recipientsField = this.fields.filter(field => this.recipientsFieldId === field.id)[0]
          if (recipientsField) this.recipientsFieldId = recipientsField.id;
          this.showRecipientsFields = true;
        });
      });
    },
    updateAutomationModule() {
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        configParams: {
          table_id: this.tableId,
          module_input_position: this.moduleInputPosition.value,
          recipients: {
            from_input: this.recipientsInput,
            from_field_id: this.recipientsFieldId,
            from_cc_input: this.ccRecipientsInput,
          },
          mailer: {
            subject: this.subject,
            body: this.body,
            files: {
              from_automation_module_output: this.automationModuleOutput,
            }
          },
        },
      });
    },
    setAsLastFocusedInput(inputName) {
      this.lastFocusedInput = inputName;
    },
    addFieldToContent(field) {
      if(this.lastFocusedInput) {
        const { selectionStart, selectionEnd } = this.$refs[this.lastFocusedInput];
        const content = this[this.lastFocusedInput];
        this[this.lastFocusedInput] = content.slice(0, selectionStart) +
                                        `{${field.name}}` +
                                        content.slice(selectionEnd);
      }
    },
    moduleInputPositionOption(index) {
      return index ? { name: index, value: index } : { name: i18n.t("automations.labels.no_source"), value: 0 };
    },
  }
}
</script>
