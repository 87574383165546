<template functional lang="pug">
  .cell-content.input-file-container
    .cell-content--file-wrapper.d-flex
      .cell-content--file(v-for="file in props.entry.value")
        .mr-1
          i.fas(:class="file | getFontawesomeIcon")
</template>

<script>
  import { getFontawesomeIcon } from '../../../services/getFileType'

  export default {
    props: {
      entry: Object
    },
    filters: { getFontawesomeIcon }
  }
</script>
