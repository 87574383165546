import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['modal'];

  open() {
    this.element.classList.add('modal-open');
  }

  close() {
    this.element.classList.remove('modal-open');
    this.modalTarget.remove();
  }
}
