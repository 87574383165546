<template lang="pug">
  .mt-3
    .form-group
      label {{ $t('automations.labels.intervalTypes') }}
      v-select(
        :options="intervalTypes"
        label="name"
        :reduce="option => option.value"
        v-model="selectedIntervalType"
      )


    .form-group(v-if="selectedIntervalType === 'weeks'")
      label {{ $t('automations.labels.daysOfWeek') }}
      div(v-for="DayOfTheWeek in Object.keys(daysOfWeek)")
        input(
          type="checkbox"
          :value="daysOfWeek[DayOfTheWeek]"
          :id="'DayOfTheWeek-' + daysOfWeek[DayOfTheWeek]"
          v-model="selectedDaysOfWeek"
        )
        label.ml-2(:for="'DayOfTheWeek-' + daysOfWeek[DayOfTheWeek]") {{ $t('daysOfWeek.' + DayOfTheWeek) }}

    .form-group(v-if="['days', 'weeks'].includes(selectedIntervalType)")
      label {{ $t('automations.labels.hour') }}
      .d-flex
        .form-group
          v-select(
            :options="hours"
            label="name"
            :reduce="option => option.value"
            v-model="selectedHour"
          )

        .form-group
          v-select(
            :options="minutes"
            label="name"
            :reduce="option => option.value"
            v-model="selectedMinute"
          )

    .form-group(v-if="selectedIntervalType === 'cron'")
      label Cron
      input.form-control(
        id="cron"
        name="cron"
        v-model="cron"
      )

    .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
      button.btn.btn-primary(
        @click.stop="updateAutomationModule"
        :disabled="!configParamsComplete"
      ) {{ $t('save') }}
</template>

<script>
import { api } from '../../../../api/client';
import i18n from "../../../../locales/locales.js";

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    configParamsProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedIntervalType: null,
      selectedDaysOfWeek: [],
      selectedHour: null,
      selectedMinute: null,
      intervalTypes: [
        { name: i18n.t('days'), value: 'days' },
        { name: i18n.t('weeks'), value: 'weeks' },
        { name: 'Cron', value: 'cron' },
      ],
      daysOfWeek: { 'monday': 1, 'tuesday': 2, 'wednesday': 3,'thursday': 4, 'friday': 5, 'saturday': 6, 'sunday': 0 },
      hours: [...Array(24).keys()].map(hour => {
        return { name: ("0" + hour).slice(-2), value: hour.toString() };
      }),
      minutes: ["0", "15", "30", "45"].map(minute => {
        return { name: ("0" + minute).slice(-2), value: minute };
      }),
      cron: null,
    }
  },
  created() {
    this.selectedIntervalType = this.configParamsProp.interval_type === undefined ? null : this.configParamsProp.interval_type;
    this.selectedDaysOfWeek = this.configParamsProp.days_of_week === undefined ? [] : this.configParamsProp.days_of_week;
    this.selectedHour = this.configParamsProp.hour === undefined ? null : this.configParamsProp.hour;
    this.selectedMinute = this.configParamsProp.minute === undefined ? null : this.configParamsProp.minute;
    this.cron = this.configParamsProp.cron === undefined ? null : this.configParamsProp.cron;
  },
  computed: {
    configParamsComplete() {
      if (!this.selectedIntervalType) return false;

      if(['days', 'weeks'].includes(this.selectedIntervalType)) {
        if (!this.selectedHour || !this.selectedMinute) return false;
        if (this.selectedIntervalType === 'weeks' && this.selectedDaysOfWeek.length == 0) return false;
      } else if (this.selectedIntervalType === 'cron') {
        if (!this.cron || this.cron === '') return false;
      }

      return true;
    }
  },
  methods: {
    updateAutomationModule() {
      let configParams = { interval_type: this.selectedIntervalType }

      if (['days', 'weeks'].includes(this.selectedIntervalType)) {
        configParams.hour = this.selectedHour;
        configParams.minute = this.selectedMinute;
      }
      if (this.selectedIntervalType === 'weeks') configParams.days_of_week = this.selectedDaysOfWeek;
      if (this.selectedIntervalType === 'cron') configParams.cron = this.cron;

      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        kind: 'at_scheduled_time',
        configParams
      });
    },
  }
}
</script>
