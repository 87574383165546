<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @click.self="closeModal"
)
  .base-modal.new-repeatable-record-modal.p-3(:style="sameModalOffsetStyle")
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")

    .base-modal-header
      p.pr-3
        i.fad.fa-calendar-plus.mr-2
        | {{ $t("repeatable_records.repeat_record") }}
        span.ml-1.text-info {{ sourceRecord.displayName }}
      hr

    .base-modal-body
      p.px-3.text-center.text-danger(v-if="!isDatePresent") {{ $t("repeatable_records.no_value_for_repetition") }}

      template(v-if="isDatePresent")
        .form-group.d-flex.align-items-baseline.flex-wrap
          v-select.small-width.d-inline.text-lowercase(
            v-model="numberOfOccurrences"
            id="number-of-occurrences"
            :clearable="false"
            :append-to-body="true"
            :options="numberOfOccurrencesOptions"
          )
          label.ml-1(for="number-of-occurrences") {{ $t("repeatable_records.occurrence") }}{{ numberOfOccurrences > 1 ? 's' : '' }}
        .form-group
          v-select.d-inline(
            v-model="occurrenceOption"
            :clearable="false"
            :options="OCCURRENCE_OPTIONS"
            :append-to-body="true"
            :get-option-label="option => $t(`occurrences.${option}`)"
          )

        .form-group.d-flex.align-items-baseline.flex-wrap(v-if="isCustomOccurrence")
          label.ml-1(for="every-number-of-days") {{ $t("occurrences.every") }}
          input.w-auto(v-model="customOccurrence" type="number" id="every-number-of-days")
          v-select.small-width.d-inline.text-lowercase(
            v-model="customPeriod"
            :clearable="false"
            :options="['days', 'month']"
            :get-option-label="option => $t(option)"
          )

        hr.mt-4

      .form-group.d-flex.align-items-baseline.flex-wrap(v-if="dateFields.length > 1")
        span {{ $t("repeatable_records.use_the_field") }}
        v-select.mx-2(
          v-model="dateField"
          :clearable="false"
          :options="dateFields"
          label="name"
          :append-to-body="true"
        )
        span {{ $t("repeatable_records.for_repeat") }}.

      template(v-if="isDatePresent")
        .form-group(v-if="hasChildTable")
          input.cursor-pointer(v-model="includeChildrenRecords" type="checkbox" id="include-children-records")
          label.cursor-pointer.ml-1(for="include-children-records") {{ $t("repeatable_records.include_children_records") }} <strong>{{ childTable.name }}</strong> ?

        .form-group.d-flex.align-items-baseline.flex-wrap(v-if="hasChildTable && includeChildrenRecords && childTableDateFields.length > 1")
          span {{ $t("repeatable_records.use_the_field") }}
          v-select.mx-2(
            v-model="childTableDateField"
            :clearable="false"
            :options="childTableDateFields"
            label="name"
            :append-to-body="true"
          )
          span {{ $t("repeatable_records.for_repeat") }} {{ $t("repeatable_records.of_children_records") }} <strong>{{ childTable.name }}</strong>.

    .base-modal-footer(v-if="isDatePresent")
      button.btn.default-btn.ml-auto.d-block(@click="repeatRecord") {{ $t("confirm") }}
</template>

<script>
import { BaseModalMixin } from "./BaseModalMixin"
import { mapActions } from "vuex"
import { clamp } from "lodash"
import Record from '../../models/record'
import { api } from '../../api/client'
import { requireConfirmation } from '../../../components/require_confirmation'
import { EventBus } from '../../main'

const OCCURRENCE_OPTIONS = Object.freeze(["every_day", "every_week", "every_two_week", "every_month", "custom"])
const MAX_NUMBER_OF_OCCURRENCES = 25
const SUPPORTED_FIELD_TYPES = Object.freeze(["date", "date_time_range"])

export default {
  mixins: [BaseModalMixin],
  props: {
    sourceRecord: {
      required: true,
      type: Object
    },
    recordModalId: {
      required: true,
      type: String
    }
  },
  data() {
    return {
      numberOfOccurrencesOptions: [...Array(MAX_NUMBER_OF_OCCURRENCES + 1).keys()].slice(1),
      numberOfOccurrences: 5,
      customOccurrence: 1,
      customPeriod: "days",
      occurrenceOption: OCCURRENCE_OPTIONS[0],
      includeChildrenRecords: false,
      dateField: null,
      childTableDateField: null
    }
  },
  computed: {
    sourceRecordTable() {
      if (this.sourceRecord) {
        return this.sourceRecord.table
      }
      return {}
    },
    dateFields() {
      return this.dateFieldsForTable(this.sourceRecordTable)
    },
    childTableDateFields() {
      return this.dateFieldsForTable(this.childTable)
    },
    childTable() {
      if (this.sourceRecordTable.hasRelationship && !this.sourceRecordTable.relationalData.child) {
        return this.sourceRecordTable.relationalData.childTable
      }
    },
    hasChildTable() {
      return Boolean(this.childTable)
    },
    referenceFieldToChildTable() {
      if (this.sourceRecordTable.hasRelationship) {
        return this.sourceRecordTable.relationalData.relationalField
      }
    },
    numberOfChildrenRecords() {
      if (this.referenceFieldToChildTable) {
        const { value } = this.sourceRecord.entriesByFieldId[this.referenceFieldToChildTable.id]
        return value.length
      }
    },
    isCustomOccurrence() {
      return this.occurrenceOption === "custom"
    },
    isDatePresent() {
      if (this.sourceRecord && this.dateField) {
        const entry = this.sourceRecord.entriesByFieldId[this.dateField.id]
        if (this.dateField.fieldType === "date") {
          return !!entry?.value
        } else {
          return !!entry?.value?.start_date_time
        }
      }
    },
    confirmationText() {
      let frequency = this.$t(`occurrences.${this.occurrenceOption}`);
      if (this.isCustomOccurrence) {
        frequency = `${this.$t("occurrences.every")} ${this.customOccurrence} ${this.$t(this.customPeriod)}`
      }
      let text =  this.$t("repeatable_records.confirmation", {
        occurrences: this.numberOfOccurrences,
        plural: this.numberOfOccurrences > 1 ? 's' : '',
        recordDisplayName: this.sourceRecord.displayName,
        frequency
      })
      if (this.includeChildrenRecords && this.numberOfChildrenRecords) {
        text += this.$t("repeatable_records.children_records_confirmation", {
          numberOfChildrenRecords: this.numberOfChildrenRecords * this.numberOfOccurrences,
          plural: this.numberOfChildrenRecords * this.numberOfOccurrences > 1 ? 's' : '',
          childTableName: this.childTable.name
        })
      }
      return text
    }
  },
  watch: {
    numberOfOccurrences(newValue) {
      this.numberOfOccurrences = clamp(newValue, 1, MAX_NUMBER_OF_OCCURRENCES)
    },
    hasChildTable(newValue) {
      this.includeChildrenRecords = newValue
    },
    dateFields([ firstField ]) {
      this.dateField = firstField
    },
    childTableDateFields([ firstField ]) {
      this.childTableDateField = firstField
    },
  },
  created() {
    this.OCCURRENCE_OPTIONS = OCCURRENCE_OPTIONS
  },
  mounted() {
    this.dateField = this.dateFields[0]
    this.childTableDateField = this.childTableDateFields[0]
    this.includeChildrenRecords = this.hasChildTable
  },
  methods: {
    ...mapActions({
      pushRecordIdForPresenceCheck: 'presenceStore/pushRecordIdForPresenceCheck'
    }),
    dateFieldsForTable({ fields = [] } = {}) {
      return fields.filter(this.supportedField)
    },
    supportedField({ fieldType, typeOptions: { repeatable_record_source } }) {
      return SUPPORTED_FIELD_TYPES.includes(fieldType) && repeatable_record_source
    },
    buildForm() {
      let form = {
        occurrence: this.occurrenceOption,
        number_of_occurrences: this.numberOfOccurrences,
        date_field_id: this.dateField.id,
        include_children_records: this.includeChildrenRecords,
      }
      if (this.isCustomOccurrence) {
        form = { ...form, custom_number: this.customOccurrence, custom_period: this.customPeriod }
      }
      if (this.includeChildrenRecords && this.childTableDateField) {
        form = { ...form, child_table_date_field_id: this.childTableDateField.id }
      }
      return form
    },
    repeatRecord() {
      requireConfirmation(this.confirmationText, this.$t('confirm'), this.$t('cancel'))
        .then(result => {
          if (result.isConfirmed) {
            this.closeModal();
            this.closeRecordModal();
            this.sendCreateRequest();
          }
        });
    },
    closeRecordModal() {
      EventBus.$emit(`closeRecordModal${this.recordModalId}`)
    },
    sendCreateRequest() {
      api.repeatableRecords
         .create(this.sourceRecord, this.buildForm())
         .then((response) => {
           this.addRepeatedRecordsToTable(response);
         })
    },
    addRepeatedRecordsToTable(response) {
      const records = Record.build(response.data.records, this.sourceRecordTable, { buildEntries: false, creationConfirmed: true })
      records.forEach((record) => {
        this.$store.dispatch("recordStore/addRecord", record)
        this.pushRecordIdForPresenceCheck({ recordId: record.id })
      });
    }
  }
}
</script>

<style lang="scss">
.new-repeatable-record-modal {
  &.base-modal {
    max-width: 400px;
  }

  input[type="number"] {
    max-width: 50px;
    appearance: textfield;
    padding: 0 .5rem;
    margin: 0 .5rem;
    text-align: center;
    color: unset;
    height: 27px;
  }

  .vs__dropdown-toggle {
    min-width: 150px;

  }

  .small-width .vs__dropdown-toggle {
    min-width: 90px;
  }
}
</style>
