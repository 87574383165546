<template lang="pug">
  span.view-action.mr-1(
    @click="$emit('open')"
    :class="{ opened: show }"
  )
    span.view-action--btn
      i.far.fa-eye-slash.fa-fw.view-action--btn-icon
      span.view-action--btn-text.ml-1 {{ $t('controlBarActions.showHide') }}
      .ctrlbar-action(
        v-if="show"
        v-click-outside="closeAction"
      )
        .ctrlbar-action--header
          .d-flex.align-items-center.w-100.p-1
            .select-list-search-icon.mr-2
              i.far.fa-search
            input.select-list-filter.flex-grow-1.mr-2.border-0(
              v-focus
              type="text"
              autocomplete="off"
              :placeholder="$t('searchField')"
              v-model="filterElementsInput"
              :key="selectedElementId + elementObjectName"
            )
          .ctrlbar-action--close(@click.stop="closeAction")
            svg.icon
              use(xlink:href="#icon-times-solid")
        .ctrlbar-action--body
          draggable(
            :list="filteredElements"
            handle=".move-column-handle"
            draggable=".draggable-field"
            :animation="150"
            :delay="100"
            ghostClass="sortable-custom-ghost"
            chosenClass="sortable-custom-chosen"
            dragClass="sortable-custom-drag"
            @change="moveColumnAndShowFirstColumn"
          )
            .ctrlbar-action--text.hoverable.draggable-field(
              v-for="field in filteredElements"
              :key="field.id"
              v-select-list-element="field"
              :class="{ 'first-movable-field': field === movableFields[0] }"
            )
              .ctrlbar-action--action-option.w-100
                .ctrlbar-action--label(:for="field.id + '-visible'")
                  span.ctrlbar-action--label-icon(:class="{ active: field.visible }")
                    span.ctrlbar-action--label-icon-pill

                  i.mr-1.fal.fa-sm.fa-fw(:class="'fa-'+ field.faIconName")
                  span.has-tooltip(
                    data-toggle="tooltip"
                    :title="field.name"
                    :class="{ [translationFallbackClasses]: field.nameFallback }"
                  ) {{ field.name | truncate(30) }}
                    i.my-auto.ml-2.far.fa-lock-alt.font-weight-lighter(
                      v-if="field.primary"
                      class="has-tooltip"
                      data-toggle="tooltip"
                      :title="$t('fields.primaryTooltip')"
                    )
                .move-column-handle.text-secondary
                  i.fas.fa-grip-vertical
        .ctrlbar-action--footer.pt-2
          .ctrlbar-action--btn.mr-2(@click="updateAllColumnVisibily(false)") {{ $t('views.hideAll') }}
          .ctrlbar-action--btn(@click="updateAllColumnVisibily(true)") {{ $t('views.showAll') }}
</template>

<script>
import { api }                              from '../../api/client';
import { map }                              from 'lodash'
import { mapState, mapGetters, mapActions } from 'vuex'
import { selectListMixin }                  from '../../mixins/selectListMixin';
import { MoveColumnMixin }                  from '../../mixins/MoveColumnMixin';
import draggable                            from 'vuedraggable';

export default {
  mixins: [selectListMixin, MoveColumnMixin],
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  components: {
    draggable,
  },
  computed: {
    selectListElements() {
      return this.orderedFields;
    },
    componentIsOnForeground() {
      return this.show;
    },
    hideableFields() {
      return this.orderedFields.slice(1) // SKIP THE FIRST ONE AS IT IS USE AS FIXED FIRST COLUMN
    },
    movableFields() {
      return this.orderedFields;
    },
    ...mapState(['translationFallbackClasses']),
    ...mapState({
      view: state => state.viewStore.view
    }),
    ...mapGetters({
      orderedFields: 'fieldStore/orderedFields',
    })
  },
  data() {
    return {
      elementObjectName: 'field'
    }
  },
  methods: {
    ...mapActions({
      computeFieldsLeftOffset: 'fieldStore/computeFieldsLeftOffset'
    }),
    closeAction() {
      this.$emit('close');
      this.cleanSelectList();
    },
    updateAllColumnVisibily(visibility) { // ALL AT ONCE
      this.hideableFields.forEach(field => field.visible = visibility)

      api.viewFields.updateColumnsVisibility({
        viewId:     this.view.id,
        fieldIds:   map(this.hideableFields, 'id'),
        visibility: visibility
      }).then(this.computeFieldsLeftOffset);
    },
    updateViewField(field) {
      const viewField = { visible: field.visible };
      api.viewFields.update({ viewId: this.view.id, fieldId: field.id, viewField });
      this.computeFieldsLeftOffset()
    },
    elementSelected(field, eventType) {
      if (this.selectListElements.indexOf(field) === 0) return;

      field.visible = !field.visible;
      this.updateViewField(field);
    },
    moveColumnAndShowFirstColumn(event) {
      // MOVE COLUMN
      this.moveColumn(event);

      // SHOW FIRST COLUMN
      const firstField = this.movableFields[0];
      firstField.visible = true;
      this.updateViewField(firstField);
    },
  }
}
</script>
