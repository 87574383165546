<template lang="pug">
  .cell-content.input-file-container
    .input-file-btn.mr-2(@click="openFileManager(entry)")
      i.fas(:class="entry.isWritable ? 'fa-plus' : 'fa-eye'")
    template(v-if="!entry.value.length")
      .cell-content--file-placeholder(@click="openFileManager(entry)")
    .cell-content--file-wrapper.d-flex
      .cell-content--file(v-for="file in entry.value")
        a.has-tooltip(
          @click.stop
          :href="file.url"
          target="_blank" rel="noopener noreferrer"
          data-toggle="tooltip"
          :title="file.filename"
        )
          .mr-1
            i.fas(:class="'fa-' + getFileType(file.filename, file.content_type)")
</template>

<script>
  import { EntryFileMixin } from '../../../mixins/EntryFileMixin'
  import { EventBus }       from '../../../main.js'

  export default {
    mixins: [EntryFileMixin],
    props: {
      entry: Object
    },
    methods: {
      openFileManager(entry) {
        EventBus.$emit('openModal', {
          modalName: 'EntryFileManagerModal',
          modalProps: entry
        }); // EVENT RECEIVER: ./EntryFileManagerModal.vue
      }
    }
  }
</script>
