import { mapGetters } from 'vuex';

export const copyEntryMixin = {
  created() {
    document.addEventListener('copy', this.copyEntryValueToClipBoard);
  },
  beforeDestroy() {
    document.removeEventListener('copy', this.copyEntryValueToClipBoard);
  },
  computed: {
    ...mapGetters({
      selectedEntry: 'gridStore/selectedEntry'
    }),
  },
  methods: {
    copyEntryValueToClipBoard(event) {
      const entry = this.selectedEntry;
      if (!entry) return;

      const entryCopyValue = entry.toString();
      event.clipboardData.setData('text/plain', entryCopyValue);
      event.preventDefault();
    }
  }
};
