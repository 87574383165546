<template lang="pug">
  .mt-3
    .form-group
      label {{ $t('automations.labels.identifier') }}
      input.form-control(
        id="identifier"
        name="identifier"
        v-model="identifier"
      )

    .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
      button.btn.btn-primary(
        @click.stop="updateAutomationModule"
        :disabled="!configParamsComplete"
      ) {{ $t('save') }}
</template>

<script>
import { api } from '../../../../api/client';

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    configParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      identifier: null,
    }
  },
  created() {
    this.identifier = this.configParams.identifier === undefined ? null : this.configParams.identifier;
  },
  computed: {
    configParamsComplete() {
      return this.identifier && this.identifier !== '';
    }
  },
  methods: {
    updateAutomationModule() {
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        kind: 'when_called',
        configParams: { identifier: this.identifier }
      });
    },
  }
}
</script>
