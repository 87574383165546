<template lang="pug">
  section.my-3
    p.font-weight-bolder {{ $t('editOptions.select') }}
    section.d-flex.mt-1.mb-1(v-if="canEditField")
      input.form-control.form-control-sm(
        :placeholder="$t('editOptions.newOption')"
        @keydown.enter="addSelect"
        v-model="newSelect"
      )
      button.default-btn.ml-2(@click.stop="addSelect") {{ $t('add') }}

    .string.field_name.d-flex.mb-2(
      v-for="(_option, index) in editedField.typeOptions.select_list"
    )
      input.form-control.form-control-sm.mr-2(
        v-model="editedField.typeOptions.select_list[index]"
        :disabled="!canEditField"
      )
      .btn.btn-sm.btn-submit.delete-icon.js-remove-select-item(
        v-if="canEditField"
      )
        i.far.fa-trash-alt(
          @click.stop="deleteSelect(index)"
        )
</template>

<script>
  export default {
    props: {
      editedField: {
        type: Object,
        required: true
      },
      canEditField: {
        type: Boolean,
        required: true
      },
    },
    data() {
     return {
        newSelect: "",
      };
    },
    methods: {
      addSelect() {
        if (!this.editedField.typeOptions.select_list) this.editedField.typeOptions.select_list = [];
        this.editedField.typeOptions.select_list.push(this.newSelect);
        this.newSelect = "";
      },
      deleteSelect(index) {
        this.editedField.typeOptions.select_list.splice(index,1);
      }
    }
  }
</script>
