<template lang="pug">
  span.view-action(
    @click="toggleAlert"
  )
    span.view-action--btn.view-action--btn-alert
      i.fas.fa-bell-on.fa-fw.view-action--btn-icon(v-if='this.currentView.alert')
      i.far.fa-bell-slash.fa-fw.view-action--btn-icon(v-else)
</template>
<script>
import { mapState } from "vuex";
import { api }      from '../../api/client';
import { EventBus } from '../../main.js';


export default {
  methods: {
    toggleAlert() {
      api.views.toggleAlert({
        view: this.currentView
      }).then(response => {
        this.currentView.alert = response.data.view.alert;
        EventBus.$emit('toggleAlert');
      });
    }
  },
  computed: {
    ...mapState({
      currentView: state => state.viewStore.view
    })
  }
};
</script>
