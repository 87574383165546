import { find,
  filter,
  isEqual,
  every,
  cloneDeep } from 'lodash';
import RecordFilterBasicInput  from '../components/record_filters_inputs/RecordFilterBasicInput';
import RecordFilterBasicSelect from '../components/record_filters_inputs/RecordFilterBasicSelect';
import RecordFilterBoolean from '../components/record_filters_inputs/RecordFilterBoolean';
import RecordFilterDate from '../components/record_filters_inputs/RecordFilterDate';
import RecordFilterReferences  from '../components/record_filters_inputs/RecordFilterReferences';

export const RecordFiltersMixin = {
  components: {
    'filter-input-string':          RecordFilterBasicInput,
    'filter-input-number':          RecordFilterBasicInput,
    'filter-input-files':           RecordFilterBasicInput,
    'filter-input-references':      RecordFilterReferences,
    'filter-input-select':          RecordFilterBasicSelect,
    'filter-input-users':           RecordFilterBasicSelect,
    'filter-input-boolean':         RecordFilterBoolean,
    'filter-input-date':            RecordFilterDate,
    'filter-input-date_time_range': RecordFilterDate,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    originalQuery: {
      type: Object,
      required: true
    },
    appliedQuery: {
      type: Object,
      required: false
    },
    loadingRecords: {
      type: Boolean,
      required: false
    },
    showSaveButton: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    filterableFields() {
      return filter(this.fields, "isUserAccessible");
    },
    filtersAreValid() {
      return every(this.editableFilters, this.filterIsValid);
    },
    queryChanged() {
      return !isEqual(this.originalQuery, this.currentQueryPayload);
    },
    filterCanBeSaved() {
      return this.showSaveButton;
    },
    queryStringCanBeApplied() {
      return this.filtersAreValid && this.queryChanged;
    },

  },
  watch: {
    currentQueryPayload(newValue, _oldValue) {
      this.$emit('query-payload-changed', cloneDeep(newValue), this.filtersAreValid);
    }
  },
  created() {
    if (this.appliedQuery) return this.buildEditableQuery(this.appliedQuery);
    this.buildEditableQuery(this.originalQuery)
  },
  methods: {
    filterIsValid(filter) {
      const valueIsNeeded = !this.inputlessOperators.includes(filter.operator);
      return (this.filterHasValue(filter) && valueIsNeeded) || !valueIsNeeded;
    },
    filterHasValue(filter) {
      return ![null, undefined, 0, [], ''].includes(filter.value);
    },
    changeFilterField(filter, field) {
      Object.assign(filter, this.filterAttributes(field));
    },
    changeFilterOperator(filter, operator) {
      filter.operator = operator;
      filter.value    = this.filterDefaultValue(filter.dataType);
    },
    filterDefaultValue(dataType) {
      const defaultValue = this.filtersDefaultValuesbyDataType[dataType];
      return typeof defaultValue === 'undefined' ? null : defaultValue;
    },
    addFilter(_event) {
      const field = this.fields[0];

      this.editableFilters.push(this.filterAttributes(field));
    },
    removeFilter(index) {
      this.editableFilters.splice(index, 1);
    },
    operatorDataType(field) {
      if (field) {
        const operationalDataTypes = ['users', 'date_time_range', 'number', 'date', 'references', 'select', 'string', 'boolean'];

        if (field.dataType === 'lookups' && operationalDataTypes.includes(field.typeOptions.lookups_field_data_type)) {
          return field.typeOptions.lookups_field_data_type;
        } else {
          return field.dataType;
        }
      }
    },
    buildFilter({ field_id, value, operator }) {
      const field = find(this.fields, ['id', Number.parseInt(field_id)]);
      const dataType = this.operatorDataType(field);
      return { field, value, operator, dataType };
    }
  }
}
