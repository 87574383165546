<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @mousedown.self="closeModal"
)
  .base-modal(:style="sameModalOffsetStyle")
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")


    //- HEADER
    .base-modal-header.px-3
      h5
        span.mr-2.text-info
          i.far.fa-cog
        span.text-xl.text-gray-800.font-weight-bolder {{ $t('dashboard.blockSettings')}}
    //- BODY
    form(
      @submit.prevent="saveBlockSettings"
    )
      .base-modal-body.px-3
        .form-group
          label(for="block-name") {{ $t('dashboard.blockName') }}
          input.form-control(id="block-name" type="text" v-model="editableBlockTitle")
        component(
          :is="'dashboard-block-settings-' + dashboardBlock.kind"
          :dashboardBlock="dashboardBlock"
          :editableSettings="editableKindSettings"
        )
      //- FOOTER
      .base-modal-footer.px-3.mt-4.d-flex.justify-content-around
        button.btn.btn-cancel(
          type="button"
          @click.prevent="closeModal"
        )
          span {{ $t('cancel') }}

        button.btn.default-btn(
          type="submit"
        ) {{ $t('save') }}
</template>

<script>
import DashboardBlockSettingsAlerts from '../dashboard_blocks/settings/DashboardBlockSettingsAlerts';
import DashboardBlockSettingsKpi from '../dashboard_blocks/settings/DashboardBlockSettingsKPI';
import DashboardBlockSettingsList from '../dashboard_blocks/settings/DashboardBlockSettingsList';
import DashboardBlockSettingsSchedule from '../dashboard_blocks/settings/DashboardBlockSettingsSchedule';
import DashboardBlockSettingsMap from '../dashboard_blocks/settings/DashboardBlockSettingsMap';
import DashboardBlockSettingsTimescale from '../dashboard_blocks/settings/DashboardBlockSettingsTimescale';
import DashboardBlockSettingsLive from '../dashboard_blocks/settings/DashboardBlockSettingsLive';
import DashboardBlockSettingsPeakperiod from '../dashboard_blocks/settings/DashboardBlockSettingsPeakPeriod';
import DashboardBlockSettingsCalendar from '../dashboard_blocks/settings/DashboardBlockSettingsCalendar';
import DashboardBlockSettingsSourcedChart from '../dashboard_blocks/settings/DashboardBlockSettingsSourcedChart';
import DashboardBlockSettingsSourcedKPI from '../dashboard_blocks/settings/DashboardBlockSettingsSourcedKPI';
import DashboardBlockSettingsReportextract from '../dashboard_blocks/settings/DashboardBlockSettingsReportExtract';
import DashboardBlockSettingsNcclist from '../dashboard_blocks/settings/DashboardBlockSettingsNccList';
import DashboardBlockSettingsChart from '../dashboard_blocks/settings/DashboardBlockSettingsChart';
import DashboardBlockSettingsLoadShift from '../dashboard_blocks/settings/DashboardBlockSettingsLoadShift';
import DashboardBlockSettingsRegionalmap from '../dashboard_blocks/settings/DashboardBlockSettingsRegionalMap';
import DashboardBlockSettingsFlexContacts from '../dashboard_blocks/settings/DashboardBlockSettingsFlexContacts';
import DashboardBlockSettingsRankings from '../dashboard_blocks/settings/DashboardBlockSettingsRankings';
import DashboardBlockSettingsText from '../dashboard_blocks/settings/DashboardBlockSettingsText';

import { BaseModalMixin } from './BaseModalMixin';
import { cloneDeep } from 'lodash';
import DashboardBlock from '../../models/dashboardBlock';
import { EventBus } from '../../main';
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';


export default {
  mixins: [BaseModalMixin, DashboardBlockMixin],
  components: {
    DashboardBlockSettingsAlerts,
    DashboardBlockSettingsKpi,
    DashboardBlockSettingsCalendar,
    DashboardBlockSettingsList,
    DashboardBlockSettingsSchedule,
    DashboardBlockSettingsMap,
    DashboardBlockSettingsTimescale,
    DashboardBlockSettingsLive,
    DashboardBlockSettingsPeakperiod,
    'dashboard-block-settings-sourced_chart': DashboardBlockSettingsSourcedChart,
    'dashboard-block-settings-sourced_kpi': DashboardBlockSettingsSourcedKPI,
    DashboardBlockSettingsReportextract,
    DashboardBlockSettingsNcclist,
    DashboardBlockSettingsChart,
    'dashboard-block-settings-load_shift': DashboardBlockSettingsLoadShift,
    DashboardBlockSettingsRegionalmap,
    'dashboard-block-settings-flex_contacts': DashboardBlockSettingsFlexContacts,
    DashboardBlockSettingsRankings,
    DashboardBlockSettingsText,
  },
  data() {
    return {
      editableBlockTitle:    '',
      editableKindSettings: {}
    }
  },
  methods: {
    onModalOpen() {
      this.editableBlockTitle   = this.dashboardBlock.title;
      this.editableKindSettings = cloneDeep(this.dashboardBlock.kind_settings);
    },
    saveBlockSettings() {
      const previousTitle = this.dashboardBlock.title;
      DashboardBlock.api().patch(`api/dashboard_blocks/${this.dashboardBlock.id}`, {
        title:         this.editableBlockTitle,
        kind_settings: this.editableKindSettings
      }).then(() => {
        if (previousTitle !== this.dashboardBlock.title) {
          DashboardBlock.update({
            where: this.dashboardBlock.id,
            data: { title_fallback: false }
          });
        }
        EventBus.$emit('blockUpdated');
        this.closeModal();
      });
    }
  }
}
</script>
