<template lang="pug">
  section.my-3
    .mb-1.font-weight-bold
      input(
        type="checkbox"
        name="limitToOneRecord"
        v-model="editedField.typeOptions.limit_to_one_record"
        :disabled="!canEditField"
      )
      label.ml-2(
        for="limitToOneRecord"
      ) {{ $t('editOptions.oneEntry') }}
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      editedField: {
        type: Object,
        required: true
      },
      canEditField: {
        type: Boolean,
        required: true
      },
    },
    computed: {
      ...mapState({
        currentTables: state => state.tableStore.tables,
        tableViews:    state => state.tableStore.table.views,
      }),
      foreignTableViews() {
        const table = this.currentTables.find(table => {
          return table.id === this.editedField.typeOptions.foreign_table_id;
        });
        if (!table) return [];
        return table.views;
      }
    },
  }
</script>
