import { units } from "./units";

export const es = {
  units,
  true: "Sí",
  false: "No",
  yes: "Sí",
  no: "No",
  create: "Crear",
  apply: "Aplicar",
  continue: "Continuar",
  update: "Modificar",
  cancel: "Cancelar",
  back: "atrás",
  close: "Cerrar",
  save: "Guardar",
  saved: "Guardado",
  delete: "Borrar",
  confirm: "Confirmar",
  deny: "Rechazar",
  add: "Añadir",
  days: "días",
  and: "y",
  or: "o",
  is: "es",
  at: "a",
  rename: "Renombrar",
  tables: "Tablas",
  table: "Tabla",
  view: "Vista",
  field: "Campo",
  dashboards: "Cuadros de mando",
  folder: "Carpeta",
  home: "Inicio",
  workspace: "Espacio de trabajo",
  newTable: "Nueva tabla",
  seeMoreDetails: "Ver más",
  no_alerts: "Sin alertas",
  history: "Historia",
  selectAll: "Seleccionar todo",
  selectNothing: "Deseleccionar todo",
  selectRecord: "Seleccionar entrada",
  noOptionForSelect: "Sin resultados",
  recordWillBeFiltered: "Esta entrada no será visible debido a los filtros de esta vista.",
  send: "Enviar",
  submit: "Enviar",
  preview: "Vista previa",
  addNewField: "Añadir una nueva columna",
  addReferenceRecord: "Añadir una entrada",
  addExistingReferenceRecord: "Añadir una entrada existente",
  createNewRecord: "Crear una nueva entrada",
  searchReference: "Buscar una entrada",
  searchField: "Buscar un campo",
  noRecordsFound: "No se ha encontrado ninguna entrada",
  noRecords: "No se han encontrado entradas",
  current_user: "Usuario actual",
  addUserRecord: "Añadir un usuario",
  searchUser: "Buscar un usuario",
  noUsersFound: "No se ha encontrado ningún usuario",
  hiddenFields: "campo oculto | campos ocultos",
  lockedRecordInfo: "Esta entrada está bloqueada y no se puede borrar ni editar.",
  updateForbidden: "Esta modificación está prohibida porque no respeta los derechos de uso de tu perfil.",
  forbidden: "No está permitido realizar esta acción.",
  mainErrorMsg: "Lo sentimos, ha habido un problema...",
  tryToReload: "Intenta recargar la página",
  default: "Por defecto",
  SUM: "Suma",
  AVG: "Media",
  MEDIAN: "Mediana",
  days: "Días",
  weeks: "Semanas",
  month: "mes",
  times: "veces",
  loading: "Cargando",
  preview: "Previsualizar",
  addAttribute: "Añadir un atributo",
  occurrences: {
    every_day: "Cada día",
    every_week: "Cada semana",
    every_two_week: "Cada dos semanas",
    every_month: "Cada mes",
    custom: "Personalizado",
    every: "Cada"
  },
  repeatable_records: {
    occurrence: "incidencia",
    repeat_record: "Repetir la entrada",
    repeat_this_record: "Repetir esta entrada",
    use_the_field: "Utilizar el campo",
    for_repeat: "para repetir",
    of_children_records: "de los",
    include_children_records: "Incluir los",
    no_value_for_repetition: "La entrada no puede repetirse porque el campo de fecha no está rellenado.",
    confirmation: "Al confirmar, creará {occurrences} entrada{plural} para la entrada: <span class=\"text-info\">{recordDisplayName}</span>, con la siguiente frecuencia: <i>{frequency}</i>, ¿estás seguro?",
    children_records_confirmation: "</br></br>También crearás {numberOfChildrenRecords} entrada{plural} en la tabla <strong>{childTableName}</strong>."
  },
  deleteRecordOptions: {
    deleteOptions: "Opciones de borrado",
    forRecurringEvent: "Para el evento periódico",
    thisEvent: "Este evento",
    thisAndTheFollowingEvents: "Este evento y otros posteriores",
    allEvents: "Todos los eventos",
    forParentRecord: "Para la entrada padre",
    WithoutChildrenRecords: "Sin los {childTableName} asociados",
    WithChildrenRecords: "Con los {childTableName} asociados",
  },
  fields: {
    primaryTooltip: "El campo primario se utiliza como nombre de visualización para las referencias.",
    noForeignTableId: "No se pueden buscar entradas.<br/>Compruebe la configuración del campo.",
    readAccess: "Campo de sólo lectura",
    writeAccess: "Campo por escrito",
    system: "Campo del sistema",
    useToRepeatRecords: "¿Usar para repetir entradas?",
    currentTemplate: "modelo actual ",
    newTemplate: "nuevo modelo ",
    none: "ninguno",
    fieldsToInsertInTheDocument: "Campos a insertar en el documento",
  },
  routes: {
    views: "vistas"
  },
  models: {
    record: {
      noDisplayName: "Entrada sin nombre",
    }
  },
  saving: "Guarda",
  controlBarActions: {
    getZipFile: "Descargar archivos adjuntos",
    filtersApplied: "Filtros aplicados",
    filter: "Filtrar",
    noFilter: "Ningún filtro aplicado",
    noAttribute: "Ningún atributo",
    addFilter: "Añadir un filtro",
    restoreFilters: "Restablecer filtros",
    restoreSorts: "Restablecer la clasificación",
    filterBy: "Filtrar por",
    filteredBy: "Filtrado por",
    tablePrefiltered: "Esta tabla está prefiltrada (clic para ver)",
    updateViewFilters: "Guardar",
    newRecordCreated: "Nueva entrada creada",
    addRecord: "Nueva entrada",
    sort: "Clasificar",
    noSorting: "Sin clasificación",
    createView: "Duplicar",
    showHide: "Ocultar",
    download: "Descargar",
    editViewDescription: "Editar la descripción de la vista",
    editViewOptions: "Editar las opciones de la vista",
    newView: "Añadir una nueva vista",
    calendar: "Calendario",
    gallery: "Galería",
    grid: "Hoja de cálculo",
    board: "Kanban",
    form: "Formulario",
    searchView: "Buscar una vista",
    downloadToCalendarFormat: "Descargar a formato calendario",
    importData: "Importar datos",
    applyEverywhereThisViewFieldsDisplay: "Aplicar este diseño de columnas en todas partes",
    dissociateView: "Desvincular la vista",
    deleteTable: "Borrar la tabla",
    cantDeleteLastView: "No se puede desvincular la última vista de una tabla.",
    cantDeleteLastTable: "No se puede borrar la última tabla.",
    lockView: "Vista desbloqueada. Haz clic para bloquear la vista.",
    unlockView: "Vista bloqueada. Haz clic para desbloquear la vista.",
    lockedView: "Vista bloqueada",
    editRecordValidations: "Validación de las entradas",
    saved: "Guardado!",
  },
  calendarNav: {
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    selectedCalendars: "Calendarios seleccionados",
  },
  recordModal: {
    alreadyOpened: "Esta entrada ya está abierta.",
    noParentRecord: "No hay entrada padre asociada del campo {fieldName}.",
    associateParentRecord: "Asociar una entrada padre",
    confirmNoCreation: "Esta entrada no se registra en la tabla.</br><strong>¿Deseas crear esta entrada?</strong></br><i>Esta entrada se perderá si no la creas.</i>",
  },
  calendarModal: {
    newCalendarView: "Nueva vista de calendario",
    editCalendarView: "Modificación del calendario",
    startAtField: "Columna para la fecha de inicio",
    endAtField: "Columna para la fecha final",
    title: "Columna para el título de cada evento",
    reference: "Columna para filtrar calendarios",
    details: "Columna para los detalles",
    endDateOption: "Modo de finalización del evento",
    range: "Período de tiempo",
    end_date_field: "Campo de fecha final",
    location: "Columna para la ubicación",
    organizer_email: "Columna para el correo electrónico del organizador",
    organizer_name: "Columna para el nombre del organizador",
    attendees: "Colonne para los invitados",
  },
  dashboard: {
    addBlock: "Añadir un módulo",
    editMode: "Modo edición",
    endEditMode: "Finalizar la edición",
    blockSettings: "Parámetros del módulo",
    blockName: "Nombre del módulo",
    newBlock: "Nuevo módulo",
    confirmDeleteBlock: "¿Borrar el módulo?",
    details: "Detalles",
    display_period_selector: "Mostrar el selector de fecha",
  },
  dashboardBlockKinds: {
    alerts: "Alertas",
    calendar: "Calendario",
    chart: "Gráfico estándar",
    flex_contacts: "Contactos FLEX",
    kpi: "KPI",
    list: "Lista",
    live: "En directo",
    load_shift: "Desprendimientos de carga",
    map: "Mapa",
    ncclist: "NCC",
    peakperiod: "Días PP2",
    regionalmap: "Regiones",
    reportextract: "Informe",
    schedule: "Agenda",
    sourced_chart: "Gráfico con fuentes",
    sourced_kpi: "KPI con fuentes",
    standalonekpi: "KPI bruto",
    stat: "Estadísticas",
    table: "Tabla",
    text: "Texto",
    timescale: "Gráfico",
  },
  dashboardBlocks: {
    noInfo: "No hay información disponible",
    backToList: "Volver a la lista",
    blockIsNotConfigured: "Es necesario configurar este módulo.",
    apiError: "Datos temporalmente no disponibles, por favor inténtelo más tarde.",
    noSitesSelected: "Ningún sitio seleccionado",
    alerts: {
      level: "Nivel",
      more: "además",
      negative_price: "Alerta de precio negativa en curso",
      noAlerts: "Ninguna alerta en curso"
    },
    kpi: {
      labels: {
        period: "Período",
        api_source_id_field_id: "Campo para el id de la fuente API",
        kind_technology_field_id: "Campo para el tipo de tecnología",
        kind: "Tipo de datos",
        data_contracts_table_id: "Campo de la tabla Datos contractuales",
        engagement_value_field_id: "Campo para el valor del compromiso de potencia",
        engagement_kind_field_id: "Campo para el tipo de compromiso",
        wiped_power_field_id: "Campo para la potencia eliminada",
        trigger_power_field_id: "Campo para la potencia del tacón",
        performance_table_id: "Tabla para los contratos ENR",
        performance_value_field_id: "Campo para el rendimiento de referencia",
        start_date_field_id: "Campo para la fecha de inicio del contrato",
        end_date_field_id: "Campo para la fecha de finalización del contrato",
        solar_installed_capacity_field_id: "Campo para el valor de la potencia fotovoltaica instalada",
        other_installed_capacity_field_id: "Campo para el valor de la capacidad instalada de otras tecnologías",
        referenced_site_field_id: "Campo para los sitios referenciados"
      },
      dataTypes: {
        production: "Producción",
        contract: "Datos contractuales",
        enr_performance: "Rendimiento ENR"
      }
    },
    nccList: {
      site: "Sitio",
      ncc: "NCC",
      certifiedCapacityLevel: "Nivel de Capacidad Certificado",
    },
    labels: {
      list_id: "Módulo de lista de los elementos que deben mostrarse",
      default_title_field_id: "Título por defecto",
      default_description_field_id: "Descripción por defecto",
      schedule_id: "Módulo de agenda para los elementos que deben mostrarse",
      calendar_id: "Módulo de calendario para los elementos que deben mostrarse",
      autoResize: "Tamaño auto.",
      select_table_to_configure: "Seleccionar una tabla para configurarla",
      concerned_table_ids: "Tablas que deben incluirse",
      table_id: "Tabla",
      view_id: "Vista",
      level_view_ids: "Vistas de nivel {level}",
      concerned_months: "Mes de operación (Todos si está vacío)",
      enabled_only_on_weekdays: "Activado sólo para los días laborables",
      disabled_on_holidays: "Discapacitados por vacaciones y días festivos",
      list_alert_default_level: "Nivel de alerta por defecto de la lista",
      level_name: "Nombre del nivel {level}",
      level_color: "Color del nivel {level}",
      locked_title: "¿Título bloqueado?",
      table_alert_default_level: "Nivel de alerta por defecto de la tabla",
      separated_by_commas: "separados por comas",
      title_field_id: "Campo para el título",
      description_field_id: "Campo para la descripción",
      icon_field_id: "Campo para el icono",
      color_field_id: "Campo para el color",
      start_date_field_id: "Campo para la fecha de inicio",
      end_date_field_id: "Campo para la fecha final",
      api_source_id_field_id: "Campo para el id de la fuente API",
      api_id: "Campo para el id de la fuente API",
      type: "Campo para el tipo de Vertical Power",
      label_field_id: "Campo para la etiqueta",
      reference_field_id: "Campo para la referencia",
      additional_params: "Parámetros adicionales",
      advanced_params: "Parámetros avanzados",
      styleTypes: {
        relative: "Relativo",
      }
    },
    list: {
      displayAllDetails: "Detalles",
      displayAllMaintenances: "mantenimiento",
      yes: "sí",
      no: "no",
      inProgress: "En proceso",
      labels: {
        table_id: "Tabla",
        view_id: "Vista",
        title_field_id: "Campo para el título",
        description_field_id: "Campo para la descripción",
        technology_field_id: "Campo para la tecnología",
        icon_field_id: "Campo para el icono",
        image_field_id: "Campo para el imagen",
        color_field_id: "Campo para el color",
        list_additional_field_ids: "Campos adicionales para la lista",
        details_additional_field_ids: "Campos adicionales para los detalles",
        disable_sorts_and_filters: "Desactivar clasificación y filtrado",
        disable_item_select: "Desactivar la selección de elementos",
        disable_details: "Desactivar el detalle",
        associations_table_id: "Tabla para asociaciones",
        principal_table_id: "Tabla principal",
        associations_view_id: "Vista para las asociaciones",
        associations_reference_field_id: "Campo para las asociaciones",
        associations_reference_field_id_site: "Campo para las asociaciones de sitios de producción",
        associations_reference_field_id_perf: "Campo para las asociaciones de rendimiento",
        associations_reference_field_id_date: "Campo para las asociaciones de fechas",
        main_reference_field_id_start_date: "Campo para la fecha de inicio del precio negativo",
        filtered_field_ids: "Campos que se pueden filtrar",
        parent_list: "Módulo de lista de padres para prefiltros",
        parent_list_reference_field_id: "Campo para filtros de lista de padres",
        api_source_id_field_id: "Campo para el id de la fuente API",
        add_margin: "Añadir espacio a la derecha de la lista",
        year_field_id: "Campo para el año",
        ncc_field_id: "Campo para el valor de NCC",
        site_name_field_id: "Campo para el nombre del sitio",
      }
    },
    reportextract: {
      kinds: {
        performances: "Informe sobre resultados",
        negative_price_monitoring: "Seguimiento de los precios negativos",
        flex_activity: "Actividades e Indisponibilidad FLEX",
      },
      labels: {
        report_kind: "Tipo de informe",
        sites_table_id: "Tabla para los sitios",
        contracts_table_id: "Tabla para los contratos",
        collects_table_id: "Tabla para las hojas de recogida",
        site_name_field_id: "Campo para el nombre del sitio",
        site_file_name_field_id: "Campo para el nombre del archivo del sitio",
        site_source_id_field_id: "Campo para el id de OPINUM",
        site_card_id_field_id: "Campo para el CARD ID",
        site_technology_field_id: "Campo para la tecnología",
        contract_site_reference_field_id: "Campo para la referencia del sitio",
        contract_target_field_id: "Campo para el rendimiento objetivo (€/MWh)",
        contract_start_date_field_id: "Campo para la fecha de inicio del contrato",
        contract_end_date_field_id: "Campo para la fecha final del contrato",
        monitoring_table_id: "Tabla para el seguimiento",
        site_reference_field_id: "Campo para la referencia del sitio",
        card_id_field_id: "Campo para el CARD ID",
        start_of_period_field_id: "Campo para la hora de precio negativo",
        first_half_hour_production_field_id: "Campo para la primera media hora de producción",
        last_half_hour_production_field_id: "Campo para la segunda media hora de producción",
        total_production_field_id: "Campo para la producción total",
        bonus_field_id: "Campo para la prima",
        comment_field_id: "Campo para los comentarios",
        responsability_field_id: "Campo para la responsabilidad",
        calendar_id: "Módulo calendario del año",
        first_day_of_week_field_id: "Campo para el primer día de la semana",
      },
    },
    live: {
      since: "Desde",
      noCurrentEvents: "Ningún evento en curso",
      labels: {
        maxNumberOfLines: "Número máximo de líneas (Tamaño auto.)"
      }
    },
    calendar: {
      availabilities: "Disponibilidades",
      unavailabilities: "Indisponibilidades",
      activations: "Activaciones",
      negative: "Precio negativo",
      negatives: "Producción en precio negativo",
      notifiedOn: "notificado el",
      downloadReport: "Descargar el informe",
      labels: {
        calendar_type: "Tipo de calendario",
        peak_periods_table_id: "Tabla de alertas PP1/PP2",
        peak_periods_one_field_id: "Campo para PP1",
        peak_periods_two_field_id: "Campo para PP2",
        peak_periods_period_field_id: "Campo para la fecha",
        activations_table_id: "Tabla para los desprendimientos de carga",
        activations_site_field_id: "Campo para el sitio",
        activations_period_field_id: "Campo para la hora del desprendimiento de carga",
        activations_file_field_id: "Campo para el documento asociado",
        activations_notification_date_field_id: "Campo para la hora de notificación de desprendimiento de carga",
        unavailabilities_table_id: "Tabla para los indisponibilidades",
        unavailabilities_table_site_field_id: "Campo para el sitio",
        unavailabilities_period_field_id: "Campo par el periodo de indisponibilidad",
      },
      legend: {
        pp2: "PP2",
        pp1pp2: "PP1/PP2",
        dayWithActivations: "Día con activación",
        sitesAvailability: "Disponibilidad de los sitios",
        dayWithNegativePrice: "Día con precio negativo",
        sitesWithoutProductionRatio: "Ratio de sitios sin producción",
      }
    },
    map: {
      default_coordinates: [-3.097902, 40.664729],
      labels: {
        longitude_field_id: "Campo para la longitud",
        latitude_field_id: "Campo para la latitud",
        title_field_id: "Campo para el título",
        detail_field_id: "Campo para el detalle",
        alert_id: "Módulo \"Alertas\" para los elementos que deben mostrarse",
      },
      errors: {
        listIsNotConfigured: "El módulo de listas no está configurado correctamente"
      }
    },
    timescale: {
      download: "Descargar",
      labels: {
        period: "Seleccione un periodo",
        year: "Seleccione un año de entrega",
        color: "Campo para el color del gráfico",
        chart_kind: "Tipo de gráfico",
        datatype: "Tipo de datos",
        select_year: "Seleccionar el año",
        aggregating_field_id: "Campo para agregar datos",
        load_shift_date_field_id: "Campo para la fecha de desprendimiento de carga",
        y_axis_field_id: "Campo para la agregación de datos ordinales",
        keep_empty_cell: "Llevar la cuenta de las células vacías",
        calculation_type: "Tipo de agregación",
        computation_mode: {
          difference: "MWh",
          ratio: "%",
          assets: "Por instalación",
          technologies: "Por tecnologías",
          customers: "Por clientes",
          aggregated: "Agregado",
        },
        aggregated: {
          true: "Curva agregada",
          false: "Curva por sitio",
          entities: {
            true: "Curva agregada",
            false: "Curva por instalación",
          },
        },
        contract_start_date_field_id: "Fecha de inicio del contrato",
        contract_end_date_field_id: "Fecha de finalización del contrato",
        unique_element: "¿Debe ser única la entrada seleccionada?"
      }
    },
    peakperiod: {
      header: "Días y previsiones PP1/PP2",
      labels: {
        table_id: "Tabla de alertas PP1/PP2",
        view_id: "Vista",
        date_field_id: "Campo para la fecha",
        pp1_value_field_id: "Campo para el valor PP1",
        pp2_value_field_id: "Campo para el valor PP2",
        estimated_value_field_id: "Campo para el riesgo PP1/PP2",
      },
      today: "Hoy",
      tomorrow: "Mañana",
      confirmed: "Informe PP1/PP2",
      prevision: "Día de riesgo notificado",
      source: {
        confirmed: "Fuente: RTE",
        prevision: "% de riesgo de aparición de días PP1 / PP2, calculado por TEFP"
      },
      ecowatt: "Ecowatt",
      ecowatt_not_declared: "No declarado",
      ecowatt_no_alert: "Sin alerta",
      ecowatt_level1: "Día tenso",
      ecowatt_level2: "Día crítico"
    },
    capacityAuction: {
      labels: {
        table_id: "Tabla",
        view_id: "Vista",
        year_field_id: "Campo para el año de entrega",
        date_field_id: "Campo para la fecha",
        capacity_auction_value_field_id: "Campo para el valor de la subasta",
      },
    },
    load_shift: {
      objective: "Objetivo",
      average_consumption: "Consumo medio",
      average_load_shift: "Desprendimiento de carga medio",
      consumed: "Consumido",
      erased: "Erased",
      reference_power: "Potencia de referencia"
    },
    regionalMap: {
      header: "Información regional en MWh",
      Biomass: "Biomasa",
      Hydro: "Hydro",
      Wind: "Eólica",
      Solar: "Solar",
      CogenGas: "Cogeneración a gas",
      CogenWaste: "Cogeneración a residuos",
    },
    flex_contacts: {
      eda: "EDA",
      entity: "Entidad",
      site: "Sitio",
      CALL: "Llamadas",
      SMS: "SMS",
      MAIL: "Emails",
      noAssociatedSite: "No estás asociado a ningún sitio.",
      downloadContacts: "Descargar todos los contactos",
      invalidFormat: "Formato no válido",
      labels: {
        flex_contacts_table_id: "Tabla para exportación en Excel de los contactos FLEX",
        download_view_id: "Vista para exportación en Excel"
      }
    },
    sourcedChart: {
      displayAsLine: "Mostrar como línea",
      forceAggregation: "Forzar agregación",
      realTime: "Tiempo real",
      labels: {
        sources: "Seleccion de las diferentes fuentes de datos",
        title: "Campo para el título",
        api_id: "Campo para el id de la fuente API",
        type: "Campo para el tipo de Vertical Power",
        technology: "Campo para la tecnología",
        customer: "Campo para el cliente",
        program_to_display: "Programa a mostrar"
      },
      sources: {
        "auto_grid/real_time_active_power": "Tiempo real - Potencia activa",
        "vertical_power/available_power": "Producible",
        "vertical_power/day_ahead_commitment": "Programa Day Ahead",
        "vertical_power/client_program": "Programa de cliente",
        "vertical_power/day_ahead_market_price": "Precios de mercado Day Ahead",
        "vertical_power/estimated_revenues": "Ingresos estimados",
        "vertical_power/forecast": "Previsiones",
        "vertical_power/imbalance": "Imbalance",
        "vertical_power/intraday_commitment": "Programa intraday",
        "vertical_power/intraday_market_price": "Precios de mercado Intraday",
        "vertical_power/measured_production": "Medida liquidable",
        "vertical_power/monthly_imbalance_and_revenues_kpi": "Solo KPI - Imbalance e ingresos mensuales",
        "vertical_power/output_generation": "Activo saliente",
        "vertical_power/power_factor": "Factor de potencia",
        "vertical_power/reactive_power": "Potencia reactiva",
        "vertical_power/active_power": "Activa entrante",
        "vertical_power/ree_limitations": "Limitaciones"
      }
    },
    text: {
      labels: {
        text: "Texto",
      },
    },
  },
  galleryModal: {
    newGalleryView: "Nueva vista de galería"
  },
  gridModal: {
    newGridView: "Nueva vista de hoja de cálculo"
  },
  formModal: {
    newFormView: "Nueva vista de formulario"
  },
  gallery: {
    focus: "Ampliar"
  },
  views: {
    scope: "Visibilidad",
    profileViewCreationNotAllowed: "Sólo puede crear vistas personales",
    selectSort: "Elige un campo para ordenar",
    entrySelectDefault: "Elige una opción",
    sortBy: "Ordenar por",
    thenBy: "entonces por",
    createViewPlaceholder: "Nombre de la vista",
    createView: "Crear la vista",
    updateView: "Actualizar la vista",
    profileView: "Vista de perfil",
    alert: "Alerta",
    showAll: "Todos",
    hideAll: "Ninguno",
    records: "Entrada | Entradas",
    addRecord: "Nueva entrada",
    editRecord: "Modificar entrada",
    foreignRecordFrom: "Entrada de la tabla {tableName}",
    noFieldAccessible: "Tu perfil no te permite acceder a esta vista.",
    files: "archivos",
    addFiles: "Añadir archivos",
    users: "Usuarios",
  },
  fileManager: {
    fileManager: "Gestión de archivos",
    dropzoneDragAndDrop: "Arrastra y suelta para subir tus archivos!",
    dropzoneClickToSelectAFileFromYourComputer: "... o haz clic para seleccionar un archivo de tu ordenador",
    myFiles: "Mis archivos",
    newFilesToUpload: "Nuevos archivos para cargar",
    upload: "Cargar",
    processing: "Actualmente procesando...",
    operationCanTakeAFewSeconds: "La operación puede tardar unos segundos...",
    operationCanTakeAFewMinutes: "La operación puede tardar unos minutos...",
    fileVerified: "Fichero verificado por el antivirus",
    fileNotYetVerified: "Archivo aún no verificado por el antivirus"
  },
  entries: {
    select: {
      noReferences: "Sin referencias",
      noUsers: "Sin usuarios",
      noOptions: "Sin opciones",
    }
  },
  actionsColumn: {
    actions: "Acciones",
    editColumn: "Configurar",
    deleteColumn: "Borrar columna",
    systemField: "Campo del sistema",
    lockedField: "Campo cerrado",
    nameColumn: "Nombre de la columna",
    description: "Descripción",
    typeOfData: "Tipo de datos",
    confirmEdit: "Ha cambiado el tipo de datos de este campo.<br>Los datos asociados pueden borrarse o verse comprometidos como resultado de esta operación.<br><span style='color: #E64C43;'>No es reversible</span>.<br><br>¿Estás seguro?",
    confirmDelete: "Eliminará este campo.<br><span style='color: #E64C43;'>No es reversible</span>.<br><br>¿Estás seguro?",
  },
  actionsRow: {
    confirmDelete: "Eliminará esta entrada.<br><span style='color: #E64C43;'>No es reversible</span>.<br><br>¿Estás seguro?",
  },
  fieldType: {
    number: "Número",
    string: "Texto",
    date: "Fecha",
    date_time_range: "Periodo de tiempo",
    boolean: "Sí/No",
    select: "Lista de opciones",
    files: "Archivos",
    references: "Referencias",
    users: "Usuarios",
    formula: "Fórmula",
    lookups: "Búsqueda",
    created_at: "Fecha de creación",
    last_modified_at: "Fecha de modificación",
    created_by: "Creador",
    last_modified_by: "Último modificador",
  },
  editOptions: {
    date: "Gestionar formato de fecha",
    number: "SelecSeleccionar unidad",
    select: "Editar opciones",
    formula: "Gestionar los parámetros del campo fórmula",
    formula_data_type: "Formato de salida",
    newOption: "Nueva opción",
    selectTable: "Seleccionar la tabla a referenciar",
    oneEntry: "Limitar a una entrada",
    selectVue: "Limitar la selección a una vista determinada",
    noTable: "Sin tabla",
    noView: "Sin vista",
    selectReferencesField: "Seleccionar el campo Referencias",
    selectLookupsField: "Seleccione el campo en el que desea buscar",
    isFiltered: "Cumple ciertas condiciones",
    antivirusScan: "Escanear archivos con antivirus",
    acceptedFileFormats: "Formatos de archivo aceptados",
  },
  projectFileAcceptedFormats: {
    all: "Todos los formatos",
    excel: "Microsoft Excel (.xls, .xlsx)"
  },
  confirmations: {
    applyEverywhereFieldsDisplay: "¿Estás seguro de que quieres aplicar esta distribución de columnas a todas las vistas de perfil y tabla personal?",
    deleteView: "¿Está seguro de que desea desvincular esta vista? Si esta vista no está vinculada a ningún otro perfil, se borrará.",
    deleteTable: "¿Está seguro de que desea borrar esta tabla?",
    deleteDashboard: "¿Está seguro de que desea borrar este cuadro de mando?",
    deleteFolder: "¿Está seguro de que desea borrar esta carpeta?",
    deletePage: "¿Estás seguro de que quieres borrar esta página?"
  },
  rightClick: {
    copy: "Copia",
    cut: "Cortar",
    paste: "Pegar",
    duplicate: "Duplicar la entrada",
    edit: "Editar la entrada",
    deleteRecord: "Borrar la entrada",
    addField: "Añadir una columna",
    deleteField: "Borrar columna",
  },
  viewForm: {
    whatDoYouWantToDo: "¿Qué quieres hacer?",
    createRecord: "Crear una entrada",
    editRecord: "Editar la entrada",
    formSubmitted: "El formulario ha sido enviado",
  },
  time: {
    custom: "Personalizado",
    currentWeek: "Semana actual",
    date_range: "Periodo",
    lastFourWeeks: "Últimas 4 semanas",
    lastSevenDays: "Últimos 7 días",
    lastThreeMonths: "Últimos 3 meses",
    lastTwelveMonths: "Últimos 12 meses",
    month: "Mes",
    monthly: "Mensual",
    monthToDate: "Desde principios del mes",
    week: "Semana",
    year: "Year",
    yearToDate: "Desde principios del año",
    fromLastWeekToNextWeek: "De la semana pasada a la próxima",
  },
  filterOperators: {
    contains: "contiene",
    doesNotContain: "no contiene",
    containsToday: "contiene hoy",
    containsNow: "contiene ahora",
    isEmpty: "está vacío",
    isNotEmpty: "no está vacío",
    isEqualTo: "es igual a",
    isNotEqualTo: "no es igual a",
    is: "es",
    isNot: "no es",
    isOn: "es el",
    isNotOn: "no es el",
    fromExcluded: "desde (excluido)",
    fromIncluded: "desde (incluido)",
    untilExcluded: "hasta (excluido)",
    untilIncluded: "hasta (incluido)",
    isToday: "es hoy",
    overlaps: "coincide con",
    doesNotoverlap: "no coincide con",
    isPast: "ha pasado",
    isNotPast: "no ha pasado",
    isInLessThan: "está en menos de",
    isInMoreThan: "está en más de",
    hasBeenLessThan: "había menos de",
    hasBeenMoreThan: "había más de",
    reference: "referencia la entrada",
    doesNotReference: "no hace referencia"
  },
  charts: {
    bar: "Histograma",
    bar_double_axis: "Histograma de doble ejes",
    line: "Curva"
  },
  dataTypes: {
    enr_production: "La producción de electricidad de",
    enr_production_stacked: "La producción acumulada de electricidad por sitio de",
    enr_perf_production: "Producción real de electricidad y rendimiento de",
    enr_forecast_production: "La producción eléctrica real y prevista de",
    flex_production: "El consumo eléctrico de",
    epex_m0: "Los precios EPEX y MO de",
    epex: "El precio EPEX de",
    capacity_auction: "Subasta de capacidad del año de entrega",
    aoe: "Precio de la Licitación de Desprendimiento de Carga",
    perf_target_actual: "Rendimiento alcanzado frente al objetivo de",
    enr_forecast_and_best_production: "La producción real y prevista"
  },
  calendarTypes: {
    calendar_with_all_events: "El calendario de PP1 y PP2",
    calendar_without_pp1: "El calendario sin PP1",
    calendar_negative_price: "El calendario de los días negativos"
  },
  notifications: {
    showAllAlerts: "Ver todas las alertas",
    showMore: "Ver más",
    noAlerts: "Sin alertas",
    noNotifications: "Ninguna notificación",
  },
  automations: {
    labels: {
      activity_times_field_ids: "Campos para tiempos de actividad",
      andTriggerRecord: "Y la entrada activada",
      arguments: "Argumentos",
      attachments: "Adjuntos",
      attributes: "Atributos",
      availableFields: "Campos disponibles",
      body: "Cuerpo",
      clickToInsert: "(haga clic para insertar)",
      collection_sheets_settings: "Configuración de las hojas de recogida",
      collection_sheets_table_id: "Tabla para registros de colecciones",
      concerned_field_ids: "Campos afectados",
      concerned_period: "Periodo afectado",
      concernedFields: "Campos afectados",
      conditions: "Condiciones",
      content: "Contenido",
      custom_dates: "Fechas personalizadas",
      days_in_order_hint: "(por orden, de lunes a domingo)",
      daysOfWeek: "Días de la semana",
      default_period_field_id: "Campo para el periodo por defecto",
      email_content: "Contenido del correo electrónico",
      end_date: "Fecha final",
      fields: "Campos",
      filename_field_id: "Campo para el nombre del archivo",
      filters: "Filtros",
      findRecordsBasedOn: "Buscar entradas basándose en",
      first_day_of_week_field_id: "Campo para el primer día de la semana",
      fromModuleOutput: "De la salida de un módulo",
      hour: "Tiempo",
      identifier: "Identificador",
      intervalTypes: "Tipos de intervalo",
      job: "Tarea",
      module: "Módulo",
      module_number: "Número de módulo",
      next_week: "Próxima semana",
      no_source: "Ninguna fuente",
      options: "Opciones",
      periods_field_ids: "Campos para periodos",
      profiles: "Perfiles",
      recipients: "Destinatarios",
      recipientsField: "Campo de destinatarios",
      reference_to_site_field_id: "Campo para la referencia al sitio",
      source: "Fuente",
      source_id_field_id: "Campo para el id de la fuente",
      start_date: "Fecha de inicio",
      subject: "Asunto",
      synchronization: "Sincronización",
      synchronized: "¿Sincronizado?",
      table: "Tabla",
      unavailabilities_settings: "Configuración de las indisponibilidades",
      unavailability_days_field_ids: "Campos para días de indisponibilidad",
      unavailability_table_id: "Tabla de indisponibilidades",
      unavailability_table: "Tabla de indisponibilidades",
      users: "Usuarios",
      view: "Vista",
    }
  },
  importDataModal: {
    importFile: "Importa el archivo",
    dzMessage: "Haga clic o arrastre el archivo",
    loadingMessage: "<strong>Paso 1/2:</strong><br>Carga del archivo actual.<br>Por favor, espere...",
    dataImportLoadingMessage: "<strong>Paso 2/2:</strong><br>Importación de datos actuales.<br>Por favor, espere...",
    dataImportSuccessMessage: "<strong>Datos importados con éxito.</strong>",
    dataImportErrorMessage: "<strong>Error al importar datos.</strong>",
    importFailed: "La importación falló ",
    atLineNumber: "en la <strong>línea {line_number}</strong><br>",
    becauseFollowingError: "debido al siguiente error de validación:",
    importFailedWarning: "Nota: las líneas anteriores se han importado.",
    CloseModalConfirmation: "Tu formulario de importación no está vacío.<br><br>¿Estás seguro de que quieres cerrarlo?",
    downloadTemplate: "Descargar el modelo",
  },
  editViewDescriptionModal: {
    editViewDescription: "Editar la descripción de la vista",
    viewDescriptionPlaceholder: "Describa la vista",
  },
  editViewOptionsModal: {
    editViewOptions: "Editar las opciones de la vista",
    chooseRedirectionView: "Elegir la vista de redirección",
    editRedirectionView: "Editar la vista de redirección",
  },
  daysOfWeek: {
    sunday: "Domingo",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
  },
  months: {
    january: "Enero",
    february: "Febrero",
    march: "Marzo",
    april: "Abril",
    may: "Mayo",
    june: "Junio",
    july: "Julio",
    august: "Agosto",
    september: "Septiembre",
    october: "Octubre",
    november: "Noviembre",
    december: "Diciembre",
  },
  relative_years: {
    last_year: "Año pasado",
    current_year: "Año en curso",
    next_year: "Próximo año",
  },
  validations: {
    title: "Validaciones de las entradas",
    add_validation_on: "Añadir un campo a validar",
    must_be: "debe ser",
    mustBePresent: "debe estar presente",
    mustBeUnique: "debe ser único",
    must_be_a: "debe ser un",
    add_comparison: "Añadir una comparación",
    add_format: "Añadir un formato",
    empty: "No hay validación en las entradas de esta tabla",
    presence: {
      must_be_present: "El campo {fieldName} debe rellenarse."
    },
    comparison: {
      lt: "inferior",
      gt: "superior",
      gteq: "superior o igual",
      lteq: "inferior o igual",
      eq: "igual",
      not_eq: "diferente",
    },
    format: {
      tel: "número de teléfono (francés)",
      email: "correo electrónico",
    },
    otherAttributeType: {
      field: "al campo",
      relative: "respecto a",
    },
    relativeAttributeValue: {
      today: "hoy",
    },
  },
  pages: {
    new: {
      title: "Crear una página",
    },
    edit: {
      title: "Editar la página",
    },
    form: {
      name: "Nombre de la página",
      type_name: "Tipo de página",
    },
    types: {
      enr_unavailability: "Gestión de los indisponibilidades de ER",
      flex_annual_delivery: "FLEX Anual - Entrega"
    }
  }
};
