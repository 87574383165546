<template lang="pug">
  Popper(
    :config="config"
    :popperId="actionId"
    @open="$emit('pop-action:open')"
    @close="$emit('pop-action:close')"
  )
    OnClickOutside(
      slot-scope="{ isOpen, open, close, updatePopper }" :do="close"
      :remoteElement="$refs.content"
    )
      component(:is="btnTag").pop-action
        span.pop-action-btn(
          :data-popper-trigger="actionId"
          :class="[{ opened: isOpen, active }, ...btnClass]"
          @click="isOpen ? close() : open();"
        )
          slot(name="trigger")
        .pop-action--content.flex-center(
          ref="content"
          v-show="isOpen"
          :data-popper-content="actionId"
          :class="contentClass"
        )
          .close-action.cursor-pointer(
            v-if="showCloseBtn"
            @click="close"
          )
            svg.icon
              use(xlink:href="#icon-times-solid")
          resize-observer(@notify="updatePopper")
          slot(name="content" :close="close" :isOpen="isOpen")
</template>

<script>
import Popper         from './Popper.vue';
import OnClickOutside from './OnClickOutside.vue';
import { uniqueId }   from 'lodash';


export default {
  name: 'pop-action',
  components: {
    Popper,
    OnClickOutside
  },
  props: {
    active: {
      type: Boolean,
      required: false
    },
    btnTag: {
      type: String,
      required: false,
      default: 'div'
    },
    btnClass: {
      type: String,
      require: false
    },
    contentClass: {
      type: String,
      require: false
    },
    showCloseBtn: {
      type: Boolean,
      required: false,
      default: true
    },
    placement: {
      type: String,
      require: false
    },
  },
  data() {
    return {
      actionId: uniqueId('pop_action_'),
      config:   {
        placement: this.placement || 'bottom-start'
      }
    }
  },
  mounted() {
    const body = document.querySelector('body');

    body.appendChild(this.$refs.content);
  },
  beforeDestroy() {
    this.$el.appendChild(this.$refs.content);
  }
}
</script>

