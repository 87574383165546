<template lang="pug">
.form-control.input-file-container(
  @click="openFileManager"
)
  //- template(v-if="entry.isWritable")
  .input-file-btn
    i.fas(:class="entry.isWritable ? 'fa-plus' : 'fa-eye'")
  p.file-return
  template(v-if="!entry.value || entry.value.length === 0")
    .file-placeholder  {{ $t('views.addFiles') }}
  template(v-if="entry.value && entry.value.length === 1")
    .file-wrapper
      .file.over
        .file-preview(
          v-if="getFileType(entry.value[0].filename, entry.value[0].content_type) === 'image'"
          :style="`background-image: url('/project_files/${entry.value[0].id}')`"
        )
        i.fas(
          v-else
          :class="'fa-' + getFileType(entry.value[0].filename, entry.value[0].content_type)"
        )
        a(
          v-on:click.stop
          :href="entry.value[0].url"
          target="_blank" rel="noopener noreferrer"
        )
          | {{ entry.value[0].filename }}
  template(v-else-if="entry.value && entry.value.length > 1")
    .file-wrapper
      .files
        i.fas.fa-copy
        | {{ entry.value.length }} {{ $t('views.files' )}}
</template>

<script>
import { EntryFileMixin } from '../../../mixins/EntryFileMixin';
import { EventBus }       from '../../../main';

export default {
  mixins: [EntryFileMixin],
  props: {
    entry: {
      type:     Object,
      required: true
    }
  },
  methods: {
    openFileManager() {
      EventBus.$emit("openModal", {
        modalName: 'EntryFileManagerModal',
        modalProps: this.entry
      }); // EVENT RECEIVER: ./EntryFileManagerModal.vue
    }
  }
}
</script>
