import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = [
    "timesAndAvailability",
    "activityLevelOnIcon",
    "activityLevelOffIcon",
    "availabilityOnIcon",
    "availabilityOffIcon",
    "activityLevelInput",
    "startTimeInput",
    "endTimeInput",
    "availabilityInput",
  ];
  static values = {
    allHalfHoursOfADay: Array,
  };

  connect() {
    const activityLevelInput = new TomSelect(this.activityLevelInputTarget);
    const startTimeInput = new TomSelect(this.startTimeInputTarget);
    const endTimeInput = new TomSelect(this.endTimeInputTarget);
    const availabilityInput = new TomSelect(this.availabilityInputTarget);

    if (this.activityLevelInputTarget.getAttribute("readonly") == "readonly") activityLevelInput.lock();
    if (this.startTimeInputTarget.getAttribute("readonly") == "readonly") startTimeInput.lock();
    if (this.endTimeInputTarget.getAttribute("readonly") == "readonly") endTimeInput.lock();
    if (this.availabilityInputTarget.getAttribute("readonly") == "readonly") availabilityInput.lock();
  }

  onActivityLevelChange(event) {
    if (event.target.value == "100") {
      this.timesAndAvailabilityTarget.classList.remove("tw-hidden");
      this.activityLevelOnIconTarget.classList.remove("tw-hidden");
      this.activityLevelOffIconTarget.classList.add("tw-hidden");
    } else if (event.target.value == "0") {
      this.startTimeInputTarget.tomselect.setValue(this.allHalfHoursOfADayValue[0]);
      this.endTimeInputTarget.tomselect.setValue(this.allHalfHoursOfADayValue[0]);
      this.availabilityInputTarget.tomselect.setValue("0");
      this.timesAndAvailabilityTarget.classList.add("tw-hidden");
      this.activityLevelOnIconTarget.classList.add("tw-hidden");
      this.activityLevelOffIconTarget.classList.remove("tw-hidden");
    }
  }

  onAvailabilityChange(event) {
    if (event.target.value == "100") {
      this.availabilityOnIconTarget.classList.remove("tw-hidden");
      this.availabilityOffIconTarget.classList.add("tw-hidden");
    } else {
      this.availabilityOnIconTarget.classList.add("tw-hidden");
      this.availabilityOffIconTarget.classList.remove("tw-hidden");
    }
  }

  onStartTimeInputChange() {
    this.endTimeInputTarget.classList.remove("invalid");

    if (this.isStartTimeValueBeforeEndTimeValue()) {
      this.startTimeInputTarget.classList.remove("invalid");
    } else {
      this.startTimeInputTarget.classList.add("invalid");
    }

    this.addInvalidErrorsForEmptyTimes();
  }

  onEndTimeInputChange() {
    this.startTimeInputTarget.classList.remove("invalid");

    if (this.isStartTimeValueBeforeEndTimeValue()) {
      this.endTimeInputTarget.classList.remove("invalid");
    } else {
      this.endTimeInputTarget.classList.add("invalid");
    }

    this.addInvalidErrorsForEmptyTimes();
  }

  isStartTimeValueBeforeEndTimeValue() {
    const startTime = this.startTimeInputTarget.value;
    const endTime = this.endTimeInputTarget.value;

    return !startTime ||
      !endTime ||
      this.allHalfHoursOfADayValue.indexOf(startTime) < this.allHalfHoursOfADayValue.indexOf(endTime);
  }

  addInvalidErrorsForEmptyTimes() {
    if (!this.startTimeInputTarget.value) this.startTimeInputTarget.classList.add("invalid");
    if (!this.endTimeInputTarget.value) this.endTimeInputTarget.classList.add("invalid");
  }
}
