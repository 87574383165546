<template lang="pug">
.dashboard-kpis-card(ref="blockContent")
  template(v-if="settingsAreReady")
    .dashboard-block--header.pb-3
      h2 {{ dashboardBlock.title }}
    .dashboard-kpis
      .flex-center.h-100(v-if="apiError")
        h2.text-center.text-warning
          i.fas.fa-exclamation-triangle
        p.font-italic.ml-2 {{ $t('dashboardBlocks.apiError') }}
      template(v-else)
        .dashboard-kpi-wrapper(
            v-for="kpi in items"
            :title="kpi.description"
          )
          .kui-card.dashboard-kpi
            .dashboard-kpi--icon.bg-project-secondary-100.text-project-secondary.tw-relative
              i.fad.fa-fw(:class="'fa-' + kpi.icon")
              .tw-h-4.tw-w-4.tw-rounded-full.tw-absolute.tw-top-0.tw-right-0.heartbeat.tw-bg-red-400(v-if="pollingInterval" style="margin-top: -0.3rem; margin-right: -0.3rem")
            .content
              .dashboard-kpi--label &nbsp;{{ kpi.label_name }}
              .dashboard-kpi--figure
                span.dashboard-kpi--value.tw-whitespace-nowrap {{ kpi.value }}
                span.dashboard-kpi--unit {{ kpi.unit }}
  .flex-center.h-100(v-else)
    div
      h2.text-center.text-warning
        i.fas.fa-exclamation-triangle
      p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}
</template>

<script>
import { api }                   from '../../api/client';
import { mapGetters }            from 'vuex';
import DashboardBlock            from '../../models/dashboardBlock';
import { DashboardBlockMixin }   from '../../mixins/DashboardBlockMixin';
import { isEqual, map,debounce } from 'lodash';
import DashboardSharedItem       from '../../models/dashboardSharedItem';

export default {
  mixins: [DashboardBlockMixin],
  props: {
    periodDates: {
      type:     Array,
      required: true
    }
  },
  data() {
    return {
      items: [],
      debouncedLoadKpis: () => {},
      initialLoadDone: false,
      apiError: false,
      pollingInterval: null,
    }
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    settingsAreReady() {
      return this.dashboardBlock &&
              this.dashboardBlock.kind_settings.list_id &&
              this.dashboardBlockList &&
              this.dashboardBlockList.kind_settings.table_id &&
              this.dashboardBlockList.kind_settings.view_id
    },
    dashboardBlockList() { // we need the list block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.list_id);
    },
    tableForKpi() {
      if (this.settingsAreReady) {
        return this.getTableById(this.dashboardBlockList.kind_settings.table_id);
      }
    },
    selectedRecordIds() {
      if (this.tableForKpi) {
        const items = DashboardSharedItem.listItems(this.dashboardBlockList.id, true).
                                          where('metadata', value => value.checked).get();
        return map(items, 'item_id');
      }
    },
    shouldAutoResize() {
      // to override computed from DashboardBlockMixin, cannot be in data
      return true;
    }
  },
  watch: {
    'selectedRecordIds': function (newRecordIds, oldRecordIds) {
      if (this.settingsAreReady && !isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) {
        this.loadKpis();
      }
    },
    'periodDates': function (newPeriodDates, _oldPeriodDates) {
      if (!newPeriodDates) return;
      this.loadKpis();
    }
  },
  mounted() {
    if (!this.settingsAreReady) return;
    window.addEventListener("resize", this.resizeBlock);

    // First time component is loaded, we want to wait until all needed list modules are loaded before
    // actually loading the chart, hence the `trailing: true, leading: false`
    // see #_loadKpis for behavior once intial load is done
    this.debouncedLoadKpis = debounce(
      this._loadKpis,
      500, { trailing: true, leading: false }
    )
    setTimeout(() => { this.loadKpis() }, 500);

    // Poll data from server every 10 seconds
    this.setPollingInterval(10000)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeBlock);
    clearInterval(this.pollingInterval);
  },
  methods: {
    loadKpis() {
      this.apiError = false;
      this.debouncedLoadKpis();
    },
    _loadKpis() {
      // When chart has been loaded at least one time,
      // then we want the load to be triggered right away (when aggregate buttons are clicked for example)
      // - to avoid the user to feel latency -
      // so we change the debounce settings for `{ trailing: true, leading: true }`
      if (!this.initialLoadDone) {
        this.initialLoadDone = true;
        this.debouncedLoadKpis = debounce(
          this._loadKpis,
          500, { trailing: true, leading: true }
        )
      }
      return api.dashboardBlocks.sourcedkpis(this.dashboardBlock.id, this.selectedRecordIds, this.periodDates, this.periodIdentifier).then(({ data }) => {
        if (data.error && data.message === "IncompleteEntitySchema") {
            return this.settingsAreReady = false;
          }

        if (data.error && data.message === "APIFailure") {
          return this.apiError = true;
        }

        // Ignore when real time
        if (data.error && data.message === "AutoGridClient::APIFailure") {
          if (this.pollingInterval) {
            return {};
          } else {
            return this.apiError = true;
          }
        }

        this.items = data.items;
      }).then(this.resizeBlock);
    },
    setPollingInterval(interval) {
      clearInterval(this.pollingInterval);
      if (this.dashboardBlock.kind_settings.real_time) {
        this.pollingInterval = setInterval(() => { this._loadKpis() }, interval)
      }
    },
  },
}
</script>
