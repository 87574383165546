import { mapState, mapGetters } from "vuex";
import store                    from "../store/store";
import { EventBus }             from "../main";

export const gridNavigationMixin = {
  created() {
    window.addEventListener("keydown", this.handleGridNavigation);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleGridNavigation);
  },
  computed: {
    ...mapState({
      openedModals: state => state.openedModals
    }),
    ...mapGetters({
      selectedEntry: "gridStore/selectedEntry"
    })
  },
  methods: {
    isClassicInput(entry) {
      return ["number", "string", "lookups", "date"].includes(entry.field.dataType);
    },
    isSelectInput(entry) {
      return ["select"].includes(entry.field.dataType);
    },
    isModalInput(entry) {
      return ["files", "select", "references"].includes(entry.field.dataType);
    },
    isArrowKey(key) {
      return ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(key);
    },
    handleGridNavigation(event) {
      const entry = this.selectedEntry;

      if (entry && !this.openedModals.length) {
        if (["ArrowUp", "ArrowDown"].includes(event.key))
          event.preventDefault();
        if (
          ["ArrowLeft", "ArrowRight"].includes(event.key) &&
          !(this.isClassicInput(entry) && entry.status === "write")
        )
          event.preventDefault();

        if (this.isArrowKey(event.key)) {
          if (
            !(this.isClassicInput(entry) && entry.status === "write") &&
            !document.querySelector("#selectCellInput:focus")
          )
            store.dispatch("gridStore/" + event.key);
        }

        if (event.key === "Tab") {
          event.preventDefault();
          event.shiftKey
            ? store.dispatch("gridStore/ArrowLeft")
            : store.dispatch("gridStore/ArrowRight");
        }

        if (event.key === "Enter" || event.key === "F2") {
          if (this.isSelectInput(entry)) return;

          if (entry.status === "write" && !this.isModalInput(entry)) {
            setTimeout(() => store.dispatch("gridStore/ArrowDown"));
          } else {
            entry.openCell();
          }
        }

        if (event.code === "Space") {
          if (
            (entry.status === "write" && this.isClassicInput(entry)) ||
            document.querySelector("#selectCellInput:focus") ||
            document.querySelector("input.mx-input")
          ) {
            return;
          }

          event.preventDefault();
          EventBus.$emit('openModal', {
            modalName:  'RecordModal',
            modalProps: { id: entry.record.id }
          });
        }

        if (event.code === 'Escape') {
          if (entry.status === "write" && this.isClassicInput(entry)) entry.status = 'read';
        }

        if (/^[a-zA-Z0-9]$/.test(event.key) && this.isClassicInput(entry) && entry.isWritable && !event.metaKey) {
          entry.openCell();
        }
      }
    }
  }
};
