<template lang="pug">
base-tree-item(
  :tree-item="treeItem"
  :depth="depth"
  :itemName="itemName"
  :onClick="toggleFolder"
  :updateItemName="updateFolderName"
  :confirmDeleteItem="confirmDeleteFolder"
  :disableDelete="folderHasChildren"
  :iconClass="treeItem.opened ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"
  v-slot="{ setOptionMenuOpened }"
  :nameFallback="nameFallback"
)
  tree-folder-add-menu(
    :tree-item-id="treeItem.id"
    @pop-action:open="setOptionMenuOpened(true)"
    @pop-action:close="setOptionMenuOpened(false)"
  )
</template>

<script>
import { mapState }      from 'vuex';
import TreeItem          from '../../../../models/treeItem';
import BaseTreeItem      from '../BaseTreeItem';
import TreeFolderAddMenu from './TreeFolderAddMenu';
import { TreeItemMixin } from '../../../../mixins/TreeItemMixin';
import { PopupMixin }    from '../../../../mixins/PopupMixin';

export default {
  name: 'tree-folder',
  components: {
    BaseTreeItem,
    TreeFolderAddMenu
  },
  mixins: [TreeItemMixin, PopupMixin],
  computed: {
    ...mapState({
      currentTable: state => state.tableStore.table,
      currentResourceType: state => state.currentResourceType,
    }),
    itemName() {
      return this.treeItem.name;
    },
    folderHasChildren() {
      return TreeItem.query().where('parent_id', this.treeItem.id).exists();
    },
    nameFallback() {
      return this.treeItem?.nameFallback;
    },
  },
  created() {
    if (localStorage.getItem(`folder_opened_${this.treeItem.id}`)) {
      this.treeItem.setFolderState({ opened: true });
    }
  },
  methods: {
    toggleFolder() {
      const opened = !this.treeItem.opened;

      this.treeItem.setFolderState({ opened });
    },
    updateFolderName(event) {
      const newName = event.currentTarget.value;

      TreeItem.api().update({
        id:   this.treeItem.id,
        name: newName
      }).then(() => {
        TreeItem.update({ where: this.treeItem.id, data: { nameFallback: false } });
      });
    },
    confirmDeleteFolder() {
      this.requireConfirmation(this.$t('confirmations.deleteFolder'))
          .then(result => { if (result.isConfirmed) this.deleteFolder() });
    },
    deleteFolder() {
      TreeItem.api().destroy(this.treeItem.id);
    }
  }
}
</script>
