import i18n from "../locales/locales.js";

const containOperators = [
  { operator: 'LIKE',      name: i18n.t('filterOperators.contains') },
  { operator: 'NOT LIKE',  name: i18n.t('filterOperators.doesNotContain') }
];

const presenceOperators = [
  { operator: 'empty',     name: i18n.t('filterOperators.isEmpty') },
  { operator: 'not empty', name: i18n.t('filterOperators.isNotEmpty') }
];

const filterOperators = {
  containOperators,
  presenceOperators,
  referencesOperators: [
    ...containOperators,
    ...presenceOperators,
    { operator: 'reference', name: i18n.t('filterOperators.reference')},
    { operator: 'does not reference', name: i18n.t('filterOperators.doesNotReference')}
  ],
  usersOperators: [
    ...containOperators,
    ...presenceOperators
  ],
  lookupsOperators: [
    ...containOperators,
    ...presenceOperators
  ],
  stringOperators: [
    ...containOperators,
    { operator: '=',         name: i18n.t('filterOperators.isEqualTo') },
    { operator: '!=',        name: i18n.t('filterOperators.isNotEqualTo') },
    ...presenceOperators
  ],
  selectOperators: [
    { operator: '=', name: i18n.t('filterOperators.is') },
    { operator: '!=', name: i18n.t('filterOperators.isNot') },
    ...containOperators,
    ...presenceOperators
  ],
  booleanOperators: [
    { operator: '=', name: i18n.t('filterOperators.is') }
  ],
  numberOperators: [
    { operator: '=',  name: '=' },
    { operator: '!=', name: "≠" },
    { operator: '<',  name: "<" },
    { operator: '>',  name: ">" },
    { operator: '>=', name: "≥" },
    { operator: '<=', name: "≤" },
    ...presenceOperators
  ],
  dateOperators: [
    { operator: 'today',                   name: i18n.t('filterOperators.isToday') },
    { operator: '=',                       name: i18n.t('filterOperators.is') },
    { operator: '!=',                      name: i18n.t('filterOperators.isNot') },
    { operator: 'is on',                   name: i18n.t('filterOperators.isOn') },
    { operator: 'is not on',               name: i18n.t('filterOperators.isNotOn') },
    { operator: '>',                       name: i18n.t('filterOperators.fromExcluded') },
    { operator: '>=',                      name: i18n.t('filterOperators.fromIncluded') },
    { operator: '<',                       name: i18n.t('filterOperators.untilExcluded') },
    { operator: '<=',                      name: i18n.t('filterOperators.untilIncluded') },
    { operator: 'is past',                 name: i18n.t('filterOperators.isPast') },
    { operator: 'is not past',             name: i18n.t('filterOperators.isNotPast') },
    { operator: 'less than days from now', name: i18n.t('filterOperators.isInLessThan') },
    { operator: 'more than days from now', name: i18n.t('filterOperators.isInMoreThan') },
    { operator: 'less than days ago',      name: i18n.t('filterOperators.hasBeenLessThan') },
    { operator: 'more than days ago',      name: i18n.t('filterOperators.hasBeenMoreThan') },
    ...presenceOperators
  ],
  dateTimeRangeOperators: [
    { operator: 'contains',                name: i18n.t('filterOperators.contains') },
    { operator: 'does not contain',        name: i18n.t('filterOperators.doesNotContain') },
    { operator: 'contains today',          name: i18n.t('filterOperators.containsToday') },
    { operator: 'contains now',            name: i18n.t('filterOperators.containsNow') },
    { operator: 'overlaps',                name: i18n.t('filterOperators.overlaps') },
    { operator: 'does not overlap',        name: i18n.t('filterOperators.doesNotoverlap') },
    { operator: 'today',                   name: i18n.t('filterOperators.isToday') },
    { operator: '=',                       name: i18n.t('filterOperators.is') },
    { operator: '!=',                      name: i18n.t('filterOperators.isNot') },
    { operator: 'is on',                   name: i18n.t('filterOperators.isOn') },
    { operator: 'is not on',               name: i18n.t('filterOperators.isNotOn') },
    { operator: '>',                       name: i18n.t('filterOperators.fromExcluded') },
    { operator: '>=',                      name: i18n.t('filterOperators.fromIncluded') },
    { operator: '<',                       name: i18n.t('filterOperators.untilExcluded') },
    { operator: '<=',                      name: i18n.t('filterOperators.untilIncluded') },
    { operator: 'is past',                 name: i18n.t('filterOperators.isPast') },
    { operator: 'is not past',             name: i18n.t('filterOperators.isNotPast') },
    { operator: 'less than days from now', name: i18n.t('filterOperators.isInLessThan') },
    { operator: 'more than days from now', name: i18n.t('filterOperators.isInMoreThan') },
    { operator: 'less than days ago',      name: i18n.t('filterOperators.hasBeenLessThan') },
    { operator: 'more than days ago',      name: i18n.t('filterOperators.hasBeenMoreThan') },
    ...presenceOperators
  ],
}

const operatorsByDataType = {
  references:      filterOperators['referencesOperators'],
  users:           filterOperators['usersOperators'],
  lookups:         filterOperators['lookupsOperators'],
  string:          filterOperators['stringOperators'],
  select:          filterOperators['selectOperators'],
  boolean:         filterOperators['booleanOperators'],
  number:          filterOperators['numberOperators'],
  date:            filterOperators['dateOperators'],
  date_time_range: filterOperators['dateTimeRangeOperators'],
  files:           filterOperators['presenceOperators'],
};

const equalOperatorsByDataType = {
  references: filterOperators['referencesOperators'].filter(operator => operator.operator === 'reference'),
  users: filterOperators['usersOperators'].filter(operator => operator.operator === 'LIKE'),
  lookups: filterOperators['lookupsOperators'].filter(operator => operator.operator === 'LIKE'),
  string: filterOperators['stringOperators'].filter(operator => operator.operator === '='),
  select: filterOperators['selectOperators'].filter(operator => operator.operator === '='),
  boolean: filterOperators['booleanOperators'].filter(operator => operator.operator === '='),
  number: filterOperators['numberOperators'].filter(operator => operator.operator === '='),
  date: filterOperators['dateOperators'].filter(operator => operator.operator === '='),
  date_time_range: filterOperators['dateTimeRangeOperators'].filter(operator => operator.operator === '='),
}

export { operatorsByDataType, equalOperatorsByDataType };
