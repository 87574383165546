<template lang="pug">
base-tree-item(
  :tree-item="treeItem"
  :depth="depth"
  :itemName="itemName"
  :isCurrentItem="isCurrentItem"
  :onClick="fetchDashboard"
  :updateItemName="updateDashboardName"
  :confirmDeleteItem="confirmDeleteDashboard"
  :disableDelete="false"
  iconClass="fad fa-analytics"
  :nameFallback="titleFallback"
)
</template>

<script>
import { mapGetters }    from 'vuex';
import Dashboard         from '../../../../models/dashboard';
import BaseTreeItem      from '../BaseTreeItem';
import { TreeItemMixin } from '../../../../mixins/TreeItemMixin';
import { PopupMixin }    from '../../../../mixins/PopupMixin';

export default {
  name: 'tree-dashboard',
  components: {
    BaseTreeItem
  },
  mixins: [TreeItemMixin, PopupMixin],
  computed: {
    ...mapGetters({
      isCurrentResource: 'isCurrentResource'
    }),
    dashboard() {
      return Dashboard.query().find(this.treeItem.itemId);
    },
    isCurrentItem() {
      return this.isCurrentResource(this.treeItem.itemId, 'dashboard');
    },
    itemName() {
      return this.dashboard ? this.dashboard.title : "";
    },
    titleFallback() {
      return this.dashboard?.title_fallback;
    },
  },
  methods: {
    fetchDashboard() {
      if (this.isCurrentItem) return;

      Dashboard.api().fetchById(this.treeItem.itemId);
      document.querySelector(".main-panel").scrollTo({top: 0, left: 0});
    },
    updateDashboardName() {
      const newName = event.currentTarget.value;

      Dashboard.api().saveTitle(this.treeItem.itemId, newName);
    },
    confirmDeleteDashboard() {
      this.requireConfirmation(this.$t('confirmations.deleteDashboard'))
          .then(result => { if (result.isConfirmed) this.deleteDashboard() });
    },
    deleteDashboard() {
      if (this.isCurrentItem) {
        const itemToFetch     = this.treeItem.findNextItemToFetch();
        var   resourceToFetch = { id: itemToFetch.itemId, type: itemToFetch.type };
      }

      Dashboard.api().destroy(this.treeItem.itemId).
        then(() => {
          if (resourceToFetch) {
            this.$store.dispatch('loadResource', resourceToFetch, { root: true });
          }
        });
    },
  }
}
</script>
