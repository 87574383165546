<script>
import { isEqual }        from 'lodash';
import { Bar }            from 'vue-chartjs';
import { tailwindColors } from '../../services/tailwindColors';
import { EventBus }       from '../../main';

export default {
  extends: Bar,
  props: {
    chartdata: {
      type:     Object,
      required: true,
    },
    noDate: {
      type: Boolean,
      required: true,
    },
    dashboardBlockId: {
      type: Number,
      required: true
    }
  },
  computed: {
    options() {
      return {
        legendCallback: this.legendCallback,
        scales: {
          xAxes: [
            {
              maxBarThickness: 60,
              distribution: 'series',
              offset: true,
              ticks: {
                beginAtZero: true,
                autoSkip: true,
                source: 'data',
                fontSize: 12,
                fontColor:tailwindColors.gray700,
              },
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: this.chartdata.xlabelString,
                padding: 10,
                fontSize: 15,
                fontStyle: 'bold'
              }
            }
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 8,
                padding: -5,
                callback: this.yAxesTickCallback,
                fontColor: "#EC4899",
              },
              scaleLabel: {
                display: true,
                labelString: this.chartdata.ylabelLeftString,
                padding: 6,
                fontSize: 15,
                fontStyle: 'bold'
              }
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 8,
                padding: -5,
                callback: this.yAxesTickCallback,
                fontColor: "#4299E1",
              },
              scaleLabel: {
                display: true,
                labelString: this.chartdata.ylabelRightString,
                padding: 6,
                fontSize: 15,
                fontStyle: 'bold'
              }
            }],
        },
        tooltips: {
          position: 'nearest',
          mode: 'index',
          xPadding: 12,
          yPadding: 12,
          intersect: false,
          backgroundColor: 'rgb(255, 255, 255)',
          bodyFontColor: 'black',
          bodyFontStyle: 'bold',
          bodyFontSize: 12,
          borderColor: tailwindColors.gray500,
          borderWidth: '1',
          titleFontColor: tailwindColors.gray800,
          footerFontColor: tailwindColors.gray900,
          titleFontStyle: 'normal',
          footerFontStyle: 'normal',
          displayColors: false,
          callbacks: {
            label: this.tooltipLabelCallback
          }
        },
        elements: {
          rectangle: {
            backgroundColor: tailwindColors.blue500,
            hoverBackgroundColor: tailwindColors.purple800,
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'center',
          labels: {
            generateLabels: this.generateLabels
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    },
    dataIsPresent() {
      return !!(this.chartdata.datasets[0]?.data?.length || this.chartdata.datasets[1]?.data?.length);
    }
  },
  watch: {
    'chartdata': function (newChartdata, oldChartdata) {
      if (!isEqual(newChartdata, oldChartdata)) {
        this.renderChart(newChartdata, this.options);
        this.$emit('sendLegend', this.generateLegend());
        this.scaleDataAxesToUnifyZeroes();
      }
    }
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
    this.$emit('sendLegend', this.generateLegend());

    EventBus.$on(`toggleSerie${this.dashboardBlockId}`, (item) => {
      const index = item.datasetIndex;
      const meta = this.chartdata.datasets[index]._meta;
      const hidden = meta[Object.keys(meta)[0]].hidden;

      meta[Object.keys(meta)[0]].hidden = hidden ? null : true;

      this.$data._chart.update();
    });
  },
  beforeDestroy() {
    EventBus.$off(`toggleSerie${this.dashboardBlockId}`);
  },
  methods: {
    legendCallback(chart) {
      return chart.legend.legendItems;
    },
    yAxesTickCallback(value) {
      return Intl.NumberFormat(this.$i18n.locale).format(value);
    },
    tooltipLabelCallback(tooltipItem, data) {
      var label = data.datasets[tooltipItem.datasetIndex].tooltipLabel || '';
      var unit  = data.datasets[tooltipItem.datasetIndex].unit

      if (label) {
        label += ' : ' + Intl.NumberFormat(this.$i18n.locale).format(tooltipItem.yLabel) + ' ' + unit;
      }

      return label;
    },
    generateLabels(chart) {
      return chart.data.datasets.map((dataset, i) => {
        return {
          datasetIndex: i,
          text:         dataset.label,
          fillStyle:    dataset.backgroundColor,
          strokeStyle:  dataset.borderColor,
          lineDash:     dataset.borderDash,
          lineWidth:    dataset.borderWidth,
          hidden:       false
        };
      });
    },
    scaleDataAxesToUnifyZeroes() {
      if (this.dataIsPresent) {
        // https://stackoverflow.com/questions/45372997/chartjsalign-zeros-on-chart-with-multi-axes/48650762#48650762
        let axes = this.options.scales.yAxes
        // Determine overall max/min values for each dataset
        this.chartdata.datasets.forEach((line) => {
          let axis = line.yAxisID ? axes.filter(ax => ax.id === line.yAxisID)[0] : axes[0]

          const minValue = Math.min(...line.data, axis.min_value || 0)
          axis.min_value = (Math.floor(minValue / 1000) * 1000)
          const maxValue = Math.max(...line.data, axis.max_value || 0)
          axis.max_value = (Math.ceil(maxValue / 1000) * 1000)
        })
        // Which gives the overall range of each axis
        axes.forEach(axis => {
          axis.range = axis.max_value - axis.min_value
          // Express the min / max values as a fraction of the overall range
          axis.min_ratio = axis.min_value / axis.range
          axis.max_ratio = axis.max_value / axis.range
        })
        // Find the largest of these ratios
        let largest = axes.reduce((a, b) => ({
          min_ratio: Math.min(a.min_ratio, b.min_ratio),
          max_ratio: Math.max(a.max_ratio, b.max_ratio)
        }))
        // Then scale each axis accordingly
        axes.forEach(axis => {
          axis.ticks = axis.ticks || { }
          axis.ticks.min = largest.min_ratio * axis.range
          axis.ticks.max = largest.max_ratio * axis.range
        })

        this.renderChart(this.chartdata, this.options);
      }
    },
  }
}
</script>
