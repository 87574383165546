import { apiClient, startFetch, endFetch } from '../client';

export default {
  create({ viewId, sortOrder}) {
    const saver = startFetch('SAVING');
    return apiClient.post('/api/views/' + viewId + '/sort_orders', { sort_order: sortOrder }).then(endFetch(saver));
  },
  update({ viewId, sortOrder }) {
    const saver = startFetch('SAVING');
    return apiClient.patch('/api/views/' + viewId + '/sort_orders/' + sortOrder.id, { sort_order: sortOrder }).then(endFetch(saver));
  },
  delete({ viewId, sortOrder : { id } }) {
    const saver = startFetch('SAVING');
    return apiClient.delete('/api/views/' + viewId + '/sort_orders/' + id).then(endFetch(saver));
  }
}
