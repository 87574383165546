import { filter, cloneDeep, remove, debounce } from 'lodash';

import { api }               from '../../api/client';
import View                  from '../../models/view';

export const namespaced = true;

export const state = {
  view:          {},
  originalQuery: {},
  appliedQuery:  {}
};

export const mutations = {
  SET_VIEW(state, view) {
    if (state.view.id) { state.view.query = state.originalQuery }

    state.view = view;
  },
  SAVE_ORIGINAL_FILTERS(state) {
    state.originalQuery = cloneDeep(state.view.query);
  },
  SAVE_APPLIED_FILTERS(state, query = null) {
    state.appliedQuery = query || cloneDeep(state.view.query);
  },
  RESTORE_VIEW_FILTERS(state, view) {
    view.query.filters         = state.originalQuery.filters;
    view.query.logicalOperator = state.originalQuery.logicalOperator;
  },
  UPDATE_LAST_RANGE_SIZE(state, [view, typeOptions]) {
    view.typeOptions = typeOptions;
  },
  CREATE_ROW_ORDER(state, { id, visible }) {
    state.view.rowOrder.push({ id, visible });
  }
};

export const actions = {
  pushNewView({ rootState }, { viewData }) {
    const newView = View.build(viewData, rootState.tableStore.table);
    rootState.tableStore.table.views.push(newView);
  },
  fetchView: function({ state, commit, dispatch }, { viewId }) {
    if (state.view.id) {
      commit('RESTORE_VIEW_FILTERS', state.view);
      commit('SAVE_APPLIED_FILTERS', {});
    }

    if (state.currentResourceType !== 'view') {
      dispatch('setCurrentResource', { resourceType: null, resourceId: null }, { root: true });
    }

    return api.views.load({ viewId }).then(response => {
      dispatch('buildViewFromPayload', { viewData: response.data.viewData });
    });
  },
  buildViewFromPayload: function({ commit, getters, dispatch }, { viewData, skipVisibleRecordIds = false }) {
    const view = getters.getViewById(viewData.id);
    view.updateWithViewData(viewData);

    commit('SET_VIEW', view);
    commit('SAVE_ORIGINAL_FILTERS');
    commit('SAVE_APPLIED_FILTERS');

    dispatch('fieldStore/buildWithViewFields', { fieldsData: viewData.fieldsData }, { root: true });
    if (!skipVisibleRecordIds) dispatch('recordStore/setVisibleRecords', {}, { root: true });

    api.views.setAsLastUsed({ viewId: view.id });
    dispatch('setCurrentResource', { resourceType: 'view', resourceId: viewData.id }, { root: true });
  },
  deleteView({ rootState, dispatch, commit }, { viewId }) {
    commit('SET_LOADING', { state : true }, { root: true });
    commit("tableStore/REMOVE_VIEW", viewId, { root: true });
    dispatch('clearViewAndGrid', null, { root: true });
  },
  updateRowOrder: debounce(({ rootState, dispatch }) => {
    const view = rootState.viewStore.view,
          { id: viewId } = view;

    api.rowOrders.forView({ viewId }).then(({ data: sortedRecordsIds }) => {
      view.sortedRecordsIds = sortedRecordsIds;
      dispatch('gridStore/clearSelectedEntry', null, { root: true });
    });
  }, 200, { leading: true }),
};

export const getters = {
  alertedViews(_state, _getters, rootState) {
    return rootState.tableStore.table ? filter(rootState.tableStore.table.views, 'alert') : [];
  },
  currentViews: function(_state, _getters, rootState) {
    return rootState.tableStore.table.views || [];
  },
  getViewById: (_state, getters) => (id) => {
    return getters.allViews.find(view => view.id === id);
  },
  allViews: function(_state, _getters, rootState) {
    return rootState.tableStore.tables.flatMap(table => table.views);
  }
};
