<template lang="pug">
  .table-filters
    template(v-if="kind == 'table_prefilter' && currentUser.superAdmin")
      label
        | Record scope
        i.ml-1.font-weight-light (super admin only)
      v-select.bg-white(
        v-model="prefilterScope"
        :options="recordScopes"
      )
      button.btn.btn-primary.d-block.ml-auto.mt-2(
        @click.stop="updatePrefilterScope"
      ) {{ $t('save') }}
      hr
    RecordFilters(
      :fields="fields"
      :originalQuery="originalQuery"
      @save-query="saveTableFilters"
    )
</template>

<script>
import { api } from '../../api/client';
import RecordFilters from '../RecordFilters';
import Field from '../../models/field';
import User from '../../models/user';
import { mapState } from 'vuex';

export default {
  components: {
    RecordFilters
  },
  props: {
    currentUserInfos: {
      type: Object,
      required: true
    },
    projectUsers: {
      type: Array,
      required: true
    },
    tableId: {
      type: Number,
      required: true
    },
    profileId: {
      type: Number,
      required: true
    },
    filtersProp: {
      type: Array,
      required: true
    },
    fieldsProp: {
      type: Array,
      required: true
    },
    logicalOperatorProp: {
      type: String,
      required: true
    },
    kind: {
      type: String,
      required: true
    },
    recordScopes: {
      type: Array,
      required: false
    },
    currentPrefilterScope: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      fields:        [],
      originalQuery: {},
      prefilterScope: null
    }
  },
  computed: {
    ...mapState(['currentUser'])
  },
  created() {
    this.fields        = Field.build(this.fieldsProp);
    this.originalQuery = {
      logicalOperator: this.logicalOperatorProp,
      filters:         this.filtersProp
    }
    this.$store.commit("SET_CURRENT_USER", User.buildCurrentUser(this.currentUserInfos));
    this.prefilterScope = this.currentPrefilterScope;
  },
  methods: {
    saveTableFilters(queryPayload) {
      this.saving = true;
      api.tables.saveFilters({
        tableId:         this.tableId,
        kind:            this.kind,
        filters:         queryPayload.filters,
        logicalOperator: queryPayload.logicalOperator,
        profileId:       this.profileId
      }).then(() => {
        this.originalQuery = queryPayload;
      });
    },
    updatePrefilterScope() {
      api.tablePermissions.update({
        tableId: this.tableId,
        profileId: this.profileId,
        payload: {
          prefilter_scope: this.prefilterScope
        }
      })
    }
  }
}
</script>
