<template lang="pug">
  .mt-3
    .form-group
      label {{ $t('automations.labels.table') }}
      v-select(
        :searching="true"
        :options="tablesProp"
        label="name"
        :reduce="option => option.id"
        v-model="tableId"
      )

    .form-group
      label {{ $t('automations.labels.findRecordsBasedOn') }}
      v-select(
        :searching="true"
        :options="sourceChoices"
        label="name"
        :reduce="option => option.value"
        v-model="source"
      )


    .form-group(v-if="tableId && source === 'filters' && showFilters")
      label {{ $t('automations.labels.conditions') }}
      RecordFilters(
        :fields="fields"
        :originalQuery="query"
        @save-query="updateAutomationModuleWithFiltersSource"
      )

      .mt-3
        input(
          type="checkbox"
          v-model="filteredByTriggerRecord"
          name="filtered-by-trigger-record"
          id="filtered-by-trigger-record"
          @change="saveTriggerRecordAndCondition"
        )
        label.ml-2 {{ $t('automations.labels.andTriggerRecord') }}

    .form-group(v-if="tableId && source === 'view' && showView")
      label {{ $t('automations.labels.view') }}
      v-select(
        :searching="true"
        :options="views"
        label="name"
        :reduce="option => option.id"
        v-model="viewId"
      )

      .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
        button.btn.btn-primary(
          v-if="viewId"
          @click.stop="updateAutomationModuleWithViewSource"
        ) {{ $t('save') }}
</template>

<script>
import { api } from '../../../../api/client';
import RecordFilters from '../../../RecordFilters';
import Field from '../../../../models/field';
import View from '../../../../models/view';
import i18n from "../../../../locales/locales.js";
import User from "../../../../models/user";

export default {
  components: {
    RecordFilters
  },
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    projectUsers: {
      type: Array,
      required: true,
    },
    tablesProp: {
      type: Array,
      required: true,
    },
    filtersProp: {
      type: Array,
      required: true,
    },
    configParamsProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId: null,
      viewId: null,
      views: [],
      fields: [],
      query: { logicalOperator: null, filters: [] },
      filteredByTriggerRecord: false,
      showFilters: false,
      showView: false,
      source: null,
      sourceChoices: [
        { name: i18n.t('automations.labels.view'), value: 'view' },
        { name: i18n.t('automations.labels.filters'), value: 'filters' }
      ],
    }
  },
  watch: {
    source: function(newSource, oldSource) {
      if(oldSource !== newSource) {
        if (newSource === 'view') {
          this.loadTableViews();
        } else if (newSource === 'filters') {
          this.loadTableSchema();
        }
      }
    }
  },
  created() {
    User.insert({ data: this.projectUsers });
    this.loadTableSchema();
    this.tableId = this.configParamsProp.table_id || null;
    this.source = this.configParamsProp.source || null;
    this.viewId = this.configParamsProp.view_id || null;
    this.filteredByTriggerRecord = this.configParamsProp.filtered_by_trigger_record || false;
    this.query.logicalOperator = this.configParamsProp.logical_operator || 'and';
    this.setQueryFilters();
  },
  methods: {
    loadTableSchema() {
      if (!this.tableId) return;

      api.fields.index({
        tableId: this.tableId,
      }).then((response) => {
        this.showFilters = false;
        setTimeout(() => {
          this.fields = Field.build(response.data);
          this.setQueryFilters();
          this.showFilters = true;
        });
      });
    },
    setQueryFilters() {
      const fieldIds     = this.fields.map(field => field.id);
      this.query.filters = this.filtersProp.filter(filter => fieldIds.includes(filter.field_id));
    },
    loadTableViews() {
      if (!this.tableId) return;

      api.views.index({
        tableId: this.tableId,
      }).then((response) => {
        this.showView = false;
        setTimeout(() => {
          this.views = View.build(response.data);
          this.showView = true;
        });
      });
    },
    updateAutomationModuleWithFiltersSource(queryPayload) {
      this.saving = true;
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        kind: 'find_records',
        configParams: {
          source: 'filters',
          table_id: this.tableId,
          logical_operator: queryPayload.logicalOperator,
          filtered_by_trigger_record: this.filteredByTriggerRecord,
        },
        filters: queryPayload.filters,
      }).then(() => {
        this.query = queryPayload;
      });
    },
    updateAutomationModuleWithViewSource() {
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        kind: 'find_records',
        configParams: { source: 'view', table_id: this.tableId, view_id: this.viewId },
      });
    },
    saveTriggerRecordAndCondition() {
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        kind: 'find_records',
        configParams: {
          source: 'filters',
          table_id: this.tableId,
          logical_operator: this.query.logicalOperator,
          filtered_by_trigger_record: this.filteredByTriggerRecord,
        },
      });
    }
  }
}
</script>
