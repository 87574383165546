import { EventBus } from '../main.js';

export const selectEntryService = (entry) => {
  if (entry.isWritable || ['references', 'files'].includes(entry.field.dataType)) {
    return selectEntryOperation[entry.field.dataType](entry);
  } else {
    return () => { false };
  }
};

const selectEntryOperation = {
  string(entry) {
    return () => {
      entry.status = 'write';
    }
  },
  files(entry) {
    entry.status = 'write';
    return () => {
      EventBus.$emit('openModal', {
        modalName: 'EntryFileManagerModal',
        modalProps: entry
      });
    }
  },
  number(entry) {
    return () => {
      entry.status = 'write';
    }
  },
  date(entry) {
    return () => {
      entry.status = 'write';
    }
  },
  date_time_range(entry) {
    return () => {
      entry.status = 'write';
    }
  },
  select(entry) {
    entry.status = 'write';
    return () => {
      // open select input, see selectInputMixin
    }
  },
  boolean(entry) {
    entry.status = 'write';
    return () => {
      entry.value = !entry.value;
    }
  },
  references(entry) {
    entry.status = 'write';
    return () => {
      if (entry.field.typeOptions.limit_to_one_record && entry.value.length) return;

      const modalName = entry.isWritable ? 'AddReferenceModal' : 'EntryReferencesModal';
      EventBus.$emit('openModal', { modalName, modalProps: { entry } });
    }
  },
  users(entry) {
    entry.status = 'write';
    return () => {
      if (entry.field.typeOptions.limit_to_one_record && entry.value.length) return;

      const modalName = entry.isWritable ? 'AddUserModal' : 'EntryUsersModal';
      EventBus.$emit('openModal', { modalName, modalProps: { entry } });
    }
  },
};
