import { api }                  from '../../api/client';
import { buildTableFromSchema } from '../../services/buildTableFromSchemaService';
import { map }                  from 'lodash';

import Dashboard from '../../models/dashboard';

export const namespaced = true;

export const actions = {
  loadProject: function({ dispatch }, { tableId, viewId, dashboardId, pageId }) {
    api.projects.load({ tableId, viewId, dashboardId, pageId }).then((response) => {
      dispatch('buildTablesFromSchema', { tableSchema: response.data.tableSchema });
      Dashboard.insert({ data: response.data.dashboardSchema });
      if (dashboardId) {
        Dashboard.insertOrUpdate({ data: response.data.dashboardData });
        dispatch('setCurrentResource', { resourceType: 'dashboard', resourceId: dashboardId }, { root: true });
      } else {
        dispatch('tableStore/buildTableFromPayload', { tableData: response.data.tableData }, { root: true });
        dispatch('viewStore/buildViewFromPayload', { viewData: response.data.viewData }, { root: true });
      }
    });
  },
  buildTablesFromSchema: function({ commit, dispatch }, { tableSchema }) {
    const tables = tableSchema.map(buildTableFromSchema);
    commit('tableStore/SET_TABLES', tables, { root: true });
    dispatch('tableStore/connectTablesWithRelationalData', null,{ root: true })
  }
};

export const getters = {
};
