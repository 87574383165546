<template lang="pug">
  .mt-3
    h2 {{ $t('automations.labels.source') }}
    .form-group
      label {{ $t('automations.labels.module_number') }}
      v-select(
        label="name"
        :options="moduleInputPositions"
        v-model="moduleInputPosition"
      )

    h2.mt-4 {{ $t('automations.labels.attributes') }}
    .form-group
      label {{ $t('automations.labels.table') }}
      v-select(
        :searching="true"
        :options="tablesProp"
        label="name"
        :reduce="option => option.id"
        v-model="tableId"
      )

    .form-group(v-if="tableId && showFilters")
      label {{ $t('automations.labels.attributes') }}
      RecordAttributes(
        :fields="fields"
        :originalQuery="query"
        @save-query="updateAutomationModule"
      )
</template>

<script>
import { api } from '../../../../api/client';
import RecordAttributes from '../../../RecordAttributes';
import Field from '../../../../models/field';
import User from "../../../../models/user";

export default {
  components: {
    RecordAttributes
  },
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    automationModulePosition: {
      type: Number,
      required: true,
    },
    projectUsers: {
      type: Array,
      required: true,
    },
    tablesProp: {
      type: Array,
      required: true,
    },
    filtersProp: {
      type: Array,
      required: true,
    },
    configParamsProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId: null,
      fields: [],
      query: { filters: [] },
      showFilters: false,
      moduleInputPosition: null,
      moduleInputPositions: [],
    }
  },
  watch: {
    tableId: function(newTableId, oldTableId) {
      if (oldTableId !== newTableId) this.loadTableSchema();
    }
  },
  created() {
    User.insert({ data: this.projectUsers });
    this.loadTableSchema();
    this.tableId = this.configParamsProp.table_id || null;
    this.setQueryFilters();
    this.moduleInputPosition = this.configParamsProp.module_input_position || 1;
    this.moduleInputPositions = Array.from({ length: this.automationModulePosition - 1 }, (_, i) => i + 1);
  },
  methods: {
    loadTableSchema() {
      if (!this.tableId) return;

      api.fields.index({
        tableId: this.tableId,
      }).then((response) => {
        this.showFilters = false;
        setTimeout(() => {
          const writableFields = this.filterWritableFields(response.data)
          this.fields = Field.build(writableFields);
          this.setQueryFilters();
          this.showFilters = true;
        });
      });
    },
    filterWritableFields(fieldData) {
      return fieldData.filter(field => field.computed === false)
    },
    setQueryFilters() {
      const fieldIds = this.fields.map(field => field.id);
      this.query.filters = this.filtersProp.filter(filter => fieldIds.includes(filter.field_id));
    },
    updateAutomationModule(queryPayload) {
      this.saving = true;
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        kind: 'update_records',
        configParams: {
          module_input_position: this.moduleInputPosition,
          table_id: this.tableId
        },
        filters: queryPayload.filters,
      }).then(() => {
        this.query = queryPayload;
      });
    },
  }
}
</script>
