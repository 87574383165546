<template lang="pug">
  .dashboard-timescale
    template(v-if="settingsAreReady")
      .dashboard-block--header.pb-3
        h2 {{ title }}
        .d-flex
          template(v-if="aggregationCanBeSelected")
            .dashboard-timescale-items--aggregation
              .d-flex.align-items-center
                .toggle-item(v-for="option in [true, false]")
                  input.toggle-selector(
                    v-model="displayAggregation"
                    type="radio"
                    :value="option"
                    :id="'aggregated-' + option"
                    @change="loadChartData"
                  )
                  label(:for="'aggregated-' + option")
                    | {{ $t("dashboardBlocks.timescale.labels.aggregated." + option) }}
      .dashboard-timescale-items
        .sidechart(v-if="itemsWithValue.length > 0")
          .dashboard-timescale-items--data
            .kui-card.dashboard-kpi(
              v-for="data in itemsWithValue"
              :title="data.description"
            )
              .dashboard-kpi--icon.bg-project-secondary-100.text-project-secondary
                i.fad.fa-fw(:class="'fa-' + data.icon")
              .content
                .dashboard-kpi--label {{ data.label }}
                .dashboard-kpi--figure
                  span.dashboard-kpi--value {{ data.value }}
                  span.dashboard-kpi--unit {{ data.unit }}
          .data-container(v-for="annotation in annotations")
            .annotation-data(:id="`annotation${annotation.id}`" :data-values="annotation.data")
        .chart
          .legend
            .row
              .col-4(v-for="item in chartLegendItems")
                .d-flex.align-items-center(@click="toggleSerie(item)" style="cursor: pointer")
                  span.color(:style="{ backgroundColor: (item.fillStyle || '#6B7280'),\
                                       border: `2px dashed ${item.strokeStyle || '#6B7280'}`}"
                  )
                  .label(:style="{ color: item.hidden ? '#4A5568' : '#1A202C'}") {{ item.text }}
          .dashboard-timescale-items--graph
            .chart-container
              .flex-center.h-100(v-if="!isMounted")
                Loader
              component(
                v-else
                :is="`chart-${dashboardBlock.kind_settings.chart_kind}`"
                :chartdata="chartdata"
                :stacked="stacked"
                :dashboardBlockId="dashboardBlockId"
                :annotations="annotations"
                :noDate="noDate"
                @sendLegend="setLegend"
              )

    .flex-center.h-100(v-else)
      div
        h2.text-center.text-warning
          i.fas.fa-exclamation-triangle
        p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}
</template>

<script>
import { api }                 from '../../api/client';
import { mapGetters }          from 'vuex';
import { EventBus }            from '../../main';
import DashboardBlock          from '../../models/dashboardBlock';
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import ChartBar                from '../charts/ChartBar';
import ChartBarDoubleAxis      from '../charts/ChartBarDoubleAxis';
import ChartLine               from '../charts/ChartLine';
import { isEqual, map }        from 'lodash';
import DashboardSharedItem     from '../../models/dashboardSharedItem';

export default {
  mixins: [DashboardBlockMixin],
  components: {
    "chart-bar":             ChartBar,
    "chart-bar-double-axis": ChartBarDoubleAxis,
    "chart-line":            ChartLine,
  },
  data() {
    return {
      chartdata:          {},
      title:              '',
      items:              [],
      chartKind:          '',
      isMounted:          false,
      displayAggregation: true,
      yearSelected:       new Date().getFullYear(),
      currentYear:        new Date().getFullYear(),
      disabledDates:      [],
      csvUrl:             '',
      annotations:        [],
      chartLegendItems:   [],
      stacked:            false,
      noDate:             false
    }
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    settingsAreReady() {
      return !!(this.dashboardBlock &&
              this.dashboardBlock.kind_settings.associations_table_id &&
              this.dashboardBlock.kind_settings.aggregating_field_id &&
              this.dashboardBlock.kind_settings.chart_kind)
    },
    dashboardBlockList() { // we need the list block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.list_id);
    },
    tableForTimescale() {
      if (this.settingsAreReady) {
        return this.getTableById(this.dashboardBlockList.kind_settings.table_id);
      }
    },
    selectedRecordIds() {
      if (this.tableForTimescale) {
        const items = DashboardSharedItem.listItems(this.dashboardBlockList.id, true).
                                          where('metadata', value => value.checked).get();
        return map(items, 'item_id');
      }
    },
    itemsWithValue() {
      return this.items.filter((item) => {
        return item.value != '';
      })
    },
    dataType() {
      if (this.dashboardBlock) {
        return this.dashboardBlock.kind_settings.datatype;
      }
    },
    aggregationCanBeSelected() {
      return ['flex_production'].includes(this.dataType) && this.selectedRecordIds.length > 1;
    }
  },
  created: function() {
    EventBus.$on('dashboard-block-load-chart-data-' + this.dashboardBlockId, this.loadChartData);
  },
  beforeDestroy() {
    EventBus.$off('dashboard-block-load-chart-data-' + this.dashboardBlockId);
  },
  // watch: {
  //   'selectedRecordIds': function (newRecordIds, oldRecordIds) {
  //     if (this.settingsAreReady && !isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) {
  //       this.loadChartData();
  //     }
  //   },
  // },
  mounted() {
    setTimeout(() => {
      this.loadChartData();
    }, 500);
  },
  methods: {
    toggleSerie(item) {
      item.hidden = !item.hidden;
      EventBus.$emit(`toggleSerie${this.dashboardBlockId}`, item);
    },
    setLegend(chartLegendItems) {
      this.chartLegendItems = chartLegendItems;
    },
    loadChartData() {
      if (!this.settingsAreReady) return;
      return api.dashboardBlocks.charts(this.dashboardBlock.id).then((response) => {
          this.isMounted = true;

          if (response.data.annotations) {
            // this.annotations = this.setAnnotations(response.data.annotations);
            this.annotations = response.data.annotations;
          }

          this.stacked = response.data.stacked || false;

          if (response.data.chartdata) {
            this.chartdata = response.data.chartdata;
            this.title     = response.data.title;
            this.items     = response.data.items;
            this.chartKind = response.data.chart_kind;
          }
      });
    },
    // setAnnotations(annotations) {
    //   return annotations.map(annotation => {
    //     annotation["onMouseenter"] = function(event) {
    //     }

    //     return annotation;
    //   });
    // },
    // disableDates(date) {
    //   if (this.disabledDates.length > 1) {
    //     return date < this.disabledDates[0] || date > this.disabledDates[1];
    //   } else {
    //     return date > this.disabledDates[0];
    //   }
    // },
  },
}
</script>
