<template lang="pug">
  section.my-3
    .mb-1.font-weight-bold
      input(
        :id="`antivirus-scan-${editedField.id}`"
        type="checkbox"
        name="antivirusScan"
        v-model="editedField.typeOptions.antivirus_scan"
        :disabled="!canEditField"
      )
      label.ml-2(
        :for="`antivirus-scan-${editedField.id}`"
      ) {{ $t('editOptions.antivirusScan') }}

    label.font-weight-bolder {{ $t('editOptions.acceptedFileFormats') }}
    v-select(
      :input-id="'edit-unit-type' + editedField.id"
      appendToBody
      :searching="true"
      :options="projectFileAcceptedFormats"
      :get-option-label="option => $t('projectFileAcceptedFormats.' + option)"
      :placeholder="$t('projectFileAcceptedFormats.all')"
      v-model="editedField.typeOptions.accepted_format"
      :disabled="!canEditField"
    )
      template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}
</template>

<script>
  import Field from '../../models/field';

  export default {
    props: {
      editedField: {
        type: Object,
        required: true
      },
      canEditField: {
        type: Boolean,
        required: true
      },
    },
    data() {
     return {
        projectFileAcceptedFormats: Field.projectFileAcceptedFormats,
      };
    },
  }
</script>
