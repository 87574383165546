<template functional lang="pug">
  .cell-content.boolean-input-cell
    .boolean-label
      i.checkbox-icon.far(:class="props.entry.value ? 'fa-check text-success' : 'fa-times text-danger times-icon'")
</template>

<script>
  export default {
    functional: true,
    props: {
      entry: Object
    },
    render(h, ctx) {
      const entry = ctx.props.entry
    }
  }
</script>
