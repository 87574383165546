import { apiClient } from '../client';

export default {
  update({ tableId, profileId, payload }) {
    return apiClient.patch(
      `/api/tables/${tableId}/profiles/${profileId}/table_permission`,
      {
        table_permission: payload
      }
    );
  }
}
