import { apiClient, startFetch, endFetch } from '../client';

export default {
  update({ recordId, fieldId, entry }) {
    const saver    = startFetch('SAVING');
    return apiClient.patch(
      'records/' + recordId + '/entry',
      { entry, field_id: fieldId }
    ).then(endFetch(saver))
  },
  addReference({ recordId, fieldId, reference }) {
    const saver = startFetch('SAVING');
    return apiClient.post(
      'records/' + recordId + '/entry/references',
      { reference, field_id: fieldId }
    ).then(endFetch(saver))
  },
  removeReference({ recordId, fieldId, reference }) {
    const saver = startFetch('SAVING');
    return apiClient.delete(
      'records/' + recordId + '/entry/references',
      { params: { foreign_record_id: reference.foreign_record_id, field_id: fieldId } }
    ).then(endFetch(saver))
  },
  addUserForReference({ recordId, fieldId, userId }) {
    const saver = startFetch('SAVING');
    return apiClient.post(
      'records/' + recordId + '/entry/users_for_references',
      { user_id: userId, field_id: fieldId }
    ).then(endFetch(saver))
  },
  removeUserForReference({ recordId, fieldId, userId }) {
    const saver = startFetch('SAVING');
    return apiClient.delete(
      'records/' + recordId + '/entry/users_for_references',
      { params: { user_id: userId, field_id: fieldId } }
    ).then(endFetch(saver))
  }
};
