import { api } from '../api/client';
import store   from '../store/store';

export default class Field {
  constructor(baseField, table) {
    this.id           = baseField.id;
    this.name         = baseField.name;
    this.dataType     = baseField.data_type;
    this.fieldType    = baseField.field_type;
    this.description  = baseField.description || "";
    this.primary      = baseField.primary || false;
    this.accessRight  = baseField.access_right || 'read';
    this.isReadOnly   = this.accessRight === 'read';
    this.isWritable   = !this.isReadOnly;
    this.leftOffset   = 0;
    this.table        = table;
    this.typeOptions  = baseField.type_options || {};
    this.isSystem     = baseField.system || false;
    this.locked       = baseField.locked || false;

    // view_field dependant data
    this.columnNumber = baseField.column_number || 0;
    this.visible      = baseField.visible       || false;
    this.columnWidth  = baseField.column_width  || 150;

    // icon
    this.setIcon();

    // translation
    this.nameFallback         = baseField.name_fallback;
    this.descriptionFallback  = baseField.description_fallback;
  }

  setIcon() {
    this.faIconName = Field.icons[this.fieldType] ? Field.icons[this.fieldType] : Field.icons['default'];
  }

  setAccessRight(accessRight) {
    this.accessRight = accessRight;
    this.isReadOnly  = accessRight === 'read';
    this.isWritable  = !this.isReadOnly;

    if (this.isReadOnly) this.status = 'read';
  }

  defaultEntryValue() {
    return Field.defaultEntryValues[this.dataType]();
  }

  updateWidthViewFieldData(viewFieldData) {
    this.columnWidth  = viewFieldData.column_width || 150;
    this.visible      = viewFieldData.visible      || false;
    this.columnNumber = viewFieldData.column_number;
  }

  get rightOffset() {
    return this.leftOffset + this.columnWidth;
  }

  get dateFormat() {
    if (this.typeOptions.date_format === 'with_time' || this.dataType === 'date_time_range') {
      return 'DD/MM/YYYY HH:mm'
    }
    return 'DD/MM/YYYY'
  }

  get isOwnTableRelationalField() {
    return this === this.table.relationalData.relationalField;
  }

  get isUserAccessible() {
    return !this.isSystem || store.state.currentUser.superAdmin || (store.state.currentUser.admin && this.isWritable);
  }

  get isDisplayable() {
    return this.visible && this.isUserAccessible;
  }

  async findLookupOriginalField(field = this) {
    if (field.dataType === 'lookups') {
      const lookupsFieldId = field.typeOptions.lookups_field_id;
      const lookupsField   = store.getters['fieldStore/getAllFieldById'](lookupsFieldId);

      if (!lookupsField) {
        const response     = await api.fields.lookupsField({ field });
        const foreignField = Field.build(response.data, null);
        return foreignField;
      } else if (lookupsField && lookupsField.dataType === 'lookups' && lookupsField.id !== this.id) {
        return this.findLookupOriginalField(lookupsField);
      }
      return lookupsField;
    }
    return field;
  }

  static build(baseFields, table) {
    if (Array.isArray(baseFields)) {
      return baseFields.map(baseField => new Field(baseField, table));
    } else {
      return new Field(baseFields, table);
    }
  }

  static get defaultEntryValues() {
    return {
      number:          () => null,
      date:            () => null,
      date_time_range: () => { return {} },
      boolean:         () => false,
      string:          () => "",
      select:          () => "",
      files:           () => { return [] },
      references:      () => { return [] },
      users:           () => { return [] },
      lookups:         () => { return [] },
    };
  }

  static async defaultTypeOptions() {
    const { data } = await api.fields.defaultTypeOptions()

    delete this.defaultTypeOptions

    this.defaultTypeOptions = () => data

    return data
  }

  static get projectFileAcceptedFormats() {
    return [
      'excel',
    ]
  }

  static get basicDataTypes() {
    return [
      'string',
      'number',
      'date',
      'boolean',
    ];
  }

  static get formulaDataTypes() {
    return Field.basicDataTypes.concat(['date_time_range', 'users']);
  }

  static get advancedDataTypes() {
    return [
      'select',
      'files',
      'references',
      'lookups',
      'users',
      'date_time_range',
    ];
  }

  static get advancedfieldTypes() {
    return [
      'formula',
      'created_at',
      'last_modified_at',
      'created_by',
      'last_modified_by',
    ];
  }

  static get dataTypes() {
    return Field.basicDataTypes.concat(Field.advancedDataTypes);
  }

  static get fieldTypes() {
    return Field.dataTypes.concat(Field.advancedfieldTypes);
  }

  static get icons() {
    return {
      default:          'text',
      date:             'calendar-day',
      date_time_range:  'calendar-day',
      string:           'text',
      select:           'chevron-circle-down',
      number:           'hashtag',
      boolean:          'check-square',
      files:            'file',
      references:       'ballot-check',
      users:            'user-friends',
      formula:          'function',
      lookups:          'search',
      created_at:       'calendar-plus',
      last_modified_at: 'calendar-edit',
      created_by:       'user-plus',
      last_modified_by: 'user-edit',
    };
  }
}
