import { clamp, first, findIndex, nth } from 'lodash'
import { EventBus }                     from '../../main.js'

export const namespaced = true

export const state = {
  selectedEntryCoords: {}
}

export const mutations = {
  SET_SELECTED_ENTRY(state, { x, y }) {
    state.selectedEntryCoords = { x, y }
  }
}

export const actions = {
  selectEntry({ commit, dispatch, getters }, { x, y }) {
    dispatch('clearSelectedEntry')

    commit('SET_SELECTED_ENTRY', { x, y })
    const newRecordRow = recordRowScrollerElement(state.selectedEntryCoords.y)
    if (newRecordRow) newRecordRow.style.zIndex = '999'
    getters.selectedEntry.focusCell();
    EventBus.$emit('gridNavigated', null, getters.selectedEntry.field, y)
  },
  clearSelectedEntry({ commit, getters }) {
    if (!getters.selectedEntry) return

    document.querySelectorAll('.vue-recycle-scroller__item-view').forEach(itemView => itemView.style.removeProperty('z-index'))
    getters.selectedEntry.status = 'read'
    commit('SET_SELECTED_ENTRY', { entry: {}, coordinates: {} })
  },
  ArrowUp({ dispatch }) {
    dispatch('moveOnAxis', { action: 'moveOnYAxis', direction: 'up' });
  },
  ArrowDown({ dispatch }) {
    dispatch('moveOnAxis', { action: 'moveOnYAxis', direction: 'down' });
  },
  ArrowLeft({ dispatch }) {
    dispatch('moveOnAxis', { action: 'moveOnXAxis', direction: 'left' });
  },
  ArrowRight({ dispatch }) {
    dispatch('moveOnAxis', { action: 'moveOnXAxis', direction: 'right' });
  },
  moveOnAxis({ dispatch, getters }, { action, direction }) {
    if (!getters.selectedEntry) return;
    getters.selectedEntry.status = 'read';

    dispatch(action, direction);

    if (getters.selectedEntry) getters.selectedEntry.focusCell();
  },
  moveOnXAxis({ state, rootGetters }, direction) {
    const xCoordOffset  = direction === 'left' ? -1 : 1;
    const currentColumn = state.selectedEntryCoords.x;
    const fields        = rootGetters['fieldStore/visibleFields'];
    const fieldIndex    = findIndex(fields, field => field.id === currentColumn);
    const neighborField = nth(fields, fieldIndex + xCoordOffset) || first(fields);

    EventBus.$emit('gridNavigated', direction, neighborField);
    state.selectedEntryCoords.x = neighborField.id;
  },
  moveOnYAxis({ state, rootState }, direction) {
    document.querySelectorAll('.vue-recycle-scroller__item-view').forEach(itemView => {
      itemView.style.removeProperty('z-index')
    });

    const yCoordOffset = direction === 'up' ? -1 : 1;
    state.selectedEntryCoords.y = clamp(
      state.selectedEntryCoords.y + yCoordOffset,
      0,
      rootState.tableStore.table.records.length - 1
    );
    EventBus.$emit('gridNavigated', direction, null, state.selectedEntryCoords.y);
    const newRecordRow = recordRowScrollerElement(state.selectedEntryCoords.y);
    if (newRecordRow) newRecordRow.style.zIndex = '999';
  }
}

export const getters = {
  selectedEntry(state, _getters, _rootState, rootGetters) {
    const record = rootGetters['recordStore/visibleRecords'][state.selectedEntryCoords.y];
    if (record) return record.entriesByFieldId[state.selectedEntryCoords.x];
  }
}

function recordRowScrollerElement(recordIndex) {
  const selector = `.grid-row[data-record-index="${recordIndex}"]`
  const element  = document.querySelector(selector)
  if (element) return element.parentElement
}
