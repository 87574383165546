import { requireConfirmation } from '../../components/require_confirmation';
import { alertPopup }          from '../../components/alert_popup';

export const PopupMixin = {
  methods: {
    requireConfirmation(text, confirmBtnText = this.$t('confirm'), cancelBtnText = this.$t('cancel')) {
      return requireConfirmation(text, confirmBtnText, cancelBtnText);
    },
    alertPopup(text, closeBtnText = this.$t('close')) {
      return alertPopup(text, closeBtnText);
    }
  }
}
