import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["legendContainer"];
  static values = {
    data: Object,
    locale: String,
    stacked: Boolean,
  }

  get options() {
    return {
      tooltips: {
        position: "nearest",
        mode: "index",
        xPadding: 12,
        yPadding: 12,
        xAlign: "center",
        intersect: false,
        backgroundColor: "white",
        bodyFontColor: "rgb(26, 32, 44)",
        bodyFontStyle: "bold",
        bodyFontSize: 12,
        bodySpacing: 8,
        borderColor: "#A0AEC0",
        borderWidth: "1",
        titleFontColor: "#2D3748",
        titleFontStyle: "normal",
        footerFontColor: "#1A202C",
        footerFontStyle: "normal",
        displayColors: false,
        callbacks: {
          title: this.tooltipTitleCallback.bind(this),
          label: this.tooltipLabelCallback.bind(this),
          footer: this.tooltipFooterCallback.bind(this),
        }
      },
      scales: {
        xAxes: [{
          stacked: this.stackedValue,
          maxBarThickness: 60,
          distribution: 'series',
          offset: true,
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            source: 'data',
            maxTicksLimit: 21,
            fontSize: 12,
            fontColor: "#4A5568",
            major: {
              enabled: true,
              fontStyle: "bold",
            },
          },
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: this.dataValue.xlabelString,
            padding: 10,
            fontSize: 15,
            fontStyle: 'bold'
          }
        }],
        yAxes: [{
          offset: false,
          stacked: this.stackedValue,
          position: "left",
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 8,
            padding: -5,
            callback: this.yAxesTickCallback,
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: this.dataValue.ylabelString,
            padding: 6,
            fontSize: 15,
            fontStyle: 'bold',
          },
        }],
      },
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
    }
  }

  connect() {
    this.chart = new Chart(
      this.element.querySelector("canvas"),
      {
        type: "bar",
        data: this.dataValue,
        options: this.options,
      }
    );
    this.setChartLegend();
  }

  setChartLegend() {
    let chartLegendItemsHtml = this.chart.data.datasets.map((item, datasetIndex) => {
      let itemHidden = this.chart.getDatasetMeta(datasetIndex).hidden;
      let label = item.label;
      let labelColor = !itemHidden ? "#1A202C" : "#4A5568";
      let borderColor = item.borderColor || "#6B7280";
      let backgroundColor = !itemHidden ? (item.backgroundColor || "#6B7280") : "white";
      let checkColor = (!itemHidden && item.borderDash) ? item.borderColor : "white";
      let borderStyle = item.borderDash ? "dashed" : "solid";
      let opacity = !itemHidden ? "initial" : "0.4";
      let labelTextDecoration = !itemHidden ? "initial" : "line-through";

      return `
        <div class="tw-flex tw-items-center tw-cursor-pointer" data-action="click->bar-chart#toggleSerie" data-bar-chart-dataset-index-param=${datasetIndex}>
          <div class="tw-w-5 tw-h-5 tw-p-0 tw-flex tw-items-center tw-justify-center" style="background-color: ${backgroundColor}; border: 2px ${borderStyle} ${borderColor}; opacity: ${opacity};">
            <i class="fas fa-check" style="color: ${checkColor};"></i>
          </div>
          <div class="tw-text-sm tw-tracking-wide tw-opacity-80 tw-ml-3" style="color: ${labelColor}; text-decoration: ${labelTextDecoration}; opacity: ${opacity};">${label}</div>
        </div>
      `;
    });

    this.legendContainerTarget.innerHTML = chartLegendItemsHtml.join("");
  }

  tooltipTitleCallback(tooltipItems) {
    let label = tooltipItems[0].label;

    if (isNaN(label)) {
      return tooltipItems[0].label;
    } else if (label.getHours() == 0 && label.getMinutes() == 0 && label.getSeconds() == 0) {
      let dateOptions = { month: 'long', day: 'numeric' };

      return label.toLocaleDateString(this.localeValue, dateOptions);
    } else {
      let dateTimeOptions = { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};

      return label.toLocaleDateString(this.localeValue, dateTimeOptions);
    }
  }

  tooltipLabelCallback(tooltipItem, data) {
    var unit = data.datasets[tooltipItem.datasetIndex].unit
    var label = data.datasets[tooltipItem.datasetIndex].label || '';


    if (label) {
      label += ' : ' + Intl.NumberFormat(this.localeValue).format(tooltipItem.yLabel) + ' ' + unit;
    }

    return label;
  }

  toggleSerie(event) {
    const itemDatasetIndex = event.params.datasetIndex;
    let meta = this.chart.getDatasetMeta(itemDatasetIndex);

    // See controller.isDatasetVisible comment
    meta.hidden = meta.hidden === null ? !this.chart.data.datasets[itemDatasetIndex].hidden : null;

    this.chart.update();
    this.setChartLegend();
  }

  tooltipFooterCallback(tooltipItems, data) {
    if (this.stackedValue) {
      const unit  = data.datasets[0].unit;
      return 'Total : ' + tooltipItems.reduce((sum, tooltipItem) => Math.round(sum + parseFloat(tooltipItem.value, 10)), 0) + ' ' + unit;
    }
  }

  yAxesTickCallback(value) {
    return Intl.NumberFormat(this.localeValue).format(value);
  }
}
