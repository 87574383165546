import { mapState } from 'vuex';
import { EventBus } from '../../main';

export const BaseModalMixin = {
  props: {
    modalKey: {
      type: String,
      required: true
    },
    modalProps: {
      type: Object
    },
    modalIndex: {
      type: Number
    }
  },
  computed: {
    ...mapState({
      openedModals: state => state.openedModals,
      mainPanelWidth:  state => state.mainPanelWidth
    }),
    modalName() {
      return this.$options._componentTag;
    },
    sameTypeModals() {
      return this.openedModals.filter((modal) => {
        return modal.modalName === this.modalName;
      });
    },
    siblingModals() {
      return this.openedModals.filter((modal) => {
        return modal.key !== this.modalKey;
      });
    },
    sameModalIndex() {
      return this.sameTypeModals.findIndex((modal) => {
        return modal.key === this.modalKey;
      });
    },
    sameModalOffset() {
      return (this.sameModalIndex * 12);
    },
    sameModalOffsetStyle() {
      if (this.mainPanelWidth > 920) {
        return {
          top:          this.sameModalOffset + 44 + 'px', // 44 is top navbar height. Not needed to be pixel perfect. Just to avoid modal top to be hidden.
          left:         this.sameModalOffset + 'px',
          marginBottom: this.sameModalOffset + 50 + 44 + 'px' // to let margin bottom at scroll end
        }
      }
      return {};
    }
  },
  methods: {
    onModalOpen() {
      // console.log('onModalOpen not implemented for ' + this.modalName);
    },
    beforeModalClose: async function () {
      // this function may be asynchronous (see RecordModal for example);
      // return 'abort' from this function to cancel modalClose and prevent onModalClose function;
    },
    onModalClose() {
      // console.log('onModalClose not implemented for ' + this.modalName);
    },
    closeModal: async function (action = null) {
      if (await this.beforeModalClose(action) !== 'abort') {
        this.$store.commit('REMOVE_OPENED_MODAL', this.modalIndex)
      }
    }
  },
  created() {
    this.onModalOpen(this.modalProps);
    EventBus.$on("close" + this.modalName + this.modalKey, this.closeModal)
  },
  beforeDestroy() {
    this.onModalClose();
  }
};
