<template lang="pug">
base-tree-item(
  :tree-item="treeItem"
  :depth="depth"
  :itemName="itemName"
  :isCurrentItem="isCurrentItem"
  :onClick="fetchTable"
  :updateItemName="updateTableName"
  :confirmDeleteItem="confirmDeleteTable"
  :disableRename="tableIsLocked"
  :disableDelete="tableIsLocked"
  iconClass="fal fa-table"
  :nameFallback="nameFallback"
)
</template>

<script>
import { api }                  from '../../../../api/client';
import { mapGetters, mapState } from 'vuex';
import BaseTreeItem             from '../BaseTreeItem';
import { TreeItemMixin }        from '../../../../mixins/TreeItemMixin';
import { PopupMixin }           from '../../../../mixins/PopupMixin';

export default {
  name: 'tree-table',
  components: {
    BaseTreeItem
  },
  mixins: [TreeItemMixin, PopupMixin],
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
      isCurrentTableStoreGetter: 'tableStore/isCurrentTable',
    }),
    ...mapState({
      tables: state => state.tableStore.tables,
      currentView: state => state.viewStore.view,
      currentResourceType: state => state.currentResourceType,
    }),
    table() {
      return this.getTableById(this.treeItem.itemId);
    },
    tableIsLocked() {
      if (this.table) {
        return this.table.locked;
      }
    },
    nameFallback() {
      return this.table?.nameFallback;
    },
    isCurrentTable() {
      if (this.table) {
        return this.isCurrentTableStoreGetter(this.table.id);
      }
    },
    isCurrentItem() {
      return this.isCurrentTable && this.currentResourceType === 'view';
    },
    itemName() {
      return this.table ? this.table.name : '';
    },
    cantDeleteTable() {
      return this.tables.length < 2;
    }
  },
  methods: {
    fetchTable() {
      if (this.isCurrentItem) return;

      this.$store.dispatch('tableStore/fetchTable', { tableId: this.treeItem.itemId });
    },
    updateTableName(event) {
      const newName = event.currentTarget.value;

      api.tables.updateName({ tableId: this.table.id, tableName: newName })
        .then(response => {
          this.table.slug = response.data.table.slug;
          this.table.name = response.data.table.name;
          this.table.nameFallback = false;

          if(this.isCurrentTable) {
            this.$store.dispatch('updateDocumentState', this.currentView.buildDocumentState());
          }
        });
    },
    confirmDeleteTable() {
      if (this.cantDeleteTable) {
        return this.alertPopup(
          this.$t('controlBarActions.cantDeleteLastTable'),
          this.$t('close')
        )
      };

      this.requireConfirmation(this.$t('confirmations.deleteTable'))
          .then(result => { if (result.isConfirmed) this.deleteTable() });
    },
    deleteTable() {
      const { itemId: tableId } = this.treeItem;

      api.tables.delete({ tableId })
        .then(() => {
          if (this.isCurrentTable) {
              this.$store.dispatch('tableStore/deleteCurrentTable');
          } else {
            this.$store.dispatch('tableStore/deleteTable', { tableId });
          }
        });
    },
  },
}
</script>
