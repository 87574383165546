export default class KuartzSelector {
  constructor(element, options = {}) {
    element.style.display = 'none';

    this.element = element;
    this.options = options;
    this.multiple = this.options.multiple || false;
    this.locale = this.options.locale || 'en';
    this.optionCategory = this.options.category || this.t('options');
    this.choices = this.options.choices || [];

    this.id = element.id || this.generateId();
    this.labelsAndValues = this.labelsAndValues();
    this.element.insertAdjacentHTML('afterend', this.selectorHTML());
    this.container = this.element.nextElementSibling;
    this.element.classList.forEach((className) => this.container.classList.add(className));

    this.insertOptionsHTML();
    this.insertCountContainerHTML();
    this.updateSelectAllStatus();

    this.addGlobalEventListeners();
    this.addEventListenersForMultipleSelector();
    this.addEventListenersForSingleSelector();

    this.onInitialize();
  }

  addGlobalEventListeners() {
    window.addEventListener('click', event => {
      event.stopPropagation();

      if (this.isInContainer(event.target)) return;

      this.toggleOptionDropdownContainer(event)
    });

    window.addEventListener('keydown', event => this.removeSelectedInputOption(event));

    this.inputOptionsWrapperTarget.addEventListener('click', event => this.toggleOptionDropdownContainer(event));

    this.inputSearchTarget.addEventListener('keyup', event => {
      if (["Enter", "ArrowUp", "ArrowDown", "Escape"].includes(event.key)) return;

      this.search(event.target.value);
    });

    this.inputTargets.forEach((inputTarget) => {
      inputTarget.addEventListener('change', (event) => {
        event.stopPropagation();

        this.toggleOption(event.currentTarget);
      });
    });

    document.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  addEventListenersForMultipleSelector() {
    if (!this.multiple) return;

    this.selectAllBtnTarget.addEventListener('click', (event) => this.toggleOptions(event));
  }

  addEventListenersForSingleSelector() {
    if (this.multiple) return;

    this.optionContainerTargets.forEach((optionContainerTarget) => {
      optionContainerTarget.addEventListener('click', (event) => {
        event.stopPropagation();

        this.closeOptionDropdownContainer(event, true)
      });
      optionContainerTarget.addEventListener('mouseover', (event) => {
        event.currentTarget.classList.add('selected');
      });
      optionContainerTarget.addEventListener('mouseleave', (event) => {
        event.currentTarget.classList.remove('selected');
      });
    });
  }

  get optionsContainerTarget() {
    return this.container.querySelector(".ks--options-container");
  }

  get dropdownIconContainerTarget() {
    return this.container.querySelector(".ks--dropdown-icon-container");
  }

  get selectAllBtnTarget() {
    return this.container.querySelector(".ks--select-all-btn");
  }

  get optionDropdownContainerTarget() {
    return this.container.querySelector(".ks--option-dropdown-container");
  }

  get inputOptionsWrapperTarget() {
    return this.container.querySelector(".ks--input-options-wrapper");
  }

  get inputOptionsContainerTarget() {
    return this.container.querySelector(".ks--input-options-container");
  }

  get inputSearchTarget() {
    return this.container.querySelector(".ks--input-search");
  }

  get optionContainerTargets() {
    return this.container.querySelectorAll(".ks--option-container");
  }

  get noResultsFoundTarget() {
    return this.container.querySelector(".ks--no-options-found");
  }

  get inputTargets() {
    return this.container.querySelectorAll(".ks--input");
  }

  get countTarget() {
    return this.container.querySelector(".ks--count");
  }

  get value() {
    return Array.from(this.element.querySelectorAll("[selected='selected']")).map((option) => option.value);
  }

  generateId() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  choiceColor(value) {
    if (!this.choices) return "slate";

    const choice = this.choices.find(choice => choice.value === value)

    return choice && choice.color ? choice.color : "slate";
  }

  t(key) {
    const translations = {
      en: {
        searchInputPlaceholder: 'Search an option',
        noResultsFound: 'No results found',
        selected: 'selected',
        options: 'options',
      },
      fr: {
        searchInputPlaceholder: 'Rechercher une option',
        noResultsFound: 'Aucun résultat trouvé',
        selected: 'sélectionnés',
        options: 'options',
      },
      es: {
        searchInputPlaceholder: 'Buscar una opción',
        noResultsFound: 'No se encontraron resultados',
        selected: 'seleccionados',
        options: 'opciones',
      },
    };

    return translations[this.locale][key];
  }

  labelsAndValues() {
    let labelsAndValues = [];

    this.element.querySelectorAll('option').forEach((option) => {
      if (labelsAndValues.map(labelAndValue => labelAndValue[1]).indexOf(option.value) == -1) {
        labelsAndValues.push([option.innerText, option.value, option.getAttribute("selected") == "selected"]);
      }
    });

    return labelsAndValues;
  }

  selectorHTML() {
    let selectAllContainerHTML = '';
    if (this.multiple) {
      selectAllContainerHTML = `
        <div class="ks--select-all-container">
          <input class="ks--select-all-btn" id="${this.id}-select-all" type="checkbox">
          <label class="ks--select-all-label" for="${this.id}-select-all">${this.optionCategory}</label>
        </div>
      `;
    }

    return `
      <div id="${this.id}--ks" class="ks--wrapper ${this.multiple ? 'multiple' : ''}">
        <div class="ks--input-options-wrapper">
          <div class="ks--input-options-container"></div>
          <div class="ks--icon-container ks--dropdown-icon-container">
            <i class="fas fa-chevron-down"></i>
          </div>
        </div>
        <div class="ks--option-dropdown-container">
          <div class="ks--input-search-container">
            <input class="ks--input-search" placeholder="${this.t('searchInputPlaceholder')}">
          </div>
          <div class="ks--options-wrapper">
            ${selectAllContainerHTML}
            <div class="ks--options-container">
              <div class="ks--no-options-found">
                ${this.t('noResultsFound')}
              </div>
            </div>
          </div>
        </div>
      </div>
    `;
  }

  inputOptionHTML(label, value) {
    if (this.multiple) {
      return `
        <div class="ks--input-option-container" data-value="${value}">
          <div class="ks--input-option-label">${label}</div>
          <div class="ks--input-option-remove-btn">
            <i class="fal fa-times"></i>
          </div>
        </div>
      `
    } else {
      return `
        <div class="ks--input-option-container" data-value="${value}">
          ${this.labelContentHTML(label, value)}
        </div>
      `
    }
  }

  optionHTML(label, value, checked) {
    const checkedHTML = checked ? 'checked="checked"' : '';

    if (this.multiple) {
      return `
        <div class="ks--option-container" data-label="${label}" data-value="${value}">
          <input class="ks--input" id="${this.id}-${value}" type="checkbox" ${checkedHTML}>
          <label class="ks--label" for="${this.id}-${value}">${label}</label>
        </div>
      `
    } else {
      return `
        <div class="ks--option-container" data-label="${label}" data-value="${value}">
          <input class="ks--input" id="${this.id}-${value}" type="radio" name="${this.id}-radio" ${checkedHTML}>
          <label class="ks--label ${label === '' ? 'tw-h-full' : ''}" for="${this.id}-${value}">
            ${this.labelContentHTML(label, value)}
          </label>
        </div>
      `
    }
  }

  labelContentHTML(label, value) {
    if (label === '') return '';

    const choiceColor = this.choiceColor(value);

    return `
      <div class="choice ${choiceColor}-choice">
        ${label}
      </div>
    `;
  }

  countContainerHTML() {
    if (!this.options.count) return '';

    return `
      <div class="ks--count-container">
        <span class="ks--count">${this.checkedCount()}</span>
        /
        <span>${this.optionCount()}</span>
        ${this.t('selected')}
      </div>
    `;
  }

  insertCountContainerHTML() {
    if (!this.options.count) return;

    this.container.insertAdjacentHTML('beforeend', this.countContainerHTML());
  }

  updateCountHTML() {
    if (!this.options.count) return;

    this.countTarget.innerText = this.checkedCount();
  }

  insertOptionsHTML() {
    this.labelsAndValues.forEach((option) => {
      const label = option[0];
      const value = option[1];
      const checkedOption = option[2];

      this.optionsContainerTarget.insertAdjacentHTML('beforeend', this.optionHTML(label, value, checkedOption));

      if (checkedOption) this.addOption(value);
    });
  }

  updateSelectAllStatus() {
    if (!this.selectAllBtnTarget) return;

    if (this.checkedCount() == 0) {
      this.selectAllBtnTarget.indeterminate = false;
      this.selectAllBtnTarget.checked = false;
    } else if (this.optionCount() == this.checkedCount()) {
      this.selectAllBtnTarget.indeterminate = false;
      this.selectAllBtnTarget.checked = true;
    } else {
      this.selectAllBtnTarget.indeterminate = true;
      this.selectAllBtnTarget.checked = false;
    }
  }

  setDropdownIcon() {
    let iconHTML = '';

    if (this.optionDropdownContainerTarget.classList.contains('open')) {
      iconHTML = '<i class="fas fa-chevron-up"></i>';
    } else {
      iconHTML = '<i class="fas fa-chevron-down"></i>';
    }
    this.dropdownIconContainerTarget.innerHTML = iconHTML;
  }

  optionCount() {
    return this.optionsContainerTarget.querySelectorAll('input').length;
  }

  checkedCount() {
    return this.optionsContainerTarget.querySelectorAll('input:checked').length;
  }

  handleKeyDown(event) {
    if (!this.isInBody) return;

    if (event.key === "Enter") {
      event.preventDefault();

      if (this.isDropdownOpen() &&
        this.selectedOption &&
        (this.multiple || !this.selectedOption.querySelector('input').checked)) {
        this.selectedOption.querySelector('input').click();
        this.afterChangeWithEnterKey();
      }

      if (!this.multiple) this.toggleOptionDropdownContainer(event, true);
    } else if (event.key === "Escape") {
      if (!this.isDropdownOpen()) return;

      event.preventDefault();

      this.closeOptionDropdownContainer(event, true);
    } else if (event.key === "Backspace") {
      if (this.multiple || this.isDropdownOpen()) return;

      event.preventDefault();

      this.clearOptions();
    } else if (event.key === "ArrowUp") {
      if (this.multiple || !this.isDropdownOpen()) return;
      event.preventDefault();

      this.selectUpOption();
    } else if (event.key === "ArrowDown") {
      if (this.multiple || !this.isDropdownOpen()) return;

      event.preventDefault();

      this.selectDownOption();
    } else {
      const keysToIgnore = [
        "CapsLock",
        "Control",
        "Alt",
        "Shift",
        "Meta",
        "Escape",
        "Backspace",
        "Space",
        "Enter",
        "ArrowUp",
        "ArrowDown",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ]
      if (this.multiple ||
          this.isDropdownOpen() ||
          keysToIgnore.includes(event.key) ||
          event.ctrlKey ||
          event.altKey ||
          event.metaKey
        ) return;

      this.openOptionDropdownContainer(event, true);
      setTimeout(() => this.inputSearchTarget.focus());
    }
  }

  get isInBody() {
    return this.element.parentElement.parentElement;
  }

  selectUpOption() {
    if (this.optionContainerTargets.length === 0) return;

    const selectedOption = this.selectedOption;

    if (selectedOption) {
      const previousOption = this.previousVisibleOptionContainerTarget;
      this.removeAllSelectedOptions();

      if (previousOption) previousOption.classList.add('selected');
    } else {
      this.lastOption.classList.add('selected');
    }
  }

  selectDownOption() {
    if (this.optionContainerTargets.length === 0) return;

    const selectedOption = this.selectedOption;

    if (selectedOption) {
      const nextOption = this.nextVisibleOptionContainerTarget;
      this.removeAllSelectedOptions();

      if (nextOption) nextOption.classList.add('selected');
    } else {
      this.firstOption.classList.add('selected');
    }
  }

  get visibleOptionContainerTargets() {
    return Array.from(this.optionContainerTargets).filter((optionContainer) => !optionContainer.classList.contains('hidden'));
  }

  get previousVisibleOptionContainerTarget() {
    const currentIndex = this.visibleOptionContainerTargets.indexOf(this.selectedOption);
    const previousIndex = currentIndex - 1;

    return this.visibleOptionContainerTargets[previousIndex];
  }

  get nextVisibleOptionContainerTarget() {
    const currentIndex = this.visibleOptionContainerTargets.indexOf(this.selectedOption);
    const nextIndex = currentIndex + 1;

    return this.visibleOptionContainerTargets[nextIndex];
  }

  get selectedOption() {
    return this.visibleOptionContainerTargets.find((optionContainer) => optionContainer.classList.contains('selected'));
  }

  get lastOption() {
    return this.visibleOptionContainerTargets[this.visibleOptionContainerTargets.length - 1];
  }

  get firstOption() {
    return this.visibleOptionContainerTargets[0];
  }

  removeAllSelectedOptions() {
    this.optionContainerTargets.forEach(optionContainer => {
      optionContainer.classList.remove('selected');
    });
  }

  selectOption(value) {
    const optionContainer = this.container.querySelector(`.ks--option-container[data-value="${value}"]`);

    if (optionContainer) {
      this.removeAllSelectedOptions();
      optionContainer.classList.add('selected');
    }
  }

  toggleOptionDropdownContainer(event, force = false) {
    event.stopPropagation();

    if (this.isDropdownOpen()) {
      this.closeOptionDropdownContainer(event, force);
    } else {
      this.openOptionDropdownContainer(event, force);
    }

    this.setDropdownIcon();
  }

  openOptionDropdownContainer(event, force = false) {
    if (
      !force &&
      (
        !this.inputOptionsWrapperTarget.contains(event.target) ||
        event.target.classList.contains('ks--input-option-label') ||
        event.target.classList.contains('ks--input-option-remove-btn')
      )
    ) return;

    this.optionDropdownContainerTarget.classList.add('open');
    this.optionDropdownContainerTarget.querySelector('input').focus();
    if (!this.multiple) {
      const value = this.value[0] || "";
      this.selectOption(value);
    }
    this.onDropdownOpen();
  }

  isDropdownOpen() {
    return this.optionDropdownContainerTarget.classList.contains('open');
  }

  closeOptionDropdownContainer(event, force = false) {
    if (
      !force &&
      (
        this.optionDropdownContainerTarget.contains(event.target) ||
        event.target.classList.contains('ks--input-option-label') ||
        event.target.classList.contains('ks--input-option-remove-btn')
      )
    ) return;

    this.optionDropdownContainerTarget.classList.remove('open');
    if (this.selectedOption) this.selectedOption.classList.remove('selected');
    this.inputSearchTarget.value = '';
    this.search('');
    this.onDropdownClose();
  }

  removeSelectedOptions() {
    this.inputOptionsContainerTarget.querySelectorAll(`.selected`).forEach((inputOption) => {
      inputOption.classList.remove('selected');
    });
  }

  removeSelectedInputOption(event) {
    if (event.key !== 'Backspace') return;

    const selectedInputOptions = this.inputOptionsContainerTarget.querySelectorAll(`.selected`);

    selectedInputOptions.forEach((inputOption) => {
      this.removeOption(inputOption.dataset.value);
    });

    if (selectedInputOptions.length != 0) this.onChange();
  }

  toggleOptions(event) {
    if (event.currentTarget.checked) {
      this.checkAllOptions();
    } else {
      this.uncheckAllOptions();
    }

    this.onChange();
  }

  checkAllOptions() {
    this.optionsContainerTarget.querySelectorAll('input').forEach((option) => {
      if (option.checked) return;

      this.addOption(option.parentElement.dataset.value);
    });
  }

  uncheckAllOptions() {
    this.optionsContainerTarget.querySelectorAll('input').forEach((option) => {
      if (!option.checked) return;

      this.removeOption(option.parentElement.dataset.value);
    });
  }

  toggleOption(input) {
    const value = input.parentElement.dataset.value;

    if (input.checked) {
      this.multiple ? this.addOption(value) : this.replaceOption(value);
    } else {
      this.removeOption(value);
    }

    this.onChange();
  }

  removeOptionFromInputOption(event) {
    event.stopPropagation();

    const value = event.currentTarget.parentElement.dataset.value;

    this.removeOption(value);

    this.onChange();
  }

  removeOption(value) {
    this.removeInputOptions(value);
    this.uncheckOptions(value);
    this.toggleSelectOptions(value, false);
    this.updateSelectAllStatus();
  }

  addOption(value) {
    this.addInputOptions(value);
    this.checkOptions(value);
    this.toggleSelectOptions(value, true);
    this.updateSelectAllStatus();
  }

  replaceOption(value) {
    this.removeAllInputOptions();
    this.addInputOptions(value);
    this.selectOnlySelectOptions(value);
  }

  clearOptions() {
    this.replaceOption("");
    this.onChange();
  }

  toggleSelectOptions(value, selected) {
    this.element.querySelectorAll(`option[value="${value}"]`).forEach((option) => {
      if (selected) {
        option.setAttribute('selected', 'selected');
      } else {
        option.removeAttribute('selected');
      }
    });
  }

  addInputOptions(value) {
    this.optionsContainerTarget.querySelectorAll(`[data-value="${value}"]`).forEach((optionContainer) => {
      const label = optionContainer.dataset.label;
      const value = optionContainer.dataset.value;

      this.inputOptionsContainerTarget.insertAdjacentHTML('beforeend', this.inputOptionHTML(label, value));

      if (this.multiple) {
        this.inputOptionsContainerTarget.lastElementChild.querySelector('.ks--input-option-label').addEventListener('click', (event) => {
          this.selectInputOption(event);
        });
        this.inputOptionsContainerTarget.lastElementChild.querySelector('.ks--input-option-remove-btn').addEventListener('click', (event) => {
          this.removeOptionFromInputOption(event);
        });
      }
    });
  }

  removeAllInputOptions() {
    this.inputOptionsContainerTarget.innerHTML = '';
  }

  unselectAllSelectOptions() {
    this.element.querySelectorAll('option').forEach((option) => {
      option.removeAttribute('selected');
    });
  }

  selectOnlySelectOptions(value = undefined) {
    this.element.querySelectorAll('option').forEach((option) => {
      if (value && option.value == value) {
        option.setAttribute('selected', 'selected');
      } else {
        option.removeAttribute('selected');
      }
    });
  }

  removeInputOptions(value) {
    this.inputOptionsContainerTarget.querySelectorAll(`[data-value="${value}"]`).forEach((option) => {
      option.remove();
    });
  }

  checkOptions(value) {
    this.optionsContainerTarget.querySelectorAll(`[data-value="${value}"] input`).forEach((option) => {
      option.checked = true;
    });
  }

  uncheckOptions(value) {
    this.optionsContainerTarget.querySelectorAll(`[data-value="${value}"] input`).forEach((option) => {
      option.checked = false;
    });
  }

  search(value) {
    let foundOptionCount = 0;

    this.optionContainerTargets.forEach(optionContainer => {
      if(optionContainer.dataset.label.toLowerCase().includes(value.toLowerCase())) {
        optionContainer.classList.remove('hidden');
        foundOptionCount++;
      } else {
        optionContainer.classList.add('hidden');
      }
    });

    if (foundOptionCount === 0) {
      this.noResultsFoundTarget.classList.add('show');
    } else {
      this.noResultsFoundTarget.classList.remove('show');
      this.removeAllSelectedOptions();
      this.firstOption.classList.add('selected');
    }
  }

  selectInputOption(event) {
    this.removeSelectedOptions();
    this.addSelectedOptions(event.currentTarget.parentElement.dataset.value);
  }

  addSelectedOptions(value) {
    this.inputOptionsContainerTarget.querySelectorAll(`[data-value="${value}"]`).forEach((option) => {
      option.classList.add('selected');
    });
  }

  isInContainer(element) {
    while (element) {
      if (element === this.container) {
        return true;
      } else if (element === document.body) {
        return false;
      }

      element = element.parentElement
    }
  }

  onChange() {
    if (this.options.count) this.updateCountHTML();
    if (this.options.onChange) this.options.onChange({ detail: this.value });
  }

  onInitialize() {
    if (this.options.onInitialize) this.options.onInitialize({ detail: this.value });
  }

  onDropdownOpen() {
    if (this.options.onDropdownOpen) this.options.onDropdownOpen();
  }

  onDropdownClose() {
    if (this.options.onDropdownClose) this.options.onDropdownClose();
  }

  afterChangeWithEnterKey() {
    if (this.options.afterChangeWithEnterKey) this.options.afterChangeWithEnterKey();
  }
}
