import moment               from 'moment';
import { UpdateEntryMixin } from '../UpdateEntryMixin';

export const DateTimeRangePickerMixin = {
  mixins: [UpdateEntryMixin],
  props: {
    entry: {
      type:     Object,
      required: true
    }
  },
  data() {
    return {
      tmpDate: null
    }
  },
  mounted() {
    this.setTmpDate();
  },
  watch: {
    "entry.value": function(_newValue, _oldValue) {
      this.setTmpDate();
    }
  },
  methods: {
    handleChange() {
      const formattedEntryStartDateTimeBeforeChange = this.entry.value ? moment(this.entry.value.start_date_time).toISOString() : null;
      const formattedEntryEndDateTimeBeforeChange   = this.entry.value ? moment(this.entry.value.end_date_time).toISOString()   : null;
      const startDateTime                           = moment(this.tmpDate[0]).toISOString();
      const endDateTime                             = moment(this.tmpDate[1]).toISOString();

      if (formattedEntryStartDateTimeBeforeChange === startDateTime && formattedEntryEndDateTimeBeforeChange === endDateTime) return;

      if (startDateTime && endDateTime) {
        this.entry.value = { start_date_time: startDateTime, end_date_time: endDateTime };
      } else {
        this.entry.value = {};
      }

      this.updateEntry(this.entry);
    },
    setTmpDate() {
      if(!(this.entry.value.start_date_time && this.entry.value.end_date_time)) {
        this.tmpDate = [];
      } else {
        this.tmpDate = [moment(this.entry.value.start_date_time).toDate(), moment(this.entry.value.end_date_time).toDate()];
      }
    }
  }
};
