<script>
export default {
  functional: true,
  props: {
    entry: {
      type:     Object,
      required: true
    }
  },
  render(h, { props, parent }) {
    let inputContent = props.entry.value;
    let unitSpan     = null;
    const unitSymbol = parent.$t('units.' + props.entry.field.typeOptions.unit_type + '.symbol');

    if (props.entry.field.typeOptions.unit_type && props.entry.value !== null) {
      unitSpan = h('span', ` ${unitSymbol}`);
    } else if (props.entry.field.typeOptions.unit_type && props.entry.value === null) {
      unitSpan = h('span', { class: 'entry-input-number-read-unit' }, ` ${unitSymbol}`);
    }

    return h('p', {
      class: 'form-control bg-light entry-input-number-read'
    }, [inputContent, unitSpan]);
  }
}
</script>
