export const dateTimeFormats = {
  'fr': {
    long: {
      year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'
    },
    monthYear: {
      year: 'numeric', month: 'long'
    },
    monthYearSmall: {
      year: 'numeric', month: 'short'
    },
    monthSmall: {
      year: 'numeric', month: 'numeric'
    }
  }
}
