<template lang="pug">
  .dashboard-list(:class=" addMarginRight ? 'mr-5' : 'mr-0'")
    template(v-if="settingsAreReady")
      .dashboard-block--header.pb-3
        h2.pr-3 {{ tableForList.name }} - {{ viewForList.name }}
        .d-flex.align-items-baseline(v-if="!focusedItem")
          template(v-if="!sortsAndFiltersDisabled && viewForList.isLoaded")
            DashboardBlockListSorts.mr-2(
              :dashboardBlock="dashboardBlock"
              :table="tableForList"
              :appliedSorts="sortOrdersFromSorts"
              :originalSorts="viewForList.sortOrders"
              @items-sortered="itemsSortered"
            )
            DashboardBlockListFilters.mr-2(
              :dashboardBlock="dashboardBlock"
              :table="tableForList"
              @items-filtered="filterItems"
            )

          template(v-if="!itemSelectDisabled")
            .dashboard-list-items--select-all(
                :class="allItemsSelected ? 'text-secondary' : 'text-primary'"
                @click="toggleCheckAllItems"
            )
              i.far(
                :class="allItemsSelected ? 'fa-eye-slash' : 'fa-eye'"
              )

      .dashboard-block--content.flex-grow-1
        .dashboard-list-items(v-if="!focusedItem")
          .container-fluid.w-100
            .row.dashboard-list-item(
              v-for="item in items"
              :key="item.recordId"
              :class="{ unchecked: !itemSelectDisabled && !itemsCheckedByRecordId[item.recordId] }"
            )
              .dashboard-list-item--col.dashboard-list-item--col-icon(v-if="iconFieldId")
                .dashboard-list-item--icon(v-if="item.icon")
                  i.fas.fa-fw(
                    :class="'fa-' + item.icon"
                    :style="{ color: item.color }"
                  )
              .dashboard-list-item--col.col
                .dashboard-list-item--title(
                  data-toggle="tooltip"
                  :title="item.title"
                ) {{ item.title }}
              .dashboard-list-item--col.col(v-if="item.description")
                .dashboard-list-item--description(
                  data-toggle="tooltip"
                  :title="item.description"
                ) {{ item.description }}
              .dashboard-list-item--col.col(
                v-for="(entryInfos) in item.listAdditionalFields"
              )
                .dashboard-list-item--description(
                  data-toggle="tooltip"
                  :title="entryInfos.stringifiedValue"
                )
                  template(v-if="entryInfos.stringifiedValue && entryInfos.style === 'label'")
                    .label.bg-red-200.text-red-700
                      | {{ entryInfos.stringifiedValue }}
                  template(v-else)
                    | {{ entryInfos.stringifiedValue }}

              .dashboard-list-item--col.dashboard-list-item--side-menu.justify-content-end.flex-shrink-0(
                v-if="!detailsDisabled || !itemSelectDisabled"
              )
                .dashboard-list-item--aside
                  .dashboard-list-item--link(
                    v-if="!detailsDisabled"
                    @click="openRecordDetails(item.recordId)"
                  )
                    i.fas.fa-fw.fa-search.text-secondary
                .dashboard-list-item--checked(
                  v-if="!itemSelectDisabled"
                  @click="toggleItemCheckedValue(item.recordId)"
                )
                  span(
                    :class="itemsCheckedByRecordId[item.recordId] ? 'text-secondary' : 'text-primary'"
                  )
                    i(:class="itemsCheckedByRecordId[item.recordId] ? 'fas fa-eye' : 'far fa-eye-slash'")

        .dashboard-list-item-details(
          v-if="!detailsDisabled"
          :class="{ opened: focusedItem, 'mr-5': addMarginRight }"
        )
          .dashboard-list-item-details--panel(v-if="focusedItem")
            .dashboard-list-item-content
              .dashboard-list-item--visuals(v-if="focusedItemImgSrc")
                .dashboard-list-item--image(
                  :style="`background-image: url(${focusedItemImgSrc})`"
                )
              .dashboard-list-item--content.pr-3.flex-grow-1
                .d-flex.align-items-start.mb-2
                  .dashboard-list-item--icon(v-if="focusedItem.icon")
                    i.fas.fa-fw(
                      :class="'fa-' + focusedItem.icon"
                      :style="{ color: focusedItem.color }"
                    )
                  div
                    .subtitle.dashboard-list-item--title {{ focusedItem.title }}
                    .dashboard-list-item--details-subtitle {{ focusedItem.description }}
                .dashboard-list-item-details-fields
                  .mb-2(
                    v-for="(value, fieldId) in focusedItem.details"
                  )
                    .field-name {{ additionalFieldsById[fieldId].name }}
                    .entry-value {{ value }}


            .dashboard-list-item--actions
              .back-to-list(@click="focusedRecordId = null")
                i.fal.fa-chevron-left.nav.mr-2
                .nav
                  | {{ $t('dashboardBlocks.backToList') }}

              .dashboard-list-item--display-details
                button.d-block.w-100.btn.btn-primary.mb-0.text-nowrap(
                  @click="openRecordModal(focusedItem.recordId)"
                )
                  i.fad.fa-expand-alt.mr-2
                  | {{ $t('dashboardBlocks.list.displayAllDetails') }}

                button.d-block.w-100.btn.btn-secondary.text-nowrap.mt-2.mb-0(
                  v-if="canShowAssociationsLink"
                  @click="openRecordInFilteredTable(focusedItem.recordId)"
                )
                  i.fad.fa-expand-alt.mr-2
                  | {{ associationsTable.name }}

    .flex-center.h-100(v-else)
      div
        h2.text-center.text-warning
          i.fas.fa-exclamation-triangle
        p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}

</template>
<script>
import {
  isEqual,
  every,
  find,
  intersection,
  capitalize
} from 'lodash';
import { mapGetters } from 'vuex';
import { api } from '../../api/client';
import { EventBus } from '../../main';
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import moment from 'moment';
import DashboardSharedItem from '../../models/dashboardSharedItem';
import DashboardBlock from '../../models/dashboardBlock';
import DashboardBlockListFilters from './DashboardBlockListFilters.vue';
import DashboardBlockListSorts from './DashboardBlockListSorts.vue';

export default {
  components: {
    DashboardBlockListSorts,
    DashboardBlockListFilters,
  },
  mixins: [DashboardBlockMixin],
  data() {
    return {
      priorityColor: [
        '#C53030',
        '#B7791F',
        '#2F855A'
      ],
      focusedRecordId: null,
      mandatorySettings: [
        'table_id',
        'view_id',
        'title_field_id',
      ],
      visibleRecordIdsFromFilters: null,
      sortOrdersFromSorts:         [],
      sortedRecordsIdsFromSorts: null,
      visibleRecordsFilteredFromParentTable: null
    }
  },
  computed: {
    ...mapGetters({
      getFieldByTableIdThenId: 'fieldStore/getFieldByTableIdThenId',
      getViewById:             'viewStore/getViewById',
      getTableById:            'tableStore/getTableById',
      getRecordById:           'recordStore/getRecordById',
      getFieldById:            'fieldStore/getAllFieldById',
    }),
    dashboardBlockItems() {
      return this.dashboardBlockItemsQuery().get();
    },
    itemsCheckedByRecordId() {
      const items = this.dashboardBlockItems;

      return items.reduce((records_by_id, item) => {
        records_by_id[item.item_id] = item.metadata.checked
        return records_by_id;
      }, {});
    },
    allItemsSelected() {
      return !this.dashboardBlockItemsQuery().where(item => !item.metadata.checked).exists();
    },
    focusedItem() {
      const record = this.getRecordById(this.focusedRecordId);
      const { details_additional_field_ids } = this.dashboardBlock.kind_settings;

      if (record && details_additional_field_ids) {
        return details_additional_field_ids.reduce((item, fieldId) => {
          const entry = record.entriesByFieldId[fieldId];

          Object.assign(item.details, { [fieldId]: entry && entry.toString() });

          return item;
        }, { details: {}, ...this.buildItem(record) });
      }
    },
    focusedItemImgSrc() {
      const record  = this.getRecordById(this.focusedRecordId);
      const fieldId = this.dashboardBlock.kind_settings['image_field_id'];
      const entry   = record.entriesByFieldId[fieldId];

      if (entry && entry.value.length) {
        return `/project_files/${entry.value[0].id}`;
      }

      return "";
    },
    visibleRecordIds() {
      const visibleRecordIds = this.dashboardBlockParentList && this.parentListReferenceFieldId ?
        this.visibleRecordsFilteredFromParentTable :
        this.viewForList.visibleRecordIds

      if (this.visibleRecordIdsFromFilters && !this.dashboardBlock.kind_settings.sorts_and_filters_disabled) {
        return intersection(visibleRecordIds, this.visibleRecordIdsFromFilters);
      }

      return visibleRecordIds;
    },
    sortOrders() {
      return this.sortOrdersFromSorts || this.viewForList.sortOrders;
    },
    sortedRecordsIds() {
      return this.sortedRecordsIdsFromSorts || this.viewForList.sortedRecordsIds;
    },
    orderedAndVisibleRecords() {
      if (this.sortedRecordsIds) {
        const visibleRecordIds = new Set(this.visibleRecordIds);
        return this.sortedRecordsIds.reduce((records, id) => {
          if (visibleRecordIds.has(id)) records.push(this.tableForList.recordsById[id]);
          return records
        }, []);
      }
      return [];
    },
    items() {
      if (this.tableForList) return this.orderedAndVisibleRecords.map(this.buildItem);
      return [];
    },
    additionalFieldsById() {
      const { details_additional_field_ids = [] } = this.dashboardBlock.kind_settings;

      return details_additional_field_ids.reduce((fieldsById, fieldId) => {
        const field = this.getFieldByTableIdThenId(this.tableForList.id, fieldId) || {};

        return Object.assign(fieldsById, { [fieldId]: field });
      }, {});
    },
    tableForList() {
      return this.getTableById(this.dashboardBlock.kind_settings.table_id);
    },
    viewForList() {
      return this.tableForList.views.find(view => view.id === this.dashboardBlock.kind_settings.view_id);
    },
    settingsAreReady() {
      return this.dashboardBlock &&
        this.tableForList &&
        this.viewForList &&
        every(this.mandatorySettings.map(setting => {
          return this.dashboardBlock.kind_settings[setting];
        }));
    },
    associationsTable() {
      if (this.dashboardBlock.kind_settings.associations_table_id) {
        return this.getTableById(this.dashboardBlock.kind_settings.associations_table_id);
      }
    },
    canShowAssociationsLink() {
      return !!(this.associationsTable &&
                this.dashboardBlock.kind_settings.associations_view_id &&
                this.dashboardBlock.kind_settings.associations_reference_field_id);
    },
    addMarginRight() {
      return this.dashboardBlock.kind_settings.addMargin === 'yes';
    },
    dashboardBlockParentList() {
      return DashboardBlock.find(this.dashboardBlock.kind_settings.parent_list);
    },
    parentListReferenceFieldId(){
      return this.dashboardBlock.kind_settings.parent_list_reference_field_id
    },
    selectedRecordIdsFromParentList() {
      if (!this.dashboardBlockParentList) return;

      const items = DashboardSharedItem.listItems(this.dashboardBlockParentList.id, true).
                                        where('metadata', value => value.checked).get();
      return items.map((item) => item.item_id);
    },
    sortsAndFiltersDisabled() {
      return this.dashboardBlock.kind_settings.sorts_and_filters_disabled;
    },
    itemSelectDisabled() {
      return this.dashboardBlock.kind_settings.item_select_disabled;
    },
    detailsDisabled() {
      return this.dashboardBlock.kind_settings.details_disabled;
    },
    iconFieldId() {
      return this.dashboardBlock.kind_settings.icon_field_id;
    }
  },
  watch: {
    'selectedRecordIdsFromParentList': function (newRecordIds, oldRecordIds) {
      if (!this.settingsAreReady || isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) return;

      this.itemsFilteredByParentList();
    },
  },
  created: async function() {
    this.loadDashboardBlock();
    EventBus.$on('dashboard-block-updated-' + this.dashboardBlockId, this.handleSettingsUpdate);
    EventBus.$on('open-record-details-' + this.dashboardBlockId, this.openRecordDetails);
  },
  beforeDestroy() {
    EventBus.$off('dashboard-block-updated-' + this.dashboardBlockId);
    EventBus.$off('open-record-details-' + this.dashboardBlockId);
  },
  methods: {
    async loadDashboardBlock() {
      if (this.settingsAreReady) {
        await this.loadTable();
        await this.loadView();
        if (this.dashboardBlockParentList) {
          await this.itemsFilteredByParentList();
        }
        this.buildDashboardSharedItems();
      }
    },
    async handleSettingsUpdate(event) {
      if (event.type === 'tableChanged') await this.loadTable();
      if (event.type === 'viewChanged') await this.loadView()
      this.buildDashboardSharedItems();
    },
    buildItem(record) {
      record.buildEntries();
      const listAdditionalFieldIds = this.dashboardBlock.kind_settings.list_additional_field_ids;
      const listAdditionalFieldStyles = this.dashboardBlock.kind_settings.list_additional_field_styles;

      let recordItem = ['title', 'description', 'icon', 'color', 'image'].reduce((item, field) => {
        return Object.assign(item, this.itemPairFor(record, field));
      }, { recordId: record.id }); // => { recordId: 1, title: 'Hello', description: 'Stuff' ... }

      if (!listAdditionalFieldIds) return recordItem;

      recordItem.listAdditionalFields = listAdditionalFieldIds.map(fieldId => {
        const entry = record.entriesByFieldId[fieldId];

        return {
          stringifiedValue: listAdditionalFieldStyles[fieldId] === 'relative' ? this.relativeDateTimeFormat(entry, fieldId) : entry && entry.toString(),
          entry: entry,
          fieldId: fieldId,
          style: listAdditionalFieldStyles[fieldId]
        }
      });

      return recordItem;
    },
    itemPairFor(record, field) {
      const fieldId = this.dashboardBlock.kind_settings[`${field}_field_id`];
      const entry   = record.entriesByFieldId[fieldId];
      const value   = entry ? entry.toString() : "";

      return { [field]: value };
    },
    loadTable() {
      if (this.tableForList && this.tableForList.slug) return; // do not load again if table is present
      return api.tables.load({
        tableId:      this.dashboardBlock.kind_settings.table_id,
        viewId:       this.dashboardBlock.kind_settings.view_id,
        withViewData: true,
        skipLoader:   true,
      }).then(response => {
          this.$store.dispatch('tableStore/buildTableFromPayload', {
            tableData:       response.data.tableData,
            setAsShownTable: false
          });
          this.viewForList.updateWithViewData(response.data.viewData);
        });
    },
    loadView() {
      if (this.viewForList && this.viewForList.slug) return;

      return api.views.load({ viewId: this.dashboardBlock.kind_settings.view_id, skipLoader: true })
        .then((response) => this.viewForList.updateWithViewData(response.data.viewData));
    },
    fetchView() {
      this.$store.dispatch("viewStore/fetchView", { viewId: this.viewForList.id });
    },
    deleteAllDashboardSharedItems() {
      DashboardSharedItem.delete(item => item.dashboard_block_id === this.dashboardBlockId);
    },
    buildDashboardSharedItems(checked = true) {
      const data = this.orderedAndVisibleRecords.map((record) => {
        return {
          dashboard_block_id: this.dashboardBlockId,
          item_id:            record.id,
          kind:               'list_item',
          metadata:           { checked }
        }
      });
      this.deleteAllDashboardSharedItems();
      DashboardSharedItem.insert({ data });
    },
    setItemCheckedValue(recordId, checked) {
      const data = { metadata: { checked } }
      DashboardSharedItem.update({ where: [this.dashboardBlockId, recordId], data });
    },
    toggleItemCheckedValue(recordId) {
      const item = DashboardSharedItem.find([this.dashboardBlockId, recordId]);
      this.setItemCheckedValue(recordId, !item.metadata.checked);
    },
    toggleCheckAllItems() {
      const newValue = !this.allItemsSelected;
      this.buildDashboardSharedItems(newValue);
    },
    openRecordDetails(recordIdForDetails) {
      this.buildDashboardSharedItems(false);
      this.setItemCheckedValue(recordIdForDetails, true);
      this.focusedRecordId = recordIdForDetails;
    },
    openRecordModal(recordId) {
      EventBus.$emit('openModal', {
        modalName: 'RecordModal',
        modalProps: {
          id: recordId,
          tableId: this.dashboardBlock.kind_settings.table_id,
          viewId: this.dashboardBlock.kind_settings.view_id,
        }
      })
    },
    openRecordInFilteredTable(recordId) {
      return this.$store.dispatch('tableStore/fetchTable', {
        tableId:              this.dashboardBlock.kind_settings.associations_table_id,
        skipVisibleRecordIds: true
      }).then(() => {
          const query = {
            logicalOperator: 'and',
            filters: [{
              field_id: this.dashboardBlock.kind_settings.associations_reference_field_id,
              operator: 'reference',
              value:    recordId
            }]
          };
          api.records.filtered({ query, tableId: this.dashboardBlock.kind_settings.associations_table_id }).then(response => {
            this.$store.dispatch('recordStore/setVisibleRecords', { visibleRecordIds: response.data.visible_record_ids });
            this.$store.commit('viewStore/SAVE_APPLIED_FILTERS', query);
          });
        });
    },
    dashboardBlockItemsQuery() {
      // second argument specify to return only query and not result, see models/dashboardSharedItem.js
      return DashboardSharedItem.listItems(this.dashboardBlockId, true);
    },
    filterItems(visibleRecordIds) {
      this.visibleRecordIdsFromFilters = visibleRecordIds;
      this.buildDashboardSharedItems();
    },
    itemsSortered({ sortOrders, sortedRecordsIds }) {
      this.sortOrdersFromSorts = sortOrders;
      this.sortedRecordsIdsFromSorts = sortedRecordsIds;
    },
    itemsFilteredByParentList() {
      const filters = this.selectedRecordIdsFromParentList.map((record_id) => {
        return {
          field_id: this.dashboardBlock.kind_settings.parent_list_reference_field_id,
          operator: 'reference',
          value:    record_id
        }
      })
      const query = { logicalOperator: 'or', filters: filters };

      api.records.filtered({ query, tableId: this.dashboardBlock.kind_settings.table_id }).then(response => {
        this.visibleRecordsFilteredFromParentTable = response.data.visible_record_ids;
        this.buildDashboardSharedItems();
      })
    },
    relativeDateTimeFormat(entry, fieldId) {
      const field = this.getFieldById(fieldId);

      if (!(entry &&
        (field.dataType === 'date' && entry.value ||
        field.dataType === 'date_time_range' && entry.value.start_date_time && entry.value.end_date_time))) return;

      const now = new Date();
      let concernedDateTime;

      if (field.dataType === 'date_time_range') {
        const startDateTime = new Date(entry.value.start_date_time);
        const endDateTime = new Date(entry.value.end_date_time);

        if (startDateTime <= now && now <= endDateTime) return "En cours";

        if (now < startDateTime) {
          concernedDateTime = startDateTime
        } else if (endDateTime < now) {
          concernedDateTime = endDateTime;
        }
      } else if (field.dataType === 'date') {
        concernedDateTime = entry.value;
      }

      moment.locale('fr');
      const relativeDateTime = moment(concernedDateTime).fromNow();

      return capitalize(relativeDateTime);
    },
  }
}
</script>
