import { apiClient } from '../vue/api/client.js';
import { refreshNotificationBadgeDisplay, fetchNotifications } from './base_notifications'
import i18n          from "../vue/locales/locales.js";
import moment        from "moment";

moment.locale(i18n.locale);

const stringifiedDate = (date) => {
  return `${moment(date).format("DD MMMM")} ${i18n.t('at')} ${moment(date).format("HH:mm")}`;
}

const moreNotificationsBtnHTML = () => {
  return `<div class="show-more-btn js-show-more-btn js-show-more-notifications-btn">${i18n.t('notifications.showMore')}</div>`;
}

const notificationHTML = (notification) => {
  return `<div class="mb-2 px-3 py-2 item js-notification-item js-notification-item-${notification.id}">
    <div class="d-flex">
      <div class="d-flex align-items-center justify-content-start text-xl"><i class="far fa-bell mr-3"></i></div>
      <div class="flex-grow-1">
        <div class="text-sm ${!notification.read ? 'font-weight-bolder' : ''}">${notification.content}</div>
        <div class="text-xs">${stringifiedDate(notification.created_at)}</div>
      </div>
      <div class="delete-notification-container d-flex align-items-start justify-content-end ml-3">
        <a class="delete-notification-btn js-delete-notification-btn">
          <i class="fad fa-times-circle"></i>
        </a>
      </div>
    </div>
  </div>`;
}

const addNotification = (notification) => {
  const notificationsPanel = document.getElementById('notifications-panel');
  const container          = document.getElementById('notifications-container');
  const notificationEl     = container.querySelector(`.js-notification-item-${notification.id}`);

  if (notificationEl) return;

  const noNotificationsContainer = container.querySelector('.no-notifications-container');

  if (noNotificationsContainer) noNotificationsContainer.remove();
  container.insertAdjacentHTML("beforeend", notificationHTML(notification));
  addStopPropagationEventOnNotification(container, notification);
  addDeleteNotificationEvent(container, notification);
  if (notificationsPanel.style.display === 'block') markNotificationAsRead(container, notification);
}

const addStopPropagationEventOnNotification = (container, notification) => {
  const notificationEl = container.querySelector(`.js-notification-item-${notification.id}`);

  if (!notificationEl) return;

  notificationEl.addEventListener('click', (event) => event.stopPropagation());
}

const addDeleteNotificationEvent = (container, notification) => {
  const deleteNotificationBtn = document.querySelector(`.js-notification-item-${notification.id} .js-delete-notification-btn`);

  if(!deleteNotificationBtn) return;

  const userId = container.dataset.userId;
  const deletePath = '/users/' + userId + '/notifications/' + notification.id;

  deleteNotificationBtn.addEventListener('click', (event) => {
    event.stopPropagation();

    const notificationEl = container.querySelector(`.js-notification-item-${notification.id}`);
    notificationEl.remove();

    apiClient.delete(deletePath)
    .then((data) => {
      if (data.statusText === "OK") notificationAfterCallback();
    });
  });
};

const markNotificationAsRead = (container, notification) => {
  const userId = container.dataset.userId;
  const markAsReadPath = '/users/' + userId + '/notifications/' + notification.id + '/mark_as_read';

  apiClient.patch(markAsReadPath);
}

const displayMoreNotificationsBtn = () => {
  const wrapper   = document.getElementById('notifications-wrapper');
  let showMoreBtn = wrapper.querySelector('.js-show-more-btn');

  if (showMoreBtn) return;

  wrapper.insertAdjacentHTML("beforeend", moreNotificationsBtnHTML());
  showMoreBtn = wrapper.querySelector('.js-show-more-btn');
  showMoreBtn.addEventListener('click', (event) => {
    event.stopPropagation();
    fetchAllNotifications();
  });
};

const displayUserNotifications = (payload) => {
  payload.data.forEach((notification) => addNotification(notification));

  if (payload.moreData) displayMoreNotificationsBtn();
};

const notificationAfterCallback = () => {
  const container        = document.getElementById('notifications-container');
  const allNotifications = container && container.dataset.all;
  allNotifications ? fetchAllNotifications() : fetchNotifications('notification');
  refreshNotificationBadgeDisplay();
};

const fetchAllNotifications = () => {
  const container = document.getElementById('notifications-container');
  const userId = container.dataset.userId;
  const allNotificationsPath = '/users/' + userId + '/notifications/last_max'
  const wrapper = document.getElementById('notifications-wrapper');
  const showMoreBtn = wrapper.querySelector('.js-show-more-btn');

  if(showMoreBtn) showMoreBtn.remove();
  container.dataset.all = true;
  fetchNotifications('notification', allNotificationsPath);
}

export { notificationAfterCallback, displayUserNotifications };
