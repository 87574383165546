<template lang="pug">
  .form-group
    label {{ $t('dashboardBlocks.labels.list_id') }}
    v-select(
      :options="dashboardBlockLists"
      label="title"
      :reduce="option => option.id"
      v-model="editableSettings.list_id"
    )
    label {{ $t('dashboardBlocks.labels.calendar_id') }}
    v-select(
      :options="dashboardBlockCalendars"
      label="title"
      :reduce="option => option.id"
      v-model="editableSettings.calendar_id"
    )
    hr
    .form-group
      label {{ $t('dashboardBlocks.list.labels.table_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="tables"
        :reduce="option => option.id"
        v-model="editableSettings.table_id"
      )
      label {{ $t('dashboardBlocks.list.labels.site_name_field_id') }}
      v-select(
        label="name"
        :options="fieldsForSelectedTable"
        :reduce="option => option.id"
        v-model="editableSettings.site_name_field_id"
      )
      label {{ $t('dashboardBlocks.list.labels.year_field_id') }}
      v-select(
        label="name"
        :options="fieldsForSelectedTable"
        :reduce="option => option.id"
        v-model="editableSettings.year_field_id"
      )
      label {{ $t('dashboardBlocks.list.labels.ncc_field_id') }}
      v-select(
        label="name"
        :options="fieldsForSelectedTable"
        :reduce="option => option.id"
        v-model="editableSettings.ncc_field_id"
      )
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DashboardBlock from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    dashboardBlockCalendars() {
      return DashboardBlock.query().where('kind', 'calendar').get();
    },
    selectedDashboardBlockList() {
      if (this.editableSettings.list_id) {
        return DashboardBlock.find(this.editableSettings.list_id);
      }
    },
    selectedDashboardBlockCalendar() {
      if (this.editableSettings.calendar_id) {
        return DashboardBlock.find(this.editableSettings.calendar_id);
      }
    },
    selectedTable() {
      if (this.editableSettings.table_id) {
        return this.getTableById(this.editableSettings.table_id);
      }
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    }
  }
}
</script>
