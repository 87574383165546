<template lang="pug">
.cell-content
  date-picker(
    v-model="tmpDate"
    :lang="this.$i18n.locale"
    type="datetime"
    range=true
    value-type="date"
    format="DD/MM/YYYY HH:mm"
    placeholder="DD/MM/YYYY HH:mm ~ DD/MM/YYYY HH:mm"
    :minute-step="5"
    :show-second="false"
    :confirm="true"
    @close="handleClose"
    @open="disableGridClickOutside"
    @clear="handleChange"
  )
</template>

<script>
import { DateTimeRangePickerMixin } from '../../../mixins/entries/DateTimeRangePickerMixin';

export default {
  mixins: [DateTimeRangePickerMixin],
  mounted() {
    this.disableGridClickOutside();
    document.querySelector('input.mx-input').focus();
  },
  beforeDestroy() {
    this.enableGridClickOutside();
  },
  methods: {
    disableGridClickOutside() {
      this.$store.commit('SET_DISABLE_GRID_CLICK_OUTSIDE', true);
    },
    enableGridClickOutside() {
      this.$store.commit('SET_DISABLE_GRID_CLICK_OUTSIDE', false);
    },
    handleClose() {
      this.handleChange();
      this.enableGridClickOutside();
    }
  }
};
</script>
