
import moment  from 'moment';
import { map, first } from 'lodash';
import i18n    from "../locales/locales.js";


export const dataTypesToStringOperations = {
  number: entry => {
    return entry.value;
  },
  string: entry => {
    return entry.value;
  },
  date: entry => {
    const value = entry.value;
    if (value) {
      if (entry.field.typeOptions.date_format === 'with_time') {
        return moment(new Date(value)).format('DD/MM/YYYY HH:mm');
      } else {
        return moment(new Date(value)).format('DD/MM/YYYY');
      }
    }
  },
  date_time_range: entry => {
    const value = entry.value;
    if (!(value.start_date_time && value.end_date_time)) return;

    return `${moment(new Date(value.start_date_time)).format('DD/MM/YYYY HH:mm')} - ${moment(new Date(value.end_date_time)).format('DD/MM/YYYY HH:mm')}`;
  },
  boolean: entry => {
    return i18n.t(`${entry.value}`);
  },
  select: entry => {
    return entry.value;
  },
  files: entry => {
    let files = [];

    entry.value.forEach(file => {
      files.push(`${file.filename} (${window.location.origin}/project_files/${file.id})`);
    });

    return files.join(', ');
  },
  references: entry => {
    return map(entry.value, 'foreign_record_display_name').join(', ')
  },
  users: entry => {
    return map(entry.value, 'display_name').join(', ')
  },
  lookups: entry => {
    return map(entry.value, 'foreign_entry_value').join(', ')
  },
};
