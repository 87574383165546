<template lang="pug">
.base-modal-wrapper(
  :class=""
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @click.self="closeModal"
)
  .base-modal.record-modal(
    :class="{ 'w-100 h-100 m-0': isOnMobile,\
              'has-footer-actions': newRecord || canBeRepeated,\
              'will-be-filtered': !leftRecord.visible }"
    :style="sameModalOffsetStyle"
  )
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")
    .left-panel(
      v-if="!isOnMobile || displayedPanels.left"
    )
      .base-modal-header.record-modal--header.px-1
        template(v-if="leftRecordIsLoaded")
          i18n(
            v-if="isForeignRecord || shouldDisplayRelationshipModal"
            path="views.foreignRecordFrom"
            tag="p"
            class="mb-1 font-weight-lighter"
          )
            template(v-slot:tableName)
              strong {{ foreignTableName || leftRecordTableName }}
          .mb-3.d-flex.align-items-center
            h5.mb-0.text-truncate.has-tooltip(
              data-toogle="tooltip"
              :title="leftRecord.displayName"
            )
              i.far.fa-edit.text-info
              span.ml-2(
                :class="{'font-italic font-weight-lighter': !recordHasDisplayName }"
              ) {{ leftRecord.displayName }}
            .ml-2
              RecordMenu(
                :record="leftRecord"
                @closeModal="closeModal"
              )
      .base-modal-body.record-modal--body.mb-2
        RecordEntriesInput(
          v-if="leftRecordIsLoaded"
          :record="leftRecord"
          :isOnMobile="isOnMobile"
          :displayedPanels="displayedPanels"
          @childRecordSelected="setChildRecord"
          recordModalPanel="left"
        )
        .no-parent-record(v-else-if="!leftRecordIsLoaded && shouldDisplayRelationshipModal")
          i18n(
            path="recordModal.noParentRecord"
            tag="span"
            class="mb-5 font-italic text-secondary px-5 text-center"
          )
            template(v-slot:fieldName)
              strong(
                :class="{ [translationFallbackClasses]: childTableRelationField.nameFallback }"
              ) {{ childTableRelationField.name }}

          .create-reference-btn.create-reference-btn-light.d-flex.align-items-center(
            @click="createParentReferenceRecord"
          )
            .mr-2.pb-1
              i.fa.fa-plus
            div {{ $t('createNewRecord') }}
          a.text-secondary.mt-3(@click.prevent="openAddParentReferenceModal") {{ $t('recordModal.associateParentRecord') }}
        .flex-center.h-100(v-else)
          Loader

      .base-modal-footer.record-modal--footer
        hr.mt-1

        button.btn.btn-light.btn-outline-primary.d-block.ml-auto(
          v-if="canBeRepeated"
          type="button"
          @click.prevent="openNewRepeatableRecordModal"
        )
          i.far.fa-redo-alt.mr-2
          span {{ $t("repeatable_records.repeat_this_record") }}

        template(v-if="shouldDisplayFooter")
          .record-modal--wil-be-filtered.text-center(
            v-if="!leftRecord.visible && !isForeignRecord"
          ) {{ $t('recordWillBeFiltered') }}
          .d-flex.justify-content-center(v-if="newRecord")
            button.btn.btn-info.text-uppercase.text-light.btn-cancel(
              @click="closeModal('cancel')"
            ) {{ $t('cancel')}}
            button.btn.btn-info.text-uppercase.text-light.btn-submit.ml-5(
              v-if="canCreateRecord"
              @click="confirmRecordCreation(false)"
            ) {{ $t('create')}}

    RightPanel(
      v-if="shouldDisplayRelationshipModal"
      :parentRecord="leftRecord"
      :rightRecord="rightRecord"
      :displayedPanels="displayedPanels"
      :isOnMobile="isOnMobile"
      :newRecord="newRecord"
      :canCreateRecord="canCreateRecord"
      :recordModalKey="modalKey"
      @childRecordSelected="setChildRecord"
      @parentRecordReferenceUpdated="setLeftRecordFromRightPanel"
      @closeModal="closeModal"
      @deleteChildRecord="deleteUnconfirmedNewRecord"
      @confirmRecordCreation="confirmRecordCreation"
    )
</template>

<script>
import { some, map, find }        from "lodash";
import { api }                    from '../../../api/client';
import Record                     from "../../../models/record";
import { EventBus }               from "../../../main.js";
import { mapState, mapGetters }   from "vuex";
import { BaseModalMixin }         from '../../modals/BaseModalMixin';
import { UpdateEntryMixin }       from "../../../mixins/UpdateEntryMixin";
import { TableRelationshipMixin } from "../../../mixins/TableRelationshipMixin";
import { alertPopup }             from '../../../../components/alert_popup';
import RecordEntriesInput         from '../../inputs/RecordEntriesInput.vue';
import RightPanel                 from './RightPanel.vue';
import { AddReferenceMixin }      from '../../../mixins/AddReferenceMixin';
import { requireConfirmation }    from '../../../../components/require_confirmation';
import RecordMenu                 from './RecordMenu';

export default {
  components: {
    RecordEntriesInput,
    RightPanel,
    RecordMenu,
  },
  mixins: [BaseModalMixin, UpdateEntryMixin, TableRelationshipMixin, AddReferenceMixin],
  data() {
    return {
      newRecord:       false,
      tmpRecordJobId:  null,
      leftRecord:      {},
      rightRecord:     null,
      displayedPanels: {},
      entry:           null,
    };
  },
  computed: {
    ...mapState(['translationFallbackClasses']),
    ...mapState({
      currentTable:    state => state.tableStore.table,
      currentViewId:   state => state.viewStore.view.id,
      tables:          state => state.tableStore.tables,
      recordsInModals: state => state.recordStore.recordsInModals
    }),
    ...mapGetters({
      getRecordById: 'recordStore/getRecordById',
      getTabledById: 'tableStore/getTableById',
    }),
    leftRecordIsLoaded() {
      return this.leftRecord instanceof Record;
    },
    shouldDisplayFooter() {
      return this.leftRecordIsLoaded && this.leftRecord.table === this.currentTable;
    },
    currentNewRecord() {
      return this.currentTableIsChild ? this.rightRecord : this.leftRecord;
    },
    shouldDisplayRelationshipModal() {
      if (this.leftRecordIsLoaded) {
        return this.leftRecord.table.hasRelationship;
      }
      if (this.rightRecord instanceof Record) {
        return this.rightRecord.table.hasRelationship;
      }
    },
    leftRecordTableName() {
      if (this.leftRecordIsLoaded) {
        return this.leftRecord.table.name;
      }
      return '';
    },
    isForeignRecord() {
      if (this.leftRecordIsLoaded) {
        return this.currentTable.id !== this.leftRecord.table.id;
      }
      return false;
    },
    isOnMobile(){
      return this.mainPanelWidth < 920;
    },
    foreignTableName() {
      if (this.isForeignRecord && this.leftRecordIsLoaded) {
        return this.leftRecord.table.name;
      }
    },
    canCreateRecord() {
      if (!this.currentNewRecord) return false;

      return some(this.currentNewRecord.entries, (entry) => {
        return entry.field.visible && entry.isWritable
      });
    },
    recordHasDisplayName() {
      return this.leftRecord.primaryEntry && this.leftRecord.primaryEntry.value;
    },
    childTableRelationField() {
      if (this.leftRecordIsLoaded) {
        const relationalField = this.leftRecord.table.relationalData.relationalField;
        const symetricFieldId = relationalField.typeOptions.symetric_field_id;
        return find(this.leftRecord.table.relationalData.childTable.fields, ['id', symetricFieldId]);
      } else if (this.rightRecord instanceof Record) {
        const relationalField = this.rightRecord.table.relationalData.relationalField;
        const symetricFieldId = relationalField.typeOptions.symetric_field_id;
        return find(this.rightRecord.table.fields, ['id', symetricFieldId]);
      }
      return {};
    },
    currentTableIsChild() {
      return this.currentTable.hasRelationship && this.currentTable.relationalData.child;
    },
    canBeRepeated() {
      return this.leftRecordIsLoaded && this.leftRecord.table.hasRepeateableSourceField && !this.newRecord
    },
  },
  methods: {
    onModalOpen({ id = null, tableId = null, viewId = null, defaultValuesByFieldId }) {
      this.displayedPanels = {
        left:  true,
        right: false
      };
      this.newRecord = false;
      if (id) {
        this.openExistingRecord({ id, tableId, viewId });
      } else {
        this.createAndOpenNewRecord({ defaultValuesByFieldId });
      }
    },
    openExistingRecord({ id, tableId, viewId }) {
      if (this.recordIsAlreadyOpened(id)) return;

      this.newRecord = false;

      if (tableId && this.currentTable.id !== tableId) {
        this.fetchAndOpenForeignRecord({ id, tableId, viewId });
      } else {
        const record = this.getRecordById(id);
        this.dispatchChildAndParentRecord(this.currentTable, record);
      }
    },
    dispatchChildAndParentRecord(table, record) {
      if (this.tableHasRelationship(table) && this.tableIsChild(table)) {
        this.setRightRecord(record);
        this.fetchParentRecord(record);
      } else  {
        this.leftRecord = record;
        this.$store.commit('recordStore/ADD_RECORD_IN_MODALS', record);
      }
    },
    setChildRecord(childRecordId, childTable) {
      this.$store.commit('recordStore/REMOVE_RECORD_IN_MODALS', this.rightRecord);
      if (!childRecordId) {
        this.rightRecord = null;
      } else if (childRecordId && childTable === this.currentTable) {
        const record = this.getRecordById(childRecordId);
        this.setRightRecord(record);
      } else if (childRecordId) {
        this.fetchRecord({ id: childRecordId, tableId: childTable.id }).then((record) => {
          this.setRightRecord(record);
        });
      }
    },
    setRightRecord(record) {
      if (this.rightRecord) {
        this.$store.commit('recordStore/REMOVE_RECORD_IN_MODALS', this.rightRecord);
      }
      this.rightRecord = record;
      if (record) {
        this.$store.dispatch('addEntryUpdateChannel', record.table.id);
        this.$store.commit('recordStore/ADD_RECORD_IN_MODALS', record);
      }
    },
    createAndOpenNewRecord({ defaultValuesByFieldId }) {
      this.newRecord = true;
      api.tables.createRecord({
        defaultValuesByFieldId,
        viewId:  this.currentViewId,
        tableId: this.currentTable.id,
      }).then(response => {
          this.tmpRecordJobId = response.data.job_id;
          const record        = Record.build(response.data.record, this.currentTable);
          record.visible      = true;
          this.dispatchChildAndParentRecord(this.currentTable, record);
          this.ensureRecordStillBelongsToView({ record });
          this.checkIfRecordNeedtoBeLocked({ record });
          this.currentTableIsChild ? this.displayOnlyRightPanel() : this.displayOnlyLeftPanel();
        });
    },
    fetchAndOpenForeignRecord({ id, tableId, viewId }) {
      this.fetchRecord({ id, tableId, viewId }).then((record) => {
        const table = this.getTabledById(tableId);
        this.dispatchChildAndParentRecord(table, record);
      });
    },
    fetchParentRecord(childRecord) {
      const relationshipFieldId       = childRecord.table.relationalData.foreign_table_reference_field_id;
      const childTableSymetricField   = childRecord.table.fields.find(field => field.typeOptions.symetric_field_id === relationshipFieldId);
      const relationshipFieldEntry    = childRecord.entriesByFieldId[childTableSymetricField.id];
      if (relationshipFieldEntry.value.length === 0) return;
      const parentRecordId = relationshipFieldEntry.value[0].foreign_record_id;

      const parentRecordTableId = childTableSymetricField.typeOptions.foreign_table_id;
      if (parentRecordTableId === this.currentTable.id) {
        this.leftRecord = this.getRecordById(parentRecordId);
        this.$store.commit('recordStore/ADD_RECORD_IN_MODALS', this.leftRecord);
      } else {
        this.fetchRecord({ id: parentRecordId, tableId: parentRecordTableId }).then((record) => {
          this.leftRecord = record;
          this.$store.commit('recordStore/ADD_RECORD_IN_MODALS', record);
        });
      }
    },
    fetchRecord({ id, tableId, viewId }) {
      return api.records.withTableData({
        recordId: id, tableId, viewId
      }).then((response) => {
          return this.$store.dispatch("recordStore/fetchForeignRecord", {
            tableId,
            recordData:     response.data.record,
            viewFieldsData: response.data.view_fields
          })
        });
    },
    beforeModalClose: async function(action) {
      if (this.newRecord && action !== 'cancel') {
        const confirmation = await requireConfirmation(
          this.$t('recordModal.confirmNoCreation'),
          this.$t('create'),
          this.$t('cancel'),
          this.$t('delete')
        );

        if (confirmation.isDismissed) return 'abort';
        if (confirmation.isConfirmed) await this.confirmRecordCreation(true);
      }
    },
    onModalClose() {
      if (this.newRecord) this.deleteUnconfirmedNewRecord(this.currentNewRecord.id);
      this.$store.commit('recordStore/REMOVE_RECORD_IN_MODALS', this.leftRecord);
      this.$store.commit('recordStore/REMOVE_RECORD_IN_MODALS', this.rightRecord);
      this.leftRecord      = {};
      this.rightRecord     = null;
      this.displayedPanels = {};
    },
    confirmRecordCreation(skipCloseModal = false) {
      return api.records.confirmCreation({
        recordId: this.currentNewRecord.id, jobId: this.tmpRecordJobId
      }).then((response) => {
          if (!response.data.error) {
            this.currentNewRecord.creationConfirmed = true;
            this.$store.dispatch("recordStore/addRecord", this.currentNewRecord);
            this.newRecord = false;
            if (this.rightRecord && this.leftRecordIsLoaded) {
              this.setRightRecord(null);
            } else {
              if (!skipCloseModal) this.closeModal('create');
            }
          } else {
            const message = response.data.custom_message || this.$t('updateForbidden')
            alertPopup(message, 'OK');
          }
        });
    },
    deleteUnconfirmedNewRecord(recordId) {
      if (this.newRecord) api.records.delete({
        recordId: recordId,
        jobId:    this.tmpRecordJobId
      }).then(() => {
          this.newRecord = false;
          this.tmpRecordJobId = null;
        });
    },
    recordIsAlreadyOpened(id) {
      if (map(this.siblingModals, 'modalProps.id').includes(id) || map(this.recordsInModals, 'id').includes(id)) {
        this.$toast.default(this.$t('recordModal.alreadyOpened'), { position: 'bottom-left' });
        this.closeModal();
        return true;
      }
    },
    setLeftRecordFromRightPanel(parentReferenceEntry) {
      if (!parentReferenceEntry) return;

      const firstReference = parentReferenceEntry.value[0];
      if (firstReference && firstReference.foreign_record_id !== this.leftRecord.id) {
        this.$store.commit('recordStore/REMOVE_RECORD_IN_MODALS', this.leftRecord);
        const currentTableHasSymetricField = !!this.currentTable.fields.find((field) => {
          return field.id === parentReferenceEntry.field.typeOptions.symetric_field_id;
        });
        if (!currentTableHasSymetricField) {
          this.fetchParentRecord(parentReferenceEntry.record);
        } else {
          const record = this.getRecordById(firstReference.foreign_record_id);
          this.leftRecord = record;
        }
      } else if (!firstReference) {
        this.$store.commit('recordStore/REMOVE_RECORD_IN_MODALS', this.leftRecord);
        this.leftRecord = {};
        this.entry = null;
      }
    },
    openAddParentReferenceModal() {
      const childRecordParentReferenceEnty = this.rightRecord.entriesByFieldId[this.childTableRelationField.id];
      EventBus.$emit('openModal', {
        modalName: 'AddReferenceModal',
        modalProps: {
          entry:                          childRecordParentReferenceEnty,
          shouldNotOpenNewReferenceModal: true
        }
      });
    },
    displayOnlyLeftPanel() {
      this.displayedPanels.right = false;
      this.displayedPanels.left  = true;
    },
    displayOnlyRightPanel() {
      this.displayedPanels.left  = false;
      this.displayedPanels.right = true;
    },
    createParentReferenceRecord() {
      if (this.entry) return; // to avoid multiple clicks

      const childRecordParentReferenceEnty = this.rightRecord.entriesByFieldId[this.childTableRelationField.id];

      this.entry = childRecordParentReferenceEnty;
      this.createReferenceRecord();
    },
    afterCreateReferenceRecord(reference) {
    },
    afterAddReference() {
    },
    openNewRepeatableRecordModal() {
      EventBus.$emit('openModal', {
        modalName: 'NewRepeatableRecordModal',
        modalProps: {
          sourceRecord: this.leftRecord,
          recordModalId: this.modalKey
        }
      });
    },
  }
};
</script>
