export const units = {
  null: {
    name: "Without unit",
    symbol: "",
  },
  percentage: {
    name: "Percentage",
    symbol: "%",
  },
  dollar: {
    name: "Dollar",
    symbol: "$",
  },
  euro: {
    name: "Euro",
    symbol: "€",
  },
  kilowattHour: {
    name: "Kilowatt-hour",
    symbol: "kWh"
  },
  megawattHour: {
    name: "Megawatt-hour",
    symbol: "MWh"
  },
  eurosPerMegawattHour: {
    name: "Euro per Megawatt-hour",
    symbol: "€/MWh"
  },
  megaWatt: {
    name: "Megawatt",
    symbol: "MW"
  },
  kiloWatt: {
    name: "Kilowatt",
    symbol: "kW"
  },
  kiloWattPeak: {
    name: "Kilowatt peak",
    symbol: "kWp"
  },
  hours: {
    name: "Hours",
    symbol: "h"
  },
  minutes: {
    name: "Minutes",
    symbol: "min"
  },
  seconds: {
    name: "Seconds",
    symbol: "sec"
  },
}
