<script>
import moment from 'moment'

export default {
  functional: true,
  props: {
    entry: {
      type: Object
    }
  },
  render(createElement, { props, parent }) {
    let dateValue;

    if (props.entry.value.start_date_time && props.entry.value.end_date_time) {
      const startDateTime = moment(props.entry.value.start_date_time).format('DD/MM/YYYY HH:mm');
      const endDateTime   = moment(props.entry.value.end_date_time).format('DD/MM/YYYY HH:mm');
      dateValue           = `${startDateTime} ~ ${endDateTime}`;
    }

    return createElement("p", { class: 'form-control bg-light' }, dateValue)
  }
}
</script>
