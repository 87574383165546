<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @mousedown.self="closeModal"
)
  .base-modal(:style="sameModalOffsetStyle")
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")


    //- HEADER
    .base-modal-header.px-3
      h5
        span.mr-2.text-info
          i.far.fa-cog
        span.text-xl.text-gray-800.font-weight-bolder {{ $t('dashboard.addBlock')}}
    //- BODY
    form(
      @submit.prevent="saveBlock"
    )
      .base-modal-body.px-3.w-100
        .block-kinds
          .block-kind(
            v-for="blockKind in blockKinds"
            @click.prevent="saveBlock(blockKind.title)"
          )
            .block-kind--icon
              i.fad(:class="'fa-' + blockKind.icon")
            .block-kind--title {{ $t('dashboardBlockKinds.' + blockKind.title) }}

      //- FOOTER
      .base-modal-footer.px-3.mt-4.d-flex.justify-content-around
        button.btn.btn-cancel(
          type="button"
          @click.prevent="closeModal"
        )
          span {{ $t('cancel') }}
</template>

<script>
import { BaseModalMixin }      from './BaseModalMixin';
import DashboardBlock          from '../../models/dashboardBlock';
import { EventBus }            from '../../main';
import { mapState } from 'vuex';

export default {
  mixins: [BaseModalMixin],
  computed: {
    ...mapState({
      currentUser:       state => state.currentUser,
      currentResourceId: state => state.currentResourceId,
      feature:           state => state.feature,
    }),
    blockKinds() {
      let kinds = [
        { title: 'list', icon: 'list' },
        { title: 'alerts', icon: 'exclamation-circle' },
        { title: 'map', icon: 'globe-europe' },
        { title: 'chart', icon: 'chart-line' },
        { title: 'sourced_chart', icon: 'chart-bar' },
        { title: 'sourced_kpi', icon: 'analytics' },
        { title: 'text', icon: 'text' },
      ]

      const tefpCustomKinds = [
        { title: 'calendar', icon: 'calendar' },
        { title: 'flex_contacts', icon: 'address-book' },
        { title: 'kpi', icon: 'analytics' },
        { title: 'live', icon: 'heart-rate' },
        { title: 'load_shift', icon: 'eraser' },
        { title: 'ncclist', icon: 'list' },
        { title: 'peakperiod', icon: 'temperature-frigid' },
        { title: 'regionalmap', icon: 'map-marked' },
        { title: 'reportextract', icon: 'file-excel' },
        { title: 'schedule', icon: 'th-list' },
        { title: 'standalonekpi', icon: 'analytics' },
        { title: 'stat', icon: 'info-circle' },
        { title: 'table', icon: 'table' },
        { title: 'timescale', icon: 'chart-line' },
      ];

      return [...kinds, ...tefpCustomKinds];
    }
  },
  methods: {
    onModalOpen() {

    },
    saveBlock(blockKind) {
      DashboardBlock.api().create(this.currentResourceId, blockKind).then(() => {
        EventBus.$emit('blockCreated');
        this.closeModal();
      });
    }
  }
}
</script>
