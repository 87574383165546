import { Controller } from "@hotwired/stimulus";
import mapboxgl from "mapbox-gl";

export default class extends Controller {
  static values = {
    accessToken: String,
    points: Array,
    defaultCoordinates: Array,
  }

  connect() {
    mapboxgl.accessToken = this.accessTokenValue;

    this.map = new mapboxgl.Map({
      container: this.element,
      style: "mapbox://styles/mapbox/light-v9",
      center: this.defaultCoordinatesValue,
      zoom: 4,
      minZoom: 4,
      maxZoom: 15,
    });

    this.map.on('load', () => {
      // ADD GEOJSON SOURCE TO THE MAP
      this.map.addSource('points', {
        'type': 'geojson',

        // Enable clustering
        'cluster': true,
        clusterMaxZoom: 11,
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        'data': this.markers,
      });

      // // LAYOUT FOR CLUSTERS - CIRCLE
      this.map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'points',
        filter: ['has', 'point_count'],
        paint: {
          // set cluster color depending of max alert level in cluster's points
          'circle-color': '#9CA3AF', // Tailwind gray 400
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20, // * 20px circles when point count is less than 2
            2,
            30, // 30px circles when point count is between 2 and 5
            5,
            40 // * 40px circles when point count is greater than or equal to 5
          ],
          'circle-opacity': 0.6,
          'circle-stroke-color': '#6B7280', // Tailwind gray 500
          'circle-stroke-width': 0.5
        }
      });

      // LAYOUT FOR CLUSTERS - TEXT
      this.map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'points',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        paint: {
          'text-color': 'black'
        }
      });

      // LAYOUT FOR SINGLE POINTS
      this.map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'points',
        filter: ['!', ['has', 'point_count']],
        paint: {
          // Define the point's color depending on its 'alertLevel' property
          'circle-color': '#9CA3AF', // Tailwind gray 400
          'circle-opacity': 0.6,
          'circle-radius': 12
        }
      });
    });

    // CHANGE CURSOR WHEN HOVERING ON A CLUSTER
    this.map.on('mouseenter', 'unclustered-point', () => {
      this.map.getCanvas().style.cursor = 'pointer';
    });
    this.map.on('mouseleave', 'unclustered-point', () => {
      this.map.getCanvas().style.cursor = '';
    });

    // ADD POPUP WHEN CLICKING ON A CLUSTER
    this.map.on('click', 'unclustered-point', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const description = e.features[0].properties.description;
      const descriptionHTML = `
        <div class='tw-m-auto tw-p-2 tw-text-center'>
          <strong>${description}</strong>
        </div>
      `;

      new mapboxgl.Popup()
                .setLngLat(coordinates)
                .setHTML(descriptionHTML)
                .addTo(this.map);
    });
  }

  get markers() {
    return {
      'type': 'FeatureCollection',
      features: this.pointsValue.map((point) => {
        return {
          type: 'Feature',
          properties: {
            description: point.description,
          },
          geometry: {
            type: 'Point',
            coordinates: point.coordinates,
          },
        };
      }),
    }
  }
}
