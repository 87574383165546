<template lang="pug">
  .dashboard-stats
    .dashboard-stat(v-for="stat in stats")
      .dashboard-stat--icon(:style="{ 'background-color': stat.color }")
        i.fas.fa-fw(:class="'fa-' + stat.icon")
      .dashboard-stat--content
        .dashboard-stat--content-infos(v-for="info in stat.infos")
          span.dashboard-stat--value {{ info.value }}
          span.dashboard-stat--unit {{ info.unit }}
</template>

<script>
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';

export default {
  mixins: [DashboardBlockMixin],
  data() {
    return {
      stats: [
        {
          icon: 'wind-turbine',
          color: '#38A169',
          infos: [
            {
              value: 55,
              unit: 'Sites'
            },
            {
              value: 535.5,
              unit: 'MW'
            }
          ]
        },
        {
          icon: 'solar-panel',
          color: '#3182CE',
          infos: [
            {
              value: 2,
              unit: 'Sites'
            },
            {
              value: 42.0,
              unit: 'MW'
            }
          ]
        },
      ]
    }
  },
}
</script>
