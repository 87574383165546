export const units = {
  null: {
    name: "Sin unidad",
    symbol: "",
  },
  percentage: {
    name: "Porcentaje",
    symbol: "%",
  },
  dollar: {
    name: "Dollar",
    symbol: "$",
  },
  euro: {
    name: "Euro",
    symbol: "€",
  },
  kilowattHour: {
    name: "Kilovatio-hora",
    symbol: "kWh"
  },
  megawattHour: {
    name: "Megavatio-hora",
    symbol: "MWh"
  },
  eurosPerMegawattHour: {
    name: "Euro por Megavatio-hora",
    symbol: "€/MWh"
  },
  megaWatt: {
    name: "Megavatio",
    symbol: "MW"
  },
  kiloWatt: {
    name: "Kilovatio",
    symbol: "kW"
  },
  kiloWattPeak: {
    name: "Kilovatio pico",
    symbol: "kWp"
  },
  hours: {
    name: "Horas",
    symbol: "h"
  },
  minutes: {
    name: "Minutos",
    symbol: "min"
  },
  seconds: {
    name: "Segundos",
    symbol: "sec"
  },
}
