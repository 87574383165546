import { find } from 'lodash';

const initProfileForm = () => {
  $('#profileFormModal').modal() // SHOW MODAL IF ERRORS DURING VALIDATIONS

  const profileForm = document.getElementById('profileForm')

  const customClassName = id => `basic-card--img ${id}-bg view-element--img profile-color-${id}-bd`

  if (profileForm) {
    const cardExampleName = profileForm.querySelector('#exampleProfileName')
    const cardExampleImg  = profileForm.querySelector('.basic-card--img')
    const cardExampleIcon = profileForm.querySelector('svg.icon use')

    const nameInput   = profileForm.querySelector('#profile_name')
    const colorInputs = profileForm.querySelectorAll('.colorInput')
    const iconInputs  = profileForm.querySelectorAll('.iconInput')

    nameInput.addEventListener('input', () => {
      const name = event.target.value.length ? event.target.value : nameInput.placeholder
      cardExampleName.innerText = name
    })

    colorInputs.forEach( colorInput => {
      colorInput.addEventListener('change', () => {
        const newClass = customClassName(event.target.value)
        cardExampleImg.className = newClass
      })
    })

    iconInputs.forEach( iconInput => {
      iconInput.addEventListener('change', () => {
        const newIcon = `#icon-${event.target.value}`
        cardExampleIcon.setAttributeNS('http://www.w3.org/1999/xlink', 'href', newIcon)
      })
    })

    const init = () => {
      const selectedColor = find(colorInputs, colorInput => colorInput.checked == true)
      const selectedIcon  = find(iconInputs, iconInput => iconInput.checked == true)
      const currentName   = nameInput.value.length ? nameInput.value : nameInput.placeholder

      cardExampleImg.className  = customClassName(selectedColor.value)
      cardExampleName.innerText = currentName
      cardExampleIcon.setAttributeNS(
        'http://www.w3.org/1999/xlink',
        'href',
        `#icon-${selectedIcon.value}`
      )
    }
    init()
  }
}

export { initProfileForm };
