var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"my-3"},[_c('div',{staticClass:"form-group mb-2"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('editOptions.selectReferencesField')))]),_c('v-select',{attrs:{"input-id":'edit-unit-type' + _vm.editedField.id,"appendToBody":"","searching":true,"options":_vm.referencesFields,"reduce":function (option) { return option.id; },"get-option-label":function (option) { return option.name; },"disabled":!_vm.canEditField},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(_vm._s(_vm.$t('noOptionForSelect')))]}}]),model:{value:(_vm.editedField.typeOptions.references_field_id),callback:function ($$v) {_vm.$set(_vm.editedField.typeOptions, "references_field_id", $$v)},expression:"editedField.typeOptions.references_field_id"}})],1),(_vm.lookupFields.length > 0)?_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('editOptions.selectLookupsField')))]),_c('v-select',{attrs:{"input-id":'edit-unit-type' + _vm.editedField.id,"appendToBody":"","searching":true,"options":_vm.lookupFields,"reduce":function (option) { return option.id; },"get-option-label":function (option) { return option.name; },"disabled":!_vm.canEditField},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(_vm._s(_vm.$t('noOptionForSelect')))]}}],null,false,2537567840),model:{value:(_vm.editedField.typeOptions.lookups_field_id),callback:function ($$v) {_vm.$set(_vm.editedField.typeOptions, "lookups_field_id", $$v)},expression:"editedField.typeOptions.lookups_field_id"}})],1):_vm._e(),_c('div',{staticClass:"form-group mb-2 d-flex align-items-center justify-content-between"},[_c('label',{staticClass:"font-weight-bolder mb-0",attrs:{"for":"is-filtered"}},[_vm._v(_vm._s(_vm.$t('editOptions.isFiltered')))]),(!_vm.query.filters.length)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.showFiltersOption),expression:"showFiltersOption"}],staticClass:"ml-2",attrs:{"type":"checkbox","id":"is-filtered","name":"is-filtered","disabled":!_vm.canEditField},domProps:{"checked":Array.isArray(_vm.showFiltersOption)?_vm._i(_vm.showFiltersOption,null)>-1:(_vm.showFiltersOption)},on:{"change":function($event){var $$a=_vm.showFiltersOption,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.showFiltersOption=$$a.concat([$$v]))}else{$$i>-1&&(_vm.showFiltersOption=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.showFiltersOption=$$c}}}}):_vm._e()]),(_vm.showFilters)?_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-weight-bolder"},[_vm._v(_vm._s(_vm.$t('automations.labels.conditions')))]),_c('RecordFilters',{attrs:{"fields":_vm.filterFields,"originalQuery":_vm.query,"showSaveButton":false},on:{"query-payload-changed":_vm.queryPayloadChanged}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }