import ChartController from "../../../../chart/controller";

export default class extends ChartController {
  get options () {
    const options = this.optionsValue;

    options.options = {
      tooltips: {
        position: "average",
        mode: "index",
        xPadding: 8,
        yPadding: 8,
        xAlign: "center",
        intersect: false,
        backgroundColor: "white",
        bodyFontColor: "rgb(26, 32, 44)",
        bodyFontStyle: "bold",
        bodyFontSize: 12,
        bodySpacing: 8,
        borderColor: "rgba(0, 0, 0, 0.1)",
        borderWidth: "1",
        titleFontColor: "rgb(26, 32, 44)",
        titleFontStyle: "normal",
        displayColors: false,
        callbacks: {
          title: this.tooltipTitleCallback.bind(this),
          label: this.tooltipLabelCallback.bind(this)
        }
      },
      scales: {
        yAxes: [{
          offset: true,
          position: "left",
          ticks: {
            beginAtZero: true,
            maxTicksLimit: 8
          },
          gridLines: {
            display: true,
          },
          scaleLabel: {
            display: true,
            labelString: "kW",
            padding: 6,
            fontSize: 15,
            fontStyle: 'bold'
          }
        }],
        xAxes: [{
            type: "time",
            time: {
              displayFormats: {
                hour:  "k:mm",
                day:   "DD/MM",
                month: "DD/MM"
              }
            },
            ticks: {
              beginAtZero: true,
              autoSkip: true,
              maxTicksLimit: 21,
              major: {
                enabled: true,
                fontStyle: "bold",
              },
            },
            gridLines: {
              display: false,
            }
          }],
      },
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      responsive: true,
      maintainAspectRatio: false
    }
    return options;
  }

  tooltipTitleCallback(tooltipItems) {
    var date            = new Date(tooltipItems[0].label);
    var dateOptions     = { month: 'long', day: 'numeric' };
    var dateTimeOptions = { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};

    if (date.getHours() == 0 && date.getMinutes() == 0 && date.getSeconds() == 0) {
      return date.toLocaleDateString(this.localeValue, dateOptions);
    } else {
      return date.toLocaleDateString(this.localeValue, dateTimeOptions);
    }
  }

  tooltipLabelCallback(tooltipItem, data) {
    var unit = data.datasets[tooltipItem.datasetIndex].unit
    var label = data.datasets[tooltipItem.datasetIndex].label || '';


    if (label) {
      label += ' : ' + Intl.NumberFormat(this.localeValue).format(tooltipItem.yLabel) + ' ' + unit;
    }

    return label;
  }
}
