<template functional lang="pug">
  .cell-content.select-read
    .cell-select-tag(v-if="props.entry.value") {{ props.entry.value }}
</template>

<script>
export default {
  functional: true,
  props: {
    entry: Object
  },
  render(h, ctx) {
    const entry = ctx.props.entry;
  }
};
</script>
