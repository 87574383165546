import { Controller } from "@hotwired/stimulus";
import { debounce } from "lodash";

export default class extends Controller {
  static targets = ["startDateInput", "endDateInput"]

  changeSiblingInput(event) {
    const { target } = event;

    const newDate = new Date(target.value);

    if (target === this.startDateInputTarget && newDate > this.endDate) {
      this.endDateInputTarget.value = this.startDateInputTarget.value;
      this.endDateInputTarget.dispatchEvent(new Event("change"));
    } else if (target === this.endDateInputTarget && newDate < this.startDate) {
      this.startDateInputTarget.value = this.endDateInputTarget.value;
      this.startDateInputTarget.dispatchEvent(new Event("change"));
    }
  }

  get startDate() {
    return new Date(this.startDateInputTarget.value);
  }

  get endDate() {
    return new Date(this.endDateInputTarget.value);
  }
}
