<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.list.labels.table_id') }}
      v-select(
        :searching="true"
        :options="tables"
        label="name"
        :reduce="option => option.id"
        v-model="editableSettings.table_id"
      )
    .form-group
      label {{ $t('dashboardBlocks.list.labels.view_id') }}
      v-select(
        :searching="true"
        :options="viewsForSelectedTable"
        label="name"
        :reduce="option => option.id"
        v-model="editableSettings.view_id"
      )
    .form-group(
      v-for="settingProperty in fieldSettingProperties"
      :key="settingProperty"
    )
      label(:for="settingProperty") {{ $t('dashboardBlocks.list.labels.' + settingProperty) }}
      v-select(
        :searching="true"
        label="name"
        :inputId="settingProperty"
        :options="fieldsForSelectedTable"
        :reduce="option => option.id"
        v-model="editableSettings[settingProperty]"
      )
    .form-group
      label {{ $t('dashboardBlocks.list.labels.list_additional_field_ids') }}
      v-select(
        :searching="true"
        :multiple="true"
        label="name"
        :options="fieldsForSelectedTable"
        :reduce="option => option.id"
        v-model="editableSettings.list_additional_field_ids"
      )

    .form-group(
      v-for="listAdditionalFieldId in editableSettings.list_additional_field_ids"
      :key="listAdditionalFieldId"
    )
      label {{ fieldName(listAdditionalFieldId) }}
      v-select(
        :searching="true"
        label="name"
        :options="styleTypes(listAdditionalFieldId)"
        :reduce="option => option.value"
        v-model="editableSettings.list_additional_field_styles[listAdditionalFieldId]"
      )

    hr

    .form-group.d-flex.align-items-center.justify-content-between.mb-2
      label.mb-0(for="sorts-and-filters-disabled") {{ $t('dashboardBlocks.list.labels.disable_sorts_and_filters') }}
      input.ml-2(
        type="checkbox"
        id="sorts-and-filters-disabled"
        name="sorts-and-filters-disabled"
        v-model="editableSettings.sorts_and_filters_disabled"
      )

    .form-group.d-flex.align-items-center.justify-content-between.mb-2
      label.mb-0(for="item-select-disabled") {{ $t('dashboardBlocks.list.labels.disable_item_select') }}
      input.ml-2(
        type="checkbox"
        id="item-select-disabled"
        name="item-select-disabled"
        v-model="editableSettings.item_select_disabled"
      )

    .form-group.d-flex.align-items-center.justify-content-between.mb-2
      label.mb-0(for="details-disabled") {{ $t('dashboardBlocks.list.labels.disable_details') }}
      input.ml-2(
        type="checkbox"
        id="details-disabled"
        name="details-disabled"
        v-model="editableSettings.details_disabled"
      )

    hr

    template(v-if="!editableSettings.details_disabled")
      .form-group(v-if="!editableSettings.details_disabled")
        label {{ $t('dashboardBlocks.list.labels.details_additional_field_ids') }}
        v-select(
          :searching="true"
          :multiple="true"
          label="name"
          :options="fieldsForSelectedTable"
          :reduce="option => option.id"
          v-model="editableSettings.details_additional_field_ids"
        )
      .form-group
        label {{ $t('dashboardBlocks.list.labels.associations_table_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="tables"
          :reduce="option => option.id"
          v-model="editableSettings.associations_table_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.list.labels.associations_view_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="viewsForAssociationsTable"
          :reduce="option => option.id"
          v-model="editableSettings.associations_view_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.list.labels.associations_reference_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForAssociationsTable"
          :reduce="option => option.id"
          v-model="editableSettings.associations_reference_field_id"
        )

      hr

    .form-group
      label {{ $t('dashboardBlocks.list.labels.parent_list') }}
      v-select(
        :searching="true"
        label="title"
        :options="dashboardBlockLists"
        :reduce="option => option.id"
        v-model="editableSettings.parent_list"
      )
    .form-group
      label {{ $t('dashboardBlocks.list.labels.parent_list_reference_field_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="fieldsToFilterFromParentList"
        :reduce="option => option.id"
        v-model="editableSettings.parent_list_reference_field_id"
      )

    hr(v-if="!editableSettings.sorts_and_filters_disabled")
    .form-group
      label {{ $t('dashboardBlocks.list.labels.filtered_field_ids') }}
      v-select(
        :searching="true"
        :multiple="true"
        label="name"
        :options="fieldsForSelectedTable"
        :reduce="option => option.id"
        v-model="editableSettings.filtered_field_ids"
      )

    hr
    .form-group
      label {{ $t('dashboardBlocks.list.labels.add_margin') }}
      v-select(
        :options="addMargin"
        label="name"
        :reduce="option => option.value"
        v-model="editableSettings.addMargin"
      )
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { EventBus }             from '../../../main';
import { filter, includes }     from 'lodash';
import DashboardBlock           from '../../../models/dashboardBlock';
import i18n                     from '../../../../vue/locales/locales';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fieldSettingProperties: [
        'title_field_id',
        'description_field_id',
        'icon_field_id',
        'color_field_id',
        'image_field_id'
      ],
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
      getFieldById: 'fieldStore/getAllFieldById'
    }),
    selectedTable() {
      if (this.editableSettings.table_id) {
        return this.getTableById(this.editableSettings.table_id);
      }
    },
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    viewsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.views;
      }
      return [];
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
    associationsTable() {
      if (this.editableSettings.associations_table_id) {
        return this.getTableById(this.editableSettings.associations_table_id);
      }
    },
    viewsForAssociationsTable() {
      if (this.associationsTable) {
        return this.associationsTable.views;
      }
      return [];
    },
    fieldsForAssociationsTable() {
      if (this.associationsTable) {
        return filter(this.associationsTable.fields, ['dataType', 'references']);
      }
      return [];
    },
    fieldsToFilterFromParentList() {
      if (this.selectedTable) {
        return filter(this.selectedTable.fields, ['dataType', 'references']);
      }
      return [];
    },
    addMargin() {
      const addMarginValues = [
        'yes',
        'no'
      ];

      return addMarginValues.map((addMargin) => {
        return {
          name: this.$t(`dashboardBlocks.list.${addMargin}`),
          value: addMargin
        }
      })
    }
  },
  watch: {
    'dashboardBlock.kind_settings': function(newSettings, oldSettings) {
      const tableAndViewChanged = (newSettings.table_id !== oldSettings.table_id) && newSettings.view_id;
      const onlyViewChanged     = newSettings.table_id && (newSettings.view_id !== oldSettings.view_id);
      if (tableAndViewChanged) {
        EventBus.$emit('dashboard-block-updated-' + this.dashboardBlock.id, { type: 'tableChanged' });
      } else if (onlyViewChanged) {
        EventBus.$emit('dashboard-block-updated-' + this.dashboardBlock.id, { type: 'viewChanged' });
      }
    },
    'editableSettings.table_id': function(_newValue) {
      this.fieldSettingProperties.forEach(property => this.editableSettings[property] = null);
      this.editableSettings.view_id = null;
      this.editableSettings.filtered_field_ids = [];
    },
    'editableSettings.associations_table_id': function() {
      this.editableSettings.associations_view_id            = null;
      this.editableSettings.associations_reference_field_id = null;
    },
    'editableSettings.list_additional_field_ids': function(newSettings, _oldSettings) {
      Object.keys(this.editableSettings.list_additional_field_styles).forEach(fieldId => {
        if(!newSettings.find(listAdditionalFieldId => listAdditionalFieldId == fieldId)) {
          delete this.editableSettings.list_additional_field_styles[fieldId];
        }
      });
    },
  },
  mounted() {
    this.editableSettings.list_additional_field_styles ||= {};
  },
  methods: {
    fieldName(fieldId) {
      return this.getFieldById(fieldId).name;
    },
    styleTypes(fieldId) {
      const dataType = this.getFieldById(fieldId).dataType;
      const defaultStyleTypes = [
        { value: 'standard', name: 'Standard' },
        { value: 'label', name: 'Label' },
      ];

      if (!dataType === 'date' || !dataType === 'date_time_range') return defaultStyleTypes;

      return [...defaultStyleTypes, { value: 'relative', name: i18n.t('dashboardBlocks.labels.styleTypes.relative') }];
    }
  }
}
</script>
