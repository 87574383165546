import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "editableInput",
    "submitButton",
  ];
  static values = {
    formType: String,
  };

  connect() {
    if (this.formTypeValue == "edit") this.disableSubmitButton();
  }

  onEditableInputChange() {
    if (this.formTypeValue === "new") return;

    const hasFormChanged = this.editableInputTargets.some(input => input.dataset.originalValue != input.value);

    hasFormChanged ? this.enableSubmitButton() : this.disableSubmitButton();
  }

  enableSubmitButton() {
    this.submitButtonTarget.disabled = false;
  }

  disableSubmitButton() {
    this.submitButtonTarget.disabled = true;
  }
}
