import { apiClient, startFetch, endFetch } from '../client';

export default {
  update({ automationModuleId, kind, tableId = null, configParams, filters = null }) {
    const saver = startFetch('SAVING');
    return apiClient.patch(
      'api/automation_modules/' + automationModuleId,
      {
        kind,
        table_id: tableId,
        config_params: configParams,
        filters,
      }
    ).then(endFetch(saver))
  },
  destroy({ automationModuleId }) {
    return apiClient.delete('api/automation_modules/' + automationModuleId)
  },
}
