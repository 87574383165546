import Field from "./column";
import Cell from "./cell";
import History from "./history";
import TextCell from "./cells/textCell";
import SelectCell from "./cells/selectCell";
import NumberCell from "./cells/numberCell";
import DateCell from "./cells/dateCell";

export default class Grid {
  constructor(element, columnData, cellData, options = {}) {
    this.element = element;
    this.columnData = columnData;
    this.cellData = cellData;
    this.options = options;
    this.gridHistory = new History();
    this.selectedCell = null;
    this.setColumns();
    this.setCells();

    document.addEventListener('keydown', this.handleNavigation.bind(this));
    this.element.addEventListener("scroll", this.onScroll.bind(this), { passive: true });
    window.addEventListener('click', this.clickOutside.bind(this));
  }

  get cellTypes() {
    return {
      text: TextCell,
      select: SelectCell,
      number: NumberCell,
      date: DateCell,
    };
  }

  setColumns() {
    this.columns = {};

    this.columnData.forEach((columnData, index) => {
      const name = columnData.id
      const type = columnData.type;
      const options = columnData.options || {};
      const columnNumber = index + 1;
      const column = new Field(name, type, columnNumber, options);

      this.columns[columnNumber] = column;
    });
  }

  setCells() {
    this.cells = {};

    this.element.querySelectorAll(".cell-container").forEach(cellContainer => {
      const controller = this;
      const rowNumber = cellContainer.dataset.rowNumber;
      const columnNumber = cellContainer.dataset.columnNumber;
      const cellData = this.cellData[rowNumber - 1][columnNumber - 1];
      const updateUrl = cellData.update_url;
      const updateMethod = cellData.update_method;
      const editable = cellData.editable || false;
      const value = cellData.value;
      const container = cellContainer;
      const column = this.columns[columnNumber];
      const isCellType = this.cellTypes[column.type]
      const cellType = this.cellTypes[column.type] || Cell;
      const cell = new cellType(
        value,
        this,
        rowNumber,
        columnNumber,
        updateUrl,
        updateMethod,
        editable,
        container,
        column,
        {
          onChange(cell) {
            controller.onChange(cell);
          },
          selectDownCell() {
            controller.selectCell(controller.onDownCell)
          },
        }
      );
      this.cells[rowNumber] ||= {};
      this.cells[rowNumber][columnNumber] = cell;

      cellContainer.addEventListener("click", (event) => this.selectCell(event.currentTarget));
      if (isCellType) cell.initializeContainer();
    });
  }

  handleNavigation(event) {
    if (this.selectedCell && !this.selectedCell.navigable) return;

    if (event.key === "ArrowUp") {
      event.preventDefault();

      this.selectCell(this.onTopCell);
    } else if (event.key === "ArrowDown") {
      event.preventDefault();

      this.selectCell(this.onDownCell);
    } else if ((event.key === "ArrowLeft" || (event.shiftKey && event.key === "Tab"))) {
      event.preventDefault();

      this.selectCell(this.onLeftCell);
    } else if ((event.key === "ArrowRight" || event.key === "Tab")) {
      event.preventDefault();

      this.selectCell(this.onRightCell);
    }
  }

  onScroll(event) {
    if (event.target.scrollLeft > 0) {
      this.element.querySelector("table").classList.add("vertical-shadow");
    } else {
      this.element.querySelector("table").classList.remove("vertical-shadow");
    }
  }

  get firstCell() {
    return this.element.querySelector("tbody tr:first-child td:first-child .cell-container");
  }

  get onTopCell() {
    if(!this.selectedCell) return this.firstCell;

    const currentRow = this.selectedCell.container.parentElement.parentElement.parentElement;
    const previousRow = currentRow.previousElementSibling;
    if (!previousRow) return;

    const colIndex = Array.from(currentRow.querySelectorAll("td .cell-container")).indexOf(this.selectedCell.container);

    return previousRow.querySelectorAll("td .cell-container")[colIndex];
  }

  get onDownCell() {
    if(!this.selectedCell) return this.firstCell;

    const currentRow = this.selectedCell.container.parentElement.parentElement.parentElement;
    const nextRow = currentRow.nextElementSibling;
    if (!nextRow) return;

    const colIndex = Array.from(currentRow.querySelectorAll("td .cell-container")).indexOf(this.selectedCell.container);

    return nextRow.querySelectorAll("td .cell-container")[colIndex];
  }

  get onLeftCell() {
    if (this.selectedCell) {
      return this.selectedCell.container.parentElement.parentElement.previousElementSibling?.querySelector(".cell-container");
    } else {
      return this.firstCell;
    }
  }

  get onRightCell() {
    if (this.selectedCell) {
      return this.selectedCell.container.parentElement.parentElement.nextElementSibling?.querySelector(".cell-container");
    } else {
      return this.firstCell;
    }
  }

  selectCell(cellEl) {
    if (!cellEl || cellEl === this.selectedCell?.container) return;

    this.unselectCell();

    this.selectedCell = this.cells[cellEl.dataset.rowNumber][cellEl.dataset.columnNumber];
    this.selectedCell.select();
  }

  unselectCell() {
    if (!this.selectedCell) return;

    this.selectedCell.unselect();
    this.selectedCell = null;
  }

  clickOutside(event) {
    if(!this.isInTableBody(event.target)) this.unselectCell();
  }

  isInTableBody(element) {
    while (element) {
      if (element === this.element.querySelector("tbody") || element.classList.contains("cell-content")) {
        return true;
      } else if (element === document.body) {
        return false;
      }

      element = element.parentElement
    }
  }

  onChange(cell) {
    this.gridHistory.add(cell);
  }
}
