<script>
import ControlCell                from "./ControlCell";
import EntryCellReadString         from "../cells/read/EntryCellReadString";
import EntryCellWriteString        from "../cells/write/EntryCellWriteString";
import EntryCellReadNumber         from "../cells/read/EntryCellReadNumber";
import EntryCellWriteNumber        from "../cells/write/EntryCellWriteNumber";
import EntryCellReadDate           from "../cells/read/EntryCellReadDate";
import EntryCellWriteDate          from "../cells/write/EntryCellWriteDate";
import EntryCellReadDateTimeRange  from "../cells/read/EntryCellReadDateTimeRange";
import EntryCellWriteDateTimeRange from "../cells/write/EntryCellWriteDateTimeRange";
import EntryCellReadBoolean        from "../cells/read/EntryCellReadBoolean";
import EntryCellWriteBoolean       from "../cells/write/EntryCellWriteBoolean";
import EntryCellReadSelect         from "../cells/read/EntryCellReadSelect";
import EntryCellWriteSelect        from "../cells/write/EntryCellWriteSelect";
import EntryCellReadFiles          from "../cells/read/EntryCellReadFiles";
import EntryCellWriteFiles         from "../cells/write/EntryCellWriteFiles";
import EntryCellReadReferences     from "../cells/read/EntryCellReadReferences";
import EntryCellWriteReferences    from "../cells/write/EntryCellWriteReferences";
import EntryCellReadUsers          from "../cells/read/EntryCellReadUsers";
import EntryCellWriteUsers         from "../cells/write/EntryCellWriteUsers";
import EntryCellReadLookups        from "../cells/read/EntryCellReadLookups";

import store                    from '../../store/store';
import { EventBus }             from "../../main.js";

const components = {
  "read-string":           EntryCellReadString,
  "read-number":           EntryCellReadNumber,
  "read-boolean":          EntryCellReadBoolean,
  "read-date":             EntryCellReadDate,
  "read-date_time_range":  EntryCellReadDateTimeRange,
  "read-files":            EntryCellReadFiles,
  "read-select":           EntryCellReadSelect,
  "read-references":       EntryCellReadReferences,
  "read-users":            EntryCellReadUsers,
  "read-lookups":          EntryCellReadLookups,
  "write-string":          EntryCellWriteString,
  "write-number":          EntryCellWriteNumber,
  "write-boolean":         EntryCellWriteBoolean,
  "write-date":            EntryCellWriteDate,
  "write-date_time_range": EntryCellWriteDateTimeRange,
  "write-files":           EntryCellWriteFiles,
  "write-select":          EntryCellWriteSelect,
  "write-references":      EntryCellWriteReferences,
  "write-users":           EntryCellWriteUsers,
};

export default {
  functional: true,
  props: {
    record: {
      type:     Object,
      required: true
    },
    index: {
      type:     Number,
      required: true
    },
    shownFields: {
      type:     Array,
      required: true
    },
    leftPanelRow: {
      type: Boolean
    },
    recordHovered: {
      type: Boolean
    },
    recordDeletionEnabled: { type: Boolean },
  },
  render(h, { props }) {
    props.record.buildEntries();
    const entries = props.shownFields.map((field) => {
      const entry = props.record.entriesByFieldId[field.id];
      if (entry) {
          entry.value; // dot not remove, it's there to trigger cell refresh when updating field's type
          const component   = components[`${entry.status}-${field.dataType}`];
          const cellContent = h(component, { props: { entry } });

          return h(
            'div', {
              class: ['cell', {
                'read-only': entry.isReadOnly,
                selected:    field.id === store.state.gridStore.selectedEntryCoords.x &&
                              props.index === store.state.gridStore.selectedEntryCoords.y
              }],
              style: {
                width: `${field.columnWidth}px`,
                left:  field.leftOffset + 'px'
              },
              key: entry.field.id,
              on: {
                click(event) {
                  if (store.getters['gridStore/selectedEntry'] === entry) return;
                  store.dispatch("gridStore/selectEntry", {
                    x: entry.field.id,
                    y: props.index
                  });
                },
                dblclick(event) {
                  entry.openCell();
                },
              },
              attrs: {
                'data-field-id':      field.id,
                'data-column-number': field.columnNumber
              }
            },
            [cellContent]
          );
        }
        return h('div', { class: 'cell' }, h('div', { class: 'cell-content' }));
      });

    if (props.leftPanelRow) {
      entries.unshift(
        h(
          ControlCell,
          { props: {
              record: props.record,
              index: props.index,
              recordDeletionEnabled: props.recordDeletionEnabled,
            }
          }
        )
      );
    }

    return h('div', {
      class: ['grid-row', {
          selected: props.index === store.state.gridStore.selectedEntryCoords.y,
          hovered:  props.record.hovered
        }],
      style: {
        'z-index': 1 * props.index
      },
      attrs: {
        'data-record-id':    props.record.id,
        'data-record-index': props.index
      },
      key: props.record.id,
      on: {
        mousemove(event) {
          // props.record.hovered = true;
          const row = document.querySelector(`.left-panel-scroller .grid-row[data-record-id="${props.record.id}"]`);
          if (row) row.classList.add('hovered');
        },
        mouseleave(event) {
          // props.record.hovered = false;
          const row = document.querySelector(`.left-panel-scroller .grid-row[data-record-id="${props.record.id}"]`);
          if (row) row.classList.remove('hovered');
        },
        wheel(event) {
          // props.record.hovered = false;
          const row = document.querySelector(`.left-panel-scroller .grid-row[data-record-id="${props.record.id}"]`);
          if (row) row.classList.remove('hovered');
        }
      }
    }, entries);
  }
}
</script>
