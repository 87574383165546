import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["sidePeek"];

  openSidePeek(event) {
    const title = event.params.title;
    this.sidePeekController.open({ title });
  }

  reload() {
    this.dispatch("reload");
  }

  get sidePeekController() {
    return this.application.getControllerForElementAndIdentifier(this.sidePeekTarget, "side-peek");
  }
}
