<template lang="pug">
  section.my-3
    label.font-weight-bolder {{ $t('editOptions.formula') }}
    label.font-weight-bolder {{ $t('editOptions.formula_data_type') }}
    v-select(
      :input-id="'edit-unit-type' + editedField.id"
      appendToBody
      :searching="true"
      :options="listTypeField"
      :get-option-label="option => $t('fieldType.' + option)"
      :placeholder="$t('units.null.name')"
      v-model="editedField.dataType"
      :disabled="!canEditField"
    )
      template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}

    div.mt-2(
        v-if="editedField.dataType == 'number'"
      )
      label.font-weight-bolder {{ $t('editOptions.number') }}
      v-select(
        :input-id="'edit-unit-type' + editedField.id"
        appendToBody
        :searching="true"
        :options="unitList"
        :disabled="!canEditField"
        :get-option-label="option => $t('units.' + option + '.name')"
        :placeholder="$t('units.null.name')"
        v-model="editedField.typeOptions.unit_type"
      )
        template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}

    textarea.form-control.string.formula-field-input.mt-4(
      v-model="editedField.typeOptions.formula"
      :disabled="!canEditField"
    )
</template>

<script>
  import Field from '../../models/field';

  export default {
    props: {
      editedField: {
        type: Object,
        required: true
      },
      canEditField: {
        type: Boolean,
        required: true
      },
    },
    data() {
     return {
        listTypeField: Field.formulaDataTypes,
        unitList: [null, 'percentage', 'euro', 'kilowattHour', 'megawattHour', 'eurosPerMegawattHour', 'megaWatt', 'kiloWatt', 'kiloWattPeak', 'hours', 'minutes', 'seconds'],
      };
    },
    mounted() {
      if(!this.listTypeField.includes(this.editedField.dataType)) {
        this.editedField.dataType = this.listTypeField[0];
      }
    },
  }
</script>
