<template lang="pug">
.child-record-references-panel.px-5
  .child-record-reference.mb-3(
    v-for="childRecordReference in childRecordReferences"
    @click="$emit('setChildRecord', childRecordReference.foreign_record_id)"
  ) {{ childRecordReference.foreign_record_display_name }}
    .remove-reference-btn(
      v-if="parentRecordReferencesEntry.isWritable"
      @click.stop="removeReference(childRecordReference, parentRecordReferencesEntry)"
    )
      i.fad.fa-times-circle
  p.text-center.font-italic.text-secondary(v-if="!childRecordReferences.length") {{ $t('noRecords') }}

  .add-child-reference-wrapper(
    v-if="parentRecordReferencesEntry && parentRecordReferencesEntry.isWritable"
  )
    hr.mt-1
    .create-reference-btn.create-reference-btn-dark.mb-3.d-flex.align-items-center(
      v-if="canCreateReferenceRecord"
      @click="createReferenceRecord"
    )
      span.mr-2
        i.fa.fa-plus
      div {{ $t('createNewRecord') }}

    .create-reference-btn.open-add-reference(
      v-if="!showAddReference"
      @click="showAddReference = true"
    )
      span.add-reference-btn.mr-2.bg-light.border
        i.fa.fa-plus
      span {{ $t('addExistingReferenceRecord') }}

    .add-child-reference.rounded.p-4(
      v-if="showAddReference"
      v-click-outside="() => showAddReference = false"
    )
      span.close-add-child-reference(@click.stop="showAddReference = false")
        svg.icon
          use(xlink:href="#icon-times-solid")
      .add-child-reference-component
        .d-flex.align-items-center.w-100
          .select-list-search-icon.mr-2
            i.far.fa-search
          input.select-list-filter.flex-grow-1.mr-2.border-0(
            v-focus
            type="text"
            autocomplete="off"
            :placeholder="$t('searchReference')"
            v-model="filterElementsInput"
            :key="selectedElementId + elementObjectName"
          )
        hr.mt-1

        template(v-if="filteredElements.length")
          .d-flex.align-items-center.px-3.py-2.mx-3.rounded.border.my-2.entry-reference(
            v-for="reference in filteredElements"
            :key="reference.foreign_record_id"
            v-select-list-element="reference"
          )
            .text-nowrap.text-truncate(
              :title="reference.foreign_record_display_name"
            ) {{ reference.foreign_record_display_name }}

        .d-flex.align-items-center.justify-content-center.h-100(v-else-if="!recordsForReference") //- From AddReferenceMixin
          Loader
        .d-flex.align-items-center.justify-content-center.h-100(v-else)
          p.font-italic {{ $t('noRecordsFound') }}

        hr
        .d-flex.justify-content-between.align-items-center
          .open-add-reference.d-flex.align-items-center(
            v-if="canCreateReferenceRecord"
            @click="createReferenceRecord"
          )
            span.add-reference-btn.mr-2
              i.fa.fa-plus
            span {{ $t('createNewRecord') }}
          p.mb-0
            small.font-weight-lighter {{ filteredElements.length }} {{ $tc('views.records', filteredElements.length) }}
</template>

<script>
import { ReferencesMixin }        from '../../../mixins/entries/ReferencesMixin';
import { TableRelationshipMixin } from '../../../mixins/TableRelationshipMixin';
import { selectListMixin }        from '../../../mixins/selectListMixin';
import { AddReferenceMixin }      from '../../../mixins/AddReferenceMixin';

export default {
  mixins: [TableRelationshipMixin, ReferencesMixin, AddReferenceMixin],
  props: {
    record: {
      type: Object
    },
    parentRecord: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showAddReference: false
    }
  },
  computed: {
    entry() {
      return this.parentRecordReferencesEntry;
    },
    componentIsOnForeground() {
      return this.showAddReference;
    },
    parentRecordReferencesEntry() {
      if (this.parentRecord.id) {
        const relationshipFieldId = this.recordRelationshipFieldId(this.parentRecord);
        return this.parentRecord.entriesByFieldId[relationshipFieldId];
      }
    },
    childRecordReferences() {
      if (this.parentRecord.id) {
        return this.parentRecordReferencesEntry ? this.parentRecordReferencesEntry.value : [];
      }
      return [];
    }
  },
  methods: {
    afterAddReference() {
      this.showAddReference = false;
    },
    afterCreateReferenceRecord(reference) {
      this.$emit('setChildRecord', reference.foreign_record_id);
    },
  },
  watch: {
    showAddReference: function(newValue, oldValue) {
      if (this.entry.field.typeOptions.foreign_table_id) {
        if (!oldValue && newValue) {
          this.fetchRecordsForReference(); // From AddReferenceMixin
        } else if (oldValue && !newValue) {
          this.cleanSelectList();
          this.recordsForReference = null;
        }
      }
    }
  }
}
</script>
