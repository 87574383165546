import moment               from 'moment';
import { UpdateEntryMixin } from '../UpdateEntryMixin';

export const DatepickerMixin = {
  mixins: [UpdateEntryMixin],
  props: {
    entry: {
      type: Object
    }
  },
  data() {
    return {
      tmpDate: null
    }
  },
  computed: {
    entity() {
      return this.entry;
    },
    field() {
      return this.entity.field;
    },
    dateFormat() {
      return this.field.dateFormat;
    },
    dateInputType() {
      if (this.field.dataType === 'date_time_range') return 'datetime';

      return this.field.typeOptions.date_format === 'with_time' ? 'datetime' : 'date';
    },
  },
  mounted() {
    this.setTmpDate();
  },
  watch: {
    "entity.value": function(_newValue, _oldValue) {
      this.setTmpDate();
    }
  },
  methods: {
    handleChange() {
      const formattedEntryDateBeforeChange = moment(this.entry.value).toISOString();
      const newFormattedDate               = moment(this.tmpDate).toISOString();

      if (formattedEntryDateBeforeChange === newFormattedDate) return;

      this.entry.value = newFormattedDate;
      this.updateEntry(this.entry);
    },
    setTmpDate() {
      this.tmpDate = this.entity && this.entity.value ? moment(this.entity.value).toDate() : null;
    }
  }
};
