import consumer from './consumer';
import Swal from 'sweetalert2'

export const buildUserDataImportChannel = (userId) => {
  return consumer.subscriptions.create(
    {
      channel: 'UserDataImportChannel',
      user_id: userId,
    },
    {
      connected() {
      },
      received(payload) {
        const statusTitle = document.querySelector('.file-manager--dropzone-title');
        const statusSubtitle = document.querySelector('.file-manager--dropzone-subtitle');

        statusTitle.innerHTML = payload.statusTitle;
        statusSubtitle.innerHTML = payload.statusSubtitle;

        if (['success', 'error'].includes(payload.status)) {
          displayStatusPopup(payload.statusTitle, payload.status);
        }
      }
    }
  );
};

const displayStatusPopup = (text, icon) => {
  Swal.fire({
    html: text,
    icon: icon,
    confirmButtonText: 'OK',
    confirmButtonColor: '#2B6CB0',
    customClass: { confirmButton: 'swal-btn' },
    showClass: { popup: 'animate__animated animate__headShake animate__faster' },
    hideClass: { popup: 'animate__animated animate__fadeOutUp animate__faster' }
  }).then(result => {
    if(result.isConfirmed) location.reload();
  });
}
