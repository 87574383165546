import Swal from 'sweetalert2'

const alertPopup = (text, confirmText) => {
  return Swal.fire({
    html: text,
    icon: 'warning',
    showCancelButton: false,
    confirmButtonText: confirmText,
    confirmButtonColor: '#2B6CB0',
    customClass: { confirmButton: 'swal-btn', cancelButton: 'swal-btn' },
    showClass: {
      popup: 'animate__animated animate__headShake animate__faster',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp animate__faster'
    }
  }).then(result => result.value)
}

export { alertPopup }
