<template lang="pug">
  span.view-action.mr-3.pr-2(
    @click.prevent="detectClick(currentView, $event)"
    :class="{opened: show || currentView.editName, \
             border: currentView.editName }"
  )
    span.view-action--btn
      i.far.fa-chevron-down.fa-fw.view-action--btn-icon(v-if="!currentView.editName")
      span.view-action--btn-text.ml-1(:class="{ 'pl-2': !currentView.editName }")
        input.edit-view-name.border-0(
          v-if="currentView.editName"
          v-model.lazy="currentView.name"
          v-focus
          @click.stop
          @change="updateViewName(currentView)"
          @blur="currentView.editName = false"
          @keyup.enter="$event.target.blur()"
        )
        span(v-else)
          i.far.mr-2(:class="'fa-'+ tableIconFor(currentView.type)")
          span(
            :title="currentView.description"
            :class="{ [translationFallbackClasses]: currentView.nameFallback }"
          ) {{ currentView.name }}

    .ctrlbar-action(
      v-if="show"
      v-click-outside="closeAction"
    )

      .ctrlbar-action--header
        .d-flex.align-items-center.w-100.select-list-search-icon
          i.far.fa-search.mr-2
          input.search-view.flex-grow-1.mr-2.border-0(
            v-focus
            type="text"
            autocomplete="off"
            :placeholder="$t('controlBarActions.searchView')"
            v-model="filterElementsInput"
            :key="selectedElementId + elementObjectName"
          )
        .ctrlbar-action--close(@click.stop="closeAction")
          svg.icon
            use(xlink:href="#icon-times-solid")

      .ctrlbar-action--body.ctrlbar-action--body-lg
        .d-flex.align-items-center.justify-content-between.mr-3.px-3.py-2.rounded.view-option(
          v-for="filteredView in filteredElements"
          :key="filteredView.id"
          v-select-list-element="filteredView"
        )
          .d-flex.align-items-center
            i.far.fa-fw.mr-2(:class="'fa-'+ tableIconFor(filteredView.type)")
            .text-nowrap.text-truncate.pr-1(
              :title="filteredView.description"
              :class="{ [translationFallbackClasses]: filteredView.nameFallback }"
            ) {{ filteredView.name }}

      span(v-if="currentView.type != 'form' && currentUser.superAdmin")
        hr
        .mr-2.mb-2.font-weight-bold {{ $t('controlBarActions.newView') }}
        .d-flex.flex-wrap
          // NEW GRID
          .view-action.bg-gray-100
            span.view-action--btn(@click.stop="openNewGridModal")
              i.far.fa-th.fa-fw.view-action--btn-icon
              span.view-action--btn-text.ml-1 {{ $t('controlBarActions.grid') }}

          // NEW CALENDAR
          .view-action.bg-gray-100
            span.view-action--btn(@click.stop="openCalendarModal")
              i.far.fa-calendar-day.fa-fw.view-action--btn-icon
              span.view-action--btn-text.ml-1 {{ $t('controlBarActions.calendar') }}

          //- //NEW GALLERY
          //- .view-action.bg-gray-100
          //-   span.view-action--btn(@click.stop="openNewGalleryModal")
          //-     i.far.fa-th-large.fa-fw.view-action--btn-icon
          //-     span.view-action--btn-text.ml-1 {{ $t('controlBarActions.gallery') }}

          //NEW FORM
          .view-action.bg-gray-100(v-if="feature.canCreateFormView")
            span.view-action--btn(@click.stop="openNewFormModal")
              //i.fal.fa-file-alt.fa-fw.view-action--btn-icon
              i.far.fa-money-check-edit.fa-fw.view-action--btn-icon
              span.view-action--btn-text.ml-1 {{ $t('controlBarActions.form') }}
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { api }                  from '../../api/client';
import { EventBus }             from "../../main.js";
import { selectListMixin }      from '../../mixins/selectListMixin';

export default {
  mixins: [selectListMixin],
  props: {
    show: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      clickNumber:      0,
      filterViewsInput: "",
      selectedViewId:   null,
      elementObjectName: 'view'
    };
  },
  computed: {
    ...mapState({
      feature: state => state.feature,
      currentView: state => state.viewStore.view,
      translationFallbackClasses: state => state.translationFallbackClasses,
      currentUser: state => state.currentUser,
      workspace: state => state.workspace,
    }),
    ...mapGetters({
      currentViews: 'viewStore/currentViews',
    }),
    selectListElements() {
      return this.currentViews;
    },
    componentIsOnForeground() {
      return this.show;
    },
    canEditName() {
      return !this.currentView.locked;
    },
  },
  methods: {
    openNewGridModal() {
      this.closeAction();
      EventBus.$emit("openModal", { modalName: 'NewGridModal' });
    },
    openCalendarModal() {
      this.closeAction();
      EventBus.$emit("openModal", { modalName: 'CalendarModal', modalProps: {} });
    },
    openNewGalleryModal(){
      this.closeAction();
      EventBus.$emit("openModal", { modalName: 'NewGalleryModal' });
    },
    openNewFormModal(){
      this.closeAction();
      EventBus.$emit("openModal", { modalName: 'NewFormModal' });
    },
    tableIconFor(viewType) {
      const icons = {
        calendar: "calendar-alt",
        grid: "th",
        gallery: "th-large",
        form: "money-check-edit"
      };

      return icons[viewType];
    },
    elementSelected(element) {
      this.fetchView(element.id);
    },
    closeAction() {
      this.cleanSelectList();
      this.$emit("close");
    },
    fetchView(viewId) {
      if (viewId === this.currentView.id) return this.closeAction();

      this.$store.dispatch("viewStore/fetchView", { viewId });
      this.closeAction();
    },
    detectClick(view, event) {
      this.clickNumber++;
      if (this.clickNumber === 1) {
        setTimeout(() => {
          switch (this.clickNumber) {
            case 1:
              this.$emit("open");
              break;
            default:
              view.editName = this.canEditName;
          }
          this.clickNumber = 0;
        }, 200);
      }
    },
    updateViewName(viewObj) {
      const view = { id: this.currentView.id, name: this.currentView.name };
      api.views.update({ view })
        .then(response => {
          viewObj.slug = response.data.view.slug;
          viewObj.nameFallback = response.data.view.name_fallback;
          if (viewObj.id === this.currentView.id && !this.workspace) {
            this.$store.dispatch("updateDocumentState", this.currentView.buildDocumentState());
          }
        });
    }
  },
  watch: {
    show(newValue, oldValue) {
      if (newValue) {
        this.selectedViewId = this.currentView.id;
      } else {
        this.selectedViewId = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
span.view-action--btn-text {
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.ctrlbar-action--body-lg {
  max-width: 450px;
}
input.search-view:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
.edit-view-name {
  width: 250px;
  height: 100%;
  margin-top: -4px;
  background-color: transparent;
  &:focus {
    outline: none;
  }
}
</style>
