import { sortBy,
         filter,
         includes,
         map,
         findIndex,
         maxBy,
         find,
         every,
         flatMap }  from "lodash";
import { EventBus } from '../../main';
import Field        from "../../models/field";
import Entry        from "../../models/entry";
import Vue          from 'vue/dist/vue.esm'

export const namespaced = true;

export const actions = {
  buildWithViewFields: ({ dispatch, rootState }, { fields = null, fieldsData, shouldComputeLeftOffset = true }) => {
    fieldsData.forEach((fieldData) => {
      const fieldsToBuild = fields || rootState.tableStore.table.fields;
      const field         = fieldsToBuild.find(field => field.id === fieldData.field_id);
      field.updateWidthViewFieldData(fieldData);
    });
    if (shouldComputeLeftOffset) dispatch('computeFieldsLeftOffset');
  },
  computeFieldsLeftOffset: ({ getters }) => {
    let leftOffset = 0;
    if (getters.visibleFields.length) getters.visibleFields[0].leftOffset = 0;
    getters.visibleFields.slice(1).forEach((field) => {
      field.leftOffset = leftOffset;
      leftOffset += field.columnWidth;
    });

    setTimeout(() => EventBus.$emit('fieldsLeftOffsetComputed'), 0);
  },
  deleteField({ rootState, dispatch }, id) {
    const fieldIndex = findIndex(rootState.tableStore.table.fields, field => field.id === id);
    if (fieldIndex >= 0) {
      rootState.tableStore.table.fields.splice(fieldIndex, 1);
      dispatch('computeFieldsLeftOffset');
      rootState.tableStore.table.records.forEach((record) => {
        Vue.delete(record.entriesByFieldId, id);
      });
    }
  },
  addField({ rootState, commit, dispatch }, { fieldData, actionInitiatorId = null }) {
    const tableIndex = findIndex(rootState.tableStore.tables, ['id', fieldData.table_id]);
    const table      = rootState.tableStore.tables[tableIndex];
    const field      = new Field(fieldData, table);

    if (tableIndex > -1) {
      commit('tableStore/ADD_FIELD_TO_TABLE', { tableIndex, field }, { root: true });
    }

    if (rootState.tableStore.table === table) dispatch('buildFieldForCurrentTable', { field, actionInitiatorId });
  },
  buildFieldForCurrentTable({ dispatch, rootState }, { field, actionInitiatorId }) {
    buildNewFieldDisplayParams(field);
    createEntries(field);
    dispatch('computeFieldsLeftOffset');

    if (actionInitiatorId === rootState.uniqSessionId) {
      setTimeout(() => { EventBus.$emit("openEditField", field) }, 100);
    }
  }
};

export const getters = {
  currentFields: (_state, _getters, rootState) => {
    return filter(rootState.tableStore.table.fields || [], "isUserAccessible");
  },
  allCurrentFieldsAreReadOnly: (_state, getters) => {
    return every(getters.currentFields, 'isReadOnly');
  },
  orderedFields: (_state, getters) => {
    return sortBy(getters.currentFields, "columnNumber");
  },
  visibleFields: (_state, getters) => {
    return filter(getters.orderedFields, "isDisplayable");
  },
  getFieldByTableIdThenId: (_state, _getters, rootState) => (tableId, fieldId) => {
    const table = rootState.tableStore.tables.find(table => table.id === tableId);
    if (table) return table.fields.find(field => field.id === fieldId);
  },
  getFieldById: (_state, _getters, rootState) => (id) => {
    return rootState.tableStore.table.fields.find(field => field.id === id);
  },
  getAllFieldById: (_state, _getters, rootState) => (id) => {
    return find(flatMap(rootState.tableStore.tables, 'fields'), ['id', id]);
  },
  fieldIsCurrentlyFiltered: (_state, _getters, rootState) => (id) => {
    const filteredFieldIds = map([
    ...rootState.viewStore.appliedQuery.filters,
    ...rootState.tableStore.table.query.filters
    ], 'field_id');
    return includes(filteredFieldIds, id);
  }
};

function buildNewFieldDisplayParams(field) {
  field.columnNumber = generateColumnNumber(field.table);
  field.visible      = true;
}

function createEntries(field) {
  field.table.records.forEach(record => {
    Vue.set(record.entriesByFieldId, field.id, new Entry(field.defaultEntryValue(), record, field));
  });
}

function generateColumnNumber(table) {
  const lastColumnNumber = maxBy(table.fields, field => field.columnNumber).columnNumber;
  return lastColumnNumber + 1;
}
