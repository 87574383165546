import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["legendContainer"];
  static values = {
    data: Object,
    locale: String,
  }

  get options() {
    return {
      tooltips: {
        position: "nearest",
        mode: "index",
        xPadding: 12,
        yPadding: 12,
        xAlign: "center",
        intersect: false,
        backgroundColor: "white",
        bodyFontColor: "rgb(26, 32, 44)",
        bodyFontStyle: "bold",
        bodyFontSize: 12,
        bodySpacing: 8,
        borderColor: "#A0AEC0",
        borderWidth: "1",
        titleFontColor: "#2D3748",
        titleFontStyle: "normal",
        footerFontColor: "#1A202C",
        footerFontStyle: "normal",
        displayColors: false,
        callbacks: {
          label: this.tooltipLabelCallback.bind(this),
        }
      },
      scales: {
        xAxes: [{
          maxBarThickness: 60,
          distribution: 'series',
          offset: true,
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            source: 'data',
            fontSize: 12,
            fontColor: "#4A5568",
          },
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: this.dataValue.xlabelString,
            padding: 10,
            fontSize: 15,
            fontStyle: 'bold'
          }
        }],
        yAxes: [
          {
            id: 'A',
            type: 'linear',
            position: 'left',
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 8,
              padding: -5,
              callback: this.yAxesTickCallback,
              fontColor: "#EC4899",
            },
            scaleLabel: {
              display: true,
              labelString: this.dataValue.ylabelLeftString,
              padding: 6,
              fontSize: 15,
              fontStyle: 'bold'
            }
          },
          {
            id: 'B',
            type: 'linear',
            position: 'right',
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 8,
              padding: -5,
              callback: this.yAxesTickCallback,
              fontColor: "#4299E1",
            },
            scaleLabel: {
              display: true,
              labelString: this.dataValue.ylabelRightString,
              padding: 6,
              fontSize: 15,
              fontStyle: 'bold'
            }
          }
        ],
      },
      animation: { duration: 0 },
      hover: { animationDuration: 0 },
      responsiveAnimationDuration: 0,
      responsive: true,
      maintainAspectRatio: false,
      legend: { display: false },
    }
  }

  connect() {
    const options = this.scaleDataAxesToUnifyZeroesOnOptions();
    this.chart = new Chart(
      this.element.querySelector("canvas"),
      {
        type: "bar",
        data: this.dataValue,
        options,
      }
    );
    this.setChartLegend();
  }

  setChartLegend() {
    let chartLegendItemsHtml = this.chart.data.datasets.map((item, datasetIndex) => {
      let itemHidden = this.chart.getDatasetMeta(datasetIndex).hidden;
      let label = item.label;
      let labelColor = !itemHidden ? "#1A202C" : "#4A5568";
      let borderColor = item.borderColor || "#6B7280";
      let backgroundColor = !itemHidden ? (item.backgroundColor || "#6B7280") : "white";
      let checkColor = (!itemHidden && item.borderDash) ? item.borderColor : "white";
      let borderStyle = item.borderDash ? "dashed" : "solid";
      let opacity = !itemHidden ? "initial" : "0.4";
      let labelTextDecoration = !itemHidden ? "initial" : "line-through";

      return `
        <div class="tw-flex tw-items-center tw-cursor-pointer" data-action="click->double-axis-bar-chart#toggleSerie" data-double-axis-bar-chart-dataset-index-param=${datasetIndex}>
          <div class="tw-w-5 tw-h-5 tw-p-0 tw-flex tw-items-center tw-justify-center" style="background-color: ${backgroundColor}; border: 2px ${borderStyle} ${borderColor}; opacity: ${opacity};">
            <i class="fas fa-check" style="color: ${checkColor};"></i>
          </div>
          <div class="tw-text-sm tw-tracking-wide tw-opacity-80 tw-ml-3" style="color: ${labelColor}; text-decoration: ${labelTextDecoration}; opacity: ${opacity};">${label}</div>
        </div>
      `;
    });

    this.legendContainerTarget.innerHTML = chartLegendItemsHtml.join("");
  }

  tooltipLabelCallback(tooltipItem, data) {
    var unit = data.datasets[tooltipItem.datasetIndex].unit
    var label = data.datasets[tooltipItem.datasetIndex].label || '';


    if (label) {
      label += ' : ' + Intl.NumberFormat(this.localeValue).format(tooltipItem.yLabel) + ' ' + unit;
    }

    return label;
  }

  toggleSerie(event) {
    const itemDatasetIndex = event.params.datasetIndex;
    let meta = this.chart.getDatasetMeta(itemDatasetIndex);

    // See controller.isDatasetVisible comment
    meta.hidden = meta.hidden === null ? !this.chart.data.datasets[itemDatasetIndex].hidden : null;

    this.chart.update();
    this.setChartLegend();
  }

  yAxesTickCallback(value) {
    return Intl.NumberFormat(this.localeValue).format(value);
  }

  scaleDataAxesToUnifyZeroesOnOptions() {
    let options = this.options;

    // https://stackoverflow.com/questions/45372997/chartjsalign-zeros-on-chart-with-multi-axes/48650762#48650762
    let axes = options.scales.yAxes

    // Determine overall max/min values for each dataset
    this.dataValue.datasets.forEach((line) => {
      let axis = line.yAxisID ? axes.filter(ax => ax.id === line.yAxisID)[0] : axes[0]

      const minValue = Math.min(...line.data, axis.min_value || 0)
      axis.min_value = (Math.floor(minValue / 1000) * 1000)
      const maxValue = Math.max(...line.data, axis.max_value || 0)
      axis.max_value = (Math.ceil(maxValue / 1000) * 1000)
    })

    // Which gives the overall range of each axis
    axes.forEach(axis => {
      axis.range = axis.max_value - axis.min_value
      // Express the min / max values as a fraction of the overall range
      axis.min_ratio = axis.min_value / axis.range
      axis.max_ratio = axis.max_value / axis.range
    })

    // Find the largest of these ratios
    let largest = axes.reduce((a, b) => ({
      min_ratio: Math.min(a.min_ratio, b.min_ratio),
      max_ratio: Math.max(a.max_ratio, b.max_ratio)
    }))

    // Then scale each axis accordingly
    axes.forEach(axis => {
      axis.ticks = axis.ticks || {}
      axis.ticks.min = largest.min_ratio * axis.range
      axis.ticks.max = largest.max_ratio * axis.range
    })

    return options;
  }
}
