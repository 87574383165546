import { apiClient } from '../client';

export default {
  load({ pageId }) {
    return apiClient.get(`/api/pages/${pageId}/load`, {
      headers: {
        Accept: 'text/html'
      }
    });
  },
  typeNames() {
    return apiClient.get('/api/pages/type_names');
  },
}
