import { Controller } from "@hotwired/stimulus"
import Flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default class extends Controller {
  static targets = [
    "inputThatCouldBeReadOnly",
    "weekPicker",
  ];
  static values = {
    locale: String,
    firstEditableFirstDayOfWeek: String,
    alreadyFilledFutureDates: Array,
  };

  connect() {
    if (this.hasWeekPickerTarget) this.initFlatpickr();
    this.displayReadOnlyDescription();
  }

  initFlatpickr() {
    const alreadyFilledFutureDates = this.alreadyFilledFutureDates;
    let firstEditableFirstDayOfWeek = new Date(this.firstEditableFirstDayOfWeekValue);

    Flatpickr(
      this.weekPickerTarget,
      {
        locale: this.locale,
        altInput: true,
        altFormat: "d/m/Y",
        defaultDate: this.weekPickerTarget.dataset.defaultDate && new Date(this.weekPickerTarget.dataset.defaultDate),
        disable: [
          function(date) {
            return date.getDay() !== 1 ||
                   date < firstEditableFirstDayOfWeek ||
                   alreadyFilledFutureDates.includes(date.getTime());
          }
        ],
      }
    );
  }

  get locale() {
    const locales = {
      en: null, // Default
      fr: French,
      es: Spanish,
    }

    return locales[this.localeValue]
  }

  get alreadyFilledFutureDates() {
    return this.alreadyFilledFutureDatesValue.map(date => new Date(date).getTime());
  }

  displayReadOnlyDescription() {
    const formHasReadOnlyInputs = this.inputThatCouldBeReadOnlyTargets.some(input => {
      return input.getAttribute("readonly") == "readonly"
    });
    if (!formHasReadOnlyInputs) return;

    this.readOnlyDescriptionTarget.setAttribute("aria-expanded", "true");
  }
}
