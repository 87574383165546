export const RecordFilterMixin = {
  props: {
    filter: {
      type:     Object,
      required: true
    }
  },
  computed: {
    field() {
      return this.filter.field;
    },
    shouldDisplayInput() {
      return !['empty', 'not empty'].includes(this.filter.operator);
    }
  }
}
