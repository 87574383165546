import { requireConfirmation } from './require_confirmation'

const initDeleteBtnAlert = () => {
  const deletesBtns = document.querySelectorAll('.js-delete-btn-alert')

  deletesBtns.forEach(deleteBtn => {
    const deleteIcon = deleteBtn.querySelector('.js-delete-icon')

    if ( deleteIcon ) {
      deleteIcon.addEventListener('click', event => {
        event.preventDefault();

        const link = deleteBtn.querySelector('.js-delete-btn-alert--link')

        requireConfirmation(
          link.dataset.confirmText,
          link.dataset.confirmBtnText,
          link.dataset.cancelBtnText
        ).then(result => { if(result.isConfirmed) link.click() })
      })
    }
  })
}

export { initDeleteBtnAlert };
