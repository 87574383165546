<template lang="pug">
  .side-menu.flex-shrink-0(
    :class="{ collapsed, 'mobile-expanded': mobileExpanded }"
    :style="{ backgroundColor: projectPrimaryColors.normal, borderRight: `6px solid ${projectSecondaryColors.normal}` }"
  )
    button.toggle-menu-mobile(@click="toggleMenuMobile")
      i.fal.fa-bars

    button.collapse-menu(@click.prevent="collapseMenu")
      i.fal.fa-chevron-left

    button.expand-menu(@click.prevent="expandMenu")
      i.fal.fa-chevron-right

    div(v-if="!collapsed")
      .side-menu--list-items-home.d-flex.align-items-center.flex-grow-1
        a.side-menu--list-items-title(
          v-if="screenWidth > 767 || mobileExpanded"
          :href="'/'"
        )
          i.fas.fa-home-lg-alt.fa-fw.mx-2.text-white(
            :style="{ backgroundColor: projectSecondaryColors.normal }"
          )
          span(v-if="!collapsed") {{ $t('home') }}

      .side-menu--list-items-title-container.d-flex.justify-content-between(
        v-if="screenWidth > 767 || mobileExpanded"
        :class="{ 'options-menu-opened': action === 'expandedOptionsMenu' }"
      )
        .d-flex.align-items-center.flex-grow-1
          span.side-menu--list-items-title {{ $t('workspace') }}
        tree-folder-add-menu(
          v-if="feature.canCreateResource"
        )

      tree-items(v-if="screenWidth > 767 || mobileExpanded")
</template>

<script>
import { mapState }      from "vuex";
import TreeItems         from "./TreeItems.vue";
import TreeFolderAddMenu from './tree_items/folder/TreeFolderAddMenu';

export default {
  name: 'side-menu',
  components: {
    TreeItems,
    TreeFolderAddMenu
  },
  created() {
    if (localStorage.menuCollapsed) {
      this.collapsed = localStorage.menuCollapsed === 'true';
    }
  },
  data() {
    return {
      collapsed:      false,
      mobileExpanded: false,
      editTabs:       false,
      action:         "",
    }
  },
  computed: {
    ...mapState({
      projectPrimaryColors:   'projectPrimaryColors',
      projectSecondaryColors: 'projectSecondaryColors',
      feature:                'feature',
      screenWidth:            'screenWidth',
    })
  },
  methods: {
    collapseMenu() {
      this.collapsed = true;
      setTimeout(() => {
        this.$emit('toggle-side-menu');
      }, 220);
    },
    expandMenu() {
      this.collapsed = false;
      setTimeout(() => {
        this.$emit('toggle-side-menu');
      }, 220);
    },
    toggleMenuMobile() {
      this.mobileExpanded = !this.mobileExpanded;
    },
    collapseMenuMobile() {
      this.mobileExpanded = false;
    },
    closeAction() {
      this.action = "";
    },
    closeActionOnEscape(event) {
      if (event.key === "Escape") this.action = "";
    },
  },
  watch: {
    collapsed(newValue) {
      localStorage.menuCollapsed = newValue;
    }
  },
}
</script>
