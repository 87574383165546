<template lang="pug">
  .grid-wrapper(
    @mousemove="moveScrollbar"
  )
    .scroll-overlay
      .grid-scrollbar.scrollbar-x(
        @mousedown.prevent="scrollbarScrollX = true"
        v-if="scrollbarWidthX < mainPanelWidth"
        :style="{ transform: `translateX(${scrollbarPositionX}px)`,\
                  width:     scrollbarWidthX + 'px' }"
      )
      .grid-scrollbar.scrollbar-y(
        v-if="shouldDisplayScrollbarY"
        @mousedown.prevent="scrollbarScrollY = true"
        :style="{ transform: `translateY(${scrollbarPositionY}px)`,\
                  height:    scrollbarHeightY + 'px' }"
      )
    .first-column-divider(
      v-if="!error && !loading && orderedFields.length"
      :class="{ scrolled: scrollX > 0 }"
      :style="{ left: firstColumnWidth - 1 + 'px' }"
    )
    .resize-helper--full(
      v-if="startOffset"
      :style="{ left: helperPosition + 'px' }"
    )
    .grid-container(
      v-if="!error && !loading && fields.length"
      ref="gridContainer"
      @contextmenu.capture.prevent
    )
      .grid-headers-wrapper
        .control-cell.firstcc
        draggable(
          :style="{ width: rowWidth + 'px', 'margin-left': `${-headersMarginLeft + controlCellWidth}px` }"
          class="grid-headers"
          handle=".move-column-handle"
          :list="visibleFields"
          @change="moveColumn"
          draggable=".header"
        )
          .cell.header(
            v-for="(field, index) in visibleFields"
            :key="field.id"
            :style="{ width: field.columnWidth + 'px' }"
            :class="{ 'first-cell header-cell': index === 0 }"
          )
            .cell-content.has-tooltip(
              :title="tooltipDescription(field)"
              @dblclick.stop="openEditField(field)"
              @mouseup.right="openEditField(field)"
              @contextmenu.prevent
              data-toggle="tooltip"
              data-html="true"
            )
              .system-field.mr-1.has-tooltip.text-warning.cursor-help.flex-center(
                v-if="field.isSystem"
                :title="$t('fields.system')"
                data-toggle="tooltip"
                data-placement="bottom"
              )
                i.fal.fa-fw.fa-wrench
              i.mr-1.far.fa-fw(:class="'fa-'+ field.faIconName")
              span(:class="{ [translationFallbackClasses]: field.nameFallback }") {{ field.name }}

            i.my-auto.mr-1.far.fa-lock-alt.has-tooltip(
              v-if="field.primary"
              data-toggle="tooltip"
              :title="$t('fields.primaryTooltip')"
            )

            EditField(
              @open="openEditField(field)"
              @close="action = ''"
              :show="action === 'editField-' + field.id"
              :field="field"
              :isFirstField="index === 0"
            )

            .move-column-handle
              i.fal.fa-grip-lines-vertical
            .resize-handle(
              @mousedown.prevent.stop.self="startResize"
              @mousemove.prevent.stop.self="moveResizeHelper"
              @mouseup.prevent.stop.self="applyResize(field, $event)"
              @mouseleave.prevent.stop.self="applyResize(field, $event)"
            )
              .resize-indicator(v-if="!startOffset")

          .cell.header.last.has-tooltip(
            v-if="feature.canAddFields"
            @click.stop="addNewField"
            data-toggle="tooltip"
            :title="$t('addNewField')"
          )
            i.far.fa-plus-circle

      .grid-rows(
        @scroll.passive="handleScrollY"
        ref="gridViewport"
        v-click-outside="unselectEntry"
      )

        .panel-scroller.left-panel-scroller(
          :style="{ height: totalScrollerHeightWithOffset + 'px',\
                    width: firstColumnWidth + 'px' }"
        )
          .fake-grid-bg(
            :style="{ height: totalScrollerHeight + 'px',\
                      width:  firstColumnWidth + 'px' }"
          )
          .visible-scroller(
            :style="{ transform: `translateY(${offsetY}px)` }"
          )
            FunctionalRecordRow(
              v-for="(record, index) in shownRecords"
              :key="record.id"
              :record="record"
              :index="startNode + index"
              :shownFields="leftPanelFields"
              :leftPanelRow="true"
              :recordDeletionEnabled="recordDeletionEnabled"
            )
          .grid-row.last-row(:style="{ width: firstColumnWidth + 'px' }")
            .add-record--row(
              v-if="recordCreationEnabled"
              @click="openRecordModal()"
            )
              i.far.fa-plus-circle.add-record--icon
        .panel-scroller.right-panel-scroller(
          ref="rightPanelScroller"
          @scroll.passive="handleScrollX"
          :style="{ height: totalScrollerHeightWithOffset + 'px',\
                    width:  mainPanelWidth - firstColumnWidth + 'px'}"
        )
          .fake-grid-bg(
            :style="{ height: totalScrollerHeight + 'px',\
                      width:  rowWidth - firstColumnWidth + 60 + 'px' }"
          )
          .visible-scroller(
            ref="visibleScroller"
            :style="{ transform: `translateY(${offsetY}px)`,\
                      width:     rowWidth - firstColumnWidth + 150 + 'px' }"
          )
            FunctionalRecordRow(
              v-for="(record, index) in shownRecords"
              :key="record.id"
              :record="record"
              :index="startNode + index"
              :shownFields="shownRightPanelFields"
              :recordDeletionEnabled="recordDeletionEnabled"
            )
          .grid-row.last-row(:style="{ width:  rowWidth - firstColumnWidth  + 60 + 'px' }")
    .grid-loading(v-else-if="!fields.length")
      div
        .text-center.mb-3.text-danger
          i.fas.fa-ban(style="font-size: 48px;")
        h5 {{ $t('views.noFieldAccessible')}}
    .grid-bottom
      template(v-if="fields.length")
        .grid-bottom--records-count
          span(v-if="!loading && !error") {{ records.length }} {{ $tc('views.records', records.length) }}
</template>

<script>
import { tail,
         first,
         last,
         sumBy,
         throttle,
         debounce,
         clamp,
         remove,
         findIndex,
         findLastIndex }        from "lodash";
import bounds                   from "binary-search-bounds";
import draggable                from "vuedraggable";
import FunctionalRecordRow      from "./rows/FunctionalRecordRow.vue";
import MainError                from "./MainError.vue";
import EditField                from "./field_type_edit/EditField.vue";
import { mapState, mapGetters } from "vuex";
import { EventBus }             from "../main.js";
import { ResizeColumnMixin }    from "../mixins/ResizeColumnMixin";
import { MoveColumnMixin }      from "../mixins/MoveColumnMixin";
import { AddNewFieldMixin }     from "../mixins/AddNewFieldMixin";

export default {
  mixins: [ResizeColumnMixin, MoveColumnMixin, AddNewFieldMixin],
  data() {
    return {
      action:             "",
      scrollY:            0,
      scrollX:            0,
      headersMarginLeft:  0,
      firstFieldIndex:    0,
      lastFieldIndex:     1,
      scrollbarScrollX:   false,
      scrollbarScrollY:   false,
      animationFrame:     null,
      gridViewportHeight: 0,
      scrollbarYPosition: 0,
      controlCellWidth:   60
    };
  },
  computed: {
    ...mapState({
      error:                      state => state.error,
      feature:                    state => state.feature,
      openedModals:               state => state.openedModals,
      disableGridClickOutside:    state => state.disableGridClickOutside,
      mainPanelWidth:             state => state.mainPanelWidth,
      translationFallbackClasses: state => state.translationFallbackClasses,
      currentTable:               state => state.tableStore.table,
    }),
    ...mapGetters({
      orderedFields:               'fieldStore/orderedFields',
      visibleFields:               'fieldStore/visibleFields',
      fields:                      'fieldStore/currentFields',
      records:                     'recordStore/visibleRecords',
      loading:                     'isLoading',
      allCurrentFieldsAreReadOnly: 'fieldStore/allCurrentFieldsAreReadOnly'
    }),
    shownRecords() {
      return this.records.slice(this.startNode, this.startNode + this.visibleNodesCount);
    },
    shownRightPanelFields() {
      return tail(this.visibleFields).slice(this.firstFieldIndex, this.lastFieldIndex);
    },
    leftPanelFields() {
      return this.visibleFields.slice(0, 1);
    },
    rightPanelFields() {
      return this.visibleFields.slice(1);
    },
    firstColumnWidth() {
      return first(this.orderedFields).columnWidth + this.controlCellWidth;
    },
    rowWidth() {
      return sumBy(this.visibleFields, "columnWidth");
    },
    // SCROLL GRID #################################################################
    shouldDisplayScrollbarY() {
      return this.totalScrollerHeight > this.gridViewportHeight;
    },
    scrollbarXWidthRatio() {
      return (this.mainPanelWidth / (this.rowWidth  + 150));
    },
    scrollbarYHeightRatio() {
      return (this.gridViewportHeight / (this.totalScrollerHeightWithOffset));
    },
    scrollbarWidthX() {
      return this.scrollbarXWidthRatio * this.mainPanelWidth - 4;
    },
    scrollbarHeightY() {
      return this.scrollbarYHeightRatio * this.gridViewportHeight - 4;
    },
    scrollbarPositionX() {
      return this.scrollbarXWidthRatio * this.scrollX + 2;
    },
    scrollbarPositionY() {
      return this.scrollbarYHeightRatio * this.scrollY + 2;
    },
    startNode() {
      const startNode = Math.floor(this.scrollY / 32) - 2;
      return Math.max(0, startNode);
    },
    totalScrollerHeight() {
      return 32 * (this.records.length + 1);
    },
    totalScrollerHeightWithOffset() {
      return this.totalScrollerHeight + 150;
    },
    visibleNodesCount() {
      const visibleNodesCount = Math.ceil(this.gridViewportHeight / 32) + 2 * 2;
      return Math.min(this.records.length + 1 - this.startNode, visibleNodesCount);
    },
    offsetY() {
      return 32 * this.startNode;
    },
    recordCreationEnabled() {
      return !this.allCurrentFieldsAreReadOnly && this.currentTable.recordCreationEnabled;
    },
    recordDeletionEnabled() {
      return !this.allCurrentFieldsAreReadOnly && this.currentTable.recordDeletionEnabled;
    },
    // #############################################################################
  },
  methods: {
    unselectEntry() {
      if (this.openedModals.length || this.disableGridClickOutside) return;
      this.$store.dispatch("gridStore/clearSelectedEntry");
    },
    // SCROLL GRID #################################################################
    handleScrollY(event) {
      if (this.animationFrame) {
        window.cancelAnimationFrame(this.animationFrame);
      }
      this.animationFrame = window.requestAnimationFrame(() => {
        this.updateScrollY(event.target.scrollTop);
      });
    },
    handleScrollX(event) {
      if (this.animationFrame) {
        window.cancelAnimationFrame(this.animationFrame);
      }
      this.animationFrame = window.requestAnimationFrame(() => {
        this.headersMarginLeft = event.target.scrollLeft;
        this.updateScrollX(event.target.scrollLeft);
      });
    },
    updateScrollY: throttle(function(scrollTop) {
      this.scrollY = scrollTop;
    }, 0, { leading: false }),
    updateScrollX: throttle(function(scrollLeft) {
      this.scrollX = scrollLeft;
      this.computeShownFieldIndexes();
    }, 0, { leading: false }),
    setgridViewportHeight: debounce(function() {
      if (this.$refs.gridViewport) this.gridViewportHeight = this.$refs.gridViewport.clientHeight;
    }, 300, { leading: true }),
    scrollGrid(event) { // for diagonal scroll, doesn't work on firefox, not used yet
      if (this.loading) return;

      if (this.animationFrame) {
        window.cancelAnimationFrame(this.animationFrame);
      }
      this.animationFrame = window.requestAnimationFrame(() => {
        this.$refs.gridViewport.scrollBy({ top: event.deltaY });
        this.$refs.rightPanelScroller.scrollBy({ left: event.deltaX });
      });
    },
    stopScrollbarScroll() {
      this.scrollbarScrollX = false;
      this.scrollbarScrollY = false;
    },
    moveScrollbar(event) {
      if (this.scrollbarScrollX) {
        this.$refs.rightPanelScroller.scrollBy({ left: event.movementX / this.scrollbarXWidthRatio })
      }
      if (this.scrollbarScrollY) {
        this.$refs.gridViewport.scrollBy({ top: event.movementY / this.scrollbarYHeightRatio })
      }
    },
    computeShownFieldIndexes: throttle(function() {
      this.firstFieldIndex = bounds.ge(
        this.rightPanelFields,
        { leftOffset: this.scrollX - this.firstColumnWidth * 2 },
        (fieldA, fieldB) => fieldA.leftOffset - fieldB.leftOffset
      );
      this.lastFieldIndex = bounds.ge(
        this.rightPanelFields,
        { leftOffset: this.scrollX + this.mainPanelWidth },
        (a, b) => a.leftOffset - b.leftOffset
      );
    }),
    // OpenModals ##################################################################
    openRecordModal() {
      EventBus.$emit("openModal", {
        modalName:  'RecordModal',
        modalProps: {}
      });
    },
    openEditField(field) {
      this.action = 'editField-' + field.id;
    },
    tooltipDescription(field) {
      return `<strong>${field.name}</strong><br>${field.description}`;
    },
  },
  components: {
    MainError,
    draggable,
    EditField,
    FunctionalRecordRow
  },
  mounted() {
    setTimeout(() => {
      this.setgridViewportHeight();
      this.computeShownFieldIndexes();
    })
    EventBus.$on("openEditField", (field) => {
      this.openEditField(field);
    });
  },
  created: function() {
    EventBus.$on('fieldsLeftOffsetComputed', this.computeShownFieldIndexes);
    EventBus.$on("gridNavigated", (direction, field, recordIndex) => {

      if (!this.$refs.rightPanelScroller) return;
      if (direction === null) direction = field.leftOffset < this.scrollX ? "left" : "right";

      // REMINDER: RECORD ROWS ARE 32px TALL
      switch (direction) {
        case "left":
          const fieldIsTooMuchLeft = field.leftOffset - 50 < this.scrollX + 50;
          const isLastField        = last(this.visibleFields) === field;

          if (fieldIsTooMuchLeft || isLastField) {
            this.$refs.rightPanelScroller.scrollTo({ left: field.leftOffset - 50 });
          }
          break;
        case "right":
          const fieldIsTooMuchRight = field.rightOffset > this.scrollX + this.$refs.rightPanelScroller.clientWidth - 50;
          const isFirstField        = first(this.visibleFields) === field;

          if (fieldIsTooMuchRight || isFirstField) {
            this.$refs.rightPanelScroller.scrollTo({
              left: field.rightOffset - this.$refs.rightPanelScroller.clientWidth + 50
            });
          }
          break;
        case "up":
          if (recordIndex * 32 - 32 < this.scrollY) {
            this.$refs.gridViewport.scrollBy({ top: -32 });
          }
          break;
        case "down":
          if (recordIndex * 32 + 32 > this.scrollY + this.$refs.gridViewport.clientHeight) {
            this.$refs.gridViewport.scrollBy({ top: 32 });
          }
          break;
      }
    });
    window.addEventListener('resize', this.setgridViewportHeight);
    window.addEventListener('mouseup', this.stopScrollbarScroll);
  },
  beforeDestroy() {
    EventBus.$off('fieldsLeftOffsetComputed');
    EventBus.$off('gridNavigated');
    EventBus.$off("openEditField");
    window.removeEventListener('resize', this.setgridViewportHeight);
    window.removeEventListener('mouseup', this.stopScrollbarScroll);
  },
  beforeMount() {
    this.firstFieldIndex = 0;
    this.lastFieldIndex = 0;
    this.scrollX = 0;
    this.scrollY = 0;
  }
};
</script>

<style lang="scss" scoped>
.grid-row {
  position: relative;
  height: 32px;

  .cell {
    position: absolute;
  }
}
</style>
