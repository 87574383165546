<template lang="pug">
  .dashboard-performance-report
    .h-100.flex-center.text-center.justify-content-end(v-if="settingsAreReady")
      a.btn.btn-secondary(
        v-if="paramsAreReady"
        :href="downloadUrl"
        :class="{ [translationFallbackClasses]: titleFallback }"
      ) {{ dashboardBlock.title }}
      p.btn.disabled(v-else) {{ $t('dashboardBlocks.noSitesSelected') }}
    .flex-center.h-100(v-else)
        div
          h2.text-center.text-warning
            i.fas.fa-exclamation-triangle
          p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}
</template>

<script>
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import { every, map }               from 'lodash';
import DashboardBlock from '../../models/dashboardBlock';
import DashboardSharedItem from '../../models/dashboardSharedItem';

export default {
  mixins: [DashboardBlockMixin],
  props: {
    periodDates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      mandatorySettings: ['report_kind', 'report_settings'],
      mandatorySettingsByKind: {
        performances: [
          'list_id',
          'sites_table_id',
          'contracts_table_id',
          'site_name_field_id',
          'site_source_id_field_id',
          'site_card_id_field_id',
          'site_technology_field_id',
          'contract_site_reference_field_id',
          'contract_target_field_id',
          'contract_start_date_field_id',
          'contract_end_date_field_id',
        ],
        negative_price_monitoring: [
          'list_id',
          'monitoring_table_id',
          'site_reference_field_id',
          'card_id_field_id',
          'start_of_period_field_id',
          'first_half_hour_production_field_id',
          'last_half_hour_production_field_id',
          'total_production_field_id',
          'bonus_field_id',
          'comment_field_id',
          'responsability_field_id'
        ]
      }
    }
  },
  computed: {
    settingsAreReady() {
      const allIdsArePresent = every(this.mandatorySettings, (setting) => {
        return this.dashboardBlock.kind_settings[setting];
      });
      const reportSettingsArePresent = this.reportKind && every(this.mandatorySettingsByKind.report_settings, (setting) => {
        return this.dashboardBlock.kind_settings.report_settings[setting];
      })
      return allIdsArePresent && reportSettingsArePresent && !!this.dashboardBlockList;
    },
    paramsAreReady() {
      return this.settingsAreReady && !!this.datesQueryString && !!this.recordIdsQueryString;
    },
    reportKind() {
      return this.dashboardBlock.kind_settings.report_kind;
    },
    dashboardBlockList() {
      if (this.reportKind) {
        return DashboardBlock.find(this.dashboardBlock.kind_settings.report_settings.list_id);
      }
    },
    dashboardBlockCalendar() {
      if (this.reportKind) {
        return DashboardBlock.find(this.dashboardBlock.kind_settings.report_settings.calendar_id);
      }
    },
    selectedRecordIds() {
      const items = DashboardSharedItem.listItems(this.dashboardBlockList.id, true).
                                        where('metadata', value => value.checked).get();
      return map(items, 'item_id');
    },
    datesQueryString() {
      if (this.dashboardBlock.kind_settings.report_settings.calendar_id) {
        return this.datesQueryStringFromCalendarBlock;
      } else {
        return this.datesQueryStringFromDashboardDatepicker;
      }
    },
    datesQueryStringFromCalendarBlock() {
      if (this.dashboardBlockCalendar) {
        const yearItem = DashboardSharedItem.query().where('kind', 'calendar_year').find([this.dashboardBlockCalendar.id, 0]);

        if (yearItem) {
          const query = new URLSearchParams();

          query.set("year", yearItem.metadata.year);

          return `?${query}`;
        }
      }
    },
    datesQueryStringFromDashboardDatepicker() {
      if (this.periodDates.length == 2) {
        const query = new URLSearchParams();

        query.set('from', this.periodDates[0].toISOString());
        query.set('to', this.periodDates[1].toISOString());

        return `?${query}`
      }

      return '';
    },
    recordIdsQueryString() {
      if (!this.selectedRecordIds.length) return '';

      return this.selectedRecordIds.reduce((queryString, recordId) => {
        queryString += '&record_ids[]=' + recordId;

        return queryString;
      }, '');
    },
    downloadUrl() {
      return `/api/report_extracts/${this.dashboardBlock.id}${this.datesQueryString}${this.recordIdsQueryString}`;
    }
  }
}
</script>
