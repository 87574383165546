import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "mainFilterForm",
    "mainSiteSelectorPageNumber"
  ];

  resetPageNumberAndSubmitForm() {
    this.mainSiteSelectorPageNumberTarget.value = 1;
    this.submitForm();
  }

  submitForm() {
    // <form>.requestSubmit() is not supported on Safari prior to version 16 (2022/09/12),
    // but requestSubmit triggers html form validations, which are nice for this form,
    // so if available, use requestSubmit, else fallback to classic submit.
    // NB: even on Safari 16, requestSubmit does not support passing a submit btn
    // to simulate a submit from this btn.
    // NB2: feels like Safari wanna play IE style ...
    const form = this.mainFilterFormTarget;

    if (form.requestSubmit) {
      form.requestSubmit();
    } else {
      form.submit();
    }

    this.changeUrlParams();

    this.dispatch("updated");
  }

  changeUrlParams() {
    const url = new URL(window.document.URL);

    url.searchParams.set("page", this.mainSiteSelectorPageNumberTarget.value);

    url.searchParams.set("start_date", this.startDate);
    url.searchParams.set("end_date", this.endDate);

    url.searchParams.delete("site_ids[]");
    this.selectedSiteIds.forEach(siteId => url.searchParams.append("site_ids[]", siteId));

    window.history.replaceState(null, "", url.toString());
  }

  get selectedSiteIds() {
    const mainSiteSelector = document.getElementById("site_ids");

    return Array.from(mainSiteSelector.querySelectorAll("[selected='selected']")).map((option) => option.value);
  }

  get startDate() {
    const startDate = document.getElementById("start_date");

    return startDate.value;
  }

  get endDate() {
    const endDate = document.getElementById("end_date");

    return endDate.value;
  }
}
