import Table from '../models/table';
import View  from '../models/view';
import Field from '../models/field';

export const buildTableFromSchema = (tablePayload) => {
  const table  = new Table(tablePayload);
  table.views  = View.build(tablePayload.views, table);
  table.fields = Field.build(tablePayload.fields, table);

  return table;
}
