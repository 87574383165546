import { EventBus }                      from '../main.js';
import { mapState, mapGetters }          from 'vuex';
import { api }                           from '../api/client';
import { debounce, includes, some, map } from "lodash";
import { alertPopup }                    from '../../components/alert_popup';
import i18n                              from "../locales/locales.js";

export const UpdateEntryMixin = {
  computed: {
    ...mapState({
      appliedQuery:     state => state.viewStore.appliedQuery,
      tableQuery:       state => state.tableStore.table.query,
      currentView:      state => state.viewStore.view,
      currentTable:     state => state.tableStore.table,
      canUseViewAlerts: state => state.feature.canUseViewAlerts,
    }),
    ...mapGetters({
      currentFields:            'fieldStore/currentFields',
      alertedViews:             "viewStore/alertedViews",
      fieldIsCurrentlyFiltered: 'fieldStore/fieldIsCurrentlyFiltered'
    })
  },
  methods: {
    updateEntry(entry, additionalCallback = null) {
      api.entries.update({
        entry:         {
          data_value: entry.value
        },
        fieldId:       entry.field.id,
        entryDataType: entry.field.dataType,
        recordId:      entry.record.id
      }).then(response => {
        if(!response.data.error) {
          this.updateCallbacks(entry);
          if (additionalCallback) additionalCallback(entry, response);
        } else {
          const message = response.data.custom_message || this.$t('updateForbidden')
          entry.restorePreviousValue();
          alertPopup(message, 'OK');
        }
      });
    },
    updateCallbacks(entry) {
      this.sortRecordsIfNeeded(entry);
      this.checkIfRecordNeedtoBeLocked({ entry });
      this.ensureRecordStillBelongsToView({ entry });
      if (this.canUseViewAlerts) this.updateAlertedViewsRecordCount(entry);
      if (entry.record.creationConfirmed) {
        this.$toast.success(`<i class="fas fa-check mr-2"></i>${this.$t('saved')}.`, { position: 'bottom-right' });
      }
    },
    ensureRecordStillBelongsToView({ entry = null, record = null }) {
      if (entry && !this.fieldIsCurrentlyFiltered(entry.field.id)) return;

      const recordToCheck = entry ? entry.record : record;
      this.$store.dispatch('presenceStore/pushRecordIdForPresenceCheck', { recordId: recordToCheck.id });
    },
    checkIfRecordNeedtoBeLocked({ entry = null, record = null }) {
      if (entry && !includes(entry.record.table.fieldIdsForLockRecords, entry.field.id)) return;

      const recordToCheck = entry ? entry.record : record;
      this.$store.dispatch('lockedRecordsStore/pushRecordIdForLockCheck', { recordId: recordToCheck.id });
    },
    updateAlertedViewsRecordCount(entry) {
      this.alertedViews.forEach(view => {
        if (some(view.query.filters, filter => filter.field_id === entry.field.id)) {
          return EventBus.$emit('toggleAlert')
        }
      });
    },
    sortRecordsIfNeeded(entry) {
      if (includes(map(this.currentView.sortOrders, "field_id"), entry.field.id)) {
        this.$store.dispatch("viewStore/updateRowOrder");
      }
    }
  }
};
