import { apiClient, startFetch, endFetch } from '../client';

export default {
  forCurrentTable({ currentTableId }) {
    const saver = startFetch('SAVING');
    return apiClient.get(
      `api/tables/${currentTableId}/data_import_params/table_index`
    ).then(endFetch(saver));
  }
};
