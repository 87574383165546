<template lang="pug">
  .cell-content.reference-entry-content.selected
    .entry-reference(
      v-for="reference in entry.value"
      :key="reference.foreign_record_id"
      @dblclick.capture.stop
      @click="openReferenceModal(reference)"
    ) {{ reference.foreign_record_display_name }}
      span.ml-1.remove-reference-btn(
        v-if="entry.isWritable"
        @click.stop="removeReference(reference, entry)"
      )
        i.far.fa-times

    .add-reference-btn.ml-1(
      v-if="canAddReference"
      @click.stop="openAddReferenceModal"
      @dblclick.stop
    )
      i.fa.fa-plus

    .reference-entry-expand-content(
      @click="openEntryReferencesModal()"
      @dblclick.stop
    )
      i.fad.fa-expand-alt
</template>

<script>
import { ReferencesMixin } from '../../../mixins/entries/ReferencesMixin';

export default {
  mixins: [ReferencesMixin],
  props: {
    entry: {
      type: Object,
      required: true
    }
  }
};
</script>
