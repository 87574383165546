<template lang="pug">
  section.my-3
    .font-weight-bolder {{ $t('editOptions.date') }}
      .d-flex.align-items-center.mb-2.ml-3
        input#withTimeOption(
          v-model="editedField.typeOptions.date_format"
          type="radio"
          value="with_time"
          name="datetimeType"
          :disabled="!canEditField"
        )
        label.label.ml-2.mb-0(
          for="withTimeOption"
        ) JJ/MM/AAA HH:MM

      .d-flex.align-items-center.mb-2.ml-3
        input#withoutTimeOption(
          v-model="editedField.typeOptions.date_format"
          type="radio"
          value="without_time"
          name="datetimeType"
          checked="checked"
          :disabled="!canEditField"
        )
        label.label.ml-2.mb-0(
          for="withoutTimeOption"
        ) JJ/MM/AAA

    .form-group(v-if="currentUser.superAdmin")
      input.cursor-pointer(
        id="repeatable-record-source"
        v-model="editedField.typeOptions.repeatable_record_source"
        type="checkbox"
        :disabled="!canEditField"
      )
      label.cursor-pointer.ml-1.font-weight-bolder(for="repeatable-record-source") {{ $t("fields.useToRepeatRecords") }}
</template>

<script>
import { mapState } from 'vuex'

  export default {
    props: {
      editedField: {
        type: Object,
        required: true
      },
      canEditField: {
        type: Boolean,
        required: true
      },
    },
    computed: {
      ...mapState(['currentUser'])
    }
  }
</script>
