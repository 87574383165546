export default class Cell {
  constructor(value, grid, rowNumber, columnNumber, updateUrl, updateMethod, editable, container, column, options = {}) {
    this.value = value;
    this.grid = grid;
    this.rowNumber = rowNumber;
    this.columnNumber = columnNumber;
    this.updateUrl = updateUrl;
    this.updateMethod = updateMethod;
    this.editable = editable;
    this.container = container;
    this.column = column;
    this.status = "read";
    this.selected = false;
    this.navigable = true;
    this.options = options;

    document.addEventListener('cut', this.handleCut.bind(this));
    document.addEventListener('copy', this.handleCopy.bind(this));
    document.addEventListener('paste', this.handlePaste.bind(this));
  }

  handleCut(event) {
    if (!this.selected || !this.editable) return;

    event.preventDefault();

    this.cut();
  }

  handleCopy(event) {
    if (!this.selected) return;

    event.preventDefault();

    this.copy();
  }

  handlePaste(event) {
    if (!this.selected || !this.editable) return;

    event.preventDefault();

    this.paste();
  }

  get inReadMode() {
    return this.status === "read";
  }

  get inWriteMode() {
    return this.status === "write";
  }

  get inputName() {
    return this.grid.options.modelName ? `${this.grid.options.modelName}[${this.column.name}]` : this.column.name;
  }

  select() {
    this.selected = true;
    this.container.classList.add("selected");
    this.selectCallbacks();
  }

  unselect() {
    this.selected = false;
    this.container.classList.remove("selected");
    if (!this.inWriteMode) return;

    const value = this.editionModeValue();
    this.update(value);
    this.setReadMode();
  }

  setReadMode() {
    this.status = "read";
    this.navigable = true;
    this.container.innerHTML = this.readHTML;
  }

  setWriteMode(value) {
    if (!this.editable) return;

    this.status = "write";
    this.container.innerHTML = this.writeHTML(value);

    return true;
  }

  initializeContainer() {
    this.setReadMode();
    this.addEventListeners();
  }

  empty() {
    this.update(null);
  }

  update(value) {
    if (this.value === value) return;

    this.previousValue = this.value;
    this.value = value;

    this.edit();
    this.save();
  }

  cut() {
    if (!this.selected || !this.editable) return;

    this.copy();
    this.update(null);
  }

  copy() {
    if (!this.selected) return;

    if (this.inReadMode) {
      navigator.clipboard.writeText(this.value);
    } else {
      navigator.clipboard.writeText(this.editionModeValue());
    }
  }

  paste() {
    if (!this.selected || !this.editable) return;

    navigator.clipboard.readText().then(clipText => {
      const newValue = clipText;

      if (newValue === this.value) return;

      this.update(newValue);
    });
  }

  save() {
    this.submitForm();
    this.onChange();
  }

  submitForm() {
    const form = this.container.querySelector("form");
    if (!form) return;

    form.requestSubmit ? form.requestSubmit() : form.submit();
  }

  addEventListeners() {
    // override
  }

  selectCallbacks() {
    // override
  }

  selectDownCell() {
    if (this.options.selectDownCell) this.options.selectDownCell();
  }

  onChange() {
    if (this.options.onChange) this.options.onChange(this);
  }
}
