export function getCookie(name) {
  const cookies = document.cookie ? document.cookie.split("; ") : []
  const prefix = `${encodeURIComponent(name)}=`
  const cookie = cookies.find(cookie => cookie.startsWith(prefix))

  if (cookie) {
    const value = cookie.split("=").slice(1).join("=")
    return value ? decodeURIComponent(value) : undefined
  }
}

const twentyYears = 20 * 365 * 24 * 60 * 60 * 1000 // max cookie lifetime

export function setCookie(name, value) {
  const body = [name, value].map(encodeURIComponent).join("=")
  const expires = new Date(Date.now() + twentyYears).toUTCString()
  const cookie = `${body}; path=/; expires=${expires}`
  document.cookie = cookie
}

// sets cookie value to null and the expiration date in past should trigger
// the removal of the cookie by the browser
export function removeCookie(name) {
  const cookie = `${encodeURIComponent(name)}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`
  document.cookie = cookie
}
