import { mapState } from 'vuex';

export const TreeItemMixin = {
  props: {
    treeItem: {
      type: Object,
      required: true
    },
    depth: {
      type:     Number,
      required: true
    }
  },
  computed: {
    ...mapState(['currentUser']),
  }
};
