<template lang="pug">
.dashboard-timescale
  template(v-if="settingsAreReady")
    .dashboard-block--header.pb-3
      h2 {{ dashboardBlock.title }}
      .d-flex
        template(v-if="computationModesAny")
          .d-flex.align-items-center
            .toggle-item(v-for="option in computationModes")
              input.toggle-selector(
                v-model="computationMode"
                type="radio"
                :value="option"
                :id="'computation-' + option + dashboardBlockId"
                @change="loadSourcedCharts"
              )
              label(:for="'computation-' + option + dashboardBlockId")
                | {{ $t("dashboardBlocks.timescale.labels.computation_mode." + option) }}
      .d-flex
        template(v-if="aggregationCanBeSelected")
          .dashboard-timescale-items--aggregation
            .d-flex.align-items-center
              .toggle-item(v-for="option in [true, false]")
                input.toggle-selector(
                  v-model="aggregated"
                  type="radio"
                  :value="option"
                  :id="'aggregated-' + option + dashboardBlockId"
                  @change="loadSourcedCharts"
                )
                label(:for="'aggregated-' + option + dashboardBlockId")
                  | {{ $t("dashboardBlocks.timescale.labels.aggregated.entities." + option) }}
        span.view-action
          a.view-action--btn(:href="CSVUrl" target="_blank")
            i.far.fa-arrow-to-bottom.fa-fw.pop-action-btn
    .dashboard-timescale-items
      .chart(:class="{ 'h-100': loadingData }")
        div
        .legend(v-if="!loadingData")
          .row.legend--row
            .col-4(v-for="item in chartLegendItems")
              .d-flex.align-items-center(@click="toggleSerie(item)" style="cursor: pointer")
                template(v-if="item.hidden")
                  .d-flex.color(:style="{ backgroundColor: 'white',\
                                          border: `2px ${item.lineDash ? 'dashed' : 'solid'} ${item.strokeStyle || '#6B7280'}`,\
                                          opacity: 0.4}"
                  )
                    i.fas.fa-check(:style="{color: 'white'}")
                  .label(
                    :style="{ color: '#4A5568', 'text-decoration': 'line-through', opacity: 0.4}"
                    ) {{ item.text }}
                template(v-else)
                  .d-flex.color(:style="{ backgroundColor: (item.fillStyle || '#6B7280'),\
                                          border: `2px dashed ${item.strokeStyle || '#6B7280'}`}"
                  )
                    i.fas.fa-check(:style="{color: item.lineDash ? item.strokeStyle : 'white'}")
                  .label(:style="{ color: '#1A202C' }") {{ item.text }}
        .dashboard-timescale-items--graph(:class="{ 'h-100': loadingData }")
          .chart-container
            .flex-center.h-100(v-if="apiError")
              h2.text-center.text-warning
                i.fas.fa-exclamation-triangle
              p.font-italic.ml-2 {{ $t('dashboardBlocks.apiError') }}
            .flex-center.h-100(v-else-if="loadingData")
              Loader
            component(
              v-else
              :is="`chart-${dashboardBlock.kind_settings.chart_kind}`"
              :chartdata="chartdata"
              :annotations="[]"
              :stacked="stacked"
              :dashboardBlockId="dashboardBlockId"
              :prettyDate="prettyDate"
              @sendLegend="setLegend"
            )
  .flex-center.h-100(v-else)
    div
      h2.text-center.text-warning
        i.fas.fa-exclamation-triangle
      p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}
</template>

<script>
import { api }                 from '../../api/client';
import { mapGetters }          from 'vuex';
import { EventBus }            from '../../main';
import DashboardBlock          from '../../models/dashboardBlock';
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import ChartBar                from '../charts/ChartBar';
import ChartBarDoubleAxis      from '../charts/ChartBarDoubleAxis';
import ChartLine               from '../charts/ChartLine';
import DashboardSharedItem     from '../../models/dashboardSharedItem';
import { isEqual, map, debounce } from 'lodash';

export default {
  mixins: [DashboardBlockMixin],
  components: {
    "chart-bar":             ChartBar,
    "chart-bar-double-axis": ChartBarDoubleAxis,
    "chart-line":            ChartLine,
  },
  props: {
    periodDates: {
      type:     Array,
      required: true
    }
  },
  data() {
    return {
      loadingData: true,
      chartdata: {},
      period: [],
      title: '',
      items: [],
      chartKind: '',
      yearSelected: new Date().getFullYear(),
      currentYear: new Date().getFullYear(),
      disabledDates: [],
      chartLegendItems:   [],
      stacked: false,
      prettyDate: true,
      debouncedLoadSourcedCharts: () => {},
      initialLoadDone: false,
      aggregated: false,
      settingsAreReady: true,
      apiError: false,
      computationMode: "",
      computationModes: [],
      denyAggregation: false,
      pollingInterval: null,
    }
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    dashboardBlockList() { // we need the list block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.list_id);
    },
    tableForTimescale() {
      if (this.settingsAreReady) {
        return this.getTableById(this.dashboardBlockList.kind_settings.table_id);
      }
    },
    selectedRecordIds() {
      if (this.tableForTimescale) {
        const items = DashboardSharedItem.listItems(this.dashboardBlockList.id, true).
                                          where('metadata', value => value.checked).get();
        return map(items, 'item_id');
      }
    },
    aggregationCanBeSelected() {
      if (this.denyAggregation) {
        return false;
      }

      return Object.keys(this.dashboardBlock.kind_settings.settings_by_source).length == 1 &&
               this.selectedRecordIds.length > 1;
    },
    computationModesAny() {
      return this.computationModes.length > 0;
    },
    CSVUrl() {
      if (this.periodDates.length === 0) return;

      const [startDate, endDate] = this.periodDates;

      let queryString = `?aggregated=${this.aggregated}`;
      queryString += `&start_date=${startDate.toISOString()}`;
      queryString += `&end_date=${endDate.toISOString()}`;
      queryString += `&computation_mode=${this.computationMode}`;

      this.selectedRecordIds.forEach((recordId) => {
        queryString += `&record_ids[]=${recordId}`
      });

      return `/api/sourced_charts/${this.dashboardBlockId}.csv${queryString}`;
    }
  },
  created: function() {
    EventBus.$on('dashboard-block-load-charts-' + this.dashboardBlockId, () => {
      this.settingsAreReady = true;
      this.fetchSourcesSettings();
      this.loadSourcedCharts();
    });
  },
  beforeDestroy() {
    EventBus.$off('dashboard-block-load-charts-' + this.dashboardBlockId);
    clearInterval(this.pollingInterval);
  },
  watch: {
    'selectedRecordIds': function (newRecordIds, oldRecordIds) {
      if (this.settingsAreReady && !isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) {
        this.aggregated = this.aggregated && this.selectedRecordIds.length > 1;

        this.loadSourcedCharts();
      }
    },
    'periodDates': function (newPeriodDates, _oldPeriodDates) {
      if (!newPeriodDates) return;
      this.loadSourcedCharts();
    }
  },
  mounted() {
      this.fetchSourcesSettings();
      // First time component is loaded, we want to wait until all needed list modules are loaded before
      // actually loading the chart, hence the `trailing: true, leading: false`
      // see #_loadSourcedCharts for behavior once intial load is done
      this.debouncedLoadSourcedCharts = debounce(
        this._loadSourcedCharts,
        500, { trailing: true, leading: false }
      )
      setTimeout(() => { this.loadSourcedCharts() }, 500)

      // Poll data from server every 30 seconds
      this.setPollingInterval(30000)
  },
  methods: {
    setPollingInterval(interval) {
      clearInterval(this.pollingInterval);
      if (this.dashboardBlock.kind_settings.real_time) {
        this.pollingInterval = setInterval(() => { this._loadSourcedCharts() }, interval)
      }
    },
    toggleSerie(item) {
      item.hidden = !item.hidden;
      EventBus.$emit(`toggleSerie${this.dashboardBlockId}`, item);
    },
    setLegend(chartLegendItems) {
      this.chartLegendItems = chartLegendItems;
    },
    loadSourcedCharts() {
      this.apiError = false;
      this.loadingData = true;

      this.debouncedLoadSourcedCharts();
    },
    _loadSourcedCharts() {
      if (!this.settingsAreReady) return;

      // When chart has been loaded at least one time,
      // then we want the load to be triggered right away (when aggregate buttons are clicked for example)
      // - to avoid the user to feel latency -
      // so we change the debounce settings for `{ trailing: true, leading: true }`
      if (!this.initialLoadDone) {
        this.initialLoadDone = true;
        this.debouncedLoadSourcedCharts = debounce(
          this._loadSourcedCharts,
          500, { trailing: true, leading: true }
        )
      }

      return api.dashboardBlocks.sourcedCharts(this.dashboardBlock.id,
                                               this.selectedRecordIds,
                                               this.periodDates,
                                               this.aggregated,
                                               this.computationMode,
        ).then(({ data }) => {
          if (data.error && data.message === "IncompleteEntitySchema") {
            return this.settingsAreReady = false;
          }

          if (data.error && data.message === "APIFailure") {
            return this.apiError = true;
          }

          // Ignore when real time
          if (data.error && data.message === "AutoGridClient::APIFailure") {
            if (this.pollingInterval) {
              return {};
            } else {
              return this.apiError = true;
            }
          }

          this.stacked = data.stacked || false;
          this.noDate = data.noDate || false;

          if (data.chartdata) {
            this.chartdata = data.chartdata;
            this.title     = data.title || "";

            this.period = (data.period || []).map((dateString) => {
              return new Date(dateString);
            });

            this.items         = data.items || [];
            this.chartKind     = data.chart_kind || "";
            this.disabledDates = (data.dates || []).map((dateString) => {
              return new Date(dateString);
            });
          }

          this.loadingData = false
        });
    },
    fetchSourcesSettings() {
      api.sourceSettings.index()
         .then(({ data }) => {
          const sources = Object.keys(this.dashboardBlock.kind_settings.settings_by_source);

          this.computationMode = data[sources[0]].default_computation_mode;
          this.computationModes = data[sources[0]].computation_modes;

          const aggregationPermissions = sources.map((source) => data[source].display_aggregation_buttons)
          this.denyAggregation = aggregationPermissions.includes(false);
        });
    },
  },
}
</script>
