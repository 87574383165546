<template functional lang="pug">
  .entry.entry-text.entry-extra-small
    .d-flex.align-items-center.pb-2
      .boolean-label.mb-0.pl-0.bg-light.entry-text
        i.entry-title-icon.text-xs.checkbox-icon.far(:class=" props.entry.value === true ? 'fa-check text-success' : 'fa-times text-danger times-icon'")
      .entry-title.text-xs.text-gray-500.ml-2.overflow-hidden {{ props.entry.field.name }}
</template>

<script>
export default {
  props:{
    entry :{
      type:     Object,
      required: true
    }
  },
  render(h, ctx) {
    const entry = ctx.props.entry
  }
}
</script>
