<template lang="pug">
  .form-group
    label {{ $t('dashboardBlocks.labels.list_id') }}
    v-select(
      :searching="true"
      :options="dashboardBlockLists"
      label="title"
      :reduce="option => option.id"
      v-model="editableSettings.list_id"
    )
    label {{ $t('dashboardBlocks.calendar.labels.calendar_type') }}
    v-select(
      :searching="true"
      :options="calendarKinds"
      label="name"
      :reduce="option => option.value"
      v-model="editableSettings.calendar_type"
    )
    template(v-if="['calendar_with_all_events', 'calendar_without_pp1'].includes(editableSettings.calendar_type)")
      hr
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.peak_periods_table_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="tables"
          :reduce="option => option.id"
          v-model="editableSettings.peak_periods_table_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.peak_periods_one_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForPeakPeriodsTable"
          :reduce="option => option.id"
          v-model="editableSettings.peak_periods_one_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.peak_periods_two_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForPeakPeriodsTable"
          :reduce="option => option.id"
          v-model="editableSettings.peak_periods_two_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.peak_periods_period_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForPeakPeriodsTable"
          :reduce="option => option.id"
          v-model="editableSettings.peak_periods_period_field_id"
        )
    template(v-if="editableSettings.calendar_type === 'calendar_with_all_events'")
      hr
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.activations_table_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="tables"
          :reduce="option => option.id"
          v-model="editableSettings.activations_table_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.activations_site_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForActivationsTable"
          :reduce="option => option.id"
          v-model="editableSettings.activations_site_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.activations_period_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForActivationsTable"
          :reduce="option => option.id"
          v-model="editableSettings.activations_period_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.activations_file_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForActivationsTable"
          :reduce="option => option.id"
          v-model="editableSettings.activations_file_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.activations_notification_date_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForActivationsTable"
          :reduce="option => option.id"
          v-model="editableSettings.activations_notification_date_field_id"
        )
      hr
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.unavailabilities_table_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="tables"
          :reduce="option => option.id"
          v-model="editableSettings.unavailabilities_table_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.unavailabilities_table_site_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForUnavailabilitiesTable"
          :reduce="option => option.id"
          v-model="editableSettings.unavailabilities_table_site_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.calendar.labels.unavailabilities_period_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForUnavailabilitiesTable"
          :reduce="option => option.id"
          v-model="editableSettings.unavailabilities_period_field_id"
        )
    template(v-if="editableSettings.calendar_type === 'calendar_negative_price'")
      hr
      .form-group
        label {{ $t('dashboardBlocks.list.labels.table_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="tables"
          :reduce="option => option.id"
          v-model="editableSettings.table_id"
        )
        label {{ $t('dashboardBlocks.list.labels.view_id') }}
        v-select(
          :searching="true"
          :options="viewsForSelectedTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.view_id"
        )
        label {{ $t('dashboardBlocks.list.labels.main_reference_field_id_start_date') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForSelectedTable"
          :reduce="option => option.id"
          v-model="editableSettings.main_reference_field_id_start_date"
        )
      hr
      .form-group
        label {{ $t('dashboardBlocks.list.labels.associations_table_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="tables"
          :reduce="option => option.id"
          v-model="editableSettings.associations_table_id"
        )
        label {{ $t('dashboardBlocks.list.labels.view_id') }}
        v-select(
          :searching="true"
          :options="viewsForAssociatedSelectedTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.associations_view_id"
        )
        label {{ $t('dashboardBlocks.list.labels.associations_reference_field_id_site') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForAssociationsTable"
          :reduce="option => option.id"
          v-model="editableSettings.associations_reference_field_id_site"
        )
        label {{ $t('dashboardBlocks.list.labels.associations_reference_field_id_date') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForAssociationsTable"
          :reduce="option => option.id"
          v-model="editableSettings.associations_reference_field_id_date"
        )
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DashboardBlock from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    selectedDashboardBlockList() {
      if (this.editableSettings.list_id) {
        return DashboardBlock.find(this.editableSettings.list_id);
      }
    },
    calendarKinds() {
      const calendarKinds = [
        'calendar_with_all_events',
        'calendar_without_pp1',
        'calendar_negative_price'
      ];

      return calendarKinds.map((calendarKind) => {
        return {
          name: this.$t(`calendarTypes.${calendarKind}`),
          value: calendarKind
        }
      })
    },
    selectedTable() {
      if (this.editableSettings.table_id) {
        return this.getTableById(this.editableSettings.table_id);
      }
    },
    peakPeriodsTable() {
      if (this.editableSettings.peak_periods_table_id) {
        return this.getTableById(this.editableSettings.peak_periods_table_id);
      }
    },
    fieldsForPeakPeriodsTable() {
      if (this.peakPeriodsTable) {
        return this.peakPeriodsTable.fields;
      }
      return [];
    },
    activationsTable() {
      if (this.editableSettings.activations_table_id) {
        return this.getTableById(this.editableSettings.activations_table_id);
      }
    },
    fieldsForActivationsTable() {
      if (this.activationsTable) {
        return this.activationsTable.fields;
      }
      return [];
    },
    unavailabilitiesTable() {
      if (this.editableSettings.unavailabilities_table_id) {
        return this.getTableById(this.editableSettings.unavailabilities_table_id);
      }
    },
    fieldsForUnavailabilitiesTable() {
      if (this.unavailabilitiesTable) {
        return this.unavailabilitiesTable.fields;
      }
      return [];
    },
    viewsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.views;
      }
      return [];
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
    associationsTable() {
      if (this.editableSettings.associations_table_id) {
        return this.getTableById(this.editableSettings.associations_table_id);
      }
    },
    viewsForAssociatedSelectedTable() {
      if (this.associationsTable) {
        return this.associationsTable.views;
      }
      return [];
    },
    fieldsForAssociationsTable() {
      if (this.associationsTable) {
        return this.associationsTable.fields;
      }
      return [];
    }
  }
}
</script>
