<template lang="pug">
  .dashboard-timescale
    template(v-if="settingsAreReady")
      .dashboard-block--header.pb-3
        h2 {{ displayedTitle }}
        .d-flex
          template(v-if="aggregationCanBeSelected")
            .dashboard-timescale-items--aggregation
              .d-flex.align-items-center
                .toggle-item(v-for="option in [true, false]")
                  input.toggle-selector(
                    v-model="displayAggregation"
                    type="radio"
                    :value="option"
                    :id="'aggregated-' + option"
                    @change="loadTimescaleCharts"
                  )
                  label(:for="'aggregated-' + option")
                    | {{ $t("dashboardBlocks.timescale.labels.aggregated." + option) }}
        .d-flex
          .dashboard-timescale-items--period(
            v-if="dataType !== 'aoe'"
          )
            template(v-if="yearCanBeSelected")
              .d-flex.align-items-center
                .toggle-item(v-for="option in [currentYear - 1, currentYear, currentYear + 1]")
                  input.toggle-selector(
                    v-model="yearSelected"
                    type="radio"
                    :value="option"
                    :id="option"
                    @change="loadTimescaleCharts"
                  )
                  label(:for="option") {{ option }}
          span.view-action
            a.view-action--btn(:href="csvUrl" target="_blank")
              i.far.fa-arrow-to-bottom.fa-fw.pop-action-btn
      .dashboard-timescale-items
        .sidechart(v-if="itemsWithValue.length > 0")
          .dashboard-timescale-items--data
            .kui-card.dashboard-kpi(
              v-for="data in itemsWithValue"
              :title="data.description"
            )
              .dashboard-kpi--icon.bg-project-secondary-100.text-project-secondary
                i.fad.fa-fw(:class="'fa-' + data.icon")
              .content
                .dashboard-kpi--label {{ data.label }}
                .dashboard-kpi--figure
                  span.dashboard-kpi--value {{ data.value }}
                  span.dashboard-kpi--unit {{ data.unit }}
          .data-container(v-for="annotation in annotations")
            .annotation-data(:id="`annotation${annotation.id}`" :data-values="annotation.data")
        .chart(:class="{ 'h-100': loadingData }")
          .legend(v-if="!loadingData")
            .row.legend--row
              .col-4(v-for="item in chartLegendItems")
                .d-flex.align-items-center(@click="toggleSerie(item)" style="cursor: pointer")
                  template(v-if="item.hidden")
                    .d-flex.color(:style="{ backgroundColor: 'white',\
                                            border: `2px ${item.lineDash ? 'dashed' : 'solid'} ${item.strokeStyle || '#6B7280'}`,\
                                            opacity: 0.4}"
                    )
                      i.fas.fa-check(:style="{color: 'white'}")
                    .label(
                      :style="{ color: '#4A5568', 'text-decoration': 'line-through', opacity: 0.4}"
                      ) {{ item.text }}
                  template(v-else)
                    .d-flex.color(:style="{ backgroundColor: (item.fillStyle || '#6B7280'),\
                                            border: `2px dashed ${item.strokeStyle || '#6B7280'}`}"
                    )
                      i.fas.fa-check(:style="{color: item.lineDash ? item.strokeStyle : 'white'}")
                    .label(:style="{ color: '#1A202C' }") {{ item.text }}
          .dashboard-timescale-items--graph(:class="{ 'h-100': loadingData }")
            .chart-container
              .flex-center.h-100(v-if="loadingData")
                Loader
              component(
                v-else
                :is="`chart-${dashboardBlock.kind_settings.chart_kind}`"
                :chartdata="chartdata"
                :stacked="stacked"
                :dashboardBlockId="dashboardBlockId"
                :annotations="annotations"
                :noDate="noDate"
                @sendLegend="setLegend"
              )

    .flex-center.h-100(v-else)
      div
        h2.text-center.text-warning
          i.fas.fa-exclamation-triangle
        p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}
</template>

<script>
import { api } from '../../api/client';
import { mapGetters } from 'vuex';
import { EventBus } from '../../main';
import DashboardBlock from '../../models/dashboardBlock';
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import ChartBar from '../charts/ChartBar';
import ChartBarDoubleAxis from '../charts/ChartBarDoubleAxis';
import ChartLine from '../charts/ChartLine';
import DashboardSharedItem from '../../models/dashboardSharedItem';
import { isEqual, map, debounce } from 'lodash';
import i18n from "../../locales/locales.js";

export default {
  mixins: [DashboardBlockMixin],
  components: {
    "chart-bar":             ChartBar,
    "chart-bar-double-axis": ChartBarDoubleAxis,
    "chart-line":            ChartLine,
  },
  props: {
    periodIdentifier: {
      type:     String,
      required: false
    },
    periodDates: {
      type:     Array,
      required: true
    }
  },
  data() {
    return {
      loadingData:        true,
      chartdata:          {},
      period:             [],
      title:              '',
      items:              [],
      chartKind:          '',
      displayAggregation: true,
      yearSelected:       new Date().getFullYear(),
      currentYear:        new Date().getFullYear(),
      disabledDates:      [],
      csvUrl:             '',
      annotations:        [],
      chartLegendItems:   [],
      stacked:            false,
      noDate:             false,
      debouncedLoadTimescaleCharts: () => {},
      initialLoadDone: false
    }
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    settingsAreReady() {
      return !!(this.dashboardBlock &&
              this.dashboardBlock.kind_settings.list_id &&
              this.dashboardBlock.kind_settings.api_source_id_field_id &&
              this.dashboardBlock.kind_settings.color_field_id &&
              this.dashboardBlockList &&
              this.dashboardBlockList.kind_settings.table_id &&
              this.dashboardBlockList.kind_settings.view_id &&
              this.dashboardBlock.kind_settings.chart_kind &&
              this.dataType)
    },
    dashboardBlockList() { // we need the list block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.list_id);
    },
    tableForTimescale() {
      if (this.settingsAreReady) {
        return this.getTableById(this.dashboardBlockList.kind_settings.table_id);
      }
    },
    selectedRecordIds() {
      if (this.tableForTimescale) {
        const items = DashboardSharedItem.listItems(this.dashboardBlockList.id, true).
                                          where('metadata', value => value.checked).get();
        return map(items, 'item_id');
      }
    },
    dashboardBlockParentList() {
      return DashboardBlock.find(this.dashboardBlock.kind_settings.parent_list);
    },
    selectedRecordIdsFromParentList() {
      if (!this.dashboardBlockParentList) return;

      const items = DashboardSharedItem.listItems(this.dashboardBlockParentList.id, true).
                                        where('metadata', value => value.checked).get();
      return items.map((item) => item.item_id);
    },
    itemsWithValue() {
      return this.items.filter((item) => {
        return item.value != '';
      })
    },
    dataType() {
      if (this.dashboardBlock) {
        return this.dashboardBlock.kind_settings.datatype;
      }
    },
    yearCanBeSelected() {
      return ['capacity_auction'].includes(this.dataType);
    },
    aggregationCanBeSelected() {
      return ['flex_production', 'enr_forecast_and_best_production'].includes(this.dataType) && this.selectedRecordIds.length > 1;
    },
    canHandleSeveralAnnotationsOrAnnotationMustbeUniqueAndActuallyIs() {
    return (this.dashboardBlock.kind_settings.unique_element && this.selectedRecordIdsFromParentList.length  == 1) || !this.dashboardBlock.kind_settings.unique_element
    },
    displayedTitle() {
      if (this.dashboardBlock.kind_settings.locked_title) {
        return this.dashboardBlock.title;
      } else {
        return i18n.t(`dataTypes.${this.dataType}`) + ' ' + this.title;
      }
    },
  },
  created: function() {
    EventBus.$on('dashboard-block-load-charts-' + this.dashboardBlockId, this.loadTimescaleCharts);
  },
  beforeDestroy() {
    EventBus.$off('dashboard-block-load-charts-' + this.dashboardBlockId);
  },
  watch: {
    'selectedRecordIds': function (newRecordIds, oldRecordIds) {
      if (this.settingsAreReady && !isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) {
        this.loadTimescaleCharts();
      }
    },
    'selectedRecordIdsFromParentList': function (newRecordIds, oldRecordIds) {
      if (!this.settingsAreReady || isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) return;

      this.loadTimescaleCharts();
    },

    'periodIdentifier': function (newPeriod, _oldPeriod) {
      if (!newPeriod) return;

      this.loadTimescaleCharts();
    },
    'periodDates': function (newPeriodDates, _oldPeriodDates) {
      if (!newPeriodDates) return;
      this.loadTimescaleCharts();
    }
  },
  mounted() {
      // First time component is loaded, we want to wait until all needed list modules are loaded before
      // actually loading the chart, hence the `trailing: true, leading: false`
      // see #_loadTimescaleCharts for behavior once intial load is done
      this.debouncedLoadTimescaleCharts = debounce(
        this._loadTimescaleCharts,
        500, { trailing: true, leading: false }
      )
      setTimeout(() => { this.loadTimescaleCharts() }, 500)
  },
  methods: {
    toggleSerie(item) {
      item.hidden = !item.hidden;
      EventBus.$emit(`toggleSerie${this.dashboardBlockId}`, item);
    },
    setLegend(chartLegendItems) {
      this.chartLegendItems = chartLegendItems;
    },
    loadTimescaleCharts() {
      this.loadingData = true;

      this.debouncedLoadTimescaleCharts();
    },
    _loadTimescaleCharts() {
      if (!this.settingsAreReady) return;

      // When chart has been loaded at least one time,
      // then we want the load to be triggered right away (when aggregate buttons are clicked for example)
      // - to avoid the user to feel latency -
      // so we change the debounce settings for `{ trailing: true, leading: true }`
      if (!this.initialLoadDone) {
        this.initialLoadDone = true;
        this.debouncedLoadTimescaleCharts = debounce(
          this._loadTimescaleCharts,
          500, { trailing: true, leading: true }
        )
      }

      return api.dashboardBlocks.timescaleCharts(this.dashboardBlock.id,
                                                this.selectedRecordIds,
                                                this.selectedRecordIdsFromParentList,
                                                this.periodDates,
                                                this.periodIdentifier,
                                                this.displayAggregation,
                                                this.yearSelected,
        ).then((response) => {
          this.csvUrl = response.data.csv_url;

          if (response.data.annotations) {
            if (this.canHandleSeveralAnnotationsOrAnnotationMustbeUniqueAndActuallyIs) {
            this.annotations = response.data.annotations;
            };
          };

          this.stacked = response.data.stacked || false;
          this.noDate = response.data.noDate || false;

          if (response.data.chartdata) {
            this.chartdata = response.data.chartdata;
            this.title     = response.data.title || "";

            if (!this.canHandleSeveralAnnotationsOrAnnotationMustbeUniqueAndActuallyIs) {
              this.chartdata.datasets = [];
              this.chartdata.labels   = [];
            };

            this.period = (response.data.period || []).map((dateString) => {
              return new Date(dateString);
            });

            this.items         = response.data.items || [];
            this.chartKind     = response.data.chart_kind || "";
            this.disabledDates = (response.data.dates || []).map((dateString) => {
              return new Date(dateString);
            });
          }

          this.loadingData = false
        });
    },
    disableDates(date) {
      if (this.disabledDates.length > 1) {
        return date < this.disabledDates[0] || date > this.disabledDates[1];
      } else {
        return date > this.disabledDates[0];
      }
    },
  },
}
</script>
