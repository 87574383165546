<template lang="pug">
  .dashboard-live(ref="blockElement")
    template(v-if="settingsAreReady")
      .dashboard-regional-map--header.pb-3
        h2.mb-1.pr-3 {{ $t('dashboardBlocks.regionalMap.header') }}
      .d-flex.justify-content-between
        .d-flex
          .m-1.text-xl.text-gray-800.p-2.rounded.bg-gray-200.cursor-pointer(
            v-for="technoButton in ['Solar', 'Wind', 'Biomass', 'Hydro', 'CogenGas', 'CogenWaste']"
            @click="setTechnology(technoButton)"
            :class="{'bg-gray-400': technology === technoButton}"
            :title="$t(`dashboardBlocks.regionalMap.${technoButton}`)"
          )
            i.fas.fa-fw(:class="'fa-' + technologyIcons[technoButton]")
        .view-action.p-2
          a.view-action--btn.text-xl(:href="xlsxUrl" target="_blank")
            i.far.fa-arrow-to-bottom.fa-fw.pop-action-btn
      div#france-regions-svg
        FranceRegionsSVG(:regions="selectedTechnology")
    .flex-center.h-100(v-else)
      div
        h2.text-center.text-warning
          i.fas.fa-exclamation-triangle
        p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}

</template>

<script>
import { map, isEqual }         from 'lodash';
import { api }                  from '../../api/client';
import { mapGetters }           from 'vuex';
import DashboardBlock           from '../../models/dashboardBlock';
import { DashboardBlockMixin }  from '../../mixins/DashboardBlockMixin';
import DashboardSharedItem      from '../../models/dashboardSharedItem';
import FranceRegionsSVG         from './FranceRegionsSVG';

export default {
  components: {
    FranceRegionsSVG
  },
  mixins: [DashboardBlockMixin],
  props: {
    periodDates: {
      type:     Array,
      required: true
    }
  },
  data() {
    return {
      technologyIcons: {
        Solar: "solar-panel",
        Wind: "wind-turbine",
        Biomass: "tree",
        Hydro: "faucet",
        CogenGas: "fire-alt",
        CogenWaste: "recycle",
      },
      technology: "Wind",
      regionalProduction: {},
      xlsxUrl: ""
    };
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    settingsAreReady() {
      return this.dashboardBlock
    },
    selectedTechnology() {
      return this.regionalProduction[this.technology] || []
    },
    dashboardBlockList() { // we need the list block to know which table to use for filtering
      if (!this.dashboardBlock.kind_settings.list_id) return;

      return DashboardBlock.find(this.dashboardBlock.kind_settings.list_id);
    },
    dashboardBlockListTable() {
      if (!(this.settingsAreReady && this.dashboardBlockList)) return;

      return this.getTableById(this.dashboardBlockList.kind_settings.table_id);
    },
    selectedRecordIds() {
      if (!this.dashboardBlockListTable) return;

      const items = DashboardSharedItem.listItems(this.dashboardBlockList.id, true).
                                        where('metadata', value => value.checked).get();
      return map(items, 'item_id');
    },
  },
  watch: {
    'periodDates': function (newPeriodDates, _oldPeriodDates) {
      if (!newPeriodDates) return;
      this.loadRegions();
    },
    'selectedRecordIds': function (newRecordIds, oldRecordIds) {
      if(this.settingsAreReady && !isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) {
        this.loadRegions();
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadRegions();
    }, 10);
  },
  methods: {
    setTechnology(technology) {
      this.technology = technology
    },
    loadRegions() {
      if (this.periodDates.length > 0) {
        return api.dashboardBlocks.regionalMap(this.dashboardBlock.id, this.selectedRecordIds, this.periodDates).then((response) => {
          this.xlsxUrl = response.data.xlsx_url;
          this.regionalProduction = response.data.regional_production;
        })
      }
    }
  },
}
</script>
