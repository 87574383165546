<template lang="pug">
  section.my-3
    label.font-weight-bolder {{ $t('editOptions.number') }}
    v-select(
      :input-id="'edit-unit-type' + editedField.id"
      appendToBody
      :searching="true"
      :options="unitList"
      :get-option-label="option => $t('units.' + option + '.name')"
      :get-option-key="option => option"
      :placeholder="$t('units.null.name')"
      :disabled="!canEditField"
      v-model="editedField.typeOptions.unit_type"
    )
      template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}
</template>

<script>
import { mapState } from 'vuex';
  export default {
    props: {
      editedField: {
        type: Object,
        required: true
      },
      canEditField: {
        type: Boolean,
        required: true
      },
    },
    data() {
     return {
        unitList: [null, 'percentage', 'euro', 'kilowattHour', 'megawattHour', 'eurosPerMegawattHour', 'megaWatt', 'kiloWatt', 'kiloWattPeak', 'hours', 'minutes', 'seconds'],
      };
    },
  }
</script>
