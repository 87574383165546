import { compact }                         from 'lodash'
import { dataTypesToStringOperations }     from '../services/dataTypesToStringOperations';
import { dataTypesSetValueCellOperations } from '../services/dataTypesSetValueCellOperations';
import { selectEntryService }              from '../services/selectEntryService';
import User                                from './user';

export default class Entry {
  constructor(value, record, field) {
    this.record      = record;
    this.field       = field;
    this._value      = value;
    this.previousValue = undefined; // in case we'll need Vue to observe it
    this.status      = "read";
    this.accessRight = field.accessRight;
    this.isReadOnly  = field.isReadOnly;
    this.isWritable  = !this.isReadOnly;

    if (record.locked) {
      this.setAccessRight('read');
    }

    this.buildDisplayValue();
  }

  set value(newValue) {
    this.previousValue = this.value;
    return this._value = newValue;
  }

  get value() {
    return this._value;
  }

  restorePreviousValue() {
    this._value = this.previousValue;
    this.previousValue = undefined;
  }

  setAccessRight(accessRight) {
    this.accessRight = accessRight;
    this.isReadOnly  = accessRight === 'read';
    this.isWritable  = !this.isReadOnly;

    if (this.isReadOnly) this.status = 'read';
  }

  restoreAccessRight() {
    this.setAccessRight(this.field.accessRight);
  }

  toString() {
    return dataTypesToStringOperations[this.field.dataType](this);
  }

  clearCell() {
    this.value = this.field.defaultEntryValue();
  }

  setValue(newValue) {
    return dataTypesSetValueCellOperations[this.field.dataType](this, newValue);
  }

  focusCell() {
    selectEntryService(this);
  }

  openCell() {
    const openEntryOperation = selectEntryService(this);
    openEntryOperation();
  }

  buildDisplayValue() {
    if (this.field.fieldType === 'lookups') this.displayValue = this.buildLookupsDisplayValue() || [];
    if (this.field.dataType === 'users')    this.displayValue = this.buildUsersDisplayValue(this.value);
  }

  buildLookupsDisplayValue() {
    if (this.field.fieldType !== 'lookups' || !this.value) return;

    return compact(this.value.map((foreignEntry) => {
      if (this.field.typeOptions.lookups_field_data_type === 'users') {
        const usersDisplayValue = this.buildUsersDisplayValue(foreignEntry.foreign_entry_value);

        return usersDisplayValue.map((userDisplayValue) => userDisplayValue.displayName);
      } else {
        return foreignEntry.foreign_entry_display_value;
      }
    }).flat());
  }

  buildUsersDisplayValue(value) {
    let usersDisplayValue = [];

    value.forEach((userId) => {
      const user = this.buildUserDisplayValue(userId);

      if (user) usersDisplayValue.push(user);
    });

    return usersDisplayValue;
  }

  buildUserDisplayValue(userId) {
    const user = User.find(userId);
    if (!user) return;

    return { id: user.id, displayName: user.displayName }
  }
};
