import { each, debounce } from 'lodash';
import { api }            from '../../api/client';

export const namespaced = true;

export const state = {
  recordIdsForPresenceCheck: new Set
}

export const actions = {
  pushRecordIdForPresenceCheck({ state, dispatch }, { recordId }) {
    state.recordIdsForPresenceCheck.add(recordId);
    dispatch('checkRecordsPresence');
  },
  checkRecordsPresence: debounce(function({ state, rootState, rootGetters }) {
    // is needed to filter records after they have been updated by websocket
    if (state.recordIdsForPresenceCheck.size) {
      api.records.presences({
        viewId:          rootState.viewStore.view.id,
        recordIds:       Array.from(state.recordIdsForPresenceCheck),
        filters:         JSON.stringify(rootState.viewStore.appliedQuery.filters),
        logicalOperator: rootState.viewStore.appliedQuery.logicalOperator,
      }).then(({ data: recordIds }) => {
        each(recordIds, (visible, recordId) => {
          recordId     = Number.parseInt(recordId, 10);
          const record = rootGetters['recordStore/allRecordsAvailable'].find((record) => {
            return record.id === recordId
          });
          if (record) {
            const row = rootState.viewStore.view.rowOrder.find(row => row.id === recordId);
            setTimeout(() => {
              record.visible = visible;
              if (row) row.visible = visible;
            }, 800);
          }
          state.recordIdsForPresenceCheck.delete(recordId);
        });
      });
    }
  }, 300, { leading: true })
};
