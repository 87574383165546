import { apiClient, startFetch, endFetch } from '../client';

export default {
  load({ tableId, withViewData = null, viewId = null, skipLoader = false }) {
    var loader;
    if (!skipLoader) { loader = startFetch() };
    return apiClient.get('/tables/' + tableId + '/load', {
      params: { with_view_data: withViewData, view_id: viewId }
    }).then((response) => {
        if (loader) return endFetch(loader)(response);
        return response;
      });
  },
  updateName({ tableId, tableName }) {
    const saver = startFetch('SAVING');
    return apiClient.patch('/tables/' + tableId, { table: { name: tableName } }).then(endFetch(saver));
  },
  recordsForReference({ tableId, recordIds = null, recordId = null, viewId }) {
    const showParam = recordId ? `/${recordId}` : ''
    return apiClient.get(
      '/tables/' + tableId  + '/records_for_references' + showParam,
      {
        params: {
          record_ids: recordIds,
          view_id:    viewId
        }
      }
    )
  },
  createRecord({ tableId, viewId, defaultValuesByFieldId }) {
    const saver = startFetch('SAVING');
    return apiClient.post('/tables/' + tableId + '/records', {
      default_values_by_field_id: defaultValuesByFieldId,
      view_id:                    viewId
    }).then(endFetch(saver))
  },
  saveFilters({ tableId, kind, filters, logicalOperator, profileId }) {
    const saver = startFetch('SAVING');
    return apiClient.patch(
      '/tables/' + tableId + '/save_filters',
      {
        kind,
        filters,
        logical_operator: logicalOperator,
        profile_id:       profileId
      }
    ).then(endFetch(saver))
  },
  lockedRecords({ tableId, recordIds }) {
    return apiClient.get(
      '/tables/' + tableId + '/locked_records',
      { params: { record_ids: recordIds } }
    )
  },
  delete({ tableId }) {
    const saver = startFetch('SAVING');
    return apiClient.delete('/tables/' + tableId).then(endFetch(saver))
  },
  index() {
    return apiClient.get(`api/tables`);
  },
  updateRecordValidations({ tableId, recordValidations }) {
    return apiClient.patch(`/api/tables/${tableId}/record_validations`, {
      table: {
        record_validations: recordValidations
      }
    });
  }
}
