<template lang="pug">
  .mt-3
    h2 {{ $t('automations.labels.source') }}
    .form-group
      label {{ $t('automations.labels.module_number') }}
      v-select(
        :options="moduleInputPositions"
        label="name"
        v-model="moduleInputPosition"
      )

    h2.mt-4 {{ $t('automations.labels.unavailability_table') }}
    .form-group
      label {{ $t("automations.labels.unavailability_table_id") }}
      v-select.bg-white(
        :options="tables"
        label="name"
        :reduce="o => o.id"
        v-model="unavailabilityTableId"
        @option:selected="loadUnavailabilityFields"
      )

    template(v-if="unavailabilityTableId")
      .form-group
        label
          | {{ $t("automations.labels.concerned_field_ids") }}
        v-select.bg-white(
          :options="unavailabilityFields"
          :multiple="true"
          label="name"
          :reduce="f => f.id"
          :close-on-select="false"
          :clear-search-on-select="false"
          :clear-search-on-blur="() => true"
          v-model="concernedFieldIds"
        )

      .form-group
        label
          | {{ $t("automations.labels.filename_field_id") }}
        v-select.bg-white(
          :options="unavailabilityFields"
          label="name"
          :reduce="f => f.id"
          v-model="filenameFieldId"
        )

    .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
      button.btn.btn-primary(
        @click.stop="updateAutomationModule"
      ) {{ $t('save') }}
</template>

<script>
import { api } from '../../../../api/client';
import Field from '../../../../models/field';
import Table from '../../../../models/table';

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    automationModulePosition: {
      type: Number,
      required: true,
    },
    configParamsProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tables: [],
      moduleInputPositions: [],
      moduleInputPosition: null,
      unavailabilityTableId: null,
      unavailabilityFields: [],
      concernedFieldIds: [],
      filenameFieldId: null,
    }
  },
  created() {
    this.moduleInputPosition = this.configParamsProp.module_input_position || 1;
    this.moduleInputPositions = Array.from({ length: this.automationModulePosition - 1 }, (_, i) => i + 1);
    api.tables.index().then(this.buildTables);
    if (this.configParamsProp) {
      this.unavailabilityTableId = this.configParamsProp.unavailability_table_id;
      this.concernedFieldIds = this.configParamsProp.concerned_field_ids || [];
      this.filenameFieldId = this.configParamsProp.filename_field_id;
    }
    if (this.unavailabilityTableId) {
      this.loadUnavailabilityFields({ id: this.unavailabilityTableId });
    }
  },
  computed: {
    form() {
      return {
        automationModuleId: this.automationModuleId,
        configParams: {
          module_input_position: this.moduleInputPosition,
          unavailability_table_id: this.unavailabilityTableId,
          concerned_field_ids: this.concernedFieldIds,
          filename_field_id: this.filenameFieldId,
        }
      }
    },
    formIsValid() {
      const { configParams } = this.form
      return !!(
        this.form.automationModuleId &&
        configParams.module_input_position &&
        configParams.unavailability_table_id &&
        configParams.concerned_field_ids.length &&
        configParams.filename_field_id
      )
    },
  },
  methods: {
    buildTables({ data: tablesData }) {
      this.tables = Table.build(tablesData);
    },
    buildFields(target) {
      return ({ data: fieldsData }) => {
        this[target] = Field.build(fieldsData);
      }
    },
    loadUnavailabilityFields({ id: tableId }) {
      api.fields.index({ tableId }).then(this.buildFields("unavailabilityFields"))
    },
    updateAutomationModule() {
      if (!this.formIsValid) return;

      api.automationModules.update(this.form);
    },
  }
}
</script>
