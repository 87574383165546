import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["element", "trigger"];

  scrollTop() {
    this.currentScroll = this.elementTarget.scrollTop;
    this.elementTarget.scrollTop = 0;
  }

  scrollBack() {
    this.elementTarget.scrollTop = this.currentScroll;
  }

  triggerTargetConnected() {
    this.scrollTop();
  }

  triggerTargetDisconnected() {
    this.scrollBack();
  }
}
