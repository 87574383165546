<script>
import { isEqual }        from 'lodash';
import { Bar }            from 'vue-chartjs';
import { tailwindColors } from '../../services/tailwindColors';
import { EventBus }       from '../../main';

export default {
  extends: Bar,
  props: {
    chartdata: {
      type:     Object,
      required: true,
    },
    stacked: {
      type: Boolean,
      required: true,
    },
    dashboardBlockId: {
      type: Number,
      required: true
    },
    prettyDate: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      timeFormat: {
        displayFormats: {
          hour:  'k:mm',
          day:   'DD/MM',
          month: 'DD/MM'
        }
      }
    }
  },
  computed: {
    yAxes() {
      return this.yLabelStringArray.map((yLabel, index) => {
        return {
            offset: false,
            stacked: this.stacked,
            position: ['left', 'right'][index % 2],
            id: `y-axis-${yLabel}`,
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 8,
              padding: -5,
              callback: this.yAxesTickCallback
            },
            gridLines: {
              display: true,
            },
            scaleLabel: {
              display: true,
              labelString: yLabel,
              padding: 6,
              fontSize: 15,
              fontStyle: 'bold'
            }
          }
      })
    },
    options() {
      return {
        legendCallback: this.legendCallback,
        scales: {
          xAxes: [
            {
              stacked: this.stacked,
              maxBarThickness: 60,
              distribution: 'series',
              offset: true,
              ticks: {
                beginAtZero: true,
                autoSkip: true,
                source: 'data',
                fontSize: 12,
                fontColor:tailwindColors.gray700,
                maxTicksLimit: 21,
                major: {
                  enabled: true,
                  fontStyle: 'bold',
                },
              },
              type: this.prettyDate ? 'time' : undefined,
              time: this.prettyDate ? this.timeFormat : {},
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: this.chartdata.xlabelString,
                padding: 10,
                fontSize: 15,
                fontStyle: 'bold'
              }
            }
          ],
          yAxes: this.yAxes,
        },
        elements: {
          rectangle: {
            backgroundColor: tailwindColors.blue500,
            hoverBackgroundColor: tailwindColors.purple800,
          }
        },
        tooltips: {
          position: 'nearest',
          mode: 'index',
          xPadding: 12,
          yPadding: 12,
          intersect: false,
          backgroundColor: 'rgb(255, 255, 255)',
          bodyFontColor: 'black',
          bodyFontStyle: 'bold',
          bodyFontSize: 12,
          borderColor: tailwindColors.gray500,
          borderWidth: '1',
          titleFontColor: tailwindColors.gray800,
          footerFontColor: tailwindColors.gray900,
          titleFontStyle: 'normal',
          footerFontStyle: 'normal',
          displayColors: false,
          callbacks: {
            title: this.tooltipTitleCallback,
            label: this.tooltipLabelCallback,
            footer: this.tooltipFooterCallback
          }
        },
        legend: {
          display: false,
          position: 'bottom',
          align: 'center',
          labels: {
            generateLabels: this.generateLabels
          }
        },
        responsive: true,
        maintainAspectRatio: false
      }
    },
    yLabelStringArray() {
      if (Array.isArray(this.chartdata.ylabelString)) {
        return this.chartdata.ylabelString
      }

      return Array.of(this.chartdata.ylabelString)
    },
  },
  watch: {
    'chartdata': function (newChartdata, oldChartdata) {
      if (!isEqual(newChartdata, oldChartdata)) {
        this.renderChart(newChartdata, this.options);
        this.$emit('sendLegend', this.generateLegend());
      }
    }
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
    this.$emit('sendLegend', this.generateLegend());

    EventBus.$on(`toggleSerie${this.dashboardBlockId}`, (item) => {
      const index = item.datasetIndex;
      const meta = this.chartdata.datasets[index]._meta;
      const hidden = meta[Object.keys(meta)[0]].hidden;

      meta[Object.keys(meta)[0]].hidden = hidden ? null : true;

      this.$data._chart.update();
    });
  },
  beforeDestroy() {
    EventBus.$off(`toggleSerie${this.dashboardBlockId}`);
  },
  methods: {
    legendCallback(chart) {
      return chart.legend.legendItems;
    },
    yAxesTickCallback(value) {
      return Intl.NumberFormat(this.$i18n.locale).format(value);
    },
    tooltipTitleCallback(tooltipItems) {
      var date = new Date(tooltipItems[0].label);

      // handle not date label
      if (isNaN(date)) {
        return tooltipItems[0].label;
      } else {
        var dateOptions     = { month: 'long', day: 'numeric' };
        var dateTimeOptions = { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit'};

        if (date.getHours() == 0 && date.getMinutes() == 0 && date.getSeconds() == 0) {
          return date.toLocaleDateString(this.$i18n.locale, dateOptions);
        } else {
          return date.toLocaleDateString(this.$i18n.locale, dateTimeOptions);
        }
      }
    },
    tooltipLabelCallback(tooltipItem, data) {
      var unit  = data.datasets[tooltipItem.datasetIndex].unit;
      var label = data.datasets[tooltipItem.datasetIndex].label || '';

      if (label) {
        label += ' : ' + Intl.NumberFormat(this.$i18n.locale).format(tooltipItem.yLabel) + ' ' + unit;
      }

      return label;
    },
    tooltipFooterCallback(tooltipItems, data) {
      if (this.stacked) {
        const unit  = data.datasets[0].unit;
        return 'Total : ' + tooltipItems.reduce((sum, tooltipItem) => Math.round(sum + parseFloat(tooltipItem.value, 10)), 0) + ' ' + unit;
      }
    },
    generateLabels(chart) {
      return chart.data.datasets.map((dataset, i) => {
        return {
          datasetIndex: i,
          text:         dataset.label,
          fillStyle:    dataset.backgroundColor,
          strokeStyle:  dataset.borderColor,
          lineDash:     dataset.borderDash,
          lineWidth:    dataset.borderWidth,
          hidden:       false
        };
      });
    },
  }
}
</script>
