<template lang="pug">
div
  .record-filters--wrapper(
    :style="{ 'overflow-y': editableFilters.length < 10 ? 'visible' : 'scroll' }"
  )
    slot(name="tableFilters")
    p.record-filters--no-filters.mb-0.font-italic.pl-1(
      v-if="editableFilters.length === 0"
    ) {{ $t('controlBarActions.noFilter') }}

    .record-filters--filter.my-2(v-for="filter, index in editableFilters")
      .record-filters--remove-filter.mr-1.cursor-pointer(@click.stop="removeFilter(index)")
        i.fal.fa-times

      .record-filters--label.text-left
        .pl-2.text-nowrap(v-if="index === 0")
          | {{ $t('controlBarActions.filterBy') }}
        .px-3(v-else-if="index === 1")
          .logical-operator-select(
            v-click-outside="closeLogicalOperatorSelect"
          )
            .logical-operator-select--input.pl-2(@click="showLogicalOperatorSelect = !showLogicalOperatorSelect")
              span {{ $t(editableLogicalOperator) }}
              i.far.fa-chevron-down.logical-operator-select--chevron.ml-3
            .logical-operator-select--options(
                v-show="showLogicalOperatorSelect"
              )
              span(@click="selectLogicalOperator('and')") {{ $t('and') }}
              span(@click="selectLogicalOperator('or')") {{ $t('or') }}
        .px-3(v-else)
          .pl-2
            | {{ $t(editableLogicalOperator) }}

      v-select.filter-input-fixed-width.mx-2.flex-shrink-0(
        :searching="true"
        :options="filterableFields"
        :get-option-label="option => option.name"
        :get-option-key="option => option.id"
        :value="filter.field"
        :clearable="false"
        :append-to-body="true"
        @input="changeFilterField(filter, $event)"
      )
        template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}

      component(
        :is="`filter-input-${filter.dataType}`"
        :filter="filter"
      )
        v-select.filter-input-fixed-width.mr-2.flex-shrink-0.p-0(
          v-if="filter.field"
          :searching="true"
          :options="operatorsByDataType[filter.dataType]"
          :reduce="option => option.operator"
          :get-option-label="option => option.name"
          :get-option-key="option => option.operator"
          :value="filter.operator"
          :clearable="false"
          :append-to-body="true"
          @input="changeFilterOperator(filter, $event)"
        )
          template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}

  .record-filters--footer.py-2
    p.record-filters--add-filter.cursor-pointer.mb-0.mr-3(@click="addFilter") + {{ $t('controlBarActions.addFilter') }}
    .spinner-border.spinner-border-sm.text-danger(v-if="loadingRecords" role="status")
      span.sr-only {{ $t('loading') + "..." }}
  .record-filters--footer.pt-2.mt-2.border-top.border-light(v-if="queryChanged")
    a.text-secondary.mr-3.cursor-pointer(@click.stop.prevent="restoreFilters") {{ $t('controlBarActions.restoreFilters') }}
    button.btn.btn-primary(
      v-if="filterCanBeSaved"
      @click.stop="$emit('save-query', currentQueryPayload)"
      :disabled="!queryStringCanBeApplied"
    ) {{ $t('save') }}
</template>

<script>
import { operatorsByDataType } from '../services/filterOperators';
import { RecordFiltersMixin } from '../mixins/RecordFiltersMixin';

export default {
  mixins: [RecordFiltersMixin],
  data() {
    return {
      editableFilters:                [],
      inputlessOperators:             ['empty', 'not empty', 'today', 'is past', 'is not past', 'contains today', 'contains now'],
      operatorsByDataType:            operatorsByDataType,
      editableLogicalOperator:        '',
      showLogicalOperatorSelect:      false,
      filtersDefaultValuesbyDataType: {
        boolean: false
      }
    }
  },
  computed: {
    currentQueryPayload() {
      const filtersPayload = this.editableFilters.map((filter) => {
        return {
          field_id: filter.field.id,
          operator: filter.operator,
          value:    filter.value
        }
      });
      return {
        logicalOperator: this.editableLogicalOperator,
        filters:         filtersPayload
      }
    },
  },
  methods: {
    filterAttributes(field) {
      const dataType = this.operatorDataType(field);
      const value    = this.filterDefaultValue(dataType);
      const operator = operatorsByDataType[dataType][0].operator;

      return { dataType, value, operator, field };
    },
    selectLogicalOperator(operator) {
      this.editableLogicalOperator = operator;
      this.closeLogicalOperatorSelect();
    },
    closeLogicalOperatorSelect() {
      this.showLogicalOperatorSelect = false;
    },
    restoreFilters() {
      this.buildEditableQuery(this.originalQuery);
    },
    buildEditableQuery(query) {
      this.editableLogicalOperator = query.logicalOperator;

      this.editableFilters = query.filters.map((this.buildFilter));
    },
  }
}
</script>
