import "bootstrap";
import "@fortawesome/fontawesome-pro/css/all.css"
import "animate.css";
import "./controllers/index";
import "@hotwired/turbo-rails";

Turbo.start();

import { initVueApp, EventBus } from "./vue/main";
import { initProfileForm } from './components/profile_form';
import { initBootstrapTooltips, initBootstrapTabs } from './components/bootstrap_components';
import { initColorPicker } from './components/color_picker';
import { initCreateUserForm } from './components/create_user_form';
import { initDeleteBtnAlert } from './components/delete_btn_alert';
import { initScenarioRunningCheckboxes } from './components/toggle_automation_scenarios';
import { refreshNotificationBadgeDisplay, initNotifications } from './components/base_notifications';

EventBus.$on('toggleAlert', refreshNotificationBadgeDisplay);

document.addEventListener("turbo:load", () => {
  initBootstrapTooltips();
  initBootstrapTabs();
  initVueApp();
  initProfileForm();
  initColorPicker();
  initCreateUserForm();
  initDeleteBtnAlert();
  initScenarioRunningCheckboxes();
  initNotifications();
})
