<template lang="pug">
  .mt-3
    .form-group
      label {{ $t('automations.labels.table') }}
      v-select(
        :searching="true"
        :options="tablesProp"
        label="name"
        :reduce="option => option.id"
        v-model="tableId"
      )

    .form-group
      v-select(
        :options="[{ name: 'Tous les champs', value: true }, { name: 'Personnalisé', value: false }]"
        label="name"
        :reduce="option => option.value"
        v-model="allFields"
      )

    .form-group(v-if="!allFields")
      label {{ $t('automations.labels.fields') }}
      div(v-for="field in fields")
        input(
          type="checkbox"
          :value="field.id"
          :id="'field-' + field.id"
          v-model="fieldIds"
        )
        label.ml-2(:for="'field-' + field.id") {{ field.name }}

    .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
      button.btn.btn-primary(
        @click.stop="updateAutomationModule"
        :disabled="!tableId"
      ) {{ $t('save') }}
</template>

<script>
import { api } from '../../../../api/client';
import Field   from '../../../../models/field';

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    tablesProp: {
      type: Array,
      required: true,
    },
    tableIdProp: {
      type: Number,
    },
    configParamsProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableId:    null,
      fields:     [],
      fieldIds:   [],
      showFields: false,
      allFields:  false,
    }
  },
  watch: {
    tableId: function(newTableId, oldTableId) {
      if(oldTableId !== newTableId) this.loadTableSchema();
    },
  },
  created() {
    this.loadTableSchema();
    this.tableId   = this.tableIdProp;
    this.allFields = this.configParamsProp.all_fields === undefined ? true : this.configParamsProp.all_fields;
    this.fieldIds  = this.configParamsProp.field_ids === undefined ? [] : this.configParamsProp.field_ids;
  },
  methods: {
    loadTableSchema() {
      if (!this.tableId) return;

      api.fields.index({
        tableId: this.tableId,
      }).then((response) => {
        this.showFields = false
        setTimeout(() => {
          this.fields     = Field.build(response.data);
          this.showFields = true;
        });
      });
    },
    updateAutomationModule() {
      if (this.allFields) this.fieldIds = [];

      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        kind:               'when_record_updated',
        tableId:            this.tableId,
        configParams:       { field_ids: this.fieldIds, all_fields: this.allFields },
      });
    },
  }
}
</script>
