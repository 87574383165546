<template lang="pug">
.mt-3
  h2 {{ $t('automations.labels.source') }}
  .form-group
    label {{ $t('automations.labels.module_number') }}
    v-select(
      label="name"
      :options="moduleInputPositions"
      v-model="moduleInputPosition"
    )

  h2.mt-4 {{ $t("automations.labels.concerned_period") }}
  .form-group.ml-4
    label {{ $t('automations.labels.start_date') }}
    .d-flex
      .form-group
        v-select.automation-module--day-number-input(
          :options="selectedStartDayNumbers"
          label="name"
          :reduce="option => option.value"
          v-model="selectedStartDayNumber"
        )

      .form-group.ml-2
        v-select.automation-module--month-input(
          :options="months"
          label="name"
          :reduce="option => option.value"
          v-model="selectedStartMonth"
        )

      .form-group.ml-2
        v-select.automation-module--year-input(
          :options="relative_years"
          label="name"
          :reduce="option => option.value"
          v-model="selectedStartRelativeYear"
        )

  .form-group.ml-4
    label {{ $t('automations.labels.end_date') }}
    .d-flex
      .form-group
        v-select.automation-module--day-number-input(
          :options="selectedEndDayNumbers"
          label="name"
          :reduce="option => option.value"
          v-model="selectedEndDayNumber"
        )

      .form-group.ml-2
        v-select.automation-module--month-input(
          :options="months"
          label="name"
          :reduce="option => option.value"
          v-model="selectedEndMonth"
        )

      .form-group.ml-2
        v-select.automation-module--year-input(
          :options="relative_years"
          label="name"
          :reduce="option => option.value"
          v-model="selectedEndRelativeYear"
        )

  .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
    button.btn.btn-primary(
      type="button"
      :disabled="!formIsValid"
      @click.stop="updateAutomationModule"
    ) {{ $t('save') }}
</template>

<script>
import { api } from '../../../../api/client';
import Field from '../../../../models/field';
import I18n from "../../../../locales/locales"

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    automationModulePosition: {
      type: Number,
      required: true,
    },
    configParams: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      moduleInputPositions: [],
      moduleInputPosition: null,
      selectedStartDayNumber: null,
      selectedStartMonth: null,
      selectedStartRelativeYear: null,
      selectedEndDayNumber: null,
      selectedEndMonth: null,
      selectedEndRelativeYear: null,
      months: [
        { name: I18n.t("months.january"), value: 1, dayNumbers: 31 },
        { name: I18n.t("months.february"), value: 2, dayNumbers: 29 },
        { name: I18n.t("months.march"), value: 3, dayNumbers: 31 },
        { name: I18n.t("months.april"), value: 4, dayNumbers: 30 },
        { name: I18n.t("months.may"), value: 5, dayNumbers: 31 },
        { name: I18n.t("months.june"), value: 6, dayNumbers: 30 },
        { name: I18n.t("months.july"), value: 7, dayNumbers: 31 },
        { name: I18n.t("months.august"), value: 8, dayNumbers: 31 },
        { name: I18n.t("months.september"), value: 9, dayNumbers: 30 },
        { name: I18n.t("months.october"), value: 10, dayNumbers: 31 },
        { name: I18n.t("months.november"), value: 11, dayNumbers: 30 },
        { name: I18n.t("months.december"), value: 12, dayNumbers: 31 },
      ],
      relative_years: [
        { name: I18n.t("relative_years.last_year"), value: -1 },
        { name: I18n.t("relative_years.current_year"), value: 0 },
        { name: I18n.t("relative_years.next_year"), value: 1 },
      ],
    }
  },
  watch: {
    selectedStartDayNumber: function(newSelectedStartDayNumber, oldSelectedStartDayNumber) {
      if (!newSelectedStartDayNumber || newSelectedStartDayNumber === oldSelectedStartDayNumber) return;

      this.checkAndCorrectSelectedEndRelativeYear();
    },
    selectedStartMonth: function(newSelectedStartMonth, oldSelectedStartMonth) {
      if (!newSelectedStartMonth || newSelectedStartMonth === oldSelectedStartMonth) return;

      this.checkAndCorrectSelectedEndRelativeYear();

      if (this.selectedStartDayNumber <= this.months[newSelectedStartMonth - 1].dayNumbers) return;

      this.selectedStartDayNumber = 1;
    },
    selectedStartRelativeYear: function(newSelectedStartRelativeYear, oldSelectedStartRelativeYear) {
      if (![-1, 0, 1].includes(newSelectedStartRelativeYear) || newSelectedStartRelativeYear === oldSelectedStartRelativeYear) return;

      this.checkAndCorrectSelectedEndRelativeYear();
    },
    selectedEndDayNumber: function(newSelectedEndDayNumber, oldSelectedEndDayNumber) {
      if (!newSelectedEndDayNumber || newSelectedEndDayNumber === oldSelectedEndDayNumber) return;

      this.checkAndCorrectSelectedEndRelativeYear();
    },
    selectedEndMonth: function(newSelectedEndMonth, oldSelectedEndMonth) {
      if (!newSelectedEndMonth || newSelectedEndMonth === oldSelectedEndMonth) return;

      this.checkAndCorrectSelectedEndRelativeYear();

      if (this.selectedEndDayNumber <= this.months[newSelectedEndMonth - 1].dayNumbers) return;

      this.selectedEndDayNumber = 1;
    },
    selectedEndRelativeYear: function(newSelectedEndRelativeYear, oldSelectedEndRelativeYear) {
      if(![-1, 0, 1].includes(newSelectedEndRelativeYear) || newSelectedEndRelativeYear === oldSelectedEndRelativeYear) return;

      this.checkAndCorrectSelectedEndRelativeYear();
    },
  },
  created() {
    this.moduleInputPosition = this.configParams.module_input_position || 1;
    this.selectedStartDayNumber = this.configParams.start_day;
    this.selectedStartMonth = this.configParams.start_month;
    this.selectedStartRelativeYear = this.configParams.start_relative_year;
    this.selectedEndDayNumber = this.configParams.end_day;
    this.selectedEndMonth = this.configParams.end_month;
    this.selectedEndRelativeYear = this.configParams.end_relative_year;

    this.moduleInputPositions = Array.from({ length: this.automationModulePosition - 1 }, (_, i) => i + 1);
  },
  computed: {
    selectedStartDayNumbers() {
      return this.monthDayNumbers(this.selectedStartMonth);
    },
    selectedEndDayNumbers() {
      return this.monthDayNumbers(this.selectedEndMonth);
    },
    form() {
      return {
        automationModuleId: this.automationModuleId,
        configParams: {
          module_input_position: this.moduleInputPosition,
          start_day: this.selectedStartDayNumber,
          start_month: this.selectedStartMonth,
          start_relative_year: this.selectedStartRelativeYear,
          end_day: this.selectedEndDayNumber,
          end_month: this.selectedEndMonth,
          end_relative_year: this.selectedEndRelativeYear,
        }
      }
    },
    formIsValid() {
      const { configParams } = this.form;
      const customDatesPeriodIsValid = (
        configParams.module_input_position &&
        configParams.start_day &&
        configParams.start_month &&
        [-1, 0, 1].includes(configParams.start_relative_year) &&
        configParams.end_day &&
        configParams.end_month &&
        [-1, 0, 1].includes(configParams.end_relative_year)
      );

      return !!(this.form.automationModuleId && customDatesPeriodIsValid);
    }
  },
  methods: {
    buildFields(target) {
      return ({ data: fieldsData }) => {
        this[target] = Field.build(fieldsData);
      }
    },
    checkAndCorrectSelectedEndRelativeYear() {
      const currentYear = new Date().getFullYear();
      const currentStartYear = currentYear + this.selectedStartRelativeYear;
      const currentEndYear = currentYear + this.selectedEndRelativeYear;
      const currentStartDate = new Date(currentStartYear, this.selectedStartMonth, this.selectedStartDayNumber);
      const currentEndDate = new Date(currentEndYear, this.selectedEndMonth, this.selectedEndDayNumber);

      if (currentStartDate <= currentEndDate) return;

      this.selectedEndRelativeYear = null;
    },
    updateAutomationModule() {
      if (!this.formIsValid) return;

      api.automationModules.update(this.form);
    },
    monthDayNumbers(monthValue) {
      const dayNumbers = monthValue ? this.months[monthValue - 1].dayNumbers : 31

      return [...Array(dayNumbers).keys()].map(dayIndex => {
        const dayNumber = dayIndex + 1;
        return { name: ("0" + dayNumber).slice(-2), value: dayNumber };
      });
    },
  }
}
</script>
