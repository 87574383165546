import { Model } from '@vuex-orm/core';

export default class User extends Model {
  static entity = 'users';

  static fields () {
    return {
      id:         this.attr(null),
      email:      this.attr(''),
      first_name: this.attr(''),
      last_name:  this.attr(''),
    }
  }

  get full_name() {
    return `${this.first_name} ${this.last_name}`.trim();
  }

  get displayName() {
    return this.first_name && this.last_name ? this.full_name : this.email;
  }

  static buildCurrentUser(userData) {
    return {
      id: userData.id,
      profileId: userData.profile_id,
      admin: userData.admin,
      superAdmin: userData.super_admin,
    }
  }
};
