<template lang="pug">
.side-menu--list-item.d-flex.justify-content-between(
  :class="{ \
    selected:              isCurrentItem, \
    'options-menu-opened': optionMenuOpened \
  }"
  :style="{ \
    backgroundColor: isCurrentItem ? projectSecondaryColors.normal : '', \
    paddingLeft:     depthIndentation \
  }"
)
  .d-flex.align-items-center.flex-grow-1.text-truncate(
    @click.prevent="onClick"
  )
    i.fa-fw.mx-2.text-white(:class="iconClass")
    input.edit-name(
      v-focus
      v-if="editName"
      :value="newName"
      @blur="quitRenameMode"
      @change="updateItemName"
      @keyup.enter="blurInput"
    )
    span.text-truncate.has-tooltip.pr-2(
      v-else
      :title="itemName"
      data-toggle="tooltip"
      data-placement="right"
      :class="{ [translationFallbackClasses]: nameFallback }"
    )
      | {{ itemName }}
  base-tree-item-options-menu(
    v-if="feature.canCreateResource"
    :tree-item="treeItem"
    @rename-item="enterRenameMode"
    @delete-item="confirmDeleteItem"
    :disableRename="disableRename"
    :disableDelete="disableDelete"
    @pop-action:open="optionMenuOpened = true"
    @pop-action:close="optionMenuOpened = false"
  )
  slot(
    v-if="feature.canCreateResource"
    :setOptionMenuOpened="setOptionMenuOpened"
  )
</template>

<script>
import { mapState }            from 'vuex';
import TreeItem                from '../../../models/treeItem';
import BaseTreeItemOptionsMenu from './BaseTreeItemOptionsMenu';
import { TreeItemMixin }       from '../../../mixins/TreeItemMixin';

export default {
  name: 'base-tree-item',
  components: {
    BaseTreeItemOptionsMenu
  },
  mixins: [TreeItemMixin],
  props: {
    onClick: {
      type: Function,
      required: true
    },
    itemName: {
      type: String,
      required: true
    },
    updateItemName: {
      type: Function,
      required: true
    },
    confirmDeleteItem: {
      type: Function,
      required: true
    },
    disableRename: {
      type: Boolean,
      default: false
    },
    disableDelete: {
      type: Boolean,
      default: false
    },
    isCurrentItem: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      default: ''
    },
    nameFallback: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      optionMenuOpened: false,
      editName:         false,
      newName:          ''
    }
  },
  computed: {
    ...mapState(['currentUser', 'feature', 'projectSecondaryColors', 'translationFallbackClasses']),
    depthIndentation() {
      return (1 + this.depth) + 'rem';
    }
  },
  watch: {
    isCurrentItem: function(isCurrentItem, wasCurrentItem) {
      if (!wasCurrentItem && isCurrentItem) {
        this.treeItem.openParentFolders();
      }
    }
  },
  methods: {
    blurInput({ target }) {
      target.blur();
    },
    setOptionMenuOpened(state) {
      this.optionMenuOpened = state;
    },
    enterRenameMode() {
      if (this.currentUser.admin) {
        TreeItem.commit(state => state.editingName = true);

        this.newName  = this.itemName
        this.editName = true;
      }
    },
    quitRenameMode() {
      TreeItem.commit(state => state.editingName = false);

      this.editName = false;
    }
  }
}
</script>
