<template lang="pug">
PopAction(:active="filtersAreApplied" btnClass="p-0")
  div(slot="trigger")
    i.far.fa-filter.fa-fw.pop-action-btn--icon
  .px-1.py-2(style="min-width: 200px;" slot="content")
    RecordFilters(
      :fields="fields"
      :loadingRecords="updatingList"
      :appliedQuery="query"
      :originalQuery="{ logicalOperator: 'and', filters: [] }"
      :showSaveButton="false"
      @query-payload-changed="queryPayloadChanged"
    )
</template>

<script>
import { isEqual,
         debounce,
         sortBy,
         find,
         map }       from 'lodash';
import { api }       from '../../api/client';
import RecordFilters from '../RecordFilters.vue';

export default {
  components: {
    RecordFilters
  },
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    table: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      query:             { logicalOperator: 'and', filters: [] },
      updatingList:      false,
      filtersAreApplied: false
    }
  },
  computed: {
    filteredFieldIds() {
      return this.dashboardBlock.kind_settings.filtered_field_ids || [];
    },
    fields() {
      const tableFields = sortBy(this.table.fields, 'name');

      if (this.filteredFieldIds.length) {
        return map(this.filteredFieldIds, (fieldId) => {
          return find(tableFields, ['id', fieldId]);
        });
      }
      return tableFields;
    }
  },
  methods: {
    async queryPayloadChanged(newQuery, filtersAreValid) {
      const queryChanged = !isEqual(this.query, newQuery);

      if (queryChanged) {
        this.query = newQuery;

        if (newQuery.filters.length === 0) {
          this.filtersAreApplied = false;
          return this.$emit('items-filtered', null);
        }

        if (filtersAreValid) {
          this.updatingList = true;
          this.applyFilters(newQuery);
        }
      }
    },
    applyFilters: debounce(function(query) {
      return api.records.filtered({ query, tableId: this.table.id }).then(response => {
        const { data: { visible_record_ids } } = response;

        this.$emit('items-filtered', visible_record_ids);

        this.updatingList      = false;
        this.filtersAreApplied = true;
      });
    }, 500, { leading: false, trailing: true })
  }
}
</script>
