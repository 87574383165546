<template lang="pug">
.base-modal-wrapper
  .base-modal.modal-mobile.overflow-auto
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")

    //- HEADER
    .base-modal-header.d-flex.align-items-center.mb-5
      span.bg-indigo-100.p-2.rounded-circle.mr-2(style="line-height: 1.3rem;")
        i.far.fa-eye-slash.fa-fw.text-indigo-800
      .text-xl.text-gray-800.font-weight-bolder {{ $t('controlBarActions.showHide') }}

    //- BODY
    .my-4.modal-body-mobile.overflow-auto
      draggable(
        :list="movableFields"
        @change="moveColumn"
        draggable=".draggable-field"
        :animation="150"
        :delay="100"
        ghostClass="sortable-custom-ghost"
        chosenClass="sortable-custom-chosen"
        dragClass="sortable-custom-drag"
      )
        .hoverable.mobile-larger.d-flex.align-items-center.draggable-field(
          v-for="field in movableFields"
          :key="field.id"
        )
          .ctrlbar-action--action-option
            input.mr-1(
              v-model="field.visible"
              type="checkbox"
              hidden="true"
              :name="field.id + '-visible'"
              :id="field.id + '-visible'"
              @change="updateViewField(field)"
            )
            label.ctrlbar-action--label.py-2(:for="field.id + '-visible'")
              span.ctrlbar-action--label-icon(:class="{ active: field.visible }")
                span.ctrlbar-action--label-icon-pill

              i.mr-1.fal.fa-sm.fa-fw(:class="'fa-'+ field.faIconName")
              span(
                :class="{ [translationFallbackClasses]: field.nameFallback }"
              ) {{ field.name }}
                i.my-auto.ml-2.far.fa-lock-alt.font-weight-lighter(
                  v-if="field.primary"
                  class="has-tooltip"
                  data-toggle="tooltip"
                  :title="$t('fields.primaryTooltip')"
                )
            .move-column-handle.text-secondary
              i.fas.fa-grip-vertical

    //- FOOTER
    .base-modal-footer
      .d-flex.pt-2
        .ctrlbar-action--btn.mr-2(@click="updateAllColumnVisibily(false)") {{ $t('views.hideAll') }}
        .ctrlbar-action--btn(@click="updateAllColumnVisibily(true)") {{ $t('views.showAll') }}
      .d-flex.justify-content-center.mt-3
        button.btn.default-btn(@click.stop="closeModal")
          span {{ $t('close') }}
</template>

<script>
import { api }              from '../../../api/client';
import { MobileModalMixin } from "./MobileModalMixin.js";
import { BaseModalMixin }   from "../BaseModalMixin";
import { MoveColumnMixin }  from '../../../mixins/MoveColumnMixin';
import { map }              from "lodash";
import draggable            from 'vuedraggable';
import { mapState } from 'vuex';

export default {
  mixins: [BaseModalMixin, MobileModalMixin, MoveColumnMixin],
  components: {
    draggable,
  },
  computed: {
    ...mapState(['translationFallbackClasses']),
    hideableFields() {
      return this.orderedFields.slice(1) // SKIP THE FIRST ONE AS IT IS USE AS FIXED FIRST COLUMN
    },
    movableFields() {
      return this.hideableFields;
    }
  },
  methods: {
    updateAllColumnVisibily(visibility) { // ALL AT ONCE
      this.hideableFields.forEach(field => field.visible = visibility)

      this.computeFieldsLeftOffset()

      api.viewFields.updateColumnsVisibility({
        viewId:     this.currentView.id,
        fieldIds:   map(this.hideableFields, 'id'),
        visibility: visibility
      })
    },
    updateViewField(field) {
      const viewField = { visible: field.visible };
      api.viewFields.update({ viewId: this.currentView.id, fieldId: field.id, viewField });
      this.computeFieldsLeftOffset()
    }
  }
};
</script>

<style lang="scss" scoped>
.mobile {
  position: absolute;
  top : 15px;
}
.mobile-larger {
  height: 10%;
}
</style>
