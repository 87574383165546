<template lang="pug">
  input.cell-content.input-cell.p-0(
    v-focus
    v-model.number="entry.value"
    type="string"
    autocomplete="off"
    @change="updateEntry(entry)"
    @blur="handleBlur"
    @keypress="isNumber"
    @keyup.stop.prevent.escape="entry.status = 'read'"
  )
</template>

<script>
  import { UpdateEntryMixin } from '../../../mixins/UpdateEntryMixin';

  export default {
    mixins: [UpdateEntryMixin],
    props: {
      entry: {
        type: Object,
        required: true
      }
    },
    methods: {
      handleBlur() {
        setTimeout(() => this.entry.status = "read");
      },
      isNumber(event) {
        if ((/[\d\.\-(Enter)]+/i).test(event.key)) return;

        event.preventDefault();
      }
    }
  }
</script>
