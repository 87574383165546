<template lang="pug">
PopAction(:active="!!appliedSorts.length" btnClass="p-0")
  div(slot="trigger")
    i.far.fa-sort-alt.fa-fw.pop-action-btn--icon
  .px-1.pb-2.pt-3(style="min-width: 200px;" slot="content")
    RecordSorts(
      :fields="fields"
      :appliedSorts="appliedSorts"
      :originalSorts="originalSorts"
      @sorts-payload-changed="handleSortUpdate"
    )
</template>

<script>
import { sortBy, isEqualWith } from 'lodash';
import RecordSorts from '../RecordSorts.vue';
import { api } from '../../api/client';

export default {
  components: {
    RecordSorts
  },
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    table: {
      type: Object,
      required: true
    },
     originalSorts: {
      type: Array,
      required: true
    },
    appliedSorts: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sorts: []
    }
  },
  computed: {
    filteredFieldIds() {
      return this.dashboardBlock.kind_settings.filtered_field_ids || [];
    },
    fields() {
      return sortBy(this.table.fields, 'name');
    }
  },
  methods: {
    handleSortUpdate(sortOrders) {
      const same = isEqualWith(this.originalSorts, sortOrders, (_a, _b, i) => {
        // this is basically: "don't compare ids and for the rest do by yourself"
        return i === "id" || undefined
      });

      if (same) {
        this.$emit('items-sortered', { sortOrders: this.originalSorts, sortedRecordsIds: null });
      } else {
        api.rowOrders.forTable({ tableId: this.table.id, sortOrders })
          .then(({ data: sortedRecordsIds }) => {
            this.$emit('items-sortered', { sortOrders, sortedRecordsIds });
          })
      }
    }
  }
}
</script>
