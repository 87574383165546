import { Model } from '@vuex-orm/core';

export default class TreeItem extends Model {
  static entity = 'tree_items';

  static state ()  {
    return {
      editingName: false
    }
  }

  static fields () {
    return {
      id: this.attr(null),
      name: this.string(''),
      type: this.string(''),
      itemId: this.attr(null),
      parent_id: this.attr(null),
      position: this.number(null),
      opened: this.boolean(false),
      children: this.hasMany(TreeItem, 'parent_id'),
      nameFallback: this.boolean(false),
    }
  }

  static updatePosition(id, parent_id, position) {
    this.api().patch(`api/tree_items/${id}/update_position/`, {
      tree_item: { parent_id, position }
    });
  }

  static apiConfig = {
    actions: {
      create(parent_id) {
        return this.post(`/api/tree_items`, {
          tree_item: { parent_id }
        });
      },
      update({ id, name = undefined, parent_id = undefined, position = undefined }) {
        return this.patch(`/api/tree_items/${id}`, {
          tree_item: { name, position }
        });
      },
      destroy(treeItemId) {
        return this.delete(`/api/tree_items/${treeItemId}`, {
          delete: treeItemId
        });
      }
    }
  }

  setFolderState({ opened }) {
    if(this.isFolder) {
      TreeItem.update({ where: this.id, data:  { opened } });

      if (opened) {
        localStorage.setItem(`folder_opened_${this.id}`, true);
      } else {
        localStorage.removeItem(`folder_opened_${this.id}`)
      }
    }
  }

  openParentFolders() {
    if (this.parentFolder) {
      this.parentFolder.setFolderState({ opened: true });

      this.parentFolder.openParentFolders();
    }
  }

  get parentFolder() {
    return TreeItem.find(this.parent_id);
  }

  get isFolder() {
    return this.type === 'folder';
  }

  get containsDeepResources() {
    if (this.isFolder) {
      return TreeItem.query().where('parent_id', this.id).get().some((item) => {
        return item.isFolder ? item.containsDeepResources : true;
      });
    }
    return true;
  }

  findNextItemToFetch() {
    const baseQuery = () => TreeItem.query().orderBy('position').where((item) => {
      return item.id !== this.id && item.type !== 'folder'
    });
    const parentId  = this.parent_id || null;
    const nextItem  = baseQuery().where('parent_id', parentId).first();

    return nextItem
      || baseQuery().where('parent_id', null).first()
      || baseQuery().first();
  }
}
