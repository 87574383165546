<template lang="pug">
  .form-group
    label {{ $t('dashboardBlocks.labels.list_id') }}
    v-select(
      :searching="true"
      :options="dashboardBlockLists"
      label="title"
      :reduce="option => option.id"
      v-model="editableSettings.list_id"
    )
</template>

<script>
import { mapState } from 'vuex';
import DashboardBlock from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    selectedDashboardBlockList() {
      if (this.editableSettings.list_id) {
        return DashboardBlock.find(this.editableSettings.list_id);
      }
    },
  }
}
</script>
