<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockLists"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.list_id"
      )
    template(v-if="selectedDashboardBlockList && selectedTable")
      .form-group(
        v-for="settingProperty in fieldSettingProperties"
        :key="settingProperty"
      )
        label(:for="settingProperty") {{ $t('dashboardBlocks.map.labels.' + settingProperty) }}
        v-select(
          :searching="true"
          label="name"
          :inputId="settingProperty"
          :options="fieldsForSelectedTable"
          :reduce="option => option.id"
          v-model="editableSettings[settingProperty]"
        )
      .form-group
        label {{ $t('dashboardBlocks.map.labels.alert_id') }}
        v-select(
          :searching="true"
          :options="dashboardBlocksForAlerts"
          label="title"
          :reduce="option => option.id"
          v-model="editableSettings.alert_id"
        )
    p.text-danger(
      v-else-if="selectedDashboardBlockList && !selectedTable"
    ) {{ $t('dashboardBlocks.map.errors.listIsNotConfigured') }}
</template>

<script>
import { mapState } from 'vuex';
import DashboardBlock from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fieldSettingProperties: [
        'longitude_field_id',
        'latitude_field_id',
        'title_field_id',
        'detail_field_id'
      ]
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    selectedDashboardBlockList() {
      if (this.editableSettings.list_id) {
        return DashboardBlock.find(this.editableSettings.list_id);
      }
    },
    selectedTable() {
      if (this.selectedDashboardBlockList && this.selectedDashboardBlockList.kind_settings.table_id) {
        const tableId = this.selectedDashboardBlockList.kind_settings.table_id;
        return this.tables.find(table => table.id === tableId);
      }
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
    dashboardBlocksForAlerts() {
      return DashboardBlock.query().where('kind', 'alerts').get();
    },
  },
  watch: {
    'editableSettings.list_id': function(newValue) {
      this.fieldSettingProperties.forEach(property => this.editableSettings[property] = null);
    }
  }
}
</script>
