<template lang="pug">
div
  .form-group
    label {{ $t('dashboardBlocks.labels.schedule_id') }}
    v-select(
      :searching="true"
      :options="scheduleDashboardBlocks"
      label="title"
      :reduce="option => option.id"
      v-model="editableSettings.schedule_id"
    )
  hr
  .form-group
    input.mr-2(
      v-model="editableSettings.auto_resize"
      type="checkbox"
      :name="dashboardBlock.id + '-autoResize'"
      :id="dashboardBlock.id + '-autoResize'"
    )
    label {{ $t('dashboardBlocks.labels.autoResize')}}
  .form-group
    label {{ $t('dashboardBlocks.live.labels.maxNumberOfLines') }}
    input.form-control(
      @keypress="isNumber"
      v-model.number="editableSettings.max_number_of_lines"
    )
</template>

<script>
import DashboardBlock from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    scheduleDashboardBlocks() {
      return DashboardBlock.query().where('kind', 'schedule').get();
    },
  },
  methods: {
    isNumber(event) {
      if (!(/([0-9\,\./])/i).test(event.key)) event.preventDefault()
    }
  }
}
</script>
