import { Controller } from "@hotwired/stimulus";
import KuartzSelector from "../../webpacker/src/javascript/components/kuartz_selector";

export default class extends Controller {
  static values = {
    locale: String,
    category: String,
  }

  connect() {
    const controller = this;
    const multiple = this.element.getAttribute("multiple");

    if (!multiple) return;

    new KuartzSelector(
      this.element,
      {
        multiple: true,
        locale: this.localeValue,
        count: true,
        category: this.categoryValue,
        onInitialize({ detail: value }) {
          controller.dispatch("initialized", { detail: value });
        },
        onChange({ detail: value }) {
          controller.dispatch("changed", { detail: value });
        },
      }
    );
  }

  // Used to dispatch tom select onInitialize event
  initialized() {
    this.dispatch("initialized", { detail: { value: this.element.value } });
  }

  // Used to dispatch tom select onChange event
  changed({ detail: value }) {
    this.dispatch("changed", { detail: value });
  }
}
