<script>
import moment from 'moment'

export default {
  functional: true,
  props: {
    entry: {
      type: Object
    }
  },
  render(createElement, { props, parent }) {
    let dateValue
    if (props.entry.value) {
      if (props.entry.field.typeOptions.date_format === 'with_time') {
        dateValue = moment(props.entry.value).format('DD/MM/YYYY HH:mm')
      } else {
        dateValue = moment(props.entry.value).format('DD/MM/YYYY')
      }
    }

    return createElement("div", { class: 'cell-content' }, dateValue)
  }
}
</script>
