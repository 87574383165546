import { Model } from '@vuex-orm/core';

export default class DashboardSharedItem extends Model {
  static entity = 'dashboard_shared_items';

  static primaryKey = ['dashboard_block_id', 'item_id'];

  static fields () {
    return {
      dashboard_block_id: this.number(null),
      item_id:            this.number(0),
      kind:               this.string(''),
      metadata:           this.attr(() => { return {} })
    }
  }

  static listItems (dashboardBlockId, queryOnly = false) {
    return this.itemForBlockAndKind(dashboardBlockId, queryOnly, 'list_item');
  }

  static alertItems(dashboardBlockId, queryOnly = false) {
    return this.itemForBlockAndKind(dashboardBlockId, queryOnly, 'alert_item');
  }

  static calendarYear(dashboardBlockId, queryOnly = false) {
    return this.itemForBlockAndKind(dashboardBlockId, queryOnly, 'calendar_year');
  }

  static itemForBlockAndKind(dashboardBlockId, queryOnly, kind) {
    const query = this.query().where((item) => {
      return item.dashboard_block_id === dashboardBlockId && item.kind === kind;
    });
    return queryOnly ? query : query.get();
  }
}
