import { api }              from '../../api/client';
import { filter }           from 'lodash';
import { EventBus }         from '../../main';
import { UpdateEntryMixin } from '../UpdateEntryMixin';
import { alertPopup }       from '../../../components/alert_popup';
import i18n                 from "../../locales/locales.js";

export const ReferencesMixin = {
  mixins: [UpdateEntryMixin],
  computed: {
    canAddReference() {
      if (this.entry) {
        return this.entry.isWritable && !(this.entry.field.typeOptions.limit_to_one_record && this.entry.value.length);
      }
    }
  },
  methods: {
    removeReference(reference, entry) {
      api.entries.removeReference({
        reference,
        recordId: entry.record.id,
        fieldId:  entry.field.id
      }).then((response) => {
        if (!response.data.error) {
          entry.value = filter(entry.value, entryReference => entryReference.foreign_record_id !== reference.foreign_record_id);
          this.updateCallbacks(entry);
        } else {
          const message = response.data.custom_message || this.$t('updateForbidden')
          alertPopup(message, 'OK');
        }
      })
    },
    openAddReferenceModal() {
      EventBus.$emit('openModal', {
        modalName: 'AddReferenceModal',
        modalProps: {
          entry: this.entry
        }
      });
    },
    openEntryReferencesModal() {
      EventBus.$emit('openModal', {
        modalName: 'EntryReferencesModal',
        modalProps: this.entry
      });
    },
    openReferenceModal(reference) {
      EventBus.$emit('openModal', {
        modalName: 'RecordModal',
        modalProps: {
          id:      reference.foreign_record_id,
          tableId: this.entry.field.typeOptions.foreign_table_id
        }
      });
    }
  }
};
