import Vue       from 'vue/dist/vue.esm';
import Vuex      from 'vuex';
import appsignal from '../../plugins/appsignal';
import ahoy      from 'ahoy.js';

import * as recordStore from './modules/recordStore';
import * as viewStore from './modules/viewStore';
import * as gridStore from './modules/gridStore';
import * as projectStore from './modules/projectStore';
import * as tableStore from './modules/tableStore';
import * as fieldStore from './modules/fieldStore';
import * as presenceStore from './modules/presenceStore';
import * as lockedRecordsStore from './modules/lockedRecordsStore';
import { buildProjectByProfileChannel } from '../channels/projectByProfileChannel';
import { buildSessionChannel } from '../channels/sessionChannel';
import { buildEntryUpdateChannel } from '../channels/entryUpdateChannel';
import { buildUserNotificationsChannel } from '../channels/userNotificationsChannel';
import { buildUserDataImportChannel } from '../channels/userDataImportChannel';

import VuexORM        from '@vuex-orm/core';
import VuexORMAxios   from '@vuex-orm/plugin-axios';
import { apiClient }  from '../api/client';

import Dashboard           from '../models/dashboard';
import DashboardBlock      from '../models/dashboardBlock';
import User                from '../models/user';
import DashboardSharedItem from '../models/dashboardSharedItem';
import TreeItem            from '../models/treeItem';

VuexORM.use(VuexORMAxios, { axios: apiClient });

const database = new VuexORM.Database();

database.register(Dashboard);
database.register(DashboardBlock);
database.register(User);
database.register(DashboardSharedItem);
database.register(TreeItem)

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
  modules: {
    recordStore,
    viewStore,
    gridStore,
    projectStore,
    tableStore,
    fieldStore,
    presenceStore,
    lockedRecordsStore,
  },
  state: {
    uniqSessionId: uniqSessionId(),
    loading: { state: true },
    error: false,
    saving: {},
    currentUser: {},
    feature: null,
    currentResourceType: null,
    currentResourceId: null,
    projectByProfileChannel: null,
    sessionChannel: null,
    userNotificationsChannel: null,
    screenWidth: 0,
    mainPanelWidth: 0,
    openedModals: [],
    entryUpdateChannels: [],
    projectPrimaryColors: {},
    projectSecondaryColors: {},
    disableGridClickOutside: false,
    mapboxApiKey: '',
    workspace: null,
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_SAVING(state, loading) {
      state.saving = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_CURRENT_USER(state, currentUser) {
      state.currentUser = currentUser;
    },
    SET_FEATURE(state, feature) {
      state.feature = feature;
    },
    SET_CURRENT_RESOURCE(state, { resourceId, resourceType }) {
      state.currentResourceId   = resourceId;
      state.currentResourceType = resourceType;
    },
    SET_PROJECT_PRIMARY_COLORS(state, projectPrimaryColors) {
      state.projectPrimaryColors = projectPrimaryColors;
    },
    SET_PROJECT_SECONDARY_COLORS(state, projectSecondaryColors) {
      state.projectSecondaryColors = projectSecondaryColors;
    },
    SET_SCREEN_WIDTH(state, screenWidth) {
      state.screenWidth = screenWidth;
    },
    SET_MAIN_PANEL_WIDTH(state, mainPanelWidth) {
      state.mainPanelWidth = mainPanelWidth;
    },
    SET_PROJECT_BY_PROFILE_CHANNEL(state, channel) {
      state.projectByProfileChannel = channel;
    },
    SET_SESSION_CHANNEL(state, channel) {
      state.sessionChannel = channel;
    },
    SET_USER_NOTIFICATIONS_CHANNEL(state, channel) {
      state.userNotificationsChannel = channel;
    },
    SET_USER_DATA_IMPORT_CHANNEL(state, channel) {
      state.userDataImportChannel = channel;
    },
    ADD_ENTRY_UPDATE_CHANNEL(state, channel) {
      state.entryUpdateChannels.push(channel);
    },
    REMOVE_ENTRY_UPDATE_CHANNEL(state, index) {
      state.entryUpdateChannels.splice(index, 1);
    },
    ADD_OPENED_MODAL(state, modal) {
      state.openedModals.push(modal);
    },
    REMOVE_OPENED_MODAL(state, index) {
      state.openedModals.splice(index, 1);
    },
    SET_DISABLE_GRID_CLICK_OUTSIDE(state, status) {
      state.disableGridClickOutside = status;
    },
    SET_MAPBOX_API_KEY(state, mapboxApiKey) {
      state.mapboxApiKey = mapboxApiKey;
    },
    SET_TRANSLATION_FALLBACK_CLASSES(state, translationFallbackClasses) {
      state.translationFallbackClasses = translationFallbackClasses;
    },
    SET_WORKSPACE(state, workspace) {
      state.workspace = workspace;
    },
  },
  actions: {
    throwError({ commit, dispatch }, { error, message }) {
      commit('SET_ERROR', message || true);
      appsignal.sendError(error);
      dispatch('disconnectAllEntryUpdateChannel');
      dispatch('closeAllModals');
    },
    loadResource({ state, dispatch }, { id, type }) {
      type = type.toLowerCase();
      id = Number.parseInt(id);
      if (state.currentResourceType === type && state.currentResourceId === id) return;

      switch (type) {
        case 'dashboard':
          Dashboard.api().fetchById(id);
          document.querySelector(".main-panel").scrollTo({top: 0, left: 0});
          break;
        case 'table':
          dispatch('tableStore/fetchTable', { tableId: id })
          break;
        case 'view':
          dispatch("viewStore/fetchView", { viewId: id });
          break;
      }
    },
    setCurrentResource({ state, commit, dispatch }, { resourceType, resourceId }) {
      let documentState;
      if (resourceType === 'view') {
        documentState = state.viewStore.view.buildDocumentState();
      } else if (resourceType === 'dashboard') {
        documentState = Dashboard.find(resourceId).buildDocumentState();
      }
      commit('SET_CURRENT_RESOURCE', { resourceId, resourceType });

      // Change the URL and document title if there is no workspace or the resource is a view
      if (documentState && !state.workspace) {
        dispatch('updateDocumentState', documentState);
      }

      ahoy.trackView();
    },
    updateDocumentState({}, { url, title }) {
      window.history.pushState(null, '', url);
      document.title = title;
    },
    clearViewAndGrid({ commit }) {
      commit('viewStore/SET_VIEW', {}, { root: true });
      commit('gridStore/SET_SELECTED_ENTRY', {}, { root: true });
    },
    setProjectByProfileChannel({ commit, state }) {
      const channel = buildProjectByProfileChannel(state.currentUser.profileId);
      commit('SET_PROJECT_BY_PROFILE_CHANNEL', channel);
    },
    setSessionChannel({ commit, state }) {
      const channel = buildSessionChannel(state.uniqSessionId);
      commit('SET_SESSION_CHANNEL', channel);
    },
    setUserNotificationsChannel({ commit, state }) {
      const channel = buildUserNotificationsChannel(state.currentUser.id);
      commit('SET_USER_NOTIFICATIONS_CHANNEL', channel);
    },
    setUserDataImportChannel({ commit, state }) {
      const channel = buildUserDataImportChannel(state.currentUser.id);
      commit('SET_USER_DATA_IMPORT_CHANNEL', channel);
    },
    addEntryUpdateChannel({ commit, state }, tableId) {
      if (!state.entryUpdateChannels.find(channel => channel.tableId === tableId)) {
        const channel = buildEntryUpdateChannel(tableId);
        channel.tableId = tableId;

        commit('ADD_ENTRY_UPDATE_CHANNEL', channel);
      }
    },
    removeEntryUpdateChannel({ commit, state }, tableId) {
      const channel = state.entryUpdateChannels.find((channel) => {
        return channel.tableId === tableId;
      });
      if (channel) {
        channel.unsubscribe();
        const index = state.entryUpdateChannels.findIndex((channel) => {
          return channel.tableId === tableId;
        });
        commit('REMOVE_ENTRY_UPDATE_CHANNEL', index);
      }
    },
    disconnectAllEntryUpdateChannel({ commit, state }) {
      while (state.entryUpdateChannels.length) {
        state.entryUpdateChannels[0].unsubscribe();
        commit('REMOVE_ENTRY_UPDATE_CHANNEL', 0);
      }
    },
    closeAllModals({ state, commit }) {
      while (state.openedModals.length) {
        commit('REMOVE_OPENED_MODAL', 0);
      }
    },
    clearChannels({ state, commit, dispatch }) {
      state.projectByProfileChannel.unsubscribe();
      state.sessionChannel.unsubscribe();
      state.userNotificationsChannel.unsubscribe();
      state.userDataImportChannel.unsubscribe();
      commit("SET_PROJECT_BY_PROFILE_CHANNEL", null);
      commit("SET_SESSION_CHANNEL", null);
      commit("SET_USER_NOTIFICATIONS_CHANNEL", null);
      commit("SET_USER_DATA_IMPORT_CHANNEL", null);
      dispatch("disconnectAllEntryUpdateChannel");
    },
  },
  getters: {
    isLoading(state) {
      return state.loading.state
    },
    isSaving(state) {
      return state.saving.state
    },
    isCurrentResource: (state) => (id, type) => {
      return state.currentResourceId === id && state.currentResourceType === type;
    },
  },
});

function uniqSessionId() {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}
