import { sortBy } from 'lodash';

export default class View {
  constructor(baseView, table) {
    this.id                   = baseView.id;
    this.name                 = baseView.name;
    this.description          = baseView.description;
    this.alert                = baseView.alert || false;
    this.type                 = baseView.view_type;
    this.typeOptions          = baseView.type_options || {};
    this.mainView             = baseView.main_view || false;
    this.locked               = baseView.locked || false;
    this.scope                = baseView.scope || null;
    this.nameFallback         = baseView.name_fallback;
    this.descriptionFallback  = baseView.description_fallback;

    this.editName = false; // for name update
    this.table    = table;

    this.query      = { logicalOperator: null, filters: [] };
    this.sortOrders = [];
    this.rowOrder   = [];
    this.slug       = '';
    this.isLoaded   = false;
  }

  get visibleRecordIds() {
    return this.rowOrder.reduce((recordIds, { id, visible }) => {
      if (visible) recordIds.push(id);

      return recordIds;
    }, [])
  }

  set visibleRecordIds(newVisibleRecordIds) {
    newVisibleRecordIds = new Set(newVisibleRecordIds);

    this.rowOrder.forEach((row) => {
      row.visible = newVisibleRecordIds.delete(row.id);
    });
  }

  set sortedRecordsIds(newSortedRecordsIds) {
    if (newSortedRecordsIds) {
      const indicesById = {};
      this.rowOrder.forEach(row => indicesById[row.id] = row);
      newSortedRecordsIds.forEach((id, index) => {
        newSortedRecordsIds[index] = indicesById[id] || { id, visible: false }
      });

      this.rowOrder = newSortedRecordsIds;
    }
  }

  get sortedRecordsIds() {
    return this.rowOrder.map((row) => row.id);
  }

  updateWithViewData(viewData) {
    this.rowOrder              = viewData.row_order;
    this.query.logicalOperator = viewData.query.logical_operator;
    this.query.filters         = viewData.query.filters;
    this.sortOrders            = viewData.sort_orders;
    this.slug                  = viewData.slug;
    this.isLoaded              = true;
  }

  buildDocumentState() {
    return {
      url:   `/${this.table.slug}/${this.slug}`,
      title: `${this.table.name} • ${this.name}`
    }
  }

  applyDefaultSortOrder() {
    this.rowOrder = sortBy(this.rowOrder, 'id');
  }

  static build(baseViews, table) {
    if (Array.isArray(baseViews)) {
      return baseViews.map(baseView => new View(baseView, table));
    } else {
      return new View(baseViews, table);
    }
  }
}
