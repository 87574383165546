import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["unavailabilityCard", "acousticCard"]

  static classes = ["highlight"]

  highlightUnavailabilities(event) {
    const unavailabilitiesIds = event.detail.unavailabilitiesIds;

    this.unavailabilityCardTargets.forEach((unavailabilityCard) => {
      const unavailabilityId = Number.parseInt(unavailabilityCard.dataset.unavailabilityId, 10);
      if (unavailabilitiesIds.includes(unavailabilityId)) {
       unavailabilityCard.classList.add(this.highlightClasses)
      } else {
        unavailabilityCard.classList.remove(this.highlightClasses)
      }
    });

    this.acousticCardTargets.forEach((acousticCard) => {
      // the difference is an acoustic card can have multiple unavailabilities
      const unavailabilityIds = Array.from(JSON.parse(acousticCard.dataset.unavailabilityIds));

      if (unavailabilityIds.some(id => unavailabilitiesIds.includes(id))) {
        acousticCard.classList.add(this.highlightClasses)
      } else {
        acousticCard.classList.remove(this.highlightClasses)
      }
    });
  }

  clearHighlight() {
    this.unavailabilityCardTargets.forEach((unavailabilityCard) => {
      unavailabilityCard.classList.remove(this.highlightClasses)
    });
  }
}
