<script>
import { EventBus } from "../../main.js";

export default {
  functional: true,
  props: {
    index: {
      type: Number,
      required: true
    },
    record: {
      type: Object,
      required: true
    },
    recordDeletionEnabled: { type: Boolean },
  },
  render(h, { props, parent }) {
    if (props.record.waitingForDeleteConfirmation) {
      return h('div', {
        class: 'confirm-delete-record',
        on: {
          click() {
            props.record.waitingForDeleteConfirmation = false;
            props.record.delete(false);
          }
        }
      }, parent.$t('delete'));
    } else {
      const rowNumber        = h('div', { class: 'row-number' }, (props.index + 1).toString());
      const expandRecordIcon = h('i', { class: 'far fa-expand-alt expand-icon' });
      const expandRecord     = h(
        'div',
        {
          class: 'expand-record',
          on: {
            click() {
              EventBus.$emit('openModal', {
                modalName:  'RecordModal',
                modalProps: { id: props.record.id }
              });
            }
          }
        },
        [expandRecordIcon]
      );
      let lockedOrDeleteRecord;
      if (props.record.locked || !props.recordDeletionEnabled) {
        const lockedRecordIcon = h('i', { class: 'far fa-ban text-secondary' });
        lockedOrDeleteRecord   = h('div', {
          class: 'has-tooltip locked-record',
          attrs: {
            'data-toggle': 'tooltip',
            title: parent.$t('lockedRecordInfo')
          }
        }, [lockedRecordIcon]);
      } else {
        const deleteRecordIcon = h('i', { class: 'far fa-times-circle delete-icon' });
        lockedOrDeleteRecord   = h('div',
          {
            class: 'delete-record',
            on: {
              click() {
                props.record.waitingForDeleteConfirmation = true;
                setTimeout(() => (props.record.waitingForDeleteConfirmation = false), 2000);
              }
            }
          }, [deleteRecordIcon]);
      }
      return h('div', {
        class: `control-cell ${props.record.locked ? 'locked' : ''}`
      }, [rowNumber, lockedOrDeleteRecord, expandRecord]);
    }
  }
}
</script>
