<template lang="pug">
.items-wrapper.emails
  p.text-secondary
    strong.mr-2
      i.fas(:class="icon")
    | {{ title }}
  .form-group.mt-3
    .d-flex
      input.form-control.mr-2(
        :id="'new-' + type"
        type="text"
        v-model="newItem"
        @focus="validContactFormat = true"
        :class="{ 'border-danger': invalidContactFormat }"
      )
      button.btn.bg-white.flex-center.add-item-btn(
        :style="{ color: projectSecondaryColors.normal,\
                  borderColor: projectSecondaryColors.normal,\
                  '--hover-color': projectSecondaryColors.normal }"
        type="button"
        :disabled="invalidContactFormat"
        @click="addItem"
      )
        i.fas.fa-plus.fa-fw
    small.text-danger(v-if="invalidContactFormat") {{ $t('dashboardBlocks.flex_contacts.invalidFormat') }}
  ol.list-group
    FlexContact(
      v-for="(item, index) in sortedItems"
      :position="index + 1"
      :item="item"
      :key="item.name"
      :attribute="attribute"
      :type="type"
      @change="$emit('edit-item', $event)"
      @delete="$emit('delete-item', $event)"
    )
</template>

<script>
import FlexContact from './FlexContact.vue';
import { mapState } from 'vuex';
import { sortBy } from 'lodash';
import { FlexContactValidationMixin } from '../../../mixins/FlexContactValidationMixin';

const ATTRIBUTES_BY_TYPE = {
  CALL: 'tel',
  SMS: 'tel',
  MAIL: 'email'
};

const ICONS_BY_TYPE = {
  CALL: 'fa-phone',
  SMS: 'fa-envelope',
  MAIL: 'fa-at'
};

export default {
  mixins: [FlexContactValidationMixin],
  components: {
    FlexContact
  },
  props: {
    type: {
      type: String,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      newItem: ''
    }
  },
  computed: {
    ...mapState(['projectSecondaryColors']),
    title() {
      return this.$t(`dashboardBlocks.flex_contacts.${this.type}`);
    },
    icon() {
      return ICONS_BY_TYPE[this.type];
    },
    sortedItems() {
      return sortBy(this.items, (item) => {
        return this.itemPosition(item);
      });
    },
    attribute() {
      return ATTRIBUTES_BY_TYPE[this.type]
    },
  },
  methods: {
    addItem() {
      this.testContactFormat(this.newItem)
      if (this.validContactFormat) {
        this.$emit("add-item", { value: this.newItem, type: this.type });
        this.newItem = '';
      }
    },
    itemPosition(item) {
      return Number.parseInt((item.name).match(/\d+/), 10);
    },
  }
}
</script>

<style lang="scss" scoped>
.add-item-btn {
  &:hover:not(:disabled) {
    background-color: var(--hover-color) !important;
    color: white !important;
  }
  &:disabled {
    filter: grayscale(100%);
    cursor: not-allowed;
  }
}
</style>
