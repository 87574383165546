import { selectListMixin }      from './selectListMixin';
import { differenceBy }         from 'lodash';
import { api }                  from '../api/client';
import { mapGetters, mapState } from 'vuex';
import { UpdateEntryMixin }     from './UpdateEntryMixin';
import { alertPopup }           from '../../components/alert_popup';
import i18n                     from "../locales/locales.js";
import User                     from "../models/user";

export const AddUserMixin = {
  mixins: [selectListMixin, UpdateEntryMixin],
  data() {
    return {
      usersForReference:       null,
      elementIdentifier:       'id',
      elementFilteredProperty: 'displayName',
      elementObjectName:       'user'
    }
  },
  computed: {
    ...mapState({
      currentTable: state => state.tableStore.table,
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    selectListElements() {
      if (this.entry) {
        return differenceBy(this.usersForReference, this.entry.displayValue, 'id');
      }
      return [];
    },
  },
  methods: {
    elementSelected(element) {
      if (element) this.addUserToEntry(element);
    },
    addUserToEntry(user) {
      api.entries.addUserForReference({
        userId:   user.id,
        recordId: this.entry.record.id,
        fieldId:  this.entry.field.id
      }).then((response) => {
        if (!response.data.error) {
          this.entry.value.push(user.id);
          this.entry.buildDisplayValue();
          this.updateCallbacks(this.entry);
          this.afterAddUserForReference();
        } else {
          const message = response.data.custom_message || this.$t('updateForbidden')
          alertPopup(message, 'OK');
        }
      })
    },
    fetchUsersForReference() {
      this.usersForReference = User.all().map((user) => {
        return {
          id:          user.id,
          displayName: user.displayName,
        }
      });
    },
  }
};
