<template lang="pug">
  div
    .form-group
        label {{ $t('dashboardBlocks.labels.concerned_table_ids') }}
        v-select(
          :searching="true"
          :multiple="true"
          label="name"
          :options="tables"
          :reduce="option => option.id"
          v-model="editableSettings.concerned_table_ids"
        )
    template(v-if="editableSettings.concerned_table_ids && editableSettings.concerned_table_ids.length")
      hr(:class="'border-' + (tableIdForConfiguration ? 'success' : 'warning')")
      .form-group#select-table-for-schedule-configuration
        label {{ $t('dashboardBlocks.labels.select_table_to_configure') }}
        v-select(
          :searching="true"
          :options="concernedTables"
          label="name"
          :reduce="option => option.id"
          v-model="tableIdForConfiguration"
          :class="tableIdForConfiguration ? 'table-selected' : 'no-table-selected'"
        )
      hr(:class="'border-' + (tableIdForConfiguration ? 'success' : 'warning')")
    template(v-if="tableIdForConfiguration")
      .form-group
        label {{ $t('dashboardBlocks.labels.view_id') }}
        v-select(
          :searching="true"
          :options="viewsForSelectedTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.settings_by_table_id[tableIdForConfiguration].view_id"
        )
      .form-group(
        v-for="settingProperty in fieldSettingProperties"
        :key="settingProperty"
      )
        label(:for="settingProperty") {{ $t('dashboardBlocks.labels.' + settingProperty) }}
        v-select(
          :searching="true"
          label="name"
          :inputId="settingProperty"
          :options="fieldsForSelectedTable"
          :reduce="option => option.id"
          v-model="editableSettings.settings_by_table_id[tableIdForConfiguration][settingProperty]"
        )
</template>

<script>
import { mapState }   from 'vuex';
import { EventBus }   from '../../../main';
import { difference } from 'lodash';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableIdForConfiguration: null,
      fieldSettingProperties: [
        'start_date_field_id',
        'end_date_field_id',
        'title_field_id',
        'label_field_id',
        'description_field_id',
        'icon_field_id',
        'color_field_id'
      ]
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    concernedTables() {
      return this.tables.filter(table => this.editableSettings.concerned_table_ids.includes(table.id));
    },
    selectedTable() {
      if (this.tableIdForConfiguration) {
        return this.tables.find(table => table.id === this.tableIdForConfiguration);
      }
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
    viewsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.views;
      }
      return [];
    }
  },
  mounted() {
    this.editableSettings.settings_by_table_id = this.editableSettings.settings_by_table_id || {};
    this.editableSettings.concerned_table_ids  = this.editableSettings.concerned_table_ids || [];
  },
  watch: {
    'dashboardBlock.kind_settings': function(newSettings, oldSettings) {
      if (newSettings.concerned_table_ids.length !== oldSettings.concerned_table_ids.length) {
        EventBus.$emit('dashboard-block-updated-' + this.dashboardBlock.id, { type: 'tablesChanged' });
      }
      const viewsToFetch = [];
      newSettings.concerned_table_ids.forEach((tableId) => {
        const oldTableSettings = oldSettings.settings_by_table_id[tableId];
        if (oldTableSettings) {
          const currentViewId = newSettings.settings_by_table_id[tableId].view_id
          const viewChanged   = currentViewId !== oldTableSettings.view_id;
          if (viewChanged) viewsToFetch.push(currentViewId);
        }
      });
      if (viewsToFetch.length) {
        EventBus.$emit('dashboard-block-updated-' + this.dashboardBlock.id, { type: 'viewsChanged' });
      }
    },
    'editableSettings.concerned_table_ids': function(newSettings, oldSettings) {
      if (!newSettings.includes(this.tableIdForConfiguration)) this.tableIdForConfiguration = null;
      const addedTableIds   = difference(newSettings, oldSettings);
      addedTableIds.forEach(tableId => this.editableSettings.settings_by_table_id[tableId] = {});
      const removedTableIds = difference(oldSettings, newSettings);
      removedTableIds.forEach((tableId) => { delete this.editableSettings.settings_by_table_id[tableId] });
    }
  }
}
</script>
