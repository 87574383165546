<template lang="pug">
  div
    .mb-4(
      v-for="(entries, visibility) in recordEntries"
      :key="visibility"
    )
      p.show-hidden-fields.hidden-fields(
        v-if="visibility === 'hidden' && entries.length && canShowHiddenFields"
        @click="showHiddenEntries = !showHiddenEntries"
        :class="{ opened: showHiddenEntries }"
      )
        span.mr-2
          span.show-hidden-fields--icon
            i.fad.fa-chevron-circle-right
        | {{ entries.length }} {{ $tc('hiddenFields', entries.length) }}
      div(
        v-if="visibility === 'visible' || showHiddenEntries"
      )
        .form-group(
          v-for="entry in entries"
          :key="entry.field.id + '-' + entry.record.id"
          :class="{ 'table-relationship-input': entry.field.isOwnTableRelationalField }"
        )
          div
            label.w-100
              .d-flex.align-items-center.justify-content-between
                .text-sm
                  i.mr-1.far.fa-fw(:class="'fa-'+ entry.field.faIconName")
                  span(
                    :class="{ [translationFallbackClasses]: entry.field.nameFallback }"
                  ) {{ entry.field.name }}
                .d-flex.align-items-center
                  span.has-tooltip.icon-help(
                    data-toggle="tooltip"
                    :title="$t('fields.' + entry.field.accessRight + 'Access')"
                  )
                    i.ml-2.fad.fa-fw.text-xs(:class="entry.field.accessRight === 'write' ? 'fa-edit' : 'fa-eye'")
                  span.ml-2.icon-action.icon-pointer(
                    v-if="isOnMobile && entry.field.isOwnTableRelationalField"
                    @click="displayOnlyRightPanel"
                  )
                    i.far.fa-angle-double-right.fa-fw
              .field-description.p-2.whitespace-pre(v-if="entry.field.description")
                | {{ entry.field.description }}
            component(
              :is="`input-${entry.accessRight}-${entry.field.dataType}`"
              :entry="entry"
              :recordModalPanel="recordModalPanel"
            )
</template>

<script>
import EntryInputReadReferences     from "./references/EntryInputReadReferences";
import EntryInputWriteReferences    from "./references/EntryInputWriteReferences";
import EntryInputReadUsers          from "./users/EntryInputReadUsers";
import EntryInputWriteUsers         from "./users/EntryInputWriteUsers";
import EntryInputReadBoolean        from "./boolean/EntryInputReadBoolean";
import EntryInputWriteBoolean       from "./boolean/EntryInputWriteBoolean";
import EntryInputReadString         from "./string/EntryInputReadString";
import EntryInputWriteString        from "./string/EntryInputWriteString";
import EntryInputReadNumber         from './number/EntryInputReadNumber.vue';
import EntryInputWriteNumber        from './number/EntryInputWriteNumber.vue';
import EntryInputReadDate           from './date/EntryInputReadDate.vue';
import EntryInputWriteDate          from './date/EntryInputWriteDate.vue';
import EntryInputReadDateTimeRange  from './date_time_range/EntryInputReadDateTimeRange.vue';
import EntryInputWriteDateTimeRange from './date_time_range/EntryInputWriteDateTimeRange.vue';
import EntryInputReadSelect         from './select/EntryInputReadSelect.vue';
import EntryInputWriteSelect        from './select/EntryInputWriteSelect.vue';
import EntryInputReadLookups        from './lookups/EntryInputReadLookups.vue';
import EntryInputReadFiles          from './files/EntryInputReadFiles.vue';

import Record from "../../models/record";
import { mapState } from "vuex";

export default {
  components: {
    "input-read-references":       EntryInputReadReferences,
    "input-write-references":      EntryInputWriteReferences,
    "input-read-users":            EntryInputReadUsers,
    "input-write-users":           EntryInputWriteUsers,
    "input-read-lookups":          EntryInputReadLookups,
    "input-read-boolean":          EntryInputReadBoolean,
    "input-write-boolean":         EntryInputWriteBoolean,
    "input-read-string":           EntryInputReadString,
    "input-write-string":          EntryInputWriteString,
    "input-read-number":           EntryInputReadNumber,
    "input-write-number":          EntryInputWriteNumber,
    "input-read-date":             EntryInputReadDate,
    "input-write-date":            EntryInputWriteDate,
    "input-read-date_time_range":  EntryInputReadDateTimeRange,
    "input-write-date_time_range": EntryInputWriteDateTimeRange,
    "input-read-select":           EntryInputReadSelect,
    "input-write-select":          EntryInputWriteSelect,
    "input-read-files":            EntryInputReadFiles,
    "input-write-files":           EntryInputReadFiles,
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    isOnMobile: {
      type: Boolean,
      required: false
    },
    displayedPanels: {
      type: Object,
      required: false
    },
    recordModalPanel: {
      type: String,
      required: false
    },
    canShowHiddenFields: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showHiddenEntries: false
    }
  },
  computed: {
    ...mapState(['translationFallbackClasses']),
    recordEntries() {
      if (this.record instanceof Record) {
        return {
          visible: this.record.visibleEntries,
          hidden:  this.record.entries.filter(entry => !entry.field.visible && entry.field.isUserAccessible)
        };
      }
      return {};
    }
  },
  methods: {
    displayOnlyRightPanel() {
      if (!this.displayedPanels) return;
      this.displayedPanels.left  = false;
      this.displayedPanels.right = true;
    }
  }
}
</script>

<style scoped>
  .icon-pointer {
    cursor: pointer;
  }
  .field-description {
    font-weight: 400;
    font-size: 0.875rem;
    opacity: 0.8;
  }
</style>
