<template lang="pug">
  .px-3.py-2(@click="openRecordModal")
    .gallery-card.shadow-sm.rounded.h-100
      template(v-if="hasImage")
        .entry-img(v-if="pictureEntry.value.length")
          img.cell-content-file-preview.picture.card-img-top(
            v-if="pictureEntry.value[indexImg].content_type.includes('image')"
            :src="`/project_files/${pictureEntry.value[indexImg].id}`"
          )
          .picture.card-img-top.d-flex.align-items-center.justify-content-center(v-else)
            i.fas.fa-4x(
              :class="pictureEntry.value[indexImg] | getFontawesomeIcon"
            )
          .left.d-flex.align-items-center.justify-content-center(@click.stop="left" v-if="pictureEntry.value.length > 1")
            i.far.fa-3x.fa-chevron-left
          .right.d-flex.align-items-center.justify-content-center(@click.stop="right" v-if="pictureEntry.value.length > 1")
            i.far.fa-3x.fa-chevron-right
        .entry-img.d-flex.align-items-center.justify-content-center.picture(v-else)
          i.fad.fa-image.fa-4x.text-gray-300
      .card-body
        .entries.pb-4(v-for="entry in visibleEntries")
          //- Titre
          .pb-2.card-title.text-xl.font-weight-bolder(
            v-if="entry.field.primary"
          ) {{ entry.value }}
          //- Component
          component(
            v-else
            keep-alive
            :is="componentName(entry.field.dataType)"
            :entry="entry"
          )
</template>

<script>
import { filter, find }         from 'lodash';
import { mapGetters }           from "vuex";
import { getFontawesomeIcon }   from '../services/getFileType';
import GalleryEntryString       from './galleryentry/GalleryEntryString.vue';
import GalleryEntrySelect       from './galleryentry/GalleryEntrySelect.vue';
import GalleryEntryBoolean      from './galleryentry/GalleryEntryBoolean.vue';
import GalleryEntryDate         from './galleryentry/GalleryEntryDate.vue';
import GalleryEntryReferences   from './galleryentry/GalleryEntryReferences.vue';
import GalleryEntryLookups      from './galleryentry/GalleryEntryLookups.vue';
import GalleryEntryFile         from './galleryentry/GalleryEntryFile.vue';
import GalleryEntryNumber       from './galleryentry/GalleryEntryNumber.vue';

export default {
  components: {
    'gallery-string':     GalleryEntryString,
    'gallery-number':     GalleryEntryNumber,
    'gallery-select':     GalleryEntrySelect,
    'gallery-boolean':    GalleryEntryBoolean,
    'gallery-date':       GalleryEntryDate,
    'gallery-references': GalleryEntryReferences,
    'gallery-lookups':    GalleryEntryLookups,
    'gallery-files':      GalleryEntryFile
  },
  filters: { getFontawesomeIcon },
  props: {
    record: {
      type:     Object,
      required: true
    },
    hasImage: {
      type:     Boolean,
      required: true
    }
  },
  data() {
    return {
      indexImg: 0
    }
  },
  mounted() {
    this.record.buildEntries();
  },
  computed: {
    pictureEntry() {
      const entry = find(this.visibleEntries, entry => entry.field.dataType === 'files');
      if (entry == undefined) {
        return { value : [] } ;
      } else {
        return entry
      }
    },
    visibleEntries() {
      return filter(this.record.entries, 'field.visible');
    },
    ...mapGetters({
      getRecordById: 'recordStore/getRecordById'
    })
  },
  methods: {
    right: function() {//changement image vers la droite
      this.indexImg += 1;
      this.indexImg = this.indexImg % this.pictureEntry.value.length;
    },
    left: function() {//changement d'image vers la gauche
      this.indexImg -= 1;
      this.indexImg = (this.pictureEntry.value.length + this.indexImg) % this.pictureEntry.value.length;
    },
    openRecordModal: function() {
      this.$emit('open-record-modal');
    },
    componentName(dataType) {
      const existingComponents = [
        'string',
        'number',
        'select',
        'boolean',
        'date',
        'references',
        'lookups',
        'files'
      ];

      return existingComponents.includes(dataType) ? ('gallery-' + dataType) : 'gallery-string';
    },
  }
}
</script>

<style scoped>
  .entry-img {
    position: relative;
  }
  .left {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    padding-left: 8px;
    color: hsl(24,12%,30%);
  }
  .right {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 8px;
    color: hsl(24,12%,30%);
  }
  .picture {
    object-fit: cover;
    height: 180px;
    max-width: 400px;
    z-index: 1;
  }
</style>
