import { apiClient, startFetch, endFetch } from '../client';

export default {
  index({ dashboard_block_id }) {
    return apiClient.get('/api/flex_contacts', {
      params: { dashboard_block_id }
    });
  },
  create({ dashboard_block_id, type, value, site_record_id }) {
    return apiClient.post('/api/flex_contacts', {
      dashboard_block_id, type, value, site_record_id
    });
  },
  update({ dashboard_block_id, value, contact_record_id, attribute }) {
    return apiClient.patch(`/api/flex_contacts/${contact_record_id}`, {
      dashboard_block_id, value, attribute
    });
  },
  destroy({ dashboard_block_id, contact_record_id }) {
    return apiClient.delete(`/api/flex_contacts/${contact_record_id}`, {
      params: { dashboard_block_id }
    });
  }
}
