<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.reportextract.labels.report_kind') }}
      v-select(
        :searching="true"
        :options="reportKinds"
        label="title"
        :getOptionLabel="getKindLabel"
        v-model="editableSettings.report_kind"
      )
    DashboardBlockSettingsReportExtractPerformances(
      v-if="editableSettings.report_kind == 'performances'"
      :dashboardBlock="dashboardBlock"
      :editableSettings="editableSettings.report_settings"
    )
    DashboardBlockSettingsReportExtractNegativePriceMonitoring(
      v-else-if="editableSettings.report_kind == 'negative_price_monitoring'"
      :dashboardBlock="dashboardBlock"
      :editableSettings="editableSettings.report_settings"
    )
    DashboardBlockSettingsReportExtractFlexActivity(
      v-if="editableSettings.report_kind == 'flex_activity'"
      :dashboardBlock="dashboardBlock"
      :editableSettings="editableSettings.report_settings"
    )
</template>

<script>
import DashboardBlockSettingsReportExtractPerformances            from './DashboardBlockSettingsReportExtractPerformances';
import DashboardBlockSettingsReportExtractNegativePriceMonitoring from './DashboardBlockSettingsReportExtractNegativePriceMonitoring';
import DashboardBlockSettingsReportExtractFlexActivity            from './DashboardBlockSettingsReportExtractFlexActivity';

export default {
  components: {
    DashboardBlockSettingsReportExtractPerformances,
    DashboardBlockSettingsReportExtractNegativePriceMonitoring,
    DashboardBlockSettingsReportExtractFlexActivity
  },
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      reportKinds: [
        'performances',
        'negative_price_monitoring',
        'flex_activity'
      ]
    }
  },
  watch: {
    'editableSettings.report_kind': function(_newSettings, _oldSettings) {
      this.$set(this.editableSettings, 'report_settings', {});
    },
  },
  methods: {
    getKindLabel(option) {
      return this.$t(`dashboardBlocks.reportextract.kinds.${option}`);
    }
  }
}
</script>
