import { Controller } from "@hotwired/stimulus";
import Flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static values = {
    locale: String,
    settings: Object,
  }

  connect() {
    const globalOptions = {
      locale: this.locale(),
      altInput: true,

      onClose: (selectedDates, _dateStr, _instance) => {
        this.dispatch("close", { detail: { selectedDates } });
      },
      onChange: (selectedDates, _dateStr, _instance) => {
        this.dispatch("change", { detail: { selectedDates } });
      }
    }

    Flatpickr(this.element, Object.assign(globalOptions, this.settingsValue))
  }

  locale() {
    const locales = {
      en: null, // Default
      fr: French,
      es: Spanish,
    }

    return locales[this.localeValue]
  }
}
