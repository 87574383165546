
<template lang="pug">
  draggable(
    v-bind="dragOptions"
    class="tree-item-container"
    @change="updateTreeItemPosition"
  )
    .tree-item-group(
      v-for="treeItem in folderItems"
      :key="treeItem.id"
    )
      component(
        :is="'tree-' + treeItem.type"
        :treeItem="treeItem"
        :depth="depth"
      )

      tree-items(
        v-if="treeItem.type === 'folder'"
        v-show="treeItem.opened"
        :depth="depth + 1"
        :treeItem="treeItem"
      )
</template>

<script>
import { filter }    from 'lodash';
import { mapState }  from 'vuex';
import draggable     from "vuedraggable";
import TreeDashboard from "./tree_items/dashboard/TreeDashboard"
import TreeTable     from "./tree_items/table/TreeTable"
import TreeFolder    from "./tree_items/folder/TreeFolder"
import TreeItem      from '../../models/treeItem';

export default {
  name: 'tree-items',
  components: {
    draggable,
    TreeDashboard,
    TreeTable,
    TreeFolder,
  },
  props: {
    treeItem: {
      type: Object,
    },
    value: {
      type:     Array,
      required: false,
      default:  null
    },
    depth: {
      type:     Number,
      required: false,
      default:  0,
    },
  },
  computed: {
    ...mapState(['feature']),
    ...mapState('entities/tree_items', {
      editingName: state => state.editingName
    }),
    dragOptions() {
      return {
        animation: 150,
        delay:     100,
        group:     "tree-items",
        disabled:  !this.feature.canCreateResource || this.editingName,
        value:     this.folderItems,
        filter:    'input.edit-name',
        // ghostClass: "sortable-custom-ghost",
        // chosenClass: "sortable-custom-chosen",
        // dragClass: "sortable-custom-drag",
      };
    },
    parentId() {
      return this.treeItem ? this.treeItem.id : null;
    },
    folderItems() {
      const { canCreateResource } = this.feature;

      const baseQuery = TreeItem.query().
                                 where('parent_id', this.parentId).
                                 orderBy('position');

      if (canCreateResource) {
        return baseQuery.get();
      } else {
        const items = baseQuery.where((_treeItem, query) => {
          query.has('children').
                orWhere('type', ['dashboard', 'table'])
        }).get();

        return filter(items, 'containsDeepResources');
      }
    }
  },
  methods: {
    updateTreeItemPosition(event) {
      TreeItem.update({ // to hide item when waiting api response
        where: event[Object.keys(event)[0]].element.id,
        data: { parent_id: -1 }
      })

      let eventData;
      if (event.added) {
        eventData = event.added;
      } else if (event.moved) {
        eventData = event.moved;
      }

      if (eventData) {
        TreeItem.updatePosition(eventData.element.id, this.parentId, eventData.newIndex + 1);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
.tree-item-container {
  margin: 0;
}
@media (min-width: 768px) {
  .tree-item-container {
    max-width: 20rem;
  }
}
</style>
