<template lang="pug">
  .cell-content.reference-entry-content.selected
    .entry-reference(
      v-for="user in entry.displayValue"
      :key="user.id"
      @dblclick.capture.stop
    ) {{ user.displayName }}
      span.ml-1.remove-reference-btn(
        v-if="entry.isWritable"
        @click.stop="removeUser(user, entry)"
      )
        i.far.fa-times

    .add-reference-btn.ml-1(
      v-if="canAddUser"
      @click.stop="openAddUserModal"
      @dblclick.stop
    )
      i.fa.fa-plus

    .reference-entry-expand-content(
      @click="openEntryUsersModal()"
      @dblclick.stop
    )
      i.fad.fa-expand-alt
</template>

<script>
import { UsersMixin } from '../../../mixins/entries/UsersMixin';

export default {
  mixins: [UsersMixin],
  props:  {
    entry: {
      type: Object,
      required: true
    }
  }
};
</script>
