<template lang="pug">
.record-sorts.pb-2.px-1
  p.font-italic.font-weight-light(v-if="editableSorts.length === 0")
    | {{ $t('controlBarActions.noSorting') }}
  .mb-3
    .d-flex.justify-content-between.mb-2(
      v-for="(sortOrder, index) in editableSorts"
      :key="sortOrder.id"
    )
      span.record-sorts--remove.mr-2(
        @click.stop="removeSort(sortOrder.id, index)"
      )
        i.fas.fa-w.fa-times
      span.flex-grow-1.mr-2.pl-1.mr-3
        span.record-sorts--sort-by
          | {{ index === 0 ? $t('views.sortBy') : $t('views.thenBy') }}
        span(
          :class="{ [translationFallbackClasses]: sortOrder.field.nameFallback }"
        ) {{ sortOrder.field.name }}
      span
        span.record-sorts--order(v-for="order in ['asc', 'desc']")
          i.fas.mr-1(
            :class="[\
              'fa-chevron-circle-' + (order === 'asc' ? 'down' : 'up'),\
              { selected: sortOrder.order === order }\
            ]"
            @click.stop="updateSortOrder(sortOrder, order)"
          )
  v-select(
    :searching="true"
    :options="sortableFields"
    :get-option-label="option => option.name"
    :get-option-key="option => option.id"
    :value="null"
    :clearable="false"
    :append-to-body="true"
    :placeholder="$t('views.selectSort')"
    @input="addSort"
  )
    template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}
  .pt-2.mt-2.border-top.border-light(v-if="sortChanged")
    a.text-secondary.mr-3.cursor-pointer(@click.stop.prevent="restoreSorts") {{ $t('controlBarActions.restoreSorts') }}
</template>

<script>
import { uniqueId, map, find, cloneDeep, isEqualWith } from 'lodash';
import { mapState } from 'vuex';
export default {
  props: {
    fields: {
      type: Array,
      required: true
    },
    originalSorts: {
      type: Array,
      required: true
    },
    appliedSorts: {
      type: Array,
      required: false
    },
    sortingRecords: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      editableSorts: []
    }
  },
  computed: {
    ...mapState(['translationFallbackClasses']),
    sortableFields() {
      const sortedFieldIds = map(this.editableSorts, 'field.id');

      return this.fields.filter((field) => {
        return !sortedFieldIds.includes(field.id);
      });
    },
    currentSortsPayload() {
      return this.editableSorts.map((sort) => {
        return {
          id:       sort.id,
          field_id: sort.field.id,
          order:    sort.order,
        }
      });
    },
    sortChanged() {
      return !isEqualWith(this.originalSorts, this.currentSortsPayload, (_a, _b, i) => i === "id" || undefined);
    }
  },
  watch: {
    currentSortsPayload(newValue, _oldValue) {
      this.$emit('sorts-payload-changed', cloneDeep(newValue));
    }
  },
  created() {
    if (this.appliedSorts.length) return this.buildEditableSorts(this.appliedSorts);
    this.buildEditableSorts(this.originalSorts);
  },
  methods: {
    addSort(field) {
      this.editableSorts.push({ id: uniqueId('$sort'), field, order: 'asc' });
    },
    removeSort(_sortId, index) {
      this.editableSorts.splice(index, 1);
    },
    updateSortOrder(sort, order) {
      sort.order = order;
    },
    buildEditableSorts(sorts) {
      this.editableSorts = sorts.map(sort => {
        const id    = sort.id || uniqueId('$sort');
        const field = find(this.fields, ['id', sort.field_id]);
        const order = sort.order || 'asc';

        return { id, field, order };
      });
    },
    restoreSorts() {
      this.buildEditableSorts(this.originalSorts);
    }
  }
}
</script>
