<template lang="pug">
  .view-gallery.overflow-auto.grid-wrapper
    .container-fluid.d-flex.justify-content-center
      .row.vw-100
        GalleryCard.col.col-sm-6.col-mt-4.col-lg-3.col-xl-2(
          v-for="record in records"
          :key="record.id"
          :record="record"
          :hasImage="hasImage"
          @open-record-modal="openRecordModal(record.id)"
        )
</template>

<script>
import { EventBus }             from "../main.js";
import GalleryCard              from "./GalleryCard.vue";
import { mapState, mapGetters } from "vuex";
import { some }                 from 'lodash';

export default {
  components: {
    GalleryCard,
  },
  computed: {
    ...mapGetters({
      records:       'recordStore/visibleRecords',
      visibleFields: 'fieldStore/visibleFields'
    }),
    hasImage(){
      return some(this.visibleFields, { 'dataType': 'files'});
    }
  },
  methods:{
    openRecordModal(recordId) {
      EventBus.$emit("openModal", {
        modalName:  'RecordModal',
        modalProps: { id: recordId }
      });
    }
  }
}
</script>
