<template lang="pug">
.dashboard-kpis-card
  template(v-if="settingsAreReady")
    .dashboard-kpis-card--title {{ title }}
    .dashboard-kpis
      .dashboard-kpi(
        v-for="kpi in items"
        :title="kpi.description"
      )
        .dashboard-kpi--icon.bg-project-secondary-100.text-project-secondary
          i.fad.fa-fw(:class="'fa-' + kpi.icon")
        div
          .dashboard-kpi--label {{ kpi.label }}
          div
            span.dashboard-kpi--value {{ kpi.value }}
            span.dashboard-kpi--unit {{ kpi.unit }}
  .flex-center.h-100(v-else)
    div
      h2.text-center.text-warning
        i.fas.fa-exclamation-triangle
      p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}
</template>

<script>
import { EventBus }             from '../../main';
import DashboardBlock           from '../../models/dashboardBlock';
import { DashboardBlockMixin }  from '../../mixins/DashboardBlockMixin';

export default {
  mixins: [DashboardBlockMixin],
  data() {
    return {

      items: [],
      title: '',
    }
  },
  mounted() {
    this.loadKpis();
  },
  computed: {
    settingsAreReady() {
      return this.dashboardBlock;
    },
  },
  methods: {
    loadKpis() {
      DashboardBlock.api().withKpis(this.dashboardBlock.id).then((response) => {
        this.items = response.response.data.items;
        this.title = response.response.data.title;
      });
    }
  },
}
</script>
