import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    url: String
  }

  resendInvitation() {
    fetch(this.urlValue, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
      }
    })
    .then(response => response.json())
    .then((data) => {
      navigator.clipboard.writeText(data.url).then(() => location.reload());
    });
  }
}
