import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dataImportParamsInput", "dataImportParamsSubmitBtn"]

  requireTemplate() {
    const dataImportParamsValue = this.dataImportParamsInputTarget.value;

    if (dataImportParamsValue) {
      this.dataImportParamsSubmitBtnTarget.disabled = false;
      this.dataImportParamsSubmitBtnTarget.classList.remove("tw-hidden");
    } else {
      this.dataImportParamsSubmitBtnTarget.disabled = true;
      this.dataImportParamsSubmitBtnTarget.classList.add("tw-hidden");
    }
  }
}
