import { apiClient, startFetch, endFetch } from '../client';

export default {
  delete({ projectFileId, fieldId, recordId }) {
    const saver = startFetch('SAVING');
    return apiClient.delete('/records/'  + recordId + '/entry/project_files/' + projectFileId, {
      params: {
        field_id: fieldId
      }
    }).then(endFetch(saver))
  }
}
