<template lang="pug">
  .mt-3
    .form-group
      label {{ $t('automations.labels.table') }}
      v-select(
        :searching="true"
        :options="tablesProp"
        label="name"
        :reduce="option => option.id"
        v-model="tableId"
      )

    .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
      button.btn.btn-primary(
        @click.stop="updateAutomationModule"
        :disabled="!tableId"
      ) {{ $t('save') }}
</template>

<script>
import { api } from '../../../../api/client';

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    tablesProp: {
      type: Array,
      required: true,
    },
    tableIdProp: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tableId: null,
    }
  },
  created() {
    this.tableId = this.tableIdProp;
  },
  methods: {
    updateAutomationModule() {
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        kind:               'when_record_created',
        tableId:            this.tableId,
      });
    }
  }
}
</script>
