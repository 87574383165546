<template lang="pug">
  .btn-group.mobile-list-view
    .btn-view.d-flex.align-items-center.overflow-hidden.px-3.py-2.bg-gray-100.rounded(@click="openMenu")
      i.far.fa-chevron-up.fa-fw.mr-2.view-action--btn-icon.view-action--btn.drop-icon
      i.far.mr-2(:class="'fa-'+ tableIconFor(currentView.type)")
      .text-mobile {{ currentView.name }}
    .menu-list-view.vw-100(v-show="show" v-click-outside="closeAction")
      .d-flex.align-items-center.justify-content-between
        div
          i.far.fa-search.mr-2
          input.search-view.mr-2.border-0(
            v-focus
            type="text"
            autocomplete="off"
            :placeholder="$t('controlBarActions.searchView')"
            v-model="filterElementsInput"
            :key="selectedElementId"
          )
        .ctrlbar-action--close(@click.stop="closeAction")
          svg.icon
            use(xlink:href="#icon-times-solid")
      .dropdown-divider
      .menu-body.overflow-auto
        .d-flex.align-items-center.px-3.py-2.rounded.view-option.overflow-hidden(
          v-for="filteredView in filteredElements"
          :class="{ disabled: filteredView.id == currentView.id }"
          @click.stop="fetchView(filteredView.id)"
          @mouseover="selectedViewId = filteredView.id"
          :data-id="filteredView.id"
          v-scrollIntoView.condition="filteredView.id === selectedViewId"
        )
          i.far.mr-2(:class="'fa-'+ tableIconFor(filteredView.type)")
          .text-truncate.text-mobile(
            :title="filteredView.name"
          ) {{ filteredView.name }}
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { api }                  from '../../api/client';
import { selectListMixin }      from '../../mixins/selectListMixin';

export default {
  mixins: [selectListMixin],
  data() {
    return {
      show:             false,
      filterViewsInput: "",
      selectedViewId:   null,
    };
  },
  computed: {
    ...mapState({
      currentView: state => state.viewStore.view,
    }),
    ...mapGetters({
      currentViews: 'viewStore/currentViews'
    }),
    filteredViews() {
      if (this.currentViews.length) {
        return this.currentViews.filter(view => {
          return view.name
            .toLowerCase()
            .includes(this.filterViewsInput.toLowerCase());
        });
      } else {
        return this.currentViews;
      }
    },
    selectListElements() {
      return this.currentViews;
    }
  },
  watch: {
    show: function(newValue, oldValue) {
      if (newValue) {
        this.selectedViewId = this.currentView.id;
      } else {
        this.selectedViewId = null;
      }
    },
  },
  methods: {
    openMenu(){
      this.show = true;
    },
    closeAction() {
      this.filterElementsInput = "";
      this.show                = false;
    },
    tableIconFor(viewType) {
      const icons = {
        calendar: "calendar-alt",
        grid:     "th",
        gallery:  "th-large"
      };

      return icons[viewType];
    },
    elementSelected(element) {
      this.fetchView(element.id);
    },
    fetchView(viewId) {
      if (viewId === this.currentView.id) return this.closeAction();

      this.$store.dispatch("viewStore/fetchView", { viewId });
      this.closeAction();
    }
  }
};
</script>
