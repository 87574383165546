import { mapGetters } from 'vuex';

export const TableRelationshipMixin = {
  computed: {
    ...mapGetters({
      getTabledById: 'tableStore/getTableById',
    }),
  },
  methods: {
    recordTableIsChild(record) {
      return record.table.relationalData.child;
    },
    tableIsChild(table) {
      return table.relationalData.child;
    },
    tableHasRelationship(table) {
      return !!table.hasRelationship;
    },
    recordRelationshipFieldId(record) {
      return record.table.relationalData.foreign_table_reference_field_id;
    }
  }
};
