import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["input"]

  syncrhonizeInputs(event) {
    this.inputTargets.forEach((input) => {
      const maxValue = Number.parseFloat(event.target.max);
      input.value = event.target.value || (maxValue / 2).toFixed(2);
    });
  }
}
