<template lang="pug">
div
  .dashboard-block--header.pb-3
    h2.mb-1.pr-3(
      :class="{ [translationFallbackClasses]: titleFallback }"
    ) {{ dashboardBlock.title }}
  .tw-p-6.tw-bg-white.tw-rounded.tw-shadow-sm.flex-grow-1.text-gray-800.text-justify.markdown-container(
    v-html="displayText"
  )
</template>

<script>
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import i18n from '../../locales/locales.js';
import { marked } from 'marked';

export default {
  mixins: [DashboardBlockMixin],
  computed: {
    localeText() {
      return this.dashboardBlock.kind_settings[`text_${i18n.locale}`];
    },
    displayText() {
      if (!this.localeText) return;

      const renderer = new marked.Renderer();
      renderer.link = (href, title, text) => `<a target="_blank" href="${href}" title="${title}">${text}</a>`;

      return marked.parse(this.localeText, { renderer: renderer });
    },
  },
}
</script>
