<template lang="pug">
.form-control.date-input
  date-picker(
    v-model="tmpDate"
    :lang="this.$i18n.locale"
    :type="dateInputType"
    value-type="date"
    :format="dateFormat"
    :placeholder="dateFormat"
    :minute-step="5"
    :show-second="false"
    :confirm="true"
    @close="handleChange"
    @clear="handleChange"
  )
</template>

<script>
import { DatepickerMixin } from '../../../mixins/entries/DatepickerMixin';

export default {
  mixins: [DatepickerMixin]
};
</script>
