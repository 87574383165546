import Record from './record';

export default class Table {
  constructor(baseTable) {
    this.id = baseTable.id;
    this.name = baseTable.name;
    this.primaryFieldId = baseTable.primary_field_id;
    this.relationalData = baseTable.relational_data || {};
    this.locked = baseTable.locked || false;
    this.importEnabled = baseTable.import_enabled !== undefined ? baseTable.import_enabled : true;
    this.recordCreationEnabled = baseTable.record_creation_enabled !== undefined ? baseTable.record_creation_enabled : true;
    this.recordDeletionEnabled = baseTable.record_deletion_enabled !== undefined ? baseTable.record_deletion_enabled : true;
    this.nameFallback = baseTable.name_fallback || false;

    this.records     = [];
    this.recordsById = {};

    this.fields  = [];
    this.views   = [];
    this.query   = { logicalOperator: null, filters: [] };
    this.slug    = '';

    this.lockedRecordIds        = [];
    this.fieldIdsForLockRecords = [];

    this.editName = false; // for name update
  }

  updateWithTableData(tableData) {
    const records = Record.build(tableData.records, this, { buildEntries: false, creationConfirmed: true });

    records.forEach(record => this.recordsById[record.id] = record);

    this.records  = records;
    this.slug     = tableData.slug;
    this.query    = { filters: tableData.filters, logicalOperator: tableData.logicalOperator };

    this.lockedRecordIds        = tableData.lockedRecordIds;
    this.fieldIdsForLockRecords = tableData.fieldIdsForLockRecords;
    this.recordValidations      = tableData.record_validations
  }

  lockRecords() {
    this.lockedRecordIds.forEach(recordId => {
      const record = this.records.find(record => record.id === recordId);
      record.lock();
    });
  }

  get hasRelationship() {
    const rData          = this.relationalData
    const foreignTableId = rData.foreign_table_id;
    const foreignTable   = rData.child ? rData.parentTable : rData.childTable;

    return !!foreignTableId && !!foreignTable;
  }

  get hasRepeateableSourceField() {
    return !!this.fields.find(field => field.typeOptions.repeatable_record_source);
  }

  get isParent() {
    return this.hasRelationship && !this.relationalData.child;
  }

  get childTable() {
    if (!this.hasRelationship) return;

    const rData = this.relationalData;
    const foreignTable = rData.child ? rData.parentTable : rData.childTable;

    return foreignTable;
  }

  static build(baseTables) {
    if (Array.isArray(baseTables)) {
      return baseTables.map(baseTable => new Table(baseTable));
    } else {
      return new Table(baseRecords);
    }
  }
}
