import consumer       from './consumer';
import { alertPopup } from '../../components/alert_popup';
import i18n           from "../locales/locales.js";

export const buildSessionChannel = (sessionId) => {
  return consumer.subscriptions.create(
    {
      channel:    "SessionChannel",
      session_id: sessionId,
    },
    {
      connected() {
      },
      received(data) {
        switch (data.action) {
          case "display_alert_popup":
            displayAlertPopup(data.data);
            break;
          case "update_conflict_boolean":
            updateConflictBoolean(data.data);
            break;
        }
      }
    }
  );
};

const displayAlertPopup = (data) => {
  alertPopup(data.message, 'OK');
}

const updateConflictBoolean = (data) => {
  // console.log(data.message);
}
