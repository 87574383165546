import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["togglable"]

  toggle() {
    this.togglableTargets.forEach(togglable => {
      togglable.classList.toggle("tw-hidden");
    });
  }
}
