<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockLists"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.load_shift_list_id"
      )
    .form-group(v-if="editableSettings.load_shift_list_id")
      label {{ $t('dashboardBlocks.list.labels.associations_reference_field_id_date') }}
      v-select(
        :searching="true"
        label="name"
        :options="fieldsForDropTable"
        :reduce="option => option.id"
        v-model="editableSettings.load_shift_date_field_id"
      )
    .form-group(v-if="editableSettings.load_shift_list_id")
      label {{ $t('dashboardBlocks.list.labels.parent_list_reference_field_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="fieldsToFilterFromParentList"
        :reduce="option => option.id"
        v-model="editableSettings.parent_list_reference_field_id"
      )
    .form-group
        label {{ $t('dashboardBlocks.list.labels.parent_list') }}
        v-select(
          :searching="true"
          label="title"
          :options="dashboardBlockLists"
          :reduce="option => option.id"
          v-model="editableSettings.site_list_id"
        )
    .form-group(v-if="editableSettings.site_list_id")
      label {{ $t('dashboardBlocks.list.labels.api_source_id_field_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="fieldsForSiteTable"
        :reduce="option => option.id"
        v-model="editableSettings.api_source_id_field_id"
      )
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { filter }               from 'lodash';
import DashboardBlock from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    selectedDashboardBlockSitesList() {
      if (this.editableSettings.site_list_id) {
        return DashboardBlock.find(this.editableSettings.site_list_id);
      }
    },
    selectedDashboardBlockDropsList() { // we need the list block to know the table it uses
      if (this.editableSettings.load_shift_list_id) {
        return DashboardBlock.find(this.editableSettings.load_shift_list_id);
      }
    },
    fieldsForDropTable() {
      if (this.selectedDashboardBlockDropsList) {
        return this.getTableById(this.selectedDashboardBlockDropsList.kind_settings.table_id).fields;
      }
    },
    fieldsToFilterFromParentList() {
      if (this.selectedDashboardBlockDropsList) {
        return filter(this.fieldsForDropTable, ['dataType', 'references']);
      }
      return [];
    },
    fieldsForSiteTable() {
      if (this.selectedDashboardBlockSitesList) {
        return this.getTableById(this.selectedDashboardBlockSitesList.kind_settings.table_id).fields;
      }
    },
  }
}
</script>
