import { Model }      from '@vuex-orm/core';
import DashboardBlock from './dashboardBlock';
import TreeItem       from './treeItem';

export default class Dashboard extends Model {
  static entity = 'dashboards';

  static fields () {
    return {
      id: this.attr(null),
      title: this.attr(''),
      slug: this.attr(''),
      display_period_selector: this.attr(''),
      period_selector_type: this.attr(''),
      title_fallback: this.boolean(false),
      dashboard_blocks: this.hasMany(DashboardBlock, 'dashboard_id')
    }
  }

  static apiConfig = {
    actions: {
      fetchById(id) {
        return this.get(`/api/dashboards/${id}`).then((response) => {
          this.model.store().dispatch('setCurrentResource', {
            resourceType: 'dashboard',
            resourceId:   id
          });
          return response;
        });
      },
      create(parent_folder_id) {
        return this.post('/api/dashboards', { dashboard: { parent_folder_id } }, { dataTransformer: ({ data }) => { return data.dashboard } }).
          then(({ response, response: { data: { tree_item } }}) => {
            if (tree_item) TreeItem.insert({ data: tree_item });

            return response;
          });
      },
      saveTitle(id, newTitle) {
        return this.patch(`/api/dashboards/${id}`, {
          dashboard: {
            title: newTitle
          }
        }).then(() => {
          if (this.model.store().getters.isCurrentResource(id, 'dashboard')) {
            Dashboard.update({ where: id, data: { title_fallback: false } });
            this.model.store().dispatch('updateDocumentState', Dashboard.find(id).buildDocumentState());
          }
        });
      },
      destroy(id) {
        return this.delete(`/api/dashboards/${id}`, { delete: id }).
          then(() => {
            TreeItem.delete(({ itemId, type }) => itemId === id && type === 'dashboard');
          });
      },
      saveDisplayPeriodSelector(id, newDisplayPeriodSelector) {
        return this.patch(`/api/dashboards/${id}`, {
          dashboard: {
            display_period_selector: newDisplayPeriodSelector
          }
        });
      },
      savePeriodSelectorType(id, newPeriodSelectorType) {
        return this.patch(`/api/dashboards/${id}`, {
          dashboard: {
            period_selector_type: newPeriodSelectorType
          }
        });
      }
    }
  }

  // instance methods
  buildDocumentState() {
    return {
      url: `/dashboards/${this.slug}`,
      title: `dashboards • ${this.title}`,
    }
  }
};
