import Vue from 'vue/dist/vue.esm'

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})

Vue.directive('scrollIntoView', {
  inserted: function (el, binding) {
    if (binding.value) {
      el.scrollIntoView({ block: 'nearest' })
    }
  }
})
