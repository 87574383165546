<script>
export default {
  functional: true,
  props: {
    entry: {
      type:     Object,
      required: true
    }
  },
  render(h, { props }) {
    return h('p', {
      class: 'form-control bg-light h-auto',
    }, props.entry.value);
  }
}
</script>
