import { concat,
         remove,
         some,
         map,
         reject,
         includes,
         findIndex } from 'lodash';
import consumer      from './consumer';
import store         from '../store/store';

export const buildEntryUpdateChannel = (tableId) => {
  return consumer.subscriptions.create(
    {
      channel:  "EntryUpdateChannel",
      table_id: tableId
    },
    {
      received(data) {
        const entriesForOrderCheck = [];

        data.forEach(entryToUpdate => {
          if (entryToUpdate.modified_by_id === store.state.uniqSessionId) return; // no need to update

          const entry = getEntry(entryToUpdate);
          if (entry) {
            updateEntryAccordingType(entry, entryToUpdate);
            entry.buildDisplayValue();
            if (tableId === store.state.tableStore.table.id) {
              highlightUpdatedEntry(entry);
              setTimeout(() => pushRecordIdForCheck(entry), 500) // Tempo to let entries update in db
            }
            entriesForOrderCheck.push(entryToUpdate)
          }
        });

        setTimeout(() => sortRecordsIfNeeded(entriesForOrderCheck), 500);
      }
    }
  );
};

const pushRecordIdForCheck = (entry) => {
  if (entryFieldIsFiltered(entry)) {
    store.dispatch('presenceStore/pushRecordIdForPresenceCheck', { recordId: entry.record.id });
  };
  if (entryFieldIsLockable(entry)) {
    store.dispatch('lockedRecordsStore/pushRecordIdForLockCheck', { recordId: entry.record.id });
  }
}

const getEntry = (entryToUpdate) => {
  let entry;
  const record = store.getters['recordStore/allRecordsAvailable'].find(record => {
    return record.id === entryToUpdate.record_id;
  })
  if (record) entry = record.entriesByFieldId[entryToUpdate.field_id];
  if (entry) return entry;

  return store.getters['recordStore/getEntry'](entryToUpdate.record_id, entryToUpdate.field_id);
};

const highlightUpdatedEntry = (entry) => {
  const entryCell = document.querySelector(
    `.grid-row[data-record-id="${entry.record.id}"] .cell[data-field-id="${entry.field.id}"]`
  );
  if (entryCell) {
    entryCell.classList.add('updated');
    clearTimeout(entry.updateTimeout); // if the animation from previous update is not over yet, clearTimeout and add new one
    entry.updateTimeout = setTimeout(() => entryCell.classList.remove('updated'), 2000); // let animation finish and then remove the class
  }
};

const updateEntryAccordingType = (entry, entryToUpdate) => {
  switch(entryToUpdate.value_type) {
    case 'primitive':
      entry.value = entryToUpdate.value;
      break;
    case 'array':
      updateArrayTypeEntry(entry, entryToUpdate);
      break;
  }
};

const sortRecordsIfNeeded = (entriesToUpdate) => {
  const sortNeeded = some(map(entriesToUpdate, 'field_id'), (fieldId) => {
    return map(store.state.viewStore.view.sortOrders, 'field_id').includes(fieldId);
  });
  if (sortNeeded) {
    store.dispatch("viewStore/updateRowOrder");
  }
};

const entryFieldIsFiltered = (entry) => {
  return store.getters['fieldStore/fieldIsCurrentlyFiltered'](entry.field.id);
};

const entryFieldIsLockable = (entry) => {
  return includes(
    store.state.tableStore.table.fieldIdsForLockRecords,
    entry.field.id
  );
};

const updateArrayTypeEntry = (entry, entryToUpdate) => {
  switch(entryToUpdate.operation) {
    case 'push':
      entry.value = concat(entry.value, entryToUpdate.value);
      break;
    case 'remove':
      entry.value = reject(entry.value, findObjectInArray(entryToUpdate));
      break;
    case 'update':
      const index = findIndex(entry.value, findObjectInArray(entryToUpdate));
      const arr   = [...entry.value];
      arr[index]  = entryToUpdate.value;
      entry.value = arr; // to trigger Vue reactivity and update component
      break;
  }
};

const findObjectInArray = (entryToUpdate) => {
  return item => item[entryToUpdate.key_to_find_name] === entryToUpdate.key_to_find_value;
};
