<template lang="pug">
  .file-manager--file-container
    template(v-if="!projectFile.antivirus_scannable || (projectFile.antivirus_scannable && projectFile.safe)")
      a(
        v-on:click.stop
        :href="`/project_files/${projectFile.id}`"
        target="_blank"
        rel="noopener noreferrer"
        :download="projectFile.filename"
      )
        .file-manager--file-preview-container
          .file-manager--file-preview(
            v-if="$attrs.fileType === 'image'"
            :style="`background-image: url('/project_files/${projectFile.id}')`"
          )
          .file-manager--file-preview(v-else)
            i.fas(
              :class="'fa-' + $attrs.fileType"
            )
        .file-manager--filename.ml-3 {{ projectFile.filename }}

    template(v-else)
      .file-manager--file-preview-container
        .file-manager--file-preview(
          v-if="$attrs.fileType === 'image'"
          :style="`background-image: url('/project_files/${projectFile.id}')`"
        )
        .file-manager--file-preview(v-else)
          i.fas(
            :class="'fa-' + $attrs.fileType"
          )
      .file-manager--filename.ml-3 {{ projectFile.filename }}

    .d-flex.align-items-center
      span.has-tooltip(
        v-if="projectFile.antivirus_scannable && projectFile.safe"
        data-toggle="tooltip"
        :title="$t('fileManager.fileVerified')"
      )
        i.fa.fa-shield-check.shield-icon.checked
      span.has-tooltip(
        v-else-if="projectFile.antivirus_scannable && !projectFile.safe"
        data-toggle="tooltip"
        :title="$t('fileManager.fileNotYetVerified')"
      )
        i.fa.fa-shield-check.shield-icon.unchecked

      .file-manager--delete-btn-container.ml-4(v-if="allowDelete")
        template(v-if="showConfirmDelete")
          .delete-btn-fade.confirm(
            @click="deleteProjectFile"
            @mouseenter="removeDeleteTimer"
            @mouseleave="removeConfirmDelete"
          )
            span.delete-btn-fade--btn
            .delete-btn-fade--link {{ $t('delete') }}
        template(v-else)
          .delete-btn-fade(@click="askConfirmDelete")
            span.delete-btn-fade--btn
              i.fa.fa-trash-alt.delete-icon
</template>

<script>
import { api }          from '../api/client';
import { EventBus }     from '../main.js';

export default {
  props: {
    projectFile: {
      type:     Object,
      required: true,
    },
    entry: {
      type:     Object,
      required: true
    },
    allowDelete: {
      type:     Boolean,
      required: true,
    }
  },
  data() {
    return {
      showConfirmDelete: false,
      confirmDeleteTimer: null,
    }
  },
  methods: {
      askConfirmDelete() {
      this.showConfirmDelete = true;

      this.confirmDeleteTimer = setTimeout(() => this.showConfirmDelete = false, 2000);
    },
    removeConfirmDelete() {
      this.confirmDeleteTimer = setTimeout(() => this.showConfirmDelete = false, 300);
    },
    removeDeleteTimer() {
      clearInterval(this.confirmDeleteTimer);
    },
    deleteProjectFile() {
      this.showConfirmDelete = false

      api.projectFiles.delete({
        projectFileId: this.projectFile.id,
        recordId:      this.entry.record.id,
        fieldId:       this.entry.field.id
      }).then(response => {
          if (response.status === 200) {
            EventBus.$emit('projectFileDeleted', this.projectFile, response);
          }
        })
    },
  }
}
</script>
