<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockList"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.list_id"
      )
    .form-group
      label {{ $t('dashboardBlocks.reportextract.labels.sites_table_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="tables"
        :reduce="option => option.id"
        v-model="editableSettings.sites_table_id"
      )
    .form-group(
      v-for="settingProperty in fieldSettingPropertiesForSites"
      :key="settingProperty"
    )
      label(:for="settingProperty") {{ $t('dashboardBlocks.reportextract.labels.' + settingProperty) }}
      v-select(
        :searching="true"
        label="name"
        :inputId="settingProperty"
        :options="fieldsForSitesTable"
        :reduce="option => option.id"
        v-model="editableSettings[settingProperty]"
      )
    .form-group
      label {{ $t('dashboardBlocks.reportextract.labels.collects_table_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="tables"
        :reduce="option => option.id"
        v-model="editableSettings.collects_table_id"
      )
    .form-group(
      v-for="settingProperty in fieldSettingPropertiesForCollects"
      :key="settingProperty"
    )
      label(:for="settingProperty") {{ $t('dashboardBlocks.reportextract.labels.' + settingProperty) }}
      v-select(
        :searching="true"
        label="name"
        :inputId="settingProperty"
        :options="fieldsForCollectsTable"
        :reduce="option => option.id"
        v-model="editableSettings[settingProperty]"
      )
    .form-group
      label.font-weight-bold.d-block
        | {{ $t("automations.labels.unavailability_days_field_ids") }}
        small.ml-1
          i {{ $t("automations.labels.days_in_order_hint") }}
      v-select.bg-white(
        :options="fieldsForCollectsTable"
        :multiple="true"
        label="name"
        :reduce="o => o.id"
        :close-on-select="false"
        :clear-search-on-select="false"
        :clear-search-on-blur="() => true"
        v-model="editableSettings.unavailability_days_field_ids"
      )
    .form-group
      label.font-weight-bold.d-block
        | {{ $t("automations.labels.periods_field_ids") }}
        small.ml-1
          i {{ $t("automations.labels.days_in_order_hint") }}
      v-select.bg-white(
        :options="fieldsForCollectsTable"
        :multiple="true"
        label="name"
        :reduce="o => o.id"
        :close-on-select="false"
        :clear-search-on-select="false"
        :clear-search-on-blur="() => true"
        v-model="editableSettings.periods_field_ids"
      )
</template>

<script>
import { mapState }   from 'vuex'
import DashboardBlock from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableIdForConfiguration: null,
      fieldSettingPropertiesForSites: [
        'site_name_field_id',
        'site_file_name_field_id'
      ],
      fieldSettingPropertiesForCollects: [
        'site_reference_field_id',
        'first_day_of_week_field_id'
      ]
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    dashboardBlockList() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    sitesTable() {
      if (this.editableSettings.sites_table_id) {
        return this.tables.find(table => table.id === this.editableSettings.sites_table_id);
      }
    },
    collectsTable () {
      if (this.editableSettings.collects_table_id) {
        return this.tables.find(table => table.id === this.editableSettings.collects_table_id);
      }
    },
    fieldsForSitesTable() {
      if (this.sitesTable) {
        return this.sitesTable.fields;
      }
      return [];
    },
    fieldsForCollectsTable() {
      if (this.collectsTable) {
        return this.collectsTable.fields;
      }
      return [];
    }
  },
  watch: {
    'editableSettings.sites_table_id': function(newSettings, oldSettings) {
      this.fieldSettingPropertiesForSites.forEach((fieldSetting) => {
        this.$set(this.editableSettings, fieldSetting, null);
      });
    },
    'editableSettings.collects_table_id': function(newSettings, oldSettings) {
      this.fieldSettingPropertiesForCollects.forEach((fieldSetting) => {
        this.$set(this.editableSettings, fieldSetting, null);
      });
    }
  },
  created() {
    if (!this.editableSettings.unavailability_days_field_ids) {
      this.$set(this.editableSettings, 'unavailability_days_field_ids', []);
    }
    if (!this.editableSettings.periods_field_ids) {
      this.$set(this.editableSettings, 'periods_field_ids', []);
    }
  }
}
</script>
