<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockLists"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.list_id"
      )
    .form-group
      label {{ $t('dashboardBlocks.timescale.labels.chart_kind') }}
      v-select(
        :searching="true"
        :options="chartKindOptions"
        label="name"
        :reduce="option => option.value"
        v-model="editableSettings.chart_kind"
      )
    .form-group
      .d-flex.align-items-center.mr-4
        input.rounded.border(
          type="checkbox"
          :id="`real_time_chart_${this.dashboardBlock.id}`"
          v-model="editableSettings.real_time"
        )
        label.ml-2.mb-0.cursor-pointer(
          :for="`real_time_chart_${this.dashboardBlock.id}`"
        ) {{ $t('dashboardBlocks.sourcedChart.realTime') }}
    hr
    .form-group
      label {{ $t('dashboardBlocks.sourcedChart.labels.sources') }}
      v-select(
        :searching="true"
        :options="sources"
        :multiple="true"
        :get-option-label="option => $t(`dashboardBlocks.sourcedChart.sources.${option}`)"
        v-model="selectedSources"
      )
      template(v-if="selectedSources.length")
        hr(:class="'border-' + (sourceForConfiguration ? 'success' : 'warning')")
        .form-group#select-table-for-schedule-configuration
          label {{ $t('dashboardBlocks.labels.select_source_to_configure') }}
          v-select(
            :searching="true"
            :options="selectedSources"
            :get-option-label="option => $t(`dashboardBlocks.sourcedChart.sources.${option}`)"
            v-model="sourceForConfiguration"
            :class="sourceForConfiguration ? 'table-selected' : 'no-table-selected'"
          )
      template(v-if="sourceForConfiguration")
        SourceSettings(
          :dashboardBlock="dashboardBlock"
          :sourceSettings="editableSettings.settings_by_source[sourceForConfiguration]"
          :fieldSettingProperties="fieldSettingPropertiesForSelectedSource"
          :fieldsForSelectedTable="fieldsForSelectedTable")

</template>


<script>
import { mapGetters } from 'vuex';
import { EventBus } from '../../../main';
import { api } from '../../../api/client';
import DashboardBlock from '../../../models/dashboardBlock';
import { difference } from 'lodash';
import SourceSettings from './sourced_charts_sources/SourceSettings';

export default {
  components: {
    SourceSettings,
  },
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      sourceForConfiguration: null,
      requiredFieldsBySource: {},
    }
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    selectedDashboardBlockList() {
      if (this.editableSettings.list_id) {
        return DashboardBlock.find(this.editableSettings.list_id);
      }
    },
    selectedTable() {
      if (this.selectedDashboardBlockList && this.selectedDashboardBlockList.kind_settings.table_id) {
        const tableId = this.selectedDashboardBlockList.kind_settings.table_id;
        return this.getTableById(tableId);
      }
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
    chartKindOptions() {
      const chartKinds = ['line', 'bar', 'bar_double_axis'];

      return chartKinds.map((chartKind) => {
        return {
          name: this.$t(`charts.${chartKind}`),
          value: chartKind
        }
      })
    },
    sources() {
      return Object.keys(this.requiredFieldsBySource);
    },
    selectedSources: {
      get() {
        return Object.keys(this.editableSettings.settings_by_source);
      },
      set(newSources) {
        const removedSources = difference(Object.keys(this.editableSettings.settings_by_source), newSources);
        removedSources.forEach((removedSource) => {
          this.$delete(this.editableSettings.settings_by_source, removedSource);
        });
        newSources.forEach((source) => {
          if (!this.editableSettings.settings_by_source[source]) {
            this.$set(this.editableSettings.settings_by_source, source, {
              id: this.selectedTable.id,
              columns: {},
              options: {
                display_as_line_in_bar_chart: false,
                force_aggregation: false,
              }
            })
          }
        });
      }
    },
    fieldSettingPropertiesForSelectedSource() {
      return this.requiredFieldsBySource[this.sourceForConfiguration];
    },
  },
  mounted() {
    this.fetchSourcesSettings();
    this.editableSettings.settings_by_source ||= {};
  },
  methods: {
    fetchSourcesSettings() {
      api.sourceSettings.index()
         .then(({ data }) => {
          const requiredFieldsBySource = {};
          Object.keys(data).forEach((source) => {
            requiredFieldsBySource[source] = data[source]["required_fields"];
          });
          this.requiredFieldsBySource = requiredFieldsBySource;
        });
    },
  },
  watch: {
    'dashboardBlock.kind_settings': function(_newSettings, _oldSettings) {
      EventBus.$emit('dashboard-block-load-charts-' + this.dashboardBlock.id);
    },
    'sourceForConfiguration': function(newSourceForConfiguration, _oldSourceForConfiguration) {
      this.editableSettings[newSourceForConfiguration] ||= {
        id: null,
        columns: {},
        options: {
          display_as_line_in_bar_chart: false,
          force_aggregation: false,
        }
      };
    }
  }
}
</script>
