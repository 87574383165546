<template lang="pug">
  PopAction(
    v-if="canDeleteRecord"
    btnClass="p-0"
    placement="bottom-end"
  )
    div(slot="trigger")
      i.pop-action-btn--icon.fas.fa-angle-down.fa-fw.mt-1
    .pt-3.pb-2(slot="content")
      p.mb-0.px-3.py-2.text-nowrap.view-action--btn.hoverable(@click="deleteRecordThenClose")
        i.far.fa-trash-alt.fa-fw
        span.view-action--btn-text.ml-1 {{ $t('rightClick.deleteRecord') }}
</template>
<script>
  import { mapGetters } from "vuex";
  export default {
    props: {
      record: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters({
        allCurrentFieldsAreReadOnly: 'fieldStore/allCurrentFieldsAreReadOnly',
      }),
      canDeleteRecord() {
        return !this.record.locked && !this.allCurrentFieldsAreReadOnly;
      }
    },
    methods: {
      deleteRecordThenClose() {
        this.record.delete(true, () => this.$emit("closeModal"));
      },
    },
  };
</script>
