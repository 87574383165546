<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @click.self="closeModal"
)
  .base-modal(:style="sameModalOffsetStyle")
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")


    //- HEADER
    .base-modal-header.d-flex.align-items-center
      span.bg-indigo-100.p-2.rounded-circle.mr-2(style="line-height: 1.3rem;")
        i.far.fa-pen.fa-fw.text-indigo-800
      .text-xl.text-gray-800.font-weight-bolder {{ $t('editViewOptionsModal.editViewOptions')}}

    //- BODY
    .base-modal-body.my-4
      .form-group
        label(for="redirection-view-id") {{ $t('editViewOptionsModal.editRedirectionView') }}
        v-select.w-100(
          inputId="redirection-view-id"
          :searching="true"
          :options="otherTableViews"
          label="name"
          :placeholder="$t('editViewOptionsModal.chooseRedirectionView')"
          :reduce="view => view.id"
          v-model="formRedirectionViewId"
          :append-to-body="true"
        )

    //- FOOTER
    .base-modal-footer.mt-4.d-flex.justify-content-between
      button.btn.btn-cancel(@click.stop="closeModal")
        span {{ $t('cancel') }}

      button.btn.default-btn(@click="updateViewOptions") {{ $t('save') }}
</template>

<script>
import { api } from '../../api/client';
import { mapState } from 'vuex';
import { BaseModalMixin } from "./BaseModalMixin";
import { cloneDeep } from "lodash";

export default {
  mixins: [BaseModalMixin],
  data() {
    return {
      formRedirectionViewId: null,
    }
  },
  computed: {
    ...mapState({
      currentView: state => state.viewStore.view,
      tableViews: state => state.tableStore.table.views,
    }),
    otherTableViews() {
      return this.tableViews.filter(view => view !== this.currentView);
    },
  },
  mounted() {
    this.formRedirectionViewId = this.currentView.typeOptions.form_redirection_view_id;
  },
  methods: {
    onModalOpen() {
    },
    onModalClose() {
    },
    updateViewOptions() {
      const typeOptions = cloneDeep(this.currentView.typeOptions);

      typeOptions.form_redirection_view_id = this.formRedirectionViewId;

      api.views.update({
        view: {
          id: this.currentView.id,
          type_options: typeOptions,
        }
      }).then(response => {
          this.currentView.typeOptions.form_redirection_view_id = response.data.view.type_options.form_redirection_view_id;
          this.closeModal();
        })
    }
  },
};
</script>

<style lang="scss" scoped and-dirty>
  .base-modal {
    max-width: 500px;
    min-width: 350px;
  }
</style>
