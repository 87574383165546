<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
)
  .base-modal.modal-mobile.overflow-auto
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")

    //- HEADER
    .base-modal-header.d-flex.align-items-center.mb-5
      span.bg-indigo-100.p-2.rounded-circle.mr-2(style="line-height: 1.3rem;")
        i.far.fa-sort-alt.fa-fw.text-indigo-800
      .text-xl.text-gray-800.font-weight-bolder {{ $t('controlBarActions.sort') }}

    //- BODY
    .my-4.modal-body-mobile.overflow-auto
      p.action-option--placeholder.my-1.ctrlbar-action--text.font-italic.pl-1(
        v-show="!currentView.sortOrders.length"
      ) {{ $t('controlBarActions.noSorting') }}
      .ctrlbar-action--action-option.my-1(
        v-for="(sortOrder, index) in currentView.sortOrders"
        :key="sortOrder.field_id + '-selected'"
      )
        .action-option--remove(@click.stop="removeSort(sortOrder, index)")
          i.fal.fa-times
        .action-option--label {{ index === 0 ? $t('views.sortBy') : $t('views.thenBy') }}
        .ctrlbar-action--text.action-option--name.h5-font-size.font-weight-normal.px-1.text-nowrap(
          :title="fieldName(sortOrder.field_id)"
          :class="{ [translationFallbackClasses]: isNameFallback(sortOrder.field_id) }"
        ) {{ fieldName(sortOrder.field_id) | truncate(30) }}
        .action-option--order
          i.fas.fa-2x.fa-chevron-circle-down.mr-1(
          :class="{ selected: sortOrder.order === 'asc' }"
          @click="changeOrder(sortOrder, 'asc')"
        )
          i.fas.fa-2x.fa-chevron-circle-up(
          :class="{ selected: sortOrder.order === 'desc' }"
          @click="changeOrder(sortOrder, 'desc')"
        )
    //- FOOTER
    .base-modal-footer.mt-4.d-flex.flex-column.justify-content-center.align-items-center
      .glint-select--wrapper.mt-2.mb-1
        select.glint-select--input.glint-select--input-sm.glint-select--input-wide(
          @change="addSort"
          :value="$t('views.selectSort')"
        )
          option(disabled) {{ $t('views.selectSort') }}
          option(
            v-for="field in sortableFields"
            :value="field.id"
            :key="field.id"
          ) {{ field.name }}
        i.fas.fa-chevron-down.glint-select--icon
      button.btn.default-btn(@click.stop="closeModal")
        span {{ $t('close') }}
</template>

<script>
import { MobileModalMixin } from "./MobileModalMixin.js";
import { api } from '../../../api/client';
import { BaseModalMixin } from "../BaseModalMixin";
import { mapState } from 'vuex';

export default {
  mixins: [BaseModalMixin, MobileModalMixin],
  computed: {
    ...mapState(['translationFallbackClasses']),
    sortableFields() {
      return this.orderedFields.filter(field => !this.currentView.sortOrders.map(sortOrder => sortOrder.field_id).includes(field.id))
    }
  },
  methods: {
    fieldName(fieldId) {
      return this.orderedFields.find(field => field.id === fieldId).name
    },
    isNameFallback(fieldId) {
      return this.orderedFields.find(field => field.id === fieldId).nameFallback;
    },
    addSort(event) {
      const sortOrder = { field_id: parseInt(event.target.value) }

      api.sortOrders.create({
        viewId: this.currentView.id,
        sortOrder
      }).then(response => {
        this.currentView.sortOrders.push(response.data.sort_order)

        this.applySort(response)
      });
    },
    removeSort(sortOrder, index) {
      this.currentView.sortOrders.splice(index, 1)
      api.sortOrders.delete({
        viewId: this.currentView.id,
        sortOrder
      }).then(this.applySort)
    },
    changeOrder(sortOrder, order) {
      sortOrder.order = order
      api.sortOrders.update({
        viewId: this.currentView.id,
        sortOrder
      }).then(this.applySort)
    },
    applySort({ data: { sorted_records_ids } }) {
      if (this.currentView.sortOrders.length) {
        this.currentView.sortedRecordsIds = sorted_records_ids;
      } else {
        this.currentView.applyDefaultSortOrder();
      }
    }
  }
};
</script>
