export default class Feature {
  static build(featureData) {
    this.canAddFields = featureData.can_add_fields;
    this.canEditFields = featureData.can_edit_fields;
    this.canCreateProfileView = featureData.can_create_profile_view;
    this.canLockViews = featureData.can_lock_views;
    this.canUseViewAlerts = featureData.can_use_view_alerts;
    this.canCreateResource = featureData.can_create_resource;
    this.canCreateFormView = featureData.can_create_form_view;
    this.canEditRecordValidations = featureData.can_edit_record_validations;

    return this;
  }
}
