<template lang="pug">
  .h-100.dashboard-map(v-if="settingsAreReady")
    .dashboard-block--header.pb-3
      h2 {{ tableForMarkers.name }}
    resize-observer(@notify="handleResize")
    Mapbox(
      v-if="canDisplayMap && !editMode"
      ref="mapElement"
      :access-token                    ="mapboxApiKey"
      :map-options                     ="mapOptions"
      :nav-control                     ="navControl"
      @map-load                        ="loaded"
      @map-click:clusters              ="clickedClusters"
      @map-click:unclustered-point     ="clickedPoint"
      @map-mouseenter:clusters         ="mouseEnteredCluster"
      @map-mouseleave:clusters         ="mouseLeftCluster"
      @map-mouseenter:unclustered-point="mouseEnteredPoint"
      @map-mouseleave:unclustered-point="mouseLeftPoint"
    )
    .flex-center.h-100(v-else)
      i.fas.fa-map-marked-alt.fa-5x
  .flex-center.h-100(v-else)
    div
      h2.text-center.text-warning
        i.fas.fa-exclamation-triangle
      p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}
</template>

<script>
import { map, debounce, isEqual, find, every } from 'lodash';
import Mapbox                                  from 'mapbox-gl-vue';
import { mapGetters, mapState }                from 'vuex';
import DashboardBlock                          from '../../models/dashboardBlock';
import { DashboardBlockMixin }                 from '../../mixins/DashboardBlockMixin';
import { EventBus }                            from '../../main';
import DashboardSharedItem                     from '../../models/dashboardSharedItem';
import i18n                                    from "../../locales/locales.js";

export default {
  mixins: [DashboardBlockMixin],
  components: {
    Mapbox
  },
  props: {
    editMode: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      canDisplayMap: false,
      mapIsloaded:    false,
      mandatorySettings: [
        'list_id',
        'longitude_field_id',
        'latitude_field_id',
        'title_field_id',
        'detail_field_id'
      ],

      // map base options
      mapOptions:     {
        style:   'mapbox://styles/mapbox/light-v9',
        center:  i18n.t('dashboardBlocks.map.default_coordinates'),
        zoom:    5,
        maxZoom: 12
      },
      navControl: {
        show: false
      },
    }
  },
  computed: {
    ...mapState({
      mapboxApiKey: state => state.mapboxApiKey,
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    alertColors() {
      return [
        '#9CA3AF', // Tailwind gray 400
        this.dashboardBlockAlerts?.kind_settings.level_one_color || '#FCD263',
        this.dashboardBlockAlerts?.kind_settings.level_two_color || '#F79C34',
        this.dashboardBlockAlerts?.kind_settings.level_three_color || '#DF1A2B'
      ]
    },
    settingsAreReady() {
      return this.dashboardBlock &&
              this.dashboardBlockList &&
              this.dashboardBlockList.kind_settings.table_id &&
              this.dashboardBlockList.kind_settings.view_id &&
              every(this.mandatorySettings.map(setting => {
                return this.dashboardBlock.kind_settings[setting];
              }));
    },
    dashboardBlockListItems() {
      return DashboardSharedItem.listItems(this.dashboardBlockList.id);
    },
    dashboardBlockAlertsId() {
      return this.dashboardBlock.kind_settings.alert_id;
    },
    dashboardBlockAlertItems() {
      return DashboardSharedItem.alertItems(this.dashboardBlockAlertsId, true).
               where('metadata', md => md.site_record_id).get();
    },
    itemsCheckedByRecordId() {
      const items = this.dashboardBlockListItems;

      return items.reduce((records_by_id, item) => {
        records_by_id[item.item_id] = item.metadata.checked
        return records_by_id;
      }, {});
    },
    dashboardBlockList() { // we need the list block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.list_id);
    },
    dashboardBlockAlerts() {
      if (!this.dashboardBlockAlertsId) return;

      return DashboardBlock.find(this.dashboardBlockAlertsId);
    },
    tableForMarkers() {
      if (this.settingsAreReady) {
        return this.getTableById(this.dashboardBlockList.kind_settings.table_id);
      }
    },
    maximumAlertLevelPerSite() {
      return this.dashboardBlockAlertItems.reduce((alertLevelPerSite, alertItem) => {
        const { level: alertLevel, site_record_id: siteRecordId } = alertItem.metadata;

        const siteAlertLevel = alertLevelPerSite[siteRecordId];

        if (!(siteAlertLevel && siteAlertLevel >= alertLevel)) {
          alertLevelPerSite[siteRecordId] = alertLevel;
        }
        return alertLevelPerSite;
      }, {});
    },
    // GeoJSON data that serves as source to the map
    markers() { // records to display on map
      const markersObject = { 'type': 'FeatureCollection', features: [] };

      if (this.tableForMarkers) {
        markersObject.features = this.tableForMarkers.records.reduce((markers, record) => {
          if (this.itemsCheckedByRecordId[record.id]) {
            markers.push(this.buildMarker(record));
          }
          return markers;
        }, []);
      }
      return markersObject;
    },
    // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#case
    // and https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#get
    // for more information
    circleColorCase() {
      return [
        'case',
        ...[0, 3, 2, 1].flatMap(n => [this.alertLevelIs(n), this.alertColors[n]]),
        this.alertColors[0],
      ];
    }
  },
  mounted() {
    this.displayMap();
  },
  watch: {
    'markers': function (newMarkers, oldMarkers) {
      const newRecordIds = map(newMarkers.features, 'properties.recordId').sort();
      const oldRecordIds = map(oldMarkers.features, 'properties.recordId').sort();

      if (!isEqual(newRecordIds, oldRecordIds) && this.mapIsloaded && !this.editMode) {
        this.refreshMap();
      }
    },
    mapIsloaded: function (mapIsLoaded, mapWasLoaded) {
      if (mapIsLoaded && !mapWasLoaded && !this.editMode) { this.refreshMap(); }
    },
    editMode: function(newValue, oldValue) {
      if (!newValue && oldValue) { this.refreshMap(); }
    },
    dashboardBlockAlertItems: function(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) { this.refreshMap(); }
    },
  },
  methods: {
    loaded() {
      this.mapIsloaded = true;
    },
    refreshMap: debounce(function() {
      if (!this.settingsAreReady) return;
      this.addMarkersAndClusters();
    }, 500, { leading: false, trailing: true }),
    displayMap() {
      this.$nextTick(() => this.canDisplayMap = true);
    },
    cleanMap(map) {
      if (map.getLayer('unclustered-point')) map.removeLayer('unclustered-point')
      if (map.getLayer('cluster-count'))     map.removeLayer('cluster-count')
      if (map.getLayer('clusters'))          map.removeLayer('clusters')
      if (map.getSource('points'))           map.removeSource('points')
    },
    addMarkersAndClusters() {
      if (!this.$refs.mapElement) return;

      const map = this.$refs.mapElement.map;

      // CLEAN MAP IF SOURCE AND LAYERS ARE ALREADY PRESENT
      this.cleanMap(map);
      // ADD GEOJSON SOURCE TO THE MAP
      map.addSource('points', {
        'type': 'geojson',

        // Enable clustering
        'cluster': true,
        'clusterProperties': {
          'alertLevel': ['max', ['get', 'alertLevel']]
        },
        clusterMaxZoom: 11,
        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
        'data': this.markers
      });

      // LAYOUT FOR CLUSTERS - CIRCLE
      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'points',
        filter: ['has', 'point_count'],
        paint: {
          // set cluster color depending of max alert level in cluster's points
          'circle-color': this.circleColorCase,
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20, // * 20px circles when point count is less than 2
            2,
            30, // 30px circles when point count is between 2 and 5
            5,
            40 // * 40px circles when point count is greater than or equal to 5
          ],
          'circle-opacity': 0.6,
          'circle-stroke-color': '#6B7280', // Tailwind gray 500
          'circle-stroke-width': 0.5
        }
      });

      // LAYOUT FOR CLUSTERS - TEXT
      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'points',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12
        },
        paint: {
          'text-color': 'black'
        }
      });

      // LAYOUT FOR SINGLE POINTS
      map.addLayer({
        id: 'unclustered-point',
        type: 'circle',
        source: 'points',
        filter: ['!', ['has', 'point_count']],
        paint: {
          // Define the point's color depending on its 'alertLevel' property
          'circle-color': this.circleColorCase,
          'circle-opacity': 0.6,
          'circle-radius': 12
        }
      });

      // FIT THE MAP TO THE MARKERS
      if (this.markers.features.length) {
        const bounds = new mapboxgl.LngLatBounds();

        this.markers.features.forEach((feature) => {
          bounds.extend(feature.geometry.coordinates);
        });

        map.fitBounds(bounds, { padding: 60 });
      }

    },

    // ZOOM IN A CLUSTER ON CLICK
    clickedClusters(map, e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      var clusterId = features[0].properties.cluster_id;
      map.getSource('points').getClusterExpansionZoom(
        clusterId,
        function (err, zoom) {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        }
      );
    },
    mouseEnteredCluster(map) {
      map.getCanvas().style.cursor = 'pointer';
    },
    mouseLeftCluster(map) {
      map.getCanvas().style.cursor = '';
    },
    clickedPoint(map, e) { // When point is clicked, item is opened is list to see details and zoomed on map
      const recordId = e.features[0].properties.recordId;
      this.openRecordDetails(recordId);
    },
    mouseEnteredPoint(map) {
      map.getCanvas().style.cursor = 'pointer';
    },
    mouseLeftPoint(map) {
      map.getCanvas().style.cursor = '';
    },
    buildMarker(record) {
      const titleEntry       = record.entriesByFieldId[this.dashboardBlock.kind_settings.title_field_id];
      const longitudeEntry   = record.entriesByFieldId[this.dashboardBlock.kind_settings.longitude_field_id];

      if (!longitudeEntry) return; // We need to get this before continuing

      const latitudeEntry    = record.entriesByFieldId[this.dashboardBlock.kind_settings.latitude_field_id];
      const recordAlertLevel = this.recordAlertLevel(record);

      return {
        type:     'Feature',
        geometry: {
          type:        'Point',
          coordinates: [longitudeEntry.value, latitudeEntry.value]
        },
        properties: {
          location:   titleEntry.toString(),
          alertLevel: recordAlertLevel,
          recordId:   record.id
        }
      }
    },
    recordAlertLevel(record) {
      return this.maximumAlertLevelPerSite[record.id] || 0;
    },
    handleResize: debounce(function() {
      this.canDisplayMap = false;

      this.displayMap();
    }, 500, { trailing: true }),
    openRecordDetails(recordId) {
      EventBus.$emit('open-record-details-' + this.dashboardBlockList.id, recordId);
    },
    alertLevelIs(level) {
      return ['==', ['get', 'alertLevel'], level];
    }
  }
}
</script>
