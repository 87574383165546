<template lang="pug">
  section.my-3
    .form-group(v-if="currentUser.superAdmin")
      input.cursor-pointer(
        id="repeatable-record-source"
        v-model="editedField.typeOptions.repeatable_record_source"
        type="checkbox"
        :disabled="!canEditField"
      )
      label.cursor-pointer.ml-1.font-weight-bolder(for="repeatable-record-source") {{ $t("fields.useToRepeatRecords") }}
</template>

<script>
import { mapState } from 'vuex'

  export default {
    props: {
      editedField: {
        type: Object,
        required: true
      },
      canEditField: {
        type: Boolean,
        required: true
      },
    },
    computed: {
      ...mapState(['currentUser'])
    }
  }
</script>
