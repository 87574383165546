<template lang="pug">
  .dashboard-live(ref="blockElement")
    template(v-if="settingsAreReady")
      .dashboard-live-items--header(ref="headerElement")
        h2.mb-1.pr-3(
          :class="{ [translationFallbackClasses]: titleFallback }"
        ) {{ dashboardBlock.title }}
          span(v-show="items.length") &nbsp;({{ items.length }})
          small(v-if="tablesAreLoaded && items.length === 0")
            i &nbsp;-&nbsp;{{ $t('dashboardBlocks.live.noCurrentEvents') }}

      .dashboard-live-items(ref="itemsContainer")
        table.dashboard-grouped-items
          tr.dashboard-live-item(
            v-for="(item, index) in items"
            :key="`${item.recordId} - ${index}`"
          )
            td.dashboard-live-item--icon.icon-large(:style="{ 'color': item.color }")
              i.fas.fa-fw(:class="'fa-' + item.icon")

            .dashboard-live-item--main-infos(:title="item.title + ' | ' + item.description")
              td.dashboard-live-item--title {{ item.title }}

              td.dashboard-live-item--description {{ item.description }}

            .dashboard-live-item--secondary-infos
              td.dashboard-live-item--counter {{ $t('dashboardBlocks.live.since') }} {{ item.counter }}

              td.dashboard-schedule-item--label-type
                .flex-center(v-if="item.label")
                  .label.bg-red-200.text-red-700 {{ item.label }}

    .flex-center.h-100(v-else)
      div
        h2.text-center.text-warning
          i.fas.fa-exclamation-triangle
        p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}

</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DashboardBlock           from '../../models/dashboardBlock';
import { DashboardBlockMixin }  from '../../mixins/DashboardBlockMixin';
import moment                   from 'moment';
import { EventBus }             from '../../main';
import { every, throttle }      from 'lodash';
import DashboardSharedItem      from '../../models/dashboardSharedItem';

export default {
  mixins: [DashboardBlockMixin],
  data() {
    return {
      refreshItems:       true,
      refreshItemsInterval: null
    };
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    settingsAreReady() {
      return this.dashboardBlock &&
              this.dashboardBlock.kind_settings.schedule_id &&
              this.dashboardBlockSchedule
    },
    tablesAreLoaded() {
      if (!this.settingsAreReady) return;

      const concernedTableIds = this.dashboardBlockSchedule.kind_settings.concerned_table_ids || [];
      return every(concernedTableIds, (tableId) => {
        const table = this.getTableById(tableId);
        if (table) return table.slug;
      });
    },
    dashboardBlockSchedule() { // we need the schedule block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.schedule_id);
    },
    scheduleSettings() {
      if (this.settingsAreReady) {
        return this.dashboardBlockSchedule.kind_settings;
      }
    },
    tablesForEventsTakingPlaceNow() {
      if (this.settingsAreReady) {
        return this.tables.filter(table => this.scheduleSettings.concerned_table_ids.includes(table.id));
      }
    },
    recordsTakingPlaceNow() {
      if (this.tablesForEventsTakingPlaceNow) {
        const records = this.tablesForEventsTakingPlaceNow.flatMap(table => table.records);
        return records.filter(this.recordIsTakingPlaceNow);
      };
    },
    items() {
      this.refreshItems;
      if (this.recordsTakingPlaceNow) {
        return this.recordsTakingPlaceNow.map(this.buildItem)
      }
      return [];
    },
  },
  watch: {
    items: function(newValue, oldValue) {
      if (this.tablesAreLoaded) {
        setTimeout(() => {
          if (this.$refs.itemsContainer && (newValue.length !== oldValue.length)) {
            this.resizeBlock();
          }
        });
      }
    },
    tablesAreLoaded: function(newValue, oldValue) {
      if (newValue && !oldValue && this.$refs.itemsContainer?.querySelector('table')) {
        this.resizeBlock();
      }
    },
    'dashboardBlock.kind_settings.auto_resize': function(newValue, oldValue) {
      if (newValue && !oldValue) this.resizeBlock();
    },
    'dashboardBlock.kind_settings.max_number_of_lines': function(newValue, oldValue) {
      if (newValue !== oldValue) this.resizeBlock();
    }
  },
  mounted() {
    this.refreshItemsInterval = setInterval(() => {
      this.refreshItems = !this.refreshItems;
    }, 60000);
    this.resizeBlock();
    EventBus.$on('dashboard-breakpoint-updated', this.resizeBlock);
  },
  beforeDestroy() {
    clearInterval(this.refreshItemsInterval);
    EventBus.$off('dashboard-breakpoint-updated', this.resizeBlock);
  },
  methods: {
    buildItem(record) {
      const tableSettings = this.scheduleSettings.settings_by_table_id[record.table.id];
      return {
        recordId:    record.id,
        title:       record.entriesByFieldId[tableSettings.title_field_id].toString(),
        description: record.entriesByFieldId[tableSettings.description_field_id].toString(),
        startDate:   record.entriesByFieldId[tableSettings.start_date_field_id].toString(),
        icon:        record.entriesByFieldId[tableSettings.icon_field_id].toString(),
        color:       record.entriesByFieldId[tableSettings.color_field_id].toString(),
        counter:     this.counter(record),
      };
    },
    counter(record) {
      moment.locale('fr');
      const tableSettings = this.scheduleSettings.settings_by_table_id[record.table.id];
      const startDate = new Date(record.entriesByFieldId[tableSettings.start_date_field_id].value);
      return moment(startDate).fromNow(true);
    },
    resizeBlock: throttle(function() {
      if (this.tablesAreLoaded && this.dashboardBlock.kind_settings.auto_resize) {
        let aimedHeight;
        let itemsHeight              = 0;
        const numberOfItems          = this.items.length;
        const maxShownItems          = this.dashboardBlock.kind_settings.max_number_of_lines || Infinity;
        const numberOfItemsToDisplay = numberOfItems > maxShownItems ? maxShownItems : numberOfItems;
        if (numberOfItems) { // table is not rendered if no items
          const table = this.$refs.itemsContainer.querySelector('table');
          const itemHeight  = Number.parseInt(table.querySelector('tr').clientHeight + 16, 10); // 16 is the margin from border-spacing on table element
          itemsHeight = (numberOfItemsToDisplay * itemHeight);
        }
        const GridITemElementStyle = getComputedStyle(document.getElementById(`dashboard-block-${this.dashboardBlockId}`));
        const paddingTop           = Number.parseInt(GridITemElementStyle.paddingTop, 10);
        const paddingBottom        = Number.parseInt(GridITemElementStyle.paddingBottom, 10);
        const blockElementStyle    = getComputedStyle(this.$refs.blockElement);
        const blockPadding         = Number.parseInt(blockElementStyle.paddingTop, 10) + Number.parseInt(blockElementStyle.paddingBottom, 10);
        const headerHeight         = this.$refs.headerElement.clientHeight;
        const otherHeights         = blockPadding + headerHeight + paddingTop + paddingBottom;

        aimedHeight = itemsHeight + otherHeights; // need to include items margin => 16 * (numberOfItems - 1)

        EventBus.$emit('resize-block', aimedHeight, this.dashboardBlockId);
      }
    }, 300, { leading: false, trailing: true }),
    recordIsTakingPlaceNow(record) {
      return DashboardSharedItem.query().whereId([this.dashboardBlockSchedule.id, record.id]).exists();
    }
  },
}
</script>
