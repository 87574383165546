import Cell from '../cell';
import moment from 'moment'

export default class TextCell extends Cell {
  get readHTML() {

    return `<div class="cell-content">${this.formattedValue}</div>`;
  }

  get formattedValue() {
    const dateFormat = this.column.options.date_format || "DD/MM/YYYY HH:mm"

    return this.value ? moment(this.value).format(dateFormat) : "";
  }

  copy() {
    if (!this.selected) return;

    navigator.clipboard.writeText(this.formattedValue);
  }
}
