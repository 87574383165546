<template lang="pug">
  .entry-input--reference
    .select-wrapper.read-only.reference-entry-content
      template(v-if="entry.value.length")
        span.tag.mr-2(
          v-for="user in entry.displayValue"
          :key="user.id"
        ) {{ user.displayName }}
      span.text-secondary.font-weight-lighter(v-else) {{ $t('entries.select.noUsers') }}

      .reference-entry-expand-content(
        v-if="canExpandUsers"
        @click="openEntryUsersModal()"
      )
        i.fad.fa-expand-alt
</template>

<script>
import { UsersMixin } from "../../../mixins/entries/UsersMixin";

export default {
  mixins: [UsersMixin],
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  computed: {
    canExpandUsers() {
      return this.entry.value.length;
    }
  }
};
</script>
