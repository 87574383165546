import { Controller } from "@hotwired/stimulus"
import debounce from "lodash.debounce"

export default class extends Controller {
  static targets = ["button"]
  static values = {
    submitLaterDelay: Number
  }

  initialize() {
    const delay = this.submitLaterDelayValue || 400
    this.submitLater = debounce(this.submit, delay, { leading: false })
  }

  submit() {
    if (this.element.requestSubmit) {
      this.element.requestSubmit();
    } else {
      this.element.submit();
    }
  }

  submitSubAction() {
    // This is to submbit a form having inner button which is not the main submit button because of different action
    // e.g. a calendar form with date picker to extand the date range
    this.buttonTarget.click()
  }
}
