<template lang="pug">
  section.my-3
    .form-group.mb-2
      label.font-weight-bolder {{ $t('editOptions.selectReferencesField') }}
      v-select(
        :input-id="'edit-unit-type' + editedField.id"
        appendToBody
        :searching="true"
        :options="referencesFields"
        :reduce="option => option.id"
        :get-option-label="option => option.name"
        v-model="editedField.typeOptions.references_field_id"
        :disabled="!canEditField"
      )
        template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}

    .form-group.mb-3(v-if="lookupFields.length > 0")
      label.font-weight-bolder {{ $t('editOptions.selectLookupsField') }}
      v-select(
        :input-id="'edit-unit-type' + editedField.id"
        appendToBody
        :searching="true"
        :options="lookupFields"
        :reduce="option => option.id"
        :get-option-label="option => option.name"
        v-model="editedField.typeOptions.lookups_field_id"
        :disabled="!canEditField"
      )
        template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}

    .form-group.mb-2.d-flex.align-items-center.justify-content-between
      label.font-weight-bolder.mb-0(for="is-filtered") {{ $t('editOptions.isFiltered') }}
      input.ml-2(
        v-if="!query.filters.length"
        type="checkbox"
        id="is-filtered"
        name="is-filtered"
        v-model="showFiltersOption"
        :disabled="!canEditField"
      )

    .form-group(v-if="showFilters")
      label.font-weight-bolder {{ $t('automations.labels.conditions') }}
      RecordFilters(
        :fields="filterFields"
        :originalQuery="query"
        :showSaveButton="false"
        @query-payload-changed="queryPayloadChanged"
      )
</template>

<script>
  import { mapState, mapGetters } from "vuex";
  import { api } from '../../api/client';
  import RecordFilters from '../RecordFilters';
  import Field from '../../models/field';

  export default {
    components: {
      RecordFilters
    },
    props: {
      editedField: {
        type: Object,
        required: true
      },
      canEditField: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        filters: [],
        logicalOperator: null,
        filterFields: [],
        showFiltersOption: false,
        filtersReady: false,
      }
    },
    computed: {
      ...mapGetters({
        currentFields: "fieldStore/currentFields",
      }),
      ...mapState({
        tables: state => state.tableStore.tables
      }),
      referencesFields() {
        return this.currentFields.filter(field => field.fieldType === "references");
      },
      lookupFields() {
        if (!this.foreignTableId) return [];

        const table = this.tables.find(table => table.id === this.foreignTableId);

        return table ? table.fields.filter(field => field.isUserAccessible) : [];
      },
      referenceField() {
        if (!this.editedField.typeOptions.references_field_id) return;

        return this.currentFields.find(field => field.id === this.editedField.typeOptions.references_field_id);
      },
      foreignTableId() {
        if (!this.referenceField) return;

        return this.referenceField.typeOptions.foreign_table_id;
      },
      isFiltered() {
        return this.editedField.typeOptions.query.filters?.length;
      },
      showFilters() {
        return this.foreignTableId && (this.isFiltered || this.showFiltersOption) && this.filtersReady;
      },
      query() {
        const query = { logicalOperator: this.logicalOperator }
        this.$set(query, "filters", this.filters)
        return query
      },
    },
    watch: {
      foreignTableId: function(newForeignTableId, oldForeignTableId) {
        if(oldForeignTableId !== newForeignTableId) this.loadFilterFields();
      }
    },
    mounted() {
      this.logicalOperator = this.editedField.typeOptions.query.logical_operator || 'and';
      (this.editedField.typeOptions.query.filters || []).forEach((filter, index) => {
        this.$set(this.filters, index, filter);
      })
      this.showFiltersOption = this.isFiltered;
      this.loadFilterFields();
    },
    methods: {
      queryPayloadChanged({ filters, logicalOperator }, _filtersAreValid) {
        this.editedField.typeOptions.query.filters = filters;
        this.editedField.typeOptions.query.logical_operator = logicalOperator;
        this.filters = filters;
        this.logicalOperator = logicalOperator;
      },
      loadFilterFields() {
        if (!this.foreignTableId) return;

        api.fields.index({
          tableId: this.foreignTableId,
        }).then((response) => {
          this.filtersReady = false
          setTimeout(() => {
            this.filterFields = Field.build(response.data);
            this.setQueryFilters();
            this.filtersReady = true;
          });
        });
      },
      setQueryFilters() {
        const filterFieldIds = this.filterFields.map(field => field.id);

        this.filters = this.filters.filter(filter => filterFieldIds.includes(Number.parseInt(filter.field_id)));
      },
    }
  }
</script>
