<template lang="pug">
  input.cell-content.input-cell.p-0(
    v-focus
    v-model="newValue"
    type="string"
    autocomplete="off"
    @change="handleChange"
    @blur="handleBlur"
    @keyup.stop.prevent.escape="handleBlur"
  )
</template>

<script>
  import { UpdateEntryMixin } from '../../../mixins/UpdateEntryMixin'

  export default {
    mixins: [UpdateEntryMixin],
    props: {
      entry: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        newValue: ""
      }
    },
    mounted() {
      this.newValue = this.entry.value
    },
    methods: {
      handleBlur() {
        setTimeout(() => this.entry.status = "read");
      },
      handleChange() {
        this.entry.value = this.newValue;
        this.updateEntry(this.entry);
      }
    }
  }
</script>
