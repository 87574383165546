<template lang="pug">
  .base-modal-wrapper(
    :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  )
    .base-modal.modal-mobile.overflow-auto
      span.base-modal-close(@click.stop="closeModal")
        svg.icon
          use(xlink:href="#icon-times-solid")

      //- HEADER
      .base-modal-header.d-flex.align-items-center.mb-5
        span.bg-indigo-100.p-2.rounded-circle.mr-2(style="line-height: 1.3rem;")
          i.far.fa-filter.fa-fw.text-indigo-800
        .text-xl.text-gray-800.font-weight-bolder {{ $t('controlBarActions.filter') }}

      //- BODY
      .my-4.modal-body-mobile.overflow-auto(:style="{ overflow: currentView.query.filters.length < 4 ? 'visible' : 'scroll' }")
          controlBarTableFilters(v-if="tableFilters.length")
          p.action-option--placeholder.my-1.ctrlbar-action--text.font-italic.pl-1(
            v-if="currentView.query.filters.length === 0"
          ) {{ $t('controlBarActions.noFilter') }}

          .ctrlbar-action--action-option.option-box.my-2.list-action.d-flex.flex-column(v-for="filter, index in currentView.query.filters")
            .action-option--remove(@click.stop="removeFilter(index)")
              i.fal.fa-2x.fa-times

            .action-option--label.action-option--label-wide.text-left
              .pl-2(v-if="index === 0")
                | {{ $t('controlBarActions.filterBy') }}
              .px-3(v-else-if="index === 1")
                .logical-operator-select(
                  v-click-outside="closeLogicalOperatorSelect"
                )
                  .logical-operator-select--input.pl-2(@click="showLogicalOperatorSelect = !showLogicalOperatorSelect")
                    span {{ $t(currentView.query.logicalOperator) }}
                    i.far.fa-chevron-down.logical-operator-select--chevron.ml-3
                  .logical-operator-select--options(
                      v-show="showLogicalOperatorSelect"
                    )
                    span(@click="selectLogicalOperator('and')") {{ $t('and') }}
                    span(@click="selectLogicalOperator('or')") {{ $t('or') }}
              .px-3(v-else)
                .pl-2
                  | {{ $t(currentView.logicalOperator) }}

            .glint-select--wrapper.mx-1
              select.glint-select--input.glint-select--input-sm.glint-select--input-wide.filter-records-select(
                :value="filter.field_id"
                @change="changerFilterField(filter, $event)"
              )
                option(
                  v-for="field in orderedFields"
                  :value="field.id"
                ) {{ fieldName(field.id) }}
              i.fas.fa-chevron-down.glint-select--icon

            .d-flex(v-if="filterNeedsBooleanInput(filter)")
              p.mb-0.mx-4 {{ $t('is') }}
              .d-flex.justify-content-center.align-items-center
                .boolean-label.editable.mb-0(@click="filter.value = !filter.value")
                  i.checkbox-icon.far(:class="filter.value ? 'fa-check text-success' : 'fa-times text-danger times-icon'")

            template(v-else)
              .glint-select--wrapper.mx-1
                select.glint-select--input.glint-select--input-sm.glint-select--input-wide-md(
                  v-model="filter.operator"
                  @change="filter.value = ''"
                )
                  option(
                    v-for="operator in operatorsFor(filter)"
                    :value="operator.operator"
                  ) {{ operator.name }}
                i.fas.fa-chevron-down.glint-select--icon

              .action-option--input-wrapper.ml-1
                input.action-option--input(
                  v-if="filterNeedsDateInput(filter)"
                  v-model="filter.value"
                  type="date"
                )

                .glint-select--wrapper(v-else-if="filterNeedsSelectInput(filter)")
                  select.glint-select--input.action-option--input(
                    v-model="filter.value"
                  )
                    option(disabled) {{ $t('views.entrySelectDefault') }}
                    option(v-for="option in optionForFilterSelect(filter)") {{ option }}
                  i.far.fa-list.clear-input(v-show="filter.value === ''" style="pointer-events: none;")

                input.action-option--input(
                  v-else-if="filterNeedsInput(filter)"
                  :type="fieldDataType(filter.field_id) == 'number' ? 'number' : 'text'"
                  v-model="filter.value"
                  @keyup.enter="applyFilters"
                )

                i.far.fa-times.clear-input(
                  v-show="filter.value != ''"
                  @click="filter.value = ''"
                )
              span.mx-2(
                v-if="filterNeedsDaysInput(filter)"
              ) {{ $t('days') }}
      //- FOOTER
      .base-modal-footer.mt-4.d-flex.flex-column.justify-content-around.align-items-center
        .pt-2.mb-2
          p.ctrlbar-action--add-filter-btn.mb-0.mr-3(@click="addFilter") + {{ $t('controlBarActions.addFilter') }}
          button.ctrlbar-action--btn.ctrlbar-action--btn-sm(
            v-if="!loadingRecords"
            @click.stop="applyFilters"
            :disabled="!validFilters"
          ) {{ $t('apply') }}
          .spinner-border.spinner-border-sm.text-danger(v-else role="status")
            span.sr-only Loading...
        button.btn.default-btn(@click.stop="closeModal")
          span {{ $t('close') }}
</template>

<script>
import { MobileModalMixin }      from "./MobileModalMixin.js";
import { api }                   from '../../../api/client';
import { BaseModalMixin }        from "../BaseModalMixin";
import { find, isEqual, filter } from "lodash";
import { operatorsByDataType }   from '../../../services/filterOperators';
import controlBarTableFilters    from '../../controlbar/controlBarTableFilters';

export default {
  components: {
      controlBarTableFilters
  },
  mixins: [BaseModalMixin, MobileModalMixin],
  computed: {
      validFilters() {
        const validFilters =  !filter(this.currentView.query.filters, filter => {
          return filter.value === '' && !this.inputlessOperators.includes(filter.operator)
        }).length;
        return validFilters;
      },
      queryStringChanged() {
        return !isEqual(this.currentViewOriginalQuery, this.currentView.query);
      },
      queryStringCanBeApplied() {
        return this.validFilters && this.queryStringChanged;
      }
  },
  watch: {
    queryStringChanged: function (newValue, oldValue) {
      this.$emit('queryStringChanged', this.queryStringChanged);
      this.filtersApplied = false;
      this.$emit('filtersApplied', this.filtersApplied);
    }
  },
  methods: {
    // fieldInfos ##################################################################
    fieldName(fieldId) {
      return this.orderedFields.find(field => field.id === fieldId).name;
    },
    fieldDataType(fieldId) {
      return this.orderedFields.find(field => field.id === fieldId).dataType;
    },
    // logicalOperator ##############################################################
    selectLogicalOperator(operator) {
      this.currentView.query.logicalOperator = operator;
      this.closeLogicalOperatorSelect();
    },
    closeLogicalOperatorSelect() {
      this.showLogicalOperatorSelect = false;
    },
    // #############################################################################
    addFilter(event) {
      this.currentView.query.filters.push({
        field_id: this.orderedFields[0].id,
        operator: null,
        value:    ''
      });
    },
    removeFilter(index) {
      this.currentView.query.filters.splice(index, 1);
    },
    changerFilterField(filter, event) {
      filter.value    = '';
      filter.field_id = parseInt(event.target.value);
      filter.operator = this.operatorsFor(filter)[0].operator;
    },
    operatorsFor(filter) {
      const dataType   =  this.fieldDataType(filter.field_id);
      const operators  = operatorsByDataType[dataType];

      if(filter.operator === null) filter.operator = operators[0].operator;

      return operators;
    },
    filterNeedsInput(filter) {
      return !this.inputlessOperators.includes(filter.operator);
    },
    filterNeedsBooleanInput(filter) {
      if(this.filterNeedsInput(filter) && this.fieldDataType(filter.field_id) === 'boolean') {
        if(filter.value === '') filter.value = false;
        return true;
      } else {
        return false;
      }
    },
    filterNeedsDateInput(filter) {
      return this.filterNeedsInput(filter) &&
             this.fieldDataType(filter.field_id) === 'date' &&
             !['days from now', 'days ago'].includes(filter.operator);
    },
    filterNeedsSelectInput(filter) {
      return this.filterNeedsInput(filter) && this.fieldDataType(filter.field_id) === 'select';
    },
    filterNeedsDaysInput(filter) {
      return this.filterNeedsInput(filter) && ['days from now', 'days ago'].includes(filter.operator);
    },
    optionForFilterSelect(filter) {
      return find(this.orderedFields, field => field.id === filter.field_id ).typeOptions.select_list;
    },
    applyFilters() {
      if(!this.validFilters) return;

      this.loadingRecords = true
      api.records.filtered({ query: this.currentView.query, tableId: this.tableId }).then(response => {
        this.$store.dispatch('recordStore/setVisibleRecords', { visibleRecordIds: response.data.visible_record_ids });
        this.currentView.visibleRecordIds = response.data.visible_record_ids;
        this.loadingRecords = false;
      });
      this.filtersApplied = true;
      this.$emit('filtersApplied', this.filtersApplied);
      this.$store.commit('viewStore/SAVE_APPLIED_FILTERS');
    }
  }
};
</script>

<style lang="scss" scope>
  .list-action{
    width:100%;
    height: auto;
  }
  .logical-operator-select--options{
    z-index: 2;
  }
  .option-box{
    padding: 8px;
  }
  .option-box:hover{
    border: 1px solid rgba(26, 32, 44, 0.3);
  }
  .action-option--input {
    min-height: 30px;
  }
</style>
