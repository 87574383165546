<template lang="pug">
.dashboard-calendar
  template(v-if="settingsAreReady")
    .dashboard-block--header.pb-3
      h2 {{ title }}
      span.view-action(v-if="calendarWithAllEvents")
        a.view-action--btn(:href="csvUrl" target="_blank")
          i.far.fa-arrow-to-bottom.fa-fw.pop-action-btn

    .d-flex.dashboard-calendar--main
      div
        .d-flex.justify-content-center.align-items-center.pt-2.pb-4.bg-white
          button.btn.mr-4(@click="loadCalendar(year - 1)")
            i.fas.fa-chevron-left.text-gray-600
          .text-2xl.year.text-center {{ year }}
          button.btn.ml-4(@click="loadCalendar(year + 1)")
            i.fas.fa-chevron-right.text-gray-600
        .dashboard-calendar--months.bg-white
          .dashboard-calendar--month(v-for="monthWithIndex in monthsWithIndexes")
            .text-center.mb-1.subtitle {{ monthWithIndex[1] }}
            table.w-100
              thead
                tr
                  th.text-center(v-for="dayInitial in dayInitials")
                    .nav.mb-2 {{ dayInitial }}

              tbody
                tr(v-for="week in Object.keys(days[monthWithIndex[0]].weeks)")
                  td(v-for="_ in weekPad(days[monthWithIndex[0]].weeks[week][0].date)")
                  td(
                    style="width: 32px; height: 32px; cursor: pointer;"
                    v-for="day in days[monthWithIndex[0]].weeks[week]"
                    @click="setCurrentDay(day)"
                  )
                    .d-flex.justify-content-center.align-items-center.rounded-circle.p-4(
                      style="width: 32px; height: 32px;"
                      :class="{ 'bg-red-200': dayIsPPTwo(day), \
                                'bg-red-300': dayIsPPOne(day) || dayIsNegative(day), \
                                'border-dotted-green-600': dayHasActivation(day), \
                                'shadow': currentDay && day.date === currentDay.date }"
                      :title="hoverTitle(day)"
                    )
                      .text-center.figures-medium(style="line-height: 16px;")
                        div {{ new Date(day.date).getDate() }}
                        div(v-if="calendarWithAllEvents")
                          div(v-if="dayIsOff(day)")
                            i.fas.text-xs
                          div(v-else-if="availabilityRatio(day) === 100")
                            i.fas.fa-circle.text-xs.text-green-600
                          div(v-else-if="availabilityRatio(day) === 0")
                            i.fas.fa-times.text-xs.text-red-600
                          div(v-else)
                            span {{availabilityRatio(day)}}
                            small %
                        div(v-if="calendarNegative")
                          div(v-if="dayIsOff(day)")
                            i.fas.text-xs
                          div(v-else-if="negativeRatio(day) === 100")
                            i.fas.fa-circle.text-xs.text-green-600
                          div(v-else-if="negativeRatio(day) === 0")
                            i.fas.fa-times.text-xs.text-red-600
                          div(v-else-if="negativeRatio(day) === -1")
                          div(v-else)
                            span {{negativeRatio(day)}}
                            small %
      .dashboard-calendar--day.d-flex.flex-column.bg-white(v-if="currentDay")
        template
          .px-3.py-2.d-flex.border-bottom.mb-1.justify-content-between.align-items-end
            div
              span.text-2xl.font-weight-bolder.mr-2 {{ currentDay.human_date.split(' ')[0] }}
              span.text-uppercase.text-gray-700 {{ currentDay.human_date.split(' ')[1] }}
            .bg-red-200.font-weight-bolder.px-2.py-1.rounded(v-if="dayIsPPTwo(currentDay)") PP2
            .bg-red-300.font-weight-bolder.px-2.py-1.rounded(v-if="dayIsPPOne(currentDay)") PP1/PP2
            .bg-red-300.font-weight-bolder.px-2.py-1.rounded(
              v-if="dayIsNegative(currentDay)"
            ) {{ $t('dashboardBlocks.calendar.negative') }}
        .d-flex.justify-content-between.flex-column(style="width: 272px; height: calc(100% - 3.8rem)")
          .px-3.py-2.overflow-auto.flex-grow-0.h-100
            DashboardBlockCalendarDay(:day="currentDay")

    .legend.d-flex.align-items-center(v-if="calendarWithAllEvents")
      .d-flex.justify-content-center.align-items-center.mr-4
        .d-flex.justify-content-center.align-items-center.rounded-circle.p-2.bg-red-200
        .text-sm.ml-2 {{ $t('dashboardBlocks.calendar.legend.pp2') }}

      .d-flex.justify-content-center.align-items-center.mr-4
        .d-flex.justify-content-center.align-items-center.rounded-circle.p-2.bg-red-300
        .text-sm.ml-2 {{ $t('dashboardBlocks.calendar.legend.pp1pp2') }}

      .d-flex.justify-content-center.align-items-center.mr-4
        .d-flex.justify-content-center.align-items-center.rounded-circle.p-2.border-dotted-green-600
        .text-sm.ml-2 {{ $t('dashboardBlocks.calendar.legend.dayWithActivations') }}

      .d-flex.justify-content-center.align-items-center
        i.fas.fa-circle.text-xs.text-green-600.mr-1
        i.fas.fa-times.text-xs.text-red-600.mr-1
        .text-sm %
        .text-sm.ml-2 {{ $t('dashboardBlocks.calendar.legend.sitesAvailability') }}

    .legend.d-flex.align-items-center(v-if="calendarNegative")
      .d-flex.justify-content-center.align-items-center.mr-4
        .d-flex.justify-content-center.align-items-center.rounded-circle.p-2.bg-red-300
        .text-sm.ml-2 {{ $t('dashboardBlocks.calendar.legend.dayWithNegativePrice') }}

      .d-flex.justify-content-center.align-items-center
        i.fas.fa-circle.text-xs.text-green-600.mr-1
        i.fas.fa-times.text-xs.text-red-600.mr-1
        .text-sm %
        .text-sm.ml-2 {{ $t('dashboardBlocks.calendar.legend.sitesWithoutProductionRatio') }}

  .flex-center.h-100(v-else)
    div
      h2.text-center.text-warning
        i.fas.fa-exclamation-triangle
      p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}
</template>

<script>
import { api }                   from '../../api/client';
import { mapGetters }            from 'vuex';
import { isEqual, map, uniq }    from 'lodash';
import DashboardBlock            from '../../models/dashboardBlock';
import DashboardBlockCalendarDay from './DashboardBlockCalendarDay';
import { DashboardBlockMixin }   from '../../mixins/DashboardBlockMixin';
import DashboardSharedItem       from '../../models/dashboardSharedItem';

export default {
  components: {
    DashboardBlockCalendarDay
  },
  mixins: [DashboardBlockMixin],
  data() {
    return {
      year:              null,
      days:              {},
      monthsWithIndexes: [],
      dayInitials:       [],
      title:             '',
      currentDay:        null,
      csvUrl:             '',
    }
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    settingsAreReady() {
      return this.dashboardBlock &&
             this.dashboardBlock.kind_settings.list_id &&
             this.dashboardBlock.kind_settings.calendar_type &&
             (!this.calendarNegative || this.settingsForNegativeCalendar) &&
             (!this.calendarWithAllEvents || this.settingsForAllEventsCalendar) &&
             (!this.calendarWithPPTwoEvents || this.settingsForPPTwoCalendar);
    },
    settingsForNegativeCalendar() {
      return this.dashboardBlock.kind_settings.view_id &&
             this.dashboardBlock.kind_settings.associations_view_id &&
             this.dashboardBlock.kind_settings.main_reference_field_id_start_date &&
             this.dashboardBlock.kind_settings.associations_reference_field_id_site &&
             this.dashboardBlock.kind_settings.associations_reference_field_id_date;
    },
    settingsForAllEventsCalendar() {
      return this.dashboardBlock.kind_settings.peak_periods_table_id &&
             this.dashboardBlock.kind_settings.peak_periods_one_field_id &&
             this.dashboardBlock.kind_settings.peak_periods_two_field_id &&
             this.dashboardBlock.kind_settings.peak_periods_period_field_id &&
             this.dashboardBlock.kind_settings.activations_table_id &&
             this.dashboardBlock.kind_settings.activations_site_field_id &&
             this.dashboardBlock.kind_settings.activations_file_field_id &&
             this.dashboardBlock.kind_settings.activations_notification_date_field_id &&
             this.dashboardBlock.kind_settings.unavailabilities_table_id &&
             this.dashboardBlock.kind_settings.unavailabilities_table_site_field_id &&
             this.dashboardBlock.kind_settings.unavailabilities_period_field_id;
    },
    settingsForPPTwoCalendar() {
      return this.dashboardBlock.kind_settings.peak_periods_table_id &&
             this.dashboardBlock.kind_settings.peak_periods_one_field_id &&
             this.dashboardBlock.kind_settings.peak_periods_two_field_id &&
             this.dashboardBlock.kind_settings.peak_periods_period_field_id;
    },
    dashboardBlockList() { // we need the list block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.list_id);
    },
    tableForCalendar() {
      if (this.settingsAreReady) {
        return this.getTableById(this.dashboardBlockList.kind_settings.table_id);
      }
    },
    selectedRecordIds() {
      if (this.tableForCalendar && this.tableForCalendar.slug) {
        const items = DashboardSharedItem.listItems(this.dashboardBlockList.id, true).
                                          where('metadata', value => value.checked).get();
        return map(items, 'item_id');
      }
    },
    calendarWithAllEvents() {
      return this.dashboardBlock.kind_settings.calendar_type === 'calendar_with_all_events';
    },
    calendarWithPPTwoEvents() {
      return this.dashboardBlock.kind_settings.calendar_type === 'calendar_without_pp1';
    },
    calendarNegative() {
      return this.dashboardBlock.kind_settings.calendar_type === 'calendar_negative_price';
    },
  },
  watch: {
    'selectedRecordIds': function (newRecordIds, oldRecordIds) {
      if(this.settingsAreReady && !isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) {
        this.loadCalendar(this.year);
      }
    },
  },
  mounted() {
    setTimeout(() => {
      if (!this.settingsAreReady) return;
      this.loadCalendar(this.year);
    }, 1500);
  },
  methods: {
    hoverTitle(day) {
      if (this.dayIsPPOne(day)) return 'PP1/PP2';
      if (this.dayIsPPTwo(day)) return 'PP2';

      return '';
    },
    setCurrentDay(day) {
      if (this.dashboardBlock.kind_settings.calendar_type === 'calendar_with_all_events' ||
        this.dashboardBlock.kind_settings.calendar_type === 'calendar_negative_price') {
        this.currentDay = day;
      }
    },
    loadCalendar(year) {
      return api.dashboardBlocks.calendar(this.dashboardBlock.id, year, this.selectedRecordIds).then((response) => {
        this.csvUrl = response.data.csv_url;

        this.monthsWithIndexes = response.data.months_with_indexes;
        this.dayInitials       = response.data.day_initials;
        this.year              = parseInt(response.data.year, 10);
        this.days              = response.data.days;
        this.title             = response.data.title;

        this.currentDay = null;

        this.buildCalendarSharedYear();
      });
    },
    weekPad(firstDayOfMonth) {
      const day = new Date(firstDayOfMonth);
      return (day.getUTCDay() + 6) % 7;
    },
    dayIsNegative(day) {
      return day.events.some(event => event.kind === "negative");
    },
    dayIsPPTwo(day) {
      return day.events.some(event => event.kind === "pp2");
    },
    dayIsPPOne(day) {
      return day.events.some(event => event.kind === "pp1");
    },
    dayHasAvailability(day) {
      return day.events.some(event => event.kind === "available");
    },
    dayHasUnavailability(day) {
      return day.events.some(event => event.kind === "unavailable");
    },
    dayHasActivation(day) {
      return day.events.some(event => event.kind === "activated");
    },
    availabilityRatio(day) {
      if (this.selectedRecordIds.length === 0) return '-';

      const unavailableSites = day.events.filter(event => event.kind === "unavailable").map(event => event.site_name);
      const unavailableSitesCount = uniq(unavailableSites).length;
      const unavailableRatio = unavailableSitesCount / this.selectedRecordIds.length;
      return Math.round((1 - unavailableRatio) * 100);
    },
    negativeRatio(day) {
      if (this.selectedRecordIds.length === 0) return '-';

      const negativeProductionEvents = day.events.filter(event => event.kind === "negative");

      if (typeof negativeProductionEvents === 'undefined' || negativeProductionEvents.length === 0) return -1;

      return Math.round(negativeProductionEvents[0].ratio);
    },
    dayIsOff(day) {
      return day.events.some(event => event.kind === "off_day");
    },
    buildCalendarSharedYear() {
      const data = [{
          dashboard_block_id: this.dashboardBlockId,
          item_id:            0,
          kind:               'calendar_year',
          metadata:           { year: this.year }
        }];
      DashboardSharedItem.insertOrUpdate({ data });
    }
  },
}
</script>
