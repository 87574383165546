import Appsignal from "@appsignal/javascript";

let key, revision;
const applicationScriptTag = document.getElementById("application-script-tag");

if (applicationScriptTag) {
  const jsSrc = document.getElementById("application-script-tag").getAttribute("src");
  revision    = jsSrc.match(/application-\w+/)[0]

  if (process.env.NODE_ENV === 'development') {
    key = null;
  } else {
    key = document.getElementsByName("appsignal-frontend-api-key")[0].getAttribute("content");
  }
}

// appsignal monitoring is initialized when configured with Vue (even for vanilla JS parts)
const appsignal = new Appsignal({ key, revision });

export default appsignal;
