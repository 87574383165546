<template lang="pug">
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { EventBus }             from '../../../main';
import DashboardBlock           from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    selectedTable() {
      if (this.editableSettings.table_id) {
        return this.getTableById(this.editableSettings.table_id);
      }
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
  },
  watch: {
    'dashboardBlock.kind_settings': function(newSettings, oldSettings) {
      const tableChanged = (newSettings.table_id !== oldSettings.table_id);
      if (tableChanged) {
        EventBus.$emit('dashboard-block-updated-' + this.dashboardBlock.id, { type: 'tableChanged' });
      }
    },
    'editableSettings.table_id': function(newValue) {
      this.fieldSettingProperties.forEach(property => this.editableSettings[property] = null);
      this.editableSettings.view_id = null;
    },
  }
}
</script>
