<template lang="pug">
component(
  :is="inputComponent"
  :filter="filter"
)
  slot
</template>

<script>
import { RecordFilterMixin }   from '../../mixins/RecordFilterMixin';
import RecordFilterBasicInput  from './RecordFilterBasicInput';
import RecordFilterBasicSelect from './RecordFilterBasicSelect';

export default {
  mixins: [RecordFilterMixin],
  components: {
    RecordFilterBasicInput,
    RecordFilterBasicSelect
  },
  data() {
    return {
      selectOperators: ['reference', 'does not reference']
    }
  },
  computed: {
    inputComponent() {
      return this.selectOperators.includes(this.filter.operator) ? RecordFilterBasicSelect : RecordFilterBasicInput;
    }
  }
}
</script>