const toggleAutomationScenarioRunning = (currentCheckbox) => {
  const url = currentCheckbox.dataset.actionUrl

  fetch(url, {
    method: "PATCH",
    headers: {
      'Accept':       'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
    },
    body: JSON.stringify({
      automation_scenario: {
        running: currentCheckbox.checked
      }
    })
  })
}

const initScenarioRunningCheckboxes = () => {
  const scenarioRunningCheckboxes = document.querySelectorAll('.js-scenario-running-checkbox');

  if (scenarioRunningCheckboxes) {
    scenarioRunningCheckboxes.forEach(scenarioCheckbox => {
      scenarioCheckbox.addEventListener('click', () => toggleAutomationScenarioRunning(scenarioCheckbox));
    })
  }
}

export { initScenarioRunningCheckboxes };
