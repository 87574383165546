import { Controller } from "@hotwired/stimulus";
import { enter, leave } from 'el-transition';

export default class extends Controller {
  static values = { triggerEvent: String }

  static targets = ['innerPanel', 'backdrop', 'title', 'content', 'footer']

  open({ title }) {
    document.body.style.overflow = "hidden"; // to avoid scrolling issues on mobile
    this.titleTarget.innerText = title;
    enter(this.innerPanelTarget);
    enter(this.backdropTarget);

    this.element.classList.remove('tw-hidden');
  }

  close() {
    document.body.style.removeProperty("overflow");
    leave(this.innerPanelTarget);
    leave(this.backdropTarget).then(() => {
      this.element.classList.add('tw-hidden');
      this.titleTarget.innerHTML = "";
      this.contentTarget.innerHTML = "";
      this.footerTarget.innerHTML = "";
    });
  }
}
