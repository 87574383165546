<template lang="pug">
  li.list-group-item
    .d-flex.justify-content-between
      .flex-center
        strong.text-secondary.text-sm \#{{ position }}
      .flex-grow-1.px-3.position-relative
        .edit-contact(v-if="edit")
          input.py-0.px-2(
            v-focus
            type="text"
            v-model="newValue"
            :class="{ 'border-danger': invalidContactFormat }"
          )
          small.text-danger(v-if="invalidContactFormat") {{ $t('dashboardBlocks.flex_contacts.invalidFormat') }}
        p.mb-0(
          style="padding-top: 2px"
          v-else
        ) {{ item[attribute] }}
      template(v-if="!edit")
        button.ml-2.flex-center(
          type="button"
          @click="editMode"
        )
          i.fas.fa-pen.text-yellow-600
        button.ml-2.flex-center(
          v-if="!edit"
          type="button"
          @click="$emit('delete', item)"
        )
          i.fas.fa-times.text-red-700
      template(v-else)
        button.ml-2.flex-center.d-block(
          type="button"
          @click="edit = false"
        )
          i.fas.fa-undo.text-secondary
        button.ml-2.flex-center.d-block(
          type="button"
          @click="update"
        )
          i.fas.fa-check.text-green-600
</template>

<script>
import { FlexContactValidationMixin } from '../../../mixins/FlexContactValidationMixin';

export default {
  mixins: [FlexContactValidationMixin],
  props: {
    item: {
      type: Object,
      required: true
    },
    attribute: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      edit: false,
      newValue: ''
    }
  },
  computed: {
    position() {
      return Number.parseInt((this.item.name).match(/\d+/), 10);
    }
  },
  methods: {
    editMode() {
      this.edit = true;
      this.validContactFormat = true;
      this.newValue = this.item[this.attribute];
    },
    update() {
      this.testContactFormat(this.newValue);
      if (this.validContactFormat) {
        this.edit = false;
        if (this.newValue !== this.item[this.attribute]) {
          this.$emit('change', {
            value: this.newValue,
            contact_record_id: this.item.contact_record_id,
            attribute: this.attribute
          });
        }
        this.newValue = '';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
button {
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
}
.edit-contact {
  input {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    width: 100%;
  }
}
li:not(:hover) button {
  display: none;
}
</style>
