import { mapState, mapGetters, mapActions } from 'vuex';

export const MobileModalMixin = {
  data(){
    return{
      newView: {
        name: '',
        alert: false
      },
      scope: 'profile',
      typeOptions: {
        titleFieldId: ''
      },
      inputlessOperators: ['empty', 'not empty', 'today', 'is past', 'is not past'],
      showLogicalOperatorSelect: false,
      filtersApplied: true,
      loadingRecords: false
    }
  },
  computed: {
    ...mapState({
      currentView:              state => state.viewStore.view,
      currentViewOriginalQuery: state => state.viewStore.originalQuery,
      tableId:                  state => state.tableStore.table.id,
      tableFilters:             state => state.tableStore.table.query.filters
    }),
    ...mapGetters({
      currentFields: 'fieldStore/currentFields',
      orderedFields: 'fieldStore/orderedFields'
    })
  },
  methods: {
    ...mapActions({
      computeFieldsLeftOffset: 'fieldStore/computeFieldsLeftOffset'
    })
  }
}
