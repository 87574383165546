<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.labels.locked_title') }}
      .text-center
        input(
          type="checkbox"
          v-model="editableSettings.locked_title"
        )
    .form-group
      label {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockLists"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.list_id"
      )
    template(v-if="selectedDashboardBlockList && selectedTable")
      .form-group
        label {{ $t('dashboardBlocks.kpi.labels.api_source_id_field_id') }}
        v-select(
          :searching="true"
          :options="fieldsForSelectedTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.api_source_id_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.timescale.labels.color') }}
        v-select(
          :searching="true"
          :options="fieldsForSelectedTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.color_field_id"
        )
    .form-group
      label {{ $t('dashboardBlocks.timescale.labels.datatype') }}
      v-select(
        :searching="true"
        :options="dataTypes"
        label="name"
        :reduce="option => option.value"
        v-model="editableSettings.datatype"
      )
    template(v-if="editableSettings.datatype === 'enr_perf_production'")
      .form-group
        label {{ $t('dashboardBlocks.kpi.labels.kind_technology_field_id') }}
        v-select(
          :searching="true"
          :options="fieldsForSelectedTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.kind_technology_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.list.labels.site_name_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForSelectedTable"
          :reduce="option => option.id"
          v-model="editableSettings.site_name_field_id"
        )
      hr
      .form-group
        label {{ $t('dashboardBlocks.list.labels.associations_table_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="tables"
          :reduce="option => option.id"
          v-model="editableSettings.associations_table_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.list.labels.associations_reference_field_id_site') }}
        v-select(
          :searching="true"
          label="name"
          :options="referenceFieldsForAssociatedTable"
          :reduce="option => option.id"
          v-model="editableSettings.associations_reference_field_id_site"
        )
      .form-group
        label {{ $t('dashboardBlocks.timescale.labels.contract_start_date_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="dateFieldsForAssociatedTable"
          :reduce="option => option.id"
          v-model="editableSettings.contract_start_date_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.timescale.labels.contract_end_date_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="dateFieldsForAssociatedTable"
          :reduce="option => option.id"
          v-model="editableSettings.contract_end_date_field_id"
        )
    template(v-if="editableSettings.datatype === 'perf_target_actual'")
      .form-group
        label {{ $t('dashboardBlocks.list.labels.site_name_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForSelectedTable"
          :reduce="option => option.id"
          v-model="editableSettings.site_name_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.kpi.labels.kind_technology_field_id') }}
        v-select(
          :searching="true"
          :options="fieldsForSelectedTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.kind_technology_field_id"
        )
      hr
      .form-group
        label {{ $t('dashboardBlocks.list.labels.associations_table_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="tables"
          :reduce="option => option.id"
          v-model="editableSettings.associations_table_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.list.labels.associations_reference_field_id_site') }}
        v-select(
          :searching="true"
          label="name"
          :options="referenceFieldsForAssociatedTable"
          :reduce="option => option.id"
          v-model="editableSettings.associations_reference_field_id_site"
        )
      .form-group
        label {{ $t('dashboardBlocks.list.labels.associations_reference_field_id_perf') }}
        v-select(
          :searching="true"
          label="name"
          :options="numberFieldsForAssociatedTable"
          :reduce="option => option.id"
          v-model="editableSettings.associations_reference_field_id_perf"
        )
      .form-group
        label {{ $t('dashboardBlocks.timescale.labels.contract_start_date_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="dateFieldsForAssociatedTable"
          :reduce="option => option.id"
          v-model="editableSettings.contract_start_date_field_id"
        )
      .form-group
        label {{ $t('dashboardBlocks.timescale.labels.contract_end_date_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="dateFieldsForAssociatedTable"
          :reduce="option => option.id"
          v-model="editableSettings.contract_end_date_field_id"
        )
    template(v-if="editableSettings.datatype === 'flex_production'")
      .form-group
        label {{ $t('dashboardBlocks.list.labels.parent_list') }}
        v-select(
          :searching="true"
          label="title"
          :options="dashboardBlockLists"
          :reduce="option => option.id"
          v-model="editableSettings.parent_list"
        )
      .form-group
        label {{ $t('dashboardBlocks.timescale.labels.unique_element') }}
        .text-center
          input(
            type="checkbox"
            v-model="editableSettings.unique_element"
          )
      .form-group
        label {{ $t('dashboardBlocks.timescale.labels.load_shift_date_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="dateFieldsForAssociatedList"
          :reduce="option => option.id"
          v-model="editableSettings.load_shift_date_field_id"
        )

    hr
    .form-group
      label {{ $t('dashboardBlocks.timescale.labels.chart_kind') }}
      v-select(
        :searching="true"
        :options="chartKindOptions"
        label="name"
        :reduce="option => option.value"
        v-model="editableSettings.chart_kind"
      )
</template>


<script>
import { mapGetters, mapState } from 'vuex';
import { EventBus }             from '../../../main';
import DashboardBlock           from '../../../models/dashboardBlock';
import { filter }               from 'lodash';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    selectedDashboardBlockList() {
      if (this.editableSettings.list_id) {
        return DashboardBlock.find(this.editableSettings.list_id);
      }
    },
    selectedTable() {
      if (this.selectedDashboardBlockList && this.selectedDashboardBlockList.kind_settings.table_id) {
        const tableId = this.selectedDashboardBlockList.kind_settings.table_id;
        return this.tables.find(table => table.id === tableId);
      }
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
    chartKindOptions() {
      const chartKinds = ['line', 'bar', 'bar_double_axis'];

      return chartKinds.map((chartKind) => {
        return {
          name: this.$t(`charts.${chartKind}`),
          value: chartKind
        }
      })
    },
    dataTypes() {
      const dataTypes = [
        'enr_production',
        'enr_forecast_production',
        'flex_production',
        'epex_m0',
        'epex',
        'capacity_auction',
        'aoe',
        'enr_production_stacked',
        'enr_perf_production',
        'perf_target_actual',
        'enr_forecast_and_best_production'
      ];

      return dataTypes.map((dataType) => {
        return {
          name: this.$t(`dataTypes.${dataType}`),
          value: dataType
        }
      })
    },
    associationsTable() {
      if (this.editableSettings.associations_table_id) {
        return this.getTableById(this.editableSettings.associations_table_id);
      }
    },
    referenceFieldsForAssociatedTable() {
      if (this.associationsTable) {
        return filter(this.associationsTable.fields, ['dataType', 'references']);
      }
      return [];
    },
    numberFieldsForAssociatedTable() {
      if (this.associationsTable) {
        return filter(this.associationsTable.fields, ['dataType', 'number']);
      }
      return [];
    },
    dateFieldsForAssociatedTable() {
      if (this.associationsTable) {
        return filter(this.associationsTable.fields, ['dataType', 'date']);
      }
      return [];
    },
    dateFieldsForAssociatedList() {
      if (this.selectedParentTable) {
        return filter(this.selectedParentTable.fields, ['dataType', 'date_time_range']);
      }
      return [];
    },
    selectedDashboardBlockParentList() {
      if (this.editableSettings.parent_list) {
        return DashboardBlock.find(this.editableSettings.parent_list);
      }
    },
    selectedParentTable() {
      if (this.selectedDashboardBlockParentList && this.selectedDashboardBlockParentList.kind_settings.table_id) {
        const tableId = this.selectedDashboardBlockParentList.kind_settings.table_id;
        return this.tables.find(table => table.id === tableId);
      }
    },
  },
  watch: {
    'dashboardBlock.kind_settings': function(_newSettings, _oldSettings) {
      EventBus.$emit('dashboard-block-load-charts-' + this.dashboardBlock.id);
    },
    'editableSettings.list_id': function(newValue) {
      [
        'api_source_id_field_id',
      ].forEach(property => this.editableSettings[property] = null);
      this.editableSettings.view_id = null;
    },
    'editableSettings.associations_table_id': function() {
      this.editableSettings.associations_reference_field_id_site = null;
      this.editableSettings.associations_reference_field_id_perf = null;
    }
  }
}
</script>
