<template lang="pug">
  input.form-control(
    v-model="newValue"
    @change="handleChange"
    @blur="handleBlur"
    @keyup.enter="$event.target.blur()"
  )
</template>

<script>
import { UpdateEntryMixin } from '../../../mixins/UpdateEntryMixin';

export default {
  mixins: [UpdateEntryMixin],
  props: {
    entry: {
      type:     Object,
      required: true
    }
  },
  data() {
    return {
      newValue: ""
    }
  },
  mounted() {
    this.newValue = this.entry.value;
  },
  methods: {
    handleBlur() {
      setTimeout(() => this.entry.status = "read");
    },
    handleChange() {
      this.entry.value = this.newValue;
      this.updateEntry(this.entry)
    }
  },
  watch: {
    'entry.value': function(_newValue, _oldValue) {
      this.newValue = this.entry.value;
    }
  }
}
</script>
