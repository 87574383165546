import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["searchInput", "searchElement"];

  filterElements() {
    const searchQuery = this.searchInputTarget.value.toLowerCase();

    this.searchElementTargets.forEach((element) => {
      const text = element.innerText.toLowerCase();

      if (text.includes(searchQuery)) {
        return element.style.removeProperty("display");
      }
      element.style.display = "none";
    });
  }

  clearSearch() {
    this.searchInputTarget.value = null;
    this.filterElements();
  }
}
