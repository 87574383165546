<template lang="pug">
  span.view-action.mr-1(
    @click="$emit('open')"
    :class="{ opened: show }"
  )
    span.view-action--btn
      i.far.fa-sort-alt.fa-fw.view-action--btn-icon
      span.view-action--btn-text.ml-1 {{ $t('controlBarActions.sort') }}
    .ctrlbar-action(
      v-if="show"
      v-click-outside="closeAction"
    )
      .ctrlbar-action--header(@click.stop="closeAction")
        div
          i.far.fa-sort-alt.mr-2
          span {{ $t('controlBarActions.sort') }}
        .ctrlbar-action--close(@click.stop="closeAction")
          svg.icon
            use(xlink:href="#icon-times-solid")
      .ctrlbar-action--body.ctrlbar-action--body-lg
        p.action-option--placeholder.my-1.ctrlbar-action--text.font-italic.pl-1(
          v-show="!currentView.sortOrders.length"
        ) {{ $t('controlBarActions.noSorting') }}
        .ctrlbar-action--action-option.my-1(
          v-for="(sortOrder, index) in currentView.sortOrders"
          :key="sortOrder.field_id + '-selected'"
        )
          .action-option--remove(
            v-if="viewIsNotLocked"
            @click.stop="removeSort(sortOrder, index)"
          )
            i.fal.fa-times
          .action-option--label {{ index === 0 ? $t('views.sortBy') : $t('views.thenBy') }}
          .ctrlbar-action--text.action-option--name.font-weight-normal.px-1.text-nowrap(
            :title="fieldName(sortOrder.field_id)"
            :class="{ [translationFallbackClasses]: isNameFallback(sortOrder.field_id) }"
          ) {{ fieldName(sortOrder.field_id) | truncate(30) }}
          .action-option--order(:class="{ editable: viewIsNotLocked }")
            i.fas.fa-chevron-circle-down.mr-1(
              :class="{ selected: sortOrder.order === 'asc' }"
              @click="changeOrder(sortOrder, 'asc')"
            )
            i.fas.fa-chevron-circle-up(
              :class="{ selected: sortOrder.order === 'desc' }"
              @click="changeOrder(sortOrder, 'desc')"
            )

        .glint-select--wrapper.mt-2.mb-1(v-if="viewIsNotLocked")
          select.glint-select--input.glint-select--input-sm.glint-select--input-wide(
            @change="addSort"
            :value="$t('views.selectSort')"
          )
            option(disabled) {{ $t('views.selectSort') }}
            option(
              v-for="field in sortableFields"
              :value="field.id"
              :key="field.id"
            ) {{ field.name }}
          i.fas.fa-chevron-down.glint-select--icon
</template>

<script>
import { api }                  from '../../api/client';
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    sortableFields() {
      return this.orderedFields.filter(field => !this.currentView.sortOrders.map(sortOrder => sortOrder.field_id).includes(field.id))
    },
    ...mapState(['translationFallbackClasses']),
    ...mapState({
      currentView: state => state.viewStore.view

    }),
    ...mapGetters({
      orderedFields: 'fieldStore/orderedFields',
      getFieldById:  "fieldStore/getFieldById"
    }),
    viewIsNotLocked() {
      return !this.currentView.locked;
    }
  },
  methods: {
    fieldName(fieldId) {
      return this.getFieldById(fieldId).name;
    },
    isNameFallback(fieldId) {
      return this.getFieldById(fieldId).nameFallback;
    },
    closeAction() {
      this.$emit('close')
    },
    addSort(event) {
      const sortOrder = { field_id: parseInt(event.target.value) }

      this.baseRequest("create", sortOrder, (response) => {
        this.currentView.sortOrders.push(response.data.sort_order)
        return response;
      });
    },
    removeSort(sortOrder, index) {
      this.currentView.sortOrders.splice(index, 1)
      this.baseRequest("delete", sortOrder);
    },
    changeOrder(sortOrder, order) {
      if (sortOrder.order !== order && this.viewIsNotLocked) {
        sortOrder.order = order
        this.baseRequest("update", sortOrder);
      }
    },
    baseRequest(requestType, sortOrder, callback) {
      return api.sortOrders[requestType]({ viewId: this.currentView.id, sortOrder })
                .then(callback)
                .then(this.loadNewSortOrder);
    },
    loadNewSortOrder({ data, data: { sorted_records_ids } }) {
      if (this.currentView.sortOrders.length) {
        this.currentView.sortedRecordsIds = sorted_records_ids;
      } else {
        this.currentView.applyDefaultSortOrder();
      }
      return data;
    }
  }
}
</script>
