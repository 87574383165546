import { api }              from '../../api/client';
import { filter }           from 'lodash';
import { EventBus }         from '../../main';
import { UpdateEntryMixin } from '../UpdateEntryMixin';
import { alertPopup }       from '../../../components/alert_popup';
import i18n                 from "../../locales/locales.js";

export const UsersMixin = {
  mixins: [UpdateEntryMixin],
  computed: {
    canAddUser() {
      if (this.entry) {
        return this.entry.isWritable && !(this.entry.field.typeOptions.limit_to_one_record && this.entry.value.length);
      }
    }
  },
  methods: {
    removeUser(user, entry) {
      api.entries.removeUserForReference({
        userId:   user.id,
        recordId: entry.record.id,
        fieldId:  entry.field.id
      }).then((response) => {
        if (!response.data.error) {
          entry.value = filter(entry.value, userIdForReference => userIdForReference !== user.id);
          entry.buildDisplayValue();
          this.updateCallbacks(entry);
        } else {
          const message = response.data.custom_message || this.$t('updateForbidden')
          alertPopup(message, 'OK');
        }
      })
    },
    openAddUserModal() {
      EventBus.$emit('openModal', {
        modalName: 'AddUserModal',
        modalProps: {
          entry: this.entry
        }
      });
    },
    openEntryUsersModal() {
      EventBus.$emit('openModal', {
        modalName: 'EntryUsersModal',
        modalProps: this.entry
      });
    },
  }
};
