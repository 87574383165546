<template lang="pug">
  span.ml-2.mr-1
    PopAction(btnTag="span" btnClass="view-action" :active="!!appliedQuery.filters.length")
      span.view-action--btn(slot="trigger")
        i.far.fa-filter.fa-fw.pop-action-btn--icon
        span.view-action--btn-text.ml-1 {{ $t('controlBarActions.filter') }}
      template(v-slot:content="{ isOpen }")
        .px-1.py-2(style="min-width: 200px;" slot="content")
          RecordFilters(
            :fields="orderedTableFields"
            :loadingRecords="loadingRecords"
            :appliedQuery="appliedQuery"
            :originalQuery="currentViewOriginalQuery"
            :showSaveButton="!currentView.locked"
            @save-query="saveViewQuery"
            @query-payload-changed="queryPayloadChanged(...arguments, isOpen)"
          )
            template(v-slot:tableFilters)
              controlBarTableFilters(
                v-if="tableIsPrefiltered"
                :query="tableQuery"
              )

</template>

<script>
import { isEqual,
         sortBy,
         throttle }           from 'lodash';
import { mapState }           from 'vuex';
import { api }                from '../../api/client';
import controlBarTableFilters from './controlBarTableFilters';
import RecordFilters          from '../RecordFilters';

export default {
  components: {
    RecordFilters,
    controlBarTableFilters
  },
  data() {
    return {
      loadingRecords: false,
    }
  },
  computed: {
    ...mapState({
      currentView:              state => state.viewStore.view,
      currentUser:              state => state.currentUser,
      appliedQuery:             state => state.viewStore.appliedQuery,
      currentViewOriginalQuery: state => state.viewStore.originalQuery,
      table:                    state => state.tableStore.table
    }),
    tableQuery() {
      return this.table.query;
    },
    tableIsPrefiltered() {
      return !!this.tableQuery && this.tableQuery.filters && this.tableQuery.filters.length;
    },
    orderedTableFields() {
      return sortBy(this.table.fields, "columnNumber");
    }
  },
  methods: {
    queryPayloadChanged(newQuery, filtersAreValid, isOpen) {
      const queryChanged = !isEqual(this.appliedQuery, newQuery);

      if (isOpen && queryChanged && filtersAreValid) {
        this.loadingRecords = true;
        this.applyFilters(newQuery, filtersAreValid);
      }
    },
    applyFilters: throttle(function(query, filtersAreValid) {
      if (!filtersAreValid) return this.loadingRecords = false;

      api.records.filtered({
        query,
        tableId: this.table.id
      }).then(response => {
          this.$store.dispatch('recordStore/setVisibleRecords', { visibleRecordIds: response.data.visible_record_ids });
          this.$store.commit('viewStore/SAVE_APPLIED_FILTERS', query);
          this.currentView.visibleRecordIds = response.data.visible_record_ids;
          this.loadingRecords = false;
        });
    }, 500, { leading: false, trailing: true }),
    saveViewQuery(queryPayload) {
      api.views.saveFilters({
        view: {
          id:                 this.currentView.id,
          logical_operator:   queryPayload.logicalOperator,
          filters_attributes: queryPayload.filters
        }
      }).then((response) => {
          this.currentView.visibleRecordIds = response.data.visible_record_ids;
          this.currentView.query            = queryPayload;
          this.$store.commit("viewStore/SAVE_ORIGINAL_FILTERS");
        });
    }
  }
}
</script>
