<template lang="pug">
  .entry-input--reference.entry-input-lookup
    .select-wrapper.read-only.reference-entry-content.entry-input-lookup-content
      span.tag.mr-2(
        v-for="displayValue in entry.displayValue"
      ) {{ displayValue }}

      .reference-entry-expand-content(
        v-if="entry.value.length"
        @click="openEntryLookupsModal()"
      )
        i.fad.fa-expand-alt
</template>

<script>
import { LookupsMixin }   from "../../../mixins/entries/LookupsMixin";

export default {
  mixins: [LookupsMixin],
  props: {
    entry: {
      type: Object,
      required: true
    }
  }
};
</script>
