<template lang="pug">
  .mt-3
    h2 {{ $t('automations.labels.source') }}
    .form-group
      label {{ $t('automations.labels.module_number') }}
      v-select(
        :options="moduleInputPositions"
        label="name"
        v-model="moduleInputPosition"
      )

    .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
      button.btn.btn-primary(
        @click.stop="updateAutomationModule"
      ) {{ $t('save') }}
</template>

<script>
import { api } from '../../../../api/client';

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    automationModulePosition: {
      type: Number,
      required: true,
    },
    configParams: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      moduleInputPositions: [],
      moduleInputPosition: null,
    }
  },
  created() {
    this.moduleInputPosition = this.configParams.module_input_position || 1;
    this.moduleInputPositions = Array.from({ length: this.automationModulePosition - 1 }, (_, i) => i + 1)
  },
  methods: {
    updateAutomationModule() {
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        configParams: { module_input_position: this.moduleInputPosition }
      });
    },
  }
}
</script>
