import { apiClient, startFetch, endFetch, api } from '../client';

export default {
  updateColumnsOrder({ viewId, columnNumberByFieldIds }) {
    const saver = startFetch('SAVING');
    return apiClient.patch(
      '/views/' + viewId + '/view_fields/update_columns_order',
      {
        column_number_by_field_ids: columnNumberByFieldIds,
      }
    ).then(endFetch(saver));
  },
  updateColumnsVisibility({ viewId, visibility, fieldIds }) {
    const saver = startFetch('SAVING');
    return apiClient.patch(
      '/views/' + viewId + '/view_fields/update_columns_visibiliy',
      {
        visibility: visibility,
        field_ids:  fieldIds
      }
    ).then(endFetch(saver));
  },
  updateFieldsDisplayForAllViews({ viewId, tableViewIds }) {
    const saver = startFetch('SAVING');
    return apiClient.patch(
      '/views/' + viewId + '/view_fields/update_fields_display_for_all_views',
      {
        table_view_ids: tableViewIds,
      }
    ).then(endFetch(saver));
  },
  update({ viewId, fieldId, viewField }){
    const saver = startFetch('SAVING');
    return apiClient.patch(
      '/views/' + viewId + '/view_fields',
      {
        field_id:   fieldId,
        view_field: viewField
      }
    ).then(endFetch(saver));
  }
};
