<template lang="pug">
  .cell-content.boolean-input-cell
    input(
      hidden
      :id="`entry-boolean-${entry.record.id}-${entry.field.id}`"
      type="checkbox"
      v-model="entry.value"
      @change="updateEntry(entry)"
    )
    label.boolean-label.editable.mb-0(:for="`entry-boolean-${entry.record.id}-${entry.field.id}`")
      i.checkbox-icon.far(:class="entry.value ? 'fa-check text-success' : 'fa-times text-danger times-icon'")
</template>

<script>
  import { UpdateEntryMixin } from '../../../mixins/UpdateEntryMixin'

  export default {
    mixins: [UpdateEntryMixin],
    props: {
      entry: {
        type: Object,
        required: true
      }
    }
  }
</script>
