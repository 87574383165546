<template lang="pug">
transition-group(
  name="fade"
  tag="div"
  class="modals-wrapper"
)
  component(
    v-for="(modal, index) in openedModals"
    :key="modal.key"
    :is="modal.modalName"
    :modalProps="modal.modalProps"
    v-bind="modal.modalProps"
    :modalIndex="index"
    :modalKey="modal.key"
  )
</template>

<script>
import { mapState } from 'vuex';
import { EventBus } from '../main';
import { uniqueId } from 'lodash';

// Mobile
import ShowHideModal from "./modals/mobile/ShowHideModal.vue";
import FilterModal   from "./modals/mobile/FilterModal.vue";
import SortModal     from "./modals/mobile/SortModal.vue";

import EntryFileManagerModal       from "./modals/EntryFileManagerModal.vue";
import RecordModal                 from "./modals/recordModal/RecordModal.vue";
import CalendarModal               from "./modals/CalendarModal";
import AddReferenceModal           from "./modals/AddReferenceModal";
import EntryReferencesModal        from "./modals/EntryReferencesModal";
import AddUserModal                from "./modals/AddUserModal";
import EntryUsersModal             from "./modals/EntryUsersModal";
import EntryLookupsModal           from "./modals/EntryLookupsModal";
import NewGridModal                from "./modals/NewGridModal";
import NewGalleryModal             from "./modals/NewGalleryModal";
import NewFormModal                from "./modals/NewFormModal";
import DashboardBlockSettingsModal from "./modals/DashboardBlockSettingsModal";
import NewDashboardBlockModal      from "./modals/NewDashboardBlockModal";
import ImportDataModal             from "./modals/ImportDataModal";
import NewRepeatableRecordModal    from "./modals/NewRepeatableRecordModal";
import EditViewDescriptionModal    from "./modals/EditViewDescriptionModal";
import DeleteRecordOptionsModal    from "./modals/DeleteRecordOptionsModal";
import EditRecordValidationsModal  from "./modals/EditRecordValidationsModal";
import EditViewOptionsModal        from "./modals/EditViewOptionsModal";

export default {
  components: {
    EntryFileManagerModal,
    CalendarModal,
    NewGridModal,
    NewGalleryModal,
    NewFormModal,
    AddReferenceModal,
    EntryReferencesModal,
    AddUserModal,
    EntryUsersModal,
    EntryLookupsModal,
    RecordModal,
    ShowHideModal,
    FilterModal,
    SortModal,
    DashboardBlockSettingsModal,
    NewDashboardBlockModal,
    ImportDataModal,
    NewRepeatableRecordModal,
    EditViewDescriptionModal,
    DeleteRecordOptionsModal,
    EditRecordValidationsModal,
    EditViewOptionsModal,
  },
  computed: {
    ...mapState({
      openedModals: state => state.openedModals
    })
  },
  created() {
    window.addEventListener("keyup", this.closeLastOpenedModalOnEscape);
    EventBus.$on('openModal', this.openModal);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.closeLastOpenedModalOnEscape);
    EventBus.$off('openModal', this.openModal);
    EventBus.$off("datepickerClosed");
  },
  methods: {
    openModal(modal) {
      modal.key = uniqueId('modal_');
      this.$store.commit('ADD_OPENED_MODAL', modal);
    },
    closeLastOpenedModalOnEscape(event) {
      if (this.openedModals.length && event.key === 'Escape') {
        this.$store.commit("REMOVE_OPENED_MODAL", this.openedModals.length - 1);
      }
    },
  }
}
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transform: opacity 0.1s;
}
</style>
