import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  static targets = ["form", "pageNumberInput"]
  static values = { initialPageNumber: Number }

  connect() {
    useClickOutside(this);
  }

  clickOutside() {
    if (!this.hasFormTarget) return;

    this.closeAndSubmitForm();
  }

  formSelected() {
    return this.element.getAttribute("aria-selected");
  }

  openForm() {
    if (!this.hasFormTarget || this.formSelected()) return;

    this.element.setAttribute("aria-selected", "true");
  }

  closeAndSubmitForm() {
    if (!this.formSelected()) return;

    this.element.removeAttribute("aria-selected");
    this.submitForm();
  }

  submitForm() {
    // <form>.requestSubmit() is not supported on Safari prior to version 16 (2022/09/12),
    // but requestSubmit triggers html form validations, which are nice for this form,
    // so if available, use requestSubmit, else fallback to classic submit.
    // NB: even on Safari 16, requestSubmit does not support passing a submit btn
    // to simulate a submit from this btn.
    // NB2: feels like Safari wanna play IE style ...
    if (this.initialPageNumberValue == this.pageNumberInputTarget.value) return;

    if (this.formTarget.requestSubmit) {
      this.formTarget.requestSubmit();
    } else {
      this.formTarget.submit();
    }
  }
}
