import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["validationSubmitBtn", "codeInput", "siteAndCodeSubmitBtn", "details"]

  static values = {
    validateAtConnect: Boolean
  }

  connect() {
    if (this.validateAtConnectValue) {
      this.requireValidation();
    }
  }

  requireValidation() {
    if (this.hasValidationSubmitBtnTarget) {
      // this.element.requestSubmit(this.validationSubmitBtnTarget); // not supported by Safari
      this.validationSubmitBtnTarget.click();
    }
  }

  requireAvailabilityPart() {
    const codeValue = this.codeInputTarget.value;
    if (codeValue) {
      // this.element.requestSubmit(this.siteAndCodeSubmitBtnTarget); // not supported by Safari <= 16
      this.siteAndCodeSubmitBtnTarget.click();
    } else {
      this.detailsTarget.innerHTML = null;
    }
  }
}
