<template lang="pug">
.mt-3
  .form-group
    label
      | {{ $t("automations.labels.fromModuleOutput") }}
    v-select.bg-white(
      :options="moduleInputPositions"
      v-model="moduleInputPosition"
    )
  .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
    button.btn.btn-primary(
      type="button"
      :disabled="!formIsValid"
      @click.stop="updateAutomationModule"
    ) {{ $t('save') }}

</template>

<script>
import { api } from '../../../../api/client';

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
     automationModulePosition: {
      type: Number,
      required: true,
    },
    configParams: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      moduleInputPosition: null,
      moduleInputPositions: []
    }
  },
  created() {
    if (this.configParams) {
      this.moduleInputPosition = this.configParams.module_input_position;
    }
    this.moduleInputPositions = Array.from({ length: this.automationModulePosition - 1 }, (_, i) => i + 1)
  },
  computed: {
    form() {
      return {
        automationModuleId: this.automationModuleId,
        configParams: {
          module_input_position: this.moduleInputPosition,
        }
      }
    },
    formIsValid() {
      const { configParams } = this.form
      return !!(
        this.form.automationModuleId &&
        configParams.module_input_position
      );
    }
  },
  methods: {
    updateAutomationModule() {
      if (this.formIsValid) {
        api.automationModules.update(this.form)
      }
    }
  }
}
</script>
