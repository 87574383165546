<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockLists"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.list_id"
      )
    .form-group
      label {{ $t('dashboardBlocks.sourcedChart.labels.sources') }}
      v-select(
        :searching="true"
        :options="sources"
        :get-option-label="option => $t(`dashboardBlocks.sourcedChart.sources.${option}`)"
        v-model="selectedSource"
      )
    .form-group
      .d-flex.align-items-center.mr-4
        input.rounded.border(
          type="checkbox"
          :id="`real_time_kpi_${this.dashboardBlock.id}`"
          v-model="editableSettings.real_time"
        )
        label.ml-2.mb-0.cursor-pointer(
          :for="`real_time_kpi_${this.dashboardBlock.id}`"
        ) {{ $t('dashboardBlocks.sourcedChart.realTime') }}
    .form-group
      template(v-if="selectedSource")
        SourceSettings(
          :dashboardBlock="dashboardBlock"
          :sourceSettings="editableSettings.sourceSettings"
          :fieldSettingProperties="fieldSettingPropertiesForSelectedSource"
          :fieldsForSelectedTable="fieldsForSelectedTable")
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DashboardBlock           from '../../../models/dashboardBlock';
import { api } from '../../../api/client';
import SourceSettings from './sourced_charts_sources/SourceSettings';



export default {
  components: {
    SourceSettings,
  },
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      requiredFieldsBySource: {},
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    selectedDashboardBlockList() {
      if (this.editableSettings.list_id) {
        return DashboardBlock.find(this.editableSettings.list_id);
      }
    },
    selectedTable() {
      if (this.selectedDashboardBlockList && this.selectedDashboardBlockList.kind_settings.table_id) {
        const tableId = this.selectedDashboardBlockList.kind_settings.table_id;
        return this.getTableById(tableId);
      }
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
    sources() {
      return Object.keys(this.requiredFieldsBySource);
    },
    selectedSource: {
      get() {
        return this.editableSettings.source;
      },
      set(newSource) {
        this.editableSettings.source = newSource;
        this.editableSettings.sourceSettings = { id: this.selectedTable.id, columns: {} };
      }
    },
    fieldSettingPropertiesForSelectedSource() {
      return this.requiredFieldsBySource[this.selectedSource];
    },
  },
  mounted() {
    this.fetchSourcesSettings();
    this.editableSettings.source ||= null;
    this.editableSettings.sourceSettings ||= { id: null, columns: {} }
  },
  methods: {
    fetchSourcesSettings() {
      api.sourceSettings.index()
         .then(({ data }) => {
          const requiredFieldsBySource = {};
          Object.keys(data).forEach((source) => {
            requiredFieldsBySource[source] = data[source]["required_fields"];
          });
          this.requiredFieldsBySource = requiredFieldsBySource;
        });
    },
  },
  watch: {
    'editableSettings.source': function(_newSource, _oldSource) {
      this.editableSettings.sourceSettings = { id: this.selectedTable.id, columns: {} };
    }
  }
}
</script>
