if (process.env.NODE_ENV === 'development') {
  window.startMonitor = function(comment) {
    console.log("-----------------------------------------------------------------------");
    console.log(comment);
    window.startMonitorHeapSize = performance.memory.totalJSHeapSize
    console.log("heap size before", startMonitorHeapSize / 1000000)
    window.startMonitorTime = new Date();
  }
  window.endMonitor = function() {
    console.log("timing", new Date() - startMonitorTime);
    console.log("heap size after", performance.memory.totalJSHeapSize / 1000000, "diff is ", (performance.memory.totalJSHeapSize - startMonitorHeapSize) / 1000000);
  }
}
