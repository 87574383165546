<template lang="pug">
  div
    .form-group
      label(for="concerned-table-ids") {{ $t('dashboardBlocks.labels.concerned_table_ids') }}
      v-select(
        :searching="true"
        :multiple="true"
        inputId="concerned-table-ids"
        label="name"
        :options="tables"
        :reduce="option => option.id"
        v-model="editableSettings.concerned_table_ids"
      )

    template(v-if="editableSettings.concerned_table_ids && editableSettings.concerned_table_ids.length")
      hr(:class="'border-' + (tableIdForConfiguration ? 'success' : 'warning')")
      .form-group#select-table-for-schedule-configuration
        label(for="select-table-to-configure") {{ $t('dashboardBlocks.labels.select_table_to_configure') }}
        v-select(
          :searching="true"
          inputId="select-table-to-configure"
          :options="concernedTables"
          label="name"
          :reduce="option => option.id"
          v-model="tableIdForConfiguration"
          :class="tableIdForConfiguration ? 'table-selected' : 'no-table-selected'"
        )
      hr(:class="'border-' + (tableIdForConfiguration ? 'success' : 'warning')")

    template(v-if="tableIdForConfiguration")
      .form-group
        label(for="level-one-view-ids") {{ $t('dashboardBlocks.labels.level_view_ids', { level: '1' }) }}
        v-select(
          :searching="true"
          :multiple="true"
          inputId="level-one-view-ids"
          :options="viewsForSelectedTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.settings_by_table_id[tableIdForConfiguration].level_one_view_ids"
        )

      .form-group
        label(for="level-two-view-ids") {{ $t('dashboardBlocks.labels.level_view_ids', { level: '2' }) }}
        v-select(
          :searching="true"
          :multiple="true"
          inputId="level-two-view-ids"
          :options="viewsForSelectedTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.settings_by_table_id[tableIdForConfiguration].level_two_view_ids"
        )

      .form-group
        label(for="level-three-view-ids") {{ $t('dashboardBlocks.labels.level_view_ids', { level: '3' }) }}
        v-select(
          :searching="true"
          :multiple="true"
          inputId="level-three-view-ids"
          :options="viewsForSelectedTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.settings_by_table_id[tableIdForConfiguration].level_three_view_ids"
        )

      .form-group(
        v-for="settingProperty in fieldSettingProperties"
        :key="settingProperty"
      )
        label(:for="settingProperty") {{ $t('dashboardBlocks.labels.' + settingProperty) }}
        v-select(
          :searching="true"
          label="name"
          :inputId="settingProperty"
          :options="fieldsForSelectedTable"
          :reduce="option => option.id"
          v-model="editableSettings.settings_by_table_id[tableIdForConfiguration][settingProperty]"
        )

    hr

    .form-group
      label(for="alerts-list-id") {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        inputId="alerts-list-id"
        :options="dashboardBlockList"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.list_id"
      )

    template(v-if="editableSettings.list_id")
      .form-group
        label(for="default-title-field-id") {{ $t('dashboardBlocks.labels.default_title_field_id') }}
        v-select(
          :searching="true"
          inputId="default-title-field-id"
          :options="fieldsForListTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.default_title_field_id"
        )

      .form-group
        label(for="default-description-field-id") {{ $t('dashboardBlocks.labels.default_description_field_id') }}
        v-select(
          :searching="true"
          inputId="default-description-field-id"
          :options="fieldsForListTable"
          label="name"
          :reduce="option => option.id"
          v-model="editableSettings.default_description_field_id"
        )

      .form-group
        label(for="list-alert-default-level") {{ $t('dashboardBlocks.labels.list_alert_default_level') }}
        v-select(
          :searching="true"
          inputId="list-alert-default-level"
          label="value"
          :options="alertLevels"
          :reduce="option => option.value"
          v-model="editableSettings.list_alert_default_level"
        )
    hr

    h6.font-weight-bold.text-uppercase {{ $t('dashboardBlocks.labels.advanced_params') }}

    .form-group
      label(for="alerts-level-one-name") {{ $t('dashboardBlocks.labels.level_name', { level: '1' }) }}
      input.form-control(
        id="alerts-level-one-name"
        type="text"
        v-model="editableSettings.level_one_name"
      )

    .form-group
      label(for="alerts-level-two-name") {{ $t('dashboardBlocks.labels.level_name', { level: '2' }) }}
      input.form-control(
        id="alerts-level-two-name"
        type="text"
        v-model="editableSettings.level_two_name"
      )

    .form-group
      label(for="alerts-level-three-name") {{ $t('dashboardBlocks.labels.level_name', { level: '3' }) }}
      input.form-control(
        id="alerts-level-three-name"
        type="text"
        v-model="editableSettings.level_three_name"
      )

    .form-group
      label(for="alerts-level-one-color") {{ $t('dashboardBlocks.labels.level_color', { level: '1' }) }}
      input.form-control(
        id="alerts-level-one-color"
        type="text"
        v-model="editableSettings.level_one_color"
      )

    .form-group
      label(for="alerts-level-two-color") {{ $t('dashboardBlocks.labels.level_color', { level: '2' }) }}
      input.form-control(
        id="alerts-level-two-color"
        type="text"
        v-model="editableSettings.level_two_color"
      )

    .form-group
      label(for="alerts-level-three-color") {{ $t('dashboardBlocks.labels.level_color', { level: '3' }) }}
      input.form-control(
        id="alerts-level-three-color"
        type="text"
        v-model="editableSettings.level_three_color"
      )

    .form-group
      label(for="concerned-months") {{ $t('dashboardBlocks.labels.concerned_months') }}
      v-select(
        :searching="true"
        :multiple="true"
        inputId="concerned-months"
        label="name"
        :options="months"
        :reduce="option => option.value"
        v-model="editableSettings.concerned_months"
      )

    .form-group.d-flex.align-items-center.justify-content-between.mb-2
      label.mb-0(for="enabled-only-on-weekdays") {{ $t('dashboardBlocks.labels.enabled_only_on_weekdays') }}
      input.ml-2(
        type="checkbox"
        id="enabled-only-on-weekdays"
        name="enabled-only-on-weekdays"
        v-model="editableSettings.enabled_only_on_weekdays"
      )

    .form-group.d-flex.align-items-center.justify-content-between.mb-2
      label.mb-0(for="disabled-on-holidays") {{ $t('dashboardBlocks.labels.disabled_on_holidays') }}
      input.ml-2(
        type="checkbox"
        id="disabled-on-holidays"
        name="disabled-on-holidays"
        v-model="editableSettings.disabled_on_holidays"
      )

    .form-group
      label(for="additional-params") {{ $t('dashboardBlocks.labels.additional_params') }}
      v-select(
        :searching="true"
        :multiple="true"
        inputId="additional-params"
        label="name"
        :options="additionalParamsOptions"
        :reduce="option => option.id"
        v-model="editableSettings.additional_param_ids"
      )

    .form-group(
      v-for="additionalParamId in editableSettings.additional_param_ids"
      :key="additionalParamId"
    )
      template(v-if="getadditionalParamOptionById(additionalParamId)")
        label(for="additional-param") {{ getadditionalParamOptionNameById(additionalParamId) }}
        input.form-control(
          :id="'additional-param-' + additionalParamId"
          type="text"
          v-model="editableSettings.additional_params[additionalParamId]"
        )
</template>

<script>
import { mapState,
         mapGetters } from 'vuex'
import DashboardBlock from '../../../models/dashboardBlock';
import { EventBus }   from '../../../main';
import { difference } from 'lodash';
import i18n           from '../../../../vue/locales/locales';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableIdForConfiguration: null,
      fieldSettingProperties: [
        'title_field_id',
        'description_field_id',
        'reference_field_id',
      ],
      dateFieldIdsProperties: ['start_date_field_id', 'end_date_field_id'],
      dateTimeRangeType: [
        { name: i18n.t('dashboardBlocks.labels.dateTimeRangeTypes.dates'), value: 'dates' },
        { name: i18n.t('dashboardBlocks.labels.dateTimeRangeTypes.dateTimeRange'), value: 'date_time_range' }
      ],
      alertLevels: [
        { value: '0' },
        { value: '1' },
        { value: '2' },
        { value: '3' },
      ],
      months: [
        { value: '1', name: i18n.t('months.january') },
        { value: '2', name: i18n.t('months.february') },
        { value: '3', name: i18n.t('months.march') },
        { value: '4', name: i18n.t('months.april') },
        { value: '5', name: i18n.t('months.may') },
        { value: '6', name: i18n.t('months.june') },
        { value: '7', name: i18n.t('months.july') },
        { value: '8', name: i18n.t('months.august') },
        { value: '9', name: i18n.t('months.september') },
        { value: '10', name: i18n.t('months.october') },
        { value: '11', name: i18n.t('months.november') },
        { value: '12', name: i18n.t('months.december') },
      ],
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
      getViewById: 'viewStore/getViewById',
      getFieldById: 'fieldStore/getAllFieldById',
    }),
    dashboardBlockList() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    concernedTables() {
      return this.tables.filter(table => this.editableSettings.concerned_table_ids.includes(table.id));
    },
    selectedTable() {
      if (this.tableIdForConfiguration) {
        return this.tables.find(table => table.id === this.tableIdForConfiguration);
      }
    },
    fieldsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.fields;
      }
      return [];
    },
    viewsForSelectedTable() {
      if (this.selectedTable) {
        return this.selectedTable.views;
      }
      return [];
    },
    listTable() {
      if (!this.editableSettings.list_id) return;

      const table_id = DashboardBlock.find(this.editableSettings.list_id).kind_settings.table_id;

      return this.tables.find(table => table.id === table_id);
    },
    fieldsForListTable() {
      if (!this.listTable) return [];

      return this.listTable.fields;
    },
    additionalParamsOptions() {
      let tableIds = [];
      let options = [];

      if (this.listTable) tableIds.push(this.listTable.id);
      if (this.editableSettings.concerned_table_ids.length) {
        tableIds = [...tableIds, ...this.editableSettings.concerned_table_ids];
      }

      tableIds.forEach(tableId => {
        const table = this.getTableById(tableId);

        options.push({ id: `table${table.id}`, name: `${i18n.t('table')}: ${table.name}` })
        table.views.forEach(view => {
          options.push({ id: `view${view.id}`, name: `${i18n.t('view')}: ${table.name}/${view.name}` })
        });
        table.fields.forEach(field => {
          options.push({ id: `field${field.id}`, name: `${i18n.t('field')}: ${table.name}/${field.name}` })
        });
      });

      return options;
    }
  },
  mounted() {
    this.editableSettings.list_alert_default_level ||= '0';
    this.editableSettings.settings_by_table_id ||= {};
    this.editableSettings.concerned_table_ids ||= [];
    this.editableSettings.additional_params ||= {};
  },
  watch: {
    'dashboardBlock.kind_settings': function(newSettings, oldSettings) {
      if (newSettings.concerned_table_ids.length !== oldSettings.concerned_table_ids.length) {
        EventBus.$emit('dashboard-block-updated-' + this.dashboardBlock.id, { type: 'tablesChanged' });
      }
      const viewsToFetch = [];
      newSettings.concerned_table_ids.forEach((tableId) => {
        const oldTableSettings = oldSettings.settings_by_table_id[tableId];
        if (oldTableSettings) {
          const currentViewId = newSettings.settings_by_table_id[tableId].view_id
          const viewChanged   = currentViewId !== oldTableSettings.view_id;
          if (viewChanged) viewsToFetch.push(currentViewId);
        }
      });
      if (viewsToFetch.length) {
        EventBus.$emit('dashboard-block-updated-' + this.dashboardBlock.id, { type: 'viewsChanged' });
      }
    },
    'editableSettings.concerned_table_ids': function(newSettings, oldSettings) {
      if (!newSettings.includes(this.tableIdForConfiguration)) this.tableIdForConfiguration = null;
      const addedTableIds   = difference(newSettings, oldSettings);
      addedTableIds.forEach(tableId => this.editableSettings.settings_by_table_id[tableId] = {});
      const removedTableIds = difference(oldSettings, newSettings);
      removedTableIds.forEach((tableId) => { delete this.editableSettings.settings_by_table_id[tableId] });
    },
    'editableSettings.additional_param_ids': function(newSettings, oldSettings) {
      const removedElementIds = difference(oldSettings, newSettings);
      removedElementIds.forEach(elementId => delete this.editableSettings.additional_params[elementId]);
    }
  },
  methods: {
    getadditionalParamOptionById(elementId) {
      return this.additionalParamsOptions.find(option => option.id === elementId);
    },
    getadditionalParamOptionNameById(elementId) {
      return this.getadditionalParamOptionById(elementId)?.name;
    }
  }
}
</script>
