import { api }                  from '../api/client';
import { mapActions, mapState } from 'vuex';

export const AddNewFieldMixin = {
  computed: {
    ...mapState({
      currentTable: state => state.tableStore.table
    })
  },
  methods: {
    ...mapActions({
      addField: 'fieldStore/addField',
    }),
    addNewField() {
      const field = {
        field_type: "string",
        table_id:   this.currentTable.id,
      };

      api.fields.create({ field: field });
    }
    }
};
