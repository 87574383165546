<template lang="pug">
  .view-action.mr-1.p-0(
    v-if="canEditField"
    @click="$emit('open')"
    :class="{ opened: show }"
  )
    span.view-action--btn.icon-pointer
      i.far.fa-angle-down.fa-fw.view-action--btn-icon
    .ctrlbar-action(
      v-if="show"
      v-click-outside="closeAction"
      :class="{ right: !isFirstField }"
    )
      .ctrlbar-action--header(@click.stop="closeAction")
        div
          i.far.fa-edit.mr-2
          span.mr-1 {{ $t('actionsColumn.editColumn') }}
          span.font-weight-bolder(
            :class="{ [translationFallbackClasses]: field.nameFallback }"
          ) {{ editedField.name }}
        .ctrlbar-action--close(@click.stop="closeAction")
          svg.icon
            use(xlink:href="#icon-times-solid")

      .ctrlbar-action--body.mt-2.px-1
          div(v-if="currentUser.superAdmin")
            .mb-2
              input.cursor-pointer(
                :id="`field-${field.id}-locked`"
                v-focus
                type="checkbox"
                v-model="editedField.locked"
                :disabled="!canEditField"
              ).mr-1
              label.font-weight-bolder(
                :for="`field-${field.id}-locked`"
              ) {{ $t('actionsColumn.lockedField') }}

            .mb-2
              input.cursor-pointer(
                :id="`system-field-${field.id}`"
                v-focus
                type="checkbox"
                v-model="editedField.isSystem"
                :disabled="!canEditField"
              ).mr-1
              label.font-weight-bolder(
                :for="`system-field-${field.id}`"
              ) {{ $t('actionsColumn.systemField') }}

          label.font-weight-bolder {{ $t('actionsColumn.nameColumn') }}
          input.form-control(
            v-focus
            v-model="editedField.name"
            :disabled="!canEditField"
            :class="{ [translationFallbackClasses]: field.nameFallback }"
          )

          .mt-2
            label.font-weight-bolder {{ $t('actionsColumn.description') }}
            textarea.pt-1.form-control(
              v-model="editedField.description"
              :disabled="!canEditField"
              :class="{ [translationFallbackClasses]: field.nameFallback }"
            )

          section.mt-2
            label.font-weight-bolder {{ $t('actionsColumn.typeOfData') }}
            v-select(
              input-id="edit-field-type"
              appendToBody
              :searching="true"
              :clearable="false"
              :disabled="!canEditField"
              :options="fieldTypes"
              :get-option-label="option => $t('fieldType.' + option)"
              v-model="editedField.fieldType"
            )
              template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}

          hr(v-if="fieldTypeHasOptions")

          component.mt-2(
            v-if="editedField.fieldType"
            :is="'fieldTypeEdit-' + editedField.fieldType"
            :editedField="editedField"
            :canEditField="canEditField"
          )
      template(v-if="canEditField")
        hr
        .ctrlbar-action--footer.flex-column.mt-3.mb-2
          button.default-btn.w-100(
            @click.stop="applyModifications"
            :disabled="!canEditField"
          ) {{ $t('save') }}

          a.b-inline-block.mt-3.mx-auto(
            href="#"
            @click.prevent.stop="deleteFieldThenClose"
            v-if="canEditField && !field.primary"
          )
            span.text-red-700
              i.far.fa-trash-alt.mr-2
              span.mr-1 {{ $t('delete') }}
              span.font-weight-bolder {{ field.name }}
</template>

<script>
import { api } from '../../api/client';
import { FieldTypeEditMixin } from "../../mixins/FieldTypeEditMixin";
import { DeleteFieldMixin } from "../../mixins/DeleteFieldMixin";
import Field from '../../models/field';
import { mapState, mapGetters } from 'vuex';
import { EventBus } from '../../main.js';
import { cloneDeep, assign, pick } from "lodash";
import { InsertFieldNameMixin } from '../../mixins/insertFieldNameMixin';

export default {
  mixins: [FieldTypeEditMixin, DeleteFieldMixin, InsertFieldNameMixin],
  props: {
    show: {
      type: Boolean,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    isFirstField: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      editedField: {
        id: null,
        name: null,
        description: null,
        dataType: null,
        fieldType: null,
        system: null,
        typeOptions: {},
        nameFallback: null,
        descriptionFallBack: null,
      },
      fieldTypes:  Field.fieldTypes,
      defaultTypeOptions: {}
    };
  },
  watch: {
    show: function(newValue, oldValue) {
      if (!oldValue && newValue) this.openAction();
    },
    "editedField.fieldType": function(newValue, oldValue) {
      if (newValue && oldValue) {
        this.editedField.typeOptions = {}
        this.setEditedFieldTypeOptions()
      }
    }
  },
  computed: {
    ...mapState(['currentUser', 'feature', 'translationFallbackClasses']),
    ...mapGetters({
      visibleRecords: 'recordStore/visibleRecords',
      currentFields: 'fieldStore/currentFields',
    }),
    fieldTypeHasOptions() {
      const typeOptions = this.defaultTypeOptions[this.field.fieldType]
      if (typeOptions) {
        return !!Object.keys(typeOptions).length
      }
    },
    fieldTypeHasChanged() {
      return this.field.fieldType !== this.editedField.fieldType
    },
    canEditField() {
      return this.feature.canEditFields && (this.currentUser.superAdmin || !this.field.locked);
    },
  },
  methods: {
      async openAction() {
      // make sure popup is fully in viewport
      EventBus.$emit('gridNavigated', null, this.field, null);

      // type options defaults are fetch from the API so there is only
      // one source of trust : Field class.
      // The defaults are fetched only the first time they are needed ;
      // after that, they are memoized on Field (js).
      // It makes the first open of a field's setting popup async.
      this.defaultTypeOptions = await Field.defaultTypeOptions()
      this.setEditedField()
    },
    closeAction() {
      this.setEditedField();
      this.$emit('close');
    },
    setEditedField() {
      this.editedField = assign(this.editedField, pick(this.field, ["id", "name", "description", "dataType", "fieldType", "isSystem", "locked", "nameFallback", "descriptionFallback"]));
      this.editedField.typeOptions = cloneDeep(this.field.typeOptions);
      if (this.editedField.fieldType === "formula") { this.insertFieldNamesInFormula() }
      this.setEditedFieldTypeOptions();
    },
    setEditedFieldTypeOptions() {
      const { fieldType, typeOptions } = this.editedField
      this.editedField.typeOptions = assign(cloneDeep(this.defaultTypeOptions[fieldType]), typeOptions)
    },
    applyModifications() {
      if (this.fieldTypeHasChanged && this.canEditField) {
        this.updateEditedFieldDataType();
        this.updateFieldEntriesWithDefaultValue()
      }
      this.sendUpdate();
    },
    updateEditedFieldDataType() {
      if (['created_at', 'last_modified_at'].includes(this.editedField.fieldType)) {
        this.editedField.dataType = 'date';
        this.editedField.typeOptions.date_format = 'with_time';
      } else if (['created_by', 'last_modified_by'].includes(this.editedField.fieldType)) {
        this.editedField.dataType = 'users';
      } else if (this.editedField.fieldType !== "formula") {
        this.editedField.dataType = this.editedField.fieldType;
      }
    },
    updateFieldEntriesWithDefaultValue() {
      const defaultValue = Field.defaultEntryValues[this.editedField.dataType]
      this.visibleRecords.forEach((record) => {
        record.buildEntries();
        record.entriesByFieldId[this.editedField.id].value = defaultValue()
      });
    },
    sendUpdate() {
      api.fields.update({ field: this.apiFormattedField() })
         .then(this.updateField)
         .then(this.closeAction)
    },
    apiFormattedField() {
      return {
        id:           this.editedField.id,
        name:         this.editedField.name,
        type_options: this.editedField.typeOptions,
        data_type:    this.editedField.dataType,
        field_type:   this.editedField.fieldType,
        description:  this.editedField.description,
        system:       this.editedField.isSystem,
        locked:       this.editedField.locked,
      };
    },
    updateField({ data: { meta: { access_right }, field } }) {
      // this.updateCurrentField(new Field(field, this.editedField.table));
      this.updateCurrentField(field);
      this.updateFieldAccessRight(access_right);
    },
    deleteFieldThenClose() {
      this.deleteField(this.field).then(() => this.closeAction());
    },
    updateCurrentField(fieldPayload) {
      this.field.name = fieldPayload.name
      this.field.description = fieldPayload.description
      this.field.dataType = fieldPayload.data_type
      this.field.fieldType = fieldPayload.field_type
      this.field.typeOptions = fieldPayload.type_options
      this.field.isSystem = fieldPayload.system
      this.field.locked = fieldPayload.locked
      this.field.nameFallback = fieldPayload.name_fallback
      this.field.descriptionFallback = fieldPayload.description_fallback
      this.field.setIcon()
    },
    updateFieldAccessRight(accessRight) {
      this.field.setAccessRight(accessRight);

      this.visibleRecords.forEach(record => {
        if (record.locked) return;

        record.buildEntries();
        record.entriesByFieldId[this.field.id].setAccessRight(accessRight);
      });
    },
    insertFieldNamesInFormula() {
      const { formula } = this.editedField.typeOptions;
      const formulaWithFieldsNames = this.insertFieldNameInContent(formula, this.currentFields);
      this.editedField.typeOptions.formula = formulaWithFieldsNames;
    }
  },
};
</script>

<style lang="scss" scoped>
  .icon-pointer {
    cursor: pointer;
    opacity: 1;
  }

  .ctrlbar-action {
    top: 25px;
  }

  .ctrlbar-action--body {
    min-width: 260px;
  }

  .ctrlbar-action.right {
    right: 0;
    left: auto;
  }
</style>
