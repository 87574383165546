import Cell from '../cell';

export default class TextCell extends Cell {
  get readHTML() {
    const value = this.value || 0;
    const unitSymbol = this.column.options.unit_symbol;

    const unitSymbolHTML = unitSymbol ? `<span class="cell-number-unit">${unitSymbol}</span>` : "";

    return `
      <div class="cell-content number-cell-content">
        ${value}
        ${unitSymbolHTML}
      </div>`;
  }

  writeHTML(value) {
    value ||= 0;

    return `
      <form data-turbo-stream="true" action="${this.updateUrl}" method="${this.updateMethod}" class="cell-content">
        <input name="${this.inputName}" type="number" value="${value}" class="input-cell" />
      </form>
    `;
  }

  addEventListeners() {
    this.container.addEventListener("dblclick", () =>  this.edit());
    document.addEventListener('keydown', (event) => {
      if (this.selected && event.key === "Enter") {
        event.preventDefault();

        if (this.inReadMode) {
          this.edit();
        } else {
          setTimeout(() => this.selectDownCell());
        }
      } else if (event.key === "Escape" && this.inWriteMode) {
        event.preventDefault();

        this.unselect();
      }
    });

    document.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  edit(value = this.value) {
    if (!this.setWriteMode(value)) return;

    this.navigable = false;
    this.input.focus();
    // this.input.selectionStart = this.input.selectionEnd = this.input.value.length;
  }

  editionModeValue() {
    return this.input.value;
  }

  update(value) {
    super.update(value);
    this.setReadMode();
  }

  get input() {
    return this.container.querySelector("input");
  }

  handleKeyDown(event) {
    if (!this.selected || !this.editable) return;

    const keysToIgnore = [
      "CapsLock",
      "Control",
      "Alt",
      "Shift",
      "Meta",
      "Escape",
      "Backspace",
      "Space",
      "Enter",
      "ArrowUp",
      "ArrowDown",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ]

    if (event.key === "Backspace" && this.inReadMode) {
      event.preventDefault();

      this.empty();
    } else if (!keysToIgnore.includes(event.key) &&
      !event.ctrlKey &&
      !event.altKey &&
      !event.metaKey &&
      this.inReadMode) {
      event.preventDefault();

      this.edit(event.key);
    }
  }
}
