import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["form"]

    submitForm() {
        // <form>.requestSubmit() is not supported on Safari prior to version 16 (2022/09/12),
        // but requestSubmit triggers html form validations, which are nice for this form,
        // so if available, use requestSubmit, else fallback to classic submit.
        if (this.formTarget.requestSubmit) {
            this.formTarget.requestSubmit();
        } else {
            this.formTarget.submit();
        }
    }
}
