const initBootstrapTabs = () => {
  // Change hash for page-reload
  $('.nav-tabs a').on('shown.bs.tab', function (e) {
    const tab = e.target.hash.substring(1, e.target.hash.length)
    const url = `${window.location.origin}${window.location.pathname}?tab=${tab}`
    window.history.pushState(null, null, url)
  })
}

const initBootstrapTooltips = () => {
  $('body').tooltip({
    selector: '.has-tooltip[data-toggle="tooltip"]',
    delay: { "show": 300, "hide": 0 },
    boundary: 'viewport'
  });
};

export { initBootstrapTooltips, initBootstrapTabs };
