import { Controller } from "@hotwired/stimulus"
import { setCookie } from "../components/cookie";

const mdBreakpoint = 768;

export default class extends Controller {
  static targets = ["openMenuBtn"];

  open() {
    this.setSideMenuClosedCookie(false);
    this.element.setAttribute("open", "open");
    this.openMenuBtnTarget.setAttribute("aria-hidden", "true");
  }

  close() {
    this.setSideMenuClosedCookie(true);
    this.element.removeAttribute("open");
    this.openMenuBtnTarget.removeAttribute("aria-hidden");
  }

  resize() {
    this.defaultAction();
  }

  defaultAction() {
    if (window.innerWidth < mdBreakpoint) {
      this.close();
    } else {
      this.open();
    }
  }

  setSideMenuClosedCookie(value) {
    if (window.innerWidth < mdBreakpoint) {
      setCookie("side_menu_closed", true);
    } else {
      setCookie("side_menu_closed", value);
    }
  }
}
