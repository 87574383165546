import { apiClient, startFetch, endFetch } from '../client';
import { serialize } from 'object-to-formdata';

export default {
  create({ field }) {
    const saver = startFetch('SAVING');
    return apiClient.post(
      `api/tables/${field.table_id}/fields/`,
      { field: field }
    ).then(endFetch(saver));
  },
  update({ field }) {
    const saver = startFetch('SAVING');
    return apiClient.patch(
      'api/fields/' + field.id,
      serialize({ field }),
    ).then(endFetch(saver))
  },
  destroy({ field }) {
    const saver = startFetch('SAVING');
    return apiClient.delete(
      'api/fields/' + field.id
    ).then(endFetch(saver))
  },
  lookupsField({ field }) {
    return apiClient.get(`/api/lookups_fields/${field.id}`);
  },
  index({ tableId }) {
    return apiClient.get(`/api/fields`,
      { params: { table_id: tableId } }
    );
  },
  defaultTypeOptions() {
    return apiClient.get("api/type_options/defaults")
  }
}
