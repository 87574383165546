import { Controller } from "@hotwired/stimulus";
import Flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index";

// Connects to data-controller="flatpickr"
export default class extends Controller {
  static targets = [
    "monthSelector",
    "dateInput",
  ];
  static values = {
    locale: String,
    defaultDate: String,
  };

  connect() {
    Flatpickr(
      this.monthSelectorTarget,
      {
        locale: this.locale(),
        altInput: true,
        onChange: (_selectedDates, _dateStr, _instance) => {
          this.submitForm();
        },
        plugins: [
          new monthSelectPlugin({
            shorthand: true, //defaults to false
            dateFormat: "Y-m-d", //defaults to "F Y"
            altFormat: "F Y", //defaults to "F Y"
          })
        ],
        defaultDate: this.defaultDateValue,
      }
    );
  }

  locale() {
    const locales = {
      en: null, // Default
      fr: French,
      es: Spanish,
    }

    return locales[this.localeValue];
  }

  submitForm() {
    if (this.monthSelectorTarget.form.requestSubmit) {
      this.monthSelectorTarget.form.requestSubmit();
    } else {
      this.monthSelectorTarget.form.submit();
    }
  }
}
