import { api }                 from "../api/client";
import { requireConfirmation } from '../../components/require_confirmation';

export const DeleteFieldMixin = {
  methods: {
    deleteField(field) {
      return requireConfirmation(
        this.$t('actionsColumn.confirmDelete'),
        this.$t('apply'),
        this.$t('cancel'),
      ).then(result => {
          if(result.isConfirmed) {
            api.fields.destroy({ field: field }).then(() => {
              this.$store.dispatch("fieldStore/deleteField", field.id);
            });
          }
        });
    }
  }
}
