<template lang="pug">
  section.my-3
    .form-group
      label.font-weight-bolder {{ $t('editOptions.selectTable') }}
      v-select(
        :input-id="'edit-reference-table' + editedField.id"
        appendToBody
        :searching="true"
        :options="currentTables"
        :reduce="option => option.id"
        :get-option-label="option => option.name"
        v-model="editedField.typeOptions.foreign_table_id"
        :disabled="!canEditField"
      )
        template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}

    .mb-1.font-weight-bold
      input(
        type="checkbox"
        name="limitToOneRecord"
        v-model="editedField.typeOptions.limit_to_one_record"
        :disabled="!canEditField"
      )
      label.ml-2(
        for="limitToOneRecord"
      ) {{ $t('editOptions.oneEntry') }}

    label.font-weight-bolder {{ $t('editOptions.selectVue') }}
    v-select(
      :input-id="'edit-reference-view' + editedField.id"
      appendToBody
      :searching="true"
      :options="foreignTableViews"
      :reduce="option => option.id"
      :disabled="!canEditField"
      :get-option-label="option => option.name"
      :placeholder="$t('editOptions.noView')"
      v-model="editedField.typeOptions.view_id_for_selection"
    )
      template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: {
      editedField: {
        type: Object,
        required: true
      },
      canEditField: {
        type: Boolean,
        required: true
      },
    },
    computed: {
      ...mapState({
        currentTables: state => state.tableStore.tables,
        tableViews:    state => state.tableStore.table.views,
      }),
      foreignTableViews() {
        const table = this.currentTables.find(table => {
          return table.id === this.editedField.typeOptions.foreign_table_id;
        });
        if (!table) return [];
        return table.views;
      }
    },
  }
</script>
