<template lang="pug">
  .entry-input--reference
    .select-wrapper.read-only.reference-entry-content
      template(v-if="entry.value.length")
        span.tag.mr-2(
          v-for="reference in entry.value"
          :key="reference.foreign_record_id"
        ) {{ reference.foreign_record_display_name }}
      span.text-secondary.font-weight-lighter(v-else) {{ $t('entries.select.noReferences') }}

      .reference-entry-expand-content(
        v-if="canExpandReferences"
        @click="openEntryReferencesModal()"
      )
        i.fad.fa-expand-alt
</template>

<script>
import { ReferencesMixin } from "../../../mixins/entries/ReferencesMixin";

export default {
  mixins: [ReferencesMixin],
  props: {
    entry: {
      type: Object,
      required: true
    }
  },
  computed: {
    canExpandReferences() {
      return this.entry.value.length && !this.entry.field.isOwnTableRelationalField;
    }
  }
};
</script>
