<template lang="pug">
  div
    //- .form-group
    //-   label {{ $t('dashboardBlocks.labels.list_id') }}
    //-   v-select(
    //-     :searching="true"
    //-     :options="dashboardBlockLists"
    //-     label="title"
    //-     :reduce="option => option.id"
    //-     v-model="editableSettings.list_id"
    //-   )
    //- template(v-if="selectedDashboardBlockList && selectedTable")
    //-   .form-group
    //-     label {{ $t('dashboardBlocks.kpi.labels.api_source_id_field_id') }}
    //-     v-select(
    //-       :searching="true"
    //-       :options="fieldsForSelectedTable"
    //-       label="name"
    //-       :reduce="option => option.id"
    //-       v-model="editableSettings.api_source_id_field_id"
    //-     )
    //-   .form-group
    //-     label {{ $t('dashboardBlocks.timescale.labels.color') }}
    //-     v-select(
    //-       :searching="true"
    //-       :options="fieldsForSelectedTable"
    //-       label="name"
    //-       :reduce="option => option.id"
    //-       v-model="editableSettings.color_field_id"
    //-     )
    //- hr
    .form-group
      label {{ $t('dashboardBlocks.list.labels.associations_table_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="tables"
        :reduce="option => option.id"
        v-model="editableSettings.associations_table_id"
      )
      label {{ $t('dashboardBlocks.timescale.labels.aggregating_field_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="fieldsForPrincipalTableAggregation"
        :reduce="option => option.id"
        v-model="editableSettings.aggregating_field_id"
      )
      template(v-if="editableSettings.aggregating_field_id")
        label {{ $t('dashboardBlocks.timescale.labels.y_axis_field_id') }}
        v-select(
          :searching="true"
          label="name"
          :options="fieldsForSecondaryAggregation"
          :reduce="option => option.id"
          v-model="editableSettings.y_axis_field_id"
        )
      template(v-if="editableSettings.y_axis_field_id")
        label {{ $t('dashboardBlocks.timescale.labels.calculation_type') }}
        v-select(
          :options="calculationKind"
          label="name"
          :reduce="option => option.value"
          v-model="editableSettings.calculation_type"
        )
    hr
    .form-group
      label {{ $t('dashboardBlocks.timescale.labels.keep_empty_cell') }}
      v-select(
        :options="keepEmptyCell"
        label="name"
        :reduce="option => option.value"
        v-model="editableSettings.keepEmptyCell"
      )
    hr
    .form-group
      label {{ $t('dashboardBlocks.timescale.labels.chart_kind') }}
      v-select(
        :searching="true"
        :options="chartKindOptions"
        label="name"
        :reduce="option => option.value"
        v-model="editableSettings.chart_kind"
      )
</template>


<script>
import { mapGetters, mapState } from 'vuex';
import { EventBus }             from '../../../main';
import DashboardBlock           from '../../../models/dashboardBlock';
import { filter }       from 'lodash';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').get();
    },
    // selectedDashboardBlockList() {
    //   if (this.editableSettings.list_id) {
    //     return DashboardBlock.find(this.editableSettings.list_id);
    //   }
    // },
    // selectedTable() {
    //   if (this.selectedDashboardBlockList && this.selectedDashboardBlockList.kind_settings.table_id) {
    //     const tableId = this.selectedDashboardBlockList.kind_settings.table_id;
    //     return this.tables.find(table => table.id === tableId);
    //   }
    // },
    // fieldsForSelectedTable() {
    //   if (this.selectedTable) {
    //     return this.selectedTable.fields;
    //   }
    //   return [];
    // },
    chartKindOptions() {
      const chartKinds = ['bar'];
      // const chartKinds = ['line', 'bar', 'bar_double_axis'];
      return chartKinds.map((chartKind) => {
        return {
          name: this.$t(`charts.${chartKind}`),
          value: chartKind
        }
      })
    },
    associationsTable() {
      if (this.editableSettings.associations_table_id) {
        return this.getTableById(this.editableSettings.associations_table_id);
      }
    },
    fieldsForAssociationsTable() {
      if (this.associationsTable) {
        return filter(this.associationsTable.fields, ['dataType', 'references']);
      }
      return [];
    },
    fieldsForPrincipalTableAggregation() {
      if (this.associationsTable) {
        return this.associationsTable.fields;
      }
      return [];
    },
    fieldsForSecondaryAggregation() {
      if (this.associationsTable) {
        return this.associationsTable.fields.filter((field) => {
          return field.id !== this.editableSettings.aggregating_field_id;
        });
      }
      return [];
    },
    keepEmptyCell() {
      const keepEmptyCellValues = [
        'yes',
        'no'
      ];

      return keepEmptyCellValues.map((keepEmptyCell) => {
        return {
          name: this.$t(keepEmptyCell),
          value: keepEmptyCell
        }
      })
    },
    calculationKind() {
      const operators = [
        'SUM',
        'AVG',
        'MEDIAN'
      ];

      return operators.map((operator) => {
        return {
          name: this.$t(operator),
          value: operator
        }
      })
    }
  },
  watch: {
    'dashboardBlock.kind_settings': function(_newSettings, _oldSettings) {
      EventBus.$emit('dashboard-block-load-chart-data-' + this.dashboardBlock.id);
    },
    // 'editableSettings.list_id': function(newValue) {
    //   [
    //     'api_source_id_field_id',
    //   ].forEach(property => this.editableSettings[property] = null);
    //   this.editableSettings.view_id = null;
    // },
    'editableSettings.associations_table_id': function() {
      this.editableSettings.aggregating_field_id = null;
    },
    'editableSettings.y_axis_field_id': function() {
      if(!this.editableSettings.y_axis_field_id) {
        this.editableSettings.calculation_type = null;
      }
    }
  }
}
</script>
