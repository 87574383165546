import { apiClient, startFetch, endFetch } from '../client';

export default {
  load({ tableId = null, viewId = null, dashboardId = null, pageId = null }) {
    const loader = startFetch();
    return apiClient.get(
      '/projects/load',
      { params: { table_id: tableId, view_id: viewId, dashboard_id: dashboardId, page_id: pageId } }
    ).then(endFetch(loader))
  },
  createTable({ parentFolderId }) {
    const saver = startFetch('SAVING');
    return apiClient.post(
      '/tables',
      { parent_folder_id: parentFolderId }
    ).then(endFetch(saver));
  },
}
