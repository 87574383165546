import { apiClient, startFetch, endFetch, api } from '../client';

export default {
  load({ viewId, skipLoader = false }) {
    var loader;
    if (!skipLoader) { loader = startFetch(); }
    return apiClient.get('/views/' + viewId + '/load')
      .then((response) => {
        if (loader) return endFetch(loader)(response);
        return response;
      });
  },
  create({ originalViewId, scope, view }) {
    const saver = startFetch('SAVING');
    return apiClient.post('/views', {
      original_view_id: originalViewId,
      scope,
      view
    }).then(endFetch(saver));
  },
  saveFilters({ view }) {
    const saver = startFetch('SAVING');
    return apiClient.patch('/views/' + view.id + '/save_filters', view).then(endFetch(saver));
  },
  toggleAlert({ view }) {
    const saver = startFetch('SAVING');
    return apiClient.patch('/views/' + view.id, { view:{ alert: !view.alert } }).then(endFetch(saver));
  },
  update({ view }) {
    const saver = startFetch('SAVING');
    return apiClient.patch('/views/' + view.id, { view }).then(endFetch(saver));
  },
  setAsLastUsed({ viewId }) {
    return apiClient.post('/views/' + viewId + '/set_as_last_used');
  },
  dissociateOrDestroy({ viewId }) {
    const saver = startFetch('SAVING');
    return apiClient.delete('/views/' + viewId + '/dissociate_or_destroy').then(endFetch(saver));
  },
  toggleViewLocking({ view }) {
    const saver = startFetch('SAVING');
    return apiClient.patch('/views/' + view.id, { view:{ locked: !view.locked } }).then(endFetch(saver));
  },
  index({ tableId }) {
    return apiClient.get(`/api/views`, { params: { table_id: tableId } });
  },
  calendarVisibleRecordIds({ viewId, datesRange: [start, end], query }) {
    return apiClient.get(`/api/views/${viewId}/calendar_visible_record_ids`, { params: { start, end, query }});
  }
}
