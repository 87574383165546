import { apiClient, startFetch, endFetch } from '../client';

export default {
  forView({ viewId }) {
    const saver = startFetch('SAVING');
    return apiClient.get('/api/views/' + viewId + '/row_order').then(endFetch(saver));
  },
  forTable({ tableId, sortOrders = [] }) {
    const saver = startFetch('SAVING');
    return apiClient.get('/api/tables/' + tableId + '/row_order', { params: { sort_orders: JSON.stringify(sortOrders) } }).then(endFetch(saver));
  },
}
