
import { Model }    from '@vuex-orm/core';
import Dashboard    from './dashboard';
import { map }      from 'lodash';
import { EventBus } from '../main';

export default class DashboardBlock extends Model {
  static entity = 'dashboard_blocks';

  static fields () {
    return {
      id: this.attr(null),
      title: this.attr(''),
      title_fallback: this.boolean(false),
      kind: this.attr(''),
      layout_settings: this.attr({}),
      kind_settings: this.attr({}),
      dashboard_id: this.attr(null),
      dashboard: this.belongsTo(Dashboard, 'dashboard_id'),
    }
  }

  static beforeDelete (model) {
    EventBus.$emit('dashboard-block-deleted', model.id); // received in DashboardShow.vue
  }

  static apiConfig = {
    actions: {
      create(dashboardId, kind) {
        return this.post(`/api/dashboards/${dashboardId}/dashboard_blocks`, { kind });
      },
      update(dashboardId, dashboard_block) {
        return this.patch(`/api/dashboard_blocks/${dashboardId}`, { dashboard_block });
      },
      destroy(dashboardBlockId) {
        return this.delete(`/api/dashboard_blocks/${dashboardBlockId}`, {
          delete: dashboardBlockId
        });
      },
      saveLayout(newLayout, breakpoint) {
        return this.patch('/api/dashboard_blocks/update_layout', {
          ids:    map(newLayout, 'i'),
          layout: map(newLayout, (item) => {
            return {
              layout_settings: {
                [breakpoint]: {
                  x:      item.x,
                  y:      item.y,
                  width:  item.w,
                  height: item.h,
                  hidden: item.hidden
                }
              }
            }
          })
        })
      },
    }
  }

  layoutSettings(breakpoint) {
    const settings = this.layout_settings[breakpoint] || this.layout_settings;

    const hidden = settings.hidden || false;

    return {
      x: settings.x,
      y: settings.y,
      w: settings.width,
      h: settings.height,
      i: this.$id,
      hidden
    }
  }
};
