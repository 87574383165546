<template functional lang="pug">
  .cell-content.reference-entry-content
    .entry-reference(
      v-for="user in props.entry.displayValue"
    ) {{ user.displayName }}
</template>

<script>
  export default {
    props:  {
      entry: {
        type: Object,
        required: true
      }
    },
  };
</script>
