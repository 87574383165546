<template lang="pug">
  .entry-input--reference
    .select-wrapper.reference-entry-content
      .entry-reference(
        v-for="reference in entry.value"
        :key="reference.foreign_record_id"
        :class="{ 'default-cursor': entry.field.isOwnTableRelationalField }"
        @click="dispatchOpenReferenceModal(reference)"
      ) {{ reference.foreign_record_display_name }}
        span.ml-1.remove-reference-btn(
          v-if="entry.isWritable && !entry.field.isOwnTableRelationalField"
          @click.stop="removeReference(reference, entry)"
        )
          i.far.fa-times

      .add-reference-btn.ml-1(
        v-if="canAddReference && !entry.field.isOwnTableRelationalField"
        @click="openAddReferenceModal"
      )
        i.fa.fa-plus

      .reference-entry-expand-content(
        v-if="entry.value.length && !entry.field.isOwnTableRelationalField"
        @click="openEntryReferencesModal"
      )
        i.fad.fa-expand-alt
</template>

<script>
import { UpdateEntryMixin } from "../../../mixins/UpdateEntryMixin";
import { ReferencesMixin }  from "../../../mixins/entries/ReferencesMixin";
import { EventBus }         from '../../../main';

export default {
  mixins: [UpdateEntryMixin, ReferencesMixin],
  props: {
    entry: {
      type: Object,
      requried: true
    },
    recordModalPanel: {
      type: String
    }
  },
  computed: {
    isInRightPanel() {
      return this.recordModalPanel === 'right';
    },
    isParentRecordEntry() {
      if (this.entry.field.table.hasRelationship) {
        return this.entry.field.id === this.entry.field.table.relationalData.relationalField.typeOptions.symetric_field_id;
      }
    }
  },
  methods: {
    dispatchOpenReferenceModal(reference) {
      if (this.entry.field.isOwnTableRelationalField) return;

      this.openReferenceModal(reference);
    },
    openAddReferenceModal() {
      EventBus.$emit('openModal', {
        modalName: 'AddReferenceModal',
        modalProps: {
          entry:                          this.entry,
          shouldNotOpenNewReferenceModal: this.isParentRecordEntry && this.isInRightPanel
        }
      });
    },
  }
};
</script>
