import Swal from 'sweetalert2'

const requireConfirmation = (text, confirmText, cancelText, denyButtonText = null) => {
  return Swal.fire({
    html:               text,
    icon:               'warning',
    showCancelButton:   true,
    showDenyButton:     !!denyButtonText,
    confirmButtonText:  confirmText,
    cancelButtonText:   cancelText,
    denyButtonText:     denyButtonText,
    confirmButtonColor: '#2B6CB0',
    cancelButtonColor:  '#E53E3E',
    customClass:        { confirmButton: 'swal-btn', cancelButton: 'swal-btn', denyButton: 'swal-btn' },
    showClass:          {
      popup: 'animate__animated animate__headShake animate__faster',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp animate__faster'
    }
  });
};

export { requireConfirmation };
