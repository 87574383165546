import { mapGetters, mapActions, mapState } from 'vuex';
import { sortBy }                           from 'lodash';
import { api }                              from '../api/client';

export const MoveColumnMixin = {
  computed: {
    ...mapState({
      currentView: state => state.viewStore.view
    }),
    ...mapGetters({
      visibleFields: 'fieldStore/visibleFields'
    }),
    movableFields() {
      return this.visibleFields; // default, can be overwrited in component
    }
  },
  methods: {
    ...mapActions({
      computeFieldsLeftOffset: 'fieldStore/computeFieldsLeftOffset'
    }),
    moveColumn(event) {
      let fieldsToUpdate, initialOrder;
      const sortedVisbleFields = sortBy(this.movableFields, 'columnNumber');
      const oldPosition        = event.moved.element.columnNumber;
      const newPositionIndex   = event.moved.newIndex === sortedVisbleFields.length ? event.moved.newIndex - 1 : event.moved.newIndex;
      const newPosition        = sortedVisbleFields[newPositionIndex].columnNumber;

      if (oldPosition < newPosition) {
        fieldsToUpdate = sortedVisbleFields.filter(h => oldPosition < h.columnNumber && h.columnNumber <= newPosition);
        fieldsToUpdate.unshift(event.moved.element);
        initialOrder       = fieldsToUpdate.map(h => h.columnNumber);
        const fieldToMove = fieldsToUpdate.shift();
        fieldsToUpdate.push(fieldToMove);
      } else {
        fieldsToUpdate = sortedVisbleFields.filter(h => newPosition <= h.columnNumber && h.columnNumber < oldPosition);
        fieldsToUpdate.push(event.moved.element);
        initialOrder       = fieldsToUpdate.map(h => h.columnNumber);
        const fieldToMove = fieldsToUpdate.pop();
        fieldsToUpdate.unshift(fieldToMove);
      }

      for(let i = 0; i < fieldsToUpdate.length; i++) { fieldsToUpdate[i].columnNumber = initialOrder[i] };

      let columnNumberByFieldIds = {};
      fieldsToUpdate.forEach(field => columnNumberByFieldIds[field.id] = field.columnNumber);

      this.computeFieldsLeftOffset();
      api.viewFields.updateColumnsOrder({ viewId: this.currentView.id, columnNumberByFieldIds })
    }
  }
}
