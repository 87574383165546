<template lang="pug">
  .record-filters--table-prefilters.mb-3
    p.mb-1.pl-1.font-italic.prefilter-warning.mr-2(@click="showFilters = !showFilters") {{ $t('controlBarActions.tablePrefiltered') }}
    .filters-wrapper.border.rounded.pb-0.pt-2(v-if="showFilters")
      div
        .ctrlbar-action--action-option.mb-2(v-for="(filter, index) in builtFilters")

          .action-option--label.action-option--label-wide.text-left
            .text-center {{ $t(index === 0 ? 'controlBarActions.filteredBy' : query.logical_operator) }}

          .prefilter-input.mx-1.px-3.has-tooltip(
            data-toggle="tooltip"
            :title="filter.fieldName"
            :class="{ [translationFallbackClasses]: filter.fieldNameFallback }"
          ) {{ filter.fieldName }}
          .prefilter-input.prefilter-input--sm.mx-1.px-3.text-center.has-tooltip {{ filter.operatorName }}
          .prefilter-input.mx-1.px-3.has-tooltip(
            v-if="filter.value.length"
            data-toggle="tooltip"
            :title="filter.value"
          ) {{ filter.value }}
    hr(v-show="showFilters")
</template>

<script>
import { find, map }           from 'lodash';
import { operatorsByDataType } from '../../services/filterOperators';
import moment                  from 'moment';
import i18n                    from "../../locales/locales.js";
import User                    from "../../models/user";
import { mapState } from "vuex";

export default {
  props: ['query'],
  data() {
    return {
      showFilters: false,
      operatorsByDataType: operatorsByDataType
    }
  },
  computed: {
    ...mapState(['translationFallbackClasses']),
    builtFilters() {
      if (!this.query) return;

      return map(this.query.filters, (filter) => {
        const fieldOperators = this.operatorsByDataType[this.filterDataType(filter.field)];

        const operatorName = find(fieldOperators, ['operator', filter.operator]).name;
        const value        = this.formatFilterValue(filter, filter.field)
        const fieldName    = filter.field.name;
        const fieldNameFallback = filter.field.name_fallback;

        return { fieldName, operatorName, value, fieldNameFallback }
      })
    }
  },
  methods: {
    formatFilterValue(filter, field) {
      if ([null, undefined, ''].includes(filter.value)) {
        return '';
      } else if (['days from now', 'days ago'].includes(filter.operator)) {
        return filter.value + ' ' + this.$t('days');
      } else if (this.filterDataType(field) === 'date' && !['days from now', 'days ago'].includes(filter.operator)) {
        if (field.type_options.date_format === 'with_time') {
          return moment(new Date(filter.value)).format('DD/MM/YYYY HH:mm');
        } else {
          return moment(new Date(filter.value)).format('DD/MM/YYYY');
        }
      } else if ((field.dataType === 'users') || (field.field_type === 'lookups' && this.filterDataType(field) === 'users')) {
        return this.userTypeFormattedValue(filter);
      } else {
        return filter.value;
      }
    },
    filterDataType(field) {
      if (field.field_type === 'lookups') {
        return field.type_options.lookups_field_data_type;
      }
      return field.data_type;
    },
    userTypeFormattedValue(filter) {
      if (filter.value === 'current_user_id') {
        return i18n.t('current_user');
      } else {
        return User.find(filter.value).displayName;
      }
    }
  }
}
</script>
