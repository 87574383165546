<template lang="pug">
  div(@click="confirmDestroyAutomationModule")
    i.far.fa-trash-alt.fa-fw.delete-icon
</template>

<script>
import { api } from '../../../api/client';
import { requireConfirmation }  from '../../../../components/require_confirmation'

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    confirmText: {
      type: String,
      required: true,
    },
    confirmBtnText: {
      type: String,
      required: true,
    },
    cancelBtnText: {
      type: String,
      required: true,
    }
  },
  methods: {
    confirmDestroyAutomationModule() {
      requireConfirmation(
        this.confirmText,
        this.confirmBtnText,
        this.cancelBtnText
      ).then(result => { if(result.isConfirmed) this.destroyAutomationModule() })
    },
    destroyAutomationModule() {
      api.automationModules.destroy({
        automationModuleId: this.automationModuleId
      }).then(response => {
        const automationModuleContainerEl = document.getElementById(`automation-module-container-${response.data.id}`);

        automationModuleContainerEl.remove();
        location.reload();
      });
    }
  }
}
</script>
