import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["hours", "input"];

  connect() {
    this.isToggling = false;
    this.isRemoving = false;
    this.#run();
  }

  #run() {
    let startClasses = ["!tw-bg-green-200", "!tw-rounded-t-lg", "!tw-mb-0"];
    let interClasses = ["!tw-bg-green-200", "!tw-mb-0"];
    let endClasses = ["!tw-bg-green-200", "!tw-rounded-b-lg"];

    this.hoursInputs.forEach((hour) => {
      let label = hour.querySelector("label");
      let input = hour.querySelector("input");
      label.classList.remove(...startClasses, ...interClasses, ...endClasses);
      label.innerText = "";

      if (input.checked) {
        this.updateHour(hour, label, input, startClasses, interClasses, endClasses);
      }
    });
  }

  updateHour(hour, label, input, startClasses, interClasses, endClasses) {
    let prevInput = hour.previousSibling?.querySelector("input");
    let nextInput = hour.nextSibling?.querySelector("input");

    if (prevInput?.checked && nextInput?.checked) {
      label.classList.add(...interClasses);
    } else {
      if (!prevInput || !prevInput.checked) {
        label.classList.add(...startClasses);
        this.#insertRangeText(hour, input.dataset.hour);
      }

      if (!nextInput || !nextInput.checked) {
        label.classList.add(...endClasses);
        this.#insertRangeText(hour, nextInput ? nextInput.dataset.hour : "00:00");
      }
    }
  }

  togglingOn(event) {
    this.isToggling = true;
    this.startInput = event.currentTarget.querySelector("input");
    this.startInput.checked = !this.startInput.checked;
    this.isRemoving = !this.startInput.checked;
  }

  toggle(event) {
    event.currentTarget.checked = !event.currentTarget.checked;
    this.#run();
  }

  togglingOff() {
    this.isToggling = false;
    this.isRemoving = false;
  }

  select(event) {
    if (!this.isToggling) return;

    const hoveredInput = event.currentTarget.querySelector("input");
    let [start, end] = [this.startInput, hoveredInput].map(input => parseInt(input.dataset.index, 10)).sort((a, b) => a - b);

    this.hoursInputs.slice(start, end + 1).forEach(input => {
      input.querySelector("input").checked = this.startInput.checked;
    });

    this.#run();
  }

  get hoursInputs() {
    return Array.from(this.hoursTarget.children)
  }

  #insertRangeText(hour, text) {
    let label = hour.querySelector("label");
    if (label.innerText !== "") {
      label.innerText += ` - ${text}`
    } else {
      label.innerText += text
    }
  }
}
