<template lang="pug">
.form-control.date-input
  date-picker(
    v-model="tmpDate"
    :lang="this.$i18n.locale"
    type="datetime"
    range=true
    value-type="date"
    format="DD/MM/YYYY HH:mm"
    placeholder="DD/MM/YYYY HH:mm ~ DD/MM/YYYY HH:mm"
    :minute-step="5"
    :show-second="false"
    :confirm="true"
    @close="handleChange"
    @clear="handleChange"
  )
</template>

<script>
import { DateTimeRangePickerMixin } from '../../../mixins/entries/DateTimeRangePickerMixin';

export default {
  mixins: [DateTimeRangePickerMixin]
};
</script>
