<template lang="pug">
.right-panel(
  v-if="!isOnMobile || displayedPanels.right"
)
  .h-100(
    v-if="rightRecord"
  )
    .base-modal-header.record-modal--header.withEntries.px-1
      h5
        span.icon-action(
          v-if="parentRecord.id"
          @click="setChildRecord(null)"
        )
          i.far.fa-angle-double-left.fa-fw
        span.ml-2.font-weight-lighter {{ tableName }}

      .mb-3.d-flex.align-items-center
        h5.mb-0.text-truncate.has-tooltip(
          data-toogle="tooltip"
          :title="rightRecord.displayName"
        )
          i.far.fa-edit.text-info
          span.ml-2(
            v-if="recordHasDisplayName"
          ) {{ rightRecord.displayName }}
        .ml-2
          RecordMenu(
            :record="rightRecord"
            @closeModal="$emit('closeModal')"
          )
    .base-modal-body.mb-2.record-modal--body
      RecordEntriesInput(
        :record="rightRecord"
        recordModalPanel="right"
      )

    .base-modal-footer.record-modal--footer
      hr.mt-1
      button.btn.btn-light.btn-outline-primary.d-block.ml-auto(
          v-if="canBeRepeated"
          type="button"
          @click.prevent="openNewRepeatableRecordModal"
        )
          i.far.fa-redo-alt.mr-2
          span {{ $t("repeatable_records.repeat_this_record") }}

      template(v-if="shouldDisplayFooter")
        .record-modal--wil-be-filtered.text-center(
          v-if="!rightRecord.visible"
        ) {{ $t('recordWillBeFiltered') }}
        .d-flex.justify-content-center(v-if="newRecord")
          button.btn.btn-info.text-uppercase.text-light.btn-cancel(
            @click="$emit('closeModal', 'cancel')"
          ) {{ $t('cancel')}}
          button.btn.btn-info.text-uppercase.text-light.btn-submit.ml-5(
            v-if="canCreateRecord"
            @click="$emit('confirmRecordCreation', false)"
          ) {{ $t('create')}}

  .h-100(
    v-else
  )
    .base-modal-header.record-modal--header.px-1
      h5.cursor-pointeur(
        v-if="isOnMobile"
        @click="displayOnlyLeftPanel"
      )
        span.icon-action
          i.far.fa-angle-double-left.fa-fw
        span.ml-2 {{ parentRecordName }}
      h5
        span.ml-2.font-weight-lighter {{ tableName }}
    .base-modal-body.pt-4.record-modal--body
      RecordModalChildReferences(
        :record="rightRecord"
        :parentRecord="parentRecord"
        @setChildRecord="setChildRecord"
      )
</template>

<script>
import RecordEntriesInput         from '../../inputs/RecordEntriesInput.vue';
import RecordModalChildReferences from './RecordModalChildReferences.vue';
import { UpdateEntryMixin }       from "../../../mixins/UpdateEntryMixin";
import { mapState, mapGetters }   from "vuex";
import { TableRelationshipMixin } from '../../../mixins/TableRelationshipMixin';
import { requireConfirmation }    from '../../../../components/require_confirmation';
import { EventBus }               from '../../../main';
import RecordMenu                 from './RecordMenu';

export default {
  components: {
    RecordEntriesInput,
    RecordModalChildReferences,
    RecordMenu
  },
  mixins: [UpdateEntryMixin, TableRelationshipMixin],
  props: {
    parentRecord: {
      type: Object,
      required: true
    },
    rightRecord: {
      type: Object
    },
    displayedPanels: {
      type: Object,
      required: true
    },
    isOnMobile: {
      type: Boolean,
      required: true
    },
    newRecord: {
      type: Boolean,
      required: true
    },
    canCreateRecord: {
      type: Boolean,
      required: true
    },
    recordModalKey: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      tables:       state => state.tableStore.tables,
      currentTable: state => state.tableStore.table
    }),
    ...mapGetters({
      getTableById: 'tableStore/getTableById'
    }),
    tableName() {
      if (this.rightRecord && this.rightRecord.table) return this.rightRecord.table.name;
      if (this.parentRecord.table.relationalData.childTable) return this.parentRecord.table.relationalData.childTable.name;
    },
    parentRecordName() {
      return this.parentRecord.displayName;
    },
    referenceToParentEntry() {
      if (this.rightRecord) {
        const symetricFieldId = this.rightRecord.table.relationalData.relationalField.typeOptions.symetric_field_id;
        return this.rightRecord.entriesByFieldId[symetricFieldId];
      }
    },
    referenceToParentEntryLength() {
      if (this.rightRecord) {
        return this.referenceToParentEntry.value.length;
      }
    },
    shouldDisplayFooter() {
      return this.rightRecord && this.rightRecord.table === this.currentTable;
    },
    canBeRepeated() {
      return !this.parentRecord.id && this.rightRecord && this.rightRecord.table.hasRepeateableSourceField && !this.newRecord
    },
  },
  methods: {
    setChildRecord: async function(id) {
      if (id) {
        this.$emit("childRecordSelected", id, this.parentRecord.table.relationalData.childTable);

      } else if (this.newRecord && !this.tableIsChild(this.rightRecord.table)) {
        const confirmation = await requireConfirmation(
          this.$t('recordModal.confirmNoCreation'),
          this.$t('create'),
          this.$t('cancel'),
          this.$t('delete')
        );

        if (confirmation.isDismissed) return;
        if (confirmation.isConfirmed) this.$emit('confirmRecordCreation', false);
        if (confirmation.isDenied) { // isDenied si delete button
          this.$emit('deleteChildRecord', this.rightRecord.id);
          this.$emit("childRecordSelected", null, null);
        }
      } else {
        this.$emit("childRecordSelected", null, null);
      }
    },
    recordHasDisplayName() {
      return this.rightRecord.primaryEntry && this.rightRecord.primaryEntry.value;
    },
    displayOnlyLeftPanel() {
      this.displayedPanels.right = false;
      this.displayedPanels.left  = true;
    },
    openNewRepeatableRecordModal() {
      EventBus.$emit('openModal', {
        modalName: 'NewRepeatableRecordModal',
        modalProps: {
          sourceRecord: this.rightRecord,
          recordModalId: this.recordModalKey
        }
      });
    }
  },
  watch: {
    'referenceToParentEntryLength': function(newValue, oldValue) {
      if ((newValue === 1 && oldValue === 0) || (newValue === 0 && oldValue === 1)) { // super explicit to prevent error prone fetch of parent record
        this.$emit('parentRecordReferenceUpdated', this.referenceToParentEntry);
      }
    }
  }
}
</script>
