<template lang="pug">
  div
    .form-group
      label {{ $t('dashboardBlocks.labels.list_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockLists"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.list_id"
      )
    .form-group
      label {{ $t('dashboardBlocks.reportextract.labels.calendar_id') }}
      v-select(
        :searching="true"
        :options="dashboardBlockCalendars"
        label="title"
        :reduce="option => option.id"
        v-model="editableSettings.calendar_id"
      )
    .form-group
      label {{ $t('dashboardBlocks.reportextract.labels.monitoring_table_id') }}
      v-select(
        :searching="true"
        label="name"
        :options="tables"
        :reduce="option => option.id"
        v-model="editableSettings.monitoring_table_id"
      )
    .form-group(
      v-for="settingProperty in fieldSettingProperties"
      :key="settingProperty"
    )
      label(:for="settingProperty") {{ $t('dashboardBlocks.reportextract.labels.' + settingProperty) }}
      v-select(
        :searching="true"
        label="name"
        :inputId="settingProperty"
        :options="fieldsForTable"
        :reduce="option => option.id"
        v-model="editableSettings[settingProperty]"
      )
</template>

<script>
import { mapState }   from 'vuex'
import DashboardBlock from '../../../models/dashboardBlock';

export default {
  props: {
    dashboardBlock: {
      type: Object,
      required: true
    },
    editableSettings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      tableIdForConfiguration: null,
      fieldSettingProperties: [
        'site_reference_field_id',
        'card_id_field_id',
        'start_of_period_field_id',
        'first_half_hour_production_field_id',
        'last_half_hour_production_field_id',
        'total_production_field_id',
        'bonus_field_id',
        'comment_field_id',
        'responsability_field_id'
      ],
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    dashboardBlockLists() {
      return DashboardBlock.query().where('kind', 'list').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    dashboardBlockCalendars() {
      return DashboardBlock.query().where('kind', 'calendar').where('dashboard_id', this.dashboardBlock.dashboard_id).get();
    },
    sitesTable() {
      if (this.editableSettings.monitoring_table_id) {
        return this.tables.find(table => table.id === this.editableSettings.monitoring_table_id);
      }
    },
    fieldsForTable() {
      if (this.sitesTable) {
        return this.sitesTable.fields;
      }
      return [];
    }
  },
  watch: {
    'editableSettings.monitoring_table_id': function(_newSettings, _oldSettings) {
      this.fieldSettingPropertiesForSites.forEach((fieldSetting) => {
        this.$set(this.editableSettings, fieldSetting, {});
      });
    }
  }
}
</script>
