import { findIndex,
         debounce,
         uniqBy } from "lodash";
import Vue from "vue/dist/vue.esm";
import Record from '../../models/record';

export const namespaced = true;

export const state = {
  recordsInModals: []
};

export const mutations = {
  ADD_RECORD_IN_MODALS(state, record) {
    state.recordsInModals.push(record);
  },
  REMOVE_RECORD_IN_MODALS(state, record) {
    const index = state.recordsInModals.indexOf(record);
    if (index !== -1) state.recordsInModals.splice(index, 1);
  }
};

export const actions = {
  setVisibleRecords({ rootState }, { visibleRecordIds = null, table = null }) {
    const recordIds = new Set(visibleRecordIds || rootState.viewStore.view.visibleRecordIds);

    (table || rootState.tableStore.table).records.forEach((record) => {
      record.visible = recordIds.delete(record.id);
    });
  },
  addRecord({ commit, dispatch }, record) {
    commit("tableStore/ADD_RECORD", record, { root: true });
    commit('viewStore/CREATE_ROW_ORDER', record, { root: true });
    dispatch("viewStore/updateRowOrder", null, { root: true });
    dispatch("lockedRecordsStore/pushRecordIdForLockCheck", { recordId: record.id }, { root: true });
  },
  fetchForeignRecord({ dispatch, rootGetters }, { tableId, recordData, viewFieldsData }) {
    const table = rootGetters['tableStore/getTableById'](tableId);
    if (!table) return;
    const foreignRecord = Record.build(recordData, table, { creationConfirmed: true });
    if (!table.records.includes(foreignRecord)) table.records.push(foreignRecord);
    dispatch('fieldStore/buildWithViewFields', {
      fields:                  table.fields,
      fieldsData:              viewFieldsData,
      shouldComputeLeftOffset: false
    }, { root: true });
    dispatch('addEntryUpdateChannel', tableId, { root: true });
    return foreignRecord;
  },
  deleteRecord({ rootState }, recordId) {
    const recordIndex = findIndex(rootState.tableStore.table.records, record => record.id === recordId)
    if (recordIndex >= 0) {
      Vue.delete(rootState.tableStore.table.records, recordIndex);
      Vue.delete(rootState.tableStore.table.recordsById, recordId);

    }
    const rowIndex = findIndex(rootState.viewStore.view.rowOrder, ({ id }) => id === recordId);
    if (rowIndex >= 0) {
      Vue.delete(rootState.viewStore.view.rowOrder, rowIndex);
    }
  }
};

export const getters = {
  getRecordById: (_state, getters) => id => {
    return getters.allRecordsAvailable.find(record => record.id === id);
  },
  getEntry: (_state, getters, rootState) => (recordId, fieldId) => {
    const record = getters.allRecordsAvailable.find(record => {
      return record.id === recordId
    });
    return record ? record.entriesByFieldId[fieldId] : null;
  },
  currentRecords: function(_state, _getters, rootState) {
    return rootState.tableStore.table.records || [];
  },
  visibleRecords: function(_state, _getters, rootState) {
    return rootState.viewStore.view.visibleRecordIds.map((id) => {
      return rootState.tableStore.table.recordsById[id];
    });
  },
  allRecordsAvailable: function(state, _getters, rootState) {
    // all the records available in front, with records from RecordModal (including foreign records)
    return uniqBy([...state.recordsInModals, ...rootState.tableStore.tables.flatMap(table => table.records)], 'id');
  }
};
