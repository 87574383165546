<template lang="pug">
  .mt-3
    .form-group
      label {{ $t('automations.labels.job') }}
      input.form-control(
        id="jobName"
        name="jobName"
        v-model="jobName"
      )

    .form-group
      label {{ $t('automations.labels.arguments') }}
      input.form-control(
        id="jobArgs"
        name="jobArgs"
        v-model="jobArgs"
      )

    .record-filters--footer.pt-2.mt-2.border-top.border-light.d-flex.justify-content-end
      button.btn.btn-primary(
        @click.stop="updateAutomationModule"
      ) {{ $t('save') }}
</template>

<script>
import { api } from '../../../../api/client';

export default {
  props: {
    automationModuleId: {
      type: Number,
      required: true,
    },
    configParamsProp: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      jobName: null,
      jobArgs: null,
    }
  },
  created() {
    this.jobName = this.configParamsProp.job_name || '';
    this.jobArgs = this.configParamsProp.job_args || '';
  },
  methods: {
    updateAutomationModule() {
      api.automationModules.update({
        automationModuleId: this.automationModuleId,
        configParams: {
          job_name: this.jobName,
          job_args: this.jobArgs,
        }
      });
    },
  }
}
</script>
