import { mapGetters }       from 'vuex';
import { UpdateEntryMixin } from "./UpdateEntryMixin";

export const pasteEntryMixin = {
  mixins: [UpdateEntryMixin],
  computed: {
    ...mapGetters({
      selectedEntry: 'gridStore/selectedEntry'
    }),
  },
  methods: {
    pasteEntryValueFromClipBoard(event = null) {
      const selectedEntry = this.selectedEntry;

      navigator.clipboard.readText().then(clipText => {
        const newValue = clipText;
        if (!selectedEntry || !newValue) return;
        if (selectedEntry.isWritable && selectedEntry.setValue(newValue)) {
          this.updateEntry(selectedEntry);
        }
      });
      if (event && selectedEntry) event.preventDefault();
    }
  }
};
