<template lang="pug">
pop-action.side-menu-expanded-menu.mr-1(
  btnClass="side-menu-expanded-menu--btn"
  contentClass="p-0"
  :showCloseBtn="false"
  @pop-action:open="$emit('pop-action:open')"
  @pop-action:close="$emit('pop-action:close')"
)
  span(slot="trigger")
    i.far.fa-plus
  template(v-slot:content="{ close }")
    div(slot="content")
      p.mb-0.text-nowrap.side-menu-expanded-menu--action(
        :title="$t('folder')"
        data-toggle="tooltip"
        @click="createFolder(close)"
      )
        i.fas.fa-chevron-right.fa-fw.mr-2
        | {{ $t('folder') }}

      p.mb-0.text-nowrap.side-menu-expanded-menu--action(
        title="Table"
        data-toggle="tooltip"
        @click="createTable(close)"
      )
        i.fas.fa-table.fa-fw.mr-2
        | Table

      p.mb-0.text-nowrap.side-menu-expanded-menu--action(
        title="Dashboard"
        data-toggle="tooltip"
        @click="createDashboard(close)"
      )
        i.fas.fa-analytics.fa-fw.mr-2
        | Dashboard
</template>

<script>
import { api }      from '../../../../api/client';
import TreeItem     from '../../../../models/treeItem';
import Dashboard    from '../../../../models/dashboard';

export default {
  name: 'tree-folder-add-menu',
  props: {
    treeItemId: {
      type:     Number,
      required: false,
      default:  null
    },
  },
  methods: {
    createFolder(closePopAction) {
      closePopAction();
      TreeItem.api().create(this.treeItemId);
    },
    createTable(closePopAction) {
      closePopAction();
      api.projects.
        createTable({ parentFolderId: this.treeItemId }).
        then(response => {
          this.$store.dispatch('tableStore/buildNewTable', { payload: response.data });
        });
    },
    createDashboard(closePopAction) {
      closePopAction();
      Dashboard.api().create(this.treeItemId).
        then(({ data: { dashboard: { id }} }) => {
          this.$store.dispatch('setCurrentResource', { resourceType: 'dashboard', resourceId: id });
        });
    },
  }
}
</script>
