<template lang="pug">
  .ncc-list.mr-5
    template(v-if="settingsAreReady")
      .dashboard-block--header.pb-3
        h2.pr-3 {{ title }}
      .ncc-table
        table.ncc-listing
          thead.ncc-listing--headers
            tr.ncc-listing--row
              th.ncc-listing--cell.ncc-title {{ $t('dashboardBlocks.nccList.site') }}
              th.ncc-listing--cell.ncc-title {{ $t('dashboardBlocks.nccList.ncc') }}
          tbody.ncc-listing--row(
              v-for="item in content"
              :key="item.site"
            )
            tr
              td.ncc-listing--cell {{ item.site }}
              td.ncc-listing--cell {{ item.ncc }} {{ $t('units.megaWatt.symbol') }}

    .flex-center.h-100(v-else)
      div
        h2.text-center.text-warning
          i.fas.fa-exclamation-triangle
        p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}

</template>
<script>
import { api }                 from '../../api/client';
import { map, isEqual }        from 'lodash';
import { mapGetters }          from 'vuex';
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import DashboardBlock          from '../../models/dashboardBlock';
import DashboardSharedItem     from '../../models/dashboardSharedItem';
import i18n                    from "../../locales/locales";

export default {
  mixins: [DashboardBlockMixin],
  data() {
    return {
      title: i18n.t('dashboardBlocks.nccList.certifiedCapacityLevel'),
      content: [],
    }
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    settingsAreReady() {
      return this.dashboardBlock &&
             this.dashboardBlock.kind_settings.list_id;
    },
    yearItem() {
      if (this.settingsAreReady) {
        const calendarForNccList = DashboardBlock.find(this.dashboardBlock.kind_settings.calendar_id).id;
        return DashboardSharedItem.find([calendarForNccList, 0]);
      }
    },
    dashboardBlockList() { // we need the list block to know the table it uses
      return DashboardBlock.find(this.dashboardBlock.kind_settings.list_id);
    },
    tableForNcc() {
      return this.yearItem ? this.getTableById(this.dashboardBlockList.kind_settings.table_id) : null;
    },
    selectedRecordIds() {
      if (this.tableForNcc && this.tableForNcc.slug) {
        const items = DashboardSharedItem.listItems(this.dashboardBlockList.id, true).
                                          where('metadata', value => value.checked).get();
        return map(items, 'item_id');
      }
    },
    currentYear() {
      return this.yearItem ? this.yearItem.metadata.year : null;
    },
  },
  watch: {
    'currentYear': function (_newValue, _oldValue) {
      if (this.settingsAreReady) {
        this.loadNcc();
      }
    },
    'selectedRecordIds': function (newRecordIds, oldRecordIds) {
      if(this.settingsAreReady && !isEqual(newRecordIds?.sort(), oldRecordIds?.sort())) {
        this.loadNcc();
      }
    },
  },
  mounted() {
    if (this.settingsAreReady) {
      this.loadNcc();
    }
  },
  methods: {
    loadNcc() {
      return api.dashboardBlocks.ncc(this.dashboardBlock.id, this.currentYear, this.selectedRecordIds).then((response) => {
        this.content = response.data.content;
      });
    }
  },
}
</script>
