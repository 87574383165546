<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @click.self="closeModal"
)
  .base-modal.entry-references-modal.p-3(:style="sameModalOffsetStyle")
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")

    //- HEADER
    .base-modal-header
      p.mb-0.mt-2
       i.fal.fa-ballot-check.mr-2
       span(
        :class="{ [translationFallbackClasses]: entry.field.nameFallback }"
       ) {{ entry.field.name }}

    //- BODY
    .base-modal-body.pt-3
      template(v-if="entry.value.length")
        .px-2.py-1.rounded.my-2.entry-reference(
          v-for="reference in entry.value"
          :key="reference.foreign_record_id"
          @click="openReferenceModal(reference)"
        ) {{ reference.foreign_record_display_name }}
          .remove-reference-btn(
            v-if="entry.isWritable"
            @click.stop="removeReference(reference, entry)"
          )
            i.fad.fa-times-circle
      .d-flex.align-items-center.justify-content-center.h-100(v-else)
        p.font-italic {{ $t('noRecords') }}

    //- FOOTER
    .base-modal-footer(v-if="canAddReference")
      hr.mt-1
      .open-add-reference.d-flex.align-items-center(
        @click="openAddReferenceModal"
      )
        span.add-reference-btn.mr-2
          i.fa.fa-plus
        span {{ $t('addReferenceRecord') }}
</template>

<script>
import { BaseModalMixin }  from './BaseModalMixin';
import { ReferencesMixin } from '../../mixins/entries/ReferencesMixin';
import { mapState }        from 'vuex';

export default {
  mixins: [BaseModalMixin, ReferencesMixin],
  data() {
    return {
      entry: null
    }
  },
  computed: {
    ...mapState(['translationFallbackClasses'])
  },
  methods: {
    onModalOpen(entry) {
      this.entry = entry;
    },
    onModalClose() {
      this.entry = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.base-modal-body {
  width: 300px;
  max-height: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
}
</style>
