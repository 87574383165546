<template lang="pug">
  .base-modal-wrapper(
    :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  )

    .base-modal(:style="sameModalOffsetStyle")
      span.base-modal-close(@click.stop="closeModal")
        svg.icon
          use(xlink:href="#icon-times-solid")

      //- HEADER
      .base-modal-header
        h5.mb-4.text-center
            i.far.fa-eraser.text-info
            span.text-uppercase.ml-2 {{ $t('deleteRecordOptions.deleteOptions') }}
      //- BODY
      .base-modal-body.mb-2
        .mb-3(v-if="destroyRepetitionsOptionsNeeded")
          h6.font-weight-bold {{ $t('deleteRecordOptions.forRecurringEvent') }}
          .d-flex.align-items-center.ml-3
            input#thisOccurrence(
              v-model="destroyRepetitionsOption"
              type="radio"
              value=""
              name="destroyRepetitionsOption"
              checked="checked"
            )
            label.label.ml-2.mb-0(
              for="thisOccurrence"
            ) {{ $t('deleteRecordOptions.thisEvent') }}

          .d-flex.align-items-center.ml-3(v-if="withFollowingOccurrencesOptionAvailable")
            input#WithFollowingOccurrences(
              v-model="destroyRepetitionsOption"
              type="radio"
              value="with_following_occurrences"
              name="destroyRepetitionsOption"
            )
            label.label.ml-2.mb-0(
              for="WithFollowingOccurrences"
            ) {{ $t('deleteRecordOptions.thisAndTheFollowingEvents') }}

          .d-flex.align-items-center.ml-3
            input#allOccurrences(
              v-model="destroyRepetitionsOption"
              type="radio"
              value="all_occurrences"
              name="destroyRepetitionsOption"
            )
            label.label.ml-2.mb-0(
              for="allOccurrences"
            ) {{ $t('deleteRecordOptions.allEvents') }}

        hr.mt-3.mb-4(v-if="destroyRepetitionsOptionsNeeded && parentRecordOptionsNeeded")

        .mb-3(v-if="parentRecordOptionsNeeded")
          h6.font-weight-bold {{ $t('deleteRecordOptions.forParentRecord') }}
          .d-flex.align-items-center.ml-3
            input#childrenRecordsNotIncluded(
              v-model="includeChildrenRecords"
              type="radio"
              value="false"
              name="includeChildrenRecords"
              checked="checked"
            )
            label.label.ml-2.mb-0(
              for="childrenRecordsNotIncluded"
            ) {{ $t('deleteRecordOptions.WithoutChildrenRecords', { childTableName: childTableName }) }}

          .d-flex.align-items-center.ml-3
            input#childrenRecordsIncluded(
              v-model="includeChildrenRecords"
              type="radio"
              value="true"
              name="includeChildrenRecords"
            )
            label.label.ml-2.mb-0(
              for="childrenRecordsIncluded"
            ) {{ $t('deleteRecordOptions.WithChildrenRecords', { childTableName: childTableName }) }}

      //- FOOTER
      .base-modal-footer.mt-4.d-flex.justify-content-between
        button.btn.btn-cancel(@click.stop="closeModal")
          span {{ $t('cancel') }}

        button.btn.default-btn(@click="deleteRecordWithOptions") Ok
</template>

<script>
  import { BaseModalMixin } from "./BaseModalMixin";

  export default {
    mixins: [BaseModalMixin],
    data() {
      return {
        record: null,
        callback: () => {},
        parentRecordOptionsNeeded: false,
        destroyRepetitionsOptionsNeeded: false,
        withFollowingOccurrencesOptionAvailable: false,
        destroyRepetitionsOption: '',
        includeChildrenRecords: false,
      }
    },
    methods: {
      onModalOpen({ record, callback, parentRecordOptionsNeeded, destroyRepetitionsOptionsNeeded, withFollowingOccurrencesOptionAvailable, childTableName }) {
        this.record = record;
        this.callback = callback;
        this.parentRecordOptionsNeeded = parentRecordOptionsNeeded;
        this.destroyRepetitionsOptionsNeeded = destroyRepetitionsOptionsNeeded;
        this.withFollowingOccurrencesOptionAvailable = withFollowingOccurrencesOptionAvailable;
        this.childTableName = childTableName;
      },
      onModalClose() {
        this.record = null;
      },
      deleteRecordWithOptions() {
        this.record.deleteAndRemoveFromStore(this.callback, this.destroyRepetitionsOption, this.includeChildrenRecords);
        this.closeModal();
      }
    }
  };
</script>

<style lang="scss" scoped and-dirty>
  .base-modal {
    min-width: 350px;
    max-width: 500px;
  }

  .label {
    font-size: 1rem;
  }
</style>
