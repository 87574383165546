<template lang="pug">
  v-select.cell-content(
    appendToBody
    :searching="true"
    :options="entry.field.typeOptions.select_list"
    v-model="entry.value"
    @input="updateEntry(entry)"
    inputId="selectCellInput"
  )
    template(#no-options="{ search, searching, loading }") {{ $t('noOptionForSelect') }}
</template>

<script>
import { UpdateEntryMixin } from "../../../mixins/UpdateEntryMixin";
import { selectInputMixin } from "../../../mixins/selectInputMixin";

export default {
  mixins: [UpdateEntryMixin, selectInputMixin],
  props: {
    entry: {
      type: Object,
      required: true
    }
  }
};
</script>
