<template lang="pug">
pop-action.side-menu-expanded-menu.mr-1(
  btnClass="side-menu-expanded-menu--btn"
  contentClass="p-0"
  :showCloseBtn="false"
  @pop-action:open="$emit('pop-action:open')"
  @pop-action:close="$emit('pop-action:close')"
)
  span(slot="trigger")
    i.far.fa-ellipsis-h
  template(v-slot:content="{ close }")
    div(slot="content")
      p.mb-0.text-nowrap.side-menu-expanded-menu--action(
        :class="{ 'btn-disabled': disableRename }"
        :disabled="disableRename"
        @click="renameItem(close)"
      )
        i.fas.fa-pen.fa-fw.mr-2
        | {{ $t('rename') }}

      p.mb-0.text-nowrap.side-menu-expanded-menu--action.text-danger(
        v-if="feature.canCreateResource"
        :class="{ 'btn-disabled': disableDelete }"
        :disabled="disableDelete"
        @click="deleteItem(close)"
      )
        i.fad.fa-trash-alt.fa-fw.mr-2
        | {{ $t('delete') }}

</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'base-tree-item-options-menu',
  props: {
    treeItem: {
      type: Object,
      required: true
    },
    disableRename: {
      type: Boolean,
      required: false,
      default: false
    },
    disableDelete: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState({
      feature: state => state.feature,
    }),
  },
  methods: {
    renameItem(closePopAction) {
      if (this.disableRename) return;

      closePopAction();
      this.$emit('rename-item');
    },
    deleteItem(closePopAction) {
      if (this.disableDelete) return;

      closePopAction();
      this.$emit('delete-item');
    }
  }
}
</script>

<style scoped>
.btn-disabled {
  color: #718096 !important;
  cursor: not-allowed !important;
  filter: grayscale(0.3) !important;
}
</style>>
