import { units } from "./units";

export const en = {
  units,
  true: "Yes",
  false: "No",
  yes: "Yes",
  no: "No",
  create: "Create",
  apply: "Apply",
  continue: "Continue",
  update: "Update",
  cancel: "Cancel",
  back: "back",
  close: "Close",
  save: "Save",
  saved: "Saved",
  delete: "Delete",
  confirm: "Confirm",
  deny: "Deny",
  add: "Add",
  days: "days",
  and: "and",
  or: "or",
  is: "is",
  at: "at",
  rename: "Rename",
  tables: "Tables",
  table: "Table",
  view: "View",
  field: "Field",
  dashboards: "Dashboards",
  folder: "Folder",
  home: "Home",
  workspace: "Workspace",
  newTable: "New table",
  seeMoreDetails: "See more",
  no_alerts: "No alerts",
  history: "History",
  selectAll: "Select all",
  selectNothing: "Unselect all",
  selectRecord: "Select record",
  noOptionForSelect: "No results",
  recordWillBeFiltered: "This record will be no longer visible based on this view's filters.",
  send: "Send",
  submit: "Submit",
  preview: "Preview",
  addNewField: "Add new field",
  addReferenceRecord: "Add reference record",
  addExistingReferenceRecord: "Add existing reference record",
  createNewRecord: "Create new record",
  searchReference: "Search reference record",
  searchField: "Search field",
  noRecordsFound: "No users found",
  noRecords: "No records",
  current_user: "Current user",
  addUserRecord: "Add user",
  searchUser: "Search user",
  noUsersFound: "No users found",
  hiddenFields: "hidden fields",
  lockedRecordInfo: "This record is locked and cannot be delete or edit.",
  updateForbidden: "This update is forbidden because it does not respect profile access rights.",
  forbidden: "You are not allowed to do this action.",
  mainErrorMsg: "Sorry, there is an issue...",
  tryToReload: "Try to reload the page",
  default: "Default",
  SUM: "Sum",
  AVG: "Average",
  MEDIAN: "Median",
  days: "Days",
  weeks: "Weeks",
  month: "month",
  times: "times",
  loading: "Loading",
  preview: "Preview",
  addAttribute: "Add attribute",
  occurrences: {
    every_day: "Every day",
    every_week: "Every week",
    every_two_week: "Every two weeks",
    every_month: "Every month",
    custom: "Custom",
    every: "Every",
  },
  repeatable_records: {
    occurrence: "occurrence",
    repeat_record: "Repeat record",
    repeat_this_record: "Repeat this record",
    use_the_field: "Use the field",
    for_repeat: "for repeat",
    of_children_records: "of",
    include_children_records: "Include",
    no_value_for_repetition: "Record can't be repeated because date field is not filled.",
    confirmation: "By confirming, you will create {occurrences} record{plural} for the record: <span class=\"text-info\">{recordDisplayName}</span>, with this frequency: <i>{frequency}</i>, are you sure?",
    children_records_confirmation: "</br></br>You will also create {numberOfChildrenRecords} record{plural} on the table <strong>{childTableName}</strong>."
  },
  deleteRecordOptions: {
    deleteOptions: "Delete options",
    forRecurringEvent: "For recurring event",
    thisEvent: "This event",
    thisAndTheFollowingEvents: "This and the following events",
    allEvents: "All events",
    forParentRecord: "For parent record",
    WithoutChildrenRecords: "Without children records of {childTableName}",
    WithChildrenRecords: "With children records of {childTableName}",
  },
  fields: {
    readAccess: "Read only access field",
    writeAccess: "Write access field",
    primaryTooltip: "The primary field is used as display name for references.",
    noForeignTableId: "Impossible to search for records.<br/>Verify field settings.",
    system: "System field",
    useToRepeatRecords: "Use to repeat records?",
    currentTemplate: "current template",
    newTemplate: "new template",
    none: "none",
  },
  routes: {
    views: "views"
  },
  models: {
    record: {
      noDisplayName: "No display name",
    }
  },
  saving: "Saving",
  controlBarActions: {
    getZipFile: "Download attachments",
    filtersApplied: "Filters applied",
    filter: "Filter",
    noFilter: "No filters applied",
    noAttribute: "No attribute",
    addFilter: "Add filter",
    restoreFilters: "Restore filters",
    restoreSorts: "Restore sort",
    filterBy: "Filter by",
    filteredBy: "Filtered by",
    tablePrefiltered: "This table is prefiltered (click to display)",
    updateViewFilters: "Save view",
    newRecordCreated: "New record created",
    addRecord: "New record",
    sort: "Sort",
    noSorting: "Not sorts applied yet",
    createView: "Duplicate",
    showHide: "Show/Hide",
    download: "Download",
    editViewDescription: "Edit view description",
    editViewOptions: "Edit view options",
    newView: "Add new view",
    calendar: "Calendar",
    gallery: "Gallery",
    grid: "Grid",
    board: "Kanban",
    form: "Form",
    searchView: "Search view",
    downloadToCalendarFormat: "Download to Calendar Format",
    importData: "Import data",
    applyEverywhereThisViewFieldsDisplay: "Apply everywhere this field display",
    dissociateView: "Dissociate view",
    deleteTable: "Delete table",
    cantDeleteLastView: "Impossible to dissociate last table view.",
    cantDeleteLastTable: "Impossible to delete last table.",
    lockView: "Unlocked view. Click to lock view.",
    unlockView: "Locked view. Click to unlock view.",
    lockedView: "Locked view",
    editRecordValidations: "Edit record validations",
    saved: "Saved!",
  },
  calendarNav: {
    today: "Today",
    month: "Month",
    week: "Week",
    selectedCalendars: "Selected calendars",
  },
  recordModal: {
    alreadyOpened: "This record is already opened.",
    noParentRecord: "No parent record from the field: {fieldName}.",
    associateParentRecord: "Associate parent record",
    confirmNoCreation: "This record is not registered on the table.</br><strong>Do you want to create this record?</strong></br><i>This record will be lost if you do not create it.</i>",
  },
  calendarModal: {
    newCalendarView: "New calendar view",
    editCalendarView: "Edit calendar view",
    startAtField: "Start at field",
    endAtField: "End at field",
    title: "Event title field",
    reference: "Reference field",
    details: "Details field",
    endDateOption: "End date option",
    range: "Range",
    end_date_field: "End date field",
    location: "Location field",
    organizer_email: "Organizer email field",
    organizer_name: "Organizer name field",
    attendees: "Attendees field",
  },
  dashboard: {
    addBlock: "Add block",
    editMode: "Edit mode",
    endEditMode: "End edit mode",
    blockSettings: "Block settings",
    blockName: "Block name",
    newBlock: "New block",
    confirmDeleteBlock: "Confirm deletion of the block?",
    details: "Details",
    display_period_selector: "Display period selector",
  },
  dashboardBlockKinds: {
    alerts: "Alerts",
    calendar: "Calendar",
    chart: "Standard chart",
    flex_contacts: "FLEX contacts",
    kpi: "KPI",
    list: "List",
    live: "Live",
    load_shift: "Load shifts",
    map: "Map",
    ncclist: "NCC",
    peakperiod: "PP2 days",
    regionalmap: "Regional map",
    reportextract: "Report",
    schedule: "Schedule",
    sourced_chart: "Chart with sources",
    sourced_kpi: "KPI with sources",
    standalonekpi: "Standalone KPI",
    stat: "Statistics",
    table: "Table",
    text: "Text",
    timescale: "Timescale",
  },
  dashboardBlocks: {
    noInfo: "No available info",
    backToList: "Back to list",
    blockIsNotConfigured: "This block needs to be configured.",
    apiError: "Data temporarily unavailable, please try again later.",
    noSitesSelected: "No sites selected",
    alerts: {
      level: "Level",
      more: "more",
      negative_price: "Negative price alert in progress",
      noAlerts: "No alerts"
    },
    kpi: {
      labels: {
        period: "Period",
        api_source_id_field_id: "API source id field",
        kind_technology_field_id: "Type of technology field",
        kind: "Data type",
        data_contracts_table_id: "Contract data table field",
        engagement_value_field_id: "Power engagement value field",
        engagement_kind_field_id: "Engagement type field",
        wiped_power_field_id: "Wiped power field",
        trigger_power_field_id: "Trigger power field",
        performance_table_id: "ENR contracts table",
        performance_value_field_id: "Reference performance field",
        start_date_field_id: "Contract start date field",
        end_date_field_id: "Contract end date field",
        solar_installed_capacity_field_id: "Solar installed capacity value field",
        other_installed_capacity_field_id: "Other technologies installed capacity value field",
        referenced_site_field_id: "Referenced sites field"
      },
      dataTypes: {
        production: "Production",
        contract: "Contract data",
        enr_performance: "ENR performance"
      }
    },
    nccList: {
      site: "Site",
      ncc: "CCL",
      certifiedCapacityLevel: "Certified Capacity Level",
    },
    labels: {
      additional_params: "Additional params",
      advanced_params: "Advanced params",
      api_id: "API id field",
      api_source_id_field_id: "API source id field",
      autoResize: "Auto resize.",
      calendar_id: "Calendar block for elements to display",
      color_field_id: "Color field",
      concerned_months: "Months of operation (All if blank)",
      concerned_table_ids: "Tables to include",
      default_description_field_id: "Default description",
      default_title_field_id: "Default title",
      description_field_id: "Description field",
      disabled_on_holidays: "Disabled on holidays",
      enabled_only_on_weekdays: "Enabled only on weekdays",
      end_date_field_id: "End date field",
      icon_field_id: "Icon field",
      label_field_id: "Label field",
      level_color: "Level color {level}",
      level_name: "Level name {level}",
      level_view_ids: "Views of level {level}",
      list_alert_default_level: "List alert default level",
      list_id: "List block for elements to display",
      locked_title: "Locked title?",
      reference_field_id: "Reference field",
      schedule_id: "Schedule block for elements to display",
      select_source_to_configure: "Select source to configure",
      select_table_to_configure: "Select table to configure",
      separated_by_commas: "separated by commas",
      start_date_field_id: "Start date field",
      table_alert_default_level: "Table alert default level",
      table_id: "Table",
      title_field_id: "Title field",
      type: "Vertical Power field",
      view_id: "View",
      styleTypes: {
        relative: "Relative",
      }
    },
    list: {
      displayAllDetails: "Details",
      displayAllMaintenances: "maintenances",
      yes: "yes",
      no: "no",
      inProgress: "In progress",
      labels: {
        table_id: "Table",
        view_id: "View",
        title_field_id: "Title field",
        description_field_id: "Description field",
        technology_field_id: "Technology field",
        icon_field_id: "Icon field",
        image_field_id: "Image field",
        color_field_id: "Color field",
        list_additional_field_ids: "List additional field",
        details_additional_field_ids: "Details additional fields",
        disable_sorts_and_filters: "Disable sorts and filters",
        disable_item_select: "Disable item select",
        disable_details: "Disable details",
        associations_table_id: "Associations table",
        principal_table_id: "Main table",
        associations_view_id: "Associations view",
        associations_reference_field_id: "Associations reference field",
        associations_reference_field_id_site: "Production site associations reference field",
        associations_reference_field_id_perf: "Performance associations reference field",
        associations_reference_field_id_date: "Date associations reference field",
        main_reference_field_id_start_date: "Main reference negative price start date field",
        filtered_field_ids: "Fields which can be filtered",
        parent_list: "Parent list block for prefilters",
        parent_list_reference_field_id: "Parent list reference filters field",
        api_source_id_field_id: "API source id field",
        add_margin: "Add right margin",
        year_field_id: "Year field",
        ncc_field_id: "NCC value field",
        site_name_field_id: "Site name field",
      }
    },
    reportextract: {
      kinds: {
        performances: "Performance report",
        negative_price_monitoring: "Negative price monitoring",
        flex_activity: "FLEX activities and unavailabilities",
      },
      labels: {
        report_kind: "Report type",
        sites_table_id: "Sites table",
        contracts_table_id: "Contracts table",
        collects_table_id: "Collect sheets table",
        site_name_field_id: "Site name field",
        site_file_name_field_id: "Site file name field",
        site_source_id_field_id: "OPINUM id field",
        site_card_id_field_id: "CARD ID site field",
        site_technology_field_id: "Technology field",
        contract_site_reference_field_id: "Reference site field",
        contract_target_field_id: "Target performance (€/MWh) field",
        contract_start_date_field_id: "Start date contract field",
        contract_end_date_field_id: "End date contract field",
        monitoring_table_id: "Monitoring table",
        site_reference_field_id: "Site reference field",
        card_id_field_id: "CARD ID field",
        start_of_period_field_id: "Start of negative price period field",
        first_half_hour_production_field_id: "First half hour production field",
        last_half_hour_production_field_id: "Last half hour proudction field",
        total_production_field_id: "Total production field",
        bonus_field_id: "Bonus field",
        comment_field_id: "Comment field",
        responsability_field_id: "Responsability field",
        calendar_id: "Calendar block for the year",
        first_day_of_week_field_id: "First day of week field",
      },
    },
    live: {
      since: "Since",
      noCurrentEvents: "No current events",
      labels: {
        maxNumberOfLines: "Max number of lines (Auto size)"
      }
    },
    calendar: {
      availabilities: "Availabilities",
      unavailabilities: "Unavailabilities",
      activations: "Activations",
      negative: "Negative price",
      negatives: "Production on negative price",
      notifiedOn: "notified on",
      downloadReport: "Download report",
      labels: {
        calendar_type: "Calendar type",
        peak_periods_table_id: "PP1/PP2 alerts table",
        peak_periods_one_field_id: "PP1 field",
        peak_periods_two_field_id: "PP2 field",
        peak_periods_period_field_id: "Date field",
        activations_table_id: "Activations table",
        activations_site_field_id: "Site field",
        activations_period_field_id: "Activations period field",
        activations_file_field_id: "Activations file field",
        activations_notification_date_field_id: "Activations notification datetime field",
        unavailabilities_table_id: "Unavailabilities table",
        unavailabilities_table_site_field_id: "Unavailabilities table site field",
        unavailabilities_period_field_id: "Unavailabilities period field"
      },
      legend: {
        pp2: "PP2",
        pp1pp2: "PP1/PP2",
        dayWithActivations: "Day with activations",
        sitesAvailability: "Sites availability",
        dayWithNegativePrice: "Day with negative price",
        sitesWithoutProductionRatio: "Sites without production ratio",
      }
    },
    map: {
      default_coordinates: [2.213749, 46.227638],
      labels: {
        longitude_field_id: "Longitude field",
        latitude_field_id: "Latitude field",
        title_field_id: "Title field",
        detail_field_id: "Detail field",
        alert_id: "\"Alerts\" block for elements to display",
      },
      errors: {
        listIsNotConfigured: "List block is not properly configured"
      }
    },
    timescale: {
      download: "Download",
      labels: {
        period: "Select period",
        year: "Select a delivery year",
        color: "Chart curve color field",
        chart_kind: "Chart type",
        datatype: "Data type",
        select_year: "Select year",
        aggregating_field_id: "Aggregating data field",
        load_shift_date_field_id: "Load shift date field",
        y_axis_field_id: "Y axis aggregating data field",
        keep_empty_cell: "Keep empty cells count",
        calculation_type: "Calculation type",
        computation_mode: {
          difference: "MWh",
          ratio: "%",
          aggregated: "Aggregated",
          assets: "By assets",
          technologies: "By technologies",
          customers: "By customers",
        },
        aggregated: {
          true: "Aggregated chart",
          false: "Chart by site",
          entities: {
            true: "Aggregated chart",
            false: "Chart by entity",
          },
        },
        contract_start_date_field_id: "Contract start date field",
        contract_end_date_field_id: "Contract end date field",
        unique_element: "Selected record must be uniq?"
      }
    },
    peakperiod: {
      header: "PP1/PP2 days & forecast",
      labels: {
        table_id: "PP1/PP2 alerts table",
        view_id: "View",
        date_field_id: "Date field",
        pp1_value_field_id: "PP1 value field",
        pp2_value_field_id: "PP2 value field",
        estimated_value_field_id: "PP1/PP2 risk field",
      },
      today: "Today",
      tomorrow: "Tomorrow",
      confirmed: "PP1/PP2 report",
      prevision: "Risk reported day",
      source: {
        confirmed: "Source: RTE",
        prevision: "PP1/PP2 days occurrence risk %, calculated by TEFP"
      },
      ecowatt: "Ecowatt",
      ecowatt_not_declared: "Not declared",
      ecowatt_no_alert: "No alert",
      ecowatt_level1: "Tense day",
      ecowatt_level2: "Critical day"
    },
    capacityAuction: {
      labels: {
        table_id: "Table",
        view_id: "View",
        year_field_id: "Delivery year field",
        date_field_id: "Date field",
        capacity_auction_value_field_id: "Capacity auction value field",
      },
    },
    load_shift: {
      objective: "Objective",
      average_consumption: "Average consumption",
      average_load_shift: "Average load shift",
      consumed: "Consumed",
      erased: "Erased",
      reference_power: "Reference power"
    },
    regionalMap: {
      header: "Regional reporting in MWh",
      Biomass: "Biomass",
      Hydro: "Hydro",
      Wind: "Wind",
      Solar: "Solar",
      CogenGas: "Cogen Gas",
      CogenWaste: "Cogen Waste",
    },
    flex_contacts: {
      eda: "EDA",
      entity: "Entity",
      site: "Site",
      CALL: "Calls",
      SMS: "SMS",
      MAIL: "Emails",
      noAssociatedSite: "You are not attached to any site.",
      downloadContacts: "Download all contacts",
      invalidFormat: "Invalid format",
      labels: {
        flex_contacts_table_id: "Table for FLEX contacts Excel export",
        download_view_id: "View for Excel export"
      }
    },
    sourcedChart: {
      displayAsLine: "Display as line",
      forceAggregation: "Force aggregation",
      realTime: "Real time",
      labels: {
        sources: "Source selection",
        title: "Title field",
        api_id: "Source ID field",
        type: "Vertical Power type field",
        technology: "Technology field",
        customer: "Customer field",
        program_to_display: "Program to display"
      },
      sources: {
        "auto_grid/real_time_active_power": "Real time - Active power",
        "vertical_power/available_power": "Available power",
        "vertical_power/day_ahead_commitment": "Day Ahead commitment",
        "vertical_power/client_program": "Client program",
        "vertical_power/day_ahead_market_price": "Day Ahead Market Prices",
        "vertical_power/estimated_revenues": "Estimated revenues",
        "vertical_power/forecast": "Forecast",
        "vertical_power/imbalance": "Imbalance",
        "vertical_power/intraday_commitment": "Intraday commitment",
        "vertical_power/intraday_market_price": "Intraday market prices",
        "vertical_power/measured_production": "Measured production",
        "vertical_power/monthly_imbalance_and_revenues_kpi": "KPI only - Monthly imbalance and revenues",
        "vertical_power/output_generation": "Output generation",
        "vertical_power/power_factor": "Power factor",
        "vertical_power/reactive_power": "Reactive power",
        "vertical_power/active_power": "Active power",
        "vertical_power/ree_limitations": "Limitations"
      }
    },
    text: {
      labels: {
        text: "Text",
      },
    },
  },
  galleryModal: {
    newGalleryView: "New gallery view"
  },
  gridModal: {
    newGridView: "New grid view"
  },
  formModal: {
    newFormView: "New form view"
  },
  gallery: {
    focus: "Expand"
  },
  views: {
    selectSort: "Select a field to sort by",
    entrySelectDefault: "select an option",
    sortBy: "Sort by",
    thenBy: "then by",
    createViewPlaceholder: "View name",
    profileView: "Profile view",
    alert: "Alert",
    showAll: "All",
    hideAll: "None",
    records: "Record | Records",
    addRecord: "New record",
    editRecord: "Edit record",
    noFieldAccessible: "You can't access this view with your current profile.",
    scope: "Visibility",
    profileViewCreationNotAllowed: "You can only create personal views",
    createView: "Create view",
    updateView: "Update view",
    foreignRecordFrom: "Foreign record from table {tableName}",
    files: "files",
    addFiles: "Add files",
    users: "Users",
  },
  fileManager: {
    fileManager: "File Manager",
    dropzoneDragAndDrop: "Drag and drop to upload your files!",
    dropzoneClickToSelectAFileFromYourComputer: "...or click to select a file from your computer",
    myFiles: "My files",
    newFilesToUpload: "New files to upload",
    upload: "Upload",
    processing: "Processing...",
    operationCanTakeAFewSeconds: "Operation can take a few seconds...",
    operationCanTakeAFewMinutes: "Operation can take a few minutes...",
    fileVerified: "File verified by antivirus",
    fileNotYetVerified: "File not yet verified by antivirus",
  },
  entries: {
    select: {
      noReferences: "No references",
      noUsers: "No users",
      noOptions: "No options",
    }
  },
  actionsColumn: {
    actions: "Actions",
    editColumn: "Edit field",
    deleteColumn: "Delete field",
    nameColumn: "Field name",
    typeOfData: "Type of data",
    systemField: "System field",
    lockedField: "Locked field",
    description: "Description",
    confirmEdit: "You have changed the data type of this field. <br> The associated data can be deleted or compromised following this operation. <br> <span style ='color: # E64C43;'> This is not reversible </span>. <br> <br> Are you sure? ",
    confirmDelete: "You will delete this field.<br><span style='color: #E64C43;'>This is not reversible</span>.<br><br>Are you sure?",
  },
  actionsRow: {
    confirmDelete: "You will delete this record.<br><span style='color: #E64C43;'>This is not reversible</span>.<br><br>Are you sure?",
  },
  fieldType: {
    number: "Number",
    string: "String",
    date: "Date",
    date_time_range: "Date/time range",
    boolean: "Yes/No",
    select: "List of options",
    files: "Files",
    references: "References",
    users: "Users",
    lookups: "Lookups",
    formula: "Formula",
    created_at: "Creation date",
    last_modified_at: "Modification date",
    created_by: "Created by",
    last_modified_by: "Modified by",
  },
  editOptions: {
    date: "Manage date format",
    select: "Edit options",
    formula: "Manage the parameters of the formula field",
    newOption: "New option",
    selectReferencesField: "Select the References field",
    selectLookupsField: "Select the Lookups field",
    antivirusScan: "Scan files by antivirus",
    acceptedFileFormats: "Accepted file formats",
    number: "Select unit",
    formula_data_type: "Formula data type",
    selectTable: "Select table to reference",
    oneEntry: "Limit to one entry",
    selectVue: "Limit selection to a specific view",
    noTable: "No table",
    noView: "No view",
    isFiltered: "Respecting some conditions",
  },
  projectFileAcceptedFormats: {
    all: "All formats",
    excel: "Microsoft Excel (.xls, .xlsx)"
  },
  confirmations: {
    applyEverywhereFieldsDisplay: "Are you sure you want to apply this field layout for all profile and personal table views?",
    deleteView: "Are you sure you want to unlink this view? If this view is not linked to any other profile, it will be deleted.",
    deleteTable: "Are you sure you want to delete this table?",
    deleteDashboard: "Are you sure you want to delete this dashboard?",
    deleteFolder: "Are you sure you want to delete this folder?",
    deletePage: "Are you sure you want to delete this page?"
  },
  rightClick: {
    copy: "Copy",
    cut: "Cut",
    paste: "Paste",
    duplicate: "Duplicate record",
    edit: "Edit record",
    deleteRecord: "Delete record",
    addField: "Add field",
    deleteField: "Delete field",
  },
  viewForm: {
    whatDoYouWantToDo: "What do you want to do?",
    createRecord: "Create record",
    editRecord: "Edit record",
    formSubmitted: "The form was sent successfully",
  },
  time: {
    custom: "Custom",
    currentWeek: "Current week",
    date_range: "Date range",
    lastFourWeeks: "Last 4 weeks",
    lastSevenDays: "Last 7 days",
    lastThreeMonths: "Last 3 months",
    lastTwelveMonths: "Last 12 months",
    month: "Month",
    monthly: "Monthly",
    monthToDate: "Since beginning of the month",
    week: "Week",
    year: "Year",
    yearToDate: "Since beginning of the year",
    fromLastWeekToNextWeek: "From last week to next week",
  },
  filterOperators: {
    contains: "contains",
    doesNotContain: "does not contain",
    containsToday: "contains today",
    containsNow: "contains now",
    isEmpty: "is empty",
    isNotEmpty: "is not empty",
    isEqualTo: "is equal to",
    isNotEqualTo: "is not equal to",
    is: "is",
    isNot: "is not",
    isOn: "is on",
    isNotOn: "is not on",
    fromExcluded: "from (excluded)",
    fromIncluded: "from (included)",
    untilExcluded: "until (excluded)",
    untilIncluded: "until (included)",
    isToday: "is today",
    overlaps: "overlaps",
    doesNotoverlap: "does not overlap",
    isPast: "is past",
    isNotPast: "is not past",
    isInLessThan: "is in less than",
    isInMoreThan: "is in more than",
    hasBeenLessThan: "has been less than",
    hasBeenMoreThan: "has been more than",
    reference: "reference",
    doesNotReference: "does not reference",
  },
  charts: {
    bar: "Bar",
    bar_double_axis: "Bar double axes",
    line: "Line",
  },
  dataTypes: {
    enr_production: "The electricity production of",
    enr_production_stacked: "The cumulative electricity production per site of",
    enr_perf_production: "The actual electricity production and performance of",
    enr_forecast_production: "The actual and forecasted electricity production of",
    flex_production: "The electricity consumption of",
    epex_m0: "The EPEX and MO prices of",
    epex: "The EPEX price of",
    capacity_auction: "Delivery year capacity auctions",
    aoe: "Price of the call for tender for load shift",
    perf_target_actual: "Performances realized vs targets of",
    enr_forecast_and_best_production: "Actual and forecasted production",
  },
  calendarTypes: {
    calendar_with_all_events: "PP1 and PP2 calendar",
    calendar_without_pp1: "Without PP1 calendar",
    calendar_negative_price: "Negative price calendar",
  },
  notifications: {
    showAllAlerts: "Show all alerts",
    showMore: "Show more",
    noAlerts: "No alerts",
    noNotifications: "No notifications",
  },
  automations: {
    labels: {
      activity_times_field_ids: "Activity hours fields",
      andTriggerRecord: "AND the trigger record",
      arguments: "Arguments",
      attachments: "Attachments",
      attributes: "Attributes",
      availableFields: "Available fields",
      body: "Body",
      clickToInsert: "(click to insert)",
      collection_sheets_settings: "Collection sheets settings",
      collection_sheets_table_id: "Collection sheets table",
      concerned_field_ids: "Concerned fields",
      concerned_period: "Concerned period",
      concernedFields: "Concerned fields",
      conditions: "Conditions",
      content: "Content",
      custom_dates: "Custom dates",
      days_in_order_hint: "(in order, from Monday to Sunday)",
      daysOfWeek: "Days of the week",
      default_period_field_id: "Default period field",
      email_content: "Email content",
      end_date: "End date",
      fields: "Fields",
      file_name_field_id: "File name field",
      filename_field_id: "Filename field",
      filters: "Filters",
      findRecordsBasedOn: "Find records based on",
      first_day_of_week_field_id: "First day of the week field",
      fromModuleOutput: "From module output",
      host: "Remote server",
      hour: "Hour",
      identifier: "Identifier",
      intervalTypes: "Interval types",
      job: "Job",
      module: "Module",
      module_number: "Module number",
      next_week: "Next week",
      no_source: "No source",
      options: "Options",
      periods_field_ids: "Periods fields",
      port: "Port",
      profiles: "Profiles",
      recipients: "Recipients",
      recipientsField: "Recipients field",
      reference_to_site_field_id: "Reference to site field",
      remotePath: "Remote path",
      source: "Source",
      source_id_field_id: "source ID field",
      start_date: "Start date",
      subject: "Subject",
      synchronization: "Synchronization",
      synchronized: "Synchronized?",
      table: "Table",
      unavailabilities_settings: "Unavailabilities settings",
      unavailability_days_field_ids: "Unavailability days fields",
      unavailability_table_id: "Unavailability table",
      unavailability_table: "Unavailability table",
      user: "User",
      users: "Users",
      view: "View",
    }
  },
  importDataModal: {
    importFile: "Import file",
    dzMessage: "Click or drag the file",
    loadingMessage: "<strong>Step 1/2:</strong><br>Loading current file.<br>Please wait...",
    dataImportLoadingMessage: "<strong>Step 2/2:</strong><br>Data import in progress.<br>Please wait...",
    dataImportSuccessMessage: "<strong>Data imported successfully.</strong>",
    dataImportErrorMessage: "<strong>Error while importing data.</strong>",
    importFailed: "Import failed ",
    atLineNumber: "at <strong>line {line_number}</strong><br>",
    becauseFollowingError: "because of following validation error:",
    importFailedWarning: "Please note that previous lines were successfuly imported.",
    CloseModalConfirmation: "Your import form is not empty.<br><br>Are you sure you want to close it?",
    downloadTemplate: "Download template",
  },
  editViewDescriptionModal: {
    editViewDescription: "Edit view description",
    viewDescriptionPlaceholder: "Describe view",
  },
  editViewOptionsModal: {
    editViewOptions: "Edit view options",
    chooseRedirectionView: "Choose redirection view",
    editRedirectionView: "Edit redirection view",
  },
  daysOfWeek: {
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
  },
  months: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  },
  relative_years: {
    last_year: "Last year",
    current_year: "Current year",
    next_year: "Next year",
  },
  validations: {
    title: "Validations on records",
    add_validation_on: "Add a field to validate",
    must_be: "must be",
    mustBePresent: "must be present",
    mustBeUnique: "must be unique",
    must_be_a: "must be a",
    add_comparison: "Add comparison",
    add_format: "Add format",
    empty: "No validation on records of this table",
    presence: {
      must_be_present: "The {fieldName} field must be present."
    },
    comparison: {
      lt: "lower than",
      gt: "greater than",
      gteq: "greater than or equal to",
      lteq: "lower than or equal to",
      eq: "equal to",
      not_eq: "not equal to",
    },
    format: {
      tel: "phone number (french)",
      email: "email",
    },
    otherAttributeType: {
      field: "the field",
      relative: "relative to",
    },
    relativeAttributeValue: {
      today: "today",
    },
  },
  pages: {
    new: {
      title: "Create a page",
    },
    edit: {
      title: "Edit page",
    },
    form: {
      name: "Page name",
      type_name: "Page type",
    },
    types: {
      enr_unavailability: "RE unavailability management",
      flex_annual_delivery: "FLEX annual delivery"
    }
  }
};
