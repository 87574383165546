<template lang="pug">
.d-flex
  slot
  date-picker(
    v-if="shouldDisplayDateInput"
    :lang="this.$i18n.locale"
    v-model="tmpDate"
    :type="dateInputType"
    value-type="date"
    :format="dateFormat"
    :minute-step="5"
    :show-second="false"
    :append-to-body="false"
    :range="shouldUseRangeDatepicker"
    :placeholder="placeholder"
    :confirm="true"
    @change="handleChange"
  )
  template(v-else-if="shouldDisplayDaysInput")
    input.record-filters--input(
      v-model.number="filter.value"
      type="number"
      @keypress="formatToNumber"
    )
    span.mx-2 {{ $t('days') }}
</template>

<script>
import moment from 'moment';
import { RecordFilterMixin } from '../../mixins/RecordFilterMixin';
import { mapGetters } from 'vuex';

export default {
  mixins: [RecordFilterMixin],
  data() {
    return {
      tmpDate: null
    }
  },
  computed: {
    ...mapGetters({
      getFieldById: 'fieldStore/getAllFieldById'
    }),
    field() {
      if (this.filter.field === 'lookups') {
        const fieldId = this.filter.field.typeOptions.lookups_field_id;
        return getFieldById(fieldId);
      }
      return this.filter.field; // so DatepickerMixin works with both RecordFilter and EntryCell/Input components
    },
    shouldUseRangeDatepicker() {
      return this.field.dataType === 'date_time_range' &&
        ['=', '!=', 'overlaps', 'does not overlap'].includes(this.filter.operator);
    },
    shouldDisplayInput() {
      return !['empty', 'not empty', 'today', 'is past', 'is not past', 'contains today', 'contains now'].includes(this.filter.operator);
    },
    relativeFilterTypes() {
      return ['less than days from now', 'more than days from now', 'less than days ago', 'more than days ago'];
    },
    shouldDisplayDaysInput() {
      return this.shouldDisplayInput && this.relativeFilterTypes.includes(this.filter.operator);
    },
    shouldDisplayDateInput() {
      return this.shouldDisplayInput && !this.relativeFilterTypes.includes(this.filter.operator);
    },
    placeholder() {
      if (this.shouldUseRangeDatepicker) {
        return 'DD/MM/YYYY HH:mm ~ DD/MM/YYYY HH:mm';
      } else {
        return this.dateFormat;
      }
    },
    dateInputType() {
      if (this.field.typeOptions.date_format === 'without_time' ||
        ['is on', 'is not on', 'contains', 'does not contain'].includes(this.filter.operator)) {
        return 'date';
      }

      if (this.field.dataType === 'date_time_range') return 'datetime';

      return this.field.typeOptions.date_format === 'with_time' ? 'datetime' : 'date';
    },
    dateFormat() {
      if (this.dateInputType === 'datetime') {
        return 'DD/MM/YYYY HH:mm';
      } else if (this.dateInputType === 'date') {
        return 'DD/MM/YYYY';
      }
    },
  },
  mounted() {
    this.setTmpDate();
  },
  watch: {
    'filter.value': function (newValue, _oldValue) {
      if (newValue === null) this.tmpDate = null;
    }
  },
  methods: {
    formatToNumber(event) {
      if ((/e/i).test(event.key)) event.preventDefault()
    },
    handleChange(date, _type) {
      if (this.shouldUseRangeDatepicker) {
        if (!date[0] || !date[1]) {
          this.filter.value = null;
        } else {
          this.filter.value = date;
        }
        this.setTmpDate();
      } else {
        this.filter.value = moment(date).toISOString();
      }
    },
    setTmpDate() {
      if (!this.shouldDisplayDateInput || !(this.filter && this.filter.value)) return this.tmpDate = null;

      if (!this.shouldUseRangeDatepicker) return this.tmpDate = moment(this.filter.value).toDate();

      if (!this.filter.value[0] || !this.filter.value[1]) return this.tmpDate = null;

      return this.tmpDate = [moment(this.filter.value[0]).toDate(), moment(this.filter.value[1]).toDate()];
    }
  }
}
</script>
