<template lang="pug">
.dashboard-peakperiod(ref="blockContent")
  template
    .dashboard-block--header
      h2.tw-text-neutral-500.tw-text-xl.tw-font-normal {{ $t('dashboardBlocks.peakperiod.header') }}
    .tw-w-fit(class="2xl:tw-flex 2xl:tw-space-x-8")
      .tw-bg-white.tw-p-6
        header
          h3.tw-text-xl.tw-font-semibold.tw-text-gray-900 {{ $t('dashboardBlocks.peakperiod.confirmed') }}
          .tw-text-sm.tw-text-gray-400.tw-font-medium {{ $t('dashboardBlocks.peakperiod.source.confirmed') }}

        .tw-mt-4.tw-flex.tw-space-x-8(v-if="confirmed_items.length")
          .tw-border.tw-border-gray-200.tw-border-solid.tw-rounded-lg.tw-px-2.tw-py-4.tw-w-full.tw-relative.tw-overflow-hidden(
            v-for="item in confirmed_items"
            :class="item.ecowatt_bg_color"
            :title="'Ecowatt : ' + $t('dashboardBlocks.peakperiod.ecowatt_' + item.ecowatt)"
          )
            .tw-text-xs.tw-text-gray-400.tw-font-medium(
              :class="item.ecowatt_text_color"
            ) {{ item.day_tag }}
            .tw-mt-2.tw-text-lg.tw-text-gray-600.tw-font-semibold(
              :class="item.ecowatt_text_color"
            ) {{ item.type_tag }}
            .tw-h-2.tw-w-full.tw-absolute.tw-bottom-0(:class="'-tw-ml-2 ' + item.ecowatt_color")

        .tw-mt-4
          .tw-text-xs.tw-text-gray-800.tw-font-medium {{ $t('dashboardBlocks.peakperiod.ecowatt') }}
          .tw-mt-2.tw-flex.tw-space-x-4
            .tw-flex.tw-space-x-1
              .tw-rounded-full(class="tw-w-3.5 tw-h-3.5 tw-bg-[#02F0C6]")
              .tw-text-xs.tw-text-gray-800.tw-font-medium {{ $t('dashboardBlocks.peakperiod.ecowatt_no_alert') }}
            .tw-flex.tw-space-x-1
              .tw-rounded-full(class="tw-w-3.5 tw-h-3.5 tw-bg-[#F2790F]")
              .tw-text-xs.tw-text-gray-800.tw-font-medium {{ $t('dashboardBlocks.peakperiod.ecowatt_level1') }}
            .tw-flex.tw-space-x-1
              .tw-rounded-full(class="tw-w-3.5 tw-h-3.5 tw-bg-[#E63946]")
              .tw-text-xs.tw-text-gray-800.tw-font-medium {{ $t('dashboardBlocks.peakperiod.ecowatt_level2') }}

      .tw-bg-white.tw-p-6.tw-w-fit.tw-mt-8(class="2xl:tw-mt-0")
        header
          h3.tw-text-xl.tw-font-semibold.tw-text-gray-900 {{ $t('dashboardBlocks.peakperiod.prevision') }}
          .tw-text-sm.tw-text-gray-400.tw-font-medium {{ $t('dashboardBlocks.peakperiod.source.prevision') }}

        .tw-mt-4.tw-flex.tw-space-x-8(v-if="estimated_items.length")
          .tw-border.tw-border-gray-200.tw-border-solid.tw-rounded-lg.tw-px-2.tw-pt-4.tw-pb-1.tw-w-20.tw-relative.tw-overflow-hidden(
            v-for="item in estimated_items"

          )
            .tw-text-xs.tw-text-gray-400.tw-font-medium.tw-text-center {{ item.issued_on }}
            .tw-mt-2.tw-flex.tw-justify-center.tw-items-end.tw-space-x-1
              template(v-if="item.estimate_value !== '' && item.estimate_value !== null")
                .tw-text-2xl.tw-text-gray-700.tw-font-bold {{ item.estimate_value }}
                .tw-text-base.tw-text-gray-600 %
              .tw-text-2xl.tw-text-gray-700.tw-font-bold(v-else) -
</template>

<script>
import { api }                 from '../../api/client';
import { mapGetters }          from 'vuex';
import { EventBus }            from '../../main';
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import moment                  from 'moment';

export default {
  mixins: [DashboardBlockMixin],
  data() {
    return {
      estimated_items: [],
      confirmed_items: [],
    }
  },
  computed: {
    ...mapGetters({
      getTableById: 'tableStore/getTableById',
    }),
    shouldAutoResize() {
      return !(this.confirmed_items.length && this.estimated_items.length);
    }
  },
  created() {
    EventBus.$on('dashboard-block-updated-' + this.dashboardBlockId, this.handleSettingsUpdate);
  },
  beforeDestroy() {
    EventBus.$off('dashboard-block-updated-' + this.dashboardBlockId);
  },
  mounted() {
    this.loadPeakPeriods();
  },
  methods: {
    handleSettingsUpdate() {
      this.loadPeakPeriods();
    },
    loadPeakPeriods() {
      return api.dashboardBlocks.peakPeriods(this.dashboardBlockId).then((response) => {
          this.confirmed_items = response.data.confirmed_items
          this.estimated_items = response.data.estimated_items.map((item) => {
            item.issued_on = moment(item.issued_on).format('D MMM');

            return item;
          });
        }).then(this.resizeBlock);
    }
  }
}
</script>
