import i18n from "../vue/locales/locales.js";
import { displayAlerts } from './alerts';
import { displayUserNotifications } from './notifications';

const capitalize = (word) => word.charAt(0).toUpperCase() + word.slice(1);
const getNotificationNavBtn = () => document.getElementById('views-notifications-button');

const noNotificationsHTML = (notificationType) => {
  return `<div class="mb-2 px-3 py-2 no-notifications-container">
    <div class="d-flex">
      <div class="d-flex align-items-center justify-content-start"><i class="far fa-bell-slash text-dark mr-2"></i></div>
      <div class="text-sm font-weight-bolder">${i18n.t('notifications.no' + capitalize(notificationType) + 's')}</div>
    </div>
  </div>`;
}

const displayNotifications = (notificationType, payload) => {
  if (notificationType === 'alert') {
    displayAlerts(payload);
  } else if (notificationType === 'notification') {
    displayUserNotifications(payload);
  }
};

const displayNoNotificationsMessage = (container, notificationType) => {
  container.insertAdjacentHTML("beforeend", noNotificationsHTML(notificationType));
}

const toggleNotificationsBadge = (notificationCount) => {
  const notificationNavBtn = getNotificationNavBtn();

  if (notificationCount > 0) {
    notificationNavBtn.classList.add('red-dotted');
  } else {
    notificationNavBtn.classList.remove('red-dotted');
  }
}

const refreshNotificationBadgeDisplay = () => {
  const notificationsPanel = document.getElementById('notifications-panel');

  if (!notificationsPanel) return;

  fetch(`${notificationsPanel.dataset.path}.json`)
  .then(response => response.json())
  .then((count) => toggleNotificationsBadge(count));
}

const removeShowMoreBtn = (notificationType) => {
  const wrapper   = document.getElementById(`${notificationType}s-wrapper`);
  let showMoreBtn = wrapper.querySelector('.js-show-more-btn');

  if (showMoreBtn) showMoreBtn.remove();
}

const fetchNotifications = (notificationType, path = null) => {
  const container = document.getElementById(`${notificationType}s-container`);

  if(container) {
    const notificationsPath = path || container.dataset.path

    fetch(`${notificationsPath}.json`)
    .then(response => response.json())
    .then((payload) => {
      container.innerHTML = '';

      if (payload.data.length) {
        displayNotifications(notificationType, payload);
      } else {
        removeShowMoreBtn(notificationType);
        displayNoNotificationsMessage(container, notificationType);
      }
    });
  }
}

const initNotifications = () => {
  const notificationNavBtn = getNotificationNavBtn();

  if (notificationNavBtn) {
    setTimeout(() => refreshNotificationBadgeDisplay(), 1000);

    notificationNavBtn.addEventListener('click', () => {
      fetchNotifications('notification');
      fetchNotifications('alert');
    });
  }
}

export { refreshNotificationBadgeDisplay, fetchNotifications, initNotifications };
