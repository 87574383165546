<template lang="pug">
.base-modal-wrapper(
  :style="{ 'z-index': (modalIndex + 1) * 1000 }"
  @click.self="closeModal"
)
  .base-modal.p-3(:style="sameModalOffsetStyle")
    span.base-modal-close(@click.stop="closeModal")
      svg.icon
        use(xlink:href="#icon-times-solid")

    .base-modal-header.mb-3
      h5.mb-4.text-center.mx-5
        i.far.fa-ballot-check.text-info
        span.text-uppercase.ml-2
          | {{ $t('validations.title') }}
    .base-modal-body
      .fields-validations
        div(
          v-for="(validations, fieldId) in validationsByFieldId"
        )
          .d-flex.justify-content-between
            p.font-weight-bolder
              i.mr-1.far.fa-fw.text-gray-600(:class="'fa-'+ fieldsById[fieldId].faIconName")
              span {{ fieldsById[fieldId].name }}
            span.text-danger.cursor-pointer(@click="removefieldValidations(fieldId)")
              i.far.fa-trash
          ul.list-unstyled.pl-3
            li.mb-1
              input.mr-2(type="checkbox" v-model="validations.presence" :id="'presence_' + fieldId")
              label(:for="'presence_' + fieldId") {{ $t("validations.mustBePresent") }}
            li.mb-1
              input.mr-2(type="checkbox" v-model="validations.uniqueness" :id="'unique_' + fieldId")
              label(:for="'unique_' + fieldId") {{ $t("validations.mustBeUnique") }}
            li.mb-1(v-for="(comparison, index) in validations.comparisons")
              .d-flex.align-items-center.justify-content-between
                .d-flex.align-items-center.justify-content-start
                  span.mr-1 {{ $t('validations.must_be') }}

                  v-select.no-shrink.mr-1.mx-2.flex-shrink-0(
                    v-model="comparison[0]"
                    :options="COMPARISON_OPERATORS"
                    :get-option-label="option => $t('validations.comparison.' + option)"
                    :clearable="false"
                    :append-to-body="true"
                  )

                  v-select.no-shrink.mr-1.mx-2.flex-shrink-0(
                    v-model="comparison[1]"
                    :options="OTHER_ATTRIBUTE_TYPES"
                    :get-option-label="otherAttributeType => $t('validations.otherAttributeType.' + otherAttributeType)"
                    :clearable="false"
                    :append-to-body="true"
                  )

                  v-select.no-shrink.mx-2.flex-shrink-0(
                    v-model="comparison[2]"
                    :searching="true"
                    :options="otherAttributeValues(comparison[1], fieldId)"
                    :get-option-label="option => option.name"
                    :get-option-key="option => option.value"
                    :clearable="false"
                    :append-to-body="true"
                    :reduce="option => option.value"
                  )

                span.text-gray-600.cursor-pointer(@click="$delete(validations.comparisons, index)")
                  i.fas.fa-minus

            span.text-gray-600.d-inline-block.cursor-pointer.add-comparison.mt-3(
              v-if="hasComparableDataType(fieldsById[fieldId])"
              @click.prevent="addComparison(fieldId)"
            )
              i.far.fa-plus.mr-2
              | {{ $t('validations.add_comparison') }}

            li.mb-1(v-for="(format, index) in validations.formats")
              .d-flex.align-items-center.justify-content-between
                .d-flex.align-items-center.justify-content-start
                  span.mr-1 {{ $t('validations.must_be_a') }}

                  v-select.no-shrink.mr-1.mx-2.flex-shrink-0(
                    v-model="validations.formats[index]"
                    :options="FORMAT_OPTIONS"
                    :get-option-label="option => $t('validations.format.' + option)"
                    :clearable="false"
                    :append-to-body="true"
                  )
                span.text-gray-600.cursor-pointer(@click="$delete(validations.formats, index)")
                  i.fas.fa-minus

            span.text-gray-600.d-inline-block.cursor-pointer.add-comparison.mt-3(
              v-if="hasFormatableDataType(fieldsById[fieldId]) && formatableFields.length"
              @click.prevent="addFormat(fieldId)"
            )
              i.far.fa-plus.mr-2
              | {{ $t('validations.add_format') }}

          hr.mb-4

      .empty-placeholder.my-5.font-italic.text-center.font-weight-light {{ $t('validations.empty') }}

    .base-modal-footer
      .d-flex.align-items-center(v-if="fieldsOptions.length")
        //- p.mb-0 {{ $t('validations.add_validation_on') }}
        v-select.w-100(
          :searching="true"
          :options="fieldsOptions"
          :get-option-label="option => option.name"
          :get-option-key="option => option.id"
          :clearable="false"
          :append-to-body="true"
          :value="null"
          :placeholder="$t('validations.add_validation_on')"
          @input="addFieldForValidation"
        )

      .mt-5
        button.btn.btn-primary.d-block.ml-auto(
          :disabled="!validationsChanged"
          @click.prevent="submitValidations"
        ) {{ $t('save') }}
</template>

<script>
import { mapState } from 'vuex'
import { BaseModalMixin } from './BaseModalMixin'
import { cloneDeep, each, isEqual } from 'lodash'
import { api } from '../../api/client'
import i18n from "../../locales/locales.js";

const VALIDATABLE_DATA_TYPES = [
  'string',
  'number',
  'date',
  'boolean',
  'select',
  'files',
  'references',
  'users',
  'date_time_range',
  'lookups'
]

const COMPARISON_OPERATORS = [
  'lt',
  'gt',
  'gteq',
  'lteq',
  'eq',
  'not_eq',
]

const OTHER_ATTRIBUTE_TYPES = [
  'field',
  'relative',
]

const RELATIVE_ATTRIBUTE_VALUES = [
  'today',
]

const COMPARABLE_DATA_TYPES = [
  'date',
  'number'
]

const FORMAT_OPTIONS = ['tel', 'email']

export default {
  mixins: [BaseModalMixin],
  data() {
    return {
      validations: null,
      validationsByFieldId: null
    }
  },
  computed: {
    ...mapState({
      table: state => state.tableStore.table
    }),
    fieldsById() {
      if (this.table.id) {
        return this.table.fields.reduce((accumulator, field) => {
          accumulator[field.id] = field
          return accumulator
        }, {})
      }
      return {}
    },
    fieldsOptions() {
      return this.table.fields
        .filter((field) => {
          return !Object.keys(this.validationsByFieldId).includes(field.id.toString()) &&
            VALIDATABLE_DATA_TYPES.includes(field.dataType)
        })
        .map(({ id, name }) => ({ id, name }))
    },
    formatableFields() {
      return this.table.fields.filter((field) => {
        return this.hasFormatableDataType(field)
      }).map(({ id, name }) => ({ id, name }))
    },
    validationsForPayload() {
      // Validations payload should look like this:
      //{
      //  presence: [1, 2, 3], # field_ids
      //  comparison: [[1, :lt, :field, 4]] # Array of [:field, operator, :other_attribute_type, :other_attribute_value]
      //}
      const validations = {
        presence: [],
        comparison: [],
        format: [],
        uniqueness: []
      }
      each(this.validationsByFieldId, (fieldValidations, fieldId) => {
        fieldId = Number.parseInt(fieldId)

        if (fieldValidations.presence) {
          validations.presence.push(fieldId)
        }
        if (fieldValidations.uniqueness) {
          validations.uniqueness.push(fieldId)
        }
        fieldValidations?.comparisons?.forEach(([operator, otherAttributeType, otherAttributeValue]) => {
          validations.comparison.push([fieldId, operator, otherAttributeType, otherAttributeValue])
        })
        fieldValidations?.formats?.forEach((formatName) => {
          validations.format.push([fieldId, formatName])
        })
      })
      return validations
    },
    validationsChanged() {
      const originalValidations = this.table.recordValidations

      return !isEqual(this.validationsForPayload.presence, originalValidations.presence) ||
        !isEqual(this.validationsForPayload.comparison, originalValidations.comparison) ||
        !isEqual(this.validationsForPayload.format, originalValidations.format) ||
        !isEqual(this.validationsForPayload.uniqueness, originalValidations.uniqueness)
    }
  },
  created() {
    this.COMPARISON_OPERATORS = COMPARISON_OPERATORS;
    this.FORMAT_OPTIONS = FORMAT_OPTIONS;
    this.OTHER_ATTRIBUTE_TYPES = OTHER_ATTRIBUTE_TYPES;
    this.RELATIVE_ATTRIBUTE_VALUES = RELATIVE_ATTRIBUTE_VALUES;
    this.validations = cloneDeep(this.table.recordValidations)
    this.validationsByFieldId = this.buildValidations()
  },
  methods: {
    hasComparableDataType({ dataType }) {
      return COMPARABLE_DATA_TYPES.includes(dataType)
    },
    hasFormatableDataType({ dataType }) {
      return dataType === "string";
    },
    buildValidations() {
      const validationsByFieldId = {}
      const validations = cloneDeep(this.table.recordValidations)

      validations.presence.forEach((fieldId) => {
        validationsByFieldId[fieldId] = { presence: true, comparisons: [], formats: [], uniqueness: false }
      })
      validations.uniqueness.forEach((fieldId) => {
        validationsByFieldId[fieldId] ||= { uniqueness: false }
        validationsByFieldId[fieldId].uniqueness = true;
      })
      validations.comparison.forEach(([fieldId, operator, otherAttributeType, otherAttributeValue]) => {
        validationsByFieldId[fieldId] ||= { comparisons: [] }
        validationsByFieldId[fieldId].comparisons.push([operator, otherAttributeType, otherAttributeValue])
      })
      validations.format.forEach(([fieldId, formatName]) => {
        validationsByFieldId[fieldId] ||= { formats: [] }
        validationsByFieldId[fieldId].formats.push(formatName)
      })
      return validationsByFieldId
    },
    addComparison(fieldId) {
      let newComparison;

      if (this.otherAttributeValues('field', fieldId).length !== 0) {
        newComparison = [COMPARISON_OPERATORS[0], 'field', this.otherAttributeValues('field', fieldId)[0].value];
      } else {
        newComparison = [COMPARISON_OPERATORS[0], 'relative', this.otherAttributeValues('relative', fieldId)[0].value];
      }

      if (this.validationsByFieldId[fieldId].comparisons === undefined) {
        this.$set(this.validationsByFieldId[fieldId], 'comparisons', [])
      }

      this.validationsByFieldId[fieldId].comparisons.push(newComparison)
    },
    addFormat(fieldId) {
      const newFormat = FORMAT_OPTIONS[0];

      if (this.validationsByFieldId[fieldId].formats === undefined) {
        this.$set(this.validationsByFieldId[fieldId], 'formats', [])
      }

      const existingFormats = this.validationsByFieldId[fieldId].formats
      this.validationsByFieldId[fieldId].formats = [newFormat, ...existingFormats];
      // this.validationsByFieldId[fieldId].formats.push([newFormat])
    },
    removefieldValidations(fieldId) {
      this.$delete(this.validationsByFieldId, fieldId)
    },
    addFieldForValidation({ id: fieldId }) {
      this.$set(this.validationsByFieldId, fieldId, {
        presence: false,
        comparison: [],
        formats: [],
        uniqueness: false
      })
    },
    otherAttributeValues(otherAttributeType, fieldId) {
      if (otherAttributeType === 'field') {
        return this.table.fields.filter((field) => {
          return this.hasComparableDataType(field) && field.id != fieldId
        }).map(({ id, name }) => ({ name, value: id }))
      } else if (otherAttributeType === 'relative') {
        return this.RELATIVE_ATTRIBUTE_VALUES.map((relativeAttributeValue) => {
          return {
            name: i18n.t('validations.relativeAttributeValue.' + relativeAttributeValue),
            value: relativeAttributeValue,
          };
        });
      } else {
        return [];
      }
    },
    submitValidations() {
      api.tables.updateRecordValidations({
        tableId: this.table.id,
        recordValidations: this.validationsForPayload
      }).then(({ data: { table: { record_validations } } }) => {
        this.table.recordValidations = record_validations;
        this.validationsByFieldId = this.buildValidations()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-placeholder {
  display: none;
}

.fields-validations:empty + .empty-placeholder {
  display: block;
}

.text-gray-600:hover {
  color: #333333;
  text-decoration: underline;
}
</style>
