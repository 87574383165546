import Vue     from 'vue/dist/vue.esm'
import VueI18n from 'vue-i18n'

import { dateTimeFormats } from './dateTimeFormats'
import { fr } from './fr/fr'
import { en } from './en/en'
import { es } from './es/es'

Vue.use(VueI18n)

const messages = { fr, en, es }

const i18n = new VueI18n({
  locale: 'fr',
  messages,
  dateTimeFormats,
})

export default i18n
