<template functional lang="pug">
  .entry-input--reference
    .select-wrapper.read-only.reference-entry-content
      span.tag(v-if="props.entry.value") {{ props.entry.value }}
</template>

<script>
export default {
  functional: true,
  props: {
    entry: Object
  },
  render(h, ctx) {
    const entry = ctx.props.entry;
  }
};
</script>
