import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["link", "content"]
  static classes = ["activeTab"]

  selectTab(event) {
    this.linkTargets.forEach((link) => {
      link.classList.remove(...this.activeTabClasses);
    });
    event.target.classList.add(...this.activeTabClasses);
    this.contentTargets.forEach((content) => {
      if (content.id === event.params.contentId) {
        content.classList.remove("tw-hidden");
      } else {
        content.classList.add("tw-hidden");
      }
    })
  }
}
