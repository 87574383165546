<template lang="pug">
  .project-show
    side-menu(
      v-if="displaySideMenu && (feature.canCreateResource || hasManyResources)"
      @toggle-side-menu="saveMainPanelWidth"
    )
    .main-panel(
      ref="mainPanel"
    )
      template(v-if="!error && currentResourceType")
        DashboardShow(v-if="currentResourceType === 'dashboard'")
        ViewShow(v-else-if="currentResourceType === 'view'")
      .flex-center.h-100(v-else-if="!error && loading")
        Loader
      .flex-center.h-100(v-else-if="error")
        MainError(:error="error")
    ModalsWrapper
</template>

<script>
import { debounce }             from "lodash";
import ModalsWrapper            from "./ModalsWrapper.vue";
import DashboardShow            from "./DashboardShow.vue";
import ViewShow                 from "./ViewShow.vue";
import SideMenu                 from "./side_menu/SideMenu.vue";
import MainError                from "./MainError.vue";
import { mapState, mapGetters } from "vuex";
import User                     from "../models/user";
import Feature                  from "../models/feature";
import TreeItem                 from '../models/treeItem';
import { EventBus }             from '../main';
import Dashboard                from '../models/dashboard';

export default {
  components: {
    ModalsWrapper,
    DashboardShow,
    ViewShow,
    SideMenu,
    MainError
  },
  props: {
    projectPrimaryColors: {
      type: Object,
      required: true
    },
    projectSecondaryColors: {
      type: Object,
      required: true
    },
    dashboardId: {
      type: Number
    },
    viewId: {
      type: Number
    },
    tableId: {
      type: Number
    },
    currentUserInfos: {
      type: Object,
      required: true
    },
    featureData: {
      type: Object,
      required: true
    },
    projectUsers: {
      type: Array,
      required: true
    },
    mapboxApiKey: {
      type: String,
    },
    treeItems: {
      type: Array,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    translationFallbackClasses: {
      type: String,
      required: true
    },
    displaySideMenu: {
      type: Boolean,
      default: true,
    },
    workspace: {
      type: String,
    }
  },
  computed: {
    ...mapState({
      feature: state => state.feature,
      tables: state => state.tableStore.tables,
      currentResourceType: 'currentResourceType',
      error: 'error'
    }),
    ...mapGetters({
      loading: "isLoading"
    }),
    hasManyResources() {
      return (this.tables.length + Dashboard.query().count()) > 1;
    }
  },
  created() {
    window.addEventListener("popstate", this.reloadLocation);
    this.setStaticConfiguration();
    this.loadProject();
    this.setTreeItems();
    this.$i18n.locale = this.locale;
  },
  mounted() {
    this.$nextTick(this.saveMainPanelWidth);
    window.addEventListener("resize", this.saveMainPanelWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.saveMainPanelWidth);
    window.removeEventListener("popstate", this.reloadLocation);
    this.$store.dispatch("clearChannels");
  },
  methods: {
    setStaticConfiguration() {
      User.insert({ data: this.projectUsers });
      this.$store.commit("SET_PROJECT_PRIMARY_COLORS", this.projectPrimaryColors);
      this.$store.commit("SET_PROJECT_SECONDARY_COLORS", this.projectSecondaryColors);
      this.$store.commit("SET_CURRENT_USER", User.buildCurrentUser(this.currentUserInfos));
      this.$store.commit("SET_FEATURE", Feature.build(this.featureData));
      this.$store.commit("SET_MAPBOX_API_KEY", this.mapboxApiKey);
      this.$store.commit("SET_TRANSLATION_FALLBACK_CLASSES", this.translationFallbackClasses);
      this.$store.commit("SET_WORKSPACE", this.workspace);
      this.$store.dispatch('setProjectByProfileChannel');
      this.$store.dispatch('setSessionChannel');
      this.$store.dispatch('setUserNotificationsChannel');
      this.$store.dispatch('setUserDataImportChannel');
    },
    saveMainPanelWidth: debounce(function() {
      this.$store.commit("SET_SCREEN_WIDTH", window.innerWidth);
      this.$store.commit("SET_MAIN_PANEL_WIDTH", this.$refs.mainPanel.clientWidth);
      EventBus.$emit("main-panel-width-updated");
    }, 1500, { leading: true, trailing: true }),
    reloadLocation() {
      window.location.reload(true);
    },
    loadProject() {
      this.$store.dispatch('projectStore/loadProject', {
        viewId:      this.viewId,
        tableId:     this.tableId,
        dashboardId: this.dashboardId,
      });
    },
    setTreeItems() {
      TreeItem.insert({ data: this.treeItems });
    },
  }
};
</script>
