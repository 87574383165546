<template lang="pug">
  span.view-action.mr-1(
    @click="$emit('open')"
    :class="{ opened: show }"
  )
    span.view-action--btn
      i.far.fa-copy.fa-fw.view-action--btn-icon
      span.view-action--btn-text.ml-1 {{ $t('controlBarActions.createView') }}
      .ctrlbar-action(
        v-if="show"
        v-click-outside="closeAction"
      )
        .ctrlbar-action--header(@click.stop="closeAction")
          div
            i.far.fa-copy.mr-2
            span {{ $t('controlBarActions.createView') }}
          .ctrlbar-action--close(@click.stop="closeAction")
            svg.icon
              use(xlink:href="#icon-times-solid")
        .ctrlbar-action--body
          .px-2.mt-2
            input.form-control(
              v-focus
              v-model="newView.name"
              :placeholder="$t('views.createViewPlaceholder')"
            )
          .ctrlbar-action--text.hoverable.text-center.my-2
            input(
              v-model="newView.alert"
              type="checkbox"
              hidden="true"
              name="newViewInput"
              id="newViewInput"
            )
            label.ctrlbar-action--label.justify-content-center(for="newViewInput")
              span.ctrlbar-action--label-icon(:class="{ active: newView.alert }")
                span.ctrlbar-action--label-icon-pill
              span {{ $t('views.alert') }}
        .ctrlbar-action--footer.flex-column
          button.ctrlbar-action--btn.mb-2(
            @click="createView('profile')"
            :disabled="!newView.name.length"
          ) {{ $t('views.profileView') }}
</template>

<script>
import { api }                  from '../../api/client';
import { EventBus }             from '../../main.js';
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      newView: {
        name: '',
        alert: false
      }
    }
  },
  methods: {
    closeAction() {
      this.$emit('close')
    },
    createView(scope) {
      const view = {
        ...this.newView,
        query_string:     JSON.stringify(this.currentView.query.filters),
        logical_operator: this.currentView.logicalOperator
      }
      api.views.create({
        view, scope, originalViewId: this.currentView.id
      }).then(response => {
          const viewData = response.data.viewData;
          this.$store.dispatch('viewStore/pushNewView', { viewData });
          this.$store.dispatch('viewStore/fetchView', { viewId: viewData.id });

          this.newView        = { name: '', alert: false };
          this.showCreateView = false;
          this.closeAction();
          EventBus.$emit('viewCreated');
        }
      )
    }
  },
  computed: {
    ...mapState({
      currentView: state => state.viewStore.view
    })
  }
}
</script>
