import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use";

export default class extends Controller {
  connect() {
    useClickOutside(this);
  }

  clickOutside() {
    this.close();
  }

  toggle() {
    if (this.element.getAttribute("open")) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    this.element.firstElementChild.setAttribute("aria-selected", "true");
    this.element.setAttribute("open", "open");
  }

  close() {
    this.element.firstElementChild.removeAttribute("aria-selected");
    this.element.removeAttribute("open");
  }
}
