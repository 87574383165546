<template lang="pug">
  .dashboard-schedule
    template(v-if="settingsAreReady")
      .dashboard-schedule-items--header.kui-mtop.pb-3
        h2.pr-3(
          :class="{ [translationFallbackClasses]: titleFallback }"
        ) {{ dashboardBlock.title }}
      .dashboard-schedule-items
        .dashboard-grouped-items
          template(
            v-for="group in itemsGroupedByDate"
          )
            .dashboard-schedule-item(
              v-for="(item, index) in group.items"
              :key="`${item.recordId} - ${group.date.fullDate} - ${index}`"
              :class="{ 'item-border-bottom': index === group.items.length -1 }"
            )
              .dashboard-schedule-item--date
                .d-flex.justify-content-start.align-items-end(
                  v-if="index === 0"
                )
                  .dashboard-schedule-item--day-of-month {{ group.date.dayOfMonth }}
                  .pl-1.dashboard-schedule-item--month-and-day {{ group.date.monthAndDayName }}

              .dashboard-schedule-item--start-and-finishing-hours {{ item.startingHours + ' - ' + item.finishingHours }}

              .dashboard-schedule-item--icon(:style="{ 'color': item.color }")
                i.fas.fa-fw(:class="'fa-' + item.icon")

              .dashboard-schedule-item--description(:title="item.description") {{ item.description }}

              .dashboard-schedule-item--title.kui-table-l(:title="item.title") {{ item.title }}

              .dashboard-schedule-item--label-type
                .flex-center
                  .label.bg-red-200.text-red-700(v-if="item.label") {{ item.label }}

    .flex-center.h-100(v-else)
      div
        h2.text-center.text-warning
          i.fas.fa-exclamation-triangle
        p.font-italic.text-center.mx-auto {{ $t('dashboardBlocks.blockIsNotConfigured') }}

</template>
<script>
import { every,
         chain,
         fromPairs } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { api } from '../../api/client';
import { EventBus } from '../../main';
import { DashboardBlockMixin } from '../../mixins/DashboardBlockMixin';
import moment from 'moment';
import DashboardSharedItem from '../../models/dashboardSharedItem';

export default {
  mixins: [DashboardBlockMixin],
  data() {
    return {
      mandatorySettings: [
        'view_id',
        'start_date_field_id',
        'end_date_field_id',
        'title_field_id',
        'label_field_id',
        'description_field_id',
        'icon_field_id',
        'color_field_id'
      ]
    }
  },
  computed: {
    ...mapState({
      tables: state => state.tableStore.tables
    }),
    ...mapGetters({
      getViewById: 'viewStore/getViewById',
      allViews:    'viewStore/allViews',
    }),
    itemsGroupedByDate() {
      if (this.tablesForList.length) return chain(this.itemsToGroupByDate)
                                    .orderBy(['startingHours'])
                                    .groupBy("dayToGroupBy")
                                    .map((items, date) => ({ date: this.splittedDateByDayAndMonth(date), items: items }))
                                    .orderBy('date.fullDate')
                                    .value();

      return [];
    },
    orderedAndVisibleRecords() {
      return this.tablesForList.flatMap((table) => {
        const sortedVisibleRecordsIds = this.sortedVisibleRecordsIdsByTableId[table.id];
        if (sortedVisibleRecordsIds) {
          return sortedVisibleRecordsIds.map(id => table.recordsById[id]);
        }
        return [];
      });
    },
    builtItems() {
      return this.orderedAndVisibleRecords.reduce((builtItems, record) => {
        const builtItem = this.buildItem(record);
        if (builtItem) builtItems.push(builtItem);
        return builtItems;
      }, []);
    },
    itemsToGroupByDate() {
      const itemsWithLessThanOneDayDuration = [];

      this.builtItems.forEach((item, i) => {
        const daysToEndDate =  moment(item.endDate, "DD/MM/YYYY").diff(moment(item.dayToGroupBy, "DD/MM/YYYY"),'days')

        if (daysToEndDate >= 0) { // Managing events with end date in the future
          const dividedItems = []

          // Creating as many generic items needed between the two dates with starting and finishing hours set to '00:00 - 23:59'
          for(i=0; i <= daysToEndDate ; i++) {
            const genericItem = Object.assign({}, item)
            dividedItems.push(Object.assign(genericItem, {dayToGroupBy: moment(item.dayToGroupBy, "DD/MM/YYYY").add(i, 'd').format("DD/MM/YYYY"), startingHours: "00:00", finishingHours: "23:59"}))
          }

          // Assigning real starting hour to the first generic item if startdate has not passed yet
          const daysBetweenStartDateAndDayToGroupBy = moment(item.startDate, "DD/MM/YYYY").diff(moment(item.dayToGroupBy, "DD/MM/YYYY"),'days')
          if (daysBetweenStartDateAndDayToGroupBy >= 0) Object.assign(dividedItems[0], {startingHours: item.startingHours})

          // Assigning real finishing hour to the last generic item
          Object.assign(dividedItems[dividedItems.length - 1], {finishingHours: item.finishingHours})
          itemsWithLessThanOneDayDuration.push(...dividedItems);

        } else if ( item.finishingHours === "XX:XX" ) { // Managing events without end date
          itemsWithLessThanOneDayDuration.push(item);
        }
      });
      return itemsWithLessThanOneDayDuration;
    },
    scheduleSettings() {
      return this.dashboardBlock.kind_settings
    },
    settingsAreReady() {
      return this.dashboardBlock && this.tablesForList.length && every(
        this.mandatorySettings.map((setting) => {
          return every(this.tablesForList, (table) => {
            const settings = this.scheduleSettings.settings_by_table_id[table.id]
            if (settings) return settings[setting]
          });
        })
      );
    },
    tablesForList() {
      if (this.scheduleSettings.concerned_table_ids) {
        return this.tables.filter(table => this.scheduleSettings.concerned_table_ids.includes(table.id));
      }
      return [];
    },
    viewsForList() {
      if (this.scheduleSettings.concerned_table_ids) {
        const viewIds = this.scheduleSettings.concerned_table_ids.map(tableId => this.scheduleSettings.settings_by_table_id[tableId].view_id);
        return this.allViews.filter(view => viewIds.includes(view.id));
      }
      return [];
    },
    sortedVisibleRecordsIdsByTableId() {
      return fromPairs(
        this.viewsForList.map((view) => [view.table.id, view.visibleRecordIds])
      );
    }
  },
  watch: {
    itemsGroupedByDate: function() {
      this.saveRecordsTakingPlaceNow();
    }
  },
  created() {
    this.loadDashboardBlock();
    EventBus.$on('dashboard-block-updated-' + this.dashboardBlockId, this.handleSettingsUpdate);
  },
  beforeDestroy() {
    EventBus.$off('dashboard-block-updated-' + this.dashboardBlockId);
  },
  methods: {
    async loadDashboardBlock() {
      if (this.settingsAreReady) {
        await this.loadTables();
        this.saveRecordsTakingPlaceNow();
        await this.loadViews();
      }
    },
    async handleSettingsUpdate(event) {
      if (event.type === 'tablesChanged') await this.loadTables();
      if (event.type === 'viewsChanged') await this.loadViews();
      this.saveRecordsTakingPlaceNow();
    },
    momentNow() {
      return moment(moment(Date.now()).format("DD/MM/YYYY"), "DD/MM/YYYY");
    },
    buildItem(record) {
      record.buildEntries();
      const tableSettings = this.scheduleSettings.settings_by_table_id[record.table.id];
      const startDate = record.entriesByFieldId[tableSettings.start_date_field_id]?.value;

      if (startDate) {
        return {
          recordId:       record.id,
          title:          record.entriesByFieldId[tableSettings.title_field_id].toString(),
          description:    record.entriesByFieldId[tableSettings.description_field_id].toString(),
          startingHours:  this.startingHours(record),
          finishingHours: this.finishingHours(record),
          dayToGroupBy:   this.dayToGroupBy(record),
          startDate:      this.startDate(record),
          endDate:        this.endDate(record),
          label:          record.entriesByFieldId[tableSettings.label_field_id].toString().toUpperCase(),
          icon:           record.entriesByFieldId[tableSettings.icon_field_id].toString(),
          color:          record.entriesByFieldId[tableSettings.color_field_id].toString(),
        }
      }
    },
    loadTables() {
      if (this.settingsAreReady) return Promise.all(this.tablesForList.map(this.loadTable));
    },
    loadViews() {
      if (this.settingsAreReady) return Promise.all(this.viewsForList.map(this.loadView));
    },
    loadTable(table) {
      if (table && table.slug) return; // do not load again if table is present

      const viewId = this.scheduleSettings.settings_by_table_id[table.id].view_id;
      return api.tables.load({
        tableId:      table.id,
        viewId:       viewId,
        withViewData: true,
        skipLoader:   true,
      }).then(response => {
          this.$store.dispatch('tableStore/buildTableFromPayload', {
            tableData:       response.data.tableData,
            setAsShownTable: false
          });
          const view = this.getViewById(viewId);
          view.updateWithViewData(response.data.viewData);
        });
    },
    loadView(view) {
      if (view && view.slug) return;

      return api.views.load({ viewId: view.id, skipLoader: true })
        .then((response) => view.updateWithViewData(response.data.viewData));
    },
    saveRecordsTakingPlaceNow() {
      const recordIdsTakingPlaceNow = this.tablesForList.reduce((records, table) => {
        table.records.forEach((record) => {
          const startDate = record.entriesByFieldId[this.scheduleSettings.settings_by_table_id[table.id].start_date_field_id]?.value
          const endDate   = record.entriesByFieldId[this.scheduleSettings.settings_by_table_id[table.id].end_date_field_id]?.value
          const from      = new Date(startDate);
          const to        = new Date(endDate);
          const now       = new Date();
          if ( startDate && now > from && (now < to || endDate === null)) records.push(record.id);
        });
        return records;
      }, []);
      const data = recordIdsTakingPlaceNow.map((recordId) => {
        return {
          dashboard_block_id: this.dashboardBlockId,
          item_id:            recordId,
          kind:               'record_taking_place_now'
        }
      });
      DashboardSharedItem.delete((item) => {
        return item.dashboard_block_id === this.dashboardBlockId && !recordIdsTakingPlaceNow.includes(item.item_id);
      });
      DashboardSharedItem.insertOrUpdate({ data });
    },
    dayToGroupBy(record) {
      moment.locale('fr');
      const startDateFieldId = this.scheduleSettings.settings_by_table_id[record.table.id].start_date_field_id;
      const startDate = record.entriesByFieldId[startDateFieldId].value;
      if (new Date(startDate) > new Date()) {
        return moment(new Date(startDate)).format('DD/MM/YYYY');
      } else {
        return moment(new Date()).format('DD/MM/YYYY');
      }
    },
    startDate(record) {
      moment.locale('fr');
      const startDateFieldId = this.scheduleSettings.settings_by_table_id[record.table.id].start_date_field_id;
      const startDate = record.entriesByFieldId[startDateFieldId].value;
        return moment(new Date(startDate)).format('DD/MM/YYYY');
    },
    endDate(record){
      moment.locale('fr');
      const endDateFieldId = this.scheduleSettings.settings_by_table_id[record.table.id].end_date_field_id;
      const endDate = record.entriesByFieldId[endDateFieldId].value;
      if (endDate === null) {
        return moment(moment(Date.now()).format("DD/MM/YYYY"), "DD/MM/YYYY");
      } else {
        return moment(new Date(endDate)).format('DD/MM/YYYY');
      };
    },
    splittedDateByDayAndMonth(dateString) {
      moment.locale('fr');
      const date = moment(dateString, "DD/MM/YYYY");
      return {
        fullDate:        new Date(date),
        dayOfMonth:      moment(new Date(date)).format('DD'),
        monthAndDayName: moment(new Date(date)).format('MMMM, dddd').toUpperCase()
      }
    },
    startingHours(record) {
      moment.locale('fr')
      const startDateFieldId = this.scheduleSettings.settings_by_table_id[record.table.id].start_date_field_id;
      const startDate = record.entriesByFieldId[startDateFieldId];
      if (startDate.field.typeOptions.date_format === 'with_time') {
        return moment(new Date(startDate.value)).format('HH:mm');
      } else {
        return "";
      }
    },
    finishingHours(record) {
      moment.locale('fr')
      const endDateFieldId = this.scheduleSettings.settings_by_table_id[record.table.id].end_date_field_id;
      const endDate   = record.entriesByFieldId[endDateFieldId];
      if (endDate.field.typeOptions.date_format === 'with_time' && endDate.value != null) {
        return moment(new Date(endDate.value)).format('HH:mm');
      } else if (endDate.field.typeOptions.date_format === 'with_time' && endDate.value === null) {
        return "XX:XX"
      } else {
        return "";
      }
    },
  },
}
</script>
