import consumer       from './consumer';
import store          from '../store/store';

export const buildProjectByProfileChannel = (profileId) => {
  return consumer.subscriptions.create(
    {
      channel: "ProjectByProfileChannel",
      profile_id: profileId,
    },
    {
      received(payload) {
        switch (payload.action) {
          case "add_field":
            addField(payload);
            break;
          case "destroy_record":
            destroyRecord(payload);
            break;
        }
      }
    }
  );
};

const addField = (payload) => {
  store.dispatch('fieldStore/addField', { fieldData: payload.data.field, actionInitiatorId: payload.action_initiator_id });
}

const destroyRecord = (payload) => {
  store.dispatch("recordStore/deleteRecord", payload.data.record_id);
}
