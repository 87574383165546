<template lang="pug">
  input.form-control.entry-input-number(
    type="text"
    v-model.number="inputContent"
    autocomplete="off"
    @focus="handleFocus"
    @change="handleChange"
    @blur="handleBlur"
    @keyup.enter="$event.target.blur()"
    @keypress="isNumber"
    @keyup.stop.prevent.escape="$event.target.blur()"
    :placeholder="unitSymbol"
  )
</template>

<script>
import { UpdateEntryMixin } from '../../../mixins/UpdateEntryMixin';
import i18n                 from "../../../locales/locales";

export default {
  mixins: [UpdateEntryMixin],
  props: {
    entry: {
      type:     Object,
      required: true
    }
  },
  data() {
    return {
      inputContent: null
    }
  },
  created() {
    this.buildInputContent();
  },
  computed: {
    unitSymbol() {
      return this.entry.field.typeOptions.unit_type ? i18n.t('units.' + this.entry.field.typeOptions.unit_type + '.symbol') : ''
    },
  },
  methods: {
    handleFocus(event) {
      event.target.value = this.entry.value;
    },
    handleBlur() {
      this.buildInputContent();
    },
    handleChange(event) {
      if (event.target.value !== '') {
        this.entry.value = Number.parseFloat(event.target.value, 10);
      } else {
        this.entry.value = null;
      }

      this.updateEntry(this.entry);
    },
    isNumber(event) {
      if ((/[\d\.\-]+/i).test(event.key)) return;

      event.preventDefault()
    },
    buildInputContent() {
      this.inputContent = this.entry.value;

      if (this.entry.field.typeOptions.unit_type && this.entry.value !== null) {
        const unitSymbol  = this.$t('units.' + this.entry.field.typeOptions.unit_type + '.symbol');
        this.inputContent = `${this.inputContent} ${unitSymbol}`;
      }
    }
  }
}
</script>
