<template lang="pug">
.d-flex
  slot
  .record-filters--input-wrapper.filter-input-min-width
    input.record-filters--input(
      v-if="shouldDisplayInput"
      v-model="filter.value"
      :type="inputType"
      @keypress="formatToNumber"
    )
</template>

<script>
import { RecordFilterMixin } from '../../mixins/RecordFilterMixin';

export default {
  mixins: [RecordFilterMixin],
  computed: {
    isNumber() {
      return this.field && this.field.dataType === 'number';
    },
    inputType() {
      if (this.isNumber) return 'number';

      return 'text';
    }
  },
  methods: {
    formatToNumber(event) {
      if (this.isNumber && (/e/i).test(event.key)) event.preventDefault()
    }
  }
}
</script>
